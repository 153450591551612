import { TextButton } from '@creditornot/cb-button';
import { CaretDown } from '@creditornot/cb-icons';
import { MenuActionItem } from '@creditornot/cb-menu';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import React from 'react';
import styled from 'styled-components';

import { LocalizedMessage } from 'i18n';

type Props = {
  className?: string;
  onChange?: (value: number) => void;
  options: number[];
  value?: number;
};

const noop = () => undefined;

const PopoverContent = styled.div`
  min-width: 72px;
  max-height: 320px;
  padding: 8px;
  overflow: hidden;
  border-radius: 8px;
`;

const PageSizesList = styled.div`
  max-height: 320px;
  overflow-y: auto;
`;

const StyledMenuActionItem = styled(MenuActionItem)`
  & + & {
    margin-top: 8px;
  }
`;

/**
 * Copied from cookbook cb-data-table Pagination
 */
export const PageSizeSelector: React.VFC<Props> = ({
  className,
  options,
  onChange = noop,
  value,
}) => (
  <Popover
    className={className}
    closeOnClickInside={true}
    content={
      <PopoverContent>
        <PageSizesList>
          {options.map((option) => (
            <StyledMenuActionItem
              key={option}
              onClick={() => onChange(option)}
              selected={option === value}
              variant="black"
            >
              {option}
            </StyledMenuActionItem>
          ))}
        </PageSizesList>
      </PopoverContent>
    }
    disableContentPadding={true}
    fallbackPlacements={['top-end']}
    placement="bottom-end"
    renderArrow={false}
    showOnClick={true}
    showOnHover={false}
  >
    <TextButton icon={<CaretDown />} iconPosition="right">
      <LocalizedMessage
        messageKey="deliveries.showNumber"
        values={{
          number: value,
        }}
      />
    </TextButton>
  </Popover>
);
