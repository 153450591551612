import {
  Home,
  FileStack,
  DeliveryBike,
  Profile,
  Group,
  TaskCheckListChecked,
  CalendarClock,
  AccountingDocument,
  Cogwheel,
} from '@creditornot/cb-icons';
import { FormattedMessage } from 'react-intl';
import { B2bCustomerConfigResponse } from '@creditornot/daas-core-api-client';

import WoltPlusIcon from 'assets/images/wolt-plus-icon.svg';
import { isSubscriptionBenefitOnlyCorporate } from 'modules/corporates/utils';
import { GROUPS_TAB_SELECTOR, POLICIES_TAB_SELECTOR } from 'onboarding';
import { View } from 'navigation/types';

import { Corporate } from './types';

interface RouteConfig {
  icon: JSX.Element;
  to: string;
  name: View;
  content: JSX.Element;
  dataProductTour?: string;
  show: boolean;
  isDisabled: boolean;
}

// just like the useCorporate hook, this function is used to get the corporate data
export const getCorporateRoutes = (
  corporate: Corporate,
  b2bConfig: B2bCustomerConfigResponse | undefined,
) => {
  const woltDriveEnabled = corporate?.products.daas_delivery_ui_enabled;

  const showReports = Boolean(woltDriveEnabled && b2bConfig?.is_cash_enabled);

  const isDisabled = corporate.status !== 'active';

  const wfwEnabled = corporate.products.waw_enabled;
  const subscriptionEnabled = corporate.products.subscription_benefit_enabled;
  const subscriptionOnlyCorporate = isSubscriptionBenefitOnlyCorporate(corporate);

  const availableRoutes: RouteConfig[] = [
    {
      icon: <Home />,
      to: `/${corporate.id}/home`,
      name: 'home',
      content: <FormattedMessage id="views.dashboard" />,
      isDisabled: false,
      show: !subscriptionOnlyCorporate,
    },
    {
      icon: <FileStack />,
      to: `/${corporate.id}/orders`,
      name: 'orders',
      content: <FormattedMessage id="views.orders" />,
      isDisabled,
      show: wfwEnabled || subscriptionEnabled,
    },
    {
      icon: <DeliveryBike />,
      to: `/${corporate.id}/deliveries`,
      name: 'deliveries',
      content: <FormattedMessage id="views.deliveries" />,
      isDisabled: false,
      show: woltDriveEnabled,
    },
    {
      icon: <Profile />,
      to: `/${corporate.id}/users`,
      name: 'users',
      content: <FormattedMessage id="views.users" />,
      isDisabled,
      // (temp) Route is only hidden for subscription only companies.
      // User management will be different for subscription only companies (= no possiblity to add regular users, just admins)
      show: !subscriptionOnlyCorporate,
    },
    {
      icon: <Profile />,
      to: `/${corporate.id}/admins`,
      name: 'admins',
      content: <FormattedMessage id="views.admins" />,
      isDisabled,
      show: subscriptionOnlyCorporate,
    },
    {
      icon: <Group />,
      to: `/${corporate.id}/groups`,
      name: 'groups',
      content: <FormattedMessage id="views.groups" />,
      dataProductTour: GROUPS_TAB_SELECTOR,
      isDisabled,
      show: wfwEnabled,
    },
    {
      icon: <TaskCheckListChecked />,
      to: `/${corporate.id}/policies`,
      name: 'policies',
      content: <FormattedMessage id="views.policies" />,
      dataProductTour: POLICIES_TAB_SELECTOR,
      isDisabled,
      show: wfwEnabled,
    },
    {
      icon: <WoltPlusIcon />,
      to: `/${corporate.id}/subscriptions`,
      name: 'subscriptions',
      content: <FormattedMessage id="views.subscriptions" />,
      isDisabled,
      show: subscriptionEnabled,
    },
    {
      icon: <CalendarClock />,
      to: `/${corporate.id}/events`,
      name: 'events',
      content: <FormattedMessage id="views.events" />,
      isDisabled,
      show: wfwEnabled,
    },
    {
      icon: <AccountingDocument />,
      to: `/${corporate.id}/invoices`,
      name: 'invoices',
      content: <FormattedMessage id="views.invoices" />,
      isDisabled,
      show: !showReports,
    },
    {
      icon: <AccountingDocument />,
      to: `/${corporate.id}/reports`,
      name: 'reports',
      content: <FormattedMessage id="views.reports" />,
      isDisabled,
      show: showReports,
    },
    {
      icon: <Cogwheel />,
      to: `/${corporate.id}/settings`,
      name: 'settings',
      content: (
        <FormattedMessage id={woltDriveEnabled ? 'views.settings' : 'views.company-settings'} />
      ),
      isDisabled: false,
      show: true,
    },
  ];
  // Filter out routes that should be hidden
  return availableRoutes.filter((route) => route.show);
};
