import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { useCallback } from 'react';
import { Column, DataTable } from '@creditornot/cb-data-table';
import { useIntl } from 'react-intl';

import {
  View,
  DataCellContent,
  ResponsiveContainer,
  ApiErrorNotification,
  RtlCaret,
} from 'components';
import { breakpoints, useMedia } from 'modules/media';
import { Corporate } from 'modules/corporates/types';
import { useFetchCorporates } from 'modules/corporates';

import MultipleCorporatesDataList from './MultipleCorporatesDataList';

const Container = styled.div`
  background-color: ${color.bg};
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 0 8px 0 rgb(32 33 37 / 6%);
`;

const TableContainer = styled.div`
  @media (max-width: ${breakpoints.medium}px) {
    overflow-x: auto;
  }

  & > * {
    @media (max-width: ${breakpoints.medium}px) {
      min-width: ${breakpoints.medium}px;
    }

    @media (max-width: ${breakpoints.small}px) {
      min-width: unset;
    }
  }
`;

const ErrorContainer = styled(ResponsiveContainer)`
  padding-top: 22px;
  padding-bottom: 22px;
`;

const CaretEnd = styled(RtlCaret).attrs({ alignment: 'end' })`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  fill: ${color.textSubdued};
`;

const useColumns = (): Column<Corporate, unknown>[] => {
  const { formatMessage } = useIntl();
  return [
    {
      id: 'name',
      name: formatMessage({ id: 'common.name' }),
      render: ({ record: { name } }) => {
        return <DataCellContent>{name}</DataCellContent>;
      },
    },
    {
      id: 'businessId',
      name: formatMessage({ id: 'common.business_id' }),
      render: ({ record: { business_id } }) => {
        return <DataCellContent>{business_id}</DataCellContent>;
      },
    },
    {
      id: 'contactPerson',
      name: formatMessage({ id: 'corporates.column-title.contact-person' }),
      render: ({ record: { contact_name, contact_email } }) => {
        return <DataCellContent subContent={contact_email}>{contact_name}</DataCellContent>;
      },
    },
    {
      id: 'caret',
      name: '',
      style: {
        width: '7%',
      },
      render: () => {
        return <CaretEnd />;
      },
    },
  ];
};

const MultipleCorporatesView = ({ history }: RouteComponentProps) => {
  const mediumView = useMedia('medium');
  const columns = useColumns();
  const { formatMessage } = useIntl();
  const { data: corporatesData, isLoading, error } = useFetchCorporates();

  const handleDataRowClick = useCallback(
    (_: string, corporate: Corporate) => {
      history.push(`/${corporate.id}`);
    },
    [history],
  );

  if (error) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={error} />
      </ErrorContainer>
    );
  }

  return (
    <View title={formatMessage({ id: 'corporates.page-title' })}>
      <Container>
        {mediumView ? (
          <MultipleCorporatesDataList
            data={corporatesData}
            getDataItemId={(dataItem) => dataItem.id}
            loading={isLoading}
            noDataMessage={formatMessage({ id: 'corporates.no-corporates' })}
            error={error}
            onDataItemClick={(corporate) => handleDataRowClick('', corporate)}
          />
        ) : (
          <TableContainer>
            <DataTable
              horizontalPadding="wide"
              tableLayout="fixed"
              dataSource={corporatesData || []}
              columns={columns}
              getRowKey={(dataItem) => dataItem.id}
              loading={isLoading}
              onRowClick={handleDataRowClick}
            />
          </TableContainer>
        )}
      </Container>
    </View>
  );
};

export default MultipleCorporatesView;
