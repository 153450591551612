import { color } from '@creditornot/cb-ingredients/design-tokens';
import { TextButton } from '@creditornot/cb-button';
import { WindowNew } from '@creditornot/cb-icons';
import { colors, typographyCss } from '@creditornot/cb-ingredients';
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  DeliveryOrderApiListDeliveryOrdersRequest as ListDeliveryOrdersRequest,
  DeliveryOrderResponse,
} from '@creditornot/wd-api-client';
import { rem } from 'polished';
import { Alert } from '@creditornot/cb-alert';
import { Empty } from '@creditornot/cb-placeholders';
import { Spinner } from '@creditornot/cb-progress';
import { useIntl } from 'react-intl';

import { useFetchDeliveriesInfinite } from 'modules/deliveries';
import { useDebounce } from 'modules/hooks';
import { Anchor, ResponsiveContainer, Waypoint, RtlCaret } from 'components';
import { useCorporateDeliverySettingsComplete } from 'modules/corporates';
import { FinalizeSettingsMessage } from 'views/delivery-home/FinalizeSettingsMessage';
import { processError } from 'utils';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';
import { Source, deliveriesInteractionOrderTracking } from 'telemetry/Avo';

import { DeliveryStatusTag } from './DeliveryStatusTag';
import { IntineraryField } from './IntineraryField';
import { useDeliveriesQueryParams } from './useDeliveriesQueryParams';
import { DeliveryDetailsModal } from './DeliveryDetailsModal';

const OrderNumber = styled.div`
  ${typographyCss.Title2()}
  color: ${color.text};
  margin-bottom: 4px;
`;

const Title = styled.div`
  ${typographyCss.Body3()}
  color: ${color.text};
`;

const DeliveryStatusContainer = styled.div`
  margin-top: 4px;
  margin-bottom: 16px;
`;

const ItemRow = styled.div`
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: ${colors.wolt4};
  }

  &:active {
    background-color: ${colors.wolt8};
  }
`;

const ItemContainer = styled(ResponsiveContainer)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid ${color.border};

  &:last-of-type {
    border-bottom: 0;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

const StyledAlert = styled(Alert)`
  margin: 16px;
`;

const TrackingLink = styled(Anchor)`
  display: inline-block;
  margin-inline-start: 8px;
`;

const FinalizeSettingsMessageContainer = styled.div`
  margin: ${rem('24px')};
`;

const CaretEnd = styled(RtlCaret).attrs({ alignment: 'end' })`
  height: ${rem('16px')};
  width: ${rem('16px')};
  fill: ${color.textSubdued};
  align-self: center;
  margin: 0 ${rem('8px')};
`;

const EmptyMessageContainer = styled.div`
  padding: ${rem('48px')} ${rem('8px')};
`;

export const DeliveriesList: FC<{
  corporateId: string;
}> = ({ corporateId }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentOrder, setCurrentOrder] = useState<DeliveryOrderResponse | undefined>(undefined);
  const { isWoltEmployeeWithOpsToolsAccess } = useIsUserWoltEmployee();

  const deliveriesInteractionOrderTrackingEvent = useTelemetryInteractionEvent(
    deliveriesInteractionOrderTracking,
  );

  const { formatMessage, formatDate } = useIntl();
  const {
    params: { from, to, deliveryStatus, pageSize, searchQuery },
  } = useDeliveriesQueryParams();

  const debouncedSearch = useDebounce(searchQuery, 500);

  const listDeliveryOrdersParams: ListDeliveryOrdersRequest = {
    corporateId,
    userId: '',
    from: from.toISOString(),
    to: to.toISOString(),
    pageToken: '',
    deliveryStatus: (deliveryStatus as ListDeliveryOrdersRequest['deliveryStatus']) || undefined,
    pageSize,
    searchQuery: debouncedSearch,
    sortBy: 'createdAt',
    sortDirection: 'desc',
  };

  const { data, error, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useFetchDeliveriesInfinite(listDeliveryOrdersParams, {
      refetchInterval: 30 * 1000,
    });

  const handleIntersect = useCallback(() => {
    if (!hasNextPage) {
      return;
    }
    void fetchNextPage();
  }, [fetchNextPage, hasNextPage]);

  const { data: deliverySettingsComplete, error: deliverySettingsCompleteError } =
    useCorporateDeliverySettingsComplete(corporateId);

  const handleOrderClick = useCallback((order: DeliveryOrderResponse) => {
    setIsModalOpen(true);
    setCurrentOrder(order);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleTrackingLinkClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      deliveriesInteractionOrderTrackingEvent({ source: Source.DELIVERIES });
      e.stopPropagation();
    },
    [deliveriesInteractionOrderTrackingEvent],
  );

  return (
    <div>
      {currentOrder && (
        <DeliveryDetailsModal
          onClose={handleModalClose}
          order={currentOrder}
          show={isModalOpen}
          showOpsToolsLink={isWoltEmployeeWithOpsToolsAccess}
        />
      )}

      {!!error && (
        <StyledAlert variant="error" title={processError(error).message}>
          {processError(error).data}
        </StyledAlert>
      )}

      {!!deliverySettingsCompleteError && (
        <StyledAlert variant="error" title={processError(deliverySettingsCompleteError).message}>
          {processError(deliverySettingsCompleteError).data}
        </StyledAlert>
      )}

      {deliverySettingsComplete === true && data && data.pages[0].orders.length === 0 ? (
        <EmptyMessageContainer>
          <Empty
            description={formatMessage({ id: 'deliveries.all-orders-description' })}
            title={formatMessage({ id: 'deliveries.no-orders-description' })}
          />
        </EmptyMessageContainer>
      ) : (
        data?.pages?.map((page, i) => (
          <React.Fragment key={i}>
            {page?.orders.map((order) => (
              <ItemRow
                key={order.id}
                data-test-id={`order-row-${order.id}`}
                onClick={() => handleOrderClick(order)}
              >
                <ItemContainer>
                  <OrderNumber data-test-id={`order-number-${order.id}`}>
                    #{order.orderNumber}
                  </OrderNumber>

                  <div>
                    {order.createdAt && (
                      <Title>
                        {formatDate(new Date(order.createdAt), {
                          dateStyle: 'short',
                          timeStyle: 'short',
                        })}
                      </Title>
                    )}
                    <Title data-test-id={`delivery-recipient-${order.id}`}>
                      {order.dropoff?.recipient?.name}
                    </Title>{' '}
                    •{' '}
                    <Title data-test-id={`delivery-parcel-${order.id}`}>
                      {formatMessage(
                        { id: 'deliveries.parcel-with-count' },
                        {
                          itemCount: order.parcels?.length ?? 0,
                        },
                      )}
                    </Title>
                  </div>
                  <DeliveryStatusContainer>
                    <div data-test-id={`delivery-status-${order.id}`}>
                      <DeliveryStatusTag status={order.status} />
                    </div>
                    {order.tracking && (
                      <TrackingLink
                        data-test-id={`delivery-tracking-link-${order.id}`}
                        href={order.tracking.url}
                        rel="noreferrer"
                        target="_blank"
                        onClick={handleTrackingLinkClick}
                      >
                        <TextButton
                          data-test-id={`delivery-tracking-${order.id}`}
                          icon={<WindowNew />}
                          iconPosition="right"
                          variant="blue"
                        >
                          {formatMessage({ id: 'deliveries.tracking' })}
                        </TextButton>
                      </TrackingLink>
                    )}
                  </DeliveryStatusContainer>
                  <div>
                    <IntineraryField order={order} />
                  </div>
                </ItemContainer>
                <CaretEnd />
              </ItemRow>
            ))}
          </React.Fragment>
        ))
      )}

      {deliverySettingsComplete === false && (
        <FinalizeSettingsMessageContainer>
          <FinalizeSettingsMessage corporateId={corporateId} />
        </FinalizeSettingsMessageContainer>
      )}

      <Waypoint onIntersect={handleIntersect} />

      {isFetchingNextPage && (
        <SpinnerContainer>
          <Spinner size="large" />
        </SpinnerContainer>
      )}
    </div>
  );
};
