import { Tag } from '@creditornot/cb-tag';
import styled from 'styled-components';

import { LocalizedMessage } from 'i18n';
import { EventUser } from 'modules/events/types';

interface Props {
  status: EventUser['status'];
  className?: string;
}

const statusRecord = {
  active: (
    <Tag size="small" variant="lightBlack">
      <LocalizedMessage messageKey="events.participants.voucher-status-valid" />
    </Tag>
  ),
  used: (
    <Tag size="small" variant="lightGreen">
      <LocalizedMessage messageKey="events.participants.voucher-status-used" />
    </Tag>
  ),
  expired: (
    <Tag size="small" variant="lightOrange">
      <LocalizedMessage messageKey="events.participants.voucher-status-expired" />
    </Tag>
  ),
  pending: (
    <Tag size="small" variant="lightBlue">
      <LocalizedMessage messageKey="events.participants.voucher-status-pending" />
    </Tag>
  ),
  not_used: (
    <Tag size="small" variant="lightBlack">
      <LocalizedMessage messageKey="events.participants.voucher-status-not-used" />
    </Tag>
  ),
  ordered: (
    <Tag size="small" variant="lightGreen">
      <LocalizedMessage messageKey="events.participants.voucher-status-ordered" />
    </Tag>
  ),
  accepted: (
    <Tag size="small" variant="lightBlack">
      <LocalizedMessage messageKey="events.participants.voucher-status-accepted" />
    </Tag>
  ),
};

const Root = styled.div`
  display: inline-block;
`;

export const EventUserStatus: React.FC<Props> = ({ status, className }) => {
  return <Root className={className}>{statusRecord[status]}</Root>;
};
