import { isNil } from 'ramda';
import { useIntl } from 'react-intl';

import { convertMinutesToDeliveryTime } from 'utils';
import { DayOfWeek } from 'modules/policies/types';
import { type MessageIds } from 'i18n';

import { daysOfWeekOrder } from './utils';

type ContentProps = {
  days: DayOfWeek[];
  className?: string;
};

type DescriptionProps = {
  startTimeInMinutes: number | null;
  endTimeInMinutes: number | null;
  className?: string;
};

const Content = ({ days, className }: ContentProps) => {
  const { formatMessage } = useIntl();

  return (
    <span className={className}>
      {`${
        days.length !== 0
          ? days
              .sort((a, b) => (daysOfWeekOrder.indexOf(a) > daysOfWeekOrder.indexOf(b) ? 1 : -1))
              .map((dayOfWeek: DayOfWeek) => {
                const key: MessageIds = `common.weekday.shorthand.${dayOfWeek}`;
                return formatMessage({ id: key }) || '';
              })
              .join(', ')
          : formatMessage({ id: 'policies.any-time' })
      }`}
    </span>
  );
};

const Description = ({ startTimeInMinutes, endTimeInMinutes, className }: DescriptionProps) => {
  if (isNil(startTimeInMinutes) || isNil(endTimeInMinutes)) {
    return null;
  }

  return (
    <span className={className}>
      {convertMinutesToDeliveryTime(startTimeInMinutes)}
      {' – '}
      {convertMinutesToDeliveryTime(endTimeInMinutes)}
    </span>
  );
};

export { Content, Description };
