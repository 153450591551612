import { FormattedDate, IntlFormatters, useIntl } from 'react-intl';
import { Button } from '@creditornot/cb-button';
import { useState } from 'react';
import styled from 'styled-components';

import { Divider, FormSection, FormViewText, FormRowV2 as Row } from 'components';
import { Consent, ConsentType } from 'modules/corporates/types';

type Props = {
  consents: Consent[];
};

const StyledDivider = styled(Divider)`
  margin-bottom: 24px;
`;

const TextContent = styled(FormViewText)`
  overflow-wrap: normal;
  word-break: break-word;
`;

const getDisplayType = (type: ConsentType, formatMessage: IntlFormatters['formatMessage']) => {
  switch (type) {
    case 'subscription_benefit_terms':
      return formatMessage({ id: 'views.settings.consent-type.subscription' });
    default:
      return type;
  }
};

export const Consents = ({ consents }: Props) => {
  const { formatMessage } = useIntl();
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow((show) => !show);
  };

  return (
    <FormSection
      title={formatMessage({ id: 'views.settings.consents' })}
      topRightItem={
        <Button type="button" variant="lightBlue" size="small" onClick={handleClick}>
          {formatMessage({ id: show ? 'common.hide' : 'common.show' })}
        </Button>
      }
    >
      {show &&
        consents.map(({ type, timestamp, user_name, text_content }, index) => {
          const displayType = getDisplayType(type, formatMessage);
          return (
            <>
              <Row
                title={formatMessage({ id: 'views.settings.consent-type' })}
                content={<FormViewText>{displayType}</FormViewText>}
              />
              <Row
                title={formatMessage({ id: 'views.settings.agreed-date' })}
                content={
                  <FormViewText>
                    <FormattedDate value={timestamp} />
                  </FormViewText>
                }
              />
              {user_name && (
                <Row
                  title={formatMessage({ id: 'views.settings.consent-accepted-by' })}
                  content={<FormViewText>{`${user_name.first} ${user_name.last}`}</FormViewText>}
                />
              )}
              {text_content && (
                <Row
                  title={formatMessage({ id: 'views.settings.consent-text' })}
                  content={<TextContent>{text_content}</TextContent>}
                />
              )}
              {index < consents.length - 1 && <StyledDivider />}
            </>
          );
        })}
    </FormSection>
  );
};
