import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { B2bCustomerConfigResponse } from '@creditornot/daas-core-api-client';

import { useQuery, UseQueryOptions } from 'modules/react-query';

import { b2bConfigApiClient } from './api';

export const useFetchB2bConfig = <T = B2bCustomerConfigResponse>(
  id: string | null,
  config?: UseQueryOptions<B2bCustomerConfigResponse, AxiosError, T>,
) => {
  const queryFn = useCallback(async () => {
    if (!id) {
      return Promise.reject('No corporate id provided for b2b config');
    }
    const { data } = await b2bConfigApiClient.corporateGetB2bConfig({ corporateId: id });
    return data;
  }, [id]);
  return useQuery({
    queryKey: ['b2bConfigs', id],
    queryFn,
    retryOnMount: false,
    ...config,
  });
};
