import { ComponentProps } from 'react';

import { FormikTextareaField } from 'components';

export const FormikEventDescriptionTextarea: React.FC<
  Partial<ComponentProps<typeof FormikTextareaField>>
> = ({ textareaProps, ...props }) => {
  return (
    <FormikTextareaField
      name="description"
      textareaProps={{
        style: {
          minHeight: '80px',
          ...textareaProps,
        },
      }}
      {...props}
    />
  );
};
