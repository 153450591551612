import * as t from 'io-ts';

import { restaurantApiAgent } from 'apiAgents';
import { PaginatedData } from 'types/PaginatedData';
import { logValidationError } from 'validation';

import { Invoice } from './types';

export type FetchInvoicesParams = {
  page: number;
  page_size: number;
  pagination?: boolean;
};

export const fetchInvoices = async (
  corporateId: string,
  { page, page_size, pagination = true }: FetchInvoicesParams,
) => {
  const { data } = await restaurantApiAgent.get<PaginatedData<Invoice>>(
    `/v1/waw-api/corporates/${corporateId}/invoices`,
    {
      params: { page, page_size, pagination },
    },
  );
  logValidationError('fetchInvoices', t.array(Invoice), data.results);
  return data;
};
