import * as t from 'io-ts';

import { restaurantApiAgent } from 'apiAgents';
import { logValidationError } from 'validation';
import { ResponseStatus, PaginatedData } from 'types';

import { UserInvite } from './types';

export const fetchInvite = async (corporateId: string, userInviteId?: string) => {
  const { data } = await restaurantApiAgent.get<UserInvite>(
    `/v1/waw-api/corporates/${corporateId}/user-invite/${userInviteId}`,
  );
  logValidationError('fetchInvite', UserInvite, data);
  return data;
};

export type FetchInviteParams = {
  email?: string;
  group_ids?: string[];
  is_expired?: boolean;
  page: number;
  page_size: number;
};

export const fetchInvites = async (
  corporateId: string,
  { email, is_expired, group_ids, page, page_size }: FetchInviteParams,
) => {
  const { data } = await restaurantApiAgent.get<PaginatedData<UserInvite>>(
    `/v1/waw-api/corporates/${corporateId}/user-invites`,
    {
      params: {
        email,
        group_ids: group_ids?.join(','),
        is_expired: is_expired,
        page_size,
        page,
        pagination: true,
      },
    },
  );
  logValidationError('fetchInvites', t.array(UserInvite), data.results);
  return data;
};

export type InvitesData = {
  email: string;
  is_corporate_manager?: boolean;
};

export type InviteUserData = {
  invites: InvitesData[];
};

export const inviteUsers = async (corporateId: string, inviteUserData: InviteUserData) => {
  const { data } = await restaurantApiAgent.post<UserInvite[]>(
    `/v1/waw-api/corporates/${corporateId}/user-invites/bulk`,
    inviteUserData,
  );
  logValidationError('inviteUsers', t.array(UserInvite), data);
  return data;
};

export const resendUserInvite = async (
  corporateId: string,
  inviteIds: string[],
  months: number,
) => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + months);
  const { data } = await restaurantApiAgent.post(
    `/v1/waw-api/corporates/${corporateId}/user-invites/resend`,
    {
      invite_ids: inviteIds,
      valid_until: currentDate.toISOString(),
    },
  );
  logValidationError('resendUserInvites', ResponseStatus, data);
  return data;
};

export const deleteUserInvite = async (corporateId: string, inviteId: string) => {
  const { data } = await restaurantApiAgent.delete(
    `/v1/waw-api/corporates/${corporateId}/user-invites/${inviteId}`,
  );
  logValidationError('deleteUserInvite', ResponseStatus, data);
  return data;
};
