import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCorporate } from 'modules/corporates';

import { downloadServiceAgreementFinal, downloadServiceAgreementPreview } from './api';
import { useFetchServiceAgreement } from './useFetchServiceAgreement';

export const useDownloadServiceAgreement = () => {
  const { data: corporate } = useCorporate();
  const queryClient = useQueryClient();

  return useCallback(
    async (serivceAgreementId: string, language: string) => {
      if (!corporate?.id) {
        return Promise.reject('Corporate is missing');
      }
      const result = await downloadServiceAgreementPreview(
        corporate.id,
        serivceAgreementId,
        language,
      );
      queryClient.invalidateQueries('service-agreement');
      return result;
    },
    [corporate?.id, queryClient],
  );
};

export const useDownloadFinalServiceAgreement = () => {
  const { data: corporate } = useCorporate();
  const { data: serviceAgreement } = useFetchServiceAgreement();

  return useCallback(async () => {
    if (!corporate?.id) {
      return Promise.reject('Corporate is missing');
    }

    if (!serviceAgreement?.id) {
      return Promise.reject('ServiceAagreement is missing');
    }

    const result = await downloadServiceAgreementFinal(corporate.id, serviceAgreement.id);
    return result;
  }, [corporate?.id, serviceAgreement?.id]);
};
