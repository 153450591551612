import { useContext } from 'react';

import ConfigsContext from './ConfigsContext';

const useConfigs = () => {
  const configsData = useContext(ConfigsContext);

  return configsData;
};

export default useConfigs;
