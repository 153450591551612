import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useMemo } from 'react';
import { Column, DataTable } from '@creditornot/cb-data-table';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';

import { ApiErrorNotification, DataCellContent, RtlCaret } from 'components';
import { LocalizedDate, LocalizedNumber, useI18n } from 'i18n';
import { PaginatedData } from 'types';
import { Event } from 'modules/events/types';
import { useCorporate } from 'modules/corporates';

import { EventStatus } from './EventStatus';
import { useEventsQueryParams } from './hooks';

interface Props {
  eventsData: PaginatedData<Event> | null | undefined;
  onEventClick: (eventId: string) => void;
  isLoading: boolean;
  error: unknown;
}

const Description = styled.small`
  ${typographyCss.Small()}
`;

const Content = styled.span`
  ${typographyCss.Body3()}
`;

const ErrorContainer = styled.div`
  padding-top: 22px;
  padding-bottom: 22px;
`;

const CaretEnd = styled(RtlCaret).attrs({ alignment: 'end' })`
  height: 16px;
  width: 16px;
  fill: ${color.textSubdued};
`;

const useEventsDataTableColumns = (): Column<Event, Record<string, unknown>>[] => {
  const { getLocalizedMessage } = useI18n();
  const { data: corporate } = useCorporate();

  return useMemo(
    () => [
      {
        id: 'name',
        name: getLocalizedMessage('events.event-name-column-title'),
        style: {
          width: '20%',
        },
        render: ({ record: { name, description } }) => {
          return (
            <div>
              <DataCellContent subContent={description && <Description>{description}</Description>}>
                <Content>{name}</Content>
              </DataCellContent>
            </div>
          );
        },
      },
      {
        id: 'date',
        style: {
          width: '20%',
        },
        name: getLocalizedMessage('events.date-column-title'),
        render: ({ record: { start_time, end_time } }) => {
          return (
            <>
              <LocalizedDate value={new Date(start_time)} /> –{' '}
              <LocalizedDate value={new Date(end_time)} />
            </>
          );
        },
      },
      {
        id: 'budget',
        style: {
          width: '20%',
        },
        name: getLocalizedMessage('events.participant-budget-column-title'),
        render: ({ record: { participant_budget_amount } }) => {
          return (
            <LocalizedNumber currency={corporate?.currency} value={participant_budget_amount} />
          );
        },
      },
      {
        id: 'numberOfParticipants',
        name: getLocalizedMessage('events.number-of-participant-column-title'),
        render: ({ record: { participant_count } }) => {
          return <>{participant_count}</>;
        },
      },
      {
        id: 'status',
        style: {
          width: '10%',
        },
        name: getLocalizedMessage('events.status-column-title'),
        render: ({ record: { status } }) => {
          return <EventStatus status={status} />;
        },
      },
      {
        id: 'actions',
        name: '',
        style: {
          width: '10%',
        },
        alignContent: 'right',
        render: () => {
          return <CaretEnd />;
        },
      },
    ],
    [corporate?.currency, getLocalizedMessage],
  );
};

export const EventsDataTable: React.FC<Props> = ({
  eventsData,
  onEventClick,
  isLoading,
  error,
}) => {
  const [{ page, page_size }, setQueryParams] = useEventsQueryParams();
  const columns = useEventsDataTableColumns();
  const { getLocalizedMessage } = useI18n();

  if (error) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={error} />
      </ErrorContainer>
    );
  }

  const hidePagination = isLoading || eventsData?.total_items_count === 0;

  return (
    <DataTable
      horizontalPadding="wide"
      tableLayout="fixed"
      dataSource={eventsData?.results ?? []}
      columns={columns}
      getRowKey={(dataItem) => dataItem.id}
      onRowClick={onEventClick}
      loading={isLoading}
      emptyMessage={{
        title: getLocalizedMessage('events.no-events-while-filering-title'),
      }}
      pagination={
        hidePagination
          ? undefined
          : {
              disableWhenLoading: true,
              pageCount: Math.ceil(
                (eventsData?.total_items_count ?? 0) / (eventsData?.page_size ?? 1),
              ),
              pageSize: page_size,
              activePage: page,
              onPageChange: (p) => setQueryParams({ page: p }),
              onPageSizeChange: (p) => {
                setQueryParams({ page: 1, page_size: p });
              },
              pageSizeOptions: [10, 20, 50, 100],
            }
      }
    />
  );
};
