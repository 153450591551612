import { ConfirmationModal } from 'components';
import { LocalizedMessage } from 'i18n';
import { useCorporate } from 'modules/corporates';
import { useRemovePolicyFromGroup } from 'modules/policies';
import { Group } from 'modules/groups/types';

interface Props {
  onClose: () => void;
  onSuccess: (removedPolicy: Group['policies'][0]) => void;
  show: boolean;
  group: Group | undefined | null;
}

export const RemovePolicyFromGroupModal = ({ onSuccess, onClose, group, show }: Props) => {
  const { data: corporate } = useCorporate();
  const policy = group?.policies?.[0];
  const removePolicyFromGroup = useRemovePolicyFromGroup();

  return (
    <ConfirmationModal
      onClose={onClose}
      show={show}
      title={<LocalizedMessage messageKey="groups-form.remove-policy-from-group" />}
      content={
        policy ? (
          <LocalizedMessage
            messageKey="groups-form.remove-policies-from-group-confirmation-message"
            values={{
              policies: policy.name,
              b: (msg: string) => <b key="msg">{msg}</b>,
            }}
          />
        ) : (
          '–'
        )
      }
      onSuccess={() =>
        setTimeout(() => {
          if (policy) {
            onSuccess(policy);
          }
          onClose();
        }, 500)
      }
      onConfirm={async () => {
        if (!corporate || !policy || !group) {
          throw 'corporate, policy and group must be provided';
        }
        await removePolicyFromGroup(group.id, policy.id);
      }}
      confirmationButtonMessage={<LocalizedMessage messageKey="common.remove" />}
      confirmationButtonVariant="lightRed"
    />
  );
};
