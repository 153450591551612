import { useEffect, useRef } from 'react';

interface UseIntersectionObserverProps<T> {
  node?: HTMLElement | null;
  callback: (entry: IntersectionObserverEntry, observer: IntersectionObserver) => T;
  options?: IntersectionObserverInit;
  updateOn?: number;
}

export function useIntersectionObserver<T>({
  node,
  callback,
  options,
  updateOn = 0,
}: UseIntersectionObserverProps<T>) {
  const intervalRef = useRef(setInterval(() => {}, 0));

  useEffect(() => {
    if (!node || !window.IntersectionObserver) return;

    const observer = new IntersectionObserver(
      (entry: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        callback(entry[0], observer);
        intervalRef.current = setInterval(() => {
          callback(entry[0], observer);
        }, updateOn);
      },
      { rootMargin: '0px', ...options },
    );

    observer.observe(node);

    return () => {
      observer.unobserve(node);
      clearInterval(intervalRef.current);
    };
  }, [callback, node, options, updateOn]);
}
