import * as t from 'io-ts';

import { restaurantApiAgent } from 'apiAgents';
import { PaginatedData } from 'types/PaginatedData';
import { logValidationError } from 'validation';

import { AgreementParty } from './types';

export const fetchAgreementParty = async (id: string) => {
  const { data } = await restaurantApiAgent.get<AgreementParty>(
    `/v1/waw-api/agreement-parties/${id}`,
  );
  logValidationError('fetchAgreementParty', AgreementParty, data);
  return data;
};

export type FetchAgreementPartiesParams = {
  legal_name?: string;
  page: number;
  page_size: number;
};

export const fetchAgreementParties = async (params: FetchAgreementPartiesParams) => {
  const { data } = await restaurantApiAgent.get<PaginatedData<AgreementParty>>(
    `/v1/waw-api/agreement-parties`,
    {
      params,
    },
  );
  logValidationError('fetchAgreementParties', t.array(AgreementParty), data.results);

  return data;
};

export type CreateArgreementPartyData = Pick<
  AgreementParty,
  'address' | 'business_id' | 'country_code' | 'bank_accounts' | 'legal_name' | 'vats'
>;

export const createAgreementParty = async (
  createArgreementPartyData: CreateArgreementPartyData,
) => {
  const { data } = await restaurantApiAgent.post<AgreementParty>(
    `/v1/waw-api/agreement-parties`,
    createArgreementPartyData,
  );
  logValidationError('createAgreementParty', AgreementParty, data);
  return data;
};

export type EditArgreementPartyData = Partial<
  Pick<
    AgreementParty,
    'address' | 'business_id' | 'country_code' | 'bank_accounts' | 'legal_name' | 'vats'
  >
>;

export const editAgreementParty = async (
  id: string,
  editArgreementPartyData: EditArgreementPartyData,
) => {
  const { data } = await restaurantApiAgent.patch<AgreementParty>(
    `/v1/waw-api/agreement-parties/${id}`,
    editArgreementPartyData,
  );
  logValidationError('editAgreementParty', AgreementParty, data);
  return data;
};
