import styled from 'styled-components';
import { TextButton } from '@creditornot/cb-button';
import { cbSemanticCssVars, typographyCss } from '@creditornot/cb-ingredients';
import { useIntl } from 'react-intl';

import { useCreateWoltPagesLink } from 'modules/common/hooks';

interface Props {
  className?: string;
  openManageCookieConsentsModal: () => void;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledTextButton = styled(TextButton)`
  color: ${cbSemanticCssVars.textSecondary};
  font-size: 12px;
  padding-inline-end: 8px;
`;

const Copyright = styled.p`
  ${typographyCss.Small()};
  color: ${cbSemanticCssVars.textSecondary};
`;

export const Compliance = ({ className, openManageCookieConsentsModal }: Props) => {
  const { formatMessage } = useIntl();
  const createWoltPagesLink = useCreateWoltPagesLink();

  const currentYear = new Date().getFullYear();

  return (
    <Root className={className}>
      <StyledTextButton
        onClick={() => {
          window.open(createWoltPagesLink('terms'));
        }}
      >
        {formatMessage({ id: 'navigation.terms-of-service' })}
      </StyledTextButton>
      <StyledTextButton
        onClick={() => {
          window.open(createWoltPagesLink('privacy'));
        }}
      >
        {formatMessage({ id: 'navigation.privacy-statement' })}
      </StyledTextButton>
      <StyledTextButton onClick={openManageCookieConsentsModal}>
        {formatMessage({ id: 'navigation.cookie-consents' })}
      </StyledTextButton>
      <Copyright>{formatMessage({ id: 'navigation.copyright' }, { currentYear })}</Copyright>
    </Root>
  );
};
