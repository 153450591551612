export const CheckmarkIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0797 6.44871C18.8129 6.25207 18.4789 6.16951 18.1513 6.21921C17.8236 6.26891 17.5291 6.4468 17.3327 6.71371L11.4087 14.7537L7.64167 11.7397C7.10141 11.3259 6.32943 11.4198 5.9042 11.9511C5.47896 12.4825 5.55648 13.2563 6.07867 13.6927L10.8617 17.5187C11.1287 17.7275 11.4684 17.8203 11.8045 17.7761C12.1405 17.7319 12.4447 17.5545 12.6487 17.2837L19.3487 8.19671C19.5449 7.92929 19.6269 7.59482 19.5764 7.26696C19.526 6.9391 19.3473 6.64474 19.0797 6.44871Z"
        fill="white"
      />
    </svg>
  );
};
