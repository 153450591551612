import * as t from 'io-ts';

import { restaurantApiAgent } from 'apiAgents';
import { logValidationError } from 'validation';
import { PaginatedData, ResponseStatus } from 'types';

import { Event, EventUsage, EventUser, UserEventInvite, EventStatistics } from './types';

export type FetchEventsParams = {
  page: number;
  page_size: number;
  search?: string;
  statuses?: string[];
};

export const fetchEvents = async (corporateId: string, fetchEventsParams: FetchEventsParams) => {
  const { data } = await restaurantApiAgent.get<PaginatedData<Event>>(
    `/v1/waw-api/corporates/${corporateId}/events`,
    {
      params: fetchEventsParams,
    },
  );
  logValidationError('fetchEvents', t.array(Event), data.results);
  return data;
};

export const fetchEvent = async (corporateId: string, eventId: string) => {
  const { data } = await restaurantApiAgent.get<Event>(
    `/v1/waw-api/corporates/${corporateId}/events/${eventId}`,
  );
  logValidationError('fetchEvent', Event, data);
  return data;
};

export type EditEventData = {
  description?: string;
  end_time?: string;
  name?: string;
  participant_budget_amount?: number;
  start_time?: string;
  invite_message?: string;
};

export const editEvent = async (
  corporateId: string,
  eventId: string,
  editEventData: EditEventData,
) => {
  const { data } = await restaurantApiAgent.patch<Event>(
    `/v1/waw-api/corporates/${corporateId}/events/${eventId}`,
    editEventData,
  );
  logValidationError('editEvent', Event, data);
  return data;
};

export type CreateEventData = {
  description: string;
  end_time: string;
  name: string;
  participant_budget_amount: number;
  start_time: string;
};

export const createEvent = async (corporateId: string, createEventsData: CreateEventData) => {
  const { data } = await restaurantApiAgent.post<Event>(
    `/v1/waw-api/corporates/${corporateId}/events`,
    createEventsData,
  );
  logValidationError('createEvent', Event, data);
  return data;
};

export const deleteEvent = async (corporateId: string, eventId: string) => {
  const { data } = await restaurantApiAgent.delete<ResponseStatus>(
    `/v1/waw-api/corporates/${corporateId}/events/${eventId}`,
  );
  logValidationError('deleteEvent', ResponseStatus, data);
  return data;
};

export type InviteUserToEventData = {
  custom_message: string | undefined;
  email: string;
  event_id: string;
};

export const inviteUserToEvent = async (
  corporateId: string,
  inviteUserToEventData: InviteUserToEventData[],
) => {
  const { data } = await restaurantApiAgent.post<UserEventInvite[]>(
    `/v1/waw-api/corporates/${corporateId}/user-invites/bulk`,
    {
      invites: inviteUserToEventData,
    },
  );
  logValidationError('inviteUserToEvent', t.array(UserEventInvite), data);
  return data;
};

export const resendEventInviteToUser = async (corporateId: string, inviteId: string) => {
  const { data } = await restaurantApiAgent.get(
    `/v1/waw-api/corporates/${corporateId}/user-invites/${inviteId}/resend`,
  );
  return data;
};

export type FetchEventUsersParams = {
  page: number;
  page_size: number;
  email?: string;
  is_expired?: boolean;
  statuses?: string[];
};

export const fetchEventUsers = async (
  corporateId: string,
  eventId: string,
  fetchEventUsersParams: FetchEventsParams,
) => {
  const { data } = await restaurantApiAgent.get<PaginatedData<EventUser>>(
    `/v1/waw-api/corporates/${corporateId}/events/${eventId}/participants`,
    {
      params: fetchEventUsersParams,
    },
  );
  logValidationError('fetchEventUsers', t.array(EventUser), data.results);
  return data;
};

export const fetchEventUsage = async (corporateId: string, eventId: string) => {
  const { data } = await restaurantApiAgent.get<EventUsage>(
    `/v1/waw-api/corporates/${corporateId}/events/${eventId}/usage`,
  );
  logValidationError('fetchEventUsage', EventUsage, data);
  return data;
};

export const fetchEventStatistics = async (corporateId: string, eventId: string) => {
  const { data } = await restaurantApiAgent.get<EventStatistics>(
    `/v1/waw-api/corporates/${corporateId}/events/${eventId}/statistics`,
  );
  logValidationError('fetchEventStatistics', EventStatistics, data);
  return data;
};

export const deleteEventUserInvite = async (corporateId: string, inviteId: string) => {
  const { data } = await restaurantApiAgent.delete<ResponseStatus>(
    `/v1/waw-api/corporates/${corporateId}/user-invites/${inviteId}`,
  );
  logValidationError('deleteEventUser', ResponseStatus, data);
  return data;
};

export const deleteEventUser = async (corporateId: string, eventId: string, inviteId: string) => {
  const { data } = await restaurantApiAgent.delete(
    `v1/waw-api/corporates/${corporateId}/events/${eventId}/participants/${inviteId}`,
  );
  logValidationError('deleteEventUser', ResponseStatus, data);
  return data;
};

export const sendEventReminder = async (corporateId: string, eventId: string) => {
  const { data } = await restaurantApiAgent.get<ResponseStatus>(
    `/v1/waw-api/corporates/${corporateId}/events/${eventId}/resend-pending-invites`,
  );
  logValidationError('sentEventReminder', ResponseStatus, data);
  return data;
};
