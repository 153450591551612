import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { TextButton } from '@creditornot/cb-button';
import { WindowNew } from '@creditornot/cb-icons';
import { useCallback, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';
import { FormattedMessage } from 'react-intl';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { env } from 'config';
import { event } from 'modules/google-analytics';
import { useCorporate, useCorporateDeliverySettingsComplete } from 'modules/corporates';
import { USER_GUIDE_SELECTOR } from 'onboarding/selectors';
import { useI18n } from 'i18n';
import { woltDriveEmail, woltAtWorkEmail, createFeedbackHrefFrom } from 'navigation/utils';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { useFetchUserCorporateRole } from 'modules/users';
import { resources } from 'modules/resources';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';
import { homeInteractionClickLink } from 'telemetry/Avo';
import { useGetCorporateTelemetrySource } from 'telemetry/hooks/useGetCorporateTelemetrySource';

const StyledTextButton = styled(TextButton)`
  padding-inline-end: 8px;
  margin-top: 12px;
  display: block;
  color: ${color.textSubdued};
`;

const Guide = styled(TextButton)`
  /* Negative margin for better onboarding UI */
  padding: 8px;
  margin-inline-start: -8px;
  margin-bottom: -8px;
  display: block;
  color: ${color.textSubdued};
`;

export const Guidance = () => {
  const { user } = useAuthState();
  const { data: corporate } = useCorporate();
  const { showMessages } = useIntercom();
  const { getLocalizedMessage } = useI18n();
  const homeInteractionClickEvent = useTelemetryInteractionEvent(homeInteractionClickLink);
  const telemetrySource = useGetCorporateTelemetrySource();

  const { isWoltEmployee } = useIsUserWoltEmployee();
  const { data: corporateRoleData } = useFetchUserCorporateRole();
  const isManager = useMemo(
    () => (corporateRoleData && corporateRoleData[0]?.role === 'corporate_manager' ? true : false),
    [corporateRoleData],
  );
  const wfwEnabled = corporate?.products.waw_enabled;
  const woltDriveEnabled = corporate?.products.daas_delivery_ui_enabled;
  const { data: deliverySettingsComplete } = useCorporateDeliverySettingsComplete(corporate?.id, {
    enabled: woltDriveEnabled,
  });

  const handleGoToWoltClick = useCallback(() => {
    event({
      category: 'admin_portal',
      action: 'click',
      label: 'goto_wolt.com',
    });
    window.open(env.WOLT_COM_URI);
  }, []);
  const handleGoToWoltDeliveryClick = useCallback(() => {
    event({
      category: 'admin_portal',
      action: 'click',
      label: 'goto_wolt_delivery.com',
    });
    corporate
      ? window.open(`${env.DAAS_SELF_SERVICE_URI}/${corporate.id}/home`)
      : window.open(env.DAAS_SELF_SERVICE_URI);
  }, [corporate]);
  const handleOpenWAWUserGuideClick = useCallback(() => {
    event({
      category: 'admin_portal',
      action: 'click',
      label: 'open_admin_user_guide',
    });
    window.open(resources.guides.wfw_guide);
  }, []);
  const handleGiveFeedbackLinkClick = useCallback(() => {
    // email to the feedback email
    event({
      category: 'admin_portal',
      action: 'click',
      label: 'give_feedback',
    });
    window.open(createFeedbackHrefFrom(corporate?.name || '', woltDriveEnabled ? 'daas' : 'waw'));
  }, [corporate, woltDriveEnabled]);
  const handleOrderSupportClick = useCallback(() => {
    // Only send telemetry event if the source is valid
    if (telemetrySource) {
      homeInteractionClickEvent({ source: telemetrySource });
    }
    showMessages();
  }, [homeInteractionClickEvent, showMessages, telemetrySource]);
  const handleContactSupportClick = useCallback(() => {
    event({
      category: 'admin_portal',
      action: 'click',
      label: 'contact_support',
    });

    if (isWoltEmployee || (isManager && woltDriveEnabled)) {
      showMessages();
      return;
    }

    if (woltDriveEnabled) {
      window.open(`mailto:${woltDriveEmail}`);
      return;
    }

    window.open(`mailto:${woltAtWorkEmail}`);
  }, [isWoltEmployee, showMessages, woltDriveEnabled, isManager]);

  if (!corporate || !user) {
    return null;
  }

  return (
    <>
      {!woltDriveEnabled ? (
        <Guide
          onClick={handleOpenWAWUserGuideClick}
          data-product-tour={USER_GUIDE_SELECTOR}
          variant="grey"
        >
          <FormattedMessage id="navigation.help-document" />
        </Guide>
      ) : null}
      <StyledTextButton onClick={handleGiveFeedbackLinkClick} variant="grey">
        <FormattedMessage id="navigation.give-feedback" />
      </StyledTextButton>

      {wfwEnabled ? (
        <StyledTextButton
          data-test-id="order-support-button"
          onClick={handleOrderSupportClick}
          variant="grey"
        >
          <FormattedMessage id="navigation.order-support" />
        </StyledTextButton>
      ) : (
        <StyledTextButton onClick={handleContactSupportClick} variant="grey">
          <FormattedMessage id="navigation.support" />
        </StyledTextButton>
      )}
      {!woltDriveEnabled ? (
        <StyledTextButton
          onClick={handleGoToWoltClick}
          iconPosition="right"
          icon={<WindowNew />}
          variant="grey"
        >
          <FormattedMessage id="navigation.wolt-link" />
        </StyledTextButton>
      ) : (
        <StyledTextButton
          disabled={!deliverySettingsComplete}
          onClick={handleGoToWoltDeliveryClick}
          iconPosition="right"
          icon={<WindowNew />}
          variant="grey"
          title={
            deliverySettingsComplete === true
              ? undefined
              : getLocalizedMessage('navigation.wolt-drive-link-disabled-title')
          }
        >
          <FormattedMessage id="navigation.wolt-drive-link" />
        </StyledTextButton>
      )}
    </>
  );
};
