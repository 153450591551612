import { LocalizedMessage } from 'i18n';
import { ModalV2, ModalHeader } from 'components';

import { CreateCorporateForm } from './CreateCorporateForm';

interface CreateCorporateModalProps {
  show: boolean;
  onClose: () => void;
  selectedCountryCode?: string;
}

const CreateCorporateModal = ({ show, onClose, ...rest }: CreateCorporateModalProps) => {
  return (
    <ModalV2
      disableDismissOnOutsidePress
      open={show}
      onDismiss={onClose}
      sizePreset={{
        desktop: 'centered:large',
        mobile: 'fullScreen',
      }}
      title="Create corporate"
      HeaderComponent={() => (
        <ModalHeader onClose={onClose} borderBottom>
          <LocalizedMessage messageKey="corporate-form.title" />
        </ModalHeader>
      )}
    >
      <CreateCorporateForm onClose={onClose} {...rest} />
    </ModalV2>
  );
};

export default CreateCorporateModal;
