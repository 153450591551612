import { useEditCorporate } from 'modules/corporates';
import { EditCorporateFormValues } from 'views/settings/CompanySettings/types';

import { formatFormValuesBeforeSending } from './formatFormValues';
import { Corporate } from './types';

type FormValues = Pick<EditCorporateFormValues, 'contact_name' | 'contact_email' | 'contact_phone'>;

export const useEditContactInformation = (corporate: Corporate) => {
  const editCorporate = useEditCorporate();

  return (initialValues: FormValues, newValues: FormValues) =>
    editCorporate(formatFormValuesBeforeSending(initialValues, newValues, corporate.currency));
};
