import styled from 'styled-components';

import { breakpoints } from 'modules/media';

const ResponsiveContainer = styled.div`
  display: flex;
  width: 100%;
  padding-inline: 24px;

  @media (max-width: ${breakpoints.medium}px) {
    padding-inline: 16px;
  }
`;

export default ResponsiveContainer;
