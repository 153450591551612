import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { useIntl } from 'react-intl';
import { Formik, FormikHelpers } from 'formik';
import { Button } from '@creditornot/cb-button';
import { useCallback } from 'react';
import { Alert } from '@creditornot/cb-alert';

import { Corporate } from 'modules/corporates/types';
import {
  formatFormValuesBeforeSending,
  formatCorporateDataToFormValues,
} from 'modules/corporates/formatFormValues';
import { EditCorporateFormValues } from 'views/settings/CompanySettings/types';
import { EditCorporateData } from 'modules/corporates/api';
import { ScrollToFieldError } from 'components/FormikScrollToFieldError/FormikScrollToFieldError';
import { processError } from 'utils';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { billingInformationView, billingInformationDataSubmit, EventName } from 'telemetry/Avo';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';

import { CompanyInformationForm } from '../components/CompanyInformation/CompanyInformationForm';
import { SignupProgress } from '../components/SignupProgress/SignupProgress';
import { ContactPersonForm } from '../components/ContactPerson/ContactPersonForm';
import { InvoicesAndReceiptsForm } from '../components/InvoicesAndReceipts/InvoicesAndReceiptsForm';
import { hasChangedInvoicingDefaults } from './telemetry.utils';

type Props = {
  corporate: Corporate;
  isFetchingCorporate: boolean;
  onSubmit: (values: EditCorporateData) => Promise<void>;
};

const SecondaryText = styled.span`
  ${typographyCss.Body3()};
  display: flex;
  margin-inline: auto;
  margin-top: 16px;
  color: ${color.textSubdued};
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const StyledSignupProgress = styled(SignupProgress)`
  margin-inline: auto;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SignupV2Step1 = ({ corporate, isFetchingCorporate, onSubmit }: Props) => {
  const { formatMessage } = useIntl();

  const initialValues = formatCorporateDataToFormValues(corporate);
  const billingInformationDataSubmitEvent = useTelemetryInteractionEvent(
    billingInformationDataSubmit,
  );

  const handleSubmit = useCallback(
    async (values: EditCorporateFormValues, actions: FormikHelpers<EditCorporateFormValues>) => {
      try {
        const submitValues = formatFormValuesBeforeSending(
          initialValues,
          values,
          corporate.currency,
        );
        // we agreed with backend that we manually patch this status
        await onSubmit({ ...submitValues, status: 'contract_pending' });
        billingInformationDataSubmitEvent({
          eventName: EventName.SIGNUP,
          contactPersonDefaultsChanged: !!submitValues.contact_name,
          invoicingDefaultsChanged: hasChangedInvoicingDefaults(submitValues),
        });
        actions.setSubmitting(false);
        actions.setStatus({ submitSucceeded: true });
        actions.resetForm();
      } catch (error) {
        actions.setSubmitting(false);
        actions.setStatus({ error });
      }
    },
    [billingInformationDataSubmitEvent, corporate.currency, initialValues, onSubmit],
  );

  return (
    <>
      <ViewEventComponent event={billingInformationView} />
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ submitForm, isSubmitting, status }) => (
          <Root>
            <ScrollToFieldError />
            <StyledSignupProgress activeIndex={1} />
            <SecondaryText>{formatMessage({ id: 'signup.step1.subtitle' })}</SecondaryText>
            <CompanyInformationForm
              renderMode="signup"
              isWoltEmployee={false}
              corporate={corporate}
            />
            <ContactPersonForm renderMode="signup" />
            <InvoicesAndReceiptsForm
              renderMode="signup"
              isWoltEmployee={false}
              corporate={corporate}
              countryConfig={undefined}
            />
            {status?.error && (
              <Alert variant="error" size="medium" title={processError(status.error).message}>
                {processError(status.error).data}
              </Alert>
            )}
            <SubmitContainer>
              <Button
                style={{ minWidth: '320px' }}
                onClick={submitForm}
                variant="blue"
                type="submit"
                size="large"
                disabled={isSubmitting || status?.submitSucceeded || isFetchingCorporate}
                loading={isSubmitting}
              >
                {formatMessage({ id: 'common.submit' })}
              </Button>
            </SubmitContainer>
          </Root>
        )}
      </Formik>
    </>
  );
};
