import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useRef } from 'react';
import { typographyCss } from '@creditornot/cb-ingredients';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { LocalizedMessage, LocalizedNumber } from 'i18n';
import { useCorporate } from 'modules/corporates';
import { EventStatistics } from 'modules/events/types';
import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';

type Props = {
  className?: string;
  eventStatistics?: EventStatistics;
};

const Budgets = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BudgetItem = styled.span`
  ${typographyCss.Body3()}
`;

const ProgressBar = styled.div`
  margin-top: 12px;
  margin-bottom: 8px;
  background-color: ${color.border};
  border-radius: 8px;
  height: 8px;
  width: 100%;
  position: relative;
`;

const ProgressGauge = styled(motion.div)`
  position: absolute;
  top: 0;
  ${cssDirectionalValue({ ltr: 'left: 0px;', rtl: 'right: 0px;' })}
  background-color: ${color.bgFillPositive};
  border-radius: inherit;
  height: inherit;
`;

export const EventBudgetUsage = ({ eventStatistics, className }: Props) => {
  const { data: corporate } = useCorporate();
  const prevTotalPercentage = useRef(0);
  const purchaseTotalAmount = eventStatistics?.purchase_total_amount ?? 0;
  const totalBudget = eventStatistics?.total_budget_amount || 0;
  const totalPercentage = ((purchaseTotalAmount ?? 0) * 100) / totalBudget || 0;

  return (
    <div className={className}>
      <ProgressBar>
        <ProgressGauge
          onAnimationComplete={() => (prevTotalPercentage.current = totalPercentage)}
          animate={{
            width: [`${prevTotalPercentage.current}%`, `${totalPercentage}%`],
            originX: 'left',
          }}
          transition={{
            easings: 'easeInOut',
            duration: 0.8,
          }}
        />
      </ProgressBar>
      <Budgets>
        <BudgetItem>
          <LocalizedMessage
            messageKey="events.event-details.used-budget"
            values={{
              amount: (
                <LocalizedNumber value={purchaseTotalAmount} currency={corporate?.currency} />
              ),
            }}
          />
        </BudgetItem>
        <BudgetItem>
          <LocalizedNumber value={totalBudget} currency={corporate?.currency} />
        </BudgetItem>
      </Budgets>
    </div>
  );
};
