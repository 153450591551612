import { FormikInputField } from 'components';
import { createValidator, isRequired, isEmail } from 'validation';
import { FormikInputFieldProps } from 'components/FormikInputField';

const validate = createValidator([isRequired, isEmail]);

interface Props extends Omit<FormikInputFieldProps, 'name' | 'type' | 'required' | 'validate'> {
  alwaysShowError?: boolean;
}

export const InvoicingEmailField = ({ alwaysShowError, ...rest }: Props) => {
  return (
    <FormikInputField
      name="invoice_email"
      type="email"
      required={true}
      validate={validate}
      alwaysShowError={alwaysShowError}
      {...rest}
    />
  );
};
