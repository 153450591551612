import styled from 'styled-components';
import { InputContainer } from '@creditornot/cb-form';
import { Alert } from '@creditornot/cb-alert';
import { FC } from 'react';

import { FormikInlineForm } from 'components';
import { LocalizedMessage, LocalizedNumber, useI18n } from 'i18n';
import { Event } from 'modules/events/types';
import { useEditEvent } from 'modules/events';
import { shallowObjectDifferences } from 'utils';
import { FormikInlineFormProps } from 'components/FormikInlineForm';

import {
  formatEventDataToEditUpComingEventFormValues,
  formatEditUpcomingEventFormValuesToApiData,
} from './utils';
import { FormikDateErrorAlert } from './FormikDateErrorAlert';
import {
  FormikEventDescriptionTextarea,
  FormikEventEndDateInputSelect,
  FormikEventEndTimeInputSelect,
  FormikEventParticipantsBudgetInput,
  FormikEventStartDateInputSelect,
  FormikEventStartTimeInputSelect,
} from './EventFormFields';
import { EditUpComingEventFormData } from './types';
import { useValidateEventDate } from './hooks';
import { EventDateTime } from './EventDateTime';

interface Props {
  onSuccess?: (event: Event) => void;
  eventData: Event;
  className?: string;
  currency: string;
}

const StyledFormikEventStartDateInputSelect = styled(FormikEventStartDateInputSelect)`
  width: 60%;
`;

const StyledFormikEventStartTimeInputSelect = styled(FormikEventStartTimeInputSelect)`
  margin-inline-start: 16px;
  width: 40%;
`;

const StyledFormikEventEndDateInputSelect = styled(FormikEventEndDateInputSelect)`
  width: 60%;
`;

const StyledFormikEventEndTimeInputSelect = styled(FormikEventEndTimeInputSelect)`
  width: 40%;
  margin-inline-start: 16px;
`;

const StyledInputContainer = styled(InputContainer)`
  margin-top: 16px;
`;

const StyledFormikDateErrorAlert = styled(FormikDateErrorAlert)`
  margin-top: 16px;
`;

const InputContainerContent = styled.div`
  display: flex;
`;

const StlyedAlert = styled(Alert)`
  margin-top: 16px;
`;

const StyledFormikInlineForm = styled(FormikInlineForm)`
  padding-top: 0;
`;

const FormikEditEventTimeInlineFormContent = () => {
  const { getLocalizedMessage } = useI18n();
  const { endDateError, startDateError } = useValidateEventDate();
  return (
    <>
      <Alert size="small" variant="info">
        {getLocalizedMessage('events.create-event-form.event-time-info-banner')}
      </Alert>

      <StyledFormikDateErrorAlert
        alwaysShowError
        endDateError={endDateError}
        startDateError={startDateError}
      />

      <StyledInputContainer
        label={getLocalizedMessage('events.create-event-form.event-time-starting-date')}
      >
        <InputContainerContent>
          <StyledFormikEventStartDateInputSelect
            alwaysShowError
            datePickerInputProps={startDateError ? { invalid: true } : undefined}
          />
          <StyledFormikEventStartTimeInputSelect
            alwaysShowError
            timeSelectProps={startDateError ? { invalid: true } : undefined}
          />
        </InputContainerContent>
      </StyledInputContainer>

      <StyledInputContainer
        label={getLocalizedMessage('events.create-event-form.event-time-ending-date')}
      >
        <InputContainerContent>
          <StyledFormikEventEndDateInputSelect
            alwaysShowError
            datePickerInputProps={endDateError ? { invalid: true } : undefined}
          />
          <StyledFormikEventEndTimeInputSelect
            alwaysShowError
            timeSelectProps={endDateError ? { invalid: true } : undefined}
          />
        </InputContainerContent>
      </StyledInputContainer>
    </>
  );
};

export const EditUpComingEventForm: React.FC<Props> = ({
  onSuccess,
  eventData,
  className,
  currency,
}) => {
  const editEvent = useEditEvent();
  const initialValues = formatEventDataToEditUpComingEventFormValues(eventData, currency);
  const handleSubmitParticipantBudget = async (values: EditUpComingEventFormData) => {
    const oldValues = formatEditUpcomingEventFormValuesToApiData(initialValues, currency);
    const newValues = formatEditUpcomingEventFormValuesToApiData(values, currency);
    const updatedValues = shallowObjectDifferences(newValues, oldValues);
    const newEvent = await editEvent(eventData.id, {
      participant_budget_amount: updatedValues.participant_budget_amount,
    });

    setTimeout(() => {
      onSuccess?.(newEvent);
    }, 500);
  };
  const handleSubmitEventTime = async (values: EditUpComingEventFormData) => {
    const oldValues = formatEditUpcomingEventFormValuesToApiData(initialValues, currency);
    const newValues = formatEditUpcomingEventFormValuesToApiData(values, currency);
    const updatedValues = shallowObjectDifferences(newValues, oldValues);
    const newEvent = await editEvent(eventData.id, {
      start_time: updatedValues.start_time,
      end_time: updatedValues.end_time,
    });

    setTimeout(() => {
      onSuccess?.(newEvent);
    }, 500);
  };
  const handleSubmitEventDescription = async (values: EditUpComingEventFormData) => {
    const oldValues = formatEditUpcomingEventFormValuesToApiData(initialValues, currency);
    const newValues = formatEditUpcomingEventFormValuesToApiData(values, currency);
    const updatedValues = shallowObjectDifferences(newValues, oldValues);
    const newEvent = await editEvent(eventData.id, {
      description: updatedValues.description,
    });

    setTimeout(() => {
      onSuccess?.(newEvent);
    }, 500);
  };

  return (
    <div className={className}>
      {/* https://github.com/DefinitelyTyped/DefinitelyTyped/issues/39136#issuecomment-719950054 */}
      <StyledFormikInlineForm<FC<FormikInlineFormProps<typeof initialValues>>>
        label={<LocalizedMessage messageKey="events.event-details.event-time-label" />}
        initialValues={initialValues}
        onSubmit={handleSubmitEventTime}
      >
        {({ isEditing }) =>
          isEditing ? (
            <FormikEditEventTimeInlineFormContent />
          ) : (
            <>
              <LocalizedMessage
                messageKey="events.event-details.event-date"
                values={{
                  startDate: <EventDateTime dateTime={eventData.start_time} />,
                  endDate: <EventDateTime dateTime={eventData.end_time} />,
                }}
              />
              <StlyedAlert size="small" variant="info">
                <LocalizedMessage messageKey="events.event-details.event-time-info-message" />
              </StlyedAlert>
            </>
          )
        }
      </StyledFormikInlineForm>

      <FormikInlineForm
        label={<LocalizedMessage messageKey="events.event-details.budget" />}
        initialValues={initialValues}
        onSubmit={handleSubmitParticipantBudget}
      >
        {({ isEditing }) =>
          isEditing ? (
            <FormikEventParticipantsBudgetInput currency={currency} />
          ) : (
            <LocalizedNumber value={eventData.participant_budget_amount} currency={currency} />
          )
        }
      </FormikInlineForm>

      <FormikInlineForm
        label={<LocalizedMessage messageKey="events.create-event-form.description" />}
        initialValues={initialValues}
        onSubmit={handleSubmitEventDescription}
      >
        {({ isEditing }) =>
          isEditing ? (
            <FormikEventDescriptionTextarea alwaysShowError />
          ) : eventData.description.trim().length === 0 ? (
            '–'
          ) : (
            eventData.description
          )
        }
      </FormikInlineForm>
    </div>
  );
};
