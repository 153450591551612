import { GetCorporateResponse } from '@creditornot/wd-api-client';
import { Alert } from '@creditornot/cb-alert';
import { InputContainer } from '@creditornot/cb-form';
import { IntlFormatters, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ReactNode, useState } from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { FormViewText } from 'components';
import { ReactHookInput } from 'components/ReactHookInput';
import { processError } from 'utils';
import { FormCard, FormRow, FormSaveCancelButtons, FormStates } from 'components/FormCard';
import { useWoltForm } from 'modules/react-hook-form/useWoltForm';

import { CustomerSupportContactInformationFormValues } from './types';

const allValuesAreEmpty = (values: CustomerSupportContactInformationFormValues) => {
  return !values.email && !values.phoneNumber && !values.url;
};

const customerSupportContactInformationYup: (
  t: IntlFormatters<ReactNode>,
) => yup.Schema<CustomerSupportContactInformationFormValues> = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(t.formatMessage({ id: 'validation.is_email' })),
    phoneNumber: yup
      .string()
      .test(
        'isValidPhoneNumber',
        t.formatMessage({ id: 'validation.is_valid_phone_number' }),
        (value) => {
          if (!value) {
            return true;
          }
          return isValidPhoneNumber(value);
        },
      ),
    url: yup.string().url(t.formatMessage({ id: 'validation.is_valid_url' })),
  });
};

export const CustomerSupportContactInformationForm = ({
  selfServiceCorporate,
  renderState,
  onSubmit,
  onRenderStateChange,
}: {
  selfServiceCorporate: GetCorporateResponse;
  renderState: FormStates;
  onSubmit: (values: CustomerSupportContactInformationFormValues) => Promise<void>;
  onRenderStateChange: (state: FormStates) => void;
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [apiError, setApiError] = useState<unknown | null>(null);

  const {
    register,
    formState: { isDirty, isSubmitting, errors, touchedFields: touched },
    getValues,
    reset,
    handleSubmit,
  } = useWoltForm<CustomerSupportContactInformationFormValues>({
    resolver: yupResolver(customerSupportContactInformationYup(intl)),
    defaultValues: {
      email: selfServiceCorporate.email,
      phoneNumber: selfServiceCorporate.phoneNumber,
      url: selfServiceCorporate.url,
    },
  });

  const formIsTouched = touched.email || touched.phoneNumber || touched.url;

  const values = getValues();

  const formIsInvalid = allValuesAreEmpty(values) && formIsTouched;

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        if (formIsInvalid) {
          return;
        }
        setApiError(null);
        try {
          await onSubmit(data);
          reset(data);
          setApiError(null);
        } catch (error) {
          setApiError(processError(error));
        }
      })}
    >
      <FormCard
        title={formatMessage({ id: 'deliveries.settings-form.customer-support-section-title' })}
        renderState={renderState}
        onRenderStateChange={onRenderStateChange}
      >
        <FormRow title={formatMessage({ id: 'common.email' })}>
          <FormRow.ViewMode>
            <FormViewText>{selfServiceCorporate.email || '-'}</FormViewText>
          </FormRow.ViewMode>

          <FormRow.EditMode>
            <InputContainer
              hint={formatMessage({ id: 'deliveries.settings-form.contact-info-email-subtitle' })}
            >
              <ReactHookInput
                register={register('email')}
                errorMessage={errors.email?.message}
                inputProps={{ invalid: formIsInvalid || !!errors.email?.message }}
              />
            </InputContainer>
          </FormRow.EditMode>
        </FormRow>

        <FormRow title={formatMessage({ id: 'common.phone-number' })}>
          <FormRow.ViewMode>
            <FormViewText>{selfServiceCorporate.phoneNumber || '-'}</FormViewText>
          </FormRow.ViewMode>

          <FormRow.EditMode>
            <InputContainer
              hint={formatMessage({ id: 'deliveries.settings-form.contact-info-phone-subtitle' })}
            >
              <ReactHookInput
                register={register('phoneNumber')}
                errorMessage={errors.phoneNumber?.message}
                inputProps={{ invalid: formIsInvalid || !!errors.phoneNumber?.message }}
              />
            </InputContainer>
          </FormRow.EditMode>
        </FormRow>
        <FormRow title={formatMessage({ id: 'deliveries.settings-form.url' })}>
          <FormRow.ViewMode>
            <FormViewText>{selfServiceCorporate.url || '-'}</FormViewText>
          </FormRow.ViewMode>

          <FormRow.EditMode>
            <InputContainer
              hint={formatMessage({ id: 'deliveries.settings-form.contact-info-url-subtitle' })}
            >
              <ReactHookInput
                register={register('url')}
                errorMessage={errors.url?.message}
                inputProps={{ invalid: formIsInvalid || !!errors.url?.message }}
              />
            </InputContainer>
          </FormRow.EditMode>
        </FormRow>
        {formIsInvalid && (
          <InputContainer>
            <Alert variant="error" size="small">
              {formatMessage({
                id: 'validation.add-at-least-one-customer-support-contact-info-type',
              })}
            </Alert>
          </InputContainer>
        )}
        {!!apiError && (
          <Alert variant="error" size="medium" title={processError(apiError).message}>
            {processError(apiError).data}
          </Alert>
        )}
        <FormRow>
          <FormRow.EditMode>
            <FormSaveCancelButtons
              dirty={isDirty}
              isSubmitting={isSubmitting}
              onCancelClick={() => {
                setApiError(null);
                onRenderStateChange('view');
                reset();
              }}
            />
          </FormRow.EditMode>
        </FormRow>
      </FormCard>
    </form>
  );
};
