import { useField } from 'formik';
import { Switch } from '@creditornot/cb-switch';
import { useCallback } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { typographyCss } from '@creditornot/cb-ingredients';

const StyledSwitch = styled(Switch)`
  display: flex;
  align-items: center;

  label {
    flex-direction: row-reverse;
    gap: 8px;
  }

  span {
    margin-inline-start: 0;
  }
`;

const Label = styled(FormattedMessage)`
  ${typographyCss.Body3()}
`;

export const PolicyAllDayDeliveryField = () => {
  const [{ value }, , { setValue }] = useField<boolean>('all_day_delivery');
  const handleSwitchClick = useCallback(() => {
    setValue(!value);
  }, [setValue, value]);

  return (
    <StyledSwitch onChange={handleSwitchClick} checked={value}>
      <Label id="policies.all-day" />
    </StyledSwitch>
  );
};
