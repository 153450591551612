import { Button, TextButton } from '@creditornot/cb-button';
import { colors, typographyCss } from '@creditornot/cb-ingredients';
import { rem } from 'polished';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { useHistory } from 'react-router';

import { useI18n } from 'i18n';

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem('24px')};
  text-align: center;
  background-color: ${colors.wolt4};
  border-radius: ${rem('8px')};
`;

const MessageDescription = styled.span`
  ${typographyCss.Body3()}
  max-width: ${rem('480px')};
  padding: ${rem('16px')} 0;
`;

const StyledHeading = styled.h5`
  ${typographyCss.Heading5()}
`;

const ContactSupportButton = styled(TextButton)`
  margin-top: ${rem('16px')};
`;

type Props = {
  corporateId: string;
};

export const FinalizeSettingsMessage: React.FC<Props> = ({ corporateId }) => {
  const { getLocalizedMessage } = useI18n();
  const { showMessages } = useIntercom();
  const history = useHistory();

  const handleAddContactDetailsClick = useCallback(() => {
    history.push(`/${corporateId}/settings/delivery-settings`);
  }, [history, corporateId]);

  return (
    <MessageContainer>
      <StyledHeading>
        {getLocalizedMessage('deliveries.additional-delivery-settings.title')}
      </StyledHeading>

      <MessageDescription>
        {getLocalizedMessage('deliveries.additional-delivery-settings.description')}
      </MessageDescription>

      <Button size="small" variant="blue" onClick={handleAddContactDetailsClick}>
        {getLocalizedMessage('deliveries.additional-delivery-settings.add-contact-details')}
      </Button>

      <ContactSupportButton onClick={showMessages}>
        {getLocalizedMessage('deliveries.additional-delivery-settings.contact-support')}
      </ContactSupportButton>
    </MessageContainer>
  );
};
