import { color } from '@creditornot/cb-ingredients/design-tokens';
import { ChangeEventHandler, useCallback, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { MagnifyingGlass } from '@creditornot/cb-icons';
import { TextButton } from '@creditornot/cb-button';
import styled from 'styled-components';
import { Empty } from '@creditornot/cb-placeholders';
import { Input } from '@creditornot/cb-input';
import { FormattedMessage, useIntl } from 'react-intl';

import { fetchSubscriptionUserIds } from 'modules/subscription/api';
import { ResponsiveContainer, CompanyUsersList } from 'components';
import { useDebounce } from 'modules/hooks';
import { useFetch } from 'modules/react-query';
import { fetchAllUsers } from 'modules/users';
import { UserV2BasicInfo } from 'modules/users/types';

type Props = {
  corporateId: string;
  subscriptionId: string;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

const Header = styled.div``;

const Row = styled(ResponsiveContainer)`
  padding-bottom: 16px;
  justify-content: space-between;

  & + & {
    border-top: 1px solid ${color.border};
  }
`;

const StyledEmpty = styled(Empty)`
  padding-top: 32px;
  padding-bottom: 24px;
`;

export const InviteExistingUserForm = ({ corporateId, subscriptionId }: Props) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search.toLowerCase(), 300);
  const { formatMessage } = useIntl();

  const [{ value: userIds }, , { setValue: setUserIds }] = useField<string[]>('userIds');

  const {
    data: companyUsers,
    isFetching: isFetchingCompanyUsers,
    error: companyUsersError,
  } = useFetch({
    queryKey: ['company-users', corporateId],
    queryFn: () => fetchAllUsers(corporateId),
  });

  const {
    data: subscriptionUsers,
    isFetching: isFetchingSubscriptionUsers,
    error: subscriptionUsersError,
  } = useFetch({
    queryKey: ['wolt-plus-users', corporateId],
    queryFn: () => fetchSubscriptionUserIds(corporateId, subscriptionId),
  });

  const { status, setStatus } = useFormikContext();

  const handleSearch: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setSearch(e.target.value),
    [],
  );

  const handleClearClick = useCallback(() => setSearch(''), []);

  const noCompanyUsers = companyUsers?.length === 0 && !isFetchingCompanyUsers;

  const selectableUsers = companyUsers?.filter(
    (user) => !subscriptionUsers?.some((subscriptionUser) => subscriptionUser === user.user_id),
  );

  const allUsers = companyUsers?.filter((user) => {
    const fullName = `${user.name.first} ${user.name.last}`;
    return fullName.toLowerCase().includes(debouncedSearch) || user.email.includes(debouncedSearch);
  });

  const allMembersSelected = selectableUsers?.length === userIds?.length;

  const error = companyUsersError || subscriptionUsersError;

  const isLoading = isFetchingCompanyUsers || isFetchingSubscriptionUsers;

  const noMembersLeftToAdd = !isLoading && selectableUsers?.length === 0;

  const handleCloseErrorAlertClick = useCallback(
    () => setStatus({ ...status, error: undefined }),
    [setStatus, status],
  );

  const getDisabledUser = useCallback(
    (user: UserV2BasicInfo) =>
      !!subscriptionUsers?.some((subscriptionUser) => subscriptionUser === user.user_id),
    [subscriptionUsers],
  );

  const getSelectedUser = useCallback(
    (user: UserV2BasicInfo) => !!userIds?.some((addedUserId) => addedUserId === user.user_id),
    [userIds],
  );

  const handleUserClick = useCallback(
    (user: UserV2BasicInfo) => {
      const isSelected = getSelectedUser(user);
      if (isSelected) {
        setUserIds(userIds?.filter((addedUserId) => addedUserId !== user.user_id));
        return;
      }

      setUserIds(userIds?.concat(user.user_id));
    },
    [getSelectedUser, setUserIds, userIds],
  );

  const handleDeselectAllUsersClick = useCallback(() => setUserIds([]), [setUserIds]);

  const handleSelectAllUsersClick = useCallback(
    () => setUserIds((selectableUsers || []).map((user) => user.user_id)),
    [selectableUsers, setUserIds],
  );

  if (noMembersLeftToAdd || noCompanyUsers) {
    return (
      <Root>
        {noCompanyUsers ? (
          <StyledEmpty
            title={formatMessage({ id: 'wolt-plus.invite-modal.no-members.title' })}
            description={formatMessage({ id: 'wolt-plus.invite-modal.no-members.description' })}
          />
        ) : (
          noMembersLeftToAdd && (
            <StyledEmpty
              title={formatMessage({ id: 'wolt-plus.invite-modal.all-members-are-added' })}
            />
          )
        )}
      </Root>
    );
  }

  return (
    <Root>
      <Header>
        {selectableUsers && (
          <Row>
            <div>
              <FormattedMessage
                id="wolt-plus.invite-modal.selected-users"
                values={{
                  selectMember: userIds?.length || 0,
                  numberOfMember: selectableUsers.length,
                  b: (text) => <strong>{text}</strong>,
                }}
              />
            </div>

            {allMembersSelected ? (
              <TextButton onClick={handleDeselectAllUsersClick}>
                <FormattedMessage id="wolt-plus.invite-modal.deselect-all" />
              </TextButton>
            ) : (
              <TextButton onClick={handleSelectAllUsersClick}>
                <FormattedMessage id="wolt-plus.invite-modal.select-all" />
              </TextButton>
            )}
          </Row>
        )}
        <Row style={{ paddingTop: 16 }}>
          <Input
            icon={<MagnifyingGlass />}
            placeholder={formatMessage({ id: 'common.search' })}
            value={search}
            onChange={handleSearch}
            onClearClick={handleClearClick}
          />
        </Row>
      </Header>

      <CompanyUsersList
        isLoading={isLoading}
        error={error}
        emptyMessage={formatMessage(
          { id: 'wolt-plus.invite-modal.no-search-results' },
          { searchTerm: search },
        )}
        onUserClick={handleUserClick}
        onCloseErrorAlertClick={handleCloseErrorAlertClick}
        allUsers={allUsers}
        getDisabledUser={getDisabledUser}
        getSelectedUser={getSelectedUser}
        disabledUserText={formatMessage({ id: 'wolt-plus.invite-modal.already-invited' })}
      />
    </Root>
  );
};
