import styled from 'styled-components';

import { LocalizedMessage } from 'i18n';
import { Policy } from 'modules/policies/types';

import * as BudgetRestriction from './BudgetRestriction';
import { LocationsRestriction } from './LocationsRestriction';
import * as TimesRestriction from './TimesRestriction';
import { AccountingNoteRequirement } from './AccountingNoteRequirement';

interface Props {
  policy: Policy;
  className?: string;
}

const Root = styled.div`
  display: inline;
`;

const BudgetRestrictionContainer = styled.span`
  display: inline-flex;
`;

export const PolicyRestrictions: React.FC<Props> = ({ policy, className }) => {
  const noRestriction =
    !policy.budget_allowance &&
    !policy.order_comment_required &&
    !policy.delivery_areas.length &&
    !policy.times.length;

  return (
    <Root className={className}>
      {noRestriction ? (
        <LocalizedMessage messageKey="common.no-restrictions" />
      ) : (
        [
          policy.budget_allowance && (
            <BudgetRestrictionContainer key="BudgetRestriction">
              <BudgetRestriction.Content
                amount={policy.budget_allowance.amount}
                currency={policy.budget_allowance.currency}
              />
              &nbsp;
              <BudgetRestriction.Description period={policy.budget_allowance.period} />
            </BudgetRestrictionContainer>
          ),
          policy.delivery_areas.length && (
            <LocationsRestriction
              key="LocationsRestriction"
              deliveryAreasNames={policy.delivery_areas.map(({ name }) => name)}
            />
          ),
          policy.times.length && (
            <span key="TimesRestriction">
              <TimesRestriction.Content days={policy.times.map(({ day_of_week }) => day_of_week)} />
              {', '}
              <TimesRestriction.Description
                startTimeInMinutes={policy.times?.[0]?.start_minute}
                endTimeInMinutes={policy.times?.[0]?.end_minute}
              />
            </span>
          ),
          policy.order_comment_required && (
            <AccountingNoteRequirement
              key="AccountingNoteRequirement"
              orderCommentRequired={policy.order_comment_required}
              short
            />
          ),
        ]
          .filter(Boolean)
          .reduce((acc: React.ReactNode[], element) => {
            if (acc.length === 0) {
              return [element];
            }
            return [...acc, ' · ', element];
          }, [])
      )}
    </Root>
  );
};
