import React from 'react';
import { CookieConsents } from '@creditornot/cwc-cookie-sdk';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Button } from '@creditornot/cb-button';
import { Form, Formik, useFormikContext } from 'formik';
import styled from 'styled-components';
import { ModalFooter } from '@creditornot/cbt-modal';
import { Switch } from '@creditornot/cb-switch';
import { Alert } from '@creditornot/cb-alert';
import { color } from '@creditornot/cb-ingredients/design-tokens';

import { FormikSwitch, InformationDisplay, ResponsiveContainer } from 'components';
import { LocalizedMessage } from 'i18n';
import { processError } from 'utils';

export type ManageCookieConsentsFormProps = {
  className?: string;
  onSubmit: (data: CookieConsents) => void;
  initialValues: CookieConsents;
};

export type ManageCookieConsentsFormComponentProps = {
  className?: string;
};

const Text = styled.span`
  ${typographyCss.Body3()}
  color: ${color.textSubdued};
`;

const Row = styled(ResponsiveContainer)`
  padding-inline: 0;
  margin-top: 24px;
  gap: 12px;
  align-items: baseline;
  justify-content: space-between;
`;

const FormFooter = styled(ModalFooter)`
  margin-top: 24px;
  margin-inline: -16px;
`;

const Root = styled(Form)`
  padding-inline: 16px;
  overflow: auto;
`;

export const ManageCookieConsentsFormComponent = ({
  className,
}: ManageCookieConsentsFormComponentProps) => {
  const { handleSubmit, status } = useFormikContext<CookieConsents>();
  return (
    <Root onSubmit={handleSubmit} className={className}>
      <Row>
        <InformationDisplay
          labelColor="black"
          label={<LocalizedMessage messageKey="gdpr-consents.manage.required.title" />}
        >
          <Text>
            <LocalizedMessage messageKey="gdpr-consents.manage.required.description" />
          </Text>
        </InformationDisplay>

        <Switch checked readOnly disabled />
      </Row>

      <Row>
        <InformationDisplay
          labelColor="black"
          label={<LocalizedMessage messageKey="gdpr-consents.manage.functional.title" />}
        >
          <Text>
            <LocalizedMessage messageKey="gdpr-consents.manage.functional.description" />
          </Text>
        </InformationDisplay>

        <FormikSwitch name="functional" />
      </Row>

      <Row>
        <InformationDisplay
          labelColor="black"
          label={<LocalizedMessage messageKey="gdpr-consents.manage.analytics.title" />}
        >
          <Text>
            <LocalizedMessage messageKey="gdpr-consents.manage.analytics.description" />
          </Text>
        </InformationDisplay>

        <FormikSwitch name="analytics" />
      </Row>

      <Row>
        <InformationDisplay
          labelColor="black"
          label={<LocalizedMessage messageKey="gdpr-consents.manage.marketing.title" />}
        >
          <Text>
            <LocalizedMessage messageKey="gdpr-consents.manage.marketing.description" />
          </Text>
        </InformationDisplay>

        <FormikSwitch name="marketing" />
      </Row>

      {status?.error && (
        <Row>
          <Alert variant="error" title={processError(status.error).message}>
            {processError(status.error).data}
          </Alert>
        </Row>
      )}

      <FormFooter>
        <Button variant="blue" size="large" stretch type="submit">
          <LocalizedMessage messageKey="gdpr-consents.manage.save-selections-button" />
        </Button>
      </FormFooter>
    </Root>
  );
};

export const ManageCookieConsentsForm = ({
  className,
  onSubmit,
  initialValues,
}: ManageCookieConsentsFormProps) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <ManageCookieConsentsFormComponent className={className} />
    </Formik>
  );
};
