import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchOrderDetails } from './api';
import { OrderDetails } from './types';

export const useFetchOrderDetails = <T = OrderDetails>(
  orderId: string | null,
  config?: UseQueryOptions<OrderDetails, unknown, T>,
) => {
  const { data: corporate } = useCorporate();
  const queryFn = useCallback(() => {
    if (!corporate || !orderId) {
      return Promise.reject();
    }
    return fetchOrderDetails(corporate.id, orderId);
  }, [corporate, orderId]);
  return useQuery({
    queryKey: ['order-details', corporate?.id, orderId],
    queryFn,
    enabled: !!(corporate && orderId),
    ...config,
  });
};
