import { FormikInputField } from 'components';
import { createValidator, isNumber, isRequired } from 'validation';

const validate = createValidator([isNumber, isRequired]);

interface Props {
  alwaysShowError?: boolean;
  currency: string;
}

export const FlatDiscountField = ({ alwaysShowError, currency }: Props) => {
  return (
    <FormikInputField
      name="flat_discount"
      type="text"
      validate={validate}
      alwaysShowError={alwaysShowError}
      inputProps={{
        suffixText: currency,
        'aria-label': 'flat_discount',
      }}
    />
  );
};
