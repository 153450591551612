import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { FormHeader } from '@creditornot/cb-form';
import { useFormikContext } from 'formik';
import React from 'react';

import { LocalizedMessage } from 'i18n';
import { CustomPricingFormSection } from 'modules/corporates/components';

import { CreateCorporateFormValues } from '../types';

interface Props {
  currency: string;
}

const Root = styled.div`
  border-bottom: 1px solid ${color.border};
`;

const StyledFormHeader = styled(FormHeader)``;

export const DriveCustomPricingSettingsFormSection = ({ currency }: Props) => {
  const {
    values: { price_increments, base_price },
    validateForm,
    getFieldMeta,
  } = useFormikContext<CreateCorporateFormValues>();

  return (
    <Root>
      <StyledFormHeader horizontalPadding>
        <LocalizedMessage messageKey="corporate-form.delivery-custom-price-section-title" />
      </StyledFormHeader>
      <CustomPricingFormSection
        currency={currency}
        formValues={{ base_price, price_increments }}
        validateForm={validateForm}
        getFieldMeta={getFieldMeta}
      />
    </Root>
  );
};
