import { Alert } from '@creditornot/cb-alert';
import { Button } from '@creditornot/cb-button';
import { Checkmark } from '@creditornot/cb-icons';
import { RadioCard } from '@creditornot/cb-radio';
import { FormikHelpers, Formik } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { InputContainer } from '@creditornot/cb-form';
import { useCallback } from 'react';
import { typographyCss } from '@creditornot/cb-ingredients';

import {
  ApiErrorNotification,
  FileInputInviteUserForm,
  FormFooter,
  TagInputInviteUserForm,
} from 'components';
import { breakpoints } from 'modules/media';

import { InviteExistingUserForm } from './InviteExistingUserForm';

interface Props {
  onClose: () => void;
  corporateId: string;
  subscriptionId: string;
  isSubscriptionOnlyCorporate: boolean;
  formType: FormType;
  onFormTypeChange: (formType: FormType) => void;
  onSubmit: (values: { emails: string[]; userIds: string[] }) => Promise<void>;
}

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: 24px;
  }
`;

const RadioCards = styled.div`
  display: flex;
  gap: 12px;

  @media (max-width: ${breakpoints.small}px) {
    flex-direction: column;
  }
`;

const StyledRadioCard = styled(RadioCard)`
  flex: 1;
`;

const Container = styled.div`
  padding: 24px;
`;

const Text = styled.div`
  ${typographyCss.Body3()}
  margin-bottom: 16px;
`;

const InviteForms = ({
  formType,
  corporateId,
  subscriptionId,
}: {
  formType: FormType;
  corporateId: string;
  subscriptionId: string;
}) => {
  switch (formType) {
    case 'ENTER_USER_EMAILS':
      return <TagInputInviteUserForm />;
    case 'IMPORT_USER_EMAILS':
      return <FileInputInviteUserForm />;
    case 'INVITE_EXISTING_USERS':
      return <InviteExistingUserForm corporateId={corporateId} subscriptionId={subscriptionId} />;
  }
};

export type FormType = 'ENTER_USER_EMAILS' | 'IMPORT_USER_EMAILS' | 'INVITE_EXISTING_USERS';

export const AddUsersFormComponent = ({
  onClose,
  corporateId,
  subscriptionId,
  isSubscriptionOnlyCorporate,
  formType,
  onFormTypeChange,
  onSubmit,
}: Props) => {
  const { formatMessage } = useIntl();

  const shouldShowInviteExistingUsersCard = !isSubscriptionOnlyCorporate;

  const handleSubmit = useCallback(
    async (
      values: { emails: string[]; userIds: string[] },
      actions: FormikHelpers<{
        emails: string[];
        userIds: string[];
      }>,
    ) => {
      try {
        await onSubmit(values);
        actions.setStatus({ submitSucceeded: true });
      } catch (error) {
        actions.setStatus({ error });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [onSubmit],
  );

  return (
    <Formik
      initialValues={{ emails: [] as string[], userIds: [] as string[] }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm, status, setFieldValue, values, errors }) => {
        return (
          <>
            <Container>
              <Text>
                <FormattedMessage id="wolt-plus.invite-modal.subtitle" />
              </Text>
              <StyledInputContainer>
                <RadioCards>
                  {shouldShowInviteExistingUsersCard && (
                    <StyledRadioCard
                      label={formatMessage({
                        id: 'wolt-plus.invite-modal.invite-existing-users.label',
                      })}
                      onChange={() => {
                        onFormTypeChange('INVITE_EXISTING_USERS');
                        setFieldValue('emails', []);
                        setFieldValue('userIds', []);
                      }}
                      subContent={formatMessage({
                        id: 'wolt-plus.invite-modal.invite-existing-users.text',
                      })}
                      checked={formType === 'INVITE_EXISTING_USERS'}
                    />
                  )}
                  <StyledRadioCard
                    label={formatMessage({ id: 'users.new-user-modal.invite-users-radio-label' })}
                    subContent={formatMessage({ id: 'users.new-user-modal.invite-option-by-text' })}
                    checked={formType === 'ENTER_USER_EMAILS'}
                    onChange={() => {
                      onFormTypeChange('ENTER_USER_EMAILS');
                      setFieldValue('emails', []);
                      setFieldValue('userIds', []);
                    }}
                  />
                  <StyledRadioCard
                    label={formatMessage({ id: 'users.new-user-modal.import-users-radio-label' })}
                    onChange={() => {
                      onFormTypeChange('IMPORT_USER_EMAILS');
                      setFieldValue('emails', []);
                      setFieldValue('userIds', []);
                    }}
                    subContent={formatMessage({ id: 'users.new-user-modal.invite-option-by-file' })}
                    checked={formType === 'IMPORT_USER_EMAILS'}
                  />
                </RadioCards>
              </StyledInputContainer>
              <StyledInputContainer>
                <InviteForms
                  formType={formType}
                  corporateId={corporateId}
                  subscriptionId={subscriptionId}
                />
              </StyledInputContainer>
              {status?.error ? (
                <StyledInputContainer>
                  <ApiErrorNotification error={status.error} />
                </StyledInputContainer>
              ) : (
                <StyledInputContainer>
                  <Alert variant="info" size="small">
                    <FormattedMessage id="wolt-plus.invite-modal.activation-note" />
                  </Alert>
                </StyledInputContainer>
              )}
            </Container>
            <FormFooter
              rightContent={
                <>
                  <Button size="small" variant="lightBlue" onClick={onClose}>
                    <FormattedMessage id="common.cancel" />
                  </Button>
                  <Button
                    size="small"
                    variant="blue"
                    data-test-id="invite-button"
                    onClick={submitForm}
                    disabled={
                      isSubmitting ||
                      status?.submitSucceeded ||
                      (values.emails.length === 0 && values.userIds.length === 0) ||
                      errors.emails ||
                      errors.userIds
                    }
                    loading={isSubmitting}
                    icon={
                      status?.submitSucceeded && (
                        <Checkmark data-test-id="invite-button.checkmark" />
                      )
                    }
                  >
                    <FormattedMessage id="users.new-user-modal.invite.invite-button" />
                  </Button>
                </>
              }
            />
          </>
        );
      }}
    </Formik>
  );
};
