import { color } from '@creditornot/cb-ingredients/design-tokens';
import { ChangeEventHandler, useCallback, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useQueryParams, withDefault } from 'use-query-params';
import styled from 'styled-components';
import { Input } from '@creditornot/cb-input';
import { MagnifyingGlass } from '@creditornot/cb-icons';
import { Empty } from '@creditornot/cb-placeholders';
import { Button, TextButton } from '@creditornot/cb-button';

import { View, Header } from 'components';
import { LocalizedMessage, useI18n } from 'i18n';
import { useMedia } from 'modules/media';
import { CREATE_GROUP_BUTTON_SELECTOR } from 'onboarding/selectors';
import { useFetchGroups } from 'modules/groups';
import { useCorporate } from 'modules/corporates';
import {
  NeverNullNumberParam,
  NeverNullStringParam,
  WoltPageSizeParam,
} from 'modules/react-query-params';
import { useDebounce } from 'modules/hooks';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { groupsView } from 'telemetry/Avo';

import { GroupsDataList } from './GroupsDataList';
import { CreateGroupModal } from './CreateGroupModal';
import { GroupsDataTable } from './GroupsDataTable';

const GroupsSearchInput = styled(Input).attrs({
  icon: <MagnifyingGlass />,
})`
  min-width: 240px;
`;

const GroupsHeader = styled(Header)`
  border-bottom: 1px solid ${color.border};
`;

const EmptyGroup = styled(Empty)`
  margin: 64px auto;
`;

const useGroupsQueryParams = () => {
  const mediumView = useMedia('medium');
  const query = useQueryParams({
    search: withDefault(NeverNullStringParam, ''),
    page: withDefault(NeverNullNumberParam, 1),
    page_size: withDefault(WoltPageSizeParam, 50),
  });
  const [params, setReactQueryParams] = query;
  useEffect(() => {
    if (mediumView) {
      setReactQueryParams({
        page_size: 50,
        page: 1,
      });
    }
  }, [mediumView, setReactQueryParams]);
  const setQueryParams: typeof setReactQueryParams = useCallback(
    (queries) => {
      const shouldResetPage = 'search' in queries || 'page_size' in queries;

      setReactQueryParams(({ page, ...staledQueries }) => ({
        ...staledQueries,
        page: shouldResetPage ? 1 : page,
        ...queries,
      }));
    },
    [setReactQueryParams],
  );
  return { params, setQueryParams };
};

const GroupsView = ({
  match,
}: RouteComponentProps<{
  corporateId: string;
  action?: 'create-group' | 'info';
}>) => {
  const history = useHistory();
  const { data: corporate } = useCorporate();
  const { getLocalizedMessage } = useI18n();
  const mediumView = useMedia('medium');
  const {
    params: { page, page_size, search },
    setQueryParams,
  } = useGroupsQueryParams();
  const debouncedSearch = useDebounce(search, 500);
  const {
    data: groups,
    isLoading,
    error,
  } = useFetchGroups({ page, page_size, search: debouncedSearch });

  const closeModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    history.push({ pathname: `/${match.params.corporateId}/groups`, search: search.toString() });
  }, [history, match.params.corporateId]);

  const openCreateGroupModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    history.push({
      pathname: `${history.location.pathname}/create-group`,
      search: search.toString(),
    });
  }, [history]);

  const gotoGroupView = useCallback(
    (groupId: string) => history.push(`/${match.params.corporateId}/groups/info/${groupId}`),
    [history, match.params.corporateId],
  );

  const handlePageChange = useCallback(
    (page: number) =>
      setQueryParams({
        page,
      }),
    [setQueryParams],
  );

  const handlePageSizeChange = useCallback(
    (pageSize: number) =>
      setQueryParams({
        page_size: pageSize,
      }),
    [setQueryParams],
  );

  const handleGroupsSearch: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setQueryParams({
        search: e.target.value,
      });
    },
    [setQueryParams],
  );

  const handleGroupsSearchClear = useCallback(() => {
    setQueryParams({
      search: '',
    });
  }, [setQueryParams]);

  const noData = !isLoading && groups?.total_items_count === 0 && debouncedSearch.length === 0;

  return (
    <>
      <ViewEventComponent event={groupsView} />
      <View
        title={getLocalizedMessage('views.groups')}
        subtitle={getLocalizedMessage('groups.explanation')}
        rightContent={
          !noData && (
            <Button
              variant="blue"
              size="small"
              data-product-tour={CREATE_GROUP_BUTTON_SELECTOR}
              onClick={openCreateGroupModal}
            >
              <LocalizedMessage messageKey="groups.create-group-button" />
            </Button>
          )
        }
      >
        {noData ? (
          <EmptyGroup
            footer={
              <TextButton onClick={openCreateGroupModal}>
                <LocalizedMessage messageKey="groups.create-your-first-group" />
              </TextButton>
            }
            title={getLocalizedMessage('groups.no-groups')}
          />
        ) : (
          <>
            <GroupsHeader
              leftItems={[
                <GroupsSearchInput
                  key="GroupsSearchInput"
                  placeholder={getLocalizedMessage('common.search')}
                  onChange={handleGroupsSearch}
                  onClearClick={handleGroupsSearchClear}
                  value={search}
                />,
              ]}
            />
            {mediumView ? (
              <GroupsDataList
                groups={groups?.results}
                isLoading={isLoading}
                error={error}
                onGroupClick={gotoGroupView}
              />
            ) : (
              <GroupsDataTable
                page={page}
                pageSize={page_size}
                data={groups}
                isLoading={isLoading}
                error={error}
                onGroupClick={gotoGroupView}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
              />
            )}
          </>
        )}

        <CreateGroupModal
          corporateId={corporate?.id}
          corporateName={corporate?.name}
          onClose={closeModal}
          show={match.params.action === 'create-group'}
        />
      </View>
    </>
  );
};

export default GroupsView;
