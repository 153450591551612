import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchEvent } from './api';
import { Event } from './types';

export const useFetchEvent = <T = Event>(
  eventId: string | null,
  config?: UseQueryOptions<Event, unknown, T>,
) => {
  const { data: corporate } = useCorporate();
  const queryFn = useCallback(() => {
    if (!corporate || !eventId) {
      return Promise.reject();
    }
    return fetchEvent(corporate.id, eventId);
  }, [corporate, eventId]);
  return useQuery({
    queryKey: ['event', corporate?.id, eventId],
    queryFn,
    enabled: !!(corporate && eventId),
    ...config,
  });
};
