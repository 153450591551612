import { Tag, TagVariant } from '@creditornot/cb-tag';
import { ReactNode, useMemo, VFC } from 'react';
import { DeliveryOrderResponseStatusEnum as StatusEnum } from '@creditornot/wd-api-client';

import { LocalizedMessage } from 'i18n';

const getTagProperties = (status: StatusEnum): { variant: TagVariant; text: ReactNode } => {
  switch (status) {
    case StatusEnum.Delivered:
      return {
        variant: 'lightBlack',
        text: <LocalizedMessage messageKey="deliveries.status.delivered" />,
      };
    case StatusEnum.BeingDelivered:
      return {
        variant: 'lightGreen',
        text: <LocalizedMessage messageKey="deliveries.status.being-delivered" />,
      };
    case StatusEnum.PickupSoon:
      return {
        variant: 'lightOrange',
        text: <LocalizedMessage messageKey="deliveries.status.pickup-soon" />,
      };
    case StatusEnum.Rejected:
      return {
        variant: 'lightRed',
        text: <LocalizedMessage messageKey="deliveries.status.rejected" />,
      };
    case StatusEnum.Scheduled:
      return {
        variant: 'lightDarkBlue',
        text: <LocalizedMessage messageKey="deliveries.status.pickup-scheduled" />,
      };
    default:
      return { variant: 'lightOrange', text: null };
  }
};

export const DeliveryStatusTag: VFC<{
  status: StatusEnum;
}> = ({ status }) => {
  const { variant, text } = useMemo(() => getTagProperties(status), [status]);

  return (
    <Tag size="small" variant={variant}>
      {text}
    </Tag>
  );
};
