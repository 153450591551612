import {
  LocalStorageAuthStorage,
  OAuth2Service,
  createAuthenticator,
} from '@creditornot/wolt-auth';

import { env } from 'config';

const origin = window.location.hostname.includes('localhost')
  ? 'http://localh:8887'
  : env.WOLT_AT_WORK_URI;

export const authService = new OAuth2Service({
  clientId: 'woltatwork-admin',
  loginRedirectUri: `${origin}/oauth2/login-callback`,
  logoutRedirectUri: `${origin}/oauth2/logout-callback`,
  serviceUrl: env.AUTH_API_URI,
});

export const authenticator = createAuthenticator({
  authService,
  storage: new LocalStorageAuthStorage({ key: 'auth-data' }),
});

declare module '@creditornot/wolt-auth/react' {
  interface AuthContext {
    authenticator: typeof authenticator;
  }
}
