import { useCallback, useMemo } from 'react';
import { intersection } from 'ramda';
import { MultiSelect } from '@creditornot/cb-select';
import styled from 'styled-components';

import { useI18n } from 'i18n';
import { Group } from 'modules/groups/types';
import { useFetch } from 'modules/react-query';
import { fetchGroups } from 'modules/groups';

import { useUsersQueryParams } from '../hooks';

interface Props {
  className?: string;
  corporateId: string;
}

const Root = styled(MultiSelect)`
  width: 240px;
`;

const useUsersFilterOptions = (groups?: Group[] | null) => {
  const { getLocalizedMessage } = useI18n();
  const [{ showInvites }] = useUsersQueryParams();
  const defaultOptions = useMemo(
    () => [
      {
        label: getLocalizedMessage('users.filter.status-title'),
        options: showInvites
          ? [
              {
                label: getLocalizedMessage('common.status.pending'),
                value: 'pending',
              },
              {
                label: getLocalizedMessage('common.status.expired'),
                value: 'expired',
              },
            ]
          : [
              {
                label: getLocalizedMessage('common.status.active'),
                value: 'active',
              },
              {
                label: getLocalizedMessage('common.status.inactive'),
                value: 'disabled',
              },
            ],
      },
    ],
    [getLocalizedMessage, showInvites],
  );
  const groupIdsOptions = useMemo(
    () =>
      !groups || (groups && !groups.length)
        ? undefined
        : {
            label: getLocalizedMessage('users.groups'),
            options: groups.map((group) => ({
              label: group.name,
              value: group.id,
            })),
          },
    [getLocalizedMessage, groups],
  );

  if (groups === undefined) {
    return [];
  }

  if (!groupIdsOptions) {
    return defaultOptions;
  }

  return [...defaultOptions, groupIdsOptions];
};

const statuses = ['active', 'disabled', 'pending', 'expired'];

export const UsersFilter = ({ className, corporateId }: Props) => {
  const { data: groupsData } = useFetch({
    queryKey: ['UsersFilter', 'Groups', corporateId],
    queryFn: () => fetchGroups(corporateId),
  });
  const [{ groups: groupsQueryParam = [], status: statusQueryParam = [] }, setQueryParams] =
    useUsersQueryParams();
  const { getLocalizedMessage } = useI18n();
  const groupOptions = useUsersFilterOptions(groupsData);
  const clearSearchParams = useCallback(() => {
    setQueryParams({
      user: undefined,
      users: [],
      invite: undefined,
      invites: [],
    });
  }, [setQueryParams]);
  const handleFilterChange = useCallback(
    (values: string[]) => {
      clearSearchParams();
      setQueryParams({
        groups: intersection(
          values,
          (groupsData || []).map(({ id }) => id),
        ),
        status: intersection(values, statuses),
      });
    },
    [clearSearchParams, groupsData, setQueryParams],
  );

  return (
    <Root
      className={className}
      onChange={handleFilterChange}
      placeholder={getLocalizedMessage('common.filter')}
      value={[...groupsQueryParam, ...statusQueryParam]}
      options={groupOptions}
    />
  );
};
