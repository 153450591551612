import { FormikInputField } from 'components';
import { FormikInputFieldProps } from 'components/FormikInputField';
import { createValidator, isRequired } from 'validation';

const validate = createValidator([isRequired]);

interface Props extends Omit<FormikInputFieldProps, 'name' | 'type' | 'required' | 'validate'> {
  alwaysShowError?: boolean;
}

export const ContactPersonNameField = ({ alwaysShowError, ...rest }: Props) => {
  return (
    <FormikInputField
      name="contact_name"
      type="text"
      required={true}
      validate={validate}
      alwaysShowError={alwaysShowError}
      inputProps={{ id: 'contact_name' }}
      {...rest}
    />
  );
};
