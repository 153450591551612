import { color } from '@creditornot/cb-ingredients/design-tokens';
import React from 'react';
import styled from 'styled-components';
import { WindowNew } from '@creditornot/cb-icons';
import { Tag } from '@creditornot/cb-tag';

import { ModalHeader, Anchor } from 'components';
import { LocalizedMessage } from 'i18n';
import { env } from 'config';
import { OrderDetails, Status } from 'modules/orders/types';
import { isDefined } from 'utils';
import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';

import { OrderStatus } from '../../OrderStatus';

interface OrderDetailsModalHeaderProps {
  onClose: () => void;
  order?: OrderDetails | null;
  isWoltEmployee: boolean;
  children?: React.ReactNode;
}

const Root = styled(ModalHeader)`
  padding-top: 22px;
  padding-bottom: 8px;
  min-height: auto;
  align-items: flex-start;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const OrderStatusList = styled.div`
  display: flex;
  gap: 16px;
`;

const OpsToolsPurchaseLink = styled(Anchor)`
  font-weight: 400;
`;

const StyledWindowNew = styled(WindowNew)`
  & > path {
    fill: ${color.textBrand};
  }

  ${cssDirectionalValue({
    ltr: '',
    rtl: 'transform: rotate(270deg);',
  })}
  height: 16px;
  margin-inline-end: 5px;
`;

const StyledTag = styled(Tag)`
  text-transform: uppercase;
`;

const isOrderRefunded = (order: OrderDetails) => {
  const refundedAmounts = order.payments.map((payment) => payment.refunded_amount);
  return refundedAmounts.some((amount) => !!amount);
};

export const OrderDetailsModalHeader = ({
  children,
  onClose,
  order,
  isWoltEmployee,
}: OrderDetailsModalHeaderProps) => {
  if (!order) {
    return null;
  }

  const isRefundedOrder = isOrderRefunded(order);
  // If order status is refunded, the last known status is also displayed
  const previousStatus = isRefundedOrder
    ? ((order.time_delivered ? 'delivered' : 'ready') as Status)
    : undefined;

  const statuses = [previousStatus, order?.status].filter(isDefined);

  return (
    <Root borderBottom={false} onClose={onClose}>
      <div>
        <div>{children}</div>
        <Row>
          <OrderStatusList>
            {statuses.map((status) => (
              <OrderStatus key={status} status={status} isPreOrder={!!order.preorder_time} />
            ))}
          </OrderStatusList>
          {order.group_order && (
            <StyledTag variant="black">
              <LocalizedMessage messageKey="common.group-order" />
            </StyledTag>
          )}

          {isWoltEmployee && (
            <OpsToolsPurchaseLink
              href={`${env.OPS_TOOLS_URI}/purchases/${order.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledWindowNew />
              <LocalizedMessage messageKey="orders.details.open-in-opstools" />
            </OpsToolsPurchaseLink>
          )}
        </Row>
      </div>
    </Root>
  );
};
