import { useIntl } from 'react-intl';

import { ModalV2 } from 'components';
import { Group } from 'modules/groups/types';

import { AddUsersToGroupForm } from './AddUsersToGroupForm';

type Props = {
  corporateId: string | undefined;
  onClose: () => void;
  onSuccess: () => void;
  onInviteUserClick: () => void;
  show: boolean;
  group: Group | null | undefined;
};

export const AddUsersToGroupModal = ({
  corporateId,
  onClose,
  onSuccess,
  onInviteUserClick,
  show,
  group,
}: Props) => {
  const { formatMessage } = useIntl();
  return (
    <ModalV2
      onDismiss={onClose}
      open={show}
      title={formatMessage({ id: 'groups-form.add-users-to-group' })}
      sizePreset={{
        desktop: 'centered:large',
        mobile: 'fullScreen',
      }}
    >
      {group && corporateId && (
        <AddUsersToGroupForm
          onClose={onClose}
          onSuccess={onSuccess}
          onInviteUserClick={onInviteUserClick}
          group={group}
          corporateId={corporateId}
        />
      )}
    </ModalV2>
  );
};
