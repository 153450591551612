import { color } from '@creditornot/cb-ingredients/design-tokens';
import { ReactNode } from 'react';
import { Alert } from '@creditornot/cb-alert';
import styled from 'styled-components';
import { MenuCheckboxItem } from '@creditornot/cb-menu';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Empty } from '@creditornot/cb-placeholders';

import { DataCellContent, LoadingState, ResponsiveContainer } from 'components';
import { LocalizedMessage } from 'i18n';
import { processError } from 'utils';
import { breakpoints } from 'modules/media';
import { UserV2BasicInfo } from 'modules/users/types';

import { ScrollableContainer } from './ScrollableContainer';

type CompanyUsersListProps = {
  isLoading: boolean;
  onUserClick: (user: UserV2BasicInfo) => void;
  onCloseErrorAlertClick: () => void;
  getDisabledUser: (user: UserV2BasicInfo) => boolean;
  getSelectedUser: (user: UserV2BasicInfo) => boolean;
  disabledUserText: ReactNode;
  allUsers: UserV2BasicInfo[] | null | undefined;
  error?: unknown;
  emptyMessage: string;
};

const UserCheckbox = styled(MenuCheckboxItem)`
  & + & {
    margin-top: 12px;
  }
`;

const CheckboxContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline-start: 4px;

  @media (max-width: ${breakpoints.medium}px) {
    display: block;
  }
`;

const Small = styled.small`
  ${typographyCss.Small()}
  color: ${color.textSubdued};
`;

const StyledEmpty = styled(Empty)`
  padding-top: 32px;
  padding-bottom: 24px;
`;

const StyledScrollableContainer = styled(ScrollableContainer)`
  max-height: 35vh;
`;

const AlertResponsiveContainer = styled(ResponsiveContainer)`
  padding-top: 24px;
  padding-bottom: 16px;
`;

export const CompanyUsersList = ({
  allUsers,
  onUserClick,
  onCloseErrorAlertClick,
  emptyMessage,
  getDisabledUser,
  getSelectedUser,
  disabledUserText,
  isLoading,
  error,
}: CompanyUsersListProps) => {
  return (
    <>
      {isLoading && (
        <LoadingState>
          <LocalizedMessage messageKey="common.loading" />
        </LoadingState>
      )}

      {!allUsers?.length && !isLoading && <StyledEmpty title={emptyMessage} />}

      {error && (
        <AlertResponsiveContainer>
          <Alert
            onCloseButtonClick={onCloseErrorAlertClick}
            style={{ flex: 1 }}
            variant="error"
            size="medium"
            title={processError(error).message}
          >
            {processError(error).data}
          </Alert>
        </AlertResponsiveContainer>
      )}

      {allUsers?.length && !isLoading ? (
        <StyledScrollableContainer>
          {allUsers.map((user) => {
            const disabled = getDisabledUser(user);
            const isSelected = getSelectedUser(user);

            return (
              <UserCheckbox
                data-test-id="UserCheckbox"
                disabled={disabled}
                checked={isSelected || disabled}
                onClick={() => {
                  onUserClick(user);
                }}
                key={user.user_id}
              >
                <CheckboxContent>
                  <DataCellContent
                    subContent={user.email}
                  >{`${user.name.first} ${user.name.last}`}</DataCellContent>
                  {disabled && <Small>{disabledUserText}</Small>}
                </CheckboxContent>
              </UserCheckbox>
            );
          })}
        </StyledScrollableContainer>
      ) : null}
    </>
  );
};
