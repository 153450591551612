import { env } from 'config';
import { Anchor } from 'components';

interface Props {
  userId: string;
  userName?: string;
  shouldLinkToOpsTools: boolean;
}

export const UserName = ({ userId, userName, shouldLinkToOpsTools }: Props) => {
  if (!userName) {
    return <span>{'–'}</span>;
  }

  if (!shouldLinkToOpsTools && userName) {
    return <span>{userName}</span>;
  }

  return (
    <Anchor target="_blank" rel="noopener noreferrer" href={`${env.OPS_TOOLS_URI}/users/${userId}`}>
      {userName}
    </Anchor>
  );
};
