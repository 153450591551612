import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';

const GridContainer = styled.div<Pick<Props, 'columns'>>`
  display: grid;
  grid-template-rows: auto;
  ${({ columns }) => css`
    grid-template-columns: repeat(${columns}, 1fr);
  `}
`;

const Title = styled.h6`
  ${typographyCss.Heading6()}
`;

const Subtitle = styled.h6`
  ${typographyCss.Body3()}
`;

const Text = styled.div`
  padding: 32px 24px 0;
`;

interface Props {
  columns?: number;
  title?: ReactNode;
  subtitle?: ReactNode;
  className?: string;
  children?: ReactNode;
}

export const Columns: React.FC<Props> = ({ columns = 2, children, title, subtitle, className }) => {
  return (
    <div className={className}>
      {(title || subtitle) && (
        <Text>
          {title && <Title>{title}</Title>}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Text>
      )}
      <GridContainer columns={columns}>{children}</GridContainer>
    </div>
  );
};
