import { LocalizedMessage } from 'i18n';
import { DayOfWeek } from 'modules/policies/types';

export const budgetPeriodMessages = {
  day: <LocalizedMessage messageKey="common.per-day" />,
  week: <LocalizedMessage messageKey="common.per-week" />,
  month: <LocalizedMessage messageKey="common.per-month" />,
  quarter: <LocalizedMessage messageKey="common.per-quarter" />,
  year: <LocalizedMessage messageKey="common.per-year" />,
};

export const daysOfWeekOrder: DayOfWeek[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
