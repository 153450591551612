import styled from 'styled-components';
import { Alert } from '@creditornot/cb-alert';

import { FormikInlineForm, InformationDisplay } from 'components';
import { LocalizedMessage, LocalizedNumber } from 'i18n';
import { Event } from 'modules/events/types';
import { useEditEvent } from 'modules/events';
import { shallowObjectDifferences } from 'utils';

import {
  formatEventDataToEditUpComingEventFormValues,
  formatEditOngoingEventFormValuesToApiData,
} from './utils';
import { FormikEventDescriptionTextarea } from './EventFormFields';
import { EventDateTime } from './EventDateTime';
import { EditOnGoingEventFormData } from './types';

interface Props {
  onSuccess?: (event: Event) => void;
  eventData: Event;
  className?: string;
  currency: string;
}

const StyledAlert = styled(Alert)`
  margin-top: 16px;
`;

const StyledInformationDisplay = styled(InformationDisplay)`
  & + & {
    padding-top: 16px;
  }
`;

export const EditOnGoingEventForm: React.FC<Props> = ({
  onSuccess,
  eventData,
  className,
  currency,
}) => {
  const editEvent = useEditEvent();
  const initialValues = formatEventDataToEditUpComingEventFormValues(eventData, currency);

  const handleSubmitEventDescription = async (values: EditOnGoingEventFormData) => {
    const updatedFormValues = shallowObjectDifferences(values, initialValues);
    const updatedData = formatEditOngoingEventFormValuesToApiData(updatedFormValues);
    const newEvent = await editEvent(eventData.id, {
      description: updatedData.description,
    });

    setTimeout(() => {
      onSuccess?.(newEvent);
    }, 500);
  };

  return (
    <div className={className}>
      <StyledInformationDisplay
        labelColor="black"
        borderBottom
        label={
          <strong>
            <LocalizedMessage messageKey="events.event-details.event-time-label" />
          </strong>
        }
      >
        <LocalizedMessage
          messageKey="events.event-details.event-date"
          values={{
            startDate: <EventDateTime dateTime={eventData.start_time} />,
            endDate: <EventDateTime dateTime={eventData.end_time} />,
          }}
        />
        <StyledAlert size="small" variant="info">
          <LocalizedMessage messageKey="events.event-details.event-time-info-message" />
        </StyledAlert>
      </StyledInformationDisplay>

      <StyledInformationDisplay
        labelColor="black"
        borderBottom
        label={
          <strong>
            <LocalizedMessage messageKey="events.event-details.budget" />
          </strong>
        }
      >
        <LocalizedNumber value={eventData.participant_budget_amount} currency={currency} />
      </StyledInformationDisplay>

      <FormikInlineForm
        label={<LocalizedMessage messageKey="events.create-event-form.description" />}
        initialValues={initialValues}
        onSubmit={handleSubmitEventDescription}
      >
        {({ isEditing }) =>
          isEditing ? (
            <FormikEventDescriptionTextarea alwaysShowError />
          ) : eventData.description.trim().length === 0 ? (
            '–'
          ) : (
            eventData.description
          )
        }
      </FormikInlineForm>
    </div>
  );
};
