import styled from 'styled-components';
import { Trashbin } from '@creditornot/cb-icons';
import { DataTable, Column } from '@creditornot/cb-data-table';
import { TextButton } from '@creditornot/cb-button';

import { env } from 'config';
import { useCorporate } from 'modules/corporates';
import {
  DataCellContent,
  IconButtonWithHelpText,
  Anchor,
  ApiErrorNotification,
  ResponsiveContainer,
} from 'components';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { LocalizedMessage, useI18n } from 'i18n';
import { User } from 'modules/users/types';

interface Props {
  data: User[] | undefined | null;
  isLoading: boolean;
  error?: unknown;
  searchTerm?: string;
  dataCellProps: DataTableComponentProps;
  groupName?: string;
  onAddUsersClick: () => void;
}

interface DataTableComponentProps {
  onRemoveUserClick?: (user: User) => void;
  isDefaultGroup?: boolean;
  hasCorporateAccess?: boolean;
}

const Content = styled.div`
  display: inline-flex;
`;

const GroupDataCellContent = styled(DataCellContent)`
  vertical-align: top;
`;

const useColumns = (): Column<User, DataTableComponentProps>[] => {
  const { getLocalizedMessage } = useI18n();
  return [
    {
      id: 'name',
      name: getLocalizedMessage('common.name'),
      render: ({ record: { full_name, id }, hasCorporateAccess }) => {
        return (
          <GroupDataCellContent>
            {hasCorporateAccess ? (
              <Anchor
                target="_blank"
                rel="noopener noreferrer"
                href={`${env.OPS_TOOLS_URI}/users/${id}`}
              >
                {full_name}
              </Anchor>
            ) : (
              full_name
            )}
          </GroupDataCellContent>
        );
      },
    },
    {
      id: 'email',
      name: getLocalizedMessage('common.email'),
      render: ({ record: { email } }) => {
        return <GroupDataCellContent>{email}</GroupDataCellContent>;
      },
    },
    {
      id: 'actions',
      name: '',
      alignContent: 'right',
      alignName: 'right',
      render: ({ record, onRemoveUserClick, isDefaultGroup }) => {
        if (isDefaultGroup) return null;

        return (
          <Content>
            <IconButtonWithHelpText
              onClick={() => {
                onRemoveUserClick?.(record);
              }}
              tooltipText={<LocalizedMessage messageKey="common.delete" />}
              tooltipPlacement="bottom"
              variant="grey"
            >
              <Trashbin height="16px" width="16px" />
            </IconButtonWithHelpText>
          </Content>
        );
      },
    },
  ];
};

const StyledNoUsersDescription = styled(LocalizedMessage)`
  max-width: 540px;
  display: inline-block;
  margin-bottom: 16px;
`;

const ErrorContainer = styled(ResponsiveContainer)`
  padding-top: 22px;
  padding-bottom: 22px;
`;

const GroupUsersDataTable: React.FC<Props> = ({
  data,
  isLoading,
  dataCellProps,
  searchTerm,
  groupName,
  error,
  onAddUsersClick,
}) => {
  const columns = useColumns();
  const { getLocalizedMessage } = useI18n();
  const { isWoltEmployee } = useIsUserWoltEmployee();
  const { data: corporate } = useCorporate();
  const isSearching = !!searchTerm;

  if (error) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={error} />
      </ErrorContainer>
    );
  }

  return (
    <DataTable
      horizontalPadding="wide"
      tableLayout="fixed"
      dataSource={data || []}
      columns={columns}
      getRowKey={(row) => row.id}
      loading={isLoading}
      emptyMessage={
        isSearching
          ? {
              title: getLocalizedMessage('groups.no-users-with-search-term', {
                searchTerm,
              }),
            }
          : {
              title: getLocalizedMessage('groups.no-users-in-group'),
              description: (
                <div>
                  <StyledNoUsersDescription
                    messageKey="groups.no-users-in-group-explanation"
                    values={{
                      paymentMethodName: `${[corporate?.name, groupName]
                        .filter(Boolean)
                        .join(' – ')}`,
                      b: (msg: string) => <b key={msg}>{msg}</b>,
                    }}
                  />

                  <div>
                    <TextButton onClick={onAddUsersClick}>
                      <LocalizedMessage messageKey="groups.add-members-to-group" />
                    </TextButton>
                  </div>
                </div>
              ) as unknown as string,
              // Hack: TODO update @creditornot/cb-data-table types
            }
      }
      componentProps={{ ...dataCellProps, hasCorporateAccess: isWoltEmployee }}
    />
  );
};

export default GroupUsersDataTable;
