import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { isBefore } from 'date-fns';
import styled from 'styled-components';
import { FormRow, InputContainer } from '@creditornot/cb-form';
import { Alert } from '@creditornot/cb-alert';

import { useI18n } from 'i18n';
import { useCorporate } from 'modules/corporates';

import { FormikDateErrorAlert } from './FormikDateErrorAlert';
import { useValidateEventDate } from './hooks';
import {
  FormikEventDescriptionTextarea,
  FormikEventEndDateInputSelect,
  FormikEventEndTimeInputSelect,
  FormikEventNameInput,
  FormikEventParticipantsBudgetInput,
  FormikEventStartDateInputSelect,
  FormikEventStartTimeInputSelect,
} from './EventFormFields';
import { CreateEventFormData } from './types';

interface Props {
  className?: string;
}

const StyledFormikEventStartDateInputSelect = styled(FormikEventStartDateInputSelect)`
  width: 60%;
`;

const StyledFormikEventStartTimeInputSelect = styled(FormikEventStartTimeInputSelect)`
  margin-inline-start: 16px;
  width: 40%;
`;

const StyledFormikEventEndDateInputSelect = styled(FormikEventEndDateInputSelect)`
  width: 60%;
`;

const StyledFormikDateErrorAlert = styled(FormikDateErrorAlert)`
  margin-top: 16px;
`;

const StyledFormikEventEndTimeInputSelect = styled(FormikEventEndTimeInputSelect)`
  width: 40%;
  margin-inline-start: 16px;
`;

const StyledInputContainer = styled(InputContainer)`
  margin-top: 16px;
`;

const InputContainerContent = styled.div`
  display: flex;
`;

const useEnsureEndDateIsNotBeforeStartDate = () => {
  const { values, setFieldValue } = useFormikContext<CreateEventFormData>();
  useEffect(() => {
    const { start_date, end_date } = values;
    if (!start_date && !end_date) return;

    if (start_date && !end_date) {
      setFieldValue('end_date', start_date);
      return;
    }

    if (start_date && end_date && isBefore(end_date, start_date)) {
      setFieldValue('end_date', start_date);
    }
  }, [setFieldValue, values]);
};

export const AddEventDetailsFormSection: React.FC<Props> = ({ className }) => {
  const { getLocalizedMessage } = useI18n();
  const { data: corporate } = useCorporate();
  const { endDateError, startDateError } = useValidateEventDate();
  useEnsureEndDateIsNotBeforeStartDate();

  return (
    <div className={className}>
      <FormRow
        horizontalPadding
        labelAlign="center"
        required
        labelHtmlFor="name"
        label={getLocalizedMessage('events.create-event-form.event-name')}
      >
        <FormikEventNameInput />
      </FormRow>

      <FormRow
        horizontalPadding
        label={getLocalizedMessage('events.create-event-form.description')}
        labelHtmlFor="description"
      >
        <FormikEventDescriptionTextarea />
      </FormRow>

      <FormRow
        horizontalPadding
        required
        label={getLocalizedMessage('events.create-event-form.event-time')}
        labelHtmlFor="start_date"
      >
        <Alert size="small" variant="info">
          {getLocalizedMessage('events.create-event-form.event-time-info-banner')}
        </Alert>

        <StyledFormikDateErrorAlert endDateError={endDateError} startDateError={startDateError} />

        <StyledInputContainer
          label={getLocalizedMessage('events.create-event-form.event-time-starting-date')}
        >
          <InputContainerContent>
            <StyledFormikEventStartDateInputSelect
              datePickerInputProps={
                startDateError
                  ? {
                      invalid: true,
                    }
                  : undefined
              }
            />
            <StyledFormikEventStartTimeInputSelect
              timeSelectProps={
                startDateError
                  ? {
                      invalid: true,
                    }
                  : undefined
              }
            />
          </InputContainerContent>
        </StyledInputContainer>

        <StyledInputContainer
          label={getLocalizedMessage('events.create-event-form.event-time-ending-date')}
        >
          <InputContainerContent>
            <StyledFormikEventEndDateInputSelect
              datePickerInputProps={
                endDateError
                  ? {
                      invalid: true,
                    }
                  : undefined
              }
            />
            <StyledFormikEventEndTimeInputSelect
              timeSelectProps={
                endDateError
                  ? {
                      invalid: true,
                    }
                  : undefined
              }
            />
          </InputContainerContent>
        </StyledInputContainer>
      </FormRow>

      <FormRow
        horizontalPadding
        required
        labelAlign="center"
        label={getLocalizedMessage('events.create-event-form.budget', {
          currency: corporate?.currency,
        })}
        labelHtmlFor="participant_budget_amount"
      >
        <FormikEventParticipantsBudgetInput currency={corporate?.currency || ''} />
      </FormRow>
    </div>
  );
};
