import { GetCorporateResponse } from '@creditornot/wd-api-client';
import { useState } from 'react';

import { useEditSelfServiceCorporate } from 'modules/corporates';
import { FormStates } from 'components/FormCard';
import { Corporate } from 'modules/corporates/types';

import { DeliveryPreferenceForm } from './DeliveryPreferenceForm';
import { DeliveryPreferenceFormValues } from './types';

export const DeliveryPreferenceSettings = ({
  selfServiceCorporate,
  corporate,
}: {
  selfServiceCorporate: GetCorporateResponse;
  corporate: Corporate;
}) => {
  const { mutateAsync: editCorporateSettings } = useEditSelfServiceCorporate();
  const [renderState, setRenderState] = useState<FormStates>('view');

  const submitHandler = async ({
    isIdCheckRequired,
    isNoContactDelivery,
    isHandshakeRequired,
    corporateLabel,
  }: DeliveryPreferenceFormValues) => {
    await editCorporateSettings({
      woltCorporateId: corporate.id,
      body: {
        isIdCheckRequired,
        isNoContactDelivery,
        isHandshakeRequired,
        label: corporateLabel,
      },
    });
    setRenderState('view');
  };

  return (
    <DeliveryPreferenceForm
      selfServiceCorporate={selfServiceCorporate}
      countryCode={corporate.country}
      renderState={renderState}
      onSubmit={submitHandler}
      onRenderStateChange={setRenderState}
    />
  );
};
