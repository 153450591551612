import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQueryClient } from 'modules/react-query';

import { createEvent, CreateEventData } from './api';

export const useCreateEvent = () => {
  const { data: corporate } = useCorporate();
  const queryClient = useQueryClient();
  return useCallback(
    async (data: CreateEventData) => {
      if (!corporate) {
        return Promise.reject('Corporate is missing');
      }
      const result = await createEvent(corporate.id, data);

      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 500);
      return result;
    },
    [corporate, queryClient],
  );
};
