import { color } from '@creditornot/cb-ingredients/design-tokens';
import { IntlShape, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { InputContainer } from '@creditornot/cb-form';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Alert } from '@creditornot/cb-alert';
import { Input } from '@creditornot/cb-input';

import { FormSection, FormViewText, FormRowV2 as Row } from 'components';
import {
  BusinessIdField,
  CityField,
  CompanyNameField,
  CountryField,
  PostalCodeField,
  StreetAddressField,
  VatIdField,
} from 'modules/corporates/components';
import countries from 'modules/configs/countries';
import { getNameWithCountryCode } from 'modules/configs/utils';
import { processError } from 'utils';
import { ApiErrorContainer } from 'views/settings/CompanySettingsV2/ApiErrorContainer';
import { Corporate } from 'modules/corporates/types';

import { FormSectionDivider, SplitRow } from '../utils.styled';
import { RenderMode } from '../../types';
import { SaveCancelButtons } from '../SaveCancelButtons';
import { EditButton } from '../EditButton';

type Props = {
  renderMode: RenderMode;
  isWoltEmployee: boolean;
  corporate: Corporate;
  onEditClick?: () => void;
  onSaveClick?: () => void;
  onCancelClick?: () => void;
  dirty?: boolean;
  isSubmitting?: boolean;
  error?: any;
};

const SecondaryText = styled.span`
  ${typographyCss.Body3()};
  display: block;
  margin-top: 8px;
  color: ${color.textSubdued};
`;

type FormValues = {
  address: string;
  business_id: string;
  city: string;
  country: string;
  name: string;
  post_code: string;
  vat_id: string;
};

const defaultViewRender = (args: { value?: string } | undefined) => (
  <FormViewText>{args?.value}</FormViewText>
);

/**
 * A bit easier to read than the putting all of this logic in the render function.
 */
const renderMapFn: (formatMessage: IntlShape['formatMessage']) => {
  [key in keyof FormValues]: {
    [key in Props['renderMode']]: (args?: { value?: string }) => JSX.Element | null;
  };
} = (formatMessage) => ({
  name: {
    edit: () => <CompanyNameField allowClear={false} />,
    signup: () => (
      <InputContainer>
        <CompanyNameField allowClear={false} />
        <SecondaryText>{formatMessage({ id: 'corporate-form.name-hint' })}</SecondaryText>
      </InputContainer>
    ),
    view: defaultViewRender,
  },
  country: {
    edit: () => <CountryField />,
    signup: () => <CountryField />,
    view: defaultViewRender,
  },
  address: {
    edit: () => <StreetAddressField allowClear={false} />,
    signup: () => <StreetAddressField allowClear={false} />,
    view: defaultViewRender,
  },
  post_code: {
    edit: () => <PostalCodeField allowClear={false} />,
    signup: () => <PostalCodeField allowClear={false} />,
    view: () => null, // We show one liner address in the view mode
  },
  city: {
    edit: () => <CityField allowClear={false} />,
    signup: () => <CityField allowClear={false} />,
    view: () => null, // We show one liner address in the view mode
  },
  business_id: {
    edit: () => <BusinessIdField allowClear={false} />,
    signup: () => <BusinessIdField allowClear={false} />,
    view: defaultViewRender,
  },
  vat_id: {
    edit: () => <VatIdField allowClear={false} />,
    signup: () => <VatIdField allowClear={false} />,
    view: defaultViewRender,
  },
});

const countryOfServiceRender = {
  edit: ({ value }: { value?: string }) => <Input disabled value={value} />,
  signup: ({ value }: { value?: string }) => <Input disabled value={value} />,
  view: ({ value }: { value?: string }) => <FormViewText>{value}</FormViewText>,
};

export const CompanyInformationForm = ({
  renderMode,
  onEditClick,
  onCancelClick,
  onSaveClick,
  dirty,
  isSubmitting,
  isWoltEmployee,
  corporate,
  error,
}: Props) => {
  const { formatMessage } = useIntl();

  const { values } = useFormikContext<FormValues>();

  const country = useMemo(() => {
    if (values.country) {
      return getNameWithCountryCode(countries)(values.country);
    }

    return '';
  }, [values.country]);

  const countryOfService = useMemo(() => {
    if (corporate.country_config.country_code) {
      return getNameWithCountryCode(countries)(corporate.country_config.country_code);
    }

    return '';
  }, [corporate.country_config.country_code]);

  const renderMap = useMemo(() => renderMapFn(formatMessage), [formatMessage]);

  return (
    <FormSection
      testId="company-information"
      title={formatMessage({ id: 'views.settings.company-information' })}
      topRightItem={
        onEditClick &&
        isWoltEmployee && <EditButton renderMode={renderMode} onEditClick={onEditClick} />
      }
    >
      <Row
        htmlFor="name"
        title={formatMessage({ id: 'corporate-form.name' })}
        content={renderMap.name[renderMode]({ value: values.name })}
      />
      <Row
        title={formatMessage({ id: 'corporate-form.country-of-service' })}
        content={countryOfServiceRender[renderMode]({ value: countryOfService })}
      />
      <Row
        title={formatMessage({ id: 'corporate-form.billing-address' })}
        content={
          renderMode === 'view' ? (
            <>
              {renderMap.address[renderMode]({
                value: `${values.address}, ${values.post_code}, ${values.city}`,
              })}
              {renderMap.country[renderMode]({ value: country })}
            </>
          ) : (
            <>
              <InputContainer
                style={{ marginBottom: '8px' }}
                labelHtmlFor="address"
                label={formatMessage({ id: 'common.street_address' })}
              >
                {renderMap.address[renderMode]({})}
              </InputContainer>
              <SplitRow style={{ marginBottom: '8px' }}>
                <InputContainer
                  labelHtmlFor="post_code"
                  label={formatMessage({ id: 'common.postal_code' })}
                >
                  {renderMap.post_code[renderMode]({})}
                </InputContainer>
                <InputContainer labelHtmlFor="city" label={formatMessage({ id: 'common.city' })}>
                  {renderMap.city[renderMode]({})}
                </InputContainer>
              </SplitRow>
              <SplitRow>
                <InputContainer
                  labelHtmlFor="country"
                  label={formatMessage({ id: 'common.country' })}
                >
                  {renderMap.country[renderMode]({ value: country })}
                </InputContainer>
              </SplitRow>
            </>
          )
        }
      />
      <FormSectionDivider />
      <Row
        htmlFor="business_id"
        title={formatMessage({ id: 'common.business_id' })}
        content={renderMap.business_id[renderMode]({ value: values.business_id })}
      />
      <Row
        htmlFor="vat_id"
        title={formatMessage({ id: 'corporate-form.vat_id' })}
        content={renderMap.vat_id[renderMode]({ value: values.vat_id })}
      />
      {error && (
        <ApiErrorContainer>
          <Alert variant="error" size="medium" title={processError(error).message}>
            {processError(error).data}
          </Alert>
        </ApiErrorContainer>
      )}
      <SaveCancelButtons
        renderMode={renderMode}
        onSaveClick={onSaveClick}
        onCancelClick={onCancelClick}
        dirty={dirty}
        isSubmitting={isSubmitting}
      />
    </FormSection>
  );
};
