import * as React from 'react';
import { Tag, TagProps } from '@creditornot/cb-tag';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import styled from 'styled-components';
import { HelpCircleFilled } from '@creditornot/cb-icons';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Variant } from '@creditornot/cb-tag/lib/esm/Tag';
import { useIntl } from 'react-intl';

import { MessageIds } from 'i18n';

import { CorporateStatus } from '../types';

type Props = {
  status: CorporateStatus;
};

type MappedStatuses = {
  [K in CorporateStatus]?: {
    variant: Variant;
    text: MessageIds;
    hint?: MessageIds;
  };
};

const Hint = styled.span`
  ${typographyCss.Body3()}
  max-width: 280px;
  display: inline-block;
`;

const CorporateTag = styled(Tag)`
  height: unset;

  > :first-child {
    white-space: normal;
  }
`;

const mappedStatuses: MappedStatuses = {
  active: {
    variant: 'lightGreen',
    text: 'corporates.status.active',
  },
  disabled: {
    variant: 'lightBlack',
    text: 'corporates.status.disabled',
    hint: 'corporates.status-info.disabled',
  },
  submitted: {
    variant: 'lightBlue',
    text: 'corporates.status.submitted',
    hint: 'corporates.status-info.submitted',
  },
  invite_pending: {
    variant: 'lightBlue',
    text: 'corporates.status.invite-pending',
    hint: 'corporates.status-info.invite-pending',
  },
  details_pending: {
    variant: 'lightBlue',
    text: 'corporates.status.details-pending',
    hint: 'corporates.status-info.details-pending',
  },
  verification_pending: {
    variant: 'lightYellow',
    text: 'corporates.status.verification-pending',
    hint: 'corporates.status-info.verification-pending',
  },
  contract_pending: {
    variant: 'lightBlue',
    text: 'corporates.status.contract-pending',
    hint: 'corporates.status-info.contract-pending',
  },
};

export const CorporateStatusTag = ({ status }: Props) => {
  const { formatMessage } = useIntl();
  const mappedStatus = mappedStatuses[status];

  if (!mappedStatus) {
    return null;
  }

  const hintProps: Partial<TagProps> = mappedStatus.hint
    ? {
        icon: (
          <Popover
            placement="top"
            content={<Hint>{formatMessage({ id: mappedStatus.hint })}</Hint>}
          >
            <HelpCircleFilled />
          </Popover>
        ),
        iconPosition: 'right',
      }
    : {};

  return (
    <CorporateTag variant={mappedStatus.variant} {...hintProps}>
      {formatMessage({ id: mappedStatus.text })}
    </CorporateTag>
  );
};
