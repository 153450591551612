import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled, { css } from 'styled-components';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { typographyCss } from '@creditornot/cb-ingredients';

import { LocalizedMessage } from 'i18n';

interface Props {
  isDisabled?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const Root = styled.small<Props>`
  ${typographyCss.Small()}
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${color.textDisabled};
    `}

  &:nth-of-type(n + 2) {
    &::before {
      content: ', ';
    }
  }
`;

const GroupTag: React.FC<Props> = ({ isDisabled, children }) => {
  if (!isDisabled) {
    return <Root>{children}</Root>;
  }
  return (
    <Root isDisabled={isDisabled}>
      <Popover placement="top" content={<LocalizedMessage messageKey="users.disabled-group-tag" />}>
        {children}
      </Popover>
    </Root>
  );
};

export default GroupTag;
