import { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { ModalV2 } from 'components';
import { useProductTour } from 'modules/product-tour';
import {
  CREATE_GROUP_MODAL_SELECTOR,
  CREATE_GROUP_BUTTON_SELECTOR,
  USER_GUIDE_SELECTOR,
} from 'onboarding/selectors';
import { Group } from 'modules/groups/types';
import { CreateGroupForm } from 'modules/groups/components';
import { useAppModals } from 'modules/app-modals';

import { useOpenCreateGroupWithoutPolicyToast } from './useOpenCreateGroupWithoutPolicyToast';

interface Props {
  corporateId?: string;
  corporateName?: string;
  onClose: () => void;
  show: boolean;
}

const Root = styled(ModalV2)`
  max-width: 740px;
`;

const Container = styled.div`
  overflow: auto;
`;

type CreateGroupModalComponentProps = {
  corporateId: string;
  corporateName: string;
};

const CreateGroupModalComponent = ({
  corporateId,
  corporateName,
}: CreateGroupModalComponentProps) => {
  const history = useHistory();
  const openCreateGroupWithoutPolicyToast = useOpenCreateGroupWithoutPolicyToast();
  const { isInTourMode, gotoStep } = useProductTour();
  const gotoOrdersView = useCallback(
    () => history.push(`/${corporateId}/orders`),
    [corporateId, history],
  );
  const gotoGroupView = useCallback(
    (groupId: string, additionalPath?: string) =>
      history.push(`/${corporateId}/groups/info/${groupId}${additionalPath ?? ''}`),
    [corporateId, history],
  );
  const handleSubmitSuccessAndOpenAddUserModal = useCallback(
    (group: Group) => {
      gotoGroupView(group.id, '/add-users');
    },
    [gotoGroupView],
  );
  const handleSubmitSuccess = useCallback(
    (group: Group) => {
      const noPolicyAdded = group.policies.length === 0;
      if (isInTourMode) {
        gotoStep('');
        setTimeout(() => {
          gotoOrdersView();
        }, 500);
        setTimeout(() => {
          gotoStep(USER_GUIDE_SELECTOR);
        }, 1000);
      } else if (noPolicyAdded) {
        openCreateGroupWithoutPolicyToast({
          corporateName: corporateName,
          groupName: group.name,
          corporateId,
          groupId: group.id,
        });
      }
      gotoGroupView(group.id);
    },
    [
      corporateId,
      corporateName,
      gotoGroupView,
      gotoOrdersView,
      gotoStep,
      isInTourMode,
      openCreateGroupWithoutPolicyToast,
    ],
  );
  return (
    <CreateGroupForm
      corporateId={corporateId}
      onSuccess={handleSubmitSuccess}
      onSuccessAndOpenAddUserModal={
        isInTourMode ? undefined : handleSubmitSuccessAndOpenAddUserModal
      }
    />
  );
};

export const CreateGroupModal = ({ corporateId, onClose, show, corporateName }: Props) => {
  const { isInTourMode, gotoStep } = useProductTour();
  const { formatMessage } = useIntl();
  const handleClose = useCallback(() => {
    if (isInTourMode) {
      gotoStep(CREATE_GROUP_BUTTON_SELECTOR);
      onClose();
    } else {
      onClose();
    }
  }, [gotoStep, isInTourMode, onClose]);
  const { hasOpenModal } = useAppModals();

  return (
    <Root
      onDismiss={handleClose}
      disableDismissOnOutsidePress={hasOpenModal}
      open={show}
      title={formatMessage({ id: 'groups-form.create-group' })}
      sizePreset={{
        desktop: 'centered:extraLarge',
        mobile: 'fullScreen',
      }}
    >
      <Container data-product-tour={CREATE_GROUP_MODAL_SELECTOR}>
        {corporateId && corporateName && (
          <CreateGroupModalComponent corporateId={corporateId} corporateName={corporateName} />
        )}
      </Container>
    </Root>
  );
};
