import { useFormikContext } from 'formik';

import { CreateEventFormData } from '../types';
import { isEndDateAfterStartDate, isDateInThePast } from '../utils';

const createDateData = (date: Date | null, minute: string) => {
  const newDate = new Date(date || 0);
  newDate.setHours(0, Number.parseInt(minute) || 0, 0, 0);
  return newDate;
};

export const useValidateEventDate = (props?: { alwaysShowError?: boolean }) => {
  const alwaysShowError = props?.alwaysShowError;
  const { values } = useFormikContext<CreateEventFormData>();
  const {
    start_date: startDateValue,
    end_date: endDateValue,
    start_minutes: startTimeValue,
    end_minutes: endTimeValue,
  } = values;

  const startDate = createDateData(startDateValue, startTimeValue);
  const endDate = createDateData(endDateValue, endTimeValue);

  const startDateError =
    alwaysShowError || (startDateValue && startTimeValue !== '')
      ? isDateInThePast(startDate)
      : undefined;

  const endDateError =
    alwaysShowError ||
    (endDateValue && endTimeValue !== '' && startDateValue && startTimeValue !== '')
      ? isEndDateAfterStartDate(startDate, endDate)
      : undefined;

  return {
    startDateError,
    endDateError,
  };
};
