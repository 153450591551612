import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Paperplane, Trashbin } from '@creditornot/cb-icons';

import { IconButtonWithHelpText } from 'components';

import { useSubscriptionUsersQueryParams } from '../../../hooks/useSubscriptionUsersQueryParams';

interface Props {
  id: string;
  onOpenDeleteInviteModal: (id: string) => void;
  onOpenResendInviteModal: (id: string) => void;
}

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTrashbin = styled(Trashbin)`
  height: 16px;
  width: 16px;
  fill: ${color.textSubdued} !important;
`;

const StyledPaperplane = styled(Paperplane)`
  height: 16px;
  width: 16px;
  fill: ${color.textSubdued} !important;
`;

export const SubscriptionInviteActionButtons = ({
  id,
  onOpenDeleteInviteModal,
  onOpenResendInviteModal,
}: Props) => {
  const [{ invites = [] }] = useSubscriptionUsersQueryParams();
  const { formatMessage } = useIntl();
  const handleOpenDeleteInvitesModal = () => onOpenDeleteInviteModal(id);
  const handleOpenResendInvitesModal = () => onOpenResendInviteModal(id);
  const isMultipleItemsSelected = invites.length > 0;

  if (isMultipleItemsSelected) {
    return null;
  }

  return (
    <Root>
      <IconButtonWithHelpText
        onClick={handleOpenResendInvitesModal}
        tooltipText={formatMessage({ id: 'common.resend' })}
        tooltipPlacement="bottom"
        variant="grey"
      >
        <StyledPaperplane />
      </IconButtonWithHelpText>
      <IconButtonWithHelpText
        onClick={handleOpenDeleteInvitesModal}
        tooltipText={formatMessage({ id: 'common.delete' })}
        tooltipPlacement="bottom"
        variant="grey"
      >
        <StyledTrashbin />
      </IconButtonWithHelpText>
    </Root>
  );
};
