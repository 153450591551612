import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Checkmark } from '@creditornot/cb-icons';
import { Button } from '@creditornot/cb-button';
import { Formik, Form } from 'formik';
import { typographyCss } from '@creditornot/cb-ingredients';

import { ApiErrorNotification, FormFooter } from 'components';
import { LocalizedMessage } from 'i18n';
import { useCorporate } from 'modules/corporates';
import { geocode, getLatLngFromGeocodingResult } from 'utils';
import { Corporate } from 'modules/corporates/types';
import { event } from 'modules/google-analytics';
import { useProductTour } from 'modules/product-tour';
import { useCreatePolicy } from 'modules/policies';
import { Policy } from 'modules/policies/types';
import { breakpoints } from 'modules/media';
import {
  DEFAULT_POLICY_LOCATION_RADIUS,
  DEFAULT_POLICY_TAKEAWAY_RADIUS,
} from 'modules/policies/constants';

import { formatPolicyFormValuesBeforeSending } from './formDataFormatters';
import PolicyFormFields from './PolicyFormFields';
import { PolicyFormValues } from './types';

interface Props {
  secondaryButtonText: string;
  onClose: () => void;
  onSecondaryButtonClick: () => void;
  onSubmitSuccess: (policy: Policy) => void;
  hideGroupSelect?: boolean;
  title?: string;
  className?: string;
}

const ApiErrorContainer = styled.div`
  margin: 24px;

  @media (max-width: ${breakpoints.medium}px) {
    margin: 24px 16px;
  }
`;

const Title = styled.div`
  ${typographyCss.Title1()}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 392px;
  margin-inline-end: 24px;

  @media (max-width: ${breakpoints.small}px) {
    flex-direction: column-reverse;
    gap: 12px;
    margin-inline-end: 0;
    width: unset;
  }
`;

const useInitialValues = (corporate: Corporate | null | undefined) => {
  const [initialValues, setInitialValues] = useState<PolicyFormValues>({
    all_day_delivery: false,
    delivery_days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    delivery_end_minute: 1020,
    delivery_locations: [],
    delivery_start_minute: 540,
    description: '',
    giftcard_purchases_allowed: false,
    group_ids: [],
    limit_delivery_location: 'false',
    limit_delivery_time: 'false',
    limit_spending: 'no-limit',
    name: '',
    non_restaurant_purchases_allowed: true,
    all_non_restaurant_product_lines_allowed: true,
    restaurant_purchases_allowed: true,
    allowed_non_restaurant_product_lines: [],
    order_comment_required: 'optional',
    spending_limit_amount: '',
    spending_limit_time_period: 'day',
  });

  const geocodeAddressAndSetDeliveryLocation = useCallback(async (address: string) => {
    const res = await geocode(address);
    const latLng = await getLatLngFromGeocodingResult(res[0]);

    setInitialValues((prevValues) => ({
      ...prevValues,
      delivery_locations: [
        {
          name: '',
          address: '',
          coordinates: latLng,
          radius: DEFAULT_POLICY_LOCATION_RADIUS,
          allow_takeaway: 'false',
          takeaway_radius: DEFAULT_POLICY_TAKEAWAY_RADIUS,
        },
      ],
    }));
  }, []);

  useEffect(() => {
    geocodeAddressAndSetDeliveryLocation(
      [corporate?.address, corporate?.city].filter(Boolean).join(' '),
    )
      .catch(() => {
        return geocodeAddressAndSetDeliveryLocation(
          [corporate?.post_code, corporate?.city].filter(Boolean).join(' '),
        );
      })
      .catch(() => {
        return geocodeAddressAndSetDeliveryLocation([corporate?.city].filter(Boolean).join(' '));
      })
      .catch(() => {
        return geocodeAddressAndSetDeliveryLocation([corporate?.country].filter(Boolean).join(' '));
      });
  }, [corporate, geocodeAddressAndSetDeliveryLocation]);

  return initialValues;
};

export const CreatePolicyForm: React.FC<Props> = ({
  title,
  secondaryButtonText,
  onSecondaryButtonClick,
  onSubmitSuccess,
  hideGroupSelect,
  className,
}) => {
  const createPolicy = useCreatePolicy();
  const { data: corporate } = useCorporate();
  const { isInTourMode } = useProductTour();
  const initialValues = useInitialValues(corporate);

  if (!corporate) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values: PolicyFormValues, actions) => {
        try {
          const newPolicy = await createPolicy(
            formatPolicyFormValuesBeforeSending(values, corporate.currency),
          );
          actions.setSubmitting(false);
          actions.setStatus({ submitSucceeded: true });

          if (newPolicy) {
            setTimeout(() => {
              onSubmitSuccess(newPolicy);
            }, 500);
          }

          if (isInTourMode) {
            event({
              category: 'new_user_onboarding',
              label: 'create_policy',
              action: 'create',
            });
          }
        } catch (error) {
          actions.setStatus({ error });
          actions.setSubmitting(false);
        }
      }}
    >
      {({ submitForm, status, isSubmitting }) => (
        <Form className={className}>
          {title && <Title>{title}</Title>}

          <PolicyFormFields
            currency={corporate.currency}
            corporateId={corporate.id}
            hideGroupSelect={hideGroupSelect}
          />

          {status?.error && (
            <ApiErrorContainer>
              <ApiErrorNotification error={status.error} />
            </ApiErrorContainer>
          )}

          <FormFooter
            hideBorder
            rightContent={
              <ButtonWrapper>
                <Button size="large" stretch variant="lightBlue" onClick={onSecondaryButtonClick}>
                  {secondaryButtonText}
                </Button>
                <Button
                  data-test-id="new-policy.save-button"
                  size="large"
                  stretch
                  onClick={submitForm}
                  variant="blue"
                  disabled={isSubmitting || status?.submitSucceeded}
                  loading={isSubmitting}
                  icon={status?.submitSucceeded && <Checkmark />}
                >
                  <LocalizedMessage messageKey="common.save" />
                </Button>
              </ButtonWrapper>
            }
          />
        </Form>
      )}
    </Formik>
  );
};
