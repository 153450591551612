import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled, { css } from 'styled-components';
import { Transition } from 'react-transition-group';
import { Button } from '@creditornot/cb-button';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Checkbox } from '@creditornot/cb-checkbox';
import { useMemo } from 'react';

import { LocalizedMessage } from 'i18n';
import { useMedia } from 'modules/media';

import { useUsersModalsController, useUsersQueryParams } from '../hooks';

interface Props {
  className?: string;
  numberOfUsers: number;
  numberOfInvites: number;
  onCheckBoxClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface AnimationWrapperProps {
  show: boolean;
  children?: React.ReactNode;
}

const Root = styled.div`
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  overflow: hidden;
  height: 36px;
  min-width: 300px;
  justify-content: flex-end;
`;

const Text = styled.span`
  ${typographyCss.Body3()}
  padding: 0 8px;
  white-space: nowrap;
  color: ${color.textSubdued};
`;

const AnimationComponent = styled.div<{ state: string }>`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease;
  background-color: transparent;
  max-width: ${({ state }) => {
    switch (state) {
      case 'entered':
        return '1000px';
      default:
        return '0px';
    }
  }};
  opacity: ${({ state }) => {
    switch (state) {
      case 'entered':
        return '1';
      default:
        return '0';
    }
  }};
`;

const AnimationWrapper: React.FC<AnimationWrapperProps> = ({ show, children }) => {
  return (
    <Transition
      mountOnEnter
      unmountOnExit
      in={show}
      timeout={{
        enter: 0,
        exit: 250,
      }}
    >
      {(state) => <AnimationComponent state={state}>{children}</AnimationComponent>}
    </Transition>
  );
};

const StyledButton = styled(Button)`
  white-space: nowrap;
  margin-inline-start: 16px;
  outline: none;
`;

const checkboxCss = css`
  margin-inline-end: auto;
  height: 24px;
`;

export const ResendAndDeletePanel = ({
  className,
  numberOfInvites,
  numberOfUsers,
  onCheckBoxClick,
}: Props) => {
  const mediumView = useMedia('medium');
  const { openDeleteUsersModal, openDeleteInvitesModal, openResendUserInvitesModal } =
    useUsersModalsController();

  const [{ showInvites, users: userIdsQueryParam = [], invites: userInviteIdsQueryParam = [] }] =
    useUsersQueryParams();

  const showDeleteUsersButton = useMemo(() => !!userIdsQueryParam?.length, [userIdsQueryParam]);

  const showDeleteInvitesButton = useMemo(
    () => !!userInviteIdsQueryParam?.length,
    [userInviteIdsQueryParam],
  );

  const showResendButton = useMemo(
    () => !userIdsQueryParam?.length && !!userInviteIdsQueryParam?.length,
    [userIdsQueryParam, userInviteIdsQueryParam],
  );

  const totalNumberOfUsersSelected = useMemo(
    () => (userIdsQueryParam?.length ?? 0) + (userInviteIdsQueryParam?.length ?? 0),
    [userIdsQueryParam, userInviteIdsQueryParam],
  );

  return (
    <Root className={className}>
      {mediumView ? (
        <Checkbox
          css={checkboxCss}
          partiallyChecked={
            showInvites
              ? userInviteIdsQueryParam.length < numberOfInvites
              : userIdsQueryParam.length < numberOfUsers
          }
          checked={
            showInvites
              ? userInviteIdsQueryParam.length === numberOfInvites
              : userIdsQueryParam.length === numberOfUsers
          }
          onChange={onCheckBoxClick}
        >
          <LocalizedMessage
            messageKey="common.selected_number"
            values={{ number: totalNumberOfUsersSelected }}
          />
        </Checkbox>
      ) : (
        <AnimationWrapper show={!!totalNumberOfUsersSelected}>
          <Text>
            <LocalizedMessage
              messageKey="common.selected_number"
              values={{ number: totalNumberOfUsersSelected }}
            />
          </Text>
        </AnimationWrapper>
      )}

      <AnimationWrapper show={showResendButton}>
        <StyledButton size="small" onClick={openResendUserInvitesModal} variant="lightBlue">
          <LocalizedMessage messageKey="common.resend" />
        </StyledButton>
      </AnimationWrapper>

      <AnimationWrapper show={showDeleteUsersButton}>
        <StyledButton size="small" onClick={openDeleteUsersModal} variant="lightRed">
          <LocalizedMessage messageKey="common.delete" />
        </StyledButton>
      </AnimationWrapper>

      <AnimationWrapper show={showDeleteInvitesButton}>
        <StyledButton size="small" onClick={openDeleteInvitesModal} variant="lightRed">
          <LocalizedMessage messageKey="common.delete" />
        </StyledButton>
      </AnimationWrapper>
    </Root>
  );
};
