import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { Button } from '@creditornot/cb-button';
import { typographyCss } from '@creditornot/cb-ingredients';
import { FormattedMessage, useIntl } from 'react-intl';
import { DiscountCoupon, GroupAdd, WoltPaperBag } from '@creditornot/cb-icons';

import { subscriptionIntroView } from 'telemetry/Avo';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { ResponsiveContainer } from 'components';
import env from 'config/env';
import { breakpoints, useMedia } from 'modules/media';
import { SubscriptionPrice } from 'modules/subscription/types';
import { LocalizedNumber } from 'i18n';

import { InformationSection } from './InformationSection';

type Props = {
  currency: string;
  onGetStartedClick: () => void;
  subscriptionPrice: SubscriptionPrice['monthly'] | undefined;
};

const Root = styled(ResponsiveContainer)`
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to top, ${color.bg} 0%, ${color.bgSurfaceBrand} 50%);
  height: 720px;
  background-size: 100% 720px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  max-width: 840px;
  align-items: center;
  margin-top: 32px;
`;

const HeaderLeftContent = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
`;

const HeaderRightContent = styled.div`
  display: flex;
  flex: 2;
`;

const ImageWrapper = styled.div`
  margin: 0 auto;
  width: 420px;
  max-width: 420px;
  margin-inline-end: -16px;

  @media (max-width: ${breakpoints.large}px) {
    max-width: 340px;
  }
`;

const Image = styled.div<{ src: string }>`
  /*
  css for aspect ratio 1:1

  Aspect ratio is used so that the content doesn't jump around when the image is
  loading.
  */
  height: 0;
  padding-top: 100%;
  background: url(${({ src }) => src});
  background-size: contain;
`;

const Title = styled.h2`
  ${typographyCss.Heading2()}
  font-weight: bold;

  @media (max-width: ${breakpoints.medium}px) {
    ${typographyCss.Heading3()}
    font-weight: bold;
  }
`;

const BrandTitle = styled.span`
  color: ${color.textBrand};
`;

const Subtitle = styled.h4`
  ${typographyCss.Heading4()}
  font-weight: bold;
  margin-top: 8px;

  @media (max-width: ${breakpoints.medium}px) {
    ${typographyCss.Heading5()}
    font-weight: bold;
  }
`;

const ContentHeading = styled.h4`
  ${typographyCss.Heading4()}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  max-width: 840px;
  margin-top: -36px;
  padding-bottom: 72px;

  @media (max-width: ${breakpoints.large}px) {
    margin-top: 20px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;

  @media (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  max-width: 90%;
  margin-top: 24px;

  @media (max-width: ${breakpoints.medium}px) {
    margin-top: 60px;
    margin-bottom: 40px;
    max-width: unset;
  }
`;

const StyledWoltPaperBag = styled(WoltPaperBag)`
  margin-bottom: 4px;
`;

const StyledGroupAdd = styled(GroupAdd)`
  margin-inline-start: 2px;
`;

export const SubscriptionLandingPage = ({
  onGetStartedClick,
  subscriptionPrice,
  currency,
}: Props) => {
  const { formatMessage } = useIntl();
  const isMediumView = useMedia('medium');

  return (
    <Root>
      <ViewEventComponent event={subscriptionIntroView} />
      <Header>
        <HeaderLeftContent>
          <Title>
            <FormattedMessage
              id="wolt-plus.landing-view.title"
              values={{
                brand: (msg) => <BrandTitle>{msg}</BrandTitle>,
              }}
            />
          </Title>
          {subscriptionPrice && (
            <Subtitle>
              <FormattedMessage
                id="wolt-plus.landing-view.subtitle"
                values={{
                  price: (
                    <LocalizedNumber
                      value={subscriptionPrice?.amount}
                      currency={subscriptionPrice?.currency}
                    />
                  ),
                }}
              />
            </Subtitle>
          )}
          <StyledButton
            variant="blue"
            size="large"
            stretch
            onClick={onGetStartedClick}
            data-test-id="wolt-plus-get-started-button"
          >
            <FormattedMessage id="wolt-plus.landing-view.button-text" />
          </StyledButton>
        </HeaderLeftContent>
        {!isMediumView && (
          <HeaderRightContent>
            <ImageWrapper>
              <Image
                src={`${env.CONSUMER_ASSETS_URI}/frontpage-assets/wolt-plus/illu-wolt-plus-rider.png`}
              />
            </ImageWrapper>
          </HeaderRightContent>
        )}
      </Header>
      <ContentContainer>
        <ContentHeading>
          <FormattedMessage id="wolt-plus.promo-section-title" />
        </ContentHeading>
        <Content>
          <InformationSection
            icon={<StyledWoltPaperBag />}
            title={formatMessage(
              { id: 'wolt-plus.promo.free-delivery-title' },
              { price: <LocalizedNumber currency={currency} value={0} /> },
            )}
            content={formatMessage({ id: 'wolt-plus.promo.free-delivery-text' })}
          />
          <InformationSection
            icon={<DiscountCoupon />}
            title={formatMessage({ id: 'wolt-plus.promo.exclusive-offers-title' })}
            content={formatMessage({ id: 'wolt-plus.promo.exclusive-offers-text' })}
          />
          <InformationSection
            icon={<StyledGroupAdd />}
            title={formatMessage({ id: 'wolt-plus.promo.invoicing-title' })}
            content={formatMessage({ id: 'wolt-plus.promo.invoicing-text' })}
          />
        </Content>
      </ContentContainer>
    </Root>
  );
};
