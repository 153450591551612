import { useFetchConsents } from 'modules/corporates';

import { Consents } from '../SignupV2/components/Consents/Consents';

export const ConsentsContainer = () => {
  const { data } = useFetchConsents();

  if (!data?.consents?.length) {
    return null;
  }

  return <Consents consents={data.consents} />;
};
