import { FormikInputField } from 'components';
import { createValidator, isNumber, min, max, isRequired } from 'validation';

const validate = createValidator([isNumber, min(0.1), max(100), isRequired]);

interface Props {
  alwaysShowError?: boolean;
}

export const PercentageDiscountField = ({ alwaysShowError }: Props) => {
  return (
    <FormikInputField
      name="percentage_discount"
      type="text"
      validate={validate}
      alwaysShowError={alwaysShowError}
      inputProps={{
        suffixText: '%',
      }}
    />
  );
};
