import { DeliveryArea, DeliveryAreaType, Policy } from 'modules/policies/types';
import { PolicyData } from 'modules/policies/api';
import { convertCentsToDisplayAmount, convertFromAmountToCents } from 'utils';
import {
  DEFAULT_POLICY_LOCATION_RADIUS,
  DEFAULT_POLICY_TAKEAWAY_RADIUS,
} from 'modules/policies/constants';

import { PolicyFormValues } from './types';

const formatLimitSpendingDataToFormValues = (data: Policy): PolicyFormValues['limit_spending'] => {
  // For later use
  // W@W with allow user to exclude delivery fee from
  // personal budget
  // if (data.budget_allowance?.exclude_delivery_fee) {
  //   return 'exclude-delivery-fee';
  // }

  return data.budget_allowance?.amount ? 'has-limit' : 'no-limit';
};

const formatDeliveryAreasToFormValues = (
  areas: DeliveryArea[],
): PolicyFormValues['delivery_locations'] => {
  const deliveryAreas = areas.filter((area) => area.delivery_type === 'delivery');
  const takeawayAreas = areas.filter((area) => area.delivery_type === 'takeaway');

  return deliveryAreas.map((deliveryArea) => {
    // Every delivery area can have a connected takeaway area with the same name
    const takeawayArea = takeawayAreas.find(
      (takeawayArea) => takeawayArea.name === deliveryArea.name,
    );
    return {
      name: deliveryArea.name,
      address: '',
      coordinates: { lat: deliveryArea.latitude, lng: deliveryArea.longitude },
      radius: deliveryArea.radius_meters,
      allow_takeaway: takeawayArea ? 'true' : 'false',
      takeaway_radius: takeawayArea?.radius_meters || DEFAULT_POLICY_TAKEAWAY_RADIUS,
    };
  });
};

const formatDeliveryLocationBeforeSending = (
  location: PolicyFormValues['delivery_locations'][number],
  radius?: number,
  deliveryType: DeliveryAreaType = 'delivery',
): PolicyData['delivery_areas'][number] => {
  return {
    name: location.name,
    radius_meters: radius || location.radius,
    latitude: location.coordinates.lat,
    longitude: location.coordinates.lng,
    delivery_type: deliveryType,
  };
};

const mapDeliveryLocationsBeforeSending = (
  locations: PolicyFormValues['delivery_locations'],
): PolicyData['delivery_areas'] => {
  return locations.flatMap((location) =>
    location.allow_takeaway === 'true'
      ? [
          formatDeliveryLocationBeforeSending(location, location.takeaway_radius, 'takeaway'),
          formatDeliveryLocationBeforeSending(location),
        ]
      : formatDeliveryLocationBeforeSending(location),
  );
};

export const formatPolicyFormValuesBeforeSending = (
  values: PolicyFormValues,
  currency: string,
): PolicyData => {
  return {
    budget_allowance:
      values.limit_spending === 'no-limit'
        ? {}
        : {
            amount: convertFromAmountToCents(values.spending_limit_amount, currency),
            period: values.spending_limit_time_period,
            exclude_delivery_fee: values.limit_spending === 'exclude-delivery-fee',
          },
    delivery_areas:
      values.limit_delivery_location === 'false'
        ? []
        : mapDeliveryLocationsBeforeSending(values.delivery_locations),
    description: values.description,
    giftcard_purchases_allowed: values.giftcard_purchases_allowed,
    group_ids: values.group_ids,
    name: values.name,
    non_restaurant_purchases_allowed: values.non_restaurant_purchases_allowed,
    all_non_restaurant_product_lines_allowed: values.all_non_restaurant_product_lines_allowed,
    restaurant_purchases_allowed: values.restaurant_purchases_allowed,
    allowed_non_restaurant_product_lines: values.allowed_non_restaurant_product_lines,
    order_comment_required: values.order_comment_required === 'optional' ? false : true,
    times:
      values.limit_delivery_time === 'false'
        ? []
        : values.delivery_days.map((day) => ({
            day_of_week: day,
            start_minute: values.delivery_start_minute,
            end_minute: values.delivery_end_minute,
          })),
  };
};

export const formatPolicyDataToEditPolicyFormValues = (
  data: Policy,
  currency: string,
  deliveryLocationCoordinates?: { lat: number; lng: number },
): PolicyFormValues => {
  return {
    all_day_delivery: data.times[0]?.start_minute === 0 && data.times[0]?.end_minute === 1440,
    delivery_days: data.times.length
      ? data.times.map((restriction) => restriction.day_of_week)
      : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    delivery_end_minute: data.times[0]?.end_minute || 1020,
    delivery_locations: data.delivery_areas.length
      ? formatDeliveryAreasToFormValues(data.delivery_areas)
      : [
          {
            name: '',
            address: '',
            coordinates: deliveryLocationCoordinates || { lat: 0, lng: 0 },
            radius: DEFAULT_POLICY_LOCATION_RADIUS,
            allow_takeaway: 'false',
            takeaway_radius: DEFAULT_POLICY_TAKEAWAY_RADIUS,
          },
        ],
    delivery_start_minute: data.times[0]?.start_minute || 540,
    description: data.description,
    giftcard_purchases_allowed: data.giftcard_purchases_allowed,
    group_ids: data.group_ids,
    limit_delivery_location: data.delivery_areas.length ? 'true' : 'false',
    limit_delivery_time: data.times.length ? 'true' : 'false',
    limit_spending: formatLimitSpendingDataToFormValues(data),
    name: data.name,
    non_restaurant_purchases_allowed: data.non_restaurant_purchases_allowed,
    all_non_restaurant_product_lines_allowed: data.all_non_restaurant_product_lines_allowed,
    restaurant_purchases_allowed: data.restaurant_purchases_allowed,
    allowed_non_restaurant_product_lines: data.allowed_non_restaurant_product_lines || [],
    order_comment_required: data.order_comment_required ? 'required' : 'optional',
    spending_limit_amount: data.budget_allowance?.amount
      ? convertCentsToDisplayAmount(data.budget_allowance.amount, currency)
      : '',
    spending_limit_time_period: data.budget_allowance?.period
      ? data.budget_allowance.period
      : 'day',
  };
};
