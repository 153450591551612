import styled from 'styled-components';
import { Paperplane, Trashbin } from '@creditornot/cb-icons';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { IconButton } from '@creditornot/cb-button';

import { LocalizedMessage } from 'i18n';

type ActionButtonsProps = {
  onResendInviteClick?: () => void;
  onDeleteClick: () => void;
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledPopover = styled(Popover)`
  height: 36px;
`;

export const ActionButtons = ({ onDeleteClick, onResendInviteClick }: ActionButtonsProps) => {
  return (
    <Root>
      {onResendInviteClick && (
        <StyledPopover
          renderArrow={false}
          content={<LocalizedMessage messageKey="users.resend-invite-button" />}
          placement="bottom"
        >
          <IconButton
            background="animated"
            variant="grey"
            size="small"
            onClick={onResendInviteClick}
          >
            <Paperplane height="16px" width="16px" />
          </IconButton>
        </StyledPopover>
      )}
      {onDeleteClick && (
        <StyledPopover
          renderArrow={false}
          content={<LocalizedMessage messageKey="common.delete" />}
          placement="bottom"
        >
          <IconButton background="animated" variant="grey" size="small" onClick={onDeleteClick}>
            <Trashbin height="16px" width="16px" />
          </IconButton>
        </StyledPopover>
      )}
    </Root>
  );
};
