import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { Button } from '@creditornot/cb-button';
import { Checkmark } from '@creditornot/cb-icons';
import { typographyCss } from '@creditornot/cb-ingredients';
import { FormattedDate } from 'react-intl';

import { ResponsiveContainer, ApiErrorNotification, FormFooter } from 'components';
import { useEditCountryConfig } from 'modules/country-configs';
import { CountryConfig } from 'modules/country-configs/types';
import { breakpoints } from 'modules/media';

import { CountryConfigFormFields } from './CountryConfigFormFields';
import {
  formatCountryConfigApiDataToFormValues,
  formatEditCountryConfigFormValuesToSubmitData,
} from './utils';

const Root = styled.div`
  width: 100%;
  max-width: 783px;
  margin: 0 auto auto;
  padding-bottom: 24px;

  @media (max-width: ${breakpoints.medium}px) {
    margin-top: 0;
  }
`;

const HeaderSection = styled(ResponsiveContainer)`
  padding-top: 42px;
  flex-direction: column;

  @media (max-width: ${breakpoints.medium}px) {
    padding: 24px;
  }
`;

const Title = styled.h6`
  ${typographyCss.Heading6()}
`;

const LastEditedSubtitle = styled.h6`
  ${typographyCss.Small()}
`;

interface Props {
  countryCode?: string;
  countryFlag?: string;
  countryName?: string;
  countryConfig: CountryConfig;
  currency?: string;
  onEditSuccess: () => void;
}

const StyledForm = styled(Form)`
  padding: 24px 24px 0;

  @media (max-width: ${breakpoints.medium}px) {
    padding: 12px 8px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 196px;
  margin-inline-end: 24px;
  justify-content: flex-end;

  @media (max-width: ${breakpoints.small}px) {
    margin-inline-end: 0;
    width: unset;
  }
`;

export const EditCountryConfigForm = ({
  countryCode,
  countryName,
  countryFlag,
  countryConfig,
  currency,
  onEditSuccess,
}: Props) => {
  const editCountryConfig = useEditCountryConfig();
  const initialValues = formatCountryConfigApiDataToFormValues(countryConfig);

  return (
    <Root>
      <HeaderSection>
        <Title>{`${countryName} ${countryFlag}`}</Title>
        <LastEditedSubtitle>
          Last edited on <FormattedDate value={new Date(countryConfig.modified_at)} />
        </LastEditedSubtitle>
      </HeaderSection>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values, actions) => {
          if (!countryCode || !currency) {
            actions.setSubmitting(false);
            actions.setStatus({ error: 'Country code or currency is missing' });
            return;
          }
          try {
            await editCountryConfig(
              countryConfig.id,
              formatEditCountryConfigFormValuesToSubmitData(values, initialValues, currency),
            );

            actions.setSubmitting(false);
            actions.setStatus({ submitSucceeded: true });

            setTimeout(() => {
              onEditSuccess();
            }, 500);
          } catch (error) {
            actions.setSubmitting(false);
            actions.setStatus({ error });
          }
        }}
      >
        {({ isSubmitting, status, handleSubmit, dirty, isValid }) => {
          return (
            <>
              <StyledForm>
                <CountryConfigFormFields alwaysShowError={true} currency={currency} />

                {status?.error && <ApiErrorNotification error={status?.error} />}
              </StyledForm>

              <FormFooter
                hideBorder
                rightContent={
                  <ButtonWrapper>
                    <Button
                      stretch
                      size="large"
                      onClick={() => handleSubmit()}
                      icon={status?.submitSucceeded && <Checkmark />}
                      disabled={isSubmitting || !isValid || !dirty || status?.submitSucceeded}
                      loading={isSubmitting}
                      variant="blue"
                    >
                      Save
                    </Button>
                  </ButtonWrapper>
                }
              />
            </>
          );
        }}
      </Formik>
    </Root>
  );
};
