import { useTelemetryViewEvent } from 'telemetry/hooks/useTelemetryEvents';

/**
 * Simply triggers AVO event when rendered
 */
export const ViewEventComponent = ({ event }: { event: () => void }) => {
  useTelemetryViewEvent(event);

  return null;
};
