import { FormHeader, FormRow } from '@creditornot/cb-form';
import styled from 'styled-components';
import { useState } from 'react';
import { Button } from '@creditornot/cb-button';
import { FormattedMessage } from 'react-intl';
import { rem } from 'polished';

import { useFetchB2bConfig } from 'modules/b2b-configs';
import { ApiErrorNotification, HiddenFromCompany, Spinner } from 'components';
import { useNotificationToast } from 'modules/app-modals';

import { EditTrackBrandingSettings } from './EditTrackBrandingSettings';
import { Preview } from './Preview';

const Root = styled.div``;

type TrackBrandingSettingsProps = {
  className?: string;
  corporateId: string;
  corporateName: string;
};

const ApiErrorNotificationContainer = styled.div`
  padding: ${rem(24)};
`;

const LoadingContainer = styled.div`
  padding: ${rem(24)};
  display: flex;
  justify-content: center;
`;

/**
 * Edit corporate branding config used in Wolt Drive track page
 * @returns
 */
export const TrackBrandingSettings = ({
  className,
  corporateId,
  corporateName,
}: TrackBrandingSettingsProps) => {
  const [mode, setMode] = useState<'edit' | 'view'>('view');

  const { openNotificationToast } = useNotificationToast();
  const {
    data: b2bConfig,
    isLoading,
    error,
  } = useFetchB2bConfig(corporateId, {
    onSuccess: (data) => {
      setMode(data ? 'view' : 'edit');
    },
  });

  const switchToEdit = () => {
    setMode('edit');
  };

  const switchToView = () => {
    setMode('view');
  };

  const handleEditSuccess = () => {
    openNotificationToast('TRACK_BRANDING_PUBLISHED', {
      variant: 'success',
      autoCloseIn: 10 * 1000,
      title: 'Done!',
      children:
        'A tracking page with custom brand will be sent to recipients the next time a delivery is created.',
    });
    switchToView();
  };

  return (
    <Root className={className}>
      <FormHeader
        horizontalPadding
        rightContent={
          mode === 'view' &&
          !error && (
            <Button variant="lightBlue" size="small" onClick={switchToEdit}>
              <FormattedMessage id="common.edit" />
            </Button>
          )
        }
      >
        Delivery tracking
      </FormHeader>
      {error && (
        <ApiErrorNotificationContainer>
          <ApiErrorNotification error={error} />
        </ApiErrorNotificationContainer>
      )}
      {isLoading && (
        <LoadingContainer>
          <Spinner size="medium" />
        </LoadingContainer>
      )}
      {b2bConfig !== undefined && (
        <>
          {mode === 'view' && (
            <>
              <FormRow
                horizontalPadding
                label="Branding"
                rightColStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {b2bConfig.corporate_branding ? 'Custom brand' : 'Wolt brand'}
                <HiddenFromCompany />
              </FormRow>
              {b2bConfig.corporate_branding && (
                <FormRow horizontalPadding label="Preview">
                  <Preview
                    corporateBranding={b2bConfig.corporate_branding}
                    pickupLocationName={corporateName}
                    rightHeaderContent={<HiddenFromCompany />}
                  />
                </FormRow>
              )}
            </>
          )}
          {mode === 'edit' && (
            <EditTrackBrandingSettings
              b2bConfig={b2bConfig}
              corporateId={corporateId}
              corporateName={corporateName}
              onCancel={switchToView}
              onEditSuccess={handleEditSuccess}
            />
          )}
        </>
      )}
    </Root>
  );
};
