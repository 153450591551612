import { useMemo } from 'react';

import { useCorporate } from 'modules/corporates';

import { useFetchCountryConfigs } from './useFetchCountryConfigs';

export const useCountryConfig = (country?: string) => {
  const { data: corporate, isLoading: isLoadingCorporate } = useCorporate();
  const { data: countryConfigs, isLoading: isLoadingConfigs } = useFetchCountryConfigs();
  const usedCountry = country ?? corporate?.country;
  const countryConfig = useMemo(
    () => countryConfigs?.find((cc) => cc.country_code === usedCountry),
    [countryConfigs, usedCountry],
  );
  return { data: countryConfig, isLoading: isLoadingCorporate || isLoadingConfigs };
};
