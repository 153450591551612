import { color } from '@creditornot/cb-ingredients/design-tokens';
import { ComponentType, useMemo } from 'react';
import { Select, SelectProps, SelectOptionComponentProps } from '@creditornot/cb-select';
import { MenuActionItem } from '@creditornot/cb-menu';
import { typographyCss } from '@creditornot/cb-ingredients';
import styled, { css } from 'styled-components';
import { Alert } from '@creditornot/cb-alert';

import { useI18n } from 'i18n';
import { isDefined, processError } from 'utils';

import { Policy } from '../types';
import { PolicyRestrictions } from './policy-restrictions';
import { useFetchPolicies } from '../useFetchPolicies';

export type PolicyOption = { label: string; value: string; policy: Policy; isDisabled: boolean };

export type PolicySelectProps = Partial<SelectProps<PolicyOption>>;

const SelectedPolicyRestrictions = styled(PolicyRestrictions)`
  color: ${color.textSubdued};
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
`;

const OptionPolicyRestrictions = styled(PolicyRestrictions)`
  white-space: normal;
`;

const Name = styled.span`
  ${typographyCss.Body3()}
`;

const PolicyRestriction = styled.small`
  ${typographyCss.Small()}
`;

// Hack tweak select style to match design
const selectCss = css`
  & > div:first-of-type {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`.toString();

const PolicyOption: ComponentType<SelectOptionComponentProps<PolicyOption>> = ({
  option: { policy, isDisabled },
  innerRef,
  ...props
}) => {
  return (
    <MenuActionItem
      data-test-id={`policy-item-${policy.id}`}
      variant="black"
      {...props}
      ref={innerRef}
      disabled={isDisabled}
    >
      <div>
        <Name>{policy.name}</Name>
      </div>
      <PolicyRestriction>
        <OptionPolicyRestrictions policy={policy} />
      </PolicyRestriction>
    </MenuActionItem>
  );
};

export const PolicySelect = ({ value, ...props }: PolicySelectProps) => {
  const { getLocalizedMessage } = useI18n();
  const {
    data: policies,
    isFetching: isFetchingPolicies,
    error: policiesError,
  } = useFetchPolicies();

  const placeholder = useMemo(
    () =>
      [
        isFetchingPolicies ? getLocalizedMessage('policies.policy-select.loading') : undefined,
        getLocalizedMessage('policies.policy-select.placeholder'),
      ].filter(isDefined)[0],
    [getLocalizedMessage, isFetchingPolicies],
  );

  if (policiesError) {
    return (
      <Alert title={processError(policiesError).message} variant="error" size="medium">
        {processError(policiesError).data}
      </Alert>
    );
  }

  return (
    <Select
      css={selectCss}
      optionComponent={PolicyOption}
      options={policies?.map((policy) => ({
        label: getLocalizedMessage('policies.policy-select.label', {
          name: (
            <div key={policy.name}>
              <Name>{policy.name}</Name>
            </div>
          ),
          policy: <SelectedPolicyRestrictions key={policy.id} policy={policy} />,
        }),
        isDisabled: value === policy.id,
        value: policy.id,
        policy,
      }))}
      placeholder={placeholder}
      clearable
      disableSearch
      value={value}
      {...props}
    />
  );
};
