import * as t from 'io-ts';

import { PersonName } from 'modules/common/types';

export type UserRole = t.TypeOf<typeof UserRole>;
export const UserRole = t.keyof({
  'restaurant.pos': null,
  admin: null,
  all_corporates: null,
  all_insights: null,
  all_menu_translations: null,
  all_menus: null,
  all_merchant_admin_permissions: null,
  all_merchants: null,
  all_payouts: null,
  all_purchases: null,
  all_venue_groups: null,
  all_venues: null,
  corporate_manager: null,
  courier_ops_manager: null,
  courier: null,
  developer: null,
  discovery_tester: null,
  employee: null,
  employee_discount: null,
  enterprise_owner: null,
  item_pricings: null,
  marketing_manager: null,
  merchant_owner: null,
  merchant: null,
  ops_manager: null,
  restaurant_manager: null,
  support_associate: null,
  user: null,
  venue_manager: null,
  venue_worker: null,
  wm_content_manager: null,
});

export type Role = t.TypeOf<typeof Role>;
export const Role = t.type({
  resource_id: t.string,
  resource_type: t.keyof({
    corporate: null,
    venue: null,
  }),
  role: UserRole,
});

export type AccessDetailsPermissions = t.TypeOf<typeof AccessDetailsPermissions>;
export const AccessDetailsPermissions = t.type({
  action: t.string,
  description: t.string,
  name: t.string,
  resource_id: t.string,
});

export type UserGroup = t.TypeOf<typeof UserGroup>;
export const UserGroup = t.type({
  active: t.boolean,
  id: t.string,
  name: t.string,
});

export type UserStatus = t.TypeOf<typeof UserStatus>;
export const UserStatus = t.keyof({
  active: null,
  disabled: null,
});

export type User = t.TypeOf<typeof User>;
export const User = t.type({
  groups: t.array(UserGroup),
  access_details: t.type({
    permissions: t.array(AccessDetailsPermissions),
  }),
  disabled: t.boolean,
  email: t.string,
  full_name: t.string,
  id: t.string,
});

export type UserV2BasicInfo = t.TypeOf<typeof UserV2BasicInfo>;
export const UserV2BasicInfo = t.type({
  email: t.string,
  user_id: t.string,
  name: PersonName,
});

export type UserV2 = t.TypeOf<typeof UserV2>;
export const UserV2 = t.intersection([
  UserV2BasicInfo,
  t.type({
    groups: t.array(UserGroup),
    is_admin: t.boolean,
    status: UserStatus,
  }),
]);

export type WoltUser = t.TypeOf<typeof WoltUser>;
export const WoltUser = t.partial({
  user: t.type({
    country: t.string,
    disabled: t.boolean,
    email_verified: t.boolean,
    email: t.string,
    id: t.type({ $oid: t.string }),
    language: t.union([t.undefined, t.string]),
    name: t.type({ first_name: t.string, last_name: t.string }),
    phone_number_verified: t.boolean,
    phone_number: t.string,
    profile_picture: t.type({
      id: t.type({
        $oid: t.string,
      }),
      url: t.string,
    }),
    roles: t.array(UserRole),
    time_created: t.type({ $date: t.number }),
  }),
});

export interface WoltUserNonNullable extends WoltUser {
  user: NonNullable<WoltUser['user']>;
}
