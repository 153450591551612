import * as t from 'io-ts';

import { logValidationError } from 'validation';
import { restaurantApiAgent } from 'apiAgents';

const MongoObjectId = t.type({ $oid: t.string });

const MongoDate = t.type({ $date: t.number });

const UserAccountSettings = t.intersection([
  t.type({
    pushMarketingOptOut: t.boolean,
    sendEmailReceipts: t.boolean,
    userHomeCountry: t.string,
  }),
  t.partial({
    internal: t.type({
      REFERRAL_PROMO_SHOWN: t.boolean,
      USER_HAS_ACCEPTED_ALCOHOL: t.boolean,
    }),
  }),
]);

// https://github.com/creditornot/wolt.com/blob/cc551651fb6049eb55a27904b617d6a0338416ec/src/client/js/common-v2/api/fetchUserAccountV1.ts#L10
export type UserAccountResult = t.TypeOf<typeof UserAccountResult>;
const UserAccountResult = t.type({
  user: t.intersection([
    t.type({
      _id: MongoObjectId,
      country: t.string,
      currency: t.string,
      credit_code: t.intersection([
        t.type({
          block_if_existing_credits: t.boolean,
          code: t.string,
          code_type: t.union([t.literal('tokens'), t.literal('credits')]),
          credit_to_acquirer: t.number,
          credit_to_issuer: t.number,
          currency: t.string,
          deeplink: t.string,
          delivery_methods: t.array(t.string),
          delivery_only: t.boolean,
          expiration_delay_days: t.number,
          greeting: t.string,
          is_registration_code: t.boolean,
          issuer: MongoObjectId,
          issuer_type: t.string,
          modified_at: MongoDate,
          staggered_token_expiration: t.boolean,
          tokens_to_acquirer: t.number,
          tokens_to_issuer: t.number,
          type: t.string,
          usable_after_first_purchase: t.boolean,
          venues: t.array(
            t.type({
              id: t.string,
              name: t.string,
            }),
          ),
        }),
        t.partial({
          split_credit_count: t.number,
        }),
      ]),
      email: t.string,
      email_verified: t.boolean,
      name: t.type({
        first_name: t.string,
        last_name: t.string,
      }),
      phone_number_verified: t.boolean,
      profile_photo: t.type({
        id: MongoObjectId,
        url: t.string,
      }),
      profile_picture: t.type({
        id: MongoObjectId,
        url: t.string,
      }),
    }),
    t.partial({
      admin: t.boolean,
      branchio_link: t.string,
      can_edit_referral_code: t.boolean,
      customer_support_phone: t.string,
      deep_link: t.string,
      deletion_state: t.intersection([
        t.type({
          requested_at: MongoDate,
          deletion_at: MongoDate,
          reason: t.string,
        }),
        t.partial({
          comment: t.string,
        }),
      ]),
      disabled: t.boolean,
      language: t.string,
      intercom_user_hash: t.string,
      is_first_time_user: t.boolean,
      password_exists: t.boolean,
      phone_number: t.string,
      purchase_count: t.number,
      roles: t.array(t.string),
      settings: UserAccountSettings,
      time_created: MongoDate,
      translation_settings: t.type({
        always_translate: t.array(t.string),
        always_translate_into: t.record(t.string, t.string),
        never_translate: t.array(t.string),
      }),
    }),
  ]),
});

export const fetchWoltUserAccount = async () => {
  const { data } = await restaurantApiAgent.get<UserAccountResult>('/v1/user/me');
  logValidationError('fetchWoltUserAccount', UserAccountResult, data);
  return data;
};
