import { color } from '@creditornot/cb-ingredients/design-tokens';
import { MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { MenuActionItem, MenuItemGroup } from '@creditornot/cb-menu';
import { Logout } from '@creditornot/cb-icons';
import { typographyCss } from '@creditornot/cb-ingredients';
import type { User } from '@creditornot/wolt-auth';

import { LocalizedMessage } from 'i18n';
import { Divider, UserMenuButton } from 'components';

export type UserDropdownProps = {
  className?: string;
  onLogoutClick: MouseEventHandler<HTMLButtonElement>;
  user: User;
};

const StyledMenuItemGroup = styled(MenuItemGroup)`
  /* Add diviver above the last MenuItem element */
  padding: 12px 16px;
  overflow: hidden;
  ${Divider} {
    margin-inline-start: -100px;
    width: 200%;
  }
`;

const Container = styled.div`
  flex: 1;
`;

const Username = styled.div`
  ${typographyCss.Body3()}
`;

const Email = styled.small`
  ${typographyCss.Small()}
  color: ${color.textSubdued};
`;

export const UserDropdown = ({ onLogoutClick, user, className }: UserDropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Popover
      show={open}
      onClickOutside={() => setOpen(false)}
      contentZIndex={999}
      showOnHover={false}
      placement="bottom-end"
      renderArrow={false}
      closeOnClickInside={true}
      disableContentPadding={true}
      className={className}
      content={
        <StyledMenuItemGroup>
          <Container>
            <Username>{`${user.name.first_name} ${user.name.last_name}`}</Username>
            <Email>{user.email}</Email>
          </Container>
          <MenuActionItem onClick={onLogoutClick} icon={<Logout />} variant="black">
            <LocalizedMessage messageKey="navigation.logout" />
          </MenuActionItem>
        </StyledMenuItemGroup>
      }
    >
      <UserMenuButton
        caretDirection={open ? 'up' : 'down'}
        user={user}
        onClick={() => setOpen((state) => !state)}
      />
    </Popover>
  );
};
