import IntlMessageFormat from 'intl-messageformat';

import type { MessageIds } from './types';

export const getFinalMinimumFractionDigits = (
  minimumFractionDigits?: number,
  currency?: string,
) => {
  if (minimumFractionDigits === 0) {
    return 0;
  }

  if (!!currency && !minimumFractionDigits) {
    return 2;
  }

  if (!minimumFractionDigits) {
    return 0;
  }

  return minimumFractionDigits;
};

export const getFinalMaximumFractionDigits = (
  maximumFractionDigits?: number,
  currency?: string,
) => {
  if (maximumFractionDigits === 0) {
    return 0;
  }

  if (!!currency && !maximumFractionDigits) {
    return 2;
  }

  if (!maximumFractionDigits) {
    return 20;
  }

  return maximumFractionDigits;
};

export const getMessageValue = (
  messages?: Record<string, string>,
  messageKey?: MessageIds,
): string | undefined => {
  try {
    if (!messageKey || !messages) {
      return undefined;
    }
    return messages[messageKey];
  } catch (error) {
    return messageKey;
  }
};

export const formatMessage = (
  message: string,
  locale: string,
  values?: {
    [key: string]: any;
  },
) => {
  try {
    return new IntlMessageFormat(message, locale).format(values);
  } catch {
    return message;
  }
};

type CurrencyConfig = {
  /**
   * Number of decimals stored for this currency. This should reflect what we
   * receive from the backend, for example:
   *
   * - JPY we recieve 1000, 0 of these are decimals, so it represents 1000
   * - EUR we recieve 1000, 2 of these are decimals, so it represents 10.00
   */
  decimals: number;
  /** Number of decimals to display for this currency */
  formatDecimals: number;
};

const DEFAULT_CURRENCY_CONFIG: CurrencyConfig = {
  decimals: 2,
  formatDecimals: 2,
};

const CURRENCY_CONFIGS: Record<string, Partial<CurrencyConfig>> = {
  HUF: {
    formatDecimals: 0,
  },
  JPY: {
    decimals: 0,
    formatDecimals: 0,
  },
  ISK: {
    decimals: 0,
    formatDecimals: 0,
  },
  KZT: {
    formatDecimals: 0,
  },
};

export const getCurrencyConfig = (currency: string): CurrencyConfig => ({
  ...DEFAULT_CURRENCY_CONFIG,
  ...(CURRENCY_CONFIGS[currency] || {}),
});

export const parseCurrency = (value: number, currency: string) => {
  const currencyConfig = getCurrencyConfig(currency);
  return value / Math.pow(10, currencyConfig.decimals);
};

export const parseIntoCents = (value: number, currency: string) => {
  const currencyConfig = getCurrencyConfig(currency);
  return value * Math.pow(10, currencyConfig.decimals);
};
