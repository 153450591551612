import { useContext } from 'react';

import { AppModalsContext } from './AppModalsContext';

export const useNotificationToast = () => {
  const { openNotificationToast, closeNotificationToast } = useContext(AppModalsContext);
  return {
    openNotificationToast,
    closeNotificationToast,
  };
};
