import { useI18n } from 'i18n';
import { useMedia } from 'modules/media';
import { View } from 'components';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { invoicesView } from 'telemetry/Avo';

import { InvoicesDataList } from './InvoicesDataList';
import { InvoicesDataTable } from './InvoicesDataTable';
import { useHandleDownloadInvoiceDocumentFromUrl } from './useHandleDownloadInvoiceDocumentFromUrl';

export const InvoicesView = () => {
  const mediumView = useMedia('medium');
  const { getLocalizedMessage } = useI18n();
  const [handleDownloadDocumentFromURL, isDownloading] = useHandleDownloadInvoiceDocumentFromUrl();

  return (
    <>
      <ViewEventComponent event={invoicesView} />
      <View title={getLocalizedMessage('views.invoices')}>
        {mediumView ? (
          <InvoicesDataList
            onDownloadDocumentClick={handleDownloadDocumentFromURL}
            isDownloading={isDownloading}
          />
        ) : (
          <InvoicesDataTable
            onDownloadDocumentClick={handleDownloadDocumentFromURL}
            isDownloading={isDownloading}
          />
        )}
      </View>
    </>
  );
};
