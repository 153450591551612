import styled from 'styled-components';
import { useEffect, ComponentPropsWithoutRef } from 'react';

const Root = styled.div`
  width: 100%;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior-y: contain;
`;

export const FillViewportHeightDiv = (props: ComponentPropsWithoutRef<'div'>) => {
  useEffect(() => {
    const resizer = () => {
      const vh = window.innerHeight * 0.01;
      setTimeout(() => {
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }, 50);
    };
    resizer();

    window.addEventListener('resize', resizer);
    return () => {
      window.removeEventListener('resize', resizer);
    };
  }, []);

  return <Root {...props} />;
};
