import * as t from 'io-ts';

import { restaurantApiAgent } from 'apiAgents';
import { PaginatedData } from 'types/PaginatedData';
import { logValidationError } from 'validation';

import { Order, OrderDetails } from './types';

export type FetchOrdersParams = {
  page: number;
  page_size: number;
  searchBy?: string;
  searchQuery?: string;
  statuses?: string[];
  purchase_types?: string[];
  group_ids?: string[];
  is_preorder?: boolean;
  received_after: string;
  received_before: string;
};

export const fetchOrders = async (
  corporateId: string,
  {
    page,
    page_size,
    searchBy,
    searchQuery,
    statuses,
    purchase_types,
    is_preorder,
    group_ids,
    received_after,
    received_before,
  }: FetchOrdersParams,
) => {
  const { data } = await restaurantApiAgent.get<PaginatedData<Order>>(
    `/v1/waw-api/corporates/${corporateId}/purchases`,
    {
      params: {
        received_after,
        received_before,
        page,
        page_size,
        is_preorder,
        statuses: statuses?.join(','),
        purchase_types: purchase_types?.join(','),
        group_ids: group_ids?.join(','),
        ...(searchBy ? { [searchBy]: searchQuery } : undefined),
      },
    },
  );
  logValidationError('fetchOrders', t.array(Order), data.results);
  return data;
};

export const fetchOrderDetails = async (corporateId: string, orderId: string) => {
  const { data } = await restaurantApiAgent.get<OrderDetails>(
    `/v1/waw-api/corporates/${corporateId}/purchases/${orderId}`,
  );
  logValidationError('fetchOrderDetails', OrderDetails, data);
  return data;
};

export const getReceipts = async (corporateId: string, orderId: string) => {
  const { data } = await restaurantApiAgent.get(
    `/v1/waw-api/corporates/${corporateId}/purchases/${orderId}/download-receipts`,
    {
      responseType: 'blob',
    },
  );
  return data;
};
