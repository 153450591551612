import { RouteComponentProps } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { Button } from '@creditornot/cb-button';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';

import { View } from 'components';
import { useCorporate } from 'modules/corporates';
import { useFetchUsers } from 'modules/users';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';

import { ActionButtons, AddAdminModal, AdminsList, RemoveAdminsModal } from './components';
import { useAdminsModalsController, useAdminsQueryParams } from './hooks';

const TooltipContent = styled.div`
  max-width: 300px;
`;

const AddButton = ({ isWoltEmployee }: { isWoltEmployee: boolean }) => {
  const { openAddAdminModal } = useAdminsModalsController();

  return (
    <Popover
      contentZIndex={1}
      placement="left"
      showOnHover={!isWoltEmployee}
      showOnClick={false}
      content={
        <TooltipContent>
          <FormattedMessage id="admins.disabled-add-button-explanation" />
        </TooltipContent>
      }
    >
      <Button variant="blue" size="small" disabled={!isWoltEmployee} onClick={openAddAdminModal}>
        <FormattedMessage id="admins.add-button-text" />
      </Button>
    </Popover>
  );
};

export const AdminsView: React.FC<
  RouteComponentProps<{ action?: 'delete' | 'add'; corporateId: string }>
> = ({ match }) => {
  const { data: corporate } = useCorporate();
  const { isWoltEmployeeWithCreate } = useIsUserWoltEmployee();
  const { openDeleteUsersModal, closeModal } = useAdminsModalsController();

  const [
    { id: userIdQueryParam, ids: userIdsQueryParam = [], page = 1, page_size = 50 },
    setQueryParams,
  ] = useAdminsQueryParams();

  const {
    data: usersData,
    isLoading: isLoadingUsers,
    error: usersError,
  } = useFetchUsers({
    page,
    page_size,
  });

  const emailsFromUserParams = useMemo(() => {
    if ((!userIdQueryParam && !userIdsQueryParam) || !usersData) {
      return [];
    }

    const ids = userIdQueryParam ? [userIdQueryParam] : userIdsQueryParam;

    const users = usersData.results.filter((user) => ids.includes(user.user_id));
    return users.map((user) => user.email);
  }, [userIdQueryParam, userIdsQueryParam, usersData]);

  const handleSuccess = useCallback(() => {
    setQueryParams({ ids: [] });
    closeModal();
  }, [closeModal, setQueryParams]);

  if (!corporate) {
    return null;
  }

  return (
    <>
      <View
        title={<FormattedMessage id="admins.title" />}
        subtitle={<FormattedMessage id="admins.subtitle" />}
        rightContent={<AddButton isWoltEmployee={isWoltEmployeeWithCreate} />}
      >
        <ActionButtons onDeleteClick={openDeleteUsersModal} />
        <AdminsList usersData={usersData} error={usersError} isLoading={isLoadingUsers} />
      </View>

      <AddAdminModal
        show={match.params.action === 'add' && isWoltEmployeeWithCreate}
        corporate={corporate}
        onClose={closeModal}
        onSuccess={handleSuccess}
      />

      <RemoveAdminsModal
        show={match.params.action === 'delete'}
        userIds={userIdQueryParam ? [userIdQueryParam] : userIdsQueryParam}
        emails={emailsFromUserParams}
        onClose={closeModal}
        onSuccess={handleSuccess}
      />
    </>
  );
};
