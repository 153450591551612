import React from 'react';

interface Props {
  FallbackComponent: React.ComponentClass<State> | React.FunctionComponent<State>;
  children?: React.ReactNode;
}

interface State {
  error: Error | null;
  errorInfo: null;
}

class ErrorBoundary extends React.Component<Props, State> {
  public readonly state = { error: null, errorInfo: null };

  public componentDidCatch(error: Error, errorInfo: any) {
    this.setState(() => ({
      error: error,
      errorInfo: errorInfo,
    }));
  }

  public render() {
    const { error, errorInfo } = this.state;
    const { FallbackComponent, children } = this.props;
    if (errorInfo) {
      return <FallbackComponent error={error} errorInfo={errorInfo} />;
    }

    return children;
  }
}

export default ErrorBoundary;
