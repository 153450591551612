import { useCallback } from 'react';

import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchCorporateV2 } from './api';
import { CorporateV2 } from './types';

/** As of now the new endpoint only includes discounts, later all corporate data will be migrated over */
export const useFetchCorporateV2 = <T = CorporateV2>(
  id: string | null,
  config?: UseQueryOptions<CorporateV2, unknown, T>,
) => {
  const queryFn = useCallback(() => {
    if (!id) {
      return Promise.reject();
    }
    return fetchCorporateV2(id);
  }, [id]);
  return useQuery({ queryKey: ['corporate-v2', id], queryFn, enabled: !!id, ...config });
};
