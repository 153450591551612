import { Contact } from '@creditornot/wd-api-client';
import { endOfToday, startOfDay, sub } from 'date-fns';

export const convertGram = (value: number, toUnit: 'g' | 'kg'): number => {
  if (toUnit === 'kg') {
    return value * 0.001;
  }
  return value;
};

export const formatWeight = (weight: number): string =>
  weight.toLocaleString(undefined, { maximumFractionDigits: 1 });

export const last30DaysDateRange = (): { from: Date; to: Date } => ({
  from: startOfDay(sub(new Date(), { days: 30 })),
  to: endOfToday(),
});

export const getPickupCommentWithoutSenderInfo = (comment: string, senderInfo: Contact) => {
  const commentWithoutSenderInfo = comment
    .split(', ')
    .filter(
      (commentPiece: string) =>
        commentPiece !== senderInfo.name && commentPiece !== senderInfo.phoneNumber,
    )
    .toString();
  return commentWithoutSenderInfo;
};
