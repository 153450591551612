import { useField } from 'formik';
import { Select } from '@creditornot/cb-select';

import { useConfigs } from 'modules/configs';
import { FormRowV2 } from 'components';

interface Props {
  className?: string;
}

export const CurrencyField = ({ className }: Props) => {
  const { configs } = useConfigs();
  const [{ value }, , { setValue }] = useField<string | null>('currency');

  return (
    <FormRowV2
      className={className}
      htmlFor="currency"
      title="Currency"
      content={
        <Select
          inputId="currency"
          value={value}
          onChange={(value) => setValue(value)}
          options={configs?.countries
            .map((country) => country.currency)
            .filter((currency, index, array) => array.indexOf(currency) === index)
            .map((currency) => ({
              label: currency,
              value: currency,
            }))}
        />
      }
    />
  );
};
