import * as React from 'react';
import { Tag } from '@creditornot/cb-tag';
import styled from 'styled-components';
import { Variant } from '@creditornot/cb-tag/lib/esm/Tag';
import { useIntl } from 'react-intl';

import { MessageIds } from 'i18n';
import { SubscriptionUserStatus } from 'modules/subscription/types';

type Props = {
  className?: string;
  status: SubscriptionUserStatus;
};

type MappedStatuses = {
  [K in SubscriptionUserStatus]?: {
    variant: Variant;
    text: MessageIds;
    hint?: MessageIds;
  };
};

const StyledTag = styled(Tag)`
  height: unset;

  > :first-child {
    white-space: normal;
  }
`;

const mappedStatuses: MappedStatuses = {
  subscribed: {
    variant: 'lightGreen',
    text: 'wolt-plus.member-status.subscribed',
  },
  not_subscribed: {
    variant: 'lightBlue',
    text: 'wolt-plus.member-status.not-subscribed',
  },
  disabled: {
    variant: 'lightBlack',
    text: 'common.disabled',
  },
};

export const SubscriptionUserTag = ({ className, status }: Props) => {
  const { formatMessage } = useIntl();
  const mappedStatus = mappedStatuses[status];

  if (!mappedStatus) {
    return null;
  }

  return (
    <StyledTag className={className} variant={mappedStatus.variant}>
      {formatMessage({ id: mappedStatus.text })}
    </StyledTag>
  );
};
