import { UseQueryOptions, useQuery } from 'react-query';
import { useCallback } from 'react';
import { AxiosError } from 'axios';

import { merchantPayoutApiAgent } from 'apiAgents';

import { VenuePayout } from './types';

export const useFetchMerchantPayouts = <T = VenuePayout[]>(
  id: string,
  config?: UseQueryOptions<VenuePayout[], AxiosError, T>,
) => {
  const queryFn = useCallback(async () => {
    const { data } = await merchantPayoutApiAgent.get(
      `/public/v1/corporate-portal/corporates/${id}/payouts`,
    );
    return data;
  }, [id]);
  return useQuery({
    queryKey: ['payoutReports', id],
    queryFn,
    ...config,
  });
};
