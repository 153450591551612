import { useCallback, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { pick } from 'lodash';

import { Corporate } from 'modules/corporates/types';
import { formatCorporateDataToFormValues } from 'modules/corporates/formatFormValues';
import { useEditCompanyInformation } from 'modules/corporates/useEditCompanyInformation';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';

import { CompanyInformationForm } from '../SignupV2/components/CompanyInformation/CompanyInformationForm';
import { EditCorporateFormValues } from '../CompanySettings/types';

type Props = {
  corporate: Corporate;
};

const FORM_KEYS = ['name', 'business_id', 'country', 'address', 'post_code', 'city', 'vat_id'];
type FormValues = Pick<
  EditCorporateFormValues,
  'name' | 'business_id' | 'country' | 'address' | 'post_code' | 'city' | 'vat_id'
>;

export const CompanyInformationContainer = ({ corporate }: Props) => {
  const [state, setState] = useState<'view' | 'edit'>('view');

  const { isWoltEmployeeWithUpdate } = useIsUserWoltEmployee();

  const initialValues = pick(formatCorporateDataToFormValues(corporate), FORM_KEYS) as FormValues;
  const editCompanyInformation = useEditCompanyInformation(corporate);

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      try {
        await editCompanyInformation(initialValues, values);
        actions.setSubmitting(false);
        actions.setStatus({ submitSucceeded: true });
        actions.resetForm();
        setState('view');
      } catch (error) {
        actions.setSubmitting(false);
        actions.setStatus({ error });
      }
    },
    [editCompanyInformation, initialValues],
  );

  return (
    <Formik
      validateOnMount={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm, dirty, status, resetForm }) => (
        <Form>
          <CompanyInformationForm
            renderMode={state}
            isWoltEmployee={isWoltEmployeeWithUpdate}
            isSubmitting={isSubmitting || status?.submitSucceeded}
            dirty={dirty}
            error={status?.error}
            corporate={corporate}
            onEditClick={() => setState('edit')}
            onCancelClick={() => {
              resetForm();
              setState('view');
            }}
            onSaveClick={submitForm}
          />
        </Form>
      )}
    </Formik>
  );
};
