import React, { useCallback, ChangeEvent, useRef, useReducer } from 'react';
import Cropper from 'react-easy-crop';
import styled from 'styled-components';
import { Button, IconButton, TextButton } from '@creditornot/cb-button';
import { Alert } from '@creditornot/cb-alert';
import { ModalContent, ModalFooter, ModalHeader } from '@creditornot/cbt-modal';
import { InputAlert } from '@creditornot/cb-input';
import { Minus, Plus } from '@creditornot/cb-icons';

import { ModalV2 } from 'components/ModalV2';

import cropImage from './cropImage';

type ResolvedType<T extends Promise<any>> = T extends Promise<infer U> ? U : never;
type Area = {
  width: number;
  height: number;
  x: number;
  y: number;
};
type CropLocation = { x: number; y: number };

const RangeInput = styled.input.attrs({
  type: 'range',
})`
  width: 100%;
  margin: 16px 0;
`;

const CropperContainter = styled.div`
  position: relative;
  height: 334px;
`;

const Controls = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
`;

const ZoomControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  gap: 12px;
`;

const ChangeButton = styled(TextButton).attrs({ variant: 'blue', size: 'small' })`
  position: relative;
`;

const SaveButton = styled(Button).attrs({ variant: 'blue', size: 'small' })`
  flex-shrink: 0;
`;
const CancelButton = styled(Button).attrs({ variant: 'lightBlue', size: 'small' })`
  flex-shrink: 0;
`;

const FileInput = styled.input.attrs({
  type: 'file',
})`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
`;

type ImageEditorProps = {
  className?: string;
  onCropDone: (file: File) => Promise<void> | void;
  aspect?: number;
  cropShape?: 'rect' | 'round';
  children?: (props: { disabled: boolean; onClick: () => void }) => React.ReactNode;
};

// Actions
type FileChange = { type: 'fileChange'; imageSrc: string | null; fileName: string };
type ModalOpenAnimationDone = { type: 'modalOpenAnimationDone' };
type CropChange = { type: 'cropChange'; crop: CropLocation };
type CropDone = { type: 'cropDone'; croppedAreaPixels: Area };
type SaveCropStart = { type: 'saveCropStart' };
type SaveCropSuccess = { type: 'saveCropSuccess' };
type SaveCropError = { type: 'saveCropError'; error: string };
type Cancel = { type: 'cancel' };
type ZoomChange = { type: 'zoomChange'; zoom: number };
type Actions =
  | FileChange
  | ModalOpenAnimationDone
  | CropChange
  | CropDone
  | SaveCropStart
  | SaveCropSuccess
  | SaveCropError
  | Cancel
  | ZoomChange;

type State = {
  imageSrc: string | null;
  fileName: string;
  crop: CropLocation;
  zoom: number;
  croppedAreaPixels: Area | null;
  isLoading: boolean;
  error: string | null;
  modalOpenAnimationDone: boolean;
};

function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'fileChange':
      return {
        ...state,
        imageSrc: action.imageSrc,
        fileName: action.fileName,
        modalOpenAnimationDone: false,
      };
    case 'modalOpenAnimationDone':
      return {
        ...state,
        modalOpenAnimationDone: true,
      };
    case 'cropChange':
      return {
        ...state,
        crop: action.crop,
      };
    case 'cropDone':
      return {
        ...state,
        croppedAreaPixels: action.croppedAreaPixels,
      };
    case 'saveCropStart':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case 'saveCropSuccess':
      return {
        ...state,
        imageSrc: null,
        fileName: '',
        isLoading: false,
        error: null,
        croppedAreaPixels: null,
        zoom: 1,
      };
    case 'saveCropError':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case 'cancel':
      return {
        ...state,
        imageSrc: null,
        fileName: '',
        croppedAreaPixels: null,
        zoom: 1,
      };
    case 'zoomChange':
      return {
        ...state,
        zoom: action.zoom,
      };
    default:
      return state;
  }
}

const defaultState: State = {
  imageSrc: null,
  fileName: '',
  crop: { x: 0, y: 0 },
  zoom: 1,
  croppedAreaPixels: null,
  isLoading: false,
  error: null,
  modalOpenAnimationDone: false,
};

export const ImageEditor = ({
  className,
  onCropDone,
  aspect,
  cropShape,
  children,
}: ImageEditorProps) => {
  const [
    { imageSrc, fileName, crop, zoom, croppedAreaPixels, isLoading, error, modalOpenAnimationDone },
    dispatch,
  ] = useReducer(reducer, defaultState);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleCropComplete = useCallback((_croppedArea: Area, croppedAreaPixels: Area) => {
    dispatch({ type: 'cropDone', croppedAreaPixels });
  }, []);

  const handleChangeClick = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };

  const handleSaveClick = async () => {
    if (croppedAreaPixels === null || imageSrc === null) {
      return;
    }

    dispatch({ type: 'saveCropStart' });

    let croppedImage: ResolvedType<ReturnType<typeof cropImage>>;
    try {
      croppedImage = await cropImage(imageSrc, croppedAreaPixels);
    } catch (error) {
      dispatch({ type: 'saveCropError', error: 'Unable to crop image, please try again' });
      return;
    }

    const file = new File([croppedImage], fileName);

    try {
      await onCropDone(file);
      dispatch({ type: 'saveCropSuccess' });
    } catch (error) {
      console.error(error);
      dispatch({ type: 'saveCropError', error: 'Unable to save image, please try again' });
    }
  };

  const handleCancelClick = () => {
    dispatch({ type: 'cancel' });
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const [file] = e.target.files;
      const fileUrl = URL.createObjectURL(file);
      dispatch({ type: 'fileChange', imageSrc: fileUrl, fileName: file.name });
      // wait for animation to end so when lib calls getBoundingClientRect return correct value https://github.com/ValentinH/react-easy-crop/issues/211
      setTimeout(() => dispatch({ type: 'modalOpenAnimationDone' }), 300);
    }
  };

  return (
    <div className={className}>
      <ModalV2 open={!!imageSrc} disableDismissOnOutsidePress>
        <ModalHeader onCloseClick={handleCancelClick}>Edit image</ModalHeader>
        <ModalContent>
          <CropperContainter>
            {modalOpenAnimationDone && imageSrc && (
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={(crop) => dispatch({ type: 'cropChange', crop })}
                onCropComplete={handleCropComplete}
                onZoomChange={(zoom) => dispatch({ type: 'zoomChange', zoom })}
                cropShape={cropShape}
                restrictPosition={false}
              />
            )}
          </CropperContainter>
          <ZoomControls>
            <IconButton
              onClick={() => dispatch({ type: 'zoomChange', zoom: zoom <= 0 ? 0 : zoom - 0.1 })}
              size="small"
              variant="blue"
            >
              <Minus />
            </IconButton>
            <RangeInput
              value={zoom}
              min={0}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) =>
                dispatch({ type: 'zoomChange', zoom: Number.parseFloat(e.currentTarget.value) })
              }
            />
            <IconButton
              onClick={() => dispatch({ type: 'zoomChange', zoom: zoom >= 3 ? 3 : zoom + 0.1 })}
              size="small"
              variant="blue"
            >
              <Plus />
            </IconButton>
          </ZoomControls>
          <InputAlert variant="info">
            To fit nicely on the tracking page the logo should fit inside the circle, you can use
            the slider to adjust the image.
          </InputAlert>
        </ModalContent>
        <ModalFooter>
          {error && (
            <Alert style={{ marginBottom: 8 }} variant="error">
              {error}
            </Alert>
          )}
          <Controls>
            <CancelButton disabled={isLoading} onClick={handleCancelClick}>
              Cancel
            </CancelButton>
            <SaveButton onClick={handleSaveClick} disabled={isLoading} loading={isLoading}>
              Save
            </SaveButton>
          </Controls>
        </ModalFooter>
      </ModalV2>
      {!imageSrc && (
        <>
          {children ? (
            children({ disabled: isLoading, onClick: handleChangeClick })
          ) : (
            <ChangeButton disabled={isLoading} onClick={handleChangeClick}>
              Change
            </ChangeButton>
          )}
          <FileInput ref={fileInputRef} accept="image/*" onChange={handleFileChange} />
        </>
      )}
    </div>
  );
};
