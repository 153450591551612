import { useCallback } from 'react';

import { useQueryClient } from 'modules/react-query';

import { createCountryConfig, CreateCountryConfigData } from './api';

export const useCreateCountryConfig = () => {
  const queryClient = useQueryClient();
  return useCallback(
    async (data: CreateCountryConfigData) => {
      const result = await createCountryConfig(data);
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 500);
      return result;
    },
    [queryClient],
  );
};
