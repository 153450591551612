import { InputContainer } from '@creditornot/cb-form';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';

import { FormikInputField, FormikTextareaField } from 'components';
import { useI18n } from 'i18n';
import { createValidator, isRequired, isNotLongerThan } from 'validation';

interface GeneralInformationFormSectionProps {
  className?: string;
  title?: string;
}

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: 24px;
  }
`;

const Title = styled.div`
  ${typographyCss.Title1()}
  display: inline-block;
  margin-bottom: 24px;
`;

const validateGroupName = createValidator([isRequired, isNotLongerThan(35)]);

const GeneralInformationFormSection = ({
  className,
  title,
}: GeneralInformationFormSectionProps) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <div className={className}>
      {title && <Title>{title}</Title>}
      <StyledInputContainer
        labelHtmlFor="groupName"
        label={getLocalizedMessage('groups-form.group-name')}
      >
        <FormikInputField
          validate={validateGroupName}
          required={true}
          inputProps={{
            placeholder: getLocalizedMessage('groups-form.group-name-placeholder'),
          }}
          name="groupName"
        />
      </StyledInputContainer>
      <StyledInputContainer
        labelHtmlFor="description"
        label={getLocalizedMessage('groups-form.description')}
      >
        <FormikTextareaField
          name="description"
          textareaProps={{
            maxLength: 200,
            style: {
              minHeight: '150px',
            },
          }}
        />
      </StyledInputContainer>
    </div>
  );
};

export default GeneralInformationFormSection;
