import { color } from '@creditornot/cb-ingredients/design-tokens';
import { colors } from '@creditornot/cb-ingredients';
import SyntaxHighlighter from 'react-syntax-highlighter';
import styled from 'styled-components';
import { githubGist as syntaxStyle } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

import { breakpoints } from 'modules/media';

import { FlashNotification } from './FlashNotification';

const ShowMoreContent = styled.div`
  margin-top: 4px;
  min-height: 0;
  min-width: 0;
`;

const FooterURL = styled.div`
  padding-top: 6px;
  font-size: 11px;
  line-height: 14px;
  font-family: Monaco, monospace;
  opacity: 0.7;
`;

const ErrorDisplay = styled.div`
  border: 1px solid ${colors.strawberry64};
  border-radius: 3px;
  font-size: 13px;
  line-height: 15px;
  color: ${color.textSubdued};
  max-height: 230px;
  max-width: 100%;
  overflow: auto;

  pre {
    margin: 0;
  }
`;

const ErrorTitle = styled.div`
  font-weight: 600;
  font-size: 16px;

  @media (max-width: ${breakpoints.small}px) {
    display: none;
  }
`;

const ErrorContent = styled.div<{ withTopPadding?: boolean }>`
  padding-top: ${({ withTopPadding }) => (withTopPadding ? '6px' : '0px')};
  font-size: 14px;
  line-height: 17px;
  color: ${color.textSubdued};
`;

const NoResponseContent = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  color: ${color.textSubdued};
`;

const StyledSyntaxHighlighter = styled(SyntaxHighlighter)`
  border-radius: 3px;
  white-space: pre-wrap;
`;

interface ErrorNotificationProps {
  children?: React.ReactNode;
  jsonData?: any;
  url?: string;
  title?: string;
}

export const ErrorNotification = ({
  children,
  jsonData,
  url,
  title,
  ...rest
}: ErrorNotificationProps) => (
  <FlashNotification
    {...rest}
    title={title}
    variant="danger"
    showMoreContent={
      (url || jsonData) && (
        <ShowMoreContent>
          <ErrorDisplay>
            {jsonData ? (
              <StyledSyntaxHighlighter language="json" style={syntaxStyle}>
                {JSON.stringify(jsonData, null, 2)}
              </StyledSyntaxHighlighter>
            ) : (
              <NoResponseContent>(empty content)</NoResponseContent>
            )}
          </ErrorDisplay>

          {!!url && <FooterURL>{url}</FooterURL>}
        </ShowMoreContent>
      )
    }
  >
    <div>
      {!!title && <ErrorTitle>{title}</ErrorTitle>}
      {!!children && <ErrorContent withTopPadding={!!title}>{children}</ErrorContent>}
    </div>
  </FlashNotification>
);
