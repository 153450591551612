import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useField, useFormikContext } from 'formik';
import { useCallback } from 'react';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { useIntl } from 'react-intl';

import { useI18n } from 'i18n';
import { CheckboxCard, FormikMultiSelectField, RadioInputCardGroup } from 'components';
import { isRequired } from 'validation/formik';

import { PolicyFormValues } from './types';

export type AllowPurchaseFromNonRestaurantVenuesProps = {
  className?: string;
};

const StyledFormikMultiSelectField = styled(FormikMultiSelectField)`
  margin: 0 0 12px 24px;
`;

const StyledRadioInputCardGroup = styled(RadioInputCardGroup)`
  margin: 12px 0;
  padding-inline-start: 24px;
`;

const RetailRestrictionTitle = styled.div`
  ${typographyCss.Body3()}
  margin: 12px 0 12px 24px;
`;

const StyledRequiredSpan = styled.span`
  color: ${color.textNegative};
`;

const RetailRestrictionRadioInput = () => {
  const { getLocalizedMessage } = useI18n();

  const [allowNonRestaurantProductLines, , allowNonRestaurantProductLinesHelpers] =
    useField<boolean>('all_non_restaurant_product_lines_allowed');

  return (
    <StyledRadioInputCardGroup
      name={allowNonRestaurantProductLines.name}
      value={allowNonRestaurantProductLines.value.toString()}
      onChange={(e) => allowNonRestaurantProductLinesHelpers.setValue(e.target.value === 'true')}
      direction="row"
      stretched
      options={[
        {
          label: getLocalizedMessage('policies.allowed-venues.non-restaurants.allow-all'),
          value: 'true',
        },
        {
          label: getLocalizedMessage('policies.allowed-venues.non-restaurants.limit'),
          value: 'false',
        },
      ]}
    />
  );
};

const RetailRestrictionMultiSelect = () => {
  const { getLocalizedMessage } = useI18n();

  const options = [
    {
      label: getLocalizedMessage('policies.allowed-venues.stores.groceries'),
      value: 'grocery',
    },
    {
      label: getLocalizedMessage('policies.allowed-venues.stores.bakeries'),
      value: 'bakery',
    },
    {
      label: getLocalizedMessage('policies.allowed-venues.stores.pharmacy'),
      value: 'pharmacy',
    },
    {
      label: getLocalizedMessage('policies.allowed-venues.stores.flowers'),
      value: 'flowers',
    },
    {
      label: getLocalizedMessage('policies.allowed-venues.stores.alcohol'),
      value: 'alcohol',
    },
    {
      label: getLocalizedMessage('policies.allowed-venues.stores.other-retail'),
      value: 'other_retail',
    },
  ].sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });

  return (
    <div>
      <RetailRestrictionTitle>
        {getLocalizedMessage('policies.venues-restriction-type-text')}
        <StyledRequiredSpan> *</StyledRequiredSpan>
      </RetailRestrictionTitle>
      <StyledFormikMultiSelectField
        name="allowed_non_restaurant_product_lines"
        options={options}
        validate={isRequired}
        multiSelectProps={{ menuPlacement: 'top' }}
      />
    </div>
  );
};

export const AllowPurchaseFromNonRestaurantVenues = ({
  className,
}: AllowPurchaseFromNonRestaurantVenuesProps) => {
  const { formatMessage } = useIntl();
  const [{ name, value }, , { setValue }] = useField<boolean>('non_restaurant_purchases_allowed');
  const handleChange = useCallback(() => {
    setValue(!value);
  }, [setValue, value]);
  const {
    values: { all_non_restaurant_product_lines_allowed },
  } = useFormikContext<PolicyFormValues>();
  const showRetailRestrictionSelect = value && !all_non_restaurant_product_lines_allowed;

  return (
    <div>
      <CheckboxCard
        className={className}
        name={name}
        checked={value}
        label={formatMessage({ id: 'policies.allowed-venues.non-restaurants' })}
        stretched
        onChange={handleChange}
      />
      {value && <RetailRestrictionRadioInput />}
      {showRetailRestrictionSelect && <RetailRestrictionMultiSelect />}
    </div>
  );
};
