import { isAfter, isEqual, isPast } from 'date-fns';

import { type MessageIds } from 'i18n';

export const isDateInThePast = (date: Date | null): undefined | MessageIds => {
  return date && isPast(date) ? 'events.error.date-is-in-the-past' : undefined;
};

export const isEndDateAfterStartDate = (
  startDate: Date | null,
  endDate: Date | null,
): undefined | MessageIds => {
  if (startDate && endDate && isEqual(startDate, endDate)) {
    return 'events.create-event-form.date-error-message';
  }
  return startDate && endDate && isAfter(startDate, endDate)
    ? 'events.create-event-form.date-error-message'
    : undefined;
};
