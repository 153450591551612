import { useCallback } from 'react';
import styled from 'styled-components';
import { Column, DataTable } from '@creditornot/cb-data-table';
import { FormattedMessage, useIntl } from 'react-intl';

import { DataCellContent, ResponsiveContainer, ApiErrorNotification, UserStatus } from 'components';
import { UserInvite } from 'modules/invites/types';
import { PaginatedData } from 'types/PaginatedData';
import { useCorporate } from 'modules/corporates';
import { corporateFeatureFlag } from 'modules/corporates/utils';
import { useI18n } from 'i18n';

import ActionButtons from '../ActionButtons';
import GroupTag from '../GroupTag';
import { useUsersModalsController, useUsersQueryParams } from '../hooks';

interface Props {
  invitesData: PaginatedData<UserInvite> | null | undefined;
  className?: string;
  selectedRowKeys: string[];
  onRowSelectionChange: (selectedRowKeys: string[]) => void;
  error: unknown;
  loading: boolean;
  isFiltering: boolean;
}

const ErrorContainer = styled(ResponsiveContainer)`
  padding: 22px 0;
`;

const InvitedTimeAgoTextContainer = styled.span`
  margin-inline-start: 14px;
`;

const useColumns = () => {
  const { getLocalizedTimeAgo } = useI18n();
  const { formatMessage } = useIntl();
  const { openDeleteInviteModal, openResendUserInviteModal } = useUsersModalsController();
  const { data: corporate } = useCorporate();
  const { groupEnabled } = corporateFeatureFlag(corporate);

  const columns: (Column<UserInvite, unknown> | undefined)[] = [
    {
      id: 'name',
      name: formatMessage({ id: 'users.column-title.name' }),
      render: () => {
        return <DataCellContent>–</DataCellContent>;
      },
    },
    {
      id: 'email',
      name: formatMessage({ id: 'users.column-title.email' }),
      render: ({ record: invite }) => {
        return <DataCellContent disabled={false}>{invite.email}</DataCellContent>;
      },
    },
    groupEnabled
      ? {
          id: 'groups',
          name: formatMessage({ id: 'users.column-title.groups' }),
          render: ({ record: invite }) => {
            return (
              <>
                {invite.groups?.map((group) => (
                  <GroupTag key={group.id} isDisabled={!group.active}>
                    {group.name}
                  </GroupTag>
                ))}
              </>
            );
          },
        }
      : undefined,
    {
      id: 'status',
      name: formatMessage({ id: 'users.column-title.status' }),
      style: {
        width: '15%',
      },
      render: ({ record: invite }) => {
        return (
          <DataCellContent
            subContent={
              invite.is_valid ? (
                <InvitedTimeAgoTextContainer>
                  <FormattedMessage
                    id="users.invited-time-ago"
                    values={{
                      timeAgo: getLocalizedTimeAgo(new Date(invite.modified_at)),
                    }}
                  />
                </InvitedTimeAgoTextContainer>
              ) : (
                <InvitedTimeAgoTextContainer>
                  <FormattedMessage
                    id="users.expired-time-ago"
                    values={{
                      timeAgo: getLocalizedTimeAgo(new Date(invite.valid_until)),
                    }}
                  />
                </InvitedTimeAgoTextContainer>
              )
            }
          >
            {invite.is_valid ? <UserStatus status="pending" /> : <UserStatus status="expired" />}
          </DataCellContent>
        );
      },
    },
    {
      id: 'actions',
      style: {
        width: '8%',
      },
      name: '',
      render: ({ record: invite }) => {
        return (
          <ActionButtons
            onResendInviteClick={() => openResendUserInviteModal(invite.id)}
            onDeleteClick={() => openDeleteInviteModal(invite.id)}
          />
        );
      },
    },
  ];

  return columns.filter((column): column is Column<UserInvite, unknown> => column !== undefined);
};

const InvitesDataTable: React.FC<Props> = ({
  invitesData,
  isFiltering,
  selectedRowKeys,
  loading,
  error,
  onRowSelectionChange,
  className,
}) => {
  const columns = useColumns();
  const { formatMessage } = useIntl();
  const [{ page = 1, page_size = 20 }, setQueryParams] = useUsersQueryParams();
  const hideRowSelection = loading || (isFiltering && invitesData?.results?.length === 0);

  const handleSelectAll = useCallback(() => {
    const isAllSeleted = selectedRowKeys.length === invitesData?.results?.length;
    if (isAllSeleted) {
      onRowSelectionChange([]);
      return;
    }

    onRowSelectionChange((invitesData?.results || []).map((d) => d.id));
  }, [invitesData, onRowSelectionChange, selectedRowKeys.length]);

  if (error) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={error} />
      </ErrorContainer>
    );
  }

  const hidePagination = loading || invitesData?.total_items_count === 0;

  return (
    <DataTable
      className={className}
      horizontalPadding="wide"
      columns={columns}
      dataSource={invitesData?.results || []}
      getRowKey={(invite) => invite.id}
      emptyMessage={{
        title: formatMessage({ id: 'users.invites-view.no-invites' }),
        description: formatMessage({ id: 'users.invites-view.no-invites-description' }),
      }}
      rowSelection={
        hideRowSelection
          ? undefined
          : {
              selectedRowKeys,
              onChange: onRowSelectionChange,
              onSelectAll: handleSelectAll,
            }
      }
      loading={loading}
      pagination={
        hidePagination
          ? undefined
          : {
              disableWhenLoading: true,
              pageCount: Math.ceil(
                (invitesData?.total_items_count ?? 0) / (invitesData?.page_size ?? 1),
              ),
              pageSize: page_size,
              activePage: page,
              onPageChange: (p) => setQueryParams({ page: p }),
              onPageSizeChange: (p) => {
                setQueryParams({ page: 1, page_size: p });
              },
              pageSizeOptions: [10, 20, 50, 100],
            }
      }
    />
  );
};

export default InvitesDataTable;
