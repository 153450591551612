import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchUserCorporateRoles } from './api';
import { Role } from './types';

export const useFetchUserCorporateRole = <T = Role[]>(
  config?: UseQueryOptions<Role[], unknown, T>,
) => {
  return useQuery({ queryKey: ['corporate-role'], queryFn: fetchUserCorporateRoles, ...config });
};
