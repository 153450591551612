import { ComponentProps } from 'react';

import { FormikTagInputField } from 'components';
import {
  createValidator,
  doesNotContainDuplicateEmails,
  isAListOfValidEmails,
  isDuplicateEmail,
  isEmail,
  isRequired,
} from 'validation';

const validateEmails = createValidator([
  isRequired,
  doesNotContainDuplicateEmails,
  isAListOfValidEmails,
]);

export const FormikEventInviteTagInput: React.FC<
  Partial<ComponentProps<typeof FormikTagInputField>>
> = ({ tagInputProp, ...props }) => {
  return (
    <FormikTagInputField
      name="emails"
      placeholder="email1@example.com, email2@example.com, email3@example.com"
      validate={validateEmails}
      validateTag={(tag, index, tags) => isEmail(tag) || isDuplicateEmail(tag, index, tags)}
      separateValuesBy={/[\s|,|;|:]/}
      setTagValueByKeyCodes={[13, 32, 44, 58, 59]}
      tagInputProp={{
        styles: { minHeight: '140px' },
        ...tagInputProp,
      }}
      {...props}
    />
  );
};
