import { useMemo, useState } from 'react';
import { useField } from 'formik';

import { useI18n } from 'i18n';
import { getFormikFieldError, parseValidationMessageKeyAndValues } from 'validation';

import TagInput, { TagInputProps } from './TagInput';
import { FormikFieldContainer } from './FormikFieldContainer';

interface FormikTagInputFieldProps {
  alwaysShowError?: boolean;
  className?: string;
  name: string;
  placeholder?: string;
  separateValuesBy?: RegExp;
  setTagValueByKeyCodes?: number[];
  validate?: (value: string[] | null) => string | void | Promise<string | void>;
  validateTag?: (value: string, index: number, values: string[]) => string | undefined;
  tagInputProp?: Partial<TagInputProps>;
}

export const FormikTagInputField = ({
  alwaysShowError = false,
  className,
  name,
  placeholder,
  separateValuesBy,
  setTagValueByKeyCodes = [13],
  tagInputProp,
  validate = () => undefined,
  validateTag = () => undefined,
}: FormikTagInputFieldProps) => {
  const { getLocalizedMessage } = useI18n();
  const [tagValues, setTagValues] = useState<string[] | null>(null);
  const [{ value, onBlur }, meta, { setValue }] = useField({
    name,
    validate,
  });

  const fieldError = useMemo(
    () => getFormikFieldError(meta, alwaysShowError),
    [alwaysShowError, meta],
  );

  const formikErrorMessage = useMemo(
    () => fieldError && getLocalizedMessage(fieldError.messageKey, fieldError.values),
    [fieldError, getLocalizedMessage],
  );

  const invalidTagsError = useMemo(
    () => tagValues?.reduce((acc, ...args) => validateTag(...args) || acc, ''),
    [tagValues, validateTag],
  );

  const invalidTagErrorMessage = useMemo(
    () => invalidTagsError && parseValidationMessageKeyAndValues(invalidTagsError),
    [invalidTagsError],
  );

  const invalidTagFormikErrorMessage = useMemo(
    () =>
      invalidTagErrorMessage &&
      getLocalizedMessage(invalidTagErrorMessage.messageKey, invalidTagErrorMessage.values),
    [getLocalizedMessage, invalidTagErrorMessage],
  );

  const errorMessage = invalidTagFormikErrorMessage || formikErrorMessage;

  return (
    <FormikFieldContainer className={className} errorMessage={errorMessage}>
      <TagInput
        id={name}
        invalid={!!fieldError || !!invalidTagsError}
        validateValues={(...args) => !!validateTag(...args)}
        placeholder={placeholder}
        separateValuesBy={separateValuesBy}
        setTagValueByKeyCodes={setTagValueByKeyCodes}
        values={value}
        onClear={(values) => {
          setValue(values);
          setTagValues(values);
        }}
        onChange={(values) => {
          setTagValues(values);
          setValue(values);
        }}
        onBlur={onBlur}
        {...tagInputProp}
      />
    </FormikFieldContainer>
  );
};
