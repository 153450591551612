import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';

import { DataList, InformationDisplay } from 'components';
import { PaginatedData } from 'types';
import { EventUser } from 'modules/events/types';
import { LocalizedMessage, LocalizedNumber } from 'i18n';
import { useCorporate } from 'modules/corporates';

import { ActionButtons } from './ActionButtons';
import { EventUserStatus } from './EventUserStatus';
import { EventUsersNoParticipants } from './EventUsersNoParticipants';

interface Props {
  data: PaginatedData<EventUser> | null | undefined;
  className?: string;
  isLoading: boolean;
  isSearching: boolean;
  isFiltering: boolean;
  onInviteClick: () => void;
  onDeleteClick: (eventUser: EventUser) => void;
  onResendInviteClick: (eventUser: EventUser) => void;
  eventBudget: number;
  isEndedEvent: boolean;
  currency: string | undefined;
}

const Email = styled.small`
  ${typographyCss.Small()}
  display: block;
`;

const Content = styled.small`
  ${typographyCss.Small()}
  display: block;
  margin-top: 8px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const StyledEventUserStatus = styled(EventUserStatus)`
  margin-inline-end: 8px;
`;

export const EventUsersDataList = ({
  data,
  className,
  isLoading,
  onInviteClick,
  isSearching,
  isFiltering,
  onDeleteClick,
  onResendInviteClick,
  eventBudget,
  isEndedEvent,
  currency,
}: Props) => {
  const { data: corporate } = useCorporate();
  if (!data) {
    return null;
  }

  if (data.total_items_count === 0) {
    return (
      <EventUsersNoParticipants
        onInviteClick={onInviteClick}
        isSearching={isSearching}
        isFiltering={isFiltering}
        isEndedEvent={isEndedEvent}
      />
    );
  }

  return (
    <DataList
      data={data?.results}
      getDataItemId={(dataItem) => dataItem.invite_id}
      renderDataItem={(eventUser) => {
        const { full_name, email, status, usage_purchase_count, purchase_total_amount } = eventUser;
        return (
          <Item>
            <InformationDisplay labelColor="grey" label={<strong>{full_name}</strong> || '–'}>
              <Email>{email}</Email>
              <Content>
                <StyledEventUserStatus status={status} />
                <LocalizedMessage
                  messageKey="events.participants.number-of-purchases"
                  values={{ number: usage_purchase_count }}
                />
                {' • '}
                <LocalizedNumber value={purchase_total_amount} currency={corporate?.currency} />
                {' / '}
                <LocalizedNumber value={eventBudget} currency={currency} />
              </Content>
            </InformationDisplay>
            {!isEndedEvent && (
              <ActionButtons
                onDeleteClick={() => onDeleteClick(eventUser)}
                onResendInviteClick={
                  eventUser.status == 'pending' ? () => onResendInviteClick(eventUser) : undefined
                }
              />
            )}
          </Item>
        );
      }}
      className={className}
      loading={isLoading}
    />
  );
};
