import styled, { keyframes } from 'styled-components';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import { useState } from 'react';

import { useCreateDomElement } from 'modules/hooks';

import { TourConfig } from './types';

interface ProductTourPopperProps {
  children?: React.ReactNode;
  referenceElement: Element | null;
  placement?: TourConfig['placement'];
}

const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const Container = styled.div`
  opacity: 0;
  animation: ${fadeIn} 150ms ease-in forwards;
  animation-delay: 300ms;
  z-index: 99999;
`;

const ProductTourPopper: React.FC<ProductTourPopperProps> = ({
  referenceElement,
  children,
  placement,
}) => {
  const element = useCreateDomElement('HightlightedElementWrapper', 'root');
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement });

  return createPortal(
    <Container ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      {children}
    </Container>,
    element,
  );
};

export default ProductTourPopper;
