import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import { useField } from 'formik';

import { FileInput } from 'components';
import { useFileReader } from 'modules/hooks';
import { useI18n, isMessageKey } from 'i18n';
import { getFormikFieldError, isFileExtensionValid } from 'validation';

import { TagsContainer } from './TagsContainer';
import { FileInputProps } from '../FileInput';

interface Props {
  alwaysShowError?: boolean;
  className?: string;
  columnName: string;
  name: string;
  validate?: (value: string[] | null) => string | void | Promise<string | void>;
  validateValue?: (value: string, index: number, values: string[]) => string | undefined;
  hideTags?: boolean;
  fileInputProps?: Partial<FileInputProps>;
}

const ErrorMessage = styled.div`
  display: inline-block;
  margin-top: 2px;
  font-size: 12px;
  color: ${color.textNegative};
`;

const isCsvFile = (file: File) => file.name.endsWith('.csv');
const isXlsxFile = (file: File) => file.name.endsWith('.xlsx');
const allowedExtensions = '.csv,.xlsx';
const validateFile = isFileExtensionValid('.csv,.xlsx');

export const FormikFileInput = ({
  alwaysShowError,
  className,
  name,
  validate,
  validateValue,
  columnName,
  hideTags,
  fileInputProps,
}: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { getLocalizedMessage } = useI18n();
  const { readXlsx, isParsing, readCsv } = useFileReader();
  const [{ value, onBlur }, meta, { setValue, setTouched }] = useField<string[] | null>({
    name,
    validate,
  });

  const formikValidationError = getFormikFieldError(meta, alwaysShowError);
  const invalidFileError = file && validateFile ? validateFile(file) : undefined;
  const invalidFileErrorMessage =
    !!(file && invalidFileError) &&
    isMessageKey(invalidFileError) &&
    getLocalizedMessage(invalidFileError);
  const formikValidationErrorMessage = isParsing
    ? undefined
    : formikValidationError?.messageKey &&
      isMessageKey(formikValidationError.messageKey) &&
      getLocalizedMessage(formikValidationError.messageKey, formikValidationError.values);
  const invalidValues =
    value && validateValue ? value.filter((...args) => !!validateValue(...args)) : undefined;

  const handleCsvFile = useCallback(
    async (inputFile: File) => {
      setValue(null);
      setError(null);
      setFile(inputFile);
      try {
        const csv = await readCsv(inputFile);
        setValue(csv.filter(Boolean).map((text) => text.toLocaleLowerCase().trim()));
      } catch (error) {
        setError(getLocalizedMessage('validation.file-input.file-parsing-error'));
      }
    },
    [getLocalizedMessage, readCsv, setValue],
  );

  const handleXlsxFile = useCallback(
    async (inputFile: File) => {
      setValue(null);
      setError(null);
      setFile(inputFile);

      try {
        const xlsx = await readXlsx(inputFile);
        const columnIndex = xlsx.reduce((acc, row) => {
          const colIndex = row.findIndex((col) => col && col.toLocaleLowerCase() === columnName);
          return colIndex === -1 ? acc : colIndex;
        }, -1);

        if (columnIndex === -1) {
          throw Error;
        }

        setValue(
          xlsx
            .map((row) => row[columnIndex].toLocaleLowerCase().trim())
            .filter((col) => !!col)
            .filter((col) => col !== columnName),
        );
      } catch (error) {
        setError(
          getLocalizedMessage('validation.file-input.missing-column-in-excel-file', {
            name: columnName,
          }),
        );
      }
    },
    [columnName, getLocalizedMessage, readXlsx, setValue],
  );

  const handleRemoveTag = useCallback(
    (_: string, index: number) => {
      setValue(value ? [...value.slice(0, index), ...value.slice(index + 1)] : null);
    },
    [setValue, value],
  );

  return (
    <div className={className}>
      <FileInput
        isLoading={isParsing}
        disabled={isParsing}
        accept={allowedExtensions}
        value={file}
        onChange={(file) =>
          isCsvFile(file)
            ? handleCsvFile(file)
            : isXlsxFile(file)
              ? handleXlsxFile(file)
              : undefined
        }
        onClear={() => {
          setError(null);
          setValue(null);
          setFile(null);
        }}
        onBlur={() => {
          onBlur(name);
          setTouched(true, true);
        }}
        invalid={!!(invalidFileErrorMessage || formikValidationErrorMessage || error)}
        {...fileInputProps}
      >
        {!hideTags && (
          <TagsContainer
            handleClearClick={handleRemoveTag}
            tagValues={
              invalidValues
                ? value?.sort((email: string) => (invalidValues.includes(email) ? -1 : 0))
                : value
            }
            invalidValues={invalidValues}
          />
        )}
      </FileInput>

      {(error || invalidFileErrorMessage || formikValidationErrorMessage) && (
        <ErrorMessage>
          {error || invalidFileErrorMessage || formikValidationErrorMessage}
        </ErrorMessage>
      )}
    </div>
  );
};
