import { Route, RouteComponentProps, RouteProps, useHistory } from 'react-router-dom';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { LoginView } from 'views/login';

import { RouteWithTopNavBar } from './RouteWithTopNavBar';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';

const RootRouteComponent = ({ match }: RouteComponentProps) => {
  const { location } = useHistory();
  const { user } = useAuthState();
  const path = location.pathname;

  if (user) {
    return <AuthenticatedRoutes location={location} />;
  }

  // User isn't authenticated
  return <RouteWithTopNavBar exact={match.isExact} path={path} component={LoginView} />;
};

export const RootRoute = (props: RouteProps) => <Route {...props} component={RootRouteComponent} />;
