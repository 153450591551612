import { FormikInputField } from 'components';
import { createValidator, isInteger, isNumber } from 'validation';
import { PriceIncrement } from 'views/corporates/create-corporate/CreateCorporateForm/types';

interface Props {
  index: number;
  fieldArray: PriceIncrement[];
  allowClear?: boolean;
}

export const CustomPriceToField = ({ index, fieldArray, allowClear = true }: Props) => {
  const validationFn = (valueStr: string) => {
    const distanceMax = Number(valueStr);
    if (index === fieldArray.length - 1 && distanceMax !== 0) {
      return 'validation.is_zero_last';
    }

    if (index === fieldArray.length - 1) {
      return undefined;
    }
    if (
      !isNaN(Number(fieldArray[index].distance_min)) &&
      distanceMax <= Number(fieldArray[index].distance_min)
    ) {
      return 'validation.is_smaller';
    }
    return undefined;
  };
  const validate = createValidator([isNumber, validationFn, isInteger]);

  return (
    <FormikInputField
      name={`price_increments.${index}.distance_max`}
      type="text"
      validate={validate}
      showErrorMessage={false}
      allowClear={allowClear}
    />
  );
};
