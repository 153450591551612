import styled from 'styled-components';
import { Column, DataTable } from '@creditornot/cb-data-table';
import { useIntl } from 'react-intl';

import { ApiErrorNotification, DataCellContent, ResponsiveContainer } from 'components';
import { useSubscriptionUsersQueryParams } from 'views/subscription/hooks/useSubscriptionUsersQueryParams';
import { UserStatus } from 'components/UserStatus';
import { SubscriptionInvite } from 'modules/subscription/types';

import { SubscriptionInviteActionButtons } from './SubscriptionInviteActionButtons';

interface Props {
  data?: SubscriptionInvite[] | null;
  loading?: boolean;
  dataCellProps: ComponentProps;
  error?: any;
  pageCount?: number;
  selectedInvites: string[];
  onInviteSelectionClick: (
    selectedRowKeys: string[],
    selectedRecords: SubscriptionInvite[],
  ) => void;
  onSelectAllInvites: () => void;
}

interface ComponentProps {
  onOpenDeleteInviteModal: (id: string) => void;
  onOpenResendInviteModal: (id: string) => void;
}

const ErrorContainer = styled(ResponsiveContainer)`
  padding-top: 22px;
  padding-bottom: 22px;
`;

const useColumns = (): Column<SubscriptionInvite, ComponentProps>[] => {
  const { formatMessage } = useIntl();

  return [
    {
      id: 'email',
      name: formatMessage({ id: 'common.email' }),
      render: ({ record }) => {
        return <DataCellContent disabled={false}>{record.email}</DataCellContent>;
      },
    },
    {
      id: 'status',
      name: formatMessage({ id: 'common.status' }),
      style: {
        width: '15%',
      },
      render: ({ record }) => {
        return (
          <DataCellContent>
            <UserStatus status={record.status} />
          </DataCellContent>
        );
      },
    },
    {
      id: 'actions',
      style: {
        width: '10%',
      },
      name: '',
      render: ({ record: { id }, onOpenDeleteInviteModal, onOpenResendInviteModal }) => {
        return (
          <SubscriptionInviteActionButtons
            id={id}
            onOpenDeleteInviteModal={onOpenDeleteInviteModal}
            onOpenResendInviteModal={onOpenResendInviteModal}
          />
        );
      },
    },
  ];
};

export const SubscriptionInviteDataTable = ({
  data,
  loading,
  error,
  dataCellProps,
  pageCount,
  selectedInvites,
  onInviteSelectionClick,
  onSelectAllInvites,
}: Props) => {
  const { formatMessage } = useIntl();
  const columns = useColumns();
  const [{ page = 1, page_size = 50 }, setQueryParams] = useSubscriptionUsersQueryParams();

  if (error) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={error} />
      </ErrorContainer>
    );
  }

  const hidePagination = loading || !data;

  return (
    <DataTable
      horizontalPadding="wide"
      dataSource={data ?? []}
      columns={columns}
      tableLayout="fixed"
      getRowKey={(invite) => invite.id}
      componentProps={{ ...dataCellProps }}
      loading={loading}
      rowSelection={{
        selectedRowKeys: selectedInvites,
        onChange: onInviteSelectionClick,
        onSelectAll: onSelectAllInvites,
      }}
      emptyMessage={{ title: formatMessage({ id: 'wolt-plus.no-invites' }) }}
      pagination={
        hidePagination
          ? undefined
          : {
              disableWhenLoading: true,
              pageCount: pageCount || 1,
              pageSize: page_size,
              activePage: page,
              onPageChange: (p) => setQueryParams({ page: p }),
              onPageSizeChange: (p) => {
                setQueryParams({ page: 1, page_size: p });
              },
              pageSizeOptions: [10, 20, 50, 100],
            }
      }
    />
  );
};
