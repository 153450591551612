import { DataTable } from '@creditornot/cb-data-table';

import { PaginatedData } from 'types';
import { EventUser } from 'modules/events/types';

import { useEventUsersColumns } from './hooks';
import { EventUsersNoParticipants } from './EventUsersNoParticipants';

interface Props {
  onPageChange: (pageNumber: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onDeleteClick: (eventUser: EventUser) => void;
  onResendInviteClick: (eventUser: EventUser) => void;
  onInviteClick: () => void;
  page: number;
  pageSize: number;
  isLoading?: boolean;
  isSearching: boolean;
  isFiltering: boolean;
  data: PaginatedData<EventUser> | null | undefined;
  eventBudget: number;
  isEndedEvent: boolean;
  currency: string | undefined;
}

export const EventUsersDataTable = ({
  data,
  isLoading,
  onDeleteClick,
  onResendInviteClick,
  onPageChange,
  onPageSizeChange,
  onInviteClick,
  page,
  isSearching,
  isFiltering,
  pageSize,
  eventBudget,
  isEndedEvent,
  currency,
}: Props) => {
  const columns = useEventUsersColumns();

  if (data?.total_items_count === 0) {
    return (
      <EventUsersNoParticipants
        onInviteClick={onInviteClick}
        isSearching={isSearching}
        isFiltering={isFiltering}
        isEndedEvent={isEndedEvent}
      />
    );
  }

  const loading = isLoading || data?.total_items_count === 0;

  return (
    <DataTable
      dataSource={data?.results ?? []}
      columns={columns}
      getRowKey={(eventUser) => eventUser.invite_id}
      loading={loading}
      componentProps={{
        onDeleteClick: isEndedEvent ? undefined : onDeleteClick,
        onResendInviteClick: isEndedEvent ? undefined : onResendInviteClick,
        eventBudget,
        currency,
      }}
      pagination={
        loading
          ? undefined
          : {
              disableWhenLoading: true,
              pageCount: Math.ceil((data?.total_items_count ?? 0) / (data?.page_size ?? 1)),
              pageSize: pageSize,
              activePage: page,
              onPageChange,
              onPageSizeChange,
              pageSizeOptions: [10, 20, 50, 100],
            }
      }
    />
  );
};
