// Equatorial circumference of the Earth
const EARTH_EQUATORIAL_CIRCUMFERENCE_METERS = 40075016.686;

const degreesToRadians = (degrees: number): number => degrees * (Math.PI / 180);

/**
 * Calculates an approximate zoom level for a Mapbox map based on the distance that should be within the bounds of the map
 *
 * https://stackoverflow.com/questions/71882172/zoom-level-by-distance-in-mapbox-map-for-xamarin-forms
 *
 * @param lat Latitude in the middle of the map
 * @param distance The distance that should be visible on the map (in meters)
 * */
export function calculateZoomLevel(lat: number, distance: number): number {
  const logNum =
    (EARTH_EQUATORIAL_CIRCUMFERENCE_METERS * Math.cos(degreesToRadians(lat))) / distance;
  return Math.log2(logNum);
}
