import { useField } from 'formik';
import { useCallback } from 'react';

import { PolicySelect } from 'modules/policies/components';
import { PolicySelectProps } from 'modules/policies/components/PolicySelect';

export type FormikPolicySelectFieldProps = {
  name: string;
  selectProps?: Partial<PolicySelectProps>;
};

export const FormikPolicySelectField = ({ name, selectProps }: FormikPolicySelectFieldProps) => {
  const [{ value }, , { setValue }] = useField<string[]>(name);
  const handleChange = useCallback(
    (value: string | null) => {
      setValue(value === null ? [] : [value]);
    },
    [setValue],
  );
  return <PolicySelect value={value?.[0]} onChange={handleChange} {...selectProps} />;
};
