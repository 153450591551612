import * as t from 'io-ts';

import { restaurantApiAgent } from 'apiAgents';
import { logValidationError } from 'validation';
import { ResponseStatus } from 'types';

import { DeliveryAreaType, Policy } from './types';

export const fetchPolicies = async (corporateId: string) => {
  const { data } = await restaurantApiAgent.get<Policy[]>(
    `/v1/waw-api/corporates/${corporateId}/policies`,
  );
  logValidationError('fetchPolicies', t.array(Policy), data);
  return data;
};

export type PolicyData = {
  budget_allowance:
    | Record<string, never>
    | {
        period: 'day' | 'week' | 'month' | 'quarter' | 'year';
        amount: number;
        exclude_delivery_fee: boolean;
      };
  delivery_areas: {
    latitude: number;
    longitude: number;
    name: string;
    radius_meters: number;
    delivery_type: DeliveryAreaType;
  }[];
  description: string;
  giftcard_purchases_allowed: boolean;
  group_ids: string[];
  name: string;
  non_restaurant_purchases_allowed: boolean;
  all_non_restaurant_product_lines_allowed: boolean;
  restaurant_purchases_allowed: boolean;
  allowed_non_restaurant_product_lines: string[];
  order_comment_required: boolean;
  times: {
    start_minute: number;
    end_minute: number;
    day_of_week: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
  }[];
};

export const createPolicy = async (corporateId: string, policyData: PolicyData) => {
  const { data } = await restaurantApiAgent.post<Policy>(
    `/v1/waw-api/corporates/${corporateId}/policies`,
    policyData,
  );
  logValidationError('createPolicy', Policy, data);
  return data;
};

export const editPolicy = async (corporateId: string, policyId: string, policyData: PolicyData) => {
  const { data } = await restaurantApiAgent.patch<Policy>(
    `/v1/waw-api/corporates/${corporateId}/policies/${policyId}`,
    policyData,
  );
  logValidationError('editPolicy', Policy, data);
  return data;
};

export const deletePolicy = async (corporateId: string, policyId: string) => {
  const { data } = await restaurantApiAgent.delete(
    `/v1/waw-api/corporates/${corporateId}/policies/${policyId}`,
  );
  logValidationError('deletePolicy', ResponseStatus, data);
  return data;
};

export const fetchPolicy = async (corporateId: string, policyId: string) => {
  const { data } = await restaurantApiAgent.get<Policy>(
    `/v1/waw-api/corporates/${corporateId}/policies/${policyId}`,
  );
  logValidationError('fetchPolicy', Policy, data);
  return data;
};

export const removePolicyFromGroup = async (
  corporateId: string,
  groupId: string,
  policyId: string,
) => {
  const { data } = await restaurantApiAgent.delete(
    `/v1/waw-api/corporates/${corporateId}/groups/${groupId}/policies/${policyId}`,
  );
  logValidationError('removePolicyFromGroup', ResponseStatus, data);
  return data;
};
