import * as t from 'io-ts';

export type Address = t.TypeOf<typeof Address>;
export const Address = t.type({
  city: t.string,
  country: t.string,
  post_code: t.string,
  street: t.string,
});

export type BankAccount = t.TypeOf<typeof BankAccount>;
export const BankAccount = t.type({
  account_bic: t.string,
  account_iban: t.string,
  country_code: t.string,
});

export type Vat = t.TypeOf<typeof Vat>;
export const Vat = t.type({
  country_code: t.string,
  vat_id: t.string,
});

export type AgreementParty = t.TypeOf<typeof AgreementParty>;
export const AgreementParty = t.type({
  address: Address,
  bank_accounts: t.array(BankAccount),
  business_id: t.string,
  country_code: t.string,
  created_at: t.string,
  id: t.string,
  legal_name: t.string,
  modified_at: t.string,
  vats: t.array(Vat),
});
