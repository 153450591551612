import styled from 'styled-components';

import { useFetchSelfServiceCorporate } from 'modules/corporates';
import { ApiErrorNotification, Spinner } from 'components';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { Corporate } from 'modules/corporates/types';

import { TrackBrandingSettings } from './TrackBrandingSettings';
import { DeliveryPreferenceSettings } from './DeliveryPreferenceSettings';
import { SmsNotificationsSettings } from './SmsNotificationsSettings';
import { CustomerSupportContactInformationSettings } from './CustomerSupportContactInformationSettings';

type CustomerSupportContactInformationSettingsProps = {
  corporate: Corporate;
};

const Root = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ApiErrorNotificationContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 24px;
`;

const Section = styled.div`
  max-width: 720px;
  width: 100%;

  & + & {
    margin-top: 32px;
  }
`;

const SpinnerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeliverySettingsView = ({
  corporate,
}: CustomerSupportContactInformationSettingsProps) => {
  const { data, isLoading, error } = useFetchSelfServiceCorporate(corporate.id);
  const { isWoltEmployeeWithUpdate } = useIsUserWoltEmployee();

  return (
    <>
      {isLoading && (
        <SpinnerBox>
          <Spinner size="medium" />
        </SpinnerBox>
      )}
      {error && (
        <ApiErrorNotificationContainer>
          <ApiErrorNotification error={error} />
        </ApiErrorNotificationContainer>
      )}
      {data && (
        <Root>
          <DeliveryPreferenceSettings selfServiceCorporate={data} corporate={corporate} />
          <CustomerSupportContactInformationSettings
            selfServiceCorporate={data}
            corporateId={corporate.id}
          />
          <SmsNotificationsSettings selfServiceCorporate={data} corporateId={corporate.id} />
          {isWoltEmployeeWithUpdate && (
            <Section>
              <TrackBrandingSettings corporateId={corporate.id} corporateName={data.name} />
            </Section>
          )}
        </Root>
      )}
    </>
  );
};
