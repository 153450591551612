import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ConfirmationModal } from 'components';
import { LocalizedMessage } from 'i18n';
import { useDeleteInvite } from 'modules/invites';

interface Props {
  show: boolean;
  selectedInviteIds: string[];
  onRemoveInvitesSuccess: () => void;
  onClose: () => void;
}

type RemoveInvitesModalTitleProps = { numberOfInvites: number };

const RemoveInvitesModalTitle = ({ numberOfInvites }: RemoveInvitesModalTitleProps) => {
  return numberOfInvites > 1 ? (
    <LocalizedMessage messageKey="users.delete-invites.title" />
  ) : numberOfInvites === 1 ? (
    <LocalizedMessage messageKey="users.delete-invite.title" />
  ) : null;
};

export const RemoveInvitesModal = ({
  show,
  selectedInviteIds,
  onRemoveInvitesSuccess,
  onClose,
}: Props) => {
  const { formatMessage } = useIntl();
  const deleteUserInvite = useDeleteInvite();

  const handleConfirm = useCallback(async () => {
    const deleteInvitePromises = selectedInviteIds.map(deleteUserInvite);
    await Promise.all([...deleteInvitePromises]);
  }, [selectedInviteIds, deleteUserInvite]);

  return (
    <ConfirmationModal
      onClose={onClose}
      onConfirm={handleConfirm}
      show={show}
      content={formatMessage(
        { id: 'users.delete-invites.confirmation-question-count' },
        {
          count: selectedInviteIds?.length,
        },
      )}
      title={<RemoveInvitesModalTitle numberOfInvites={selectedInviteIds.length} />}
      confirmationButtonMessage={<LocalizedMessage messageKey="common.delete" />}
      confirmationButtonVariant="lightRed"
      onSuccess={() => setTimeout(onRemoveInvitesSuccess, 500)}
    />
  );
};
