import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { DistanceBasedDeliveryPriceRange } from '@creditornot/daas-core-api-client';

import { LocalizedCurrencySymbol, parseCurrency, useI18n } from 'i18n';

export type CustomPricingPreviewProps = {
  priceIncrements: DistanceBasedDeliveryPriceRange[];
  basePrice: number;
  currency: string;
  className?: string;
};

const Body3 = styled.p`
  ${typographyCss.Body3()}
`;

export const CustomPricingPreview = ({
  basePrice,
  currency,
  className,
  priceIncrements,
}: CustomPricingPreviewProps) => {
  const { getLocalizedMessage } = useI18n();
  const localCurencySymbol = LocalizedCurrencySymbol({ currency });
  return (
    <div className={className}>
      <Body3>
        <b>{priceIncrements.length}</b>{' '}
        {getLocalizedMessage('corporate-form.custom-price.price-distance-ranges')}
      </Body3>
      {priceIncrements.map((increment, index) => {
        return (
          <p key={index}>
            {increment.distance_min} m to{' '}
            {increment.distance_max === 0 ? 0 : increment.distance_max - 1} m:{' '}
            {parseCurrency(basePrice + increment.fee, currency)}
            {localCurencySymbol}
          </p>
        );
      })}
    </div>
  );
};
