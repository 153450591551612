import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { Column, DataTable, DataTableProps } from '@creditornot/cb-data-table';
import { TwoPersons } from '@creditornot/cb-icons';

import { DataCellContent, ResponsiveContainer, ApiErrorNotification } from 'components';
import { LocalizedMessage, useI18n, LocalizedNumber, LocalizedDate, LocalizedTime } from 'i18n';
import { isDefined } from 'utils';
import { Order } from 'modules/orders/types';
import { PaginatedData } from 'types/PaginatedData';

import { OrderStatus } from './OrderStatus';

interface Props extends Partial<DataTableProps<Order, any>> {
  data: PaginatedData<Order> | null;
  month?: number;
  year?: number;
  pageSize?: number;
  page?: number;
  onChange: (params: { page: number; page_size?: number }) => void;
  loading?: boolean;
  error?: any;
}

const ErrorContainer = styled(ResponsiveContainer)`
  padding-top: 22px;
  padding-bottom: 22px;
`;

const StyledTwoPersons = styled(TwoPersons)`
  height: 20px;
  width: 20px;
  color: ${color.textSubdued};
`;

const UserDataCell = styled.div`
  display: flex;
  align-items: center;
`;

const UserDataCellContent = styled(DataCellContent)`
  margin-inline-end: 16px;
`;

const useColumns = (): Column<Order, Record<string, unknown>>[] => {
  const { getLocalizedMessage } = useI18n();
  return [
    {
      id: 'status',
      name: getLocalizedMessage('orders.status-column-title'),
      style: {
        width: '12%',
      },
      render: ({ record: { status, venue_preorder_time } }) => {
        return (
          <DataCellContent>
            <OrderStatus status={status} isPreOrder={!!venue_preorder_time} />
          </DataCellContent>
        );
      },
    },
    {
      id: 'venue',
      name: getLocalizedMessage('orders.venue-column-title'),
      render: ({ record: { venue } }) => {
        return <DataCellContent subContent={venue.address.city}>{venue.name}</DataCellContent>;
      },
    },
    {
      id: 'user',
      name: getLocalizedMessage('orders.user-column-title'),
      render: ({
        record: {
          user: { name: userName, email },
          is_group_order,
        },
      }) => {
        return (
          <UserDataCell>
            <UserDataCellContent subContent={email}>{userName}</UserDataCellContent>
            {is_group_order && (
              <Popover
                placement="bottom"
                contentZIndex={1}
                content={<LocalizedMessage messageKey="common.group-order" />}
              >
                <StyledTwoPersons viewBox="0 0 20 20" />
              </Popover>
            )}
          </UserDataCell>
        );
      },
    },
    {
      id: 'total-amount',
      name: getLocalizedMessage('orders.total-column-title'),
      style: {
        width: '10%',
      },
      render: ({ record: { total_amount, currency } }) => {
        return (
          <DataCellContent>
            <LocalizedNumber value={total_amount} currency={currency} />
          </DataCellContent>
        );
      },
    },
    {
      id: 'accounting-note',
      name: getLocalizedMessage('common.accounting-note'),
      style: {
        width: '27%',
      },
      render: ({ record: { accounting_note } }) => {
        return <DataCellContent>{accounting_note ? accounting_note : '–'}</DataCellContent>;
      },
    },
    {
      id: 'delivery-time',
      name: getLocalizedMessage('order.delivery-time-column-title'),
      style: {
        width: '12%',
      },
      render: ({ record: { venue_time_delivered, venue_preorder_time, status } }) => {
        const isFuturePreorder = !!venue_preorder_time && status == 'received';

        if (isFuturePreorder) {
          return (
            <DataCellContent subContent={<LocalizedTime value={new Date(venue_preorder_time)} />}>
              <LocalizedDate value={new Date(venue_preorder_time)} />
            </DataCellContent>
          );
        }

        return (
          <DataCellContent
            subContent={
              venue_time_delivered && (
                <LocalizedTime
                  value={new Date(venue_time_delivered)}
                  hour="numeric"
                  minute="numeric"
                />
              )
            }
          >
            {venue_time_delivered ? <LocalizedDate value={new Date(venue_time_delivered)} /> : '–'}
          </DataCellContent>
        );
      },
    },
  ];
};

const OrdersDataTable: React.FC<Props> = ({
  data,
  loading,
  page = 1,
  pageSize: page_size,
  onChange,
  month,
  year,
  error,
  ...rest
}) => {
  const columns = useColumns();
  const { getLocalizedMessage } = useI18n();

  if (error) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={error} />
      </ErrorContainer>
    );
  }

  const hidePagination = loading || data?.total_items_count === 0;

  const show30DaysEmptyMessage =
    (!isDefined(month) || !isDefined(year)) && data?.results.length === 0 && !loading;

  return (
    <DataTable
      horizontalPadding="wide"
      tableLayout="fixed"
      dataSource={data?.results ?? []}
      columns={columns}
      getRowKey={(order) => order.id}
      emptyMessage={
        show30DaysEmptyMessage
          ? {
              title: getLocalizedMessage('orders.no-orders-in-30-days'),
              description: getLocalizedMessage('orders.no-orders-explanation'),
            }
          : {
              title: getLocalizedMessage('orders.no-orders'),
              description: getLocalizedMessage('orders.no-orders-explanation'),
            }
      }
      loading={loading}
      pagination={
        hidePagination
          ? undefined
          : {
              disableWhenLoading: true,
              pageCount: Math.ceil((data?.total_items_count ?? 0) / (data?.page_size ?? 1)),
              pageSize: page_size,
              activePage: page,
              onPageChange: (p) => onChange({ page: p }),
              onPageSizeChange: (p) => {
                onChange({ page: 1, page_size: p });
              },
              pageSizeOptions: [10, 20, 50, 100],
            }
      }
      {...rest}
    />
  );
};

export default OrdersDataTable;
