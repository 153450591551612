import { color } from '@creditornot/cb-ingredients/design-tokens';
import { FormRow, InputContainer } from '@creditornot/cb-form';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { Button } from '@creditornot/cb-button';
import styled from 'styled-components';
import { Alert } from '@creditornot/cb-alert';
import { Checkmark } from '@creditornot/cb-icons';
import _ from 'lodash';

import { LocalizedMessage, useI18n } from 'i18n';
import {
  BusinessIdField,
  CityField,
  CompanyNameField,
  ContactPersonEmailField,
  ContactPersonNameField,
  ContactPersonPhoneNumberField,
  InvoicingEmailField,
  PostalCodeField,
  ReceiptEmailField,
  SendInvoiceCopyToEmailsField,
  StreetAddressField,
  VatIdField,
} from 'modules/corporates/components';
import { FormFooter, ResponsiveContainer } from 'components';
import { useCorporate, useEditCorporate } from 'modules/corporates';
import { Corporate } from 'modules/corporates/types';
import { processError } from 'utils';

import { AddBillingInformationFormValues } from './types';

type AddBillingInformationFormProps = {
  onSuccess: (corporate: Corporate) => void;
  className?: string;
  onClose: () => void;
};

type FormikAddBillingInformationFormProps = {
  onClose: () => void;
  className?: string;
  corporate: Corporate;
};

const AlertContainer = styled(ResponsiveContainer)`
  margin-bottom: 24px;
`;

const StyledAlert = styled(Alert)`
  width: 100%;
`;

const FirstFormRow = styled(FormRow)`
  border-top: 1px solid ${color.border};
`;

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: 24px;
  }
`;

const InputContainerWrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;

const FormikAddBillingInformationForm = ({
  className,
  corporate,
  onClose,
}: FormikAddBillingInformationFormProps) => {
  const { status, isSubmitting } = useFormikContext();
  const { getLocalizedMessage } = useI18n();
  return (
    <>
      <Form className={className}>
        <FirstFormRow
          labelHtmlFor="name"
          labelAlign="center"
          horizontalPadding
          required
          label={getLocalizedMessage('corporate-form.name')}
        >
          <CompanyNameField inputProps={{ autoFocus: false, disabled: true }} allowClear={false} />
        </FirstFormRow>
        <FormRow
          labelHtmlFor="business_id"
          labelAlign="center"
          horizontalPadding
          required
          label={getLocalizedMessage('common.business_id')}
        >
          <BusinessIdField inputProps={{ autoFocus: true }} />
        </FormRow>
        <FormRow
          label={getLocalizedMessage('corporate-form.vat_id')}
          labelHtmlFor="vat_id"
          labelAlign="center"
          horizontalPadding
          required
        >
          <VatIdField />
        </FormRow>
        <FormRow
          labelAlign="center"
          horizontalPadding
          label={getLocalizedMessage('common.country')}
        >
          {corporate?.country ?? '–'}
        </FormRow>
        <FormRow
          label={getLocalizedMessage('corporate-form.billing-address')}
          labelHtmlFor="address"
          required
          horizontalPadding
        >
          <StyledInputContainer label={getLocalizedMessage('common.address')}>
            <StreetAddressField />
          </StyledInputContainer>
          <InputContainerWrapper>
            <InputContainer
              css={`
                width: 50%;
              `}
              label={getLocalizedMessage('common.postal_code')}
            >
              <PostalCodeField />
            </InputContainer>
            <InputContainer
              css={`
                margin-inline-start: 24px;
                width: 50%;
              `}
              label={getLocalizedMessage('common.city')}
            >
              <CityField />
            </InputContainer>
          </InputContainerWrapper>
        </FormRow>
        <FormRow
          label={getLocalizedMessage('corporate-form.invoicing_email-label')}
          labelHtmlFor="invoice_email"
          horizontalPadding
          required
        >
          <StyledInputContainer
            labelHtmlFor="invoice_email"
            label={getLocalizedMessage('corporate-form.invoicing_email')}
            hint={getLocalizedMessage('corporate-form.invoicing_email_explanation')}
          >
            <InvoicingEmailField />
          </StyledInputContainer>
          <StyledInputContainer>
            <SendInvoiceCopyToEmailsField />
          </StyledInputContainer>
        </FormRow>
        <FormRow
          label={getLocalizedMessage('corporate-form.contact-person-title')}
          labelHtmlFor="contact_name"
          horizontalPadding
          required
        >
          <StyledInputContainer label={getLocalizedMessage('common.name')}>
            <ContactPersonNameField />
          </StyledInputContainer>
          <StyledInputContainer label={getLocalizedMessage('common.email')}>
            <ContactPersonEmailField />
          </StyledInputContainer>
          <StyledInputContainer label={getLocalizedMessage('common.phone-number')}>
            <ContactPersonPhoneNumberField />
          </StyledInputContainer>
        </FormRow>
        <FormRow
          label={getLocalizedMessage('corporate-form.receipt-email')}
          labelHtmlFor="single_receipt_email"
          horizontalPadding
        >
          <StyledInputContainer
            hint={getLocalizedMessage('corporate-form.receipt-email-explanation')}
          >
            <ReceiptEmailField alwaysShowError />
          </StyledInputContainer>
        </FormRow>
        {status?.error && (
          <AlertContainer>
            <StyledAlert variant="error" title={processError(status.error).message}>
              {processError(status.error).data}
            </StyledAlert>
          </AlertContainer>
        )}
        <FormFooter
          rightContent={
            <>
              <Button
                disabled={status?.submitSucceeded || isSubmitting}
                onClick={onClose}
                variant="lightBlue"
                size="small"
              >
                <LocalizedMessage messageKey="common.cancel" />
              </Button>
              <Button
                variant="blue"
                size="small"
                type="submit"
                icon={status?.submitSucceeded && <Checkmark />}
                disabled={status?.submitSucceeded || isSubmitting}
                loading={!status?.submitSucceeded && isSubmitting}
              >
                <LocalizedMessage messageKey="common.submit" />
              </Button>
            </>
          }
        />
      </Form>
    </>
  );
};

export const AddBillingInformationForm = ({
  onSuccess,
  className,
  onClose,
}: AddBillingInformationFormProps) => {
  const { data: corporate } = useCorporate();
  const editCorporate = useEditCorporate();
  const handleSubmit = useCallback(
    async (
      values: AddBillingInformationFormValues,
      { setSubmitting, setStatus }: FormikHelpers<AddBillingInformationFormValues>,
    ) => {
      try {
        setSubmitting(true);
        // Corporate name should not be sent in the payload
        const normalizedValues = _.omit(values, 'name');
        const corporate = await editCorporate(normalizedValues);
        setSubmitting(false);
        setStatus({ submitSucceeded: true });
        setTimeout(() => {
          onSuccess(corporate);
        }, 500);
      } catch (error) {
        setSubmitting(false);
        setStatus({ error });
      }
    },
    [editCorporate, onSuccess],
  );
  const initialValues: AddBillingInformationFormValues = useMemo(
    () => ({
      address: corporate?.address ?? '',
      business_id: corporate?.business_id ?? '',
      city: corporate?.city ?? '',
      contact_email: corporate?.contact_email ?? '',
      contact_name: corporate?.contact_name ?? '',
      contact_phone: corporate?.contact_phone ?? '',
      invoice_email_cc_recipients: corporate?.invoice_email_cc_recipients ?? [],
      invoice_email: corporate?.invoice_email ?? '',
      name: corporate?.name ?? '',
      post_code: corporate?.post_code ?? '',
      single_receipt_email: corporate?.single_receipt_email ?? '',
      vat_id: corporate?.vat_id ?? '',
    }),
    [corporate],
  );
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {corporate && (
        <FormikAddBillingInformationForm
          className={className}
          corporate={corporate}
          onClose={onClose}
        />
      )}
    </Formik>
  );
};
