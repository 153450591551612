export const formatToUuid = (hexString: string): string => {
  // Check if the input already contains hyphens or is not of length 32
  if (hexString.includes('-') || hexString.length !== 32) {
    return hexString;
  }

  // Add hyphens to convert the hex string to UUID format
  const uuidFormatted = `${hexString.slice(0, 8)}-${hexString.slice(8, 12)}-${hexString.slice(
    12,
    16,
  )}-${hexString.slice(16, 20)}-${hexString.slice(20)}`;

  return uuidFormatted;
};
