import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQueryClient } from 'modules/react-query';

import { editEvent, EditEventData } from './api';

export const useEditEvent = () => {
  const { data: corporate } = useCorporate();
  const queryClient = useQueryClient();
  return useCallback(
    async (eventId: string, data: EditEventData) => {
      if (!corporate) {
        return Promise.reject('Corporate is missing');
      }

      const result = await editEvent(corporate.id, eventId, data);

      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 500);
      return result;
    },
    [corporate, queryClient],
  );
};
