import { color } from '@creditornot/cb-ingredients/design-tokens';
import { FormRow } from '@creditornot/cb-form';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { Button, TextButton } from '@creditornot/cb-button';
import { rem } from 'polished';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RadioCard } from '@creditornot/cb-radio';
import { Input, InputAlert } from '@creditornot/cb-input';
import { Controller } from 'react-hook-form';
import { Alert } from '@creditornot/cb-alert';
import { typographyCss } from '@creditornot/cb-ingredients';
import { useLocalStorage } from 'react-use';
import { Checkbox } from '@creditornot/cb-checkbox';
import { Trashbin } from '@creditornot/cb-icons';
import { Tag } from '@creditornot/cb-tag';
import { B2bCustomerConfigResponse, CorporateBranding } from '@creditornot/daas-core-api-client';

import {
  b2bConfigApiClient,
  useDeleteB2bConfigCorporateBranding,
  useUpdateB2bConfig,
} from 'modules/b2b-configs';
import { useWoltForm } from 'modules/react-hook-form/useWoltForm';
import { FormikFieldContainer as FieldContainer } from 'components/FormikFieldContainer';
import { ApiErrorNotification, ImageEditor } from 'components';
import { uploadFile } from 'utils';

import { Preview } from './Preview';
import {
  BannerImageFileInput,
  BannerImagePlaceholder,
  LogoImagePlaceholder,
} from './ImagePlaceholder';

const Root = styled.div`
  position: relative;
`;

type TrackBrandingSettingsProps = {
  className?: string;
  b2bConfig: B2bCustomerConfigResponse;
  corporateId: string;
  corporateName: string;
  onCancel: () => void;
  onEditSuccess: () => void;
};

const RadioCardsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: ${rem(8)};

  > * {
    flex: 1;
  }
`;

const ColorRect = styled.div<{ $fill?: string }>`
  min-width: ${rem(81)};
  height: ${rem(36)};
  background-color: ${(props) => props.$fill};
  display: inline-block;
  border-radius: ${rem(8)};
  margin-inline-start: ${rem(18)};
  flex-shrink: 0;
  border: 1px solid ${color.border};
`;

const LogoImage = styled.img`
  width: ${rem(150)};
  height: ${rem(150)};
  border-radius: 50%;
  display: block;
`;

const BannerImage = styled.div<{ $imageUrl: string }>`
  width: 100%;
  aspect-ratio: 5 / 3;
  background-image: url(${(props) => props.$imageUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: ${rem(8)};
`;

const FooterImage = styled.img`
  height: ${rem(160)};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${rem(24)};
  gap: ${rem(8)};
  width: 100%;
  border-top: 1px solid ${color.border};
`;

const ColorInputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ErrorContainer = styled.div`
  margin-top: ${rem(12)};
`;

const ImageSection = styled.div`
  & + & {
    margin-top: ${rem(24)};
  }
`;

const InputDescription = styled.span`
  ${typographyCss.Body3()}
  color: ${color.text};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem(16)};
`;

const RequiredAsterisk = styled.span`
  color: ${color.textNegative};
`;

const ImageContainer = styled.div<{ $verticalPadding?: boolean; $invalid?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => (props.$invalid ? color.borderNegative : color.border)};
  border-radius: ${rem(8)};
  padding: ${({ $verticalPadding }) => ($verticalPadding ? rem(16) : 0)} 0;
`;

const DeleteButton = styled(TextButton).attrs({
  variant: 'red',
  size: 'small',
  icon: <Trashbin />,
  iconPosition: 'right',
})``;

const StyledPreview = styled(Preview)`
  margin-bottom: ${rem(16)};
`;

type BrandType = 'wolt' | 'custom';

type FormValues = {
  type: BrandType;
  theme: CorporateBranding['theme'];
  brand_color: string;
  title_color: string;
  background_color: string;
  stepper_background_color: string;
  hide_wolt_brand: boolean;
  logo_square_url: string;
  logo_footer_url: string;
  contact_details_modal_image_url: string;
};

const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

const validationSchema: yup.ObjectSchema<FormValues> = yup.object({
  type: yup.mixed<BrandType>().oneOf(['wolt', 'custom']).default('wolt').required('Required'),
  theme: yup
    .mixed<CorporateBranding['theme']>()
    .oneOf(['dark', 'light'])
    .default('light')
    .when('type', {
      is: 'custom',
      then: (schema) => schema.required(),
    }),
  brand_color: yup
    .string()
    .default('')
    .when('type', {
      is: 'custom',
      then: (schema) =>
        schema.matches(hexColorRegex, { message: 'Must be a valid hex color' }).required(),
    }),
  title_color: yup
    .string()
    .default('')
    .when('type', {
      is: 'custom',
      then: (schema) =>
        schema.matches(hexColorRegex, {
          message: 'Must be a valid hex color',
          excludeEmptyString: true,
        }),
    }),
  background_color: yup
    .string()
    .default('')
    .when('type', {
      is: 'custom',
      then: (schema) =>
        schema
          .matches(hexColorRegex, {
            message: 'Must be a valid hex color',
            excludeEmptyString: true,
          })
          .required('Required'),
    }),
  stepper_background_color: yup
    .string()
    .default('')
    .when('type', {
      is: 'custom',
      then: (schema) =>
        schema.matches(hexColorRegex, {
          message: 'Must be a valid hex color',
          excludeEmptyString: true,
        }),
    }),
  hide_wolt_brand: yup
    .boolean()
    .default(false)
    .when('type', {
      is: 'custom',
      then: (schema) => schema.required('Required'),
    }),
  logo_square_url: yup
    .string()
    .default('')
    .when('type', {
      is: 'custom',
      then: (schema) => schema.required('Required'),
    }),
  logo_footer_url: yup.string().default(''),
  contact_details_modal_image_url: yup.string().default(''),
});

/**
 * Edit corporate branding config used in Wolt Drive track page
 */
export const EditTrackBrandingSettings = ({
  className,
  b2bConfig,
  corporateId,
  corporateName,
  onCancel,
  onEditSuccess,
}: TrackBrandingSettingsProps) => {
  const { corporate_branding: corporateBranding } = b2bConfig;
  const [draft, setDraft, removeDraft] = useLocalStorage<null | FormValues>(
    `branding-draft-${corporateId}`,
    null,
  );
  const defaultValues: FormValues = {
    type: corporateBranding ? 'custom' : 'wolt',
    brand_color: corporateBranding?.brand_color ?? '',
    background_color: corporateBranding?.background_color ?? '',
    theme: corporateBranding?.theme ?? 'light',
    title_color: corporateBranding?.title_color ?? '',
    hide_wolt_brand: corporateBranding?.hide_wolt_brand ?? false,
    stepper_background_color: corporateBranding?.stepper_background_color ?? '',
    logo_square_url: corporateBranding?.logo_square_url ?? '',
    logo_footer_url: corporateBranding?.logo_footer_url ?? '',
    contact_details_modal_image_url: corporateBranding?.contact_details_modal_image_url ?? '',
  };
  const { handleSubmit, watch, register, control, setValue, formState, getValues, trigger } =
    useWoltForm<FormValues>({
      resolver: yupResolver(validationSchema),
      defaultValues,
    });
  const { mutateAsync: b2bConfigMutateAsync } = useUpdateB2bConfig();
  const { mutateAsync: deleteCorporateBrandingMutateAsync } = useDeleteB2bConfigCorporateBranding();
  const [b2bConfigUpdateError, setB2bConfigUpdateError] = useState<unknown | undefined>(undefined);
  const [previewConfig, setPreviewConfig] = useState<CorporateBranding | null>(
    defaultValues.type === 'wolt' ? null : defaultValues,
  );
  const [isValidated, setIsValidated] = useState(false);

  const brandingType = watch('type');

  useEffect(() => {
    if (!draft) return;
    Object.entries(draft).forEach(([key, value]) => {
      setValue(key as keyof FormValues, value, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = watch(async (values) => {
      try {
        if (values.type === 'wolt') {
          setPreviewConfig(null);
          return;
        }
        const validatedValues = await validationSchema.validate(values);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { type, ...rest } = validatedValues;
        setPreviewConfig(rest);
      } catch (e) {
        // ignore change and not update preview config, otherwise preview iframe crashes when invalid values
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleImageSave = (fieldName: keyof FormValues) => async (file: File) => {
    const {
      data: { upload_url, cdn_url },
    } = await b2bConfigApiClient.corporateGetB2bConfigImageUploadUrl({
      corporateId: corporateId,
      fileName: file.name,
    });
    await uploadFile(file, upload_url, { headers: { 'Content-Type': '' } });
    setValue(fieldName, cdn_url, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
  };

  const handleRemoveImage = (fieldName: keyof FormValues) => () => {
    setValue(fieldName, '', { shouldDirty: true, shouldValidate: true, shouldTouch: true });
  };

  const handleSaveToDraft = async () => {
    setIsValidated(true);
    if (!(await trigger())) {
      return;
    }
    const values = getValues();
    setDraft(values);
    onCancel();
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (data.type === 'wolt') {
        await deleteCorporateBrandingMutateAsync({ corporateId });
      } else {
        const corporateBranding = { ...data, type: undefined };
        await b2bConfigMutateAsync({
          corporateId: corporateId,
          payload: {
            corporate_branding: corporateBranding,
          },
        });
      }

      removeDraft();
      onEditSuccess();
      return;
    } catch (error) {
      setB2bConfigUpdateError(error);
    }
  });

  return (
    <Root className={className}>
      <form onSubmit={onSubmit}>
        <FormRow horizontalPadding label="Branding">
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, onBlur, value } }) => (
              <RadioCardsContainer>
                <RadioCard
                  onChange={() => onChange('wolt')}
                  onBlur={onBlur}
                  checked={value === 'wolt'}
                  label="Wolt brand"
                />
                <RadioCard
                  onChange={() => onChange('custom')}
                  onBlur={onBlur}
                  checked={value === 'custom'}
                  label="Custom brand"
                />
              </RadioCardsContainer>
            )}
          />
          <InputAlert variant="info" style={{ marginTop: 12 }}>
            Applies to tracking for Wolt Drive Web and API deliveries
          </InputAlert>
        </FormRow>
        {brandingType === 'custom' && (
          <>
            <FormRow horizontalPadding label="Page template" required>
              <Controller
                control={control}
                name="theme"
                render={({ field: { onChange, onBlur, value } }) => (
                  <RadioCardsContainer>
                    <RadioCard
                      onChange={onChange}
                      onBlur={onBlur}
                      checked={value === 'light'}
                      value="light"
                      label="Light theme"
                    />
                    <RadioCard
                      onChange={onChange}
                      onBlur={onBlur}
                      checked={value === 'dark'}
                      value="dark"
                      label="Dark theme"
                    />
                  </RadioCardsContainer>
                )}
              />
              <InputAlert variant="info" style={{ margin: '12px 0' }}>
                Select dark theme if the brand uses dark color backgrounds on web or app.
              </InputAlert>
              <Checkbox {...register('hide_wolt_brand')}>Hide the Wolt logo</Checkbox>
            </FormRow>
            <FormRow horizontalPadding label="Images (JPG, PNG format)">
              <ImageSection>
                <Controller
                  control={control}
                  name="logo_square_url"
                  render={({ fieldState, field: { value } }) => (
                    <FieldContainer errorMessage={fieldState.error?.message}>
                      <InputDescription>
                        <span>
                          Brand logo (Recommended aspect ratio 1:1)
                          <RequiredAsterisk>*</RequiredAsterisk>
                        </span>
                        {value && (
                          <DeleteButton onClick={handleRemoveImage('logo_square_url')}>
                            Delete
                          </DeleteButton>
                        )}
                      </InputDescription>
                      <ImageContainer $verticalPadding $invalid={fieldState.invalid}>
                        {value ? (
                          <LogoImage src={value} alt="logo" />
                        ) : (
                          <ImageEditor
                            onCropDone={handleImageSave('logo_square_url')}
                            cropShape="round"
                            aspect={1}
                          >
                            {({ onClick }) => (
                              <LogoImagePlaceholder
                                description="(min.240x240px)"
                                onClick={onClick}
                              />
                            )}
                          </ImageEditor>
                        )}
                      </ImageContainer>
                    </FieldContainer>
                  )}
                />
                <InputAlert variant="info" style={{ marginTop: 8 }}>
                  Visible on the header of the page inside a circle, and footer.
                </InputAlert>
              </ImageSection>
              <ImageSection>
                <Controller
                  control={control}
                  name="contact_details_modal_image_url"
                  render={({ fieldState, field: { value } }) => (
                    <FieldContainer errorMessage={fieldState.error?.message}>
                      <InputDescription>
                        Brand image (Optional, Recommended aspect radio 5:3)
                        {value && (
                          <DeleteButton
                            onClick={handleRemoveImage('contact_details_modal_image_url')}
                          >
                            Delete
                          </DeleteButton>
                        )}
                      </InputDescription>

                      {value ? (
                        <ImageContainer>
                          <BannerImage $imageUrl={value} />
                        </ImageContainer>
                      ) : (
                        <ImageEditor
                          onCropDone={handleImageSave('contact_details_modal_image_url')}
                          aspect={5 / 3}
                        >
                          {({ onClick }) => (
                            <BannerImagePlaceholder
                              onClick={onClick}
                              description="(min.1000x600px)"
                              aspectRatio="5/3"
                            />
                          )}
                        </ImageEditor>
                      )}
                    </FieldContainer>
                  )}
                />
                <InputAlert variant="info" style={{ marginTop: 8 }}>
                  Visible on sender details modal, together with customer support info.
                </InputAlert>
              </ImageSection>
              <ImageSection>
                <Controller
                  control={control}
                  name="logo_footer_url"
                  render={({ fieldState, field: { value } }) => (
                    <FieldContainer errorMessage={fieldState.error?.message}>
                      <InputDescription>
                        Additional logo (Optional)
                        {value && (
                          <DeleteButton onClick={handleRemoveImage('logo_footer_url')}>
                            Delete
                          </DeleteButton>
                        )}
                      </InputDescription>

                      {value ? (
                        <ImageContainer $verticalPadding>
                          <FooterImage src={value} />
                        </ImageContainer>
                      ) : (
                        <BannerImageFileInput
                          description="(min.140x140px)"
                          onFileChange={handleImageSave('logo_footer_url')}
                        />
                      )}
                    </FieldContainer>
                  )}
                />
                <InputAlert variant="info" style={{ marginTop: 8 }}>
                  Visible on the footer, if provided. Use when the brand provides a logo file which
                  is not in square format.
                </InputAlert>
              </ImageSection>
            </FormRow>
            <FormRow horizontalPadding label="Colors (HEX format)">
              <Controller
                control={control}
                name="brand_color"
                render={({ field, fieldState }) => (
                  <FieldContainer errorMessage={fieldState.error?.message}>
                    <InputDescription>
                      <span>
                        Brand color <RequiredAsterisk>*</RequiredAsterisk>
                      </span>
                    </InputDescription>
                    <ColorInputContainer>
                      <Input {...field} invalid={fieldState.invalid} placeholder="E.g #ffffff" />
                      <ColorRect $fill={field.value} />
                    </ColorInputContainer>
                  </FieldContainer>
                )}
              />
              <InputAlert variant="info" style={{ marginTop: 8 }}>
                This is the color used on the timer, map pins and links.
              </InputAlert>
            </FormRow>
            <FormRow horizontalPadding label="">
              <Controller
                control={control}
                name="background_color"
                render={({ field, fieldState }) => (
                  <FieldContainer errorMessage={fieldState.error?.message}>
                    <InputDescription>
                      <span>
                        Page background color<RequiredAsterisk>*</RequiredAsterisk>
                      </span>
                    </InputDescription>
                    <ColorInputContainer>
                      <Input {...field} invalid={fieldState.invalid} placeholder="E.g #ffffff" />
                      <ColorRect $fill={field.value} />
                    </ColorInputContainer>
                  </FieldContainer>
                )}
              />
              <InputAlert variant="info" style={{ marginTop: 8 }}>
                Customise if a specific color is used on their website/app
              </InputAlert>
            </FormRow>

            <FormRow horizontalPadding label="">
              <Controller
                control={control}
                name="title_color"
                render={({ field, fieldState }) => (
                  <FieldContainer errorMessage={fieldState.error?.message}>
                    <InputDescription>Header title color (optional)</InputDescription>
                    <ColorInputContainer>
                      <Input {...field} invalid={fieldState.invalid} placeholder="E.g #ffffff" />
                      <ColorRect $fill={field.value} />
                    </ColorInputContainer>
                  </FieldContainer>
                )}
              />
              <InputAlert variant="info" style={{ marginTop: 8 }}>
                Customise if a specific color is used on their website/app. The default follows the
                page theme.
              </InputAlert>
            </FormRow>

            <FormRow horizontalPadding label="">
              <Controller
                control={control}
                name="stepper_background_color"
                render={({ field, fieldState }) => (
                  <FieldContainer errorMessage={fieldState.error?.message}>
                    <InputDescription>Status steps color (optional)</InputDescription>
                    <ColorInputContainer>
                      <Input {...field} invalid={fieldState.invalid} placeholder="E.g #ffffff" />
                      <ColorRect $fill={field.value} />
                    </ColorInputContainer>
                  </FieldContainer>
                )}
              />
              <InputAlert variant="info" style={{ marginTop: 8 }}>
                Customise if a specific color is used on their website/app. The default follows the
                page theme.
              </InputAlert>
            </FormRow>
          </>
        )}
        <FormRow
          horizontalPadding
          label="Preview"
          info="Preview is updated only when fields are valid. "
        >
          <StyledPreview
            pickupLocationName={corporateName}
            corporateBranding={previewConfig}
            rightHeaderContent={<Tag variant="lightBlack">DRAFT</Tag>}
          />
          <Button
            variant="blue"
            size="small"
            loading={formState.isSubmitting}
            disabled={!formState.isValid || formState.isSubmitting || !formState.isDirty}
            type="submit"
            stretch
          >
            Publish
          </Button>
          {!!b2bConfigUpdateError && (
            <ErrorContainer>
              <ApiErrorNotification error={b2bConfigUpdateError} />
            </ErrorContainer>
          )}
          {(isValidated || formState.isSubmitted) && !formState.isValid && (
            <ErrorContainer>
              <Alert variant="error">
                Uh oh, it looks like there are a few issues. Please correct the errors above.
              </Alert>
            </ErrorContainer>
          )}
        </FormRow>

        <ButtonContainer>
          <Button variant="lightBlue" size="small" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="blue" size="small" onClick={handleSaveToDraft}>
            Save
          </Button>
        </ButtonContainer>
      </form>
    </Root>
  );
};
