import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQuery, UseQueryOptions } from 'modules/react-query';
import { PaginatedData } from 'types';

import { fetchPaginatedGroups, FetchPaginatedGroupsParams } from './api';
import { Group } from './types';

export const useFetchGroups = <T = PaginatedData<Group>>(
  params: FetchPaginatedGroupsParams,
  config?: UseQueryOptions<PaginatedData<Group>, unknown, T>,
) => {
  const { data: corporate } = useCorporate();
  const queryFn = useCallback(() => {
    if (!corporate) {
      return Promise.reject();
    }
    return fetchPaginatedGroups(corporate.id, params);
  }, [corporate, params]);
  return useQuery({
    queryKey: ['groups', corporate?.id, params],
    queryFn,
    enabled: !!corporate,
    ...config,
  });
};
