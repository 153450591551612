import { EditCorporateData, EditCorporateRestrictedData } from 'modules/corporates/api';
import { isDefined } from 'utils';

import { EditCorporateFormValues } from '../../../views/settings/CompanySettings/types';

export const formatGeneralInformationFormValuesBeforeSending = ({
  name,
  business_id,
  country,
  address,
  post_code,
  city,
  contact_email,
  contact_name,
  contact_phone,
  industry,
}: Partial<EditCorporateFormValues>): Partial<EditCorporateData & EditCorporateRestrictedData> => {
  return {
    name: isDefined(name) ? name.trim() : undefined,
    business_id: isDefined(business_id) ? business_id.trim() : undefined,
    country: isDefined(country) ? country.trim() : undefined,
    address: isDefined(address) ? address.trim() : undefined,
    post_code: isDefined(post_code) ? post_code.trim() : undefined,
    city: isDefined(city) ? city.trim() : undefined,
    contact_name: isDefined(contact_name) ? contact_name.trim() : undefined,
    contact_email: isDefined(contact_email) ? contact_email.trim() : undefined,
    contact_phone: isDefined(contact_phone) ? contact_phone.trim() : undefined,
    industry: industry !== null && isDefined(industry) ? industry.trim() : undefined,
  };
};
