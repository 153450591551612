import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';

import { useI18n } from 'i18n';
import { DataList, EmptyState, DataCellContent, RtlCaret } from 'components';
import { Group } from 'modules/groups/types';

interface Props {
  groups: Group[] | undefined;
  onGroupClick: (groupId: string) => void;
  isLoading: boolean;
  error: any;
}

const DataRowContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const InfoContainer = styled.div`
  min-width: 0;
`;

const MembersAndPoliciesContent = styled.small`
  ${typographyCss.Small()}
  margin-top: 4px;
`;

const CaretEnd = styled(RtlCaret).attrs({ alignment: 'end' })`
  min-width: 16px;
  height: 16px;
  fill: ${color.textSubdued};
`;

export const GroupsDataList = ({ groups, onGroupClick, isLoading, error }: Props) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <DataList
      data={groups}
      getDataItemId={({ id }) => id}
      onDataItemClick={({ id }) => onGroupClick(id)}
      loading={isLoading}
      noData={<EmptyState icon="group">{getLocalizedMessage('groups.no-groups-found')}</EmptyState>}
      error={error}
      renderDataItem={({ name, description, user_count, policies }) => {
        const membersAndPolicies = [
          getLocalizedMessage('groups.members', { userCount: user_count }),
          policies?.map(({ name }) => name).join(', '),
        ]
          .filter(Boolean)
          .join(' \u2219 ');
        return (
          <DataRowContent>
            <InfoContainer>
              <DataCellContent subContent={description}>{name}</DataCellContent>
              <MembersAndPoliciesContent>{membersAndPolicies}</MembersAndPoliciesContent>
            </InfoContainer>
            <CaretEnd />
          </DataRowContent>
        );
      }}
    />
  );
};
