import { FormattedMessage } from 'react-intl';

import { DataCellContent } from 'components';

import { CorporateProducts } from '../../modules/corporates/types';

type Props = {
  productLine: CorporateProducts;
};

export const ProductLineTag = ({ productLine }: Props) => {
  return (
    <>
      {productLine.daas_delivery_ui_enabled && (
        <DataCellContent>
          <FormattedMessage id="corporates.product-line.wolt-drive" />
        </DataCellContent>
      )}
      {(productLine.waw_enabled || productLine.subscription_benefit_enabled) && (
        <DataCellContent>
          <FormattedMessage id="corporates.product-line.wolt-at-work" />
        </DataCellContent>
      )}
    </>
  );
};
