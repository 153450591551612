import { Route, Switch } from 'react-router-dom';

import { UsersView } from './UsersView';

const RootRoutes = () => (
  <Switch>
    <Route exact path="/:corporateId/users" component={UsersView} />
    <Route exact path="/:corporateId/users/:action" component={UsersView} />
    <Route exact path="/:corporateId/users/:action/:userId" component={UsersView} />
  </Switch>
);

export default RootRoutes;
