import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { useField } from 'formik';

import { useI18n } from 'i18n';
import { FormikSwitch } from 'components';

const StyledFormikSwitch = styled(FormikSwitch)`
  color: ${color.textSubdued};
  margin-bottom: 12px;

  & label {
    font-size: 12px;
  }
`;

export const HasTransactionLimitField = () => {
  const { getLocalizedMessage } = useI18n();
  const [{ value }, , { setValue }] = useField<boolean>('has_transaction_limits');

  return (
    <StyledFormikSwitch
      name="has_transaction_limits"
      label={getLocalizedMessage('corporate-form.set-transaction-limit')}
      switchProps={{
        onChange: () => setValue(!value),
      }}
    />
  );
};
