import { Select } from '@creditornot/cb-select';
import { useField } from 'formik';
import { ChangeEventHandler, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Input } from '@creditornot/cb-input';
import { IntlShape, useIntl } from 'react-intl';

import {
  createValidator,
  isRequired,
  isNumber,
  isInteger,
  min,
  getFormikFieldError,
} from 'validation';
import { FormikFieldContainer } from 'components/FormikFieldContainer';

const validate = createValidator([isRequired, isNumber, isInteger, min(1)]);

interface Props {
  alwaysShowError?: boolean;
  disabled?: boolean;
}

const StyledInput = styled(Input)`
  margin-top: 0.5rem;
`;

const options = ['', 2, 5, 7, 8, 10, 14, 15, 21, 30, 31, 35, 40, 45, 60, 90, 'other'] as const;

const getSelectLabel = (
  option: (typeof options)[number],
  formatMessage: IntlShape['formatMessage'],
): string => {
  switch (option) {
    case '':
      return formatMessage({ id: 'common.select' });
    case 'other':
      return formatMessage({ id: 'corporate-form.payment_due_in-other' });
    default:
      return formatMessage({ id: 'common.number_of_days' }, { number: option });
  }
};

export const PaymentDueInField = ({ alwaysShowError, disabled }: Props) => {
  const [{ value }, meta, { setValue }] = useField<string | null>({
    name: 'payment_term_delay_days',
    validate,
  });
  const [localValue, setLocalValue] = useState<string | null>(
    options.find((option) => option?.toString() === value)?.toString() ?? 'other',
  );
  const { formatMessage } = useIntl();

  const errorMessage = useMemo(() => {
    const fieldError = getFormikFieldError(meta, alwaysShowError);
    return fieldError && formatMessage({ id: fieldError.messageKey }, fieldError.values);
  }, [formatMessage, meta, alwaysShowError]);

  const handleLocalValueChange = useCallback(
    (newLocalValue: string | null) => {
      setLocalValue(newLocalValue);
      if (newLocalValue && newLocalValue !== 'other') {
        setValue(newLocalValue, true);
      } else if (newLocalValue === '') {
        setValue(null, true);
      }
    },
    [setValue],
  );

  const handleOtherValueChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setValue(event.target.value, true);
    },
    [setValue],
  );

  return (
    <>
      <FormikFieldContainer errorMessage={errorMessage}>
        <Select
          inputId="payment_term_delay_days"
          value={localValue}
          onChange={handleLocalValueChange}
          disabled={disabled}
          invalid={!!errorMessage && localValue !== 'other'}
          options={options.map((days) => ({
            value: days?.toString() ?? null,
            label: getSelectLabel(days, formatMessage),
          }))}
        />
        {localValue === 'other' && (
          <StyledInput
            data-test-id="payment-due-in-other"
            invalid={!!errorMessage}
            value={value ?? ''}
            onChange={handleOtherValueChange}
            type="text"
            disabled={disabled}
          />
        )}
      </FormikFieldContainer>
    </>
  );
};
