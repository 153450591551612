import styled from 'styled-components';
import { FieldArray, useFormikContext } from 'formik';
import { Plus, Trashbin } from '@creditornot/cb-icons';
import { TextButton } from '@creditornot/cb-button';
import { InputContainer } from '@creditornot/cb-form';

import { FormikInputField } from 'components';
import { createValidator, isRequired } from 'validation';

import { CreateArgreementPartyFormValues } from '../types';
import CountrySelectField from './CountrySelectField';

const BankAccountSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 532px;

  & + & {
    margin-top: 24px;
  }
`;

const BankAccountSectionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BankAccountSectionTitle = styled.span`
  font-size: 14px;
  margin-bottom: 10px;
`;

const RemoveBankAccountButton = styled(TextButton)`
  font-size: 14px;
  font-weight: 600;
  margin-inline-start: 5px;
  margin-bottom: 10px;
`;

const AddBankAccountButton = styled(TextButton)`
  font-weight: 600;
  font-size: 14px;
`;

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: 24px;
  }
`;

const validate = createValidator([isRequired]);

const LocalBankAccountsField = () => {
  const { values, validateForm } = useFormikContext<CreateArgreementPartyFormValues>();

  return (
    <FieldArray name="bank_accounts">
      {(arrayHelpers) => {
        return (
          <>
            {values?.bank_accounts?.map((_account, index) => (
              <BankAccountSection key={index}>
                <BankAccountSectionTitleContainer>
                  <BankAccountSectionTitle>{`Bank account #${index + 1}`}</BankAccountSectionTitle>

                  <RemoveBankAccountButton
                    icon={<Trashbin height="16px" />}
                    onClick={(e) => {
                      e.preventDefault();

                      arrayHelpers.remove(index);
                      setTimeout(() => {
                        validateForm();
                      }, 100);
                      /* There's a bug in Formik where using remove arrayHelper can cause the an empty array to be
                        to the error object under the key of the field array: https://github.com/jaredpalmer/formik/issues/784 */
                    }}
                  >
                    Remove
                  </RemoveBankAccountButton>
                </BankAccountSectionTitleContainer>
                <StyledInputContainer label="Account BIC">
                  <FormikInputField
                    name={`bank_accounts.${index}.account_bic`}
                    validate={validate}
                    required={true}
                  />
                </StyledInputContainer>
                <StyledInputContainer label="Account IBAN">
                  <FormikInputField
                    name={`bank_accounts.${index}.account_iban`}
                    required={true}
                    validate={validate}
                  />
                </StyledInputContainer>

                <StyledInputContainer label="Country code">
                  <CountrySelectField
                    name={`bank_accounts.${index}.country_code`}
                    validate={validate}
                  />
                </StyledInputContainer>
              </BankAccountSection>
            ))}
            <AddBankAccountButton
              icon={<Plus height="16px" />}
              variant="blue"
              onClick={(e) => {
                e.preventDefault();
                arrayHelpers.push({ account_iban: '', country_code: '', account_bic: '' });
              }}
              style={{
                marginTop: values?.bank_accounts?.length ? '16px' : '0px',
              }}
            >
              {values?.bank_accounts.length ? 'Add another bank account' : 'Add a bank account'}
            </AddBankAccountButton>
          </>
        );
      }}
    </FieldArray>
  );
};

export default LocalBankAccountsField;
