import { FormattedDate, useIntl } from 'react-intl';
import { TextButton } from '@creditornot/cb-button';
import { useState } from 'react';
import { Alert } from '@creditornot/cb-alert';
import styled from 'styled-components';

import { FormSection, FormViewText, FormRowV2 as Row } from 'components';
import { APIFailure, processErrorBlob } from 'utils/processError';

type Props = {
  date: Date;
  agreedBy: string;
  onDownloadClick: () => Promise<void>;
};

const StyledAlert = styled(Alert)`
  margin-top: 16px;
`;

export const ServiceAgreement = ({ date, agreedBy, onDownloadClick }: Props) => {
  const { formatMessage } = useIntl();
  const [downloadError, setDownloadError] = useState<undefined | APIFailure>();

  const handleDownloadClick = async () => {
    try {
      await onDownloadClick();
    } catch (e) {
      setDownloadError(await processErrorBlob(e));
    }
  };

  return (
    <FormSection
      title={formatMessage({ id: 'views.settings.service-agreement' })}
      topRightItem={
        <TextButton size="small" onClick={handleDownloadClick}>
          {formatMessage({ id: 'views.settings.service-agreement-download' })}
        </TextButton>
      }
    >
      {!!downloadError && (
        <StyledAlert
          variant="error"
          size="medium"
          title={downloadError.message}
          onCloseButtonClick={() => setDownloadError(undefined)}
        >
          {downloadError.data}
        </StyledAlert>
      )}
      <Row
        title={formatMessage({ id: 'views.settings.agreed-date' })}
        content={
          <FormViewText>
            <FormattedDate value={date} />
          </FormViewText>
        }
      />
      <Row
        title={formatMessage({ id: 'views.settings.agreed-by' })}
        content={<FormViewText>{agreedBy}</FormViewText>}
      />
    </FormSection>
  );
};
