import { useField } from 'formik';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { RadioInputCardGroup } from 'components';

const StyledRadioInputCardGroup = styled(RadioInputCardGroup)`
  & > label:nth-child(2) {
    margin-inline-end: 0;
  }
`;

export const InvoicingOptionFieldV2 = () => {
  const { formatMessage } = useIntl();
  const [{ name, value }, , { setValue }] = useField<string>('organise_invoice_by_groups');

  return (
    <StyledRadioInputCardGroup
      name={name}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      direction="row"
      stretched
      data-test-id="organise_invoice_by_groups"
      options={[
        {
          label: formatMessage({ id: 'corporate-form.normal-invoice-option' }),
          value: 'false',
        },
        {
          label: formatMessage({ id: 'corporate-form.organized-by-groups-invoice-option' }),
          value: 'true',
        },
      ]}
    />
  );
};
