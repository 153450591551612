import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useLocalStorage } from 'react-use';

import { useCorporate } from 'modules/corporates';

export const useSubscriptionAdBanner = () => {
  const { data: corporate } = useCorporate();
  const [isSubscriptionBannerSeen, setValue] = useLocalStorage(
    'wolt-subscription-ad-banner-seen',
    false,
  );
  const history = useHistory();

  const isSubscriptionBannerEnabled = useMemo(() => {
    if (!corporate) {
      return false;
    }

    return corporate?.products.subscription_benefit_enabled;
  }, [corporate]);

  const dismissSubscriptionAdBanner = useCallback(() => {
    setValue(true);
  }, [setValue]);

  const handleSubscriptionAdBannerLearnMoreClick = useCallback(() => {
    if (!corporate) return;

    history.push(`/${corporate.id}/subscriptions`);
  }, [corporate, history]);

  return {
    isSubscriptionBannerEnabled,
    isSubscriptionBannerSeen: Boolean(isSubscriptionBannerSeen),
    dismissSubscriptionAdBanner,
    handleSubscriptionAdBannerLearnMoreClick,
  };
};
