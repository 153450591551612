import { color } from '@creditornot/cb-ingredients/design-tokens';
import { TextButtonLink } from '@creditornot/cb-button';
import { useMemo } from 'react';
import styled from 'styled-components';
import { WindowNew } from '@creditornot/cb-icons';
import { CorporateBranding } from '@creditornot/daas-core-api-client';

import PhoneWrapper from 'assets/images/phone-wrapper.png';
import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';

type PreviewProps = {
  corporateBranding: CorporateBranding | null;
  className?: string;
  pickupLocationName?: string;
  rightHeaderContent?: React.ReactNode;
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-bottom: 8px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px;
  border: 1px solid ${color.border};
  border-radius: 8px;
`;

const IframeContainer = styled.div<{ $width: number; $height: number }>`
  position: relative;
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  padding: 12px 16px; /* match the phone bezel */
`;

const PhoneOverlay = styled.img.attrs({ src: PhoneWrapper })`
  position: absolute;
  top: 0;
  ${cssDirectionalValue({ ltr: 'left: 0;', rtl: 'right: 0;' })}
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
`;

const IFrame = styled.iframe<{ $width: number; $height: number }>`
  border: 0;
  width: ${({ $width }) => `calc((${$width}px - 32px) / 0.8)`};
  height: ${({ $height }) => `calc((${$height}px - 24px) / 0.8)`};
  transform: scale(0.8);
  transform-origin: ${cssDirectionalValue({ ltr: 'top left', rtl: 'top right' })};
`;

export const Preview = ({
  className,
  corporateBranding,
  pickupLocationName,
  rightHeaderContent,
}: PreviewProps) => {
  const previewUrl = useMemo(() => {
    const params = {
      fromLocationName: pickupLocationName ?? '',
      ...(corporateBranding
        ? {
            themeMode: corporateBranding.theme,
            brandColor: corporateBranding.brand_color,
            titleColor: corporateBranding.title_color ?? '',
            backgroundColor: corporateBranding.background_color,
            stepperBackgroundColor: corporateBranding.stepper_background_color ?? '',
            logoSquareUrl: corporateBranding.logo_square_url,
            logoFooterUrl: corporateBranding.logo_footer_url ?? '',
            contactDetailsModalImageUrl: corporateBranding.contact_details_modal_image_url ?? '',
          }
        : {}),
    };
    const cleanedParams = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value !== undefined && value !== ''),
    );

    const searchParams = new URLSearchParams(cleanedParams);
    return `https://daas-track.development.dev.woltapi.com/en/preview?${searchParams.toString()}`;
  }, [corporateBranding, pickupLocationName]);
  return (
    <div className={className}>
      <Header>
        <TextButtonLink href={previewUrl} target="_blank" icon={<WindowNew />} iconPosition="right">
          Open in desktop view
        </TextButtonLink>
        {rightHeaderContent}
      </Header>
      <Container>
        <IframeContainer $width={300} $height={609}>
          <PhoneOverlay />
          <IFrame src={previewUrl} $width={300} $height={609} />
        </IframeContainer>
      </Container>
    </div>
  );
};
