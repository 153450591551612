import { isNotEmptyOrZero } from 'utils';

import { CorporateV2 } from '../types';

export const formatDiscountsFormValuesBeforeSending = ({
  invoice_discounts,
}: Partial<CorporateV2>): Partial<CorporateV2> => {
  return {
    invoice_discounts: {
      subscription_fee:
        invoice_discounts?.subscription_fee &&
        isNotEmptyOrZero(invoice_discounts.subscription_fee.percentage)
          ? { percentage: invoice_discounts.subscription_fee.percentage }
          : null,
    },
  };
};
