import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Button } from '@creditornot/cb-button';
import { GroupAdd } from '@creditornot/cb-icons';

import { breakpoints, useMedia } from 'modules/media';
import { Header, Divider, SearchInput, View } from 'components';
import { LocalizedMessage, useI18n } from 'i18n';
import { User } from 'modules/users/types';
import { useFetchGroup, useFetchGroupUsers } from 'modules/groups';
import { NeverNullStringParam, useQueryParams } from 'modules/react-query-params';
import { Group } from 'modules/groups/types';

import { AddUsersToGroupModal } from './AddUsersToGroupModal';
import { AutomaticallyAddUserModal } from './AutomaticallyAddUserModal';
import { ChangeGroupPolicyModal } from './ChangeGroupPolicyModal';
import { DeleteGroupModal } from './DeleteGroupModal';
import EditGroupInfoModal from './EditGroupInfoModal';
import { EditGroupStatusModal } from './EditGroupStatusModal';
import { GroupDetails } from './GroupDetails';
import GroupUsersDataList from './GroupUsersDataList';
import GroupUsersDataTable from './GroupUsersDataTable';
import { RemoveUsersFromGroupModal } from './RemoveUsersFromGroupModal';
import { RemovePolicyFromGroupModal } from './RemovePolicyFromGroupModal';
import { useOpenRemovePolicyFromGroupToast } from './useOpenRemovePolicyFromGroupToast';

const StyledGroupHeader = styled(Header)`
  min-height: 84px;

  @media (max-width: ${breakpoints.medium}px) {
    min-height: unset;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  max-width: 240px;

  @media (max-width: ${breakpoints.small}px) {
    max-width: unset;
    min-width: 0;
  }
`;

const Right = styled.div`
  width: 65%;

  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
    margin-bottom: 24px;
    border-top: 1px solid ${color.border};
  }
`;

const Left = styled.div`
  width: 35%;
  border-inline-end: 1px solid ${color.border};
  height: 100%;
  flex: 1;

  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
  }
`;

const Root = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;

  @media (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
  }
`;

const Heading = styled.h6`
  ${typographyCss.Heading6()}
`;

const GroupView = ({
  history,
  match,
}: RouteComponentProps<{
  corporateId: string;
  groupId: string;
  action?:
    | 'edit'
    | 'delete'
    | 'add-users'
    | 'remove-user'
    | 'remove-policy'
    | 'change-policy'
    | 'auto-add'
    | 'edit-status';
}>) => {
  const openRemovePolicyFromGroupToast = useOpenRemovePolicyFromGroupToast();
  const smallView = useMedia('small');
  const { getLocalizedMessage } = useI18n();
  const [searchUsersValue, setSearchUsersValue] = useState('');
  const [{ user: userQueryParam }, setUserQueryParams] = useQueryParams({
    user: NeverNullStringParam,
  });

  const {
    data: group,
    isLoading: isFetchingGroup,
    error: fetchGroupError,
  } = useFetchGroup(match.params.groupId);

  const {
    data: groupUsers,
    isLoading: isFetchGroupUsers,
    error: fetchGroupUsersError,
  } = useFetchGroupUsers(match.params.groupId);

  const closeModal = useCallback(
    () => history.push(`/${match.params.corporateId}/groups/info/${match.params.groupId}`),
    [history, match.params.corporateId, match.params.groupId],
  );

  const goToGroupsView = useCallback(
    () => history.push(`/${match.params.corporateId}/groups`),
    [history, match.params.corporateId],
  );

  const openAddUsersToGroupModal = useCallback(
    () => history.push(`${history.location.pathname}/add-users`),
    [history],
  );

  const openRemoveUserModal = useCallback(
    ({ id }: User) => {
      setUserQueryParams({ user: id });
      history.push(`${history.location.pathname}/remove-user${history.location.search}`);
    },
    [history, setUserQueryParams],
  );

  const handleAutoAddClick = useCallback(() => {
    history.push(`${history.location.pathname}/auto-add`);
  }, [history]);

  const handleEditGroupStatusClick = useCallback(() => {
    history.push(`${history.location.pathname}/edit-status`);
  }, [history]);

  const handleRemovePolicyClick = useCallback(() => {
    history.push(`${history.location.pathname}/remove-policy`);
  }, [history]);

  const handleChangeGroupPolicyClick = useCallback(() => {
    history.push(`${history.location.pathname}/change-policy`);
  }, [history]);

  const handleBackClick = useCallback(() => {
    history.push(`/${match.params.corporateId}/groups`);
  }, [history, match.params.corporateId]);

  const handleEditClick = useCallback(() => {
    history.push(`${history.location.pathname}/edit`);
  }, [history]);

  const handleDeleteClick = useCallback(() => {
    history.push(`${history.location.pathname}/delete`);
  }, [history]);

  const handleInviteUserClick = useCallback(
    () => history.push(`/${match.params.corporateId}/users/invite`),
    [history, match.params.corporateId],
  );

  const handleRemovePolicyFromGroupSuccess = useCallback(
    (removedPolicy: Group['policies'][0]) => {
      history.push(`/${match.params.corporateId}/groups/info/${match.params.groupId}`);
      openRemovePolicyFromGroupToast({
        policyName: removedPolicy.name,
        numberOfPolicies: 1,
      });
    },
    [history, match.params.corporateId, match.params.groupId, openRemovePolicyFromGroupToast],
  );

  return (
    <>
      <View
        onBackClick={handleBackClick}
        onDeleteClick={handleDeleteClick}
        onEditClick={handleEditClick}
        title={group?.name}
        subtitle={group?.description}
      >
        <Root>
          <Left>
            <GroupDetails
              onAcivateAutoAddClick={handleAutoAddClick}
              onDeacivateAutoAddClick={handleAutoAddClick}
              onAcivateGroupClick={handleEditGroupStatusClick}
              onDeacivateGroupClick={handleEditGroupStatusClick}
              onChangeGroupPolicyClick={handleChangeGroupPolicyClick}
              onRemoveGroupPolicyClick={handleRemovePolicyClick}
              isLoading={isFetchingGroup}
              error={fetchGroupError}
              group={group}
            />
          </Left>

          <Right>
            <StyledGroupHeader
              leftItems={[
                <Heading key="groupsUsersHeader">
                  <LocalizedMessage messageKey="groups.users" />
                </Heading>,
              ]}
              rightItems={[
                <StyledSearchInput
                  key="groupUsersSearch"
                  type="text"
                  onSearch={setSearchUsersValue}
                  placeholder={getLocalizedMessage('common.search')}
                />,
                <Button
                  variant="lightBlue"
                  size="small"
                  key="groupAddUsersButton"
                  icon={<GroupAdd />}
                  onClick={openAddUsersToGroupModal}
                >
                  <LocalizedMessage messageKey="groups.add-users" />
                </Button>,
              ]}
            />

            <Divider />

            {smallView ? (
              <GroupUsersDataList
                data={groupUsers}
                isLoading={isFetchGroupUsers}
                error={fetchGroupUsersError}
                dataItemProps={{
                  onRemoveUserClick: openRemoveUserModal,
                  isDefaultGroup: group?.is_default,
                }}
                searchTerm={searchUsersValue}
                groupName={group?.name}
                onAddUsersClick={openAddUsersToGroupModal}
              />
            ) : (
              <GroupUsersDataTable
                data={groupUsers?.filter(({ full_name }) =>
                  full_name.toLocaleLowerCase().includes(searchUsersValue.toLocaleLowerCase()),
                )}
                searchTerm={searchUsersValue}
                isLoading={isFetchGroupUsers}
                dataCellProps={{
                  onRemoveUserClick: openRemoveUserModal,
                  isDefaultGroup: group?.is_default,
                }}
                error={fetchGroupUsersError}
                groupName={group?.name}
                onAddUsersClick={openAddUsersToGroupModal}
              />
            )}
          </Right>
        </Root>
      </View>

      <EditGroupInfoModal
        group={group}
        corporateId={match.params.corporateId}
        onClose={closeModal}
        onSuccess={closeModal}
        show={match.params.action === 'edit'}
      />

      <EditGroupStatusModal
        group={group}
        onClose={closeModal}
        onSuccess={closeModal}
        show={match.params.action === 'edit-status'}
      />

      <AutomaticallyAddUserModal
        group={group}
        onClose={closeModal}
        onSuccess={closeModal}
        show={match.params.action === 'auto-add'}
      />

      <AddUsersToGroupModal
        group={group}
        corporateId={match.params.corporateId}
        onClose={closeModal}
        onSuccess={closeModal}
        onInviteUserClick={handleInviteUserClick}
        show={match.params.action === 'add-users'}
      />

      <DeleteGroupModal
        group={group}
        onClose={closeModal}
        goToGroupsView={goToGroupsView}
        show={match.params.action === 'delete'}
      />

      <RemoveUsersFromGroupModal
        userId={userQueryParam}
        group={group}
        corporateId={match.params.corporateId}
        onClose={closeModal}
        onSuccess={closeModal}
        show={match.params.action === 'remove-user'}
      />

      <ChangeGroupPolicyModal
        group={group}
        onClose={closeModal}
        onSuccess={closeModal}
        show={match.params.action === 'change-policy'}
      />

      <RemovePolicyFromGroupModal
        group={group}
        onClose={closeModal}
        onSuccess={handleRemovePolicyFromGroupSuccess}
        show={match.params.action === 'remove-policy'}
      />
    </>
  );
};

export default GroupView;
