import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ApiErrorNotification, ModalV2, Spinner } from 'components';
import { useFetchUser } from 'modules/users';
import { fetchGroups } from 'modules/groups';
import { useFetch } from 'modules/react-query';
import { breakpoints } from 'modules/media';
import { Corporate } from 'modules/corporates/types';

import { EditUserForm } from './EditUserForm';

interface Props {
  show: boolean;
  corporate: Corporate;
  userId?: string;
  onEditUserSuccess: () => void;
  onClose: () => void;
}

const SpinnerContainer = styled.div`
  padding: 24px;
`;

const ApiErrorContainer = styled.div`
  margin: 24px;

  @media (max-width: ${breakpoints.medium}px) {
    margin: 24px 16px;
  }
`;

export const EditUserModal: React.FC<Props> = ({
  show,
  corporate,
  onClose,
  userId,
  onEditUserSuccess,
}) => {
  const {
    data: userData,
    isFetching: isFetchingUser,
    error: userError,
  } = useFetchUser(userId || null);

  const { data: groups } = useFetch({
    queryKey: ['EditUserModal', 'Groups', corporate?.id],
    queryFn: () => fetchGroups(corporate.id),
    enabled: !!userId,
  });

  const { formatMessage } = useIntl();

  return (
    <ModalV2
      onDismiss={onClose}
      sizePreset="sideEndSheet:large"
      open={show}
      title={formatMessage({ id: 'users.edit-user.title' })}
      contentStyle={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isFetchingUser && (
        <SpinnerContainer>
          <Spinner size="small" />
        </SpinnerContainer>
      )}

      {!!userError && (
        <ApiErrorContainer>
          <ApiErrorNotification error={userError} />
        </ApiErrorContainer>
      )}

      {userData && (
        <EditUserForm
          corporate={corporate}
          user={userData}
          groups={groups}
          onClose={onClose}
          onEditUserSuccess={onEditUserSuccess}
        />
      )}
    </ModalV2>
  );
};
