import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { generateCodeVerifier } from '@creditornot/wolt-auth';
import { useAuthActions } from '@creditornot/wolt-auth/react';

import { getLocalStorageItem, removeLocalStorageItem } from 'modules/hooks';

import { authService } from './authenticator';

export const LoginCallback = () => {
  const { login } = useAuthActions();
  const history = useHistory();

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code');
    const codeVerifier = localStorage.getItem('codeVerifier');
    const userWasRedirectedFrom: string | null = getLocalStorageItem('userWasRedirectedFrom');

    const handleLogin = async () => {
      if (code && codeVerifier) {
        await login({ code, codeVerifier, grantType: 'authorization_code' });
        localStorage.removeItem('codeVerifier');

        history.replace(userWasRedirectedFrom ?? '/');
        removeLocalStorageItem('userWasRedirectedFrom');
      } else if (code && !codeVerifier) {
        // case where magic link was opened in another browser
        const codeVerifier = await generateCodeVerifier();
        localStorage.setItem('codeVerifier', codeVerifier);
        location.href = await authService.getAuthorizeUri(codeVerifier);
      }
    };

    handleLogin();
  }, [history, login]);

  return null;
};
