import { FormikInputField } from 'components';
import { createValidator, isRequired, isNumber, min, max } from 'validation';

const validate = createValidator([isRequired, isNumber, min(0), max(100)]);

interface Props {
  alwaysShowError?: boolean;
}

export const InvoicingFeeField = ({ alwaysShowError }: Props) => {
  return (
    <FormikInputField
      name="invoice_fee_percentage"
      type="text"
      required={true}
      validate={validate}
      alwaysShowError={alwaysShowError}
    />
  );
};
