import { GetCorporateResponse, SmsNotificationEventEnum } from '@creditornot/wd-api-client';
import {
  B2bCustomerConfigCreateRequest,
  B2bCustomerConfigUpdateRequest,
  DeliveryPricingType,
  Discount,
} from '@creditornot/daas-core-api-client';

import { getB2bConfigCurrencyCode } from 'modules/b2b-configs';
import { convertFromAmountToCents } from 'utils';
import {
  B2bConfigFormValues,
  B2bPricing,
} from 'views/settings/CompanySettings/B2bConfigSettings/types';

type B2bConfigMap = {
  formData: B2bConfigFormValues;
  currency: string;
  country: string;
  corporateId: string;
  selfServiceCorporate: GetCorporateResponse;
};

type DiscountConvertProps = {
  currency: string;
  discountType: 'flat' | 'percentage' | 'noDiscount';
  flatDiscount: number;
  percentageDiscount: number;
  isCustomPricing: boolean;
};

const convertPricingType = (type: B2bPricing['type']): DeliveryPricingType => {
  if (type === 'flatFee') return 'flat_fee';
  if (type === 'distanceBased') return 'distance_based';
  if (type === 'custom') return 'custom';
  throw new Error('Type for pricing is not supported');
};

const convertB2bConfigDiscount = ({
  currency,
  discountType,
  flatDiscount,
  percentageDiscount,
  isCustomPricing,
}: DiscountConvertProps): Discount[] => {
  if (isCustomPricing) return [];
  if (discountType === 'flat') {
    return [{ type: 'flat', value: convertFromAmountToCents(flatDiscount, currency) }];
  }
  if (discountType === 'percentage') {
    return [{ type: 'total_price_percentage', value: percentageDiscount }];
  }
  return [];
};
export const formatB2bConfigFormValuesForUpdate = ({
  formData,
  currency,
  country,
  selfServiceCorporate,
}: Omit<B2bConfigMap, 'corporateId'>): B2bCustomerConfigUpdateRequest => {
  const currencyCode = getB2bConfigCurrencyCode(currency);

  if (!currencyCode) {
    throw new Error(`Invalid currency code: ${currencyCode}`);
  }

  return {
    country_code: country,
    delivery_distance_meters:
      formData.isCustomDeliveryDistance === 'isCustom' ? formData.deliveryDistance ?? 0 : 0,
    dynamic_pickup_venue_id: formData.venueId,
    wolt_merchant_id: formData.merchantId,
    sms_notification_copy:
      selfServiceCorporate.smsNotifications?.find(
        (sms) => sms.event === SmsNotificationEventEnum.Received,
      )?.messageText ?? '',
    pricing: {
      type: convertPricingType(formData.pricing.type),
      discounts: convertB2bConfigDiscount({
        currency: currency,
        flatDiscount: formData.pricing.discounts?.flatValue ?? 0,
        percentageDiscount: formData.pricing.discounts?.percentageValue ?? 0,
        discountType: formData.pricing.discounts?.type ?? 'noDiscount',
        isCustomPricing: formData.pricing.type === 'custom',
      }),
      custom_pricing:
        formData.pricing.type === 'custom' && formData.pricing.customPricing?.distanceRanges
          ? {
              base_price: {
                amount: convertFromAmountToCents(
                  formData.pricing.customPricing.basePrice,
                  currencyCode,
                ),
                currency: currencyCode,
              },
              distance_ranges: formData.pricing.customPricing?.distanceRanges.map(
                (distanceRange) => {
                  return {
                    distance_min: distanceRange.distanceMin,
                    distance_max: distanceRange.distanceMax,
                    fee: convertFromAmountToCents(distanceRange.fee, currencyCode),
                  };
                },
              ),
            }
          : undefined,
    },
    is_cash_enabled: formData.isCashEnabled,
    is_intercom_enabled: formData.isIntercomEnabled,
  };
};

export const formatB2bConfigFormValuesForCreate = ({
  formData,
  currency,
  selfServiceCorporate,
  corporateId,
  country,
}: B2bConfigMap): B2bCustomerConfigCreateRequest => {
  const currencyCode = getB2bConfigCurrencyCode(currency);

  if (!currencyCode) {
    throw new Error(
      `Currency: ${currency} did not match any currecy code supported by B2bConfigurations`,
    );
  }

  return {
    country_code: country,
    corporate_id: corporateId,
    delivery_distance_meters:
      formData.isCustomDeliveryDistance === 'isCustom' ? formData.deliveryDistance ?? 0 : 0,
    dynamic_pickup_venue_id: formData.venueId,
    wolt_merchant_id: formData.merchantId,
    sms_notification_copy:
      selfServiceCorporate.smsNotifications?.find(
        (sms) => sms.event === SmsNotificationEventEnum.Received,
      )?.messageText ?? '',
    pricing: {
      type: convertPricingType(formData.pricing.type),
      discounts: convertB2bConfigDiscount({
        currency: currencyCode,
        flatDiscount: formData.pricing.discounts?.flatValue ?? 0,
        percentageDiscount: formData.pricing.discounts?.percentageValue ?? 0,
        discountType: formData.pricing.discounts?.type ?? 'noDiscount',
        isCustomPricing: formData.pricing.type === 'custom',
      }),
      custom_pricing:
        formData.pricing.type === 'custom' && formData.pricing.customPricing?.distanceRanges
          ? {
              base_price: {
                amount: convertFromAmountToCents(
                  formData.pricing.customPricing.basePrice,
                  currencyCode,
                ),
                currency: currencyCode,
              },
              distance_ranges: formData.pricing.customPricing?.distanceRanges.map(
                (distanceRange) => {
                  return {
                    distance_min: distanceRange.distanceMin,
                    distance_max: distanceRange.distanceMax,
                    fee: convertFromAmountToCents(distanceRange.fee, currencyCode),
                  };
                },
              ),
            }
          : undefined,
    },
    is_cash_enabled: formData.isCashEnabled,
    is_intercom_enabled: formData.isIntercomEnabled,
  };
};
