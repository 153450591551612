import { useField } from 'formik';
import { useIntl } from 'react-intl';

import { RadioInputCardGroup } from 'components';

export const OrderOptionField = () => {
  const { formatMessage } = useIntl();
  const [organiseOrderReportByGroupsInput, , organiseOrderReportByGroupsHelpers] =
    useField<boolean>('organise_order_report_by_groups');

  return (
    <RadioInputCardGroup
      name={organiseOrderReportByGroupsInput.name}
      value={String(organiseOrderReportByGroupsInput.value)}
      onChange={(e) => organiseOrderReportByGroupsHelpers.setValue(e.target.value === 'true')}
      direction="row"
      stretched
      options={[
        {
          label: formatMessage({ id: 'corporate-form.normal-order-option' }),
          value: 'false',
        },
        {
          label: formatMessage({ id: 'corporate-form.organized-by-groups-order-option' }),
          value: 'true',
        },
      ]}
    />
  );
};
