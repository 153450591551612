import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { MultiSelect } from '@creditornot/cb-select';
import { intersection } from 'ramda';

import { useI18n } from 'i18n';
import { PayoutStatus } from 'modules/merchantPayouts/types';

import { useReportsQueryParams } from '../hooks';

type Props = {
  className?: string;
};

const StyledMultiSelect = styled(MultiSelect)`
  width: 360px;
`;

const useReportsFilterOption = () => {
  const { getLocalizedMessage } = useI18n();
  const defaultOptions = useMemo(
    (): { label: string; options: { label: string; value: PayoutStatus }[] }[] => [
      {
        label: getLocalizedMessage('users.filter.status-title'),
        options: [
          {
            label: getLocalizedMessage('reports.status.success'),
            value: 'sent_to_bank',
          },
          {
            label: getLocalizedMessage('reports.status.pending'),
            value: 'in_progress',
          },
          {
            label: getLocalizedMessage('reports.status.rejected'),
            value: 'kyc_failed',
          },
          {
            label: getLocalizedMessage('reports.status.not-applicable'),
            value: 'not_applicable',
          },
        ],
      },
    ],
    [getLocalizedMessage],
  );

  return defaultOptions;
};

const statuses: PayoutStatus[] = ['in_progress', 'kyc_failed', 'not_applicable', 'sent_to_bank'];

export const ReportsFilter = ({ className }: Props) => {
  const { getLocalizedMessage } = useI18n();
  const [{ status }, setQueryParams] = useReportsQueryParams();

  const handleFilterChange = useCallback(
    (values: string[]) => {
      setQueryParams({
        status: intersection(values, statuses),
      });
    },
    [setQueryParams],
  );
  const options = useReportsFilterOption();
  return (
    <StyledMultiSelect
      className={className}
      placeholder={getLocalizedMessage('reports.filter')}
      options={options}
      onChange={handleFilterChange}
      value={status}
    />
  );
};
