import { Button } from '@creditornot/cb-button';
import { useIntl } from 'react-intl';

import { RenderMode } from '../types';

export const EditButton = ({
  renderMode,
  onEditClick,
}: {
  renderMode: RenderMode;
  onEditClick?: () => void;
}) => {
  const { formatMessage } = useIntl();

  if (renderMode === 'view') {
    return (
      <Button type="button" variant="lightBlue" size="small" onClick={onEditClick}>
        {formatMessage({ id: 'common.edit' })}
      </Button>
    );
  }

  return null;
};
