import React, { useEffect } from 'react';
import {
  IntercomProps,
  IntercomProvider as ReactIntercomProvider,
  useIntercom,
} from 'react-use-intercom';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { useFetch } from 'modules/react-query';
import { env } from 'config';

import { fetchWoltUserAccount } from './api';

const isBrowser = () => typeof window === 'object';

const IntercomBootComponent = () => {
  const { user } = useAuthState();
  const intercom = useIntercom();
  const { data: woltUser } = useFetch({
    queryKey: ['ReactIntercomProvider', user?.id],
    queryFn: () => fetchWoltUserAccount(),
    enabled: !!user,
  });

  useEffect(() => {
    if (woltUser && isBrowser()) {
      const {
        user: { email, _id, name, intercom_user_hash },
      } = woltUser;
      const settings: IntercomProps = {
        email: email,
        userId: _id.$oid,
        name: `${name.first_name} ${name.last_name}`,
        userHash: intercom_user_hash,
        createdAt: Date.now().toString(),
        hideDefaultLauncher: true,
      };

      intercom.boot(settings);

      return () => {
        intercom.shutdown();
      };
    }
  }, [intercom, woltUser]);

  return null;
};

export const IntercomProvider = ({ children }: any) => {
  return (
    <ReactIntercomProvider appId={env.INTERCOM_APP_ID}>
      <IntercomBootComponent />
      {children}
    </ReactIntercomProvider>
  );
};
