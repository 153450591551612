import { MoreHorizontal } from '@creditornot/cb-icons';
import styled from 'styled-components';
import { MenuActionItem, MenuItemGroup } from '@creditornot/cb-menu';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';

import { DataCellContent, DataList, EmptyState } from 'components';
import { Policy } from 'modules/policies/types';
import { LocalizedMessage, useI18n } from 'i18n';

interface Props {
  data: Policy[] | null;
  loading: boolean;
  error: any;
  dataItemProps: {
    onOpenEditPolicyModal: (id: string) => void;
    onOpenDeletePolicyModal: (id: string) => void;
  };
  onCreatePolicyClick: () => void;
}

const DataItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const PoliciesDataList: React.FC<Props> = ({
  data,
  loading,
  error,
  onCreatePolicyClick,
  dataItemProps,
}) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <DataList
      data={data}
      getDataItemId={(dataItem) => dataItem.id}
      loading={loading}
      noData={
        <EmptyState
          subtitle={getLocalizedMessage('policies.no-policies-explanation')}
          icon="policy"
          actionButtonText={getLocalizedMessage('policies.create-your-first-policy')}
          onActionButtonClick={onCreatePolicyClick}
        >
          {getLocalizedMessage('policies.no-policies')}
        </EmptyState>
      }
      error={error}
      renderDataItemProps={dataItemProps}
      renderDataItem={(policy, { onOpenDeletePolicyModal, onOpenEditPolicyModal }) => {
        return (
          <DataItem>
            <DataCellContent subContent={policy.description}>{policy.name}</DataCellContent>

            <Popover
              renderArrow={false}
              placement="bottom-end"
              content={
                <MenuItemGroup>
                  <MenuActionItem variant="black" onClick={() => onOpenEditPolicyModal(policy.id)}>
                    <LocalizedMessage messageKey={'common.edit'} />
                  </MenuActionItem>
                  <MenuActionItem
                    variant="black"
                    onClick={() => onOpenDeletePolicyModal(policy.id)}
                  >
                    <LocalizedMessage messageKey={'common.delete'} />
                  </MenuActionItem>
                </MenuItemGroup>
              }
            >
              <MoreHorizontal height="16px" width="16px" />
            </Popover>
          </DataItem>
        );
      }}
    />
  );
};

export default PoliciesDataList;
