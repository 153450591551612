import { Field, FieldProps, useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { CoordinatesPickerWithAlert } from 'components/CoordinatesPicker';
import { Coordinates } from 'types';
import { useElementVisibility } from 'modules/policies';
import { env } from 'config';

import { PolicyFormValues } from './types';

interface Props {
  index: number;
}

const PolicyDeliveryLocationPicker = ({ index }: Props) => {
  const { formatMessage } = useIntl();
  const {
    initialValues: { delivery_locations: initialDeliveryLocations },
    values: { delivery_locations },
  } = useFormikContext<PolicyFormValues>();
  const numberOfMaps = useMemo(() => delivery_locations.length, [delivery_locations]);

  const [showMap, containerRef] = useElementVisibility({
    // The map component itself is 290px in height, so this offset will render around 10-11 maps at once (but not exceed the 16 map limit)
    offset: 3000, // px
    // Browsers can only handle max 16 WebGL contexts, so the amount of maps rendered on one page is limited (each delivery location can have max 2 maps)
    // Note: the most recently added map in the delivery location list should always be visible
    alwaysShow: numberOfMaps === index + 1 || numberOfMaps < 8,
  });

  return (
    <div ref={containerRef}>
      <Field name={`delivery_locations.${index}.coordinates`}>
        {({ field, form }: FieldProps<Coordinates>) => (
          <CoordinatesPickerWithAlert
            showPlaceholderContainer={!showMap}
            // We show map radius if the takeaway map is hidden
            radius={
              env.ENABLE_POLICY_TAKEAWAY_RESTRICTION === 'false'
                ? delivery_locations[index].radius
                : undefined
            }
            location={field.value}
            alertMessage={formatMessage({ id: 'map.correct-location-alert' })}
            onLocationChange={(e) => form.setFieldValue(field.name, e.lngLat)}
            // Only show an initial alert for newly added delivery locations (Note: new locations have no name by default)
            showInitialAlert={!initialDeliveryLocations[index]?.name}
          />
        )}
      </Field>
    </div>
  );
};

export default PolicyDeliveryLocationPicker;
