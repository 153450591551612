import { CreateCorporateData } from 'modules/corporates/api';
import { convertFromAmountToCents } from 'utils';

import { CreateCorporateFormValues } from '../types';

export const formatCreateCorporateFormValuesBeforeSending = (
  values: Omit<
    CreateCorporateFormValues,
    'merchant_id' | 'maximum_distance_in_meters' | 'delivery_distance' | 'venue_id'
  >,
  currency: string,
): Omit<CreateCorporateData, 'currency'> => {
  const has_transaction_limits = values.transaction_limits
    ? Object.values(values.transaction_limits).every((number) =>
        Number.isInteger(Number.parseInt(number)),
      )
    : false;

  const transactionLimits =
    has_transaction_limits && values.transaction_limits
      ? {
          daily_transactions_amount: convertFromAmountToCents(
            values.transaction_limits.daily_transactions_amount,
            currency,
          ),
          single_transaction_amount: convertFromAmountToCents(
            values.transaction_limits.single_transaction_amount,
            currency,
          ),
        }
      : null;

  const hasCreditLimit = values.credit_limit_amount
    ? Number.isInteger(Number.parseInt(values.credit_limit_amount))
    : false;

  const isDaas = values.products.includes('DELIVERY_UI');

  return {
    address: values.address.trim(),
    billing_twice_a_month: values.billing_twice_a_month === 'true',
    business_id: values.business_id.trim(),
    city: values.city.trim(),
    contact_email: values.contact_email.trim(),
    contact_name: values.contact_name.trim(),
    contact_phone: values.contact_phone.trim(),
    country: values.country,
    credit_limit_amount: convertFromAmountToCents(values.credit_limit_amount, currency) || 0,
    customer_reference: values.customer_reference.trim(),
    electronic_invoicing_details:
      values.is_electronic_invoicing_enabled && values.electronic_invoicing_details
        ? values.electronic_invoicing_details
        : null,
    has_credit_limit: hasCreditLimit,
    has_transaction_limits: isDaas ? false : has_transaction_limits,
    industry: values.industry || null,
    invoice_email_cc_recipients: values.invoice_email_cc_recipients,
    invoice_email: values.invoice_email.trim(),
    invoice_fee_percentage: isDaas
      ? 0
      : Number.parseInt(values.invoice_fee_percentage.replace(',', '.')),
    invoice_overdue_interest: Number.parseInt(values.invoice_overdue_interest.replace(',', '.')),
    name: values.name.trim(),
    organise_invoice_by_groups: values.organise_invoice_by_groups === 'true',
    organise_order_report_by_groups: values.organise_order_report_by_groups === 'true',
    payment_term_delay_days: Number.parseInt(values.payment_term_delay_days),
    post_code: values.post_code.trim(),
    preferred_invoice_locale: values.preferred_invoice_locale.trim(),
    products: values.products,
    single_receipt_email: values.single_receipt_email.trim(),
    transaction_limits: isDaas ? null : transactionLimits,
    vat_id: values.vat_id.trim(),
  };
};
