import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { FieldArray, useFormikContext } from 'formik';
import { Plus, Trashbin } from '@creditornot/cb-icons';
import { TextButton } from '@creditornot/cb-button';
import { InputContainer } from '@creditornot/cb-form';

import { FormikInputField } from 'components';
import { createValidator, isRequired } from 'validation';

import { CountryConfigFormValues } from '../types';

const BankAccountSection = styled.div`
  border-top: 1px solid ${color.border};
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding-top: 18px;
`;

const BankAccountSectionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BankAccountSectionTitle = styled.span`
  font-size: 14px;
  margin-bottom: 10px;
`;

const RemoveBankAccountButton = styled(TextButton)`
  font-size: 14px;
  font-weight: 600;
  margin-inline-start: 5px;
  margin-bottom: 10px;
`;

const AddBankAccountButton = styled(TextButton)`
  font-weight: 600;
  margin-top: 18px;
  font-size: 14px;
`;

const validate = createValidator([isRequired]);

const LocalBankAccountsField = () => {
  const { values, validateForm } = useFormikContext<CountryConfigFormValues>();

  return (
    <FieldArray name="local_bank_account_numbers">
      {(arrayHelpers) => {
        return (
          <>
            {values.local_bank_account_numbers.map((_account, index) => (
              <BankAccountSection key={index}>
                <BankAccountSectionTitleContainer>
                  <BankAccountSectionTitle>
                    {`Local format for Wolt bank account #${index + 1}`}
                  </BankAccountSectionTitle>

                  <RemoveBankAccountButton
                    icon={<Trashbin height="16px" />}
                    onClick={(e) => {
                      e.preventDefault();

                      arrayHelpers.remove(index);
                      setTimeout(() => {
                        validateForm();
                      }, 100);
                      /* There's a bug in Formik where using remove arrayHelper can cause the an empty array to be
                        to the error object under the key of the field array: https://github.com/jaredpalmer/formik/issues/784 */
                    }}
                  >
                    Remove
                  </RemoveBankAccountButton>
                </BankAccountSectionTitleContainer>
                <InputContainer label="Account number" style={{ marginBottom: 8 }}>
                  <FormikInputField
                    name={`local_bank_account_numbers.${index}.bank_account_number`}
                    validate={validate}
                    required={true}
                  />
                </InputContainer>
                <InputContainer label="Display name">
                  <FormikInputField
                    name={`local_bank_account_numbers.${index}.display_name`}
                    required={true}
                    validate={validate}
                  />
                </InputContainer>
              </BankAccountSection>
            ))}
            <AddBankAccountButton
              wrap
              icon={<Plus height="16px" />}
              variant="blue"
              onClick={(e) => {
                e.preventDefault();
                arrayHelpers.push({ bank_account_number: '', display_name: '' });
              }}
            >
              {values.local_bank_account_numbers.length
                ? 'Add another local format for Wolt bank account'
                : 'Add a local format for Wolt bank account'}
            </AddBankAccountButton>
          </>
        );
      }}
    </FieldArray>
  );
};

export default LocalBankAccountsField;
