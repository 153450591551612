import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCorporate } from 'modules/corporates';

import { resendUserInvite } from './api';

export const useResendInvite = () => {
  const queryClient = useQueryClient();
  const { data: corporate } = useCorporate();

  return useCallback(
    async (inviteIds: string[]) => {
      if (!corporate?.id) {
        return Promise.resolve();
      }
      await resendUserInvite(corporate.id, inviteIds, 2);
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 500);
    },
    [queryClient, corporate?.id],
  );
};
