import { useCallback } from 'react';

import { UseQueryOptions, useQuery } from 'modules/react-query';

import { fetchWoltUsers } from './api';
import { WoltUserNonNullable } from './types';

export const useFetchWoltUsers = <T = WoltUserNonNullable[]>(
  search: string | null,
  config?: UseQueryOptions<WoltUserNonNullable[], unknown, T>,
) => {
  const queryFn = useCallback(() => {
    if (search === null) {
      return Promise.reject();
    }
    return fetchWoltUsers(search);
  }, [search]);

  return useQuery({
    queryKey: ['wolt-users', search],
    queryFn,
    enabled: search !== null,
    ...config,
  });
};
