import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQueryClient } from 'modules/react-query';

import { EditUserData, editUser } from './api';

interface Props {
  userId: string;
  data: EditUserData;
  invalidateDelay?: number;
}

export const useEditUser = () => {
  const { data: corporate } = useCorporate();
  const queryClient = useQueryClient();
  return useCallback(
    async ({ userId, data, invalidateDelay }: Props) => {
      if (!corporate?.id) {
        return Promise.reject('Corporate is missing');
      }

      await editUser(corporate.id, userId, data);

      setTimeout(() => {
        queryClient.invalidateQueries();
      }, invalidateDelay ?? 0);
    },
    [queryClient, corporate],
  );
};
