import { color } from '@creditornot/cb-ingredients/design-tokens';
import { Button } from '@creditornot/cb-button';
import { FormHeader, FormRow } from '@creditornot/cb-form';
import { typographyCss } from '@creditornot/cb-ingredients';
import styled from 'styled-components';
import { FormattedDate } from 'react-intl';

import { LocalizedMessage, useI18n } from 'i18n';
import { CorporateStatusTag } from 'modules/corporates/components';
import { Corporate } from 'modules/corporates/types';
import { useEditCorporateRestricted } from 'modules/corporates/useEditCorporate';
import { FormikEditableFormRow, FormikInputField } from 'components';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';

type CorporateAccountProps = {
  corporate: Corporate;
  onReactivateClick: () => void;
  onDisableClick: () => void;
  onDeleteClick: () => void;
  onActivateNewClick: () => void;
  className?: string;
};

const StyledButton = styled(Button)`
  display: inline-block;
  margin-top: 16px;
`;

const StyledBody3 = styled.span`
  ${typographyCss.Body3()}
  display: block;
`;

const FormikRowContainer = styled.div`
  border-top: 1px solid ${color.border};
`;

export const CorporateAccount = ({
  corporate,
  onReactivateClick,
  onDeleteClick,
  onDisableClick,
  onActivateNewClick,
  className,
}: CorporateAccountProps) => {
  const { getLocalizedMessage } = useI18n();
  const { isWoltEmployee } = useIsUserWoltEmployee();
  const editCorporateRestricted = useEditCorporateRestricted();

  return (
    <div className={className}>
      <FormHeader horizontalPadding>
        <LocalizedMessage messageKey="corporate-form.company-account-title" />
      </FormHeader>
      <FormRow horizontalPadding label={getLocalizedMessage('corporates-form.company-status')}>
        <CorporateStatusTag status={corporate.status} />
      </FormRow>
      <FormRow horizontalPadding label={getLocalizedMessage('corporates-form.status-updated-at')}>
        <FormattedDate
          value={new Date(corporate.status_modified_at)}
          year="numeric"
          month="numeric"
          day="numeric"
          hour="numeric"
          minute="numeric"
        />
      </FormRow>
      {isWoltEmployee && (
        <FormikRowContainer>
          <FormikEditableFormRow
            horizontalPadding
            label={getLocalizedMessage('corporates-form.account_manager')}
            labelHtmlFor="accountManager"
            initialValues={{
              accountManager: corporate.account_manager,
            }}
            onSubmit={(data) =>
              editCorporateRestricted({
                account_manager: data.accountManager ? data.accountManager : undefined,
              })
            }
          >
            {({ isEditing }) =>
              isEditing ? (
                <FormikInputField name="accountManager" inputProps={{ id: 'accountManager' }} />
              ) : (
                <div>{corporate.account_manager}</div>
              )
            }
          </FormikEditableFormRow>
        </FormikRowContainer>
      )}
      {corporate.status !== 'active' && corporate.status !== 'disabled' && (
        <FormRow
          horizontalPadding
          label={getLocalizedMessage('corporates-form.activate-new-account')}
        >
          <StyledBody3>
            <LocalizedMessage messageKey="corporate-form.activate-new-account-text" />
          </StyledBody3>

          <StyledButton onClick={onActivateNewClick} size="small" variant="lightBlue">
            <LocalizedMessage messageKey="corporate-form.activate-new-account-button" />
          </StyledButton>
        </FormRow>
      )}
      {corporate.status === 'disabled' && (
        <FormRow horizontalPadding label={getLocalizedMessage('corporates-form.activate-account')}>
          <StyledBody3>
            <LocalizedMessage messageKey="corporate-form.activate-account-text" />
          </StyledBody3>

          <StyledButton onClick={onReactivateClick} size="small" variant="lightBlue">
            <LocalizedMessage messageKey="corporate-form.activate-account-button" />
          </StyledButton>
        </FormRow>
      )}
      {corporate.status === 'active' && (
        <FormRow horizontalPadding label={getLocalizedMessage('corporates-form.disable-account')}>
          <StyledBody3>
            <LocalizedMessage messageKey="corporate-form.disable-account-text" />
          </StyledBody3>

          <StyledButton onClick={onDisableClick} size="small" variant="lightRed">
            <LocalizedMessage messageKey="corporate-form.disable-account-button" />
          </StyledButton>
        </FormRow>
      )}
      <FormRow horizontalPadding label={getLocalizedMessage('corporates-form.delete-account')}>
        <StyledBody3>
          <LocalizedMessage messageKey="corporate-form.delete-account-text" />
        </StyledBody3>

        <StyledButton size="small" variant="lightRed" onClick={onDeleteClick}>
          <LocalizedMessage messageKey="corporate-form.delete-account-button" />
        </StyledButton>
      </FormRow>
    </div>
  );
};
