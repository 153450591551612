import { setMetadata, telemetryLogin, telemetryLogout } from '@creditornot/browser-telemetry';
import type { User } from '@creditornot/wolt-auth';
import { registerAuthInterceptor } from '@creditornot/wolt-auth/axios';
import { useAuthState } from '@creditornot/wolt-auth/react';
import React, { useEffect, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import {
  daasCoreApiAgent,
  corporateServiceApiAgent,
  daasSelfServiceApiAgent,
  restaurantApiAgent,
  wolfApiAgent,
  merchantPayoutApiAgent,
} from 'apiAgents';
import { ErrorBoundary } from 'components';
import { IntlProvider } from 'i18n';
import { AppModalsProvider } from 'modules/app-modals';
import { authenticator } from 'modules/auth/authenticator';
import { ConfigsProvider } from 'modules/configs';
import { CookieConsentsProvider } from 'modules/cookie-consents';
import { GoogleAnalyticsProvider } from 'modules/google-analytics';
import { IntercomProvider } from 'modules/intercom';
import { QueryClientProvider } from 'modules/react-query';
import { ThemeProvider } from 'modules/waw-theme';
import Routes from 'routes';
import { TelemetryBoot } from 'telemetry/components/TelemetryBoot';
import AppCrashView from 'views/app-crash';

import { bugsnagClient } from './bugsnagClient';

const AppErrorBoundary =
  bugsnagClient.getPlugin('react')?.createErrorBoundary(React) ?? ErrorBoundary;

const isWoltEmployee = (user: User) => user.roles.includes('employee');

registerAuthInterceptor(
  authenticator,
  daasSelfServiceApiAgent,
  restaurantApiAgent,
  merchantPayoutApiAgent,
  wolfApiAgent,
  daasCoreApiAgent,
  corporateServiceApiAgent,
);

const Root = () => {
  const { user } = useAuthState();

  const isWoltEmployeeRef = useRef(user ? isWoltEmployee(user) : false);

  useEffect(() => {
    isWoltEmployeeRef.current = user ? isWoltEmployee(user) : false;
  });

  useEffect(() => {
    if (user?.id) {
      setMetadata({ is_wolt_employee: isWoltEmployeeRef.current });
      telemetryLogin(user.id);
    }

    return () => {
      setMetadata({ is_wolt_employee: false });
      telemetryLogout();
    };
  }, [user?.id]);

  return (
    <IntlProvider>
      <AppErrorBoundary FallbackComponent={AppCrashView}>
        {/* TODO: Implement language select. Use as the main locale for now */}
        <ThemeProvider>
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter5Adapter}>
              <QueryClientProvider>
                <ConfigsProvider>
                  <CookieConsentsProvider>
                    <IntercomProvider>
                      <GoogleAnalyticsProvider>
                        <AppModalsProvider>
                          <TelemetryBoot />
                          <Routes />
                        </AppModalsProvider>
                      </GoogleAnalyticsProvider>
                    </IntercomProvider>
                  </CookieConsentsProvider>
                </ConfigsProvider>
              </QueryClientProvider>
            </QueryParamProvider>
          </BrowserRouter>
        </ThemeProvider>
      </AppErrorBoundary>
    </IntlProvider>
  );
};

export default Root;
