import { MenuActionItem } from '@creditornot/cb-menu';
import { MultiSelect, SelectOptionComponentProps } from '@creditornot/cb-select';
import { useField } from 'formik';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { fetchGroups } from 'modules/groups';
import { useFetch } from 'modules/react-query';

type PolicyGroupsFieldProps = {
  corporateId: string;
};

const ItemContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomOption: React.FC<
  SelectOptionComponentProps<{
    label: string;
    value: string;
    disabled: boolean;
  }>
> = ({ option, ...props }) => {
  const { formatMessage } = useIntl();
  return (
    <MenuActionItem variant="black" {...props} disabled={option.disabled}>
      <ItemContent>
        {option.label}
        {option.disabled &&
          ` - ${formatMessage({ id: 'policies.create-policy.add-policy-group-error' })}`}
      </ItemContent>
    </MenuActionItem>
  );
};

export const PolicyGroupsField = ({ corporateId }: PolicyGroupsFieldProps) => {
  const { formatMessage } = useIntl();
  const [{ value }, , { setValue }] = useField<string[]>('group_ids');
  const { data: groups } = useFetch({
    queryKey: ['PolicyGroupsField', 'Groups', corporateId],
    queryFn: () => fetchGroups(corporateId),
  });
  return (
    <MultiSelect
      options={(groups ?? []).map((group) => ({
        label: group.name,
        value: group.id,
        disabled: !!group.policies.length,
      }))}
      maxMenuHeight={280}
      optionComponent={CustomOption}
      placeholder={formatMessage({ id: 'common.select-placeholder' })}
      value={value}
      onChange={(values) => setValue(values)}
    />
  );
};
