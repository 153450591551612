import { useEditCorporate } from 'modules/corporates';
import { EditCorporateFormValues } from 'views/settings/CompanySettings/types';

import { formatFormValuesBeforeSending } from './formatFormValues';
import { Corporate } from './types';

type FormValues = Pick<
  EditCorporateFormValues,
  | 'invoice_email'
  | 'invoice_email_cc_recipients'
  | 'single_receipt_email'
  | 'preferred_invoice_locale'
  | 'customer_reference'
  | 'billing_twice_a_month'
  | 'payment_term_delay_days'
  | 'invoice_overdue_interest'
  | 'organise_invoice_by_groups'
  | 'organise_order_report_by_groups'
  | 'is_electronic_invoicing_enabled'
  | 'electronic_invoicing_details'
  | 'credit_limit_amount'
  | 'transaction_limits'
  | 'invoice_fee_percentage'
  | 'has_transaction_limits'
>;

export const useEditInvoicesAndReceipts = (corporate: Corporate) => {
  const editCorporate = useEditCorporate();

  return (initialValues: FormValues, newValues: FormValues) =>
    editCorporate(formatFormValuesBeforeSending(initialValues, newValues, corporate.currency));
};
