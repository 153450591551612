import {
  B2bCustomerConfigCreateRequest,
  DeliveryPricingType,
  DistanceBasedDeliveryPricing,
  Pricing,
} from '@creditornot/daas-core-api-client';

import { convertToDiscountArray } from 'modules/corporates/utils';
import { convertFromAmountToCents } from 'utils';
import { getB2bConfigCurrencyCode } from 'modules/b2b-configs';

import { CreateCorporateFormValues } from '../types';

type FormatB2bValues = {
  corporateId: string;
  values: CreateCorporateFormValues;
  smsNotificationCopy: string;
  currency: string;
};

export const mapFormValuesToCreateDriveConfigRequest = ({
  corporateId,
  values,
  smsNotificationCopy,
  currency,
}: FormatB2bValues): B2bCustomerConfigCreateRequest => {
  const currencyCode = getB2bConfigCurrencyCode(currency);

  if (!currencyCode) {
    throw new Error(
      `Currency: ${currency} did not match any currecy code supported by B2bConfigurations`,
    );
  }

  const customPricing: DistanceBasedDeliveryPricing | undefined =
    values.delivery_price_type === DeliveryPricingType.Custom
      ? {
          base_price: {
            amount: convertFromAmountToCents(values.base_price, currencyCode),
            currency: currencyCode,
          },
          distance_ranges: values.price_increments.map((increment) => {
            return {
              distance_min: +increment.distance_min,
              distance_max: +increment.distance_max,
              fee: convertFromAmountToCents(increment.fee, currencyCode),
            };
          }),
        }
      : undefined;
  const pricing: Pricing = {
    type: values.delivery_price_type,
    discounts:
      values.delivery_price_type !== DeliveryPricingType.Custom
        ? convertToDiscountArray({
            currency: currencyCode,
            discountType: values.discount_type,
            flatDiscount: values.flat_discount,
            percentageDiscount: values.percentage_discount,
          })
        : [],
    custom_pricing: customPricing,
  };

  return {
    corporate_id: corporateId,
    country_code: values.country,
    delivery_distance_meters:
      values.delivery_distance === 'custom' ? +values.maximum_distance_in_meters : 0,
    dynamic_pickup_venue_id: values.venue_id,
    pricing: pricing,
    sms_notification_copy: smsNotificationCopy,
    wolt_merchant_id: values.merchant_id,
  };
};
