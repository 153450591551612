import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { MessageBubbleTextFilled, WindowNew, ArrowRight } from '@creditornot/cb-icons';
import { ModalHeader } from '@creditornot/cbt-modal';
import { DeliveryOrderResponse, Parcel } from '@creditornot/wd-api-client';
import { rem } from 'polished';
import { TextButton } from '@creditornot/cb-button';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Fragment } from 'react';

import { Anchor, InformationDisplay, ModalV2, ResponsiveContainer } from 'components';
import { env } from 'config';

import { DeliveryStatusTag } from '..';
import { getPickupCommentWithoutSenderInfo } from '../utils';
import { AddressLine } from '../AddressLine';
import { DeliveryDetail, useDeliveryDetails } from '../useDeliveryDetails';

type EditUserModalProps = {
  onClose: () => void;
  show: boolean;
  order: DeliveryOrderResponse;
  showOpsToolsLink: boolean;
};

const Container = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const Section = styled(ResponsiveContainer)`
  display: block;
  padding-top: ${rem('32px')};
  padding-bottom: ${rem('24px')};
  box-sizing: border-box;

  & + & {
    border-top: 1px solid ${color.border};
  }
`;

const Title = styled.div`
  ${typographyCss.Title1()}
  color: ${color.text};
  margin-bottom: ${rem('24px')};
  display: flex;
  justify-content: space-between;
`;

const StyledAnchor = styled(Anchor)`
  margin-inline-start: ${rem('16px')};
`;

const StyledArrowRight = styled(ArrowRight)`
  margin-inline-end: ${rem('6px')};
  height: ${rem('16px')};
`;

const StyledModalHeader = styled(ModalHeader)`
  padding-top: ${rem('22px')};
  padding-bottom: ${rem('22px')};
  min-height: auto;
  display: flex;
  align-items: flex-start;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem('4px')};
  margin-top: ${rem('12px')};
`;

const StyledMessageBubble = styled(MessageBubbleTextFilled)`
  width: ${rem('15px')};
  margin-inline-end: ${rem('7px')};
  color: ${color.textSubdued};
`;

const Comment = styled.div`
  margin-top: ${rem('4px')};
  display: flex;
  align-items: center;
`;

const InformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ItineraryTitle = styled.span`
  ${typographyCss.Body3()}
  font-weight: 700;
  margin-bottom: ${rem('4px')};
`;

const SectionDivider = styled.div<{ marginTopBottom: number }>`
  margin: ${(props) => `${rem(props.marginTopBottom)} 0`};
  border-top: 1px solid ${color.border};
`;

const PriceSection = styled(ResponsiveContainer)`
  display: block;
  padding-top: ${rem('32px')};
  padding-bottom: ${rem('24px')};
  box-sizing: border-box;
  border-top: 1px solid ${color.border};
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'title value'
    'extra-info extra-info';
  justify-content: space-between;
  padding: ${rem('14px')} 0;

  & + & {
    border-top: 1px solid ${color.border};
  }
`;

const DeliveryDetailsContent = styled.div`
  & > *:first-of-type {
    padding-top: 0;
  }
`;

const RowTitle = styled.div`
  grid-area: title;
  ${typographyCss.Body3()}
  color: ${color.textSubdued};
`;

const RowValue = styled.div`
  grid-area: value;
  text-align: end;
  ${typographyCss.Body3()}
  color: ${color.text};
`;

const RowExtraInfo = styled.div`
  grid-area: extra-info;
  margin-top: ${rem('4px')};
  ${typographyCss.Body3()}
  color: ${color.text};
`;

const TotalPriceInformationContainer = styled(InformationContainer)`
  font-weight: 700;
`;

const detailRowOrder: DeliveryDetail['type'][] = [
  'parcel',
  'large-heavy-parcel',
  'cash-to-collect',
  'age-check',
  'no-contact',
  'pin-code-check',
];

export const DeliveryDetailsModal = ({
  onClose,
  order,
  show,
  showOpsToolsLink,
}: EditUserModalProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const deliveryDetails = useDeliveryDetails(order);

  const renderDetailTitle = (detailType: DeliveryDetail['type']) => {
    switch (detailType) {
      case 'large-heavy-parcel':
        return formatMessage({ id: 'deliveries.order-side-panel.large-heavy-delivery' });
      case 'parcel':
        return formatMessage(
          { id: 'deliveries.parcel-with-count' },
          {
            itemCount: order.parcels?.length ?? 0,
          },
        );
      case 'no-contact':
        return formatMessage({ id: 'deliveries.delivery-details.no-contact' });
      case 'pin-code-check':
        return formatMessage({ id: 'deliveries.delivery-details.pin-code-check' });
      case 'age-check':
        return formatMessage({ id: 'deliveries.delivery-details.age-check' });
      case 'cash-to-collect':
        return formatMessage({ id: 'deliveries.delivery-details.cash-to-collect' });
      default:
        return '';
    }
  };

  return (
    <ModalV2
      onDismiss={onClose}
      open={show}
      sizePreset="sideEndSheet:large"
      title={' '} // ModalV2 requires a title prop, otherwise below component will not render
      HeaderComponent={() => (
        <StyledModalHeader onCloseClick={onClose}>
          <div data-test-id="delivery-modal-header">
            <div>{order.pickup.sender.name}</div>
            <div>
              <StyledArrowRight />
              {order.dropoff.recipient.name}
            </div>
            <HeaderContent>
              <DeliveryStatusTag status={order.status} />

              <StyledAnchor href={order.tracking.url} target="_blank" rel="noopener noreferrer">
                <TextButton icon={<WindowNew />} iconPosition="right" variant="blue">
                  <FormattedMessage id="deliveries.order-side-panel.tracking" />{' '}
                </TextButton>
              </StyledAnchor>
              {showOpsToolsLink && (
                <StyledAnchor
                  href={order && `${env.OPS_TOOLS_URI}/purchases/${order.woltOrderReferenceId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TextButton icon={<WindowNew />} iconPosition="right" variant="blue">
                    <FormattedMessage id="orders.details.open-in-opstools" />
                  </TextButton>
                </StyledAnchor>
              )}
            </HeaderContent>
          </div>
        </StyledModalHeader>
      )}
    >
      <Container data-test-id="delivery-modal-container">
        <Section>
          <Title>
            <FormattedMessage id="deliveries.order-side-panel.delivery-information" />
          </Title>
          <InformationDisplay
            label={formatMessage({ id: 'deliveries.order-side-panel.order-number' })}
          >
            #{order.orderNumber}
          </InformationDisplay>

          {order.merchantOrderReferenceId && (
            <InformationDisplay
              label={formatMessage({ id: 'deliveries.order-side-panel.order-reference' })}
            >
              {order.merchantOrderReferenceId}
            </InformationDisplay>
          )}

          {order.createdAt && (
            <InformationDisplay
              label={formatMessage({ id: 'deliveries.order-side-panel.order-placed' })}
            >
              <FormattedDate
                value={new Date(order.createdAt)}
                dateStyle="short"
                timeStyle="short"
              />
            </InformationDisplay>
          )}

          {/* TODO: Show "Order created by" when the backend is ready. */}

          {/* <InformationDisplay
              label={formatMessage({id:'deliveries.order-side-panel.order-created-by'})}
            >
              Firstname Lastname <br />
              firstname.lastname@example
            </InformationDisplay> */}
        </Section>

        <Section>
          <Title>
            <FormattedMessage id="deliveries.delivery-details-column" />
          </Title>
          <DeliveryDetailsContent>
            {deliveryDetails
              .filter((detail) => detail.enabled)
              .sort((a, b) => detailRowOrder.indexOf(a.type) - detailRowOrder.indexOf(b.type))
              .map((detail) => {
                if (detail.type === 'parcel') {
                  return (
                    <>
                      {order.parcels?.map((parcel, index) => (
                        <Row key={index}>
                          <RowTitle>
                            {parcel.identifier
                              ? `1 x ${parcel.identifier}`
                              : formatMessage({ id: 'deliveries.order-side-panel.no-identifier' })}
                          </RowTitle>
                          <RowValue>
                            {formatNumber(parcel.price?.amounts ?? 0, {
                              currency: parcel.price?.currency ?? order.price.currency,
                              style: 'currency',
                              currencyDisplay: 'code',
                            })}
                            {parcel.dimensions.weightGram > 0
                              ? ` / ${formatNumber(parcel.dimensions.weightGram / 1000, {
                                  style: 'unit',
                                  unit: 'kilogram',
                                })}`
                              : ''}
                          </RowValue>
                          <RowExtraInfo>
                            {parcel.description ? (
                              <div>
                                {formatMessage({
                                  id: 'deliveries.order-side-panel.parcel-description',
                                })}
                                : {parcel.description}
                              </div>
                            ) : null}
                            {hasDimensions(parcel) ? (
                              <div>
                                {formatMessage(
                                  {
                                    id: 'deliveries.order-side-panel.parcel-dimensions',
                                  },
                                  {
                                    width: parcel.dimensions.widthCm,
                                    height: parcel.dimensions.heightCm,
                                    length: parcel.dimensions.depthCm,
                                  },
                                )}
                              </div>
                            ) : null}
                          </RowExtraInfo>
                        </Row>
                      ))}
                    </>
                  );
                }
                if (detail.type === 'large-heavy-parcel') {
                  return (
                    <Row key={detail.type}>
                      <RowTitle>{renderDetailTitle(detail.type)}</RowTitle>
                      <RowValue>{detail.value}</RowValue>
                      {order.parcels?.some((parcel) => !!parcel.description) ? (
                        <RowExtraInfo>
                          <StyledMessageBubble />{' '}
                          {order.parcels.find((parcel) => !!parcel.description)?.description ?? ''}
                        </RowExtraInfo>
                      ) : null}
                    </Row>
                  );
                }
                if (detail.type === 'age-check') {
                  return (
                    <Fragment key={detail.type}>
                      <Row>
                        <RowTitle>
                          {formatMessage({ id: 'deliveries.delivery-details.restricted-items' })}
                        </RowTitle>
                        <RowValue>{detail.value}</RowValue>
                      </Row>
                      <Row>
                        <RowTitle>{renderDetailTitle(detail.type)}</RowTitle>
                        <RowValue>{formatMessage({ id: 'common.required' })}</RowValue>
                      </Row>
                    </Fragment>
                  );
                }
                return (
                  <Row key={detail.type}>
                    <RowTitle>{renderDetailTitle(detail.type)}</RowTitle>
                    <RowValue>
                      {['no-contact', 'pin-code-check'].includes(detail.type)
                        ? formatMessage({ id: 'common.required' })
                        : detail.value}
                    </RowValue>
                  </Row>
                );
              })}
          </DeliveryDetailsContent>
        </Section>

        <Section>
          <Title>
            <FormattedMessage id="deliveries.itinerary" />
          </Title>

          <InformationContainer>
            <ItineraryTitle>
              <FormattedMessage id="deliveries.order-side-panel.pickup" />
            </ItineraryTitle>
            {order.pickup.eta && (
              <ItineraryTitle>
                <FormattedDate value={new Date(order.pickup.eta)} hour="2-digit" minute="2-digit" />
              </ItineraryTitle>
            )}
          </InformationContainer>
          <InformationDisplay label={formatMessage({ id: 'deliveries.order-side-panel.sender' })}>
            {order.pickup.sender.name} ({order.pickup.sender.phoneNumber})
          </InformationDisplay>

          <InformationDisplay
            label={formatMessage({ id: 'deliveries.order-side-panel.pickup-location' })}
          >
            <AddressLine
              items={[
                order.pickup.location.address.street,
                order.pickup.location.address.postCode,
                order.pickup.location.address.city,
              ]}
            />

            {order.pickup.comment &&
              getPickupCommentWithoutSenderInfo(order.pickup.comment, order.pickup.sender) && (
                <Comment>
                  <StyledMessageBubble />
                  {getPickupCommentWithoutSenderInfo(order.pickup.comment, order.pickup.sender)}
                </Comment>
              )}

            <SectionDivider marginTopBottom={30} />

            <InformationContainer>
              <ItineraryTitle>
                <FormattedMessage id="deliveries.order-side-panel.order-dropoff" />
              </ItineraryTitle>
              {order.dropoff.eta && (
                <ItineraryTitle>
                  <FormattedDate
                    value={new Date(order.dropoff.eta)}
                    hour="2-digit"
                    minute="2-digit"
                  />
                </ItineraryTitle>
              )}
            </InformationContainer>
            <InformationDisplay
              label={formatMessage({ id: 'deliveries.order-side-panel.recipient' })}
            >
              {order.dropoff.recipient.name} ({order.dropoff.recipient.phoneNumber})
            </InformationDisplay>
          </InformationDisplay>
          <InformationDisplay
            label={formatMessage({ id: 'deliveries.order-side-panel.order-dropoff-location' })}
          >
            <AddressLine
              items={[
                order.dropoff.location?.address.street,
                order.dropoff.location?.address.postCode,
                order.dropoff.location?.address.city,
              ]}
            />

            {order.dropoff.comment && (
              <Comment>
                <StyledMessageBubble /> {order.dropoff.comment}
              </Comment>
            )}
          </InformationDisplay>
        </Section>

        <PriceSection>
          <InformationDisplay
            label={
              <Title>
                <FormattedMessage id="deliveries.order-side-panel.payment-information" />
              </Title>
            }
          >
            <InformationContainer>
              {/* @TODO: add distance when available in the backend */}
              <FormattedMessage id="deliveries.delivery-fee" />

              {order.price && (
                <div>
                  {formatNumber(order.price.amounts, {
                    style: 'currency',
                    currency: order.price.currency,
                    currencyDisplay: 'code',
                  })}
                </div>
              )}
            </InformationContainer>

            <SectionDivider marginTopBottom={14} />

            <TotalPriceInformationContainer>
              <FormattedMessage id="deliveries.order-side-panel.total-price-incl-taxes" />

              {order.price && (
                <div>
                  {formatNumber(order.price.amounts, {
                    style: 'currency',
                    currency: order.price.currency,
                    currencyDisplay: 'code',
                  })}
                </div>
              )}
            </TotalPriceInformationContainer>
          </InformationDisplay>
        </PriceSection>
      </Container>
    </ModalV2>
  );
};

function hasDimensions(parcel: Parcel): boolean {
  return !!parcel.dimensions.widthCm || !!parcel.dimensions.heightCm || !!parcel.dimensions.depthCm;
}
