import * as t from 'io-ts';

import { PersonName } from 'modules/common/types';

export type TransactionLimits = t.TypeOf<typeof TransactionLimits>;
export const TransactionLimits = t.type({
  daily_transactions_amount: t.number,
  single_transaction_amount: t.number,
});

export type ElectronicInvoicingDetails = t.TypeOf<typeof ElectronicInvoicingDetails>;
export const ElectronicInvoicingDetails = t.type({
  edi: t.string,
  edi_operator: t.string,
  vat_exemption_disclaimer: t.union([t.undefined, t.string]),
});

export type CorporateStatus = t.TypeOf<typeof CorporateStatus>;
export const CorporateStatus = t.keyof({
  submitted: null,
  invite_pending: null,
  details_pending: null,
  verification_pending: null,
  contract_pending: null,
  disabled: null,
  active: null,
  rejected: null,
});

export type CorporateProducts = t.TypeOf<typeof CorporateProducts>;
export const CorporateProducts = t.type({
  daas_delivery_ui_enabled: t.boolean,
  drive_api_enabled: t.boolean,
  waw_enabled: t.boolean,
  subscription_benefit_enabled: t.boolean,
});

export type Corporate = t.TypeOf<typeof Corporate>;
export const Corporate = t.intersection([
  t.type({
    address: t.string,
    billing_twice_a_month: t.boolean,
    city: t.string,
    contact_email: t.string,
    contact_name: t.string,
    contact_phone: t.string,
    country_config: t.type({
      id: t.string,
      country_code: t.string,
    }),
    country_config_id: t.string,
    country: t.string,
    created_at: t.string,
    currency: t.string,
    customer_reference: t.string,
    has_credit_limit: t.boolean,
    has_transaction_limits: t.boolean,
    id: t.string,
    include_payment_note_vat_summary: t.boolean,
    invoice_email_cc_recipients: t.array(t.string),
    invoice_fee_percentage: t.number,
    invoice_overdue_interest: t.number,
    is_wolt_company: t.boolean,
    modified_at: t.string,
    name: t.string,
    organise_invoice_by_groups: t.boolean,
    organise_order_report_by_groups: t.boolean,
    payment_term_delay_days: t.number,
    post_code: t.string,
    preferred_invoice_locale: t.string,
    products: CorporateProducts,
    status: CorporateStatus,
    status_modified_at: t.string,
  }),
  t.partial({
    account_manager: t.union([t.null, t.string]),
    business_id: t.union([t.string, t.null]),
    credit_limit_amount: t.union([t.null, t.number]),
    discount_percentage: t.union([t.null, t.number]),
    electronic_invoicing_details: t.union([t.null, ElectronicInvoicingDetails]),
    industry: t.union([t.null, t.string]),
    invoice_email: t.union([t.string, t.null]),
    single_receipt_email: t.union([t.null, t.string]),
    transaction_limits: t.union([t.null, TransactionLimits]),
    signup_flow_version: t.number,
    vat_id: t.union([t.string, t.null]),
  }),
]);

export type CorporateDiscount = t.TypeOf<typeof CorporateDiscount>;
export const CorporateDiscount = t.type({ percentage: t.number });

export type CorporateDiscounts = t.TypeOf<typeof CorporateDiscounts>;
export const CorporateDiscounts = t.type({
  subscription_fee: t.union([t.null, CorporateDiscount]),
});

/** As of now the new endpoint only includes discounts, later all corporate data will be migrated over */
export type CorporateV2 = t.TypeOf<typeof CorporateV2>;
export const CorporateV2 = t.type({
  invoice_discounts: t.union([t.null, CorporateDiscounts]),
});

export type ConsentType = t.TypeOf<typeof ConsentType>;
export const ConsentType = t.keyof({
  subscription_benefit_terms: null,
});

export type Consent = t.TypeOf<typeof Consent>;
export const Consent = t.intersection([
  t.type({
    id: t.string,
    type: ConsentType,
    value: t.boolean,
    timestamp: t.string,
  }),
  t.partial({
    text_content: t.string,
    user_id: t.string,
    user_name: PersonName,
  }),
]);

export type Consents = t.TypeOf<typeof Consents>;
export const Consents = t.type({
  consents: t.array(Consent),
});
