import { Country } from './types';

const countries: Country[] = [
  {
    name: 'Austria',
    id: 'at',
    code: 'AUT',
    phonePrefix: '+43',
    flag: '🇦🇹',
    requiresCookieConsent: true,
    language: 'de-at',
    currency: 'EUR',
    defaultTimezone: 'Europe/Vienna',
  },
  {
    name: 'Azerbaijan',
    id: 'az',
    code: 'AZE',
    phonePrefix: '+994',
    flag: '🇦🇿',
    requiresCookieConsent: false,
    language: 'az',
    currency: 'AZN',
    defaultTimezone: 'Asia/Baku',
  },
  {
    name: 'Croatia',
    id: 'hr',
    code: 'HRV',
    phonePrefix: '+385',
    flag: '🇭🇷',
    requiresCookieConsent: true,
    language: 'hr',
    currency: 'EUR',
    defaultTimezone: 'Europe/Zagreb',
  },
  {
    name: 'Cyprus',
    id: 'cy',
    code: 'CYP',
    phonePrefix: '+357',
    flag: '🇨🇾',
    requiresCookieConsent: true,
    language: 'el',
    currency: 'EUR',
    defaultTimezone: 'Asia/Nicosia',
    identifiers: {
      businessIdFormat: 'HE123456',
      vatIdFormat: 'CY12345678A',
    },
  },
  {
    name: 'Czechia',
    id: 'cz',
    code: 'CZE',
    phonePrefix: '+420',
    flag: '🇨🇿',
    requiresCookieConsent: true,
    language: 'cs',
    currency: 'CZK',
    defaultTimezone: 'Europe/Prague',
    identifiers: {
      businessIdFormat: '12345678',
      vatIdFormat: 'CZ12345678',
    },
  },
  {
    name: 'Denmark',
    id: 'dk',
    code: 'DNK',
    phonePrefix: '+45',
    flag: '🇩🇰',
    requiresCookieConsent: true,
    language: 'da',
    currency: 'DKK',
    defaultTimezone: 'Europe/Copenhagen',
    identifiers: {
      businessIdFormat: '12345678',
      vatIdFormat: 'DK12345678',
    },
  },
  {
    name: 'Estonia',
    id: 'ee',
    code: 'EST',
    phonePrefix: '+372',
    flag: '🇪🇪',
    requiresCookieConsent: true,
    language: 'et',
    currency: 'EUR',
    defaultTimezone: 'Europe/Tallinn',
    identifiers: {
      businessIdFormat: '12345678',
      vatIdFormat: 'EE123456789',
    },
  },
  {
    name: 'Finland',
    id: 'fi',
    code: 'FIN',
    phonePrefix: '+358',
    flag: '🇫🇮',
    requiresCookieConsent: true,
    language: 'fi',
    currency: 'EUR',
    defaultTimezone: 'Europe/Helsinki',
    identifiers: {
      businessIdFormat: '1234567-8',
      vatIdFormat: 'FI12345678',
    },
  },
  {
    name: 'Georgia',
    id: 'ge',
    code: 'GEO',
    phonePrefix: '+995',
    flag: '🇬🇪',
    requiresCookieConsent: false,
    language: 'ka',
    currency: 'GEL',
    defaultTimezone: 'Asia/Tbilisi',
  },
  {
    name: 'Germany',
    id: 'de',
    code: 'DEU',
    phonePrefix: '+49',
    flag: '🇩🇪',
    requiresCookieConsent: true,
    language: 'de',
    currency: 'EUR',
    defaultTimezone: 'Europe/Berlin',
    identifiers: {
      businessIdFormat: 'HRB 123456',
      vatIdFormat: 'DE123456789',
    },
  },
  {
    name: 'Greece',
    id: 'gr',
    code: 'GRC',
    phonePrefix: '+30',
    flag: '🇬🇷',
    requiresCookieConsent: true,
    language: 'el',
    currency: 'EUR',
    defaultTimezone: 'Europe/Athens',
    identifiers: {
      businessIdFormat: '123456789012',
      vatIdFormat: 'EL123456789',
    },
  },
  {
    name: 'Hungary',
    id: 'hu',
    code: 'HUN',
    phonePrefix: '+36',
    flag: '🇭🇺',
    requiresCookieConsent: true,
    language: 'hu',
    currency: 'HUF',
    defaultTimezone: 'Europe/Budapest',
    identifiers: {
      businessIdFormat: '12 34 567890',
      vatIdFormat: '12345678-9-01',
    },
  },
  {
    name: 'Iceland',
    id: 'is',
    code: 'ISL',
    phonePrefix: '+354',
    flag: '🇮🇸',
    requiresCookieConsent: true,
    language: 'is',
    currency: 'ISK',
    defaultTimezone: 'Atlantic/Reykjavik',
    identifiers: {
      businessIdFormat: '1234567890',
      vatIdFormat: '12345',
    },
  },
  {
    name: 'Israel',
    id: 'il',
    code: 'ISR',
    phonePrefix: '+972',
    flag: '🇮🇱',
    requiresCookieConsent: false,
    language: 'he',
    currency: 'ILS',
    defaultTimezone: 'Asia/Jerusalem',
    identifiers: {
      businessIdFormat: '123456789',
      vatIdFormat: '123456789',
    },
  },
  {
    name: 'Japan',
    id: 'jp',
    code: 'JPN',
    phonePrefix: '+81',
    flag: '🇯🇵',
    requiresCookieConsent: true,
    language: 'ja',
    currency: 'JPY',
    defaultTimezone: 'Asia/Tokyo',
  },
  {
    name: 'Kazakhstan',
    id: 'kz',
    code: 'KAZ',
    phonePrefix: '+7',
    flag: '🇰🇿',
    requiresCookieConsent: false,
    language: 'ru',
    currency: 'KZT',
    defaultTimezone: 'Asia/Almaty',
  },
  {
    name: 'Latvia',
    id: 'lv',
    code: 'LVA',
    phonePrefix: '+371',
    flag: '🇱🇻',
    requiresCookieConsent: true,
    language: 'lv',
    currency: 'EUR',
    defaultTimezone: 'Europe/Riga',
    identifiers: {
      businessIdFormat: '12345678901',
      vatIdFormat: 'LV12345678901',
    },
  },
  {
    name: 'Lithuania',
    id: 'lt',
    code: 'LTU',
    phonePrefix: '+370',
    flag: '🇱🇹',
    requiresCookieConsent: true,
    language: 'lt',
    currency: 'EUR',
    defaultTimezone: 'Europe/Vilnius',
    identifiers: {
      businessIdFormat: '123456789',
      vatIdFormat: 'LT123456789012',
    },
  },
  {
    name: 'Malta',
    id: 'mt',
    code: 'MLT',
    phonePrefix: '+356',
    flag: '🇲🇹',
    requiresCookieConsent: true,
    defaultTimezone: 'Europe/Malta',
    language: 'mt',
    currency: 'EUR',
    identifiers: {
      businessIdFormat: 'C12345',
      vatIdFormat: 'MT12345678',
    },
  },
  {
    name: 'Norway',
    id: 'no',
    code: 'NOR',
    phonePrefix: '+47',
    flag: '🇳🇴',
    requiresCookieConsent: true,
    language: 'nb',
    currency: 'NOK',
    defaultTimezone: 'Europe/Oslo',
    identifiers: {
      businessIdFormat: '123456789',
      vatIdFormat: '123456789MVA',
    },
  },
  {
    name: 'Poland',
    id: 'pl',
    code: 'POL',
    phonePrefix: '+48',
    flag: '🇵🇱',
    requiresCookieConsent: true,
    language: 'pl',
    currency: 'PLN',
    defaultTimezone: 'Europe/Warsaw',
  },
  {
    name: 'Serbia',
    id: 'rs',
    code: 'SRB',
    phonePrefix: '+381',
    flag: '🇷🇸',
    requiresCookieConsent: true,
    language: 'sr',
    currency: 'RSD',
    defaultTimezone: 'Europe/Belgrade',
  },
  {
    name: 'Slovakia',
    id: 'sk',
    code: 'SVK',
    phonePrefix: '+421',
    flag: '🇸🇰',
    requiresCookieConsent: true,
    language: 'sk',
    currency: 'EUR',
    defaultTimezone: 'Europe/Bratislava',
    identifiers: {
      businessIdFormat: '12345678',
      vatIdFormat: 'SK1234567890',
    },
  },
  {
    name: 'Slovenia',
    id: 'si',
    code: 'SVN',
    phonePrefix: '+386',
    flag: '🇸🇮',
    requiresCookieConsent: true,
    language: 'sl',
    currency: 'EUR',
    defaultTimezone: 'Europe/Ljubljana',
  },
  {
    name: 'Sweden',
    id: 'se',
    code: 'SWE',
    phonePrefix: '+46',
    flag: '🇸🇪',
    requiresCookieConsent: true,
    language: 'sv',
    currency: 'SEK',
    defaultTimezone: 'Europe/Stockholm',
    identifiers: {
      businessIdFormat: '123456-7890',
      vatIdFormat: 'SE123456789012',
    },
  },
];

export default countries;

export const requiredCookieConsentCountries = countries.filter((c) => c.requiresCookieConsent);

export const getCountryServiceAgreementLanguages = (country: string) => {
  if (!countries.some((c) => c.code === country)) {
    throw new Error(`Invalid country code: ${country}`);
  }

  switch (country) {
    case 'CZE':
      return ['en', 'cs'];
    case 'DEU':
      return ['en', 'de'];
    case 'EST':
      return ['en', 'et'];
    case 'HUN':
      return ['en', 'hu'];
    case 'ISR':
      return ['en', 'he'];
    case 'LTU':
      return ['en', 'lt'];
    case 'LVA':
      return ['en', 'lv'];
    case 'SVK':
      return ['en', 'sk'];
    default:
      return ['en'];
  }
};
