import { FormikTextareaField } from 'components';

interface Props {
  alwaysShowError?: boolean;
}

const PolicyDescriptionField = ({ alwaysShowError }: Props) => {
  return (
    <FormikTextareaField
      testId="policy.description-input"
      name="description"
      alwaysShowError={alwaysShowError}
      textareaProps={{ maxLength: 200, rows: 4 }}
    />
  );
};

export default PolicyDescriptionField;
