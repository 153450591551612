import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useCorporate } from 'modules/corporates';

export const useAdminsModalsController = () => {
  const { data: corporate } = useCorporate();
  const history = useHistory();

  const closeModal = useCallback(() => {
    if (!corporate) {
      return;
    }
    const search = new URLSearchParams(history.location.search);
    search.delete('id');
    const pathname = `/${corporate.id}/admins?${search.toString()}`;
    history.push(pathname);
  }, [corporate, history]);

  const openDeleteUserModal = useCallback(
    (userId: string) => {
      const search = new URLSearchParams(history.location.search);
      search.set('id', userId);
      const pathname = `${history.location.pathname}/delete?${search.toString()}`;
      history.push(pathname);
    },
    [history],
  );

  const openDeleteUsersModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    const pathname = `${history.location.pathname}/delete?${search.toString()}`;
    history.push(pathname);
  }, [history]);

  const openAddAdminModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    const pathname = `${history.location.pathname}/add?${search.toString()}`;
    history.push(pathname);
  }, [history]);

  return {
    closeModal,
    openDeleteUserModal,
    openDeleteUsersModal,
    openAddAdminModal,
  };
};
