import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import {
  LegacyPopover as Popover,
  LegacyPopoverProps as PopoverProps,
} from '@creditornot/cb-popover';
import { MenuActionItem, MenuItemGroup, MenuLinkItem } from '@creditornot/cb-menu';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { typographyCss } from '@creditornot/cb-ingredients';
import { useIntl } from 'react-intl';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { LocalizedMessage, useI18n, supportedLanguages } from 'i18n';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { Divider } from 'components';
import { useCorporate } from 'modules/corporates';
import { useThemeMode } from 'modules/waw-theme/ThemeProvider';
import { authService } from 'modules/auth/authenticator';

import { useOpenLanguageChangeModal } from './useOpenLanguageChangeModal';

type Props = Partial<PopoverProps> & {
  showUserInfo?: boolean;
};

const StyledMenuItemGroup = styled(MenuItemGroup)`
  /* Add diviver above the last MenuItem element */
  padding: 12px 16px;
  overflow: hidden;
  ${Divider} {
    margin-inline-start: -100px;
    width: 200%;
  }
`;

const Username = styled.span`
  ${typographyCss.Body3()}
`;

const Email = styled.small`
  ${typographyCss.Small()}
  color: ${color.textSubdued};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserInfoDropdown: React.FC<Props> = ({ children, showUserInfo, ...props }) => {
  const { themeMode, setThemeMode } = useThemeMode();
  const openLanguageChangeModal = useOpenLanguageChangeModal();
  const { getLocalizedMessage } = useI18n();
  const { locale } = useIntl();
  const history = useHistory();
  const { user } = useAuthState();
  const { isWoltEmployee } = useIsUserWoltEmployee();
  const isOnCountryConfigsPage = !!useRouteMatch('/country-configs');
  const isOnAgreementPartiesPage = !!useRouteMatch('/internal/agreement-parties');
  const { remove } = useCorporate();

  const languageLabel = useMemo(() => {
    return supportedLanguages.find((language) => language.code === locale.split('-')[0])?.label;
  }, [locale]);

  const handleGotoPageWithWoltAtWorkAccess = useCallback(
    (link: string) => {
      remove();
      history.push(link);
    },
    [remove, history],
  );

  const handleToggleThemeMode = useCallback(
    () => setThemeMode(themeMode === 'dark' ? 'light' : 'dark'),
    [setThemeMode, themeMode],
  );

  const handleCountriesConfigClick = useCallback(
    () => handleGotoPageWithWoltAtWorkAccess('/internal/country-configs'),
    [handleGotoPageWithWoltAtWorkAccess],
  );

  const handleAgreementPartiesClick = useCallback(
    () => handleGotoPageWithWoltAtWorkAccess('/internal/agreement-parties'),
    [handleGotoPageWithWoltAtWorkAccess],
  );

  const logout = useCallback(() => {
    window.location.replace(authService.getLogoutUri());
  }, []);

  return (
    <Popover
      {...props}
      showOnHover={false}
      placement="bottom-end"
      renderArrow={false}
      closeOnClickInside={true}
      disableContentPadding={true}
      content={
        <StyledMenuItemGroup>
          {showUserInfo && user && (
            <Item>
              <Username>{`${user.name.first_name} ${user.name.last_name}`}</Username>
              <Email>{user.email}</Email>
            </Item>
          )}
          <MenuActionItem onClick={openLanguageChangeModal} variant="black">
            {`${getLocalizedMessage('common.language')}: ${languageLabel}`}
          </MenuActionItem>
          {isWoltEmployee && (
            <>
              <Divider />
              <MenuLinkItem onClick={handleToggleThemeMode}>
                {`${getLocalizedMessage('common.theme')}: ${getLocalizedMessage(
                  themeMode === 'light' ? 'themes.light' : 'themes.dark',
                )}`}
              </MenuLinkItem>
              <MenuLinkItem onClick={handleCountriesConfigClick} active={isOnCountryConfigsPage}>
                <LocalizedMessage messageKey="navigation.country-configuration" />
              </MenuLinkItem>
              <MenuLinkItem onClick={handleAgreementPartiesClick} active={isOnAgreementPartiesPage}>
                <LocalizedMessage messageKey="navigation.agreement-parties" />
              </MenuLinkItem>
            </>
          )}
          <Divider />
          <MenuActionItem onClick={logout} variant="black">
            <LocalizedMessage messageKey="navigation.logout" />
          </MenuActionItem>
        </StyledMenuItemGroup>
      }
    >
      {children}
    </Popover>
  );
};
