import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useState } from 'react';
import { pick } from 'lodash';

import { Corporate } from 'modules/corporates/types';
import { formatCorporateDataToFormValues } from 'modules/corporates/formatFormValues';
import { useEditContactInformation } from 'modules/corporates/useEditContactInformation';

import { ContactPersonForm } from '../SignupV2/components/ContactPerson/ContactPersonForm';
import { EditCorporateFormValues } from '../CompanySettings/types';

type Props = {
  corporate: Corporate;
};

const FORM_KEYS = ['contact_email', 'contact_name', 'contact_phone'];
type FormValues = Pick<EditCorporateFormValues, 'contact_email' | 'contact_name' | 'contact_phone'>;

export const ContactPersonContainer = ({ corporate }: Props) => {
  const [state, setState] = useState<'view' | 'edit'>('view');
  const initialValues = pick(formatCorporateDataToFormValues(corporate), FORM_KEYS) as FormValues;

  const editContactInformation = useEditContactInformation(corporate);

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      try {
        await editContactInformation(initialValues, values);
        actions.setSubmitting(false);
        actions.setStatus({ submitSucceeded: true });
        actions.resetForm();
        setState('view');
      } catch (error) {
        actions.setSubmitting(false);
        actions.setStatus({ error });
      }
    },
    [editContactInformation, initialValues],
  );

  return (
    <Formik
      validateOnMount={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm, dirty, status, resetForm }) => (
        <Form>
          <ContactPersonForm
            renderMode={state}
            onEditClick={() => setState('edit')}
            onCancelClick={() => {
              resetForm();
              setState('view');
            }}
            onSaveClick={submitForm}
            isSubmitting={isSubmitting || status?.submitSucceeded}
            dirty={dirty}
            error={status?.error}
          />
        </Form>
      )}
    </Formik>
  );
};
