export function nestedObjectDifferences<T extends Record<string, any>>(
  updatedObj: T,
  initialObj: T,
): Partial<T> {
  return Object.keys(updatedObj).reduce((acc, key) => {
    if (typeof updatedObj[key] === 'object' && updatedObj[key] !== null) {
      if (typeof initialObj[key] === 'object' && initialObj[key] !== null) {
        const nestedDifferences = nestedObjectDifferences(updatedObj[key], initialObj[key]);
        if (Object.keys(nestedDifferences).length > 0) {
          return { ...acc, [key]: nestedDifferences };
        }
      } else {
        return { ...acc, [key]: updatedObj[key] };
      }
    } else {
      if (updatedObj[key] !== initialObj[key]) {
        return { ...acc, [key]: updatedObj[key] };
      }
    }
    return acc;
  }, {});
}
