import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { LocalizedMessage, useI18n } from 'i18n';
import {
  CREATE_POLICY_BUTTON_SELECTOR,
  CREATE_POLICY_MODAL_SELECTOR,
  GROUPS_TAB_SELECTOR,
} from 'onboarding/selectors';
import { CreatePolicyForm } from 'modules/policies/components';
import { useProductTour } from 'modules/product-tour';
import { useCorporate } from 'modules/corporates';
import { View } from 'components';
import { breakpoints } from 'modules/media';

// Max-width is calculated like this: 735px (policy form section max-width) + 2*24px margin
const Root = styled(View)`
  width: 100%;
  max-width: 783px;
  margin: 80px auto auto;
  padding-bottom: 24px;

  @media (max-width: ${breakpoints.medium}px) {
    margin-top: 0;
  }
`;

export const CreatePolicyView = () => {
  const history = useHistory();
  const { getLocalizedMessage } = useI18n();
  const { isInTourMode, gotoStep } = useProductTour();
  const { data: corporate } = useCorporate();
  const handleClose = useCallback(() => {
    if (!corporate) return;
    if (isInTourMode) {
      gotoStep(CREATE_POLICY_BUTTON_SELECTOR);
    }
    history.push(`/${corporate.id}/policies${history.location.search}`);
  }, [corporate, isInTourMode, history, gotoStep]);
  const handleSuccess = useCallback(() => {
    if (!corporate) return;
    if (isInTourMode) {
      gotoStep('');
      setTimeout(() => {
        gotoStep(GROUPS_TAB_SELECTOR);
      }, 500);
    }
    history.push(`/${corporate.id}/policies${history.location.search}`);
  }, [corporate, isInTourMode, history, gotoStep]);
  const handleBackClick = useCallback(() => {
    if (!corporate) return;
    history.push(`/${corporate.id}/policies`);
  }, [corporate, history]);

  return (
    <Root
      onBackClick={!isInTourMode ? handleBackClick : undefined}
      title={<LocalizedMessage messageKey="policies.create-policy-title" />}
      data-product-tour={CREATE_POLICY_MODAL_SELECTOR}
      headerStyle={{ paddingBottom: 0 }}
      hideHeaderBorder
    >
      <CreatePolicyForm
        secondaryButtonText={getLocalizedMessage('common.cancel')}
        onSecondaryButtonClick={handleClose}
        onClose={handleClose}
        onSubmitSuccess={handleSuccess}
      />
    </Root>
  );
};
