import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled, { css } from 'styled-components';

import { DataList } from 'components';
import { Corporate } from 'modules/corporates/types';

interface MultipleCorporatesDataListProps {
  className?: string;
  data: Corporate[] | null | undefined;
  error?: any;
  getDataItemId: (dataItem: Corporate) => string;
  loading?: boolean;
  noDataMessage?: string;
  onDataItemSelectionClick?: (dataItem: Corporate) => void;
  onDataItemClick: (corporate: Corporate) => void;
}

const DataItem = styled.div`
  width: 100%;
  display: flex;
  font-weight: 700;
  align-items: flex-start;
  color: ${color.text};
`;

const Container = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  flex: 1;
`;

const Title = styled.div<{
  isDisabled?: boolean;
}>`
  font-weight: 700;
  ${({ isDisabled }) => {
    if (isDisabled)
      return css`
        color: ${color.textDisabled};
      `;

    return css`
      font-weight: 700;
      color: ${color.text};
    `;
  }}
`;

const Subtitle = styled.div<{
  isDisabled?: boolean;
}>`
  margin-top: 2px;
  margin-bottom: 6px;
  font-weight: normal;
  ${({ isDisabled }) => {
    if (isDisabled)
      return css`
        color: ${color.textDisabled};
      `;

    return css`
      color: ${color.textSubdued};
    `;
  }}
`;

const renderDataItem = (dataItem: Corporate) => {
  return (
    <DataItem>
      <Container>
        <Title>{dataItem.name}</Title>
        <Subtitle>{dataItem.business_id}</Subtitle>
      </Container>
    </DataItem>
  );
};

const MultipleCorporatesDataList = (props: MultipleCorporatesDataListProps) => {
  return <DataList renderDataItem={renderDataItem} {...props} />;
};

export default MultipleCorporatesDataList;
