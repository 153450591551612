import React, { useCallback } from 'react';
import { Alert } from '@creditornot/cb-alert';
import { FormattedMessage } from 'react-intl';

import { ConfirmationModal, LoadingState } from 'components';
import { fetchUser, useEditUser } from 'modules/users';
import { Group } from 'modules/groups/types';
import { useFetch } from 'modules/react-query';
import { processError } from 'utils';
import { UI_SUCCESS_DELAY } from 'modules/common/constants';
import { formatToUuid } from 'modules/common/utils';

type Props = {
  corporateId: string;
  onClose: () => void;
  onSuccess: () => void;
  show: boolean;
  userId?: string;
  group: Group | null | undefined;
};

export const RemoveUsersFromGroupModal = ({
  corporateId,
  group,
  userId,
  onClose,
  onSuccess,
  show,
}: Props) => {
  const editUser = useEditUser();

  const shouldEnableQuery = !!userId && !!group;

  const {
    data: userData,
    error: userError,
    isFetching: isLoadingUser,
  } = useFetch({
    queryKey: ['user', corporateId, userId],
    queryFn: () => (userId ? fetchUser(corporateId, userId) : Promise.reject()),
    enabled: shouldEnableQuery,
  });

  const handleConfirm = useCallback(async () => {
    if (!userData || !group) {
      return;
    }

    const groupIds = userData.groups.map((group) => group.id);

    // Group ids from r-api are not in UUID format, but group ids from corporate-service are UUID
    const normalizedGroupId = formatToUuid(group.id);
    const filteredGroupIds = groupIds.filter((id) => id !== normalizedGroupId);

    // TODO - switch to dedicated DELETE endpoint if available later
    await editUser({
      userId: userData.user_id,
      data: { group_ids: filteredGroupIds },
      invalidateDelay: UI_SUCCESS_DELAY,
    });
  }, [userData, group, editUser]);

  return (
    <ConfirmationModal
      onClose={onClose}
      onConfirm={handleConfirm}
      show={show && !!userId}
      content={
        <>
          {userError && (
            <Alert title={processError(userError).message} variant="error">
              {processError(userError).data}
            </Alert>
          )}
          {isLoadingUser && (
            <LoadingState>
              <FormattedMessage id="common.loading" />
            </LoadingState>
          )}
          {userData && (
            <FormattedMessage
              id="groups-form.remove-user-from-group-confirmation-message"
              values={{ user: userData.email }}
            />
          )}
        </>
      }
      title={<FormattedMessage id="groups-form.remove-user-from-group" />}
      confirmationButtonMessage={<FormattedMessage id="common.remove" />}
      confirmationButtonVariant="lightRed"
      confirmationButtonDisabled={!shouldEnableQuery || isLoadingUser || Boolean(userError)}
      onSuccess={() => setTimeout(onSuccess, UI_SUCCESS_DELAY)}
    />
  );
};
