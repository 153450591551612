import { getCookieName } from '@creditornot/cwc-cookie-sdk';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useCookie, useFirstMountState } from 'react-use';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { match } from '@formatjs/intl-localematcher';

import { env } from 'config';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';

import { supportedLanguages, SupportedLanguageCode, fallbackLanguage } from './languageUtils';
import en from './messages/en.json';

export const DEFAULT_LANGUAGE_CODE: SupportedLanguageCode = 'en';

export const getLanguageCodeFromLocale = (locale: string) => locale.split('-')[0];

const ADMIN_PORTAL_LANGUAGE_COOKIE_NAME = 'wfw-admin-portal-language';

const getSupportedLanguageCode = (
  requestLocales: string[],
  isWoltEmployee: boolean,
): SupportedLanguageCode => {
  const availableLanguages = supportedLanguages
    .filter((lang) => (isWoltEmployee ? true : !lang.internal))
    .map(({ code }) => code);

  return match(requestLocales, availableLanguages, DEFAULT_LANGUAGE_CODE) as SupportedLanguageCode;
};

const resolveWithBrowserLocale = (languageCode: SupportedLanguageCode) => {
  if (
    languageCode in fallbackLanguage &&
    window.navigator.languages.some((language) => language.startsWith(languageCode))
  ) {
    const matchedBrowserLanguages = window.navigator.languages.filter((language) =>
      language.startsWith(languageCode),
    );

    return (
      fallbackLanguage[languageCode]!.find((language) =>
        matchedBrowserLanguages.includes(language),
      ) ?? fallbackLanguage[languageCode]![0]
    );
  }
  return languageCode;
};

function loadMessages(languageCode: string) {
  return import(`./messages/${languageCode}.json`);
}

export const IntlContext = createContext<{
  setUserLanguage: (code: SupportedLanguageCode) => void;
}>({
  setUserLanguage: () => {},
});

export const useSetUserLocale = () => {
  const value = useContext(IntlContext);
  return value;
};

export const IntlProvider = ({
  children,
  languageCode: languageCodeProp,
}: {
  children: React.ReactNode;
  languageCode?: string;
}) => {
  const { isWoltEmployee } = useIsUserWoltEmployee();
  /**
   * We want to read language cookie set i.e. from wolt.com, but
   * we want an explicit language cookie for admin portal as not
   * all lanauges are supported in admin portal.
   */
  const [languageCookie] = useCookie(getCookieName('language'));
  const [adminPortalLanguageCookie, setAdminPortalLanguageCookie] = useCookie(
    ADMIN_PORTAL_LANGUAGE_COOKIE_NAME,
  );
  const [languageCode, setLanguageCode] = useState(() =>
    getSupportedLanguageCode(
      [
        languageCodeProp,
        adminPortalLanguageCookie,
        languageCookie,
        env.NODE_ENV === 'development' && env.DEVELOPMENT_LOCALE
          ? env.DEVELOPMENT_LOCALE
          : undefined,
        window.navigator.language,
        window.navigator.languages[0],
      ].filter((locale): locale is string => locale !== null && typeof locale === 'string'),
      isWoltEmployee,
    ),
  );

  const [messages, setMessages] = useState(en);
  const isFirstMount = useFirstMountState();
  const resolvedLocale = resolveWithBrowserLocale(languageCode);

  useEffect(() => {
    if (isFirstMount && languageCode === 'en') {
      return;
    }

    loadMessages(languageCode).then((res) => {
      setMessages({ ...en, ...res.default });
    });
  }, [languageCode, isFirstMount]);

  const setUserLanguage = useCallback(
    (langCode: SupportedLanguageCode) => {
      setLanguageCode(langCode);
      setAdminPortalLanguageCookie(langCode);
    },
    [setAdminPortalLanguageCookie],
  );

  return (
    <IntlContext.Provider value={{ setUserLanguage }}>
      <ReactIntlProvider locale={resolvedLocale} messages={messages} defaultLocale="en">
        {children}
      </ReactIntlProvider>
    </IntlContext.Provider>
  );
};
