import styled from 'styled-components';
import { Trashbin } from '@creditornot/cb-icons';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { IconButton } from '@creditornot/cb-button';
import { FormattedMessage } from 'react-intl';

import { useAdminsQueryParams } from '../../hooks';

interface ActionButtonsProps {
  onDeleteClick: () => void;
}

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const StyledPopover = styled(Popover)`
  height: 36px;
`;

export const TableActionButtons = ({ onDeleteClick }: ActionButtonsProps) => {
  const [{ ids = [] }] = useAdminsQueryParams();
  const isMultipleSelected = ids.length > 0;

  if (isMultipleSelected) {
    return null;
  }

  return (
    <Root>
      <StyledPopover
        renderArrow={false}
        content={<FormattedMessage id="common.delete" />}
        placement="bottom"
      >
        <IconButton background="animated" variant="grey" size="small" onClick={onDeleteClick}>
          <Trashbin height="16px" width="16px" />
        </IconButton>
      </StyledPopover>
    </Root>
  );
};
