import * as t from 'io-ts';

import { merchantPayoutApiAgent } from 'apiAgents';
import { logValidationError } from 'validation';

import { VenuePayout } from './types';

export const fetchMerchantPayouts = async (id: string) => {
  const { data } = await merchantPayoutApiAgent.get<VenuePayout[]>(
    `/public/v1/corporate-portal/merchants/${id}/payouts`,
  );
  logValidationError('fetchMerchantPayouts', t.array(VenuePayout), data);
  return data;
};

export const fetchMerchantPayoutsZip = async (corporateId: string, payoutId: string) => {
  const response = await merchantPayoutApiAgent.get<string>(
    `/public/v1/corporate-portal/payouts/${corporateId}/${payoutId}/documents/zip`,
    { responseType: 'arraybuffer' },
  );
  logValidationError('fetchMerchantPayoutsZip', t.string, response);
  return response;
};

export const fetchMerchantPayoutsCsv = async (corporateId: string, payoutId: string) => {
  const response = await merchantPayoutApiAgent.get<string>(
    `/public/v1/corporate-portal/payouts/${corporateId}/${payoutId}/documents/csv`,
  );
  logValidationError('fetchMerchantPayoutsZip', t.string, response);
  return response;
};
