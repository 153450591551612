import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKey } from 'react-query/types/core';

export function useFetch<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>) {
  return useQuery({
    cacheTime: 60 * 1000,
    refetchOnMount: true,
    ...options,
  });
}
