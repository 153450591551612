import React from 'react';
import styled from 'styled-components';

import LabelledRadioInput from './LabelledRadioInput';
import { RadioInputCardProps, RadioInputCardGroupProps, RadioInputOption } from './types';

type CustomRadioInputProps = RadioInputCardProps & {
  id: string;
};

type RadioInputGroupProps = RadioInputCardGroupProps & {
  render?: (props: CustomRadioInputProps) => React.ReactElement;
};

const Root = styled.div<{ direction: string }>`
  display: flex;
  flex-direction: ${({ direction }) => (direction === 'column' ? 'column' : 'row')};
  ${({ direction }) => direction === 'row' && 'flex-wrap: wrap'};
  gap: 10px;
  width: 100%;
`;

const StyledLabelledRadioInput = styled(LabelledRadioInput)<{ direction: string }>`
  & + & {
    ${({ direction }) =>
      direction === 'column' ? 'margin-top: 3px;' : 'margin-inline-start: 10px;'}
  }
`;

const RadioInputGroup = ({
  className,
  direction = 'column',
  stretched = false,
  disabled,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  optionWidth = 200,
  render,
  value,
}: RadioInputGroupProps) => {
  const renderRadioInputs = () => {
    const createRadioInput = render
      ? (option: RadioInputOption) => {
          const id = `${name}-${option.value}`;
          return React.cloneElement(
            render({
              checked: value === option.value,
              direction,
              stretched,
              disabled: disabled || option.disabled,
              id,
              name,
              onBlur,
              onChange,
              onFocus,
              option,
              optionWidth,
            }),
            {
              key: id,
            },
          );
        }
      : (option: RadioInputOption) => {
          const id = `${name}-${option.value}`;
          return (
            <StyledLabelledRadioInput
              key={id}
              id={id}
              name={name}
              checked={value === option.value}
              value={option.value}
              label={option.label}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              disabled={disabled || option.disabled}
              direction={direction}
            />
          );
        };

    return options.map(createRadioInput);
  };

  return (
    <Root direction={direction} className={className}>
      {renderRadioInputs()}
    </Root>
  );
};

export default RadioInputGroup;
