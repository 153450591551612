import axios from 'axios';

import { env } from 'config';

export const authApiAgent = axios.create({
  baseURL: env.NODE_ENV === 'development' ? '/auth-api' : env.AUTH_API_URI,
});

export const daasSelfServiceApiAgent = axios.create();

export const merchantPayoutApiAgent = axios.create({
  baseURL: env.NODE_ENV === 'development' ? '/merchant-payout-api' : env.MERCHANT_PAYOUT_URI,
});

export const restaurantApiAgent = axios.create({
  baseURL: env.NODE_ENV === 'development' ? '/restaurant-api' : env.RESTAURANT_API_URI,
  headers: { 'X-App-Version': env.APP_VERSION },
});

export const wolfApiAgent = axios.create({
  baseURL: env.NODE_ENV === 'development' ? '/wolf-api' : env.WOLF_API_URI,
  headers: { 'X-App-Version': env.APP_VERSION },
});

export const daasCoreApiAgent = axios.create();

export const corporateServiceApiAgent = axios.create({
  baseURL: env.NODE_ENV === 'development' ? '/corporate-service' : env.CORPORATE_SERVICE_URI,
  headers: { 'X-App-Version': env.APP_VERSION },
});
