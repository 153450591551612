import { useCallback } from 'react';
import { SegmentedControl } from '@creditornot/cb-segmented-control';

import { useI18n } from 'i18n';

import { useUsersQueryParams } from '../hooks';

interface Props {
  className?: string;
  numberOfUsers: number;
  numberOfInvites: number;
  fixedWidths: boolean;
}

const UsersSegmentedControl: React.FC<Props> = ({
  className,
  numberOfUsers,
  numberOfInvites,
  fixedWidths,
}) => {
  const { getLocalizedMessage } = useI18n();
  const [{ showInvites, search, groups, status }, setQueryParams] = useUsersQueryParams();
  const handleChange = useCallback(
    (value: 'users' | 'invites') => {
      if (value === 'users') {
        setQueryParams({
          showInvites: undefined,
          users: undefined,
          invites: undefined,
          page: 1,
        });
        return;
      }

      setQueryParams({
        showInvites: true,
        invites: undefined,
        users: undefined,
        page: 1,
      });
    },
    [setQueryParams],
  );

  // Force component to update to fix SegmentedControl Tab width
  const isFiltering = !!search || !!groups || !!status;
  const key = `${numberOfUsers}-${numberOfInvites}-${isFiltering}`;

  return (
    <SegmentedControl
      key={key}
      className={className}
      fixedWidths={fixedWidths}
      options={[
        {
          value: 'users',
          label: `${getLocalizedMessage('users.segment.users')}${
            numberOfUsers || isFiltering ? `(${numberOfUsers})` : ' '
          }`,
        },
        {
          value: 'invites',
          label: `${getLocalizedMessage('users.segment.invites')}${
            numberOfInvites || isFiltering ? `(${numberOfInvites})` : ' '
          }`,
        },
      ]}
      value={showInvites ? 'invites' : 'users'}
      onChange={handleChange}
    />
  );
};

export default UsersSegmentedControl;
