import * as t from 'io-ts';

import { SpendingLimitPeriod } from 'modules/common/types';

export type GroupPolicy = t.TypeOf<typeof GroupPolicy>;
export const GroupPolicy = t.type({
  budget_allowance: t.union([
    t.type({
      period: SpendingLimitPeriod,
      amount: t.number,
      currency: t.string,
      exclude_delivery_fee: t.boolean,
    }),
    t.null,
  ]),
  created_at: t.string,
  delivery_areas: t.array(
    t.type({
      created_at: t.string,
      description: t.string,
      id: t.string,
      latitude: t.number,
      longitude: t.number,
      modified_at: t.string,
      name: t.string,
      radius_meters: t.number,
    }),
  ),
  description: t.string,
  giftcard_purchases_allowed: t.boolean,
  id: t.string,
  modified_at: t.string,
  name: t.string,
  order_comment_required: t.boolean,
  times: t.array(
    t.type({
      start_minute: t.number,
      end_minute: t.number,
      day_of_week: t.keyof({
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
      }),
    }),
  ),
});

export type Group = t.TypeOf<typeof Group>;
export const Group = t.type({
  active: t.boolean,
  add_to_all: t.boolean,
  created_at: t.string,
  description: t.string,
  id: t.string,
  is_default: t.boolean,
  modified_at: t.string,
  name: t.string,
  user_count: t.union([t.undefined, t.number]),
  policies: t.array(GroupPolicy),
});
