import React, { useState, useLayoutEffect, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';

import { useCreateDomElement } from 'modules/hooks';

import ProductTourContext from './ProductTourContext';
import ProductTourPopper from './ProductTourPopper';
import { TourConfig } from './types';
import HightlightedElementWrapper from './HightlightedElementWrapper';

interface Props {
  config: TourConfig[];
  children?: React.ReactNode;
}

const PRODUCT_TOUR_ID = 'PRODUCT_TOUR';
const ROOT_ID = 'root';

const GlobalStyle = createGlobalStyle`
  body {
    height: 100%;
    overflow: hidden;
  }
`;

const useHightlightElement = (selector: string | null) => {
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const setTargetElementIntervalRef = useRef(setInterval(() => {}, 0));

  const handleConfigSelectorChange = useCallback(() => {
    if (selector) {
      const newElement = document.querySelector<HTMLElement>(`[data-product-tour="${selector}"]`);

      if (newElement && setTargetElementIntervalRef.current) {
        clearInterval(setTargetElementIntervalRef.current);
      }

      setTargetElement(newElement);
    }
  }, [selector]);

  useEffect(() => {
    setTargetElement(null);

    const newElement = document.querySelector<HTMLElement>(
      `[data-product-tour="${selector || ''}"]`,
    );

    if (newElement) {
      setTargetElement(newElement);
    } else {
      setTargetElementIntervalRef.current = setInterval(handleConfigSelectorChange, 500);

      return () => clearInterval(setTargetElementIntervalRef.current);
    }
  }, [handleConfigSelectorChange, selector]);

  useLayoutEffect(() => {
    targetElement?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  }, [targetElement]);

  return targetElement;
};

const ProductTourProvider: React.FC<Props> = ({ children, config }) => {
  const [step, setStep] = useState(-1);

  const hightlightedElement = useHightlightElement(config[step]?.selector ?? null);

  const productTourRoot = useCreateDomElement(PRODUCT_TOUR_ID, ROOT_ID);

  const [isInTourMode, setIsInTourMode] = useState(false);

  const start = useCallback(() => {
    setIsInTourMode(true);
    setStep(0);
  }, []);

  const end = useCallback(() => {
    setIsInTourMode(false);
    setStep(-1);
  }, []);

  const gotoStep = useCallback(
    (selector: string) => {
      if (!isInTourMode) return;

      const selectorIndex = config.findIndex((c) => c.selector === selector);

      setStep(selectorIndex);
    },
    [config, isInTourMode],
  );

  return (
    <ProductTourContext.Provider
      value={{
        start,
        end,
        gotoStep,
        isInTourMode,
        step: config[step]?.selector,
      }}
    >
      {children}

      {isInTourMode &&
        ReactDOM.createPortal(
          <>
            <ProductTourPopper
              placement={config[step]?.placement}
              referenceElement={hightlightedElement}
            >
              {config[step]?.renderTooltip?.({
                start,
                end,
                gotoStep,
                isInTourMode,
              })}
            </ProductTourPopper>

            {!config[step]?.disableBackdrop && (
              <HightlightedElementWrapper
                element={hightlightedElement}
                isInteractable={!!config[step]?.isInteractable}
              />
            )}

            <GlobalStyle />
          </>,
          productTourRoot,
        )}
    </ProductTourContext.Provider>
  );
};

export default ProductTourProvider;
