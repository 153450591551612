import { Select, SelectProps } from '@creditornot/cb-select';
import { useField } from 'formik';
import { isNil } from 'ramda';

import { useI18n } from 'i18n';

type BaseOptions = {
  label: string;
  value: string;
};

type CompanyIndustryFieldProps = Partial<SelectProps<BaseOptions>>;

export const CompanyIndustryField = (props: CompanyIndustryFieldProps) => {
  const [{ value }, , { setValue }] = useField<string>('industry');
  const { getLocalizedMessage } = useI18n();

  return (
    <Select
      {...props}
      placeholder={getLocalizedMessage('common.select')}
      inputId="industry"
      value={value}
      onChange={(value) => !isNil(value) && setValue(value)}
      options={[
        {
          label: getLocalizedMessage('corporate-form.industry.consulting_services'),
          value: 'consulting_services',
        },
        {
          label: getLocalizedMessage('corporate-form.industry.event_and_media_services'),
          value: 'event_and_media_services',
        },
        {
          label: getLocalizedMessage('corporate-form.industry.education_and_government'),
          value: 'education_and_government',
        },
        {
          label: getLocalizedMessage('corporate-form.industry.financial_services'),
          value: 'financial_services',
        },
        {
          label: getLocalizedMessage('corporate-form.industry.healthcare_and_pharmaceuticals'),
          value: 'healthcare_and_pharmaceuticals',
        },
        {
          label: getLocalizedMessage('corporate-form.industry.hospitality'),
          value: 'hospitality',
        },
        {
          label: getLocalizedMessage('corporate-form.industry.information_technology'),
          value: 'information_technology',
        },
        {
          label: getLocalizedMessage('corporate-form.industry.legal_services'),
          value: 'legal_services',
        },
        {
          label: getLocalizedMessage('corporate-form.industry.manufacturing_and_construction'),
          value: 'manufacturing_and_construction',
        },
        {
          label: getLocalizedMessage('corporate-form.industry.retail'),
          value: 'retail',
        },
        {
          label: getLocalizedMessage('corporate-form.industry.e_commerce'),
          value: 'e_commerce',
        },
        {
          label: getLocalizedMessage('corporate-form.industry.other'),
          value: 'other',
        },
      ]}
    />
  );
};
