import styled from 'styled-components';
import { Button } from '@creditornot/cb-button';
import { useIntl } from 'react-intl';

type Props = {
  onCancelClick?: () => void;
  dirty?: boolean;
  isSubmitting?: boolean;
};

const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const FormSaveCancelButtons = ({ onCancelClick, dirty, isSubmitting }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <ButtonsContainer>
      <Button
        type="button"
        variant="lightBlue"
        size="large"
        onClick={onCancelClick}
        disabled={isSubmitting}
      >
        {formatMessage({ id: 'common.cancel' })}
      </Button>
      <Button
        type="submit"
        variant="blue"
        size="large"
        disabled={!dirty || isSubmitting}
        loading={isSubmitting}
      >
        {formatMessage({ id: 'common.save' })}
      </Button>
    </ButtonsContainer>
  );
};
