import { Route, RouteProps } from 'react-router-dom';
import { ElementType } from 'react';

import { TopNavigationBar } from 'navigation';
import { FillViewportHeightDiv } from 'components';

export const RouteWithTopNavBar = ({
  component,
  ...rest
}: RouteProps & { showNavbarBorder?: boolean; [key: string]: any }) => {
  const Component = component as ElementType<any>;

  return (
    <Route
      render={(props) => {
        return (
          <FillViewportHeightDiv>
            <TopNavigationBar />
            <Component {...props} {...rest} />
          </FillViewportHeightDiv>
        );
      }}
      {...rest}
    />
  );
};
