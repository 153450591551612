import { Button } from '@creditornot/cb-button';

import { LocalizedMessage } from 'i18n';
import { INVITE_USERS_BUTTON_SELECTOR } from 'onboarding/selectors';

import { useUsersModalsController } from './hooks';

interface Props {
  className?: string;
}

export const InviteButton = ({ className }: Props) => {
  const { openUserInviteModal } = useUsersModalsController();

  return (
    <Button
      className={className}
      variant="blue"
      size="small"
      data-product-tour={INVITE_USERS_BUTTON_SELECTOR}
      onClick={openUserInviteModal}
    >
      <LocalizedMessage messageKey="users.invite-users-button" />
    </Button>
  );
};
