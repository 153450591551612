import env from 'config/env';

export const getLottieSizes = (size: LottieSizes) => {
  switch (size) {
    case 'square':
      return '_1-1.json';
    case 'wide':
      return '_3-2.json';
    default:
      return '_1-1.json';
  }
};

export type LottieSizes = 'square' | 'wide';

/**
 * @deprecated Use getLottiePathFromConsumerAssets instead
 */
const getLottiePath = (name: string) => (size: LottieSizes) =>
  `https://cdn.wolt.com/lottie/assets/${name}${getLottieSizes(size)}`;

const getLottiePathFromConsumerAssets = (name: string) => (size: LottieSizes) =>
  `${env.CONSUMER_ASSETS_URI}/lottie/assets/${name}${getLottieSizes(size)}`;

export const lottieAnimations = {
  loginOverview: getLottiePathFromConsumerAssets('corporate_login_overview'),
  token: getLottiePath('token'),
  guyDrink: getLottiePath('guy_drink'),
  waiterOrder: getLottiePath('waiter_order'),
  girlInvitePlanes: getLottiePathFromConsumerAssets('girl_invite_planes'),
  courierWaving: getLottiePath('courier_waving'),
  burgerFries: getLottiePath('burger_fries'),
  groupReady: getLottiePath('group_ready'),
  courierGasp: getLottiePath('courier_gasp'),
  girlSofaCode: getLottiePath('girl_sofa_code'),
  girlShrugging: getLottiePath('girl_shrugging'),
  girlWires: getLottiePath('girl_wires'),
  courierNotFound: getLottiePath('courier_not_found'),
  mail: getLottiePath('mail'),
  highFiveHands: getLottiePath('high_five_hands'),
  existingAccount: getLottiePath('existing_account'),
  verifyEmail: getLottiePath('verify_email'),
  scientistError: getLottiePath('scientist_error'),
  howToUseVoucher: getLottiePath('how_to_use_voucher'),
  city: getLottiePathFromConsumerAssets('city'),
  magnifyingGlasses: getLottiePathFromConsumerAssets('magnifying_glasses'),
};
