import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQueryClient } from 'modules/react-query';

import { AddUserData, addUser } from './api';

interface Props {
  userData: AddUserData;
  invalidateDelay?: number;
}

export const useAddUser = () => {
  const queryClient = useQueryClient();
  const { data: corporate } = useCorporate();

  return useCallback(
    async ({ userData, invalidateDelay }: Props) => {
      if (!corporate?.id) {
        return Promise.resolve();
      }
      await addUser(corporate.id, userData);

      setTimeout(() => {
        queryClient.invalidateQueries();
      }, invalidateDelay ?? 0);
    },
    [corporate, queryClient],
  );
};
