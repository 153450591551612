import styled from 'styled-components';

import { ResponsiveContainer } from 'components';

export const ApiErrorContainer = styled(ResponsiveContainer)`
  margin-top: 4px;
  margin-bottom: -20px;
  padding-inline: 0;

  & > div {
    flex: 1;
  }
`;
