import { color } from '@creditornot/cb-ingredients/design-tokens';
import { ChangeEventHandler, useCallback, useState } from 'react';
import { Formik, FormikHelpers, useField, useFormikContext } from 'formik';
import { Checkmark, MagnifyingGlass } from '@creditornot/cb-icons';
import { Button, TextButton } from '@creditornot/cb-button';
import styled from 'styled-components';
import { Empty } from '@creditornot/cb-placeholders';
import { Input } from '@creditornot/cb-input';

import { CompanyUsersList, FormFooter, ResponsiveContainer } from 'components';
import { LocalizedMessage, useI18n } from 'i18n';
import { Group } from 'modules/groups/types';
import { useDebounce } from 'modules/hooks';
import { useFetch } from 'modules/react-query';
import { fetchGroupUsers, useAddGroupUsers } from 'modules/groups';
import { UserV2BasicInfo } from 'modules/users/types';
import { fetchAllUsers } from 'modules/users';

type AddUsersToGroupFormProps = {
  corporateId: string;
  onClose: () => void;
  onSuccess: () => void;
  onInviteUserClick: () => void;
  group: Group;
};

type FormikAddUsersToGroupFormProps = {
  group: Group;
  corporateId: string;
  onClose: () => void;
  onInviteUserClick: () => void;
};

type FormValues = {
  userIds: string[];
};

const initialValues: FormValues = {
  userIds: [],
};

const StyledFormFooter = styled(FormFooter)`
  margin-top: 16px;
`;

const FormikAddUsersToGroupFormRoot = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

const Header = styled.div``;

const Row = styled(ResponsiveContainer)`
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: space-between;

  & + & {
    border-top: 1px solid ${color.border};
  }
`;

const StyledEmpty = styled(Empty)`
  padding-top: 32px;
  padding-bottom: 24px;
`;

const FormikAddUsersToGroupForm = ({
  corporateId,
  group,
  onClose,
  onInviteUserClick,
}: FormikAddUsersToGroupFormProps) => {
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search.toLowerCase(), 300);

  const { getLocalizedMessage } = useI18n();

  const [{ value: addedUserIds }, , { setValue }] = useField<string[]>('userIds');

  const {
    data: companyUsers,
    isFetching: isFetchingCompanyUsers,
    error: companyUsersError,
  } = useFetch({
    queryKey: ['company-users', corporateId],
    queryFn: () => fetchAllUsers(corporateId),
  });

  const {
    data: groupUsers,
    isFetching: isFetchingGroupUsers,
    error: groupUsersError,
  } = useFetch({
    queryKey: ['group-users', corporateId, group.id],
    queryFn: () => fetchGroupUsers(corporateId, group.id),
  });

  const { status, submitForm, isSubmitting, isValid, setStatus, dirty } = useFormikContext();

  const handleSearch: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setSearch(e.target.value),
    [],
  );

  const handleClearClick = useCallback(() => setSearch(''), []);

  const noCompanyUsers = companyUsers?.length === 0 && !isFetchingCompanyUsers;

  const selectableUsers = companyUsers?.filter(
    (user) => !groupUsers?.some((groupUser) => groupUser.id === user.user_id),
  );

  const allUsers = companyUsers?.filter((user) => {
    const fullName = `${user.name.first} ${user.name.last}`;
    return fullName.toLowerCase().includes(debouncedSearch) || user.email.includes(debouncedSearch);
  });

  const allMembersSelected = selectableUsers?.length === addedUserIds.length;

  const error = companyUsersError || groupUsersError || status?.error;

  const isFetching = isFetchingCompanyUsers || isFetchingGroupUsers;

  const noMembersLeftToAdd = !isFetching && selectableUsers?.length === 0;

  const handleCloseErrorAlertClick = useCallback(
    () => setStatus({ ...status, error: undefined }),
    [setStatus, status],
  );

  const getDisabledUser = useCallback(
    (user: UserV2BasicInfo) => !!groupUsers?.some((groupUser) => groupUser.id === user.user_id),
    [groupUsers],
  );

  const getSelectedUser = useCallback(
    (user: UserV2BasicInfo) => !!addedUserIds.some((addedUserId) => addedUserId === user.user_id),
    [addedUserIds],
  );

  const handleUserClick = useCallback(
    (user: UserV2BasicInfo) => {
      const isSelected = getSelectedUser(user);
      if (isSelected) {
        setValue(addedUserIds.filter((addedUserId) => addedUserId !== user.user_id));
        return;
      }

      setValue(addedUserIds.concat(user.user_id));
    },
    [addedUserIds, getSelectedUser, setValue],
  );

  const handleDeselectAllUsersClick = useCallback(() => setValue([]), [setValue]);

  const handleSelectAllUsersClick = useCallback(
    () => setValue((selectableUsers || []).map((user) => user.user_id)),
    [selectableUsers, setValue],
  );

  if (noMembersLeftToAdd || noCompanyUsers) {
    return (
      <FormikAddUsersToGroupFormRoot>
        {noCompanyUsers ? (
          <>
            <StyledEmpty
              title={getLocalizedMessage('groups.no-members-to-add-title')}
              description={getLocalizedMessage('groups.no-members-to-add-description')}
            />
            <StyledFormFooter
              rightContent={
                <Button
                  variant="blue"
                  size="small"
                  data-test-id="AddUsersToGroupForm.InviteUsers"
                  onClick={onInviteUserClick}
                >
                  <LocalizedMessage messageKey="groups.no-members-to-add-button" />
                </Button>
              }
            />
          </>
        ) : (
          noMembersLeftToAdd && (
            <>
              <StyledEmpty title={getLocalizedMessage('groups.all-members-are-added')} />
              <StyledFormFooter
                rightContent={
                  <Button variant="blue" size="small" onClick={submitForm}>
                    <LocalizedMessage messageKey="common.got-it" />
                  </Button>
                }
              />
            </>
          )
        )}
      </FormikAddUsersToGroupFormRoot>
    );
  }

  return (
    <FormikAddUsersToGroupFormRoot>
      <Header>
        {selectableUsers && (
          <Row>
            <div>
              <LocalizedMessage
                messageKey="groups.group-users-select.selected-users"
                values={{
                  selectMember: addedUserIds.length,
                  numberOfMember: selectableUsers.length,
                  b: (text: string) => <strong key={text}>{text}</strong>,
                }}
              />
            </div>

            {allMembersSelected ? (
              <TextButton onClick={handleDeselectAllUsersClick}>
                <LocalizedMessage messageKey="groups.group-users-select.deselected-all-users" />
              </TextButton>
            ) : (
              <TextButton onClick={handleSelectAllUsersClick}>
                <LocalizedMessage messageKey="groups.group-users-select.selected-all-users" />
              </TextButton>
            )}
          </Row>
        )}
        <Row>
          <Input
            icon={<MagnifyingGlass />}
            placeholder={getLocalizedMessage('common.search')}
            value={search}
            onChange={handleSearch}
            onClearClick={handleClearClick}
          />
        </Row>
      </Header>

      <CompanyUsersList
        isLoading={isFetching}
        error={error}
        emptyMessage={getLocalizedMessage('groups.no-users-with-search-term', {
          searchTerm: search,
        })}
        onUserClick={handleUserClick}
        onCloseErrorAlertClick={handleCloseErrorAlertClick}
        allUsers={allUsers}
        getDisabledUser={getDisabledUser}
        getSelectedUser={getSelectedUser}
        disabledUserText={getLocalizedMessage('groups.group-users-select.added-user')}
      />

      <StyledFormFooter
        rightContent={
          <>
            <Button
              size="small"
              variant="lightBlue"
              onClick={onClose}
              disabled={isSubmitting || status?.submitSucceeded}
            >
              <LocalizedMessage messageKey="common.cancel" />
            </Button>
            <Button
              variant="blue"
              size="small"
              onClick={submitForm}
              disabled={!isValid || isSubmitting || status?.submitSucceeded || !dirty}
              loading={isSubmitting}
              data-test-id="AddUsersToGroupForm.SubmitButton"
              icon={
                status?.submitSucceeded && (
                  <Checkmark data-test-id="AddUsersToGroupForm.Checkmark" />
                )
              }
            >
              <LocalizedMessage messageKey="common.add" />
            </Button>
          </>
        }
      />
    </FormikAddUsersToGroupFormRoot>
  );
};

export const AddUsersToGroupForm = ({
  corporateId,
  onClose,
  onSuccess,
  onInviteUserClick,
  group,
}: AddUsersToGroupFormProps) => {
  const addGroupUsers = useAddGroupUsers();

  const handleSubmit = useCallback(
    async ({ userIds }: FormValues, actions: FormikHelpers<FormValues>) => {
      try {
        await addGroupUsers(group.id, userIds);

        actions.setSubmitting(false);
        actions.setStatus({ submitSucceeded: true });
        setTimeout(() => {
          onSuccess();
        }, 500);
      } catch (error) {
        actions.setStatus({ error });
        actions.setSubmitting(false);
      }
    },
    [addGroupUsers, group.id, onSuccess],
  );

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      <FormikAddUsersToGroupForm
        corporateId={corporateId}
        group={group}
        onClose={onClose}
        onInviteUserClick={onInviteUserClick}
      />
    </Formik>
  );
};
