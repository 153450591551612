import { CookieConsents } from '@creditornot/cwc-cookie-sdk';
import { MouseEventHandler, useCallback } from 'react';

import { CookieConsentsModal, ManageCookieConsentsModal } from '.';

export type ManageCookieConsentsControllerProps = {
  showConsentsModal: boolean;
  showManageCookieConsentsModal: boolean;
  setShowManageCookieConsentsModal: React.Dispatch<React.SetStateAction<boolean>>;
  initialConsents: CookieConsents;
  privacyPolicyUrl: string;
  onAcceptDefaultConsentsClick: MouseEventHandler<HTMLButtonElement>;
  onSubmitCookieConsents: (consents: CookieConsents) => void;
};

export const ManageCookieConsentsController = ({
  showConsentsModal,
  showManageCookieConsentsModal,
  setShowManageCookieConsentsModal,
  initialConsents,
  privacyPolicyUrl,
  onAcceptDefaultConsentsClick,
  onSubmitCookieConsents: onSubmitCookieConsentsProp,
}: ManageCookieConsentsControllerProps) => {
  const handleRequestManageConsentsOpen = useCallback(() => {
    setShowManageCookieConsentsModal(true);
  }, [setShowManageCookieConsentsModal]);

  const handleDismissManageCookieConsentsModal = useCallback(() => {
    setShowManageCookieConsentsModal(false);
  }, [setShowManageCookieConsentsModal]);

  const onSubmitCookieConsents = useCallback(
    (consents: CookieConsents) => {
      handleDismissManageCookieConsentsModal();
      onSubmitCookieConsentsProp(consents);
    },
    [handleDismissManageCookieConsentsModal, onSubmitCookieConsentsProp],
  );

  return (
    <>
      <CookieConsentsModal
        show={showConsentsModal}
        privacyPolicyUrl={privacyPolicyUrl}
        onAcceptDefaultConsentsClick={onAcceptDefaultConsentsClick}
        onManageConsentsClick={handleRequestManageConsentsOpen}
      />
      <ManageCookieConsentsModal
        show={showManageCookieConsentsModal}
        initialConsents={initialConsents}
        onDismiss={handleDismissManageCookieConsentsModal}
        onSubmit={onSubmitCookieConsents}
      />
    </>
  );
};
