import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { FC } from 'react';

import { Header, View } from 'components';

import { ReportsSegmentedControl } from './components/ReportsSegmentedControl';
import { ReportsFilter } from './components/ReportsFilter';
import { useReportsQueryParams } from './hooks';
import { ReportsInvoiceDataView } from './views/ReportsInvoiceDataView';
import { ReportsPayoutDataView } from './views/ReportsPayoutDataView';

export const ReportsView: FC<RouteComponentProps<{ corporateId: string }>> = ({ match }) => {
  const { formatMessage } = useIntl();
  const reportsFilter = <ReportsFilter />;
  const reportsSegmentedControl = <ReportsSegmentedControl />;
  const [{ tabName }] = useReportsQueryParams();

  return (
    <View
      title={formatMessage({ id: 'reports.title' })}
      subtitle={formatMessage({ id: 'reports.subtitle' })}
    >
      <Header
        borderBottom
        leftItems={[reportsSegmentedControl, tabName === 'payouts' && reportsFilter]}
      />
      {tabName === 'payouts' ? (
        <ReportsPayoutDataView corporateId={match.params.corporateId} />
      ) : (
        <ReportsInvoiceDataView />
      )}
    </View>
  );
};
