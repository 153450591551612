import { useCallback, useMemo } from 'react';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { Operation } from './types';
import hasPermissions from './hasPermissions';

const useIsUserWoltEmployee = () => {
  const { user } = useAuthState();
  const userPermissions = useMemo(() => (!!user && user.permissions) || [], [user]);
  const operations: Operation[] = useMemo(() => ['c', 'r', 'u', 'd'], []);

  const comparePermissions = useCallback(
    (resource: string, operations: Operation[]) =>
      !!user && hasPermissions([{ resource, operations }], userPermissions),
    [user, userPermissions],
  );

  // Compares each CRUD operation to the current user's permissions and maps it to an object
  const userPermissionMap = useMemo(() => {
    return operations.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: comparePermissions('wolt_at_work', [curr]),
      }),
      {},
    ) as Record<Operation, boolean>;
  }, [comparePermissions, operations]);

  const isWoltEmployeeWithFullCrudAccess = useMemo(
    () => comparePermissions('wolt_at_work', operations),
    [comparePermissions, operations],
  );

  const isWoltEmployeeWithOpsToolsAccess = useMemo(
    () => comparePermissions('ops_tools', ['r']),
    [comparePermissions],
  );

  return {
    isWoltEmployeeWithCreate: userPermissionMap['c'],
    isWoltEmployee: userPermissionMap['r'],
    isWoltEmployeeWithUpdate: userPermissionMap['u'],
    isWoltEmployeeWithDelete: userPermissionMap['d'],
    isWoltEmployeeWithOpsToolsAccess,
    isWoltEmployeeWithFullCrudAccess,
  };
};

export default useIsUserWoltEmployee;
