import { Tag, TagVariant as Variant } from '@creditornot/cb-tag';

import { LocalizedMessage, type MessageIds } from 'i18n';

type Status =
  | 'active'
  | 'received'
  | 'acknowledged'
  | 'fetched'
  | 'production'
  | 'ready'
  | 'delivered'
  | 'refunded';

interface Props {
  status: Status;
  isPreOrder: boolean;
  className?: string;
}

const statusMap: Record<Status, Variant> = {
  active: 'lightGreen',
  received: 'lightGreen',
  acknowledged: 'lightGreen',
  fetched: 'lightGreen',
  production: 'lightGreen',
  ready: 'lightGreen',
  delivered: 'lightBlack',
  refunded: 'lightRed',
};

const statusMessageKeys: Record<Status, MessageIds> = {
  active: 'common.status.active',
  received: 'common.status.active',
  acknowledged: 'common.status.active',
  fetched: 'common.status.active',
  production: 'common.status.active',
  ready: 'common.status.active',
  delivered: 'orders.delivered-status',
  refunded: 'orders.refunded-status',
};

export const OrderStatus = ({ status, isPreOrder, className }: Props) => {
  const showPreOrderStatus =
    isPreOrder && (['acknowledged', 'received'] as Array<typeof status>).includes(status);

  // Display raw status data if receives unexpected status
  const statusMessageKey = statusMessageKeys[status] ?? status;

  return (
    <Tag className={className} variant={showPreOrderStatus ? 'lightDarkBlue' : statusMap[status]}>
      {showPreOrderStatus ? (
        <LocalizedMessage messageKey="common.pre-order" />
      ) : (
        <LocalizedMessage messageKey={statusMessageKey} />
      )}
    </Tag>
  );
};
