import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';

interface DividerProps extends React.PropsWithoutRef<JSX.IntrinsicElements['div']> {
  color?: string;
}

export default styled.div<DividerProps>`
  background-color: ${({ color: colorProp }) => colorProp || color.border};
  min-height: 1px;
  width: 100%;
`;
