import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { Checkmark } from '@creditornot/cb-icons';
import { Button } from '@creditornot/cb-button';
import { typographyCss } from '@creditornot/cb-ingredients';

import { ApiErrorNotification, FormFooter } from 'components';
import { LocalizedMessage, useI18n } from 'i18n';
import { useEditEvent, useInviteEventUsers } from 'modules/events';
import { Event } from 'modules/events/types';
import { isDuplicateEmail, isEmail } from 'validation';

import { InviteEventUsersFormData } from '../types';
import { InviteEventUsersFormSection } from '../InviteEventUsersFormSection';
import { formatInviteEventUsersFormValuesToInviteEventUsersApiData } from '../utils';

interface Props {
  onSuccess: () => void;
  onCloseClick: () => void;
  eventData: Event;
}

interface FormikInviteEventUsersFormProps {
  onCloseClick: () => void;
}

const ErrorContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const InvitationCountText = styled.span`
  ${typographyCss.Body3()}
  color: ${color.textSubdued};
`;

const InvitationCountNumber = styled.span`
  ${typographyCss.Body3({ weight: 'bold' })}
  color: ${color.text};
`;

const countValidEmails = (values: Array<string>) =>
  values
    .filter((email) => isEmail(email) === undefined)
    .filter((email, index, emails) => isDuplicateEmail(email, index, emails) === undefined).length;

const FormikInviteEventUsersForm = ({ onCloseClick }: FormikInviteEventUsersFormProps) => {
  const { status, submitForm, isSubmitting, values, dirty, isValid } =
    useFormikContext<InviteEventUsersFormData>();

  const validEmailsAmount = values.emails ? countValidEmails(values.emails) : 0;

  return (
    <>
      <InviteEventUsersFormSection />

      {status?.error && (
        <ErrorContainer>
          <ApiErrorNotification error={status.error} />
        </ErrorContainer>
      )}

      <FormFooter
        leftContent={
          values.emails && values.emails.length > 0 ? (
            <InvitationCountText>
              <LocalizedMessage
                messageKey="events.create-event-form.invite-participants.invitation-count-text"
                values={{
                  count: validEmailsAmount,
                  b: (msg: string) => (
                    <InvitationCountNumber key="msg">{msg}</InvitationCountNumber>
                  ),
                }}
              />
            </InvitationCountText>
          ) : null
        }
        rightContent={
          <>
            <Button
              size="small"
              variant="lightBlue"
              onClick={onCloseClick}
              disabled={isSubmitting || status?.submitSucceeded}
            >
              <LocalizedMessage messageKey="common.close" />
            </Button>
            <Button
              size="small"
              variant="blue"
              onClick={submitForm}
              disabled={isSubmitting || status?.submitSucceeded || !dirty || !isValid}
              loading={isSubmitting}
              icon={status?.submitSucceeded && <Checkmark />}
            >
              <LocalizedMessage messageKey="events.create-event-form.invite-participants.send-invite-button" />
            </Button>
          </>
        }
      />
    </>
  );
};

export const InviteEventUsersForm = ({ onCloseClick, eventData, onSuccess }: Props) => {
  const inviteEventUsers = useInviteEventUsers();
  const eventId = eventData.id;
  const editEvent = useEditEvent();
  const { getLocalizedMessage } = useI18n();
  const initialValues: InviteEventUsersFormData = useMemo(() => {
    return {
      emails: [],
      customMessage:
        eventData.invite_message ||
        getLocalizedMessage(
          'events.create-event-form.invite-participants.customize-message-placeholder',
        ),
      saveDefaultMessage: true,
    };
  }, [eventData.invite_message, getLocalizedMessage]);

  const handleSubmit = useCallback(
    async (values: InviteEventUsersFormData, actions: FormikHelpers<InviteEventUsersFormData>) => {
      try {
        await inviteEventUsers(
          formatInviteEventUsersFormValuesToInviteEventUsersApiData({
            eventId,
            ...values,
          }),
        );

        if (values.saveDefaultMessage) {
          await editEvent(eventId, { invite_message: values.customMessage });
        }

        actions.setSubmitting(false);
        actions.setStatus({ submitSucceeded: true });
        setTimeout(() => {
          onSuccess();
        }, 500);
      } catch (error) {
        actions.setSubmitting(false);
        actions.setStatus({ error });
      }
    },
    [inviteEventUsers, eventId, onSuccess, editEvent],
  );

  return (
    <Formik initialValues={initialValues} validateOnMount onSubmit={handleSubmit}>
      <FormikInviteEventUsersForm onCloseClick={onCloseClick} />
    </Formik>
  );
};
