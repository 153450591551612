export const storageAvailable = () => {
  if (typeof window !== 'object') {
    return false;
  }
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const x = '__storage_test__';
    window.localStorage.setItem(x, x);
    window.localStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

export const getLocalStorageItem = (key: string) => {
  if (!storageAvailable()) return;

  const item = localStorage.getItem(key);
  try {
    const parsed = item && JSON.parse(item);
    return parsed;
  } catch (e) {
    localStorage.removeItem(key);
    console.error(e);
    return;
  }
};

export const setLocalStorageItem = (key: string, data: unknown) => {
  if (!storageAvailable()) return;

  try {
    const str = JSON.stringify(data);
    localStorage.setItem(key, str);
  } catch (e) {
    console.error(e);
  }
};

export const removeLocalStorageItem = (key: string) => {
  if (storageAvailable()) {
    localStorage.removeItem(key);
  }
};
