import { useCallback } from 'react';

import {
  NeverNullDelimitedArrayParam,
  NeverNullNumberParam,
  NeverNullStringParam,
  useQueryParams,
  WoltPageSizeParam,
} from 'modules/react-query-params';

export const useAdminsQueryParams = () => {
  const query = useQueryParams({
    id: NeverNullStringParam,
    ids: NeverNullDelimitedArrayParam,
    page: NeverNullNumberParam,
    page_size: WoltPageSizeParam,
  });
  const [params, setReactQueryParams] = query;

  const setQueryParams: typeof setReactQueryParams = useCallback(
    (queries) => {
      const shouldResetPage = 'page_size' in queries;

      setReactQueryParams(({ page, ...staledQueries }) => ({
        ...staledQueries,
        page: shouldResetPage ? 1 : page,
        ...queries,
      }));
    },
    [setReactQueryParams],
  );
  return [params, setQueryParams] satisfies typeof query;
};
