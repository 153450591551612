import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useMemo } from 'react';
import { Column, DataTable } from '@creditornot/cb-data-table';
import styled from 'styled-components';
import { Tag } from '@creditornot/cb-tag';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { InfoCircleFilled } from '@creditornot/cb-icons';
import { typographyCss } from '@creditornot/cb-ingredients';

import { ApiErrorNotification, DataCellContent, ResponsiveContainer, RtlCaret } from 'components';
import { useI18n } from 'i18n';
import { Group } from 'modules/groups/types';
import { PaginatedData } from 'types';

interface Props {
  data: PaginatedData<Group> | undefined;
  onGroupClick: (groupId: string) => void;
  isLoading: boolean;
  error: unknown;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

const Content = styled.span`
  ${typographyCss.Body3()}
  max-width: 280px;
  display: inline-block;
`;

const StyledInfoCircleFilled = styled(InfoCircleFilled)`
  width: 16px;
  height: 16px;
  margin-inline-start: 8px;
  vertical-align: inherit;
  fill: ${color.textSubdued};
`;

const CaretEnd = styled(RtlCaret).attrs({ alignment: 'end' })`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  fill: ${color.textSubdued};
`;

const ErrorContainer = styled(ResponsiveContainer)`
  padding-top: 22px;
  padding-bottom: 22px;
`;

export const GroupsDataTable = ({
  data,
  onGroupClick,
  isLoading,
  error,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
}: Props) => {
  const { getLocalizedMessage } = useI18n();

  const columns: Column<Group, Record<string, unknown>>[] = useMemo(
    () => [
      {
        id: 'name',
        name: getLocalizedMessage('common.name'),
        render: ({ record: { name, description, active } }) => {
          return (
            <DataCellContent disabled={!active} subContent={description}>
              {name}
            </DataCellContent>
          );
        },
      },
      {
        id: 'members',
        name: getLocalizedMessage('groups.number-of-members'),
        render: ({ record: { user_count, active } }) => {
          return <DataCellContent disabled={!active}>{user_count}</DataCellContent>;
        },
      },
      {
        id: 'policies',
        name: getLocalizedMessage('common.policies'),
        render: ({ record: { policies, active } }) => {
          return (
            <DataCellContent disabled={!active}>
              {policies.length > 0 ? policies.map(({ name }) => name).join(', ') : '-'}
            </DataCellContent>
          );
        },
      },
      {
        id: 'status',
        name: getLocalizedMessage('common.status'),
        render: ({ record: { active } }) => {
          return active ? (
            <Tag variant="lightGreen">{getLocalizedMessage('groups.active-group-status')}</Tag>
          ) : (
            <Tag variant="lightBlack">{getLocalizedMessage('groups.inactive-group-status')}</Tag>
          );
        },
      },
      {
        id: 'auto-add',
        name: getLocalizedMessage('groups.auto-add', {
          infoBubble: (
            <Popover
              key="active-auto-add-group-description"
              placement="top"
              content={
                <Content>{getLocalizedMessage('groups.active-auto-add-group-description')}</Content>
              }
            >
              <StyledInfoCircleFilled />
            </Popover>
          ),
        }),

        render: ({ record: { add_to_all } }) => {
          return add_to_all ? (
            <Tag variant="lightGreen">{getLocalizedMessage('common.on')}</Tag>
          ) : (
            <Tag variant="lightBlack">{getLocalizedMessage('common.off')}</Tag>
          );
        },
      },
      {
        id: 'caret',
        name: '',
        style: {
          width: '7%',
        },
        render: () => {
          return <CaretEnd />;
        },
      },
    ],
    [getLocalizedMessage],
  );

  if (error) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={error} />
      </ErrorContainer>
    );
  }

  const hidePagination = isLoading || data?.total_items_count === 0;

  return (
    <DataTable
      horizontalPadding="wide"
      tableLayout="fixed"
      dataSource={data?.results ?? []}
      columns={columns}
      getRowKey={(dataItem) => dataItem.id}
      onRowClick={onGroupClick}
      loading={isLoading}
      emptyMessage={{
        title: getLocalizedMessage('groups.no-groups-found'),
      }}
      pagination={
        hidePagination
          ? undefined
          : {
              disableWhenLoading: true,
              pageCount: Math.ceil((data?.total_items_count ?? 0) / (data?.page_size ?? 1)),
              pageSize: pageSize,
              activePage: page,
              onPageChange,
              onPageSizeChange,
              pageSizeOptions: [10, 20, 50, 100],
            }
      }
    />
  );
};
