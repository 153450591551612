import { SVGProps } from 'react';

export const AcceptTermsImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="374"
    height="250"
    viewBox="0 0 374 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1343_378)">
      <path
        d="M257.853 160.348C257.887 162.257 257.229 164.375 256.156 166.216C254.978 168.264 253.281 170.035 251.411 171.007L193.961 200.521C190.359 202.361 184.542 202.396 180.94 200.556L122.797 171.111C120.858 170.139 119.126 168.299 117.949 166.181C116.945 164.375 116.321 162.327 116.356 160.452L117.083 90.1392C117.118 86.1114 120.061 81.2503 123.559 79.2712L180.594 48.0212C184.126 46.1114 189.875 46.1114 193.441 48.0212L250.546 79.2712C254.078 81.1809 256.987 86.042 257.021 90.1392L257.853 160.348Z"
        fill="#009DE0"
      />
      <path
        d="M256.155 166.216C254.978 168.264 253.281 170.035 251.411 171.007L193.96 200.521C190.359 202.361 184.541 202.396 180.94 200.556L122.796 171.111C120.857 170.139 119.126 168.299 117.948 166.181C119.056 163.334 121.169 160.695 123.558 159.375L180.593 128.125C184.125 126.181 189.874 126.181 193.441 128.125L250.545 159.375C252.935 160.729 255.047 163.368 256.155 166.216Z"
        fill="#004B70"
      />
      <path
        d="M240.018 87.2217C241.749 88.2287 243.204 90.6592 243.273 92.6731L243.55 111.319C243.585 113.333 242.165 114.201 240.399 113.194L205.25 93.5759C203.484 92.6037 202.064 90.1384 202.064 88.1245L201.96 69.3745C201.96 67.3606 203.38 66.5273 205.111 67.4995L240.018 87.2217Z"
        fill="#004B70"
      />
      <path
        d="M262.562 75.8682V83.8543C262.562 84.4098 262.181 84.9654 261.489 85.3473L244.936 94.0279C243.516 94.8612 241.161 94.8612 239.707 94.0279L203.103 74.0626C202.376 73.6459 202.03 73.0904 202.03 72.5696V64.5834C201.995 63.9932 202.376 63.4029 203.103 62.9862L219.24 54.3057C220.695 53.4723 223.015 53.4723 224.47 54.3057L261.523 74.3057C262.25 74.7223 262.597 75.3126 262.562 75.8682Z"
        fill="#004B70"
      />
      <path
        d="M262.562 83.6809V83.8545C262.562 84.4101 262.181 84.9656 261.488 85.3476L244.935 94.0281C243.516 94.8614 241.161 94.8614 239.706 94.0281L203.103 74.0628C202.376 73.6462 202.029 73.0906 202.029 72.5698V72.3962C202.064 71.8753 202.445 71.3545 203.103 70.9725L219.24 62.292C220.695 61.4587 223.015 61.4587 224.469 62.292L261.523 82.292C262.181 82.6739 262.527 83.1601 262.562 83.6809Z"
        fill="#0082A5"
      />
      <path
        d="M270.845 62.1107C265.971 45.01 254.513 19.5324 236.315 27.58C226.745 31.8135 239.584 38.4523 240.519 43.3888C241.453 48.3253 251.053 67.2292 261.804 73.3761C269.921 77.9548 273.657 71.7359 270.845 62.1107Z"
        fill="#E59065"
      />
      <path
        d="M252.898 95.6875C252.898 95.6875 271.026 94.6659 275.823 93.5134C279.464 92.6494 285.658 88.5114 287.642 82.3183C287.642 82.3183 306.026 81.3849 307.958 71.6779C309.891 61.971 306.079 58.5058 306.079 58.5058C306.079 58.5058 307.355 54.7908 307.055 50.5033C306.756 46.2158 304.075 45.0326 304.075 45.0326C304.075 45.0326 306.143 43.3391 305.771 37.7655C305.398 32.1919 294.933 27.376 286.394 26.6923C277.854 26.0085 267.844 27.1098 263.526 31.6947C259.251 36.2372 251.895 45.591 249.482 47.6239C247.069 49.6568 241.311 52.9421 242.623 71.5929C244.086 92.3018 252.898 95.6875 252.898 95.6875Z"
        fill="#E59065"
      />
      <path
        d="M301.096 41.9011C303.273 42.1367 304.952 40.9107 305.858 40.0198C305.691 43.9172 304.14 45.1873 304.14 45.1873C299.128 42.1388 284.448 40.1094 284.448 40.1094C291.889 39.5826 297.723 41.568 301.096 41.9011Z"
        fill="#D67D4C"
      />
      <path
        d="M242.712 70.2667C243.975 76.4917 246.569 82.1728 247.949 85.2285C248.576 86.6485 250.064 87.4772 251.56 87.3634L266.74 86.3557C277.216 85.6446 279.889 78.8584 279.742 76.4576C279.595 74.0567 278.816 66.3369 286.556 65.7702C294.296 65.2036 293.07 72.5182 295.234 74.1247C296.888 75.3835 304.377 74.2146 307.944 72.2359C305.46 81.4662 287.718 82.363 287.718 82.363C285.734 88.5561 279.54 92.6941 275.899 93.5581C271.102 94.7107 252.974 95.7322 252.974 95.7322C252.974 95.7322 244.247 92.3473 242.784 71.6384C242.746 71.1668 242.708 70.6951 242.712 70.2667Z"
        fill="#D67D4C"
      />
      <path
        d="M304.152 45.1709C304.152 45.1709 304.322 45.2583 304.619 45.4754C304.407 45.3448 304.322 45.2583 304.152 45.1709Z"
        fill="#D67D4C"
      />
      <path
        d="M295.462 51.7785C301.346 53.0361 304.915 55.1277 307.119 52.6215C307.008 55.2768 306.473 57.4996 306.209 58.2682C296.819 52.8202 282.318 49.9355 282.318 49.9355C282.318 49.9355 289.321 50.5185 295.462 51.7785Z"
        fill="#D67D4C"
      />
      <path
        d="M232.267 108.704L243.403 101.744L253.034 95.7544L252.991 95.7112L252.417 93.3062L251.107 87.4663L251.108 87.3806L244.146 56.5489L243.459 56.8849L212.504 71.7053L202.028 76.7438L202.044 88.1838C202.067 90.1978 203.453 92.6536 205.238 93.6138L225.255 104.822L232.267 108.704Z"
        fill="#E59065"
      />
      <path
        d="M252.981 95.7671L243.393 101.714L232.258 108.674L225.245 104.792L243.269 92.6725L247.355 89.9277L247.44 89.9285L251.097 87.4366L251.183 87.3517L251.226 87.395L251.269 87.3526L251.226 87.395L252.407 93.2764L252.896 95.6806L252.939 95.7238L252.981 95.7671Z"
        fill="#D67D4C"
      />
      <path
        d="M172.836 84.5489L173.148 106.25C172.698 107.986 171.347 109.827 169.823 110.625L133.67 130.382C132.181 131.25 130.553 130.729 130.692 128.577L130.761 108.542C130.796 108.056 131.177 107.535 131.592 107.327C131.731 107.257 131.835 107.223 131.973 107.257L150.881 111.111C151.158 111.146 151.435 111.077 151.712 110.938C151.989 110.764 152.301 110.521 152.578 110.209L172.386 84.9309C172.524 84.7573 172.663 84.6531 172.836 84.5489ZM169.65 82.2573C170.066 82.1878 170.1 82.8128 169.685 83.3337L151.885 105.66C151.158 106.459 150.327 107.118 149.288 107.084L131.661 103.924C131.177 103.854 131.488 102.917 132.042 102.604C140.631 97.9517 161.478 86.5281 169.65 82.2573Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1343_378">
        <rect width="374" height="250" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
