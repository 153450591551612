import * as t from 'io-ts';

export type TransactionLimits = t.TypeOf<typeof TransactionLimits>;
export const TransactionLimits = t.type({
  daily_transactions_amount: t.number,
  single_transaction_amount: t.number,
});

export type BlockedMerchants = t.TypeOf<typeof BlockedMerchants>;
export const BlockedMerchants = t.type({
  merchant_id: t.string,
  note: t.string,
});

export type MainInvoiceDocumentType = t.TypeOf<typeof MainInvoiceDocumentType>;
export const MainInvoiceDocumentType = t.keyof({
  invoice: null,
  payment_note: null,
  cash_accounting: null,
  payment_summary: null,
  tax_invoice: null,
});

export type ServiceFeeDocumentLocation = t.TypeOf<typeof ServiceFeeDocumentLocation>;
export const ServiceFeeDocumentLocation = t.keyof({
  main_document: null,
  separate_invoice: null,
  wolt_sub_invoice: null,
});

export type ServiceFeeVatExplanation = t.TypeOf<typeof ServiceFeeVatExplanation>;
export const ServiceFeeVatExplanation = t.keyof({
  '': null,
  reverse_charge: null,
  service_export: null,
});

export type LocalBankAccountNumber = t.TypeOf<typeof LocalBankAccountNumber>;
export const LocalBankAccountNumber = t.type({
  bank_account_number: t.string,
  display_name: t.string,
});

export type CountryStatus = t.TypeOf<typeof CountryStatus>;
export const CountryStatus = t.keyof({
  active: null,
  internal: null,
  disabled: null,
});

export type NetsuiteConfig = t.TypeOf<typeof NetsuiteConfig>;
export const NetsuiteConfig = t.type({
  bank_account_name: t.string,
  country_name: t.string,
  delivery_item_name: t.string,
  department_name: t.union([t.string, t.null]),
  discount_item_name: t.string,
  giftcard_item_name: t.string,
  purchase_item_name: t.string,
  separated_delivery_fee: t.boolean,
  subsidiary_id: t.union([t.number, t.null]),
  tips_item_name: t.string,
});

export type Products = t.TypeOf<typeof Products>;
export const Products = t.type({
  drive_api: t.boolean,
  drive_delivery_ui: t.boolean,
  subscription_benefit: t.boolean,
  wolt_for_work: t.boolean,
});

export type CountryConfig = t.TypeOf<typeof CountryConfig>;
export const CountryConfig = t.type({
  agreement_party_id: t.union([t.string, t.null]),
  blocked_merchants: t.array(BlockedMerchants),
  country_code: t.string,
  created_at: t.string,
  currency: t.string,
  default_corporate_credit_limit_amount: t.union([t.number, t.null]),
  default_corporate_invoice_fee_percentage: t.union([t.number, t.null]),
  default_corporate_payment_term_delay_days: t.union([t.number, t.null]),
  default_corporate_invoice_overdue_interest: t.union([t.number, t.null]),
  delivery_fee_sub_invoice_location: t.string,
  enabled_products: Products,
  invoice_header_explanation: t.string,
  id: t.string,
  include_giftcards_in_goods: t.boolean,
  include_main_document_order_list: t.boolean,
  include_main_document_vat_id: t.boolean,
  include_refunds_in_credit_amount: t.boolean,
  include_sub_invoice_bank_transfer_note: t.boolean,
  include_sub_invoice_issuer_details: t.boolean,
  is_tipping_enabled: t.boolean,
  is_electronic_invoicing_enabled: t.boolean,
  main_invoice_document_title: t.string,
  show_giftcard_vat: t.boolean,
  local_bank_account_numbers: t.array(LocalBankAccountNumber),
  main_invoice_document_type: MainInvoiceDocumentType,
  modified_at: t.string,
  show_tips_in_summary_section: t.boolean,
  netsuite_config: t.union([NetsuiteConfig, t.null]),
  requires_separate_merchant_invoices: t.boolean,
  requires_wolt_sub_invoices: t.boolean,
  send_invoices_to_merchants: t.boolean,
  service_fee_document_location: ServiceFeeDocumentLocation,
  service_fee_document_type: t.union([t.string, t.null]),
  service_fee_vat_explanation: ServiceFeeVatExplanation,
  service_fee_vat_percentage: t.union([t.number, t.null]),
  standalone_wolt_country: t.boolean,
  status: CountryStatus,
  transaction_limits: TransactionLimits,
  wolt_company_contact_email: t.union([t.string, t.null]),
  wolt_company_account_bic: t.string,
  wolt_company_account_iban: t.string,
  wolt_company_name: t.string,
});
