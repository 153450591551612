import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Tag } from '@creditornot/cb-tag';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Spinner } from '@creditornot/cb-progress';

import { DataList, EmptyState, ResponsiveContainer } from 'components';
import { LocalizedNumber } from 'i18n';
import { useCorporate } from 'modules/corporates';
import { VenuePayout } from 'modules/merchantPayouts/types';

import { ReportsDropdownActions } from './ReportsDropdownActions';
import { getStatus } from '../utils';

type InvoicesDataListProps = {
  venuePayouts: VenuePayout[];
  isLoading: boolean;
  corporateId: string;
};

type DataItemComponentProps = {
  payout: VenuePayout;
  corporateId: string;
};

const DataItem = styled.div`
  width: 100%;
  display: flex;
  font-weight: 700;
  align-items: center;
  color: ${color.text};
`;

const Container = styled.div`
  flex: 1;
`;

const SpinnerWrapper = styled(ResponsiveContainer)`
  padding-top: 22px;
  padding-bottom: 22px;
  justify-content: center;
`;

const Text = styled.div`
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  gap: 12px;
  color: ${color.text};
`;

const StyledTag = styled(Tag)``;

const Title = styled.div`
  ${typographyCss.Title1()}
`;

const Subtitle = styled.div`
  margin-top: 2px;
  margin-bottom: 6px;
  font-weight: normal;
  color: ${color.textSubdued};
`;

const DataItemComponent = ({ payout, corporateId }: DataItemComponentProps) => {
  const { formatMessage } = useIntl();
  const { data: corporate } = useCorporate();
  const {
    toAccount,
    transferStatus,
    startDate,
    endDate,
    payoutAmount,
    currency,
    interval,
    intervalNumber,
  } = payout;
  return (
    <DataItem>
      <Container>
        <Title>
          Sales Report {interval.replace('_', ' ')} {intervalNumber}
        </Title>
        <Subtitle>
          <FormattedDate value={startDate} />
          {' – '}
          <FormattedDate value={endDate} />
        </Subtitle>
        {toAccount && <Subtitle>****{toAccount}</Subtitle>}
        <Text>
          <LocalizedNumber value={payoutAmount} currency={currency} />
          <StyledTag size="small" variant={getStatus(transferStatus).color}>
            {formatMessage({
              id: `reports.status.${getStatus(transferStatus).key}`,
            })}
          </StyledTag>
        </Text>
      </Container>
      <ReportsDropdownActions
        report={payout}
        corporateId={corporateId}
        isJapanese={corporate?.country === 'JPN'}
      />
    </DataItem>
  );
};

export const ReportsDataList = ({
  venuePayouts,
  isLoading,
  corporateId,
}: InvoicesDataListProps) => {
  return (
    <DataList
      data={venuePayouts}
      getDataItemId={(payout) => payout.id}
      renderDataItem={(payout) => <DataItemComponent payout={payout} corporateId={corporateId} />}
      noData={
        isLoading ? (
          <SpinnerWrapper>
            <Spinner size="large" />
          </SpinnerWrapper>
        ) : (
          <EmptyState
            subtitle={<FormattedMessage id="reports.no-report-explanation" />}
            icon="invoice"
          >
            <FormattedMessage id="reports.no-report" />
          </EmptyState>
        )
      }
    />
  );
};
