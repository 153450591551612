import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useCallback, ReactNode } from 'react';
import styled from 'styled-components';
import { DataTable, Column, RowClickFn } from '@creditornot/cb-data-table';
import { FormattedDate } from 'react-intl';

import { useI18n } from 'i18n';
import { ApiErrorNotification, DataCellContent, ResponsiveContainer, RtlCaret } from 'components';
import { Corporate } from 'modules/corporates/types';
import { useFetchCorporatesWithPagination } from 'modules/corporates';
import { useDebounce } from 'modules/hooks';
import { CorporateStatusTag } from 'modules/corporates/components';
import { formatCamelCaseToSnakeCase } from 'utils';

import { ProductLineTag } from './ProductLineTag';
import { useCorporatesQueryParams } from './useCorporatesQueryParams';

interface Props {
  header?: ReactNode;
  onDataItemClick: (corporate: Corporate) => void;
}

const CaretEnd = styled(RtlCaret).attrs({ alignment: 'end' })`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  fill: ${color.textSubdued};
`;

const useColumns = (): Column<Corporate, Record<string, unknown>>[] => {
  const { getLocalizedMessage } = useI18n();

  return [
    {
      id: 'name',
      name: getLocalizedMessage('common.name'),
      render: ({ record: { name } }) => {
        return <DataCellContent>{name}</DataCellContent>;
      },
    },
    {
      id: 'businessId',
      name: getLocalizedMessage('common.business_id'),
      render: ({ record: { business_id } }) => {
        return <DataCellContent>{business_id}</DataCellContent>;
      },
    },
    {
      id: 'contactPerson',
      name: getLocalizedMessage('corporates.column-title.contact-person'),
      render: ({ record: { contact_name, contact_email } }) => {
        return <DataCellContent subContent={contact_email}>{contact_name}</DataCellContent>;
      },
    },
    {
      id: 'productLine',
      name: getLocalizedMessage('corporates.product-line'),
      render: ({ record: { products } }) => {
        return <ProductLineTag productLine={products} />;
      },
    },
    {
      id: 'status',
      name: getLocalizedMessage('common.status'),
      render: ({ record: { status } }) => {
        return <CorporateStatusTag status={status} />;
      },
    },
    {
      id: 'statusModified',
      name: getLocalizedMessage('corporates.status-modified-at'),
      render: ({ record: { status_modified_at } }) => {
        return (
          <DataCellContent>
            <FormattedDate
              value={new Date(status_modified_at)}
              year="numeric"
              month="numeric"
              day="numeric"
              hour="numeric"
              minute="numeric"
            />
          </DataCellContent>
        );
      },
    },
    {
      id: 'caret',
      name: '',
      style: {
        width: '7%',
      },
      render: () => {
        return <CaretEnd />;
      },
    },
  ];
};

const ErrorContainer = styled(ResponsiveContainer)`
  padding-top: 22px;
  padding-bottom: 22px;
`;

export const CorporateDataTable = ({ header, onDataItemClick }: Props) => {
  const columns = useColumns();
  const { getLocalizedMessage } = useI18n();
  const {
    params: { page, page_size, country, search, corporate_status, products },
    setQueryParams,
  } = useCorporatesQueryParams();
  const debouncedSearch = useDebounce(search, 500);
  const { data, isLoading, error } = useFetchCorporatesWithPagination({
    page,
    page_size,
    country_code: country,
    name: debouncedSearch,
    statuses: corporate_status?.map(formatCamelCaseToSnakeCase),
    products: products?.map(formatCamelCaseToSnakeCase),
  });

  const handleDataRowClick: RowClickFn<Corporate> = useCallback(
    (_, corporate) => {
      onDataItemClick(corporate);
    },
    [onDataItemClick],
  );

  if (error) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={error} />
      </ErrorContainer>
    );
  }

  const hidePagination = isLoading || data?.total_items_count === 0;

  return (
    <DataTable
      horizontalPadding="wide"
      header={header}
      tableLayout="fixed"
      dataSource={data?.results ?? []}
      columns={columns}
      getRowKey={(dataItem) => dataItem.id}
      loading={isLoading}
      emptyMessage={{
        title: getLocalizedMessage('corporates.no-corporates'),
      }}
      onRowClick={handleDataRowClick}
      pagination={
        hidePagination
          ? undefined
          : {
              disableWhenLoading: true,
              pageCount: Math.ceil((data?.total_items_count ?? 0) / (data?.page_size ?? 1)),
              pageSize: page_size,
              activePage: page,
              onPageChange: (p) => setQueryParams({ page: p }),
              onPageSizeChange: (p) => {
                setQueryParams({ page: 1, page_size: p });
              },
              pageSizeOptions: [10, 20, 50, 100],
            }
      }
    />
  );
};
