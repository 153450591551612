import { useField } from 'formik';
import styled from 'styled-components';
import { RadioCard } from '@creditornot/cb-radio';

import { useI18n } from 'i18n';

type Props = {
  className?: string;
};

const Container = styled.div`
  & + & {
    margin-top: 12px;
  }
`;

export const BillingCycleField: React.FC<Props> = ({ className }) => {
  const { getLocalizedMessage } = useI18n();
  const [{ name, value, onChange, onBlur }] = useField<string>('billing_twice_a_month');

  return (
    <div className={className}>
      {[
        {
          label: getLocalizedMessage('corporate-form.billing_cycle-monthly'),
          value: 'false',
          subContent: getLocalizedMessage('corporate-form.billing_cycle-monthly-explanation'),
        },
        {
          label: getLocalizedMessage('corporate-form.billing_cycle-twice_a_month'),
          value: 'true',
          subContent: getLocalizedMessage('corporate-form.billing_cycle-twice_a_month-explanation'),
        },
      ].map((option) => (
        <Container key={JSON.stringify(option)}>
          <RadioCard
            name={name}
            checked={option.value === value}
            label={option.label}
            subContent={option.subContent}
            value={option.value}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Container>
      ))}
    </div>
  );
};
