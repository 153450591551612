import { createContext } from 'react';

import { ProductTourContextProps } from './types';

export default createContext<ProductTourContextProps>({
  start: () => {},
  end: () => {},
  gotoStep: () => {},
  isInTourMode: false,
  step: undefined,
});
