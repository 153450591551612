import { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { Button } from '@creditornot/cb-button';
import { Checkmark } from '@creditornot/cb-icons';
import { typographyCss } from '@creditornot/cb-ingredients';

import { ApiErrorNotification, EmptyState, FormFooter, ResponsiveContainer } from 'components';
import { useCreateCountryConfig } from 'modules/country-configs';
import { breakpoints } from 'modules/media';

import { CountryConfigFormFields } from './CountryConfigFormFields';
import { formatCreateCountryConfigFormValuesToSubmitData } from './utils';
import { CountryConfigFormValues } from './types';

const Root = styled.div`
  width: 100%;
  max-width: 783px;
  margin: 0 auto auto;
  padding-bottom: 24px;

  @media (max-width: ${breakpoints.medium}px) {
    margin-top: 0;
  }
`;

const Section = styled(ResponsiveContainer)`
  padding-top: 42px;
  flex-direction: column;

  @media (max-width: ${breakpoints.medium}px) {
    padding: 24px;
  }
`;

const Title = styled.h6`
  ${typographyCss.Heading6()}
`;

const StyledForm = styled(Form)`
  padding: 24px 24px 0;

  @media (max-width: ${breakpoints.medium}px) {
    padding: 12px 8px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 192px;
  margin-inline-end: 24px;
  justify-content: flex-end;

  @media (max-width: ${breakpoints.small}px) {
    margin-inline-end: 0;
    width: unset;
  }
`;

interface Props {
  countryName?: string;
  countryFlag?: string;
  countryCode?: string;
  currency?: string;
  onCreateSuccess: () => void;
}

export const CreateCountryConfigForm = ({
  countryName,
  countryFlag,
  countryCode,
  currency,
  onCreateSuccess,
}: Props) => {
  const [showForm, setShowForm] = useState(false);
  const createCountryConfig = useCreateCountryConfig();

  const initialValues: CountryConfigFormValues = {
    agreement_party_id: '',
    currency: currency || 'EUR',
    default_corporate_credit_limit_amount: '1000',
    default_corporate_invoice_fee_percentage: '0',
    default_corporate_payment_term_delay_days: '30',
    default_corporate_invoice_overdue_interest: '16',
    enabled_products: {
      drive_api: true,
      drive_delivery_ui: true,
      wolt_for_work: true,
      subscription_benefit: true,
    },
    include_main_document_order_list: false,
    include_main_document_vat_id: false,
    include_sub_invoice_bank_transfer_note: false,
    include_sub_invoice_issuer_details: false,
    is_tipping_enabled: false,
    local_bank_account_numbers: [],
    main_invoice_document_type: 'invoice',
    netsuite_config: {
      bank_account_name: '',
      country_name: '',
      delivery_item_name: '',
      department_name: '',
      discount_item_name: '',
      giftcard_item_name: '',
      purchase_item_name: '',
      separated_delivery_fee: false,
      subsidiary_id: '',
      tips_item_name: '',
    },
    requires_separate_merchant_invoices: false,
    requires_wolt_sub_invoices: false,
    send_invoices_to_merchants: false,
    service_fee_document_location: 'main_document',
    service_fee_vat_explanation: '',
    service_fee_vat_percentage: '0',
    standalone_wolt_country: false,
    status: 'active',
    transaction_limits: { daily_transactions_amount: '200', single_transaction_amount: '50' },
    wolt_company_account_bic: '',
    wolt_company_account_iban: '',
    wolt_company_name: '',
  };

  return (
    <Root>
      {!showForm ? (
        <EmptyState
          icon="policy"
          subtitle={`It seems like ${countryName} hasn't been configured yet.`}
          actionButtonText={`Configure ${countryName}`}
          onActionButtonClick={() => setShowForm(true)}
        >
          No configuration found
        </EmptyState>
      ) : (
        <div>
          <Section>
            <Title>{`${countryName} ${countryFlag}`}</Title>
          </Section>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              if (!countryCode || !currency) {
                actions.setStatus({ error: 'Country code or currency is missing' });
                return;
              }

              return createCountryConfig(
                formatCreateCountryConfigFormValuesToSubmitData(values, countryCode, currency),
              )
                .then(() => {
                  actions.setStatus({ submitSucceeded: true });
                  actions.setSubmitting(false);

                  setTimeout(() => {
                    onCreateSuccess();
                  }, 500);
                })
                .catch((error) => {
                  actions.setSubmitting(false);
                  actions.setStatus({ error });
                });
            }}
          >
            {({ isSubmitting, status, handleSubmit, isValid }) => (
              <>
                <StyledForm>
                  <CountryConfigFormFields alwaysShowError={true} currency={currency} />
                </StyledForm>

                {status?.error && <ApiErrorNotification error={status.error} />}

                <FormFooter
                  hideBorder
                  rightContent={
                    <ButtonWrapper>
                      <Button
                        stretch
                        size="large"
                        onClick={() => handleSubmit()}
                        icon={status?.submitSucceeded && <Checkmark />}
                        disabled={isSubmitting || !isValid || status?.submitSucceeded}
                        loading={isSubmitting}
                        variant="blue"
                      >
                        Create
                      </Button>
                    </ButtonWrapper>
                  }
                />
              </>
            )}
          </Formik>
        </div>
      )}
    </Root>
  );
};
