import { useCallback } from 'react';

import { useSubscriptionModalsController } from 'views/subscription/hooks/useSubscriptionModalsController';
import { useSubscriptionUsersQueryParams } from 'views/subscription/hooks/useSubscriptionUsersQueryParams';
import { SubscriptionInvite, SubscriptionUser } from 'modules/subscription/types';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { subscriptionView } from 'telemetry/Avo';

import { SubscriptionListHeader } from './SubscriptionListHeader';
import { SubscriptionUserList } from './SubscriptionUserList';
import { SubscriptionInviteList } from './SubscriptionInviteList';

interface UsersProps {
  usersData?: SubscriptionUser[] | null;
  usersLoading: boolean;
  usersError: any;
  usersDataItemProps: {
    onOpenDeleteUserModal: (id: string) => void;
  };
  usersPageCount?: number;
  usersCount?: number;
}

interface InvitesProps {
  invitesData?: SubscriptionInvite[] | null;
  invitesLoading: boolean;
  invitesError: any;
  invitesDataItemProps: {
    onOpenDeleteInviteModal: (id: string) => void;
    onOpenResendInviteModal: (id: string) => void;
  };
  invitesPageCount?: number;
  invitesCount?: number;
}

type Props = UsersProps & InvitesProps;

export const SubscriptionList = ({
  usersData,
  usersLoading,
  usersError,
  usersDataItemProps,
  usersPageCount,
  usersCount,
  invitesData,
  invitesLoading,
  invitesError,
  invitesDataItemProps,
  invitesPageCount,
  invitesCount,
}: Props) => {
  const [{ showInvites }] = useSubscriptionUsersQueryParams();
  const { openDeleteUsersModal, openDeleteInvitesModal, openResendInvitesModal } =
    useSubscriptionModalsController();

  const handleDeleteUsersClick = useCallback(() => {
    openDeleteUsersModal();
  }, [openDeleteUsersModal]);

  const handleDeleteInvitesClick = useCallback(() => {
    openDeleteInvitesModal();
  }, [openDeleteInvitesModal]);

  const handleResendInvitesClick = useCallback(() => {
    openResendInvitesModal();
  }, [openResendInvitesModal]);

  return (
    <>
      <ViewEventComponent event={subscriptionView} />
      <SubscriptionListHeader
        onDeleteUsersClick={handleDeleteUsersClick}
        onDeleteInvitesClick={handleDeleteInvitesClick}
        onResendInvitesClick={handleResendInvitesClick}
        invitesCount={invitesCount}
        usersCount={usersCount}
      />
      {showInvites ? (
        <SubscriptionInviteList
          data={invitesData}
          loading={invitesLoading}
          error={invitesError}
          dataItemProps={invitesDataItemProps}
          pageCount={invitesPageCount}
        />
      ) : (
        <SubscriptionUserList
          data={usersData}
          loading={usersLoading}
          error={usersError}
          dataItemProps={usersDataItemProps}
          pageCount={usersPageCount}
        />
      )}
    </>
  );
};
