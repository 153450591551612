import styled from 'styled-components';
import { Alert } from '@creditornot/cb-alert';

import { LocalizedMessage } from 'i18n';

type PricingInfoAlertProps = {
  isCustom: boolean;
  className?: string;
};

const StyledInfoBox = styled(Alert).attrs({ variant: 'info', size: 'small' })``;

export const PricingInfoAlert = ({ isCustom, className }: PricingInfoAlertProps) => {
  return (
    <StyledInfoBox className={className}>
      {isCustom ? (
        <LocalizedMessage messageKey="corporate-form.delivery-price.info-custom" />
      ) : (
        <LocalizedMessage messageKey="corporate-form.delivery-price.info" />
      )}
    </StyledInfoBox>
  );
};
