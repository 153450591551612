import * as t from 'io-ts';

export type SpendingLimitPeriod = t.TypeOf<typeof SpendingLimitPeriod>;
export const SpendingLimitPeriod = t.keyof({
  day: null,
  week: null,
  month: null,
  quarter: null,
  year: null,
});
