import styled from 'styled-components';
import { Button } from '@creditornot/cb-button';
import { useIntl } from 'react-intl';

import { FormRowV2 as Row } from 'components';

import { RenderMode } from '../types';

type Props = {
  renderMode: RenderMode;
  onSaveClick?: () => void;
  onCancelClick?: () => void;
  dirty?: boolean;
  isSubmitting?: boolean;
};

const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const SaveCancelButtons = ({
  renderMode,
  onSaveClick,
  onCancelClick,
  dirty,
  isSubmitting,
}: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Row
      title=""
      content={
        renderMode === 'edit' && (
          <ButtonsContainer>
            <Button
              type="button"
              variant="lightBlue"
              size="large"
              onClick={onCancelClick}
              disabled={isSubmitting}
            >
              {formatMessage({ id: 'common.cancel' })}
            </Button>
            <Button
              type="submit"
              variant="blue"
              size="large"
              onClick={onSaveClick}
              disabled={!dirty || isSubmitting}
              loading={isSubmitting}
            >
              {formatMessage({ id: 'common.save' })}
            </Button>
          </ButtonsContainer>
        )
      }
    />
  );
};
