import { Column } from '@creditornot/cb-data-table';

import { LocalizedMessage, LocalizedNumber, useI18n } from 'i18n';
import { DataCellContent } from 'components';
import { EventUser } from 'modules/events/types';

import { ActionButtons } from '../ActionButtons';
import { EventUserStatus } from '../EventUserStatus';

type Props = {
  onDeleteClick?: (eventUser: EventUser) => void;
  onResendInviteClick?: (eventUser: EventUser) => void;
  eventBudget: number;
  currency: string | undefined;
};

export const useEventUsersColumns = (): Column<EventUser, Props>[] => {
  const { getLocalizedMessage } = useI18n();
  return [
    {
      id: 'name',
      name: getLocalizedMessage('common.name'),
      render: ({ record: { full_name } }) => {
        return <DataCellContent>{full_name || '–'}</DataCellContent>;
      },
    },
    {
      id: 'email',
      name: getLocalizedMessage('common.email'),
      render: ({ record: { email } }) => {
        return <DataCellContent>{email}</DataCellContent>;
      },
    },
    {
      id: 'status',
      name: getLocalizedMessage('events.participants.voucher-column-title'),
      render: ({ record: { status } }) => {
        return <EventUserStatus status={status} />;
      },
    },
    {
      id: 'usage',
      name: getLocalizedMessage('events.participants.usage-column-title'),
      render: ({
        eventBudget,
        currency,
        record: { purchase_total_amount, usage_purchase_count },
      }) => {
        return (
          <DataCellContent
            subContent={
              <LocalizedMessage
                messageKey="events.participants.number-of-purchases"
                values={{ number: usage_purchase_count }}
              />
            }
          >
            <LocalizedNumber value={purchase_total_amount} currency={currency} />
            {' / '}
            <LocalizedNumber value={eventBudget} currency={currency} />
          </DataCellContent>
        );
      },
    },
    {
      id: 'actions',
      name: '',
      alignContent: 'right',
      render: ({ onDeleteClick, onResendInviteClick, record }) => {
        if (!onDeleteClick || record.status === 'used' || !onResendInviteClick) {
          return null;
        }

        return (
          <ActionButtons
            onDeleteClick={() => onDeleteClick(record)}
            onResendInviteClick={
              record.status == 'pending' ? () => onResendInviteClick(record) : undefined
            }
          />
        );
      },
    },
  ];
};
