import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { FormHeader, FormRow, InputContainer } from '@creditornot/cb-form';
import { Alert } from '@creditornot/cb-alert';
import { rem } from 'polished';
import { useFormikContext } from 'formik';
import { DiscountType } from '@creditornot/daas-core-api-client';

import { LocalizedMessage, useI18n } from 'i18n';
import { breakpoints } from 'modules/media';
import {
  PricingInfoAlert,
  DeliveryDistanceField,
  DeliveryPriceDiscountField,
  DeliveryPriceField,
  DistanceInMetersField,
  FlatDiscountField,
  MerchantIdField,
  PercentageDiscountField,
  VenueIdField,
} from 'modules/corporates/components';

import { CreateCorporateFormValues } from '../types';

interface Props {
  currency: string;
}

const StyledDiscountContainer = styled(InputContainer)`
  & + & {
    margin-top: 24px;
  }

  margin-top: 36px;
`;

const Root = styled.div`
  border-bottom: 1px solid ${color.border};
`;

const StyledFormHeader = styled(FormHeader)`
  border-top: 1px solid ${color.border};
`;

const StyledAlert = styled(Alert)`
  margin: ${rem(24)};
`;

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: ${rem(24)};
  }
`;

const DistanceInMetersInputContainer = styled(InputContainer)`
  margin-top: ${rem(8)};
`;

const StyledPricingInfoAlert = styled(PricingInfoAlert)`
  margin-top: ${rem(8)};
`;

const StyledFormRow = styled(FormRow)`
  @media (max-width: ${breakpoints.medium}px) {
    flex-direction: column;

    & > div {
      margin-bottom: 8px;
    }
  }
`;

export const DriveDeliverySettingsFormSection = ({ currency }: Props) => {
  const { getLocalizedMessage } = useI18n();
  const {
    values: { discount_type, delivery_distance, delivery_price_type },
  } = useFormikContext<CreateCorporateFormValues>();

  return (
    <Root>
      <StyledAlert variant="info" size="medium">
        <LocalizedMessage messageKey="corporate-form.delivery-settings.info-alert" />
      </StyledAlert>
      <StyledFormHeader horizontalPadding>
        <LocalizedMessage messageKey="corporate-form.delivery-settings-section-title" />
      </StyledFormHeader>
      <StyledFormRow
        horizontalPadding
        labelHtmlFor="merchant_id"
        label={getLocalizedMessage('corporate-form.delivery-settings.merchant-id')}
        required
        labelAlign="top"
      >
        <StyledInputContainer
          hint={getLocalizedMessage('corporate-form.delivery-settings.merchant-id-explanation')}
        >
          <MerchantIdField />
        </StyledInputContainer>
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        labelHtmlFor="venue_id"
        label={getLocalizedMessage('corporate-form.delivery-settings.venue-id')}
        required
        labelAlign="top"
      >
        <StyledInputContainer
          hint={getLocalizedMessage('corporate-form.delivery-settings.venue-id-explanation')}
        >
          <VenueIdField />
        </StyledInputContainer>
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        labelHtmlFor="delivery_distance"
        label={getLocalizedMessage('corporate-form.delivery-settings.delivery-distance')}
        required
        labelAlign="top"
      >
        <DeliveryDistanceField />

        {delivery_distance === 'custom' && (
          <DistanceInMetersInputContainer
            labelHtmlFor="maximum_distance_in_meters"
            label={getLocalizedMessage('corporate-form.delivery-settings.distance-in-meters')}
          >
            <DistanceInMetersField />
          </DistanceInMetersInputContainer>
        )}
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        required
        label={getLocalizedMessage('corporate-form.delivery-fee')}
        labelHtmlFor="delivery_price_type"
      >
        <DeliveryPriceField />
        <StyledPricingInfoAlert isCustom={delivery_price_type === 'custom'} />
      </StyledFormRow>

      {delivery_price_type !== 'custom' && (
        <StyledFormRow
          horizontalPadding
          labelHtmlFor="discount_type"
          label={getLocalizedMessage('corporate-form.delivery-discount.discount')}
        >
          <DeliveryPriceDiscountField />
          <StyledInputContainer>
            {discount_type === DiscountType.Flat && (
              <StyledDiscountContainer
                hint={getLocalizedMessage('corporate-form.delivery-discount.flat-hint')}
              >
                <FlatDiscountField currency={currency} alwaysShowError />
              </StyledDiscountContainer>
            )}
            {discount_type === DiscountType.TotalPricePercentage && (
              <StyledDiscountContainer
                hint={getLocalizedMessage('corporate-form.delivery-discount.percentage-hint')}
              >
                <PercentageDiscountField alwaysShowError />
              </StyledDiscountContainer>
            )}
          </StyledInputContainer>
        </StyledFormRow>
      )}
    </Root>
  );
};
