import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { IconButton } from '@creditornot/cb-button';
import { useCallback } from 'react';
import { Empty } from '@creditornot/cb-placeholders';
import { MoreHorizontal } from '@creditornot/cb-icons';
import { colors, typographyCss } from '@creditornot/cb-ingredients';
import { FormattedMessage, useIntl } from 'react-intl';

import { env } from 'config';
import { DataList, Anchor, DropdownButton } from 'components';
import { UserV2 } from 'modules/users/types';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';

import { useAdminsModalsController } from '../../hooks';

interface Props {
  className?: string;
  users?: UserV2[] | undefined;
  selectedRowKeys: string[];
  onRowSelectionChange: (selectedRowKeys: string[], selectedRecords: UserV2[]) => void;
  error: unknown;
  loading: boolean;
}

type ComponentProps = {
  selectedRowKeys: string[];
};

const DataItem = styled.div<{
  isDisabled: boolean;
  isSelected: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  color: ${(props) => props.isDisabled && color.textDisabled};
  background-color: ${(props) => props.isSelected && colors.wolt12};
`;

const Container = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div`
  ${typographyCss.Body3()}
  color: ${color.textSubdued};
  margin-top: 4px;
`;

const UserTitle = ({
  user,
  shouldLinkToOpsTools,
}: {
  user: UserV2;
  shouldLinkToOpsTools: boolean;
}) => {
  const fullName = `${user.name.first} ${user.name.last}`;

  if (shouldLinkToOpsTools) {
    return (
      <Anchor
        target="_blank"
        rel="noopener noreferrer"
        href={`${env.OPS_TOOLS_URI}/users/${user.user_id}`}
      >
        {fullName}
      </Anchor>
    );
  }

  return <span>{fullName}</span>;
};

const StyledDropdownButton = styled(DropdownButton)`
  position: absolute;
  ${cssDirectionalValue({ ltr: 'right: 0;', rtl: 'left: 0;' })}
`;

const StyledRow = styled(Row)`
  padding-inline-end: 32px;
  position: relative;
`;

const useRenderDataItem = () => {
  const { openDeleteUserModal } = useAdminsModalsController();
  const { isWoltEmployee } = useIsUserWoltEmployee();

  const renderDataItem = (user: UserV2, { selectedRowKeys }: ComponentProps) => {
    const isSelected = selectedRowKeys ? selectedRowKeys.includes(user.user_id) : false;

    return (
      <DataItem isSelected={isSelected} isDisabled={false}>
        <Container>
          <StyledRow>
            <UserTitle user={user} shouldLinkToOpsTools={isWoltEmployee} />

            {!selectedRowKeys.length && (
              <StyledDropdownButton
                showCaret={false}
                placement="bottom-end"
                actions={[
                  {
                    label: <FormattedMessage id="common.delete" />,
                    onClick: () => openDeleteUserModal(user.user_id),
                  },
                ]}
              >
                <IconButton background="animated" size="small" variant="black">
                  <MoreHorizontal />
                </IconButton>
              </StyledDropdownButton>
            )}
          </StyledRow>

          <Text>{user.email}</Text>
        </Container>
      </DataItem>
    );
  };
  return renderDataItem;
};

export const AdminsDataList: React.FC<Props> = ({
  users,
  onRowSelectionChange,
  selectedRowKeys,
  ...rest
}) => {
  const renderDataItem = useRenderDataItem();
  const { formatMessage } = useIntl();

  const handleRowSelectionChange = useCallback(
    (_: UserV2, selectedItemIds: string[]) => {
      const selectedRecord = (users || []).reduce<UserV2[]>((obj, val) => {
        const isSelected = selectedRowKeys.includes(val.user_id);
        if (!isSelected) {
          obj.push(val);
        }
        return obj;
      }, []);
      onRowSelectionChange(selectedItemIds, selectedRecord);
    },
    [users, onRowSelectionChange, selectedRowKeys],
  );

  return (
    <DataList
      data={users}
      getDataItemId={(user) => user.user_id}
      noData={<Empty title={formatMessage({ id: 'admins.no-admins' })} />}
      onDataItemSelectionClick={handleRowSelectionChange}
      renderDataItem={renderDataItem}
      selectedDataItems={selectedRowKeys}
      renderDataItemProps={{ selectedRowKeys }}
      {...rest}
    />
  );
};
