export const getCurrentMonthDateRange = () => {
  const startDate = new Date();
  startDate.setDate(1);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date();
  return {
    startDate,
    endDate,
  };
};
