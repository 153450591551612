import styled from 'styled-components';
import { useCallback } from 'react';
import { useAuthState } from '@creditornot/wolt-auth/react';
import { useIntl } from 'react-intl';

import { View } from 'components';
import { useCorporate, useEditCorporate } from 'modules/corporates';
import { EditCorporateData } from 'modules/corporates/api';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { useCorporateV2 } from 'modules/corporates/useCorporateV2';

import { SignupV2Step1 } from './Step1/SignupV2Step1';
import { SignupV2Step3 } from './Step3/SignupV2Step3';
import { SignupV2Step2Container } from './Step2/SignupV2Step2Container';
import { CompanySettingsV2 } from '../CompanySettingsV2/CompanySettingsV2';

const StepsContainer = styled.div`
  padding: 60px 16px;
  max-width: 720px;
  margin-inline: auto;
`;

export const SignupV2 = () => {
  const { formatMessage } = useIntl();
  const { data: corporate } = useCorporate();
  const { data: corporateV2 } = useCorporateV2();
  const { user } = useAuthState();
  const editCorporate = useEditCorporate(['service-agreement']);
  const { isFetching } = useCorporate();
  const { isWoltEmployeeWithUpdate } = useIsUserWoltEmployee();

  const handleCorporateDetailsSubmit = useCallback(
    async (values: EditCorporateData) => {
      await editCorporate(values);
    },
    [editCorporate],
  );

  if (!corporate) {
    return null;
  }

  return (
    <View
      title={formatMessage({ id: 'signup.in-progress.title' }, { name: user?.name.first_name })}
      subtitle={formatMessage({ id: 'signup.in-progress.subtitle' }, { company: corporate.name })}
    >
      <StepsContainer>
        {corporate.status === 'details_pending' && (
          <SignupV2Step1
            corporate={corporate}
            isFetchingCorporate={isFetching}
            onSubmit={handleCorporateDetailsSubmit}
          />
        )}
        {corporate.status === 'contract_pending' && (
          <SignupV2Step2Container corporate={corporate} isFetchingCorporate={isFetching} />
        )}
        {corporate.status === 'verification_pending' && (
          <SignupV2Step3
            renderEditCorporateForm={
              isWoltEmployeeWithUpdate
                ? () => <CompanySettingsV2 corporate={corporate} corporateV2={corporateV2} />
                : undefined
            }
          />
        )}
      </StepsContainer>
    </View>
  );
};
