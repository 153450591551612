import { Route, Switch } from 'react-router-dom';

import { SubscriptionsView } from './SubscriptionsView';

const RootRoutes = () => {
  return (
    <Switch>
      <Route exact path="/:corporateId/subscriptions" component={SubscriptionsView} />
      <Route exact path="/:corporateId/subscriptions/:action" component={SubscriptionsView} />
    </Switch>
  );
};

export default RootRoutes;
