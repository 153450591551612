import { useCallback } from 'react';
import {
  DeliveryOrderApiListDeliveryOrdersRequest as ListDeliveryOrdersRequest,
  ListDeliveryOrdersResponse,
} from '@creditornot/wd-api-client';

import {
  useInfiniteQuery,
  useQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from 'modules/react-query';

import { deliveryOrderApiClient } from './api';

export const useFetchDeliveries = <T = ListDeliveryOrdersResponse>(
  params: ListDeliveryOrdersRequest,
  config?: UseQueryOptions<ListDeliveryOrdersResponse, unknown, T>,
) => {
  const queryFn = useCallback(async () => {
    const { data } = await deliveryOrderApiClient.listDeliveryOrders(params);
    return data;
  }, [params]);
  return useQuery({ queryKey: ['deliveries', params], queryFn, ...config });
};

export const useFetchDeliveriesInfinite = <T = ListDeliveryOrdersResponse>(
  params: ListDeliveryOrdersRequest,
  config?: UseInfiniteQueryOptions<ListDeliveryOrdersResponse, unknown, T>,
) => {
  const getNextPageParam = (lastPage: ListDeliveryOrdersResponse) => {
    if (!lastPage.orders.length || !lastPage.pagination?.nextPageToken) {
      // this set hasNextPage to false
      return undefined;
    }
    return lastPage.pagination.nextPageToken;
  };
  const queryFn = useCallback(
    async ({ pageParam = '' }) => {
      const { data } = await deliveryOrderApiClient.listDeliveryOrders({
        ...params,
        pageToken: pageParam,
      });
      return data;
    },
    [params],
  );
  return useInfiniteQuery({
    queryKey: ['deliveries-infinite', params],
    queryFn,
    getNextPageParam,
    ...config,
  });
};
