import { ComponentProps } from 'react';

import { useI18n } from 'i18n';
import { FormikTextareaField, FormikSwitch } from 'components';

export const FormikInviteEventUsersSaveMessageSwitch: React.VFC<
  Partial<ComponentProps<typeof FormikTextareaField>>
> = () => {
  const { getLocalizedMessage } = useI18n();

  return (
    <FormikSwitch
      name="saveDefaultMessage"
      label={getLocalizedMessage(
        'events.create-event-form.invite-participants.save-custom-message-toggle',
      )}
    />
  );
};
