import { useField } from 'formik';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { CheckboxCard } from 'components';

type Props = {
  className?: string;
};

export const PolicyAllowRestaurantsField = ({ className }: Props) => {
  const { formatMessage } = useIntl();
  const [{ value, name }, , { setValue }] = useField<boolean>('restaurant_purchases_allowed');
  const handleChange = useCallback(() => {
    setValue(!value);
  }, [setValue, value]);
  return (
    <CheckboxCard
      className={className}
      name={name}
      checked={value}
      label={formatMessage({ id: 'policies.allow-purchase-normal-venues' })}
      stretched
      onChange={handleChange}
    />
  );
};
