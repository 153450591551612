import { FieldValidator, useField } from 'formik';
import { Radio, RadioProps } from '@creditornot/cb-radio';
import styled from 'styled-components';

interface Option<T> {
  label: string;
  value: T;
}

interface Props<T> {
  className?: string;
  name: string;
  options: Option<T>[];
  radioProps?: Partial<RadioProps>;
  validate?: FieldValidator;
}

const Item = styled.div`
  & + & {
    margin-top: 8px;
  }
`;

const Root = styled.div``;

function FormikRadioInputsGroupField<T>({
  name,
  options,
  radioProps,
  validate,
  className,
}: Props<T>) {
  const [{ value }, , { setValue }] = useField<T>({ name, validate });

  return (
    <Root className={className}>
      {options.map((option, index) => {
        return (
          <Item key={index}>
            <Radio
              onClick={() => setValue(option.value)}
              name={name}
              checked={value === option.value}
              readOnly={true}
              {...radioProps}
            >
              {option.label}
            </Radio>
          </Item>
        );
      })}
    </Root>
  );
}

export default FormikRadioInputsGroupField;
