import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { useCallback } from 'react';

import { ResourcesColumns, ResponsiveContainer, View } from 'components';
import { LocalizedMessage } from 'i18n';
import { useCorporate } from 'modules/corporates';
import { useOpenEventTutorialVideo } from 'modules/events';
import { event } from 'modules/google-analytics';
import { breakpoints } from 'modules/media';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';
import { Resource, homeInteractionResources } from 'telemetry/Avo';
import { resources } from 'modules/resources';

import { BillingInformation } from './BillingInformation';
import { ServiceArgreement } from './ServiceArgreement';
import { ContractPending } from './ContractPending';
import { createEmailMessageHref } from './utils';
import { useOpenOnboardUsersTutorialVideo, useOpenOrderTutorialVideo } from './hooks';
import { useAddBillingInformationModal } from './useAddBillingInformationModal';
import { SelfServiceSignupProgress } from './SelfServiceSignupProgress';

const StyledBillingInformation = styled(BillingInformation)`
  margin: 64px auto;
`;

const StyledResourcesColumns = styled(ResourcesColumns)`
  margin-top: 64px;
  border-top: 1px solid ${color.border};

  @media (max-width: ${breakpoints.small}px) {
    margin-top: 48px;
  }
`;

const StyledServiceArgreement = styled(ServiceArgreement)`
  margin: 24px auto 0;

  @media (max-width: ${breakpoints.small}px) {
    margin-top: 0;
  }
`;

const StyledContractPending = styled(ContractPending)`
  margin: 24px auto 0;

  @media (max-width: ${breakpoints.small}px) {
    margin-top: 0;
  }
`;

const StyledSelfServiceSignupProgress = styled(SelfServiceSignupProgress)`
  margin: 48px auto 0;
  width: 100%;
`;

export const SelfServiceSignupHome = () => {
  const { data: corporate } = useCorporate();
  const openOrderTutorialVideo = useOpenOrderTutorialVideo();
  const openEventTutorialVideo = useOpenEventTutorialVideo();
  const openOnboardUsersTutorialVideo = useOpenOnboardUsersTutorialVideo();
  const homeInteractionResourcesEvent = useTelemetryInteractionEvent(homeInteractionResources);
  const handleOrderGuideClick = useCallback(() => {
    window.open(resources.guides.groupOrder);
    event({
      category: 'self_signup_dashboard',
      action: 'click',
      label: 'order_guide',
    });
  }, []);
  const handleContactClick = useCallback(() => {
    if (!corporate) return;
    window.open(createEmailMessageHref(corporate?.name));
  }, [corporate]);
  const addBillingInformationModal = useAddBillingInformationModal();
  const handleAddBillingInformationClick = useCallback(() => {
    addBillingInformationModal();
  }, [addBillingInformationModal]);
  const handleOpenOrderTutorialVideoClick = useCallback(() => {
    openOrderTutorialVideo();
    event({
      category: 'self_signup_dashboard',
      action: 'click',
      label: 'order_tutorial',
    });
  }, [openOrderTutorialVideo]);
  const handleOnboardUsersTutorialVideoClick = useCallback(() => {
    openOnboardUsersTutorialVideo();
    event({
      category: 'self_signup_dashboard',
      action: 'click',
      label: 'accepting_company_invite_tutorial',
    });
  }, [openOnboardUsersTutorialVideo]);
  const handleEventTutorialVideoClick = useCallback(() => {
    homeInteractionResourcesEvent({
      resource: Resource.WHAT_ARE_EVENTS,
    });
    openEventTutorialVideo();
    event({
      category: 'self_signup_dashboard',
      action: 'click',
      label: 'company_event_tutorial',
    });
  }, [homeInteractionResourcesEvent, openEventTutorialVideo]);
  const handleEventGuideClick = useCallback(() => {
    homeInteractionResourcesEvent({
      resource: Resource.LEARN_HOW_EVENTS_WORK,
    });
    window.open(resources.guides.events);
    event({
      category: 'self_signup_dashboard',
      action: 'click',
      label: 'company_event_guide',
    });
  }, [homeInteractionResourcesEvent]);

  if (!corporate) {
    return null;
  }

  return (
    <View
      title={<LocalizedMessage messageKey="views.dashboard.welcome-title" />}
      subtitle={<LocalizedMessage messageKey="views.dashboard.welcome-subtitle" />}
    >
      <StyledSelfServiceSignupProgress status={corporate.status} />
      <ResponsiveContainer>
        {corporate.status === 'details_pending' && (
          <StyledBillingInformation
            onAddBillingInformationClick={handleAddBillingInformationClick}
            onContactClick={handleContactClick}
          />
        )}
        {corporate.status === 'verification_pending' && (
          <StyledServiceArgreement onContactClick={handleContactClick} />
        )}
        {corporate.status === 'contract_pending' && (
          <StyledContractPending onContactClick={handleContactClick} />
        )}
      </ResponsiveContainer>
      {['contract_pending', 'verification_pending'].includes(corporate.status) && (
        <StyledResourcesColumns
          onEventTutorialVideoClick={handleEventTutorialVideoClick}
          onOnboardUsersTutorialVideoClick={handleOnboardUsersTutorialVideoClick}
          onOrderTutorialVideoClick={handleOpenOrderTutorialVideoClick}
          onOrderGuideClick={handleOrderGuideClick}
          onEventGuideClick={handleEventGuideClick}
        />
      )}
    </View>
  );
};
