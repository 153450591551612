import { color } from '@creditornot/cb-ingredients/design-tokens';
import { Spinner } from '@creditornot/cb-progress';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Root = styled.div`
  padding: 32px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingMessage = styled.span`
  display: inline-block;
  margin-inline-start: 10px;
  color: ${color.textSubdued};
`;

export const LoadingState = ({ children, className }: Props) => {
  return (
    <Root className={className}>
      <Spinner size="medium" />
      <LoadingMessage>{children}</LoadingMessage>
    </Root>
  );
};
