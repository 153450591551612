import { color } from '@creditornot/cb-ingredients/design-tokens';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  font-size: 14px;
  text-decoration: none;
  color: ${color.textBrand};

  :visited {
    color: ${color.textBrand};
  }

  :hover {
    color: ${color.textBrandHovered};
  }

  :active {
    color: ${color.textBrandSelected};
  }
`;

export default StyledLink;
