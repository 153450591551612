import { set } from 'date-fns';

import { CreateEventData, EditEventData, InviteUserToEventData } from 'modules/events/api';
import { Event } from 'modules/events/types';
import { convertCentsToDisplayAmount, convertFromAmountToCents } from 'utils';

import {
  CreateEventFormData,
  EditOnGoingEventFormData,
  EditUpComingEventFormData,
  InviteEventUsersFormData,
} from '../types';

export const formatEditUpcomingEventFormValuesToApiData = (
  {
    name,
    participant_budget_amount,
    description,
    end_date,
    start_date,
    end_minutes,
    start_minutes,
  }: EditUpComingEventFormData,
  currency: string,
): EditEventData => {
  const startDate = set(start_date, {
    hours: 0,
    minutes: Number.parseInt(start_minutes),
    seconds: 0,
  });
  const endDate = set(end_date, {
    hours: 0,
    minutes: Number.parseInt(end_minutes),
    seconds: 0,
  });

  return {
    name,
    participant_budget_amount: convertFromAmountToCents(participant_budget_amount, currency),
    description,
    end_time: endDate.toISOString(),
    start_time: startDate.toISOString(),
  };
};

export const formatEditOngoingEventFormValuesToApiData = ({
  description,
}: EditOnGoingEventFormData): EditEventData => {
  return {
    description,
  };
};

export const formatCreateEventFormValuesToApiData = (
  {
    name,
    participant_budget_amount,
    description,
    end_date,
    start_date,
    end_minutes,
    start_minutes,
  }: CreateEventFormData,
  currency: string,
): CreateEventData => {
  if (!start_date || !end_date) {
    throw new Error('start_date and end_date is missing');
  }

  const startDate = set(start_date, {
    hours: 0,
    minutes: Number.parseInt(start_minutes),
    seconds: 0,
  });
  const endDate = set(end_date, {
    hours: 0,
    minutes: Number.parseInt(end_minutes),
    seconds: 0,
  });

  return {
    name,
    participant_budget_amount: convertFromAmountToCents(participant_budget_amount, currency),
    description,
    end_time: endDate.toISOString(),
    start_time: startDate.toISOString(),
  };
};

export const formatInviteEventUsersFormValuesToInviteEventUsersApiData = ({
  eventId,
  emails,
  customMessage,
}: Pick<InviteEventUsersFormData, 'customMessage' | 'emails'> & {
  eventId: string;
}): InviteUserToEventData[] => {
  if (!emails) {
    throw new Error('Emails are missing');
  }
  return emails.map((email) => ({
    event_id: eventId,
    email,
    custom_message: customMessage,
  }));
};

export const formatEventDataToEditUpComingEventFormValues = (
  {
    name,
    description,
    start_time,
    end_time,
    participant_budget_amount,
  }: Pick<Event, 'name' | 'description' | 'start_time' | 'end_time' | 'participant_budget_amount'>,
  currency: string,
): EditUpComingEventFormData => {
  const startDate = new Date(start_time);
  const startMinutes = startDate.getHours() * 60 + startDate.getMinutes();
  const endDate = new Date(end_time);
  const endMinutes = endDate.getHours() * 60 + endDate.getMinutes();

  return {
    name,
    description,
    participant_budget_amount: convertCentsToDisplayAmount(participant_budget_amount, currency),
    start_minutes: startMinutes.toString(),
    start_date: startDate,
    end_minutes: endMinutes.toString(),
    end_date: endDate,
  };
};
