import { useEditCorporateV2 } from 'modules/corporates';

import { formatFormValuesBeforeSendingV2 } from './formatFormValues';
import { CorporateV2 } from './types';

export const useEditDiscounts = () => {
  const editCorporate = useEditCorporateV2();

  return (initialValues: CorporateV2, newValues: CorporateV2) =>
    editCorporate(formatFormValuesBeforeSendingV2(initialValues, newValues));
};
