import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useState, useEffect, useRef, useCallback } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from '@creditornot/cb-ingredients';
import { Cross } from '@creditornot/cb-icons';
import { IconButton } from '@creditornot/cb-button';
import { Spinner } from '@creditornot/cb-progress';

import { useI18n } from 'i18n';
import { isDefined } from 'utils';

export interface FileInputProps {
  onChange: (files: File) => void;
  onClear: () => void;
  onBlur?: () => void;
  accept?: string;
  invalid?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  value?: File | null;
  children?: React.ReactNode;
}

type Variant = 'grey' | 'black' | 'blue' | 'red';

const rootStyles: Record<Variant, FlattenSimpleInterpolation> = {
  black: css`
    border-color: ${color.border};
  `,
  grey: css`
    background-color: ${color.border};
    border-color: ${color.border};
  `,
  blue: css`
    border-color: ${color.borderSelected};
  `,
  red: css`
    border-color: ${color.borderNegative};
  `,
};

const Root = styled.div<{ variant: Variant }>`
  border-radius: 4px;
  border: 2px dashed ${color.border};
  min-height: 94px;
  position: relative;
  transition: all 250ms ease;
  width: 100%;
  z-index: 1;
  ${({ variant }) => rootStyles[variant]};
`;

const Overlay = styled.div`
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 250ms ease;
  width: 100%;
`;

const DragAndDropOverlay = styled(Overlay)<{ disabled: boolean }>`
  z-index: 9;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

const DraggingOverlay = styled(Overlay)`
  align-items: center;
  background-color: ${color.bg};
  color: ${color.textBrand};
  display: flex;
  justify-content: center;
  opacity: 0.8;
  z-index: 8;
`;

const SpinnerOverlay = styled(DraggingOverlay)`
  z-index: 11;
`;

const Input = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const Container = styled.div<{ fade: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          color: ${color.textDisabled};
        `
      : css`
          & label {
            cursor: pointer;
            color: ${color.textBrand};
            transition: color 350ms ease;

            &:hover {
              color: ${colors.wolt64};
            }

            &:active {
              color: ${color.textBrand};
            }
          }
        `}
  ${({ disabled, fade }) => {
    if (disabled)
      return css`
        z-index: 10;
      `;
    else if (fade)
      return css`
        z-index: 2;
        opacity: 0.4;
      `;
    else
      return css`
        z-index: 10;
      `;
  }}
`;

const ValueContainer = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  width: 100%;
`;

const InputContainer = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Value = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const LabelButton = styled.label`
  font-size: 14px;
  font-weight: 500;
  margin-inline-start: auto;
  display: inline-block;
`;

const Placeholder = styled.div`
  font-size: 14px;
  display: inline-block;
  margin-inline-start: 4px;
  color: ${color.textSubdued};
`;

const StyledIconButton = styled(IconButton)`
  margin-inline-start: 10px;
`;

const useOnDrag = (
  ref: React.MutableRefObject<HTMLElement | null>,
  {
    dragEnterHandler,
    dragLeaveHandler,
    dropHandler,
  }: {
    dragEnterHandler: (event: DragEvent) => void;
    dragLeaveHandler: (event: DragEvent) => void;
    dropHandler: (event: DragEvent) => void;
  },
) => {
  const counter = useRef(0);

  useEffect(() => {
    const dragEnterListener = (event: DragEvent) => {
      counter.current++;
      dragEnterHandler(event);
    };
    const dragLeaveListener = (event: DragEvent) => {
      counter.current--;
      if (counter.current === 0) {
        dragLeaveHandler(event);
      }
    };
    const dropListener = (event: DragEvent) => {
      counter.current = 0;
      dropHandler(event);
    };

    document.addEventListener('dragenter', dragEnterListener);
    document.addEventListener('dragleave', dragLeaveListener);
    document.addEventListener('drop', dropListener);

    return () => {
      document.removeEventListener('dragenter', dragEnterListener);
      document.removeEventListener('dragleave', dragLeaveListener);
      document.removeEventListener('drop', dropListener);
    };
  }, [ref, dragEnterHandler, dragLeaveHandler, dropHandler]);
};

const FileInput = ({
  onChange,
  onClear,
  onBlur,
  value = null,
  accept = '',
  invalid = false,
  disabled = false,
  children,
  isLoading,
}: FileInputProps) => {
  const [dragging, setDragging] = useState(false);
  const [fileOver, setFileOver] = useState(false);
  const rootRef = useRef<HTMLDivElement | null>(null);
  const { getLocalizedMessage } = useI18n();

  useOnDrag(rootRef, {
    dragEnterHandler: (e) => {
      e.preventDefault();

      setDragging(true);
    },
    dragLeaveHandler: (e) => {
      e.preventDefault();

      setDragging(false);
    },
    dropHandler: (e) => {
      e.preventDefault();

      setFileOver(false);
      setDragging(false);
      if (onBlur) onBlur();
      const data = e.dataTransfer;
      if (data) {
        onChange(data.files[0]);
      }
    },
  });

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        onChange(e.target.files[0]);
        if (onBlur) onBlur();
      }
    },
    [onBlur, onChange],
  );

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';

    setFileOver(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setFileOver(false);
  }, []);

  const variant = (
    [
      disabled ? 'grey' : undefined,
      dragging || isLoading ? 'blue' : undefined,
      invalid ? 'red' : undefined,
      'black',
    ] as (Variant | undefined)[]
  ).filter(isDefined)[0];

  return (
    <Root variant={variant} ref={rootRef}>
      {dragging && (
        <DragAndDropOverlay
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          disabled={disabled}
        />
      )}

      {dragging && (
        <DraggingOverlay>
          {fileOver
            ? getLocalizedMessage('components.file-input.drop-file-here')
            : getLocalizedMessage('components.file-input.drag-file-here')}
        </DraggingOverlay>
      )}

      {isLoading ? (
        <SpinnerOverlay>
          <Spinner size="large" />
        </SpinnerOverlay>
      ) : (
        <Container disabled={disabled} fade={dragging}>
          {value ? (
            <ValueContainer>
              <Value>{value.name}</Value>
              {!disabled && (
                <StyledIconButton onClick={onClear} size="tiny" variant="black">
                  <Cross />
                </StyledIconButton>
              )}
              <Input accept={accept} onChange={handleChange} type="file" id="file_input" />
              <LabelButton htmlFor="file_input">
                {getLocalizedMessage('components.file-input.change-file')}
              </LabelButton>
            </ValueContainer>
          ) : (
            <InputContainer>
              <Input accept={accept} onChange={handleChange} type="file" id="file_input" />
              <LabelButton htmlFor="file_input">
                {getLocalizedMessage('components.file-input.choose-a-file')}
              </LabelButton>
              <Placeholder>
                {getLocalizedMessage('components.file-input.or-drop-it-here')}
              </Placeholder>
            </InputContainer>
          )}
        </Container>
      )}
      {children}
    </Root>
  );
};

export default FileInput;
