import { useCallback } from 'react';

import { ConfirmationModal } from 'components';
import { LocalizedMessage } from 'i18n';
import { useResendEventInviteToUser } from 'modules/events';
import { EventUser } from 'modules/events/types';

type Props = {
  show: boolean;
  eventUsers: EventUser[];
  onSuccess: () => void;
  onClose: () => void;
};

export const ResendEventInviteModal = ({ show, onClose, eventUsers, onSuccess }: Props) => {
  const resendEventUserInvite = useResendEventInviteToUser();

  const handleConfirm = useCallback(async () => {
    const resendEventUserInvitePromises = eventUsers.map(({ invite_id }) =>
      resendEventUserInvite(invite_id),
    );
    return Promise.all(resendEventUserInvitePromises);
  }, [eventUsers, resendEventUserInvite]);

  return (
    <ConfirmationModal
      onClose={onClose}
      onConfirm={handleConfirm}
      show={show}
      content={
        eventUsers.length ? (
          <LocalizedMessage
            messageKey="events.resend-event-invitation-to-users.text"
            values={{
              emails: eventUsers.map(({ email }) => email).join(', '),
            }}
          />
        ) : null
      }
      title={
        eventUsers.length ? (
          <LocalizedMessage
            messageKey="events.resend-event-invitation-to-users.title"
            values={{ userCount: eventUsers.length }}
          />
        ) : null
      }
      confirmationButtonMessage={<LocalizedMessage messageKey="common.resend" />}
      confirmationButtonVariant="blue"
      onSuccess={() => setTimeout(onSuccess, 500)}
    />
  );
};
