import {
  CookieConsents,
  decodeCookieConsentsData,
  encodeCookieConsentsData,
  getCookieName,
  getCookieOptions,
} from '@creditornot/cwc-cookie-sdk';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useCookie } from 'react-use';

import { env } from 'config';
import { useCreateWoltPagesLink } from 'modules/common/hooks';

import { ManageCookieConsentsController } from './components';
import { useShowConsentsModal } from './useShowConsentsModal';

type GdrpConsentContextValue = {
  consents: CookieConsents;
  updateConsents: (consents: CookieConsents) => void;
  hasReceivedConsentEvent: boolean;
  openManageCookieConsentsModal: () => void;
};

const CookieConsentsContext = createContext<GdrpConsentContextValue | undefined>(undefined);

export const useCookieConsents = () => {
  const value = useContext(CookieConsentsContext);
  if (value === undefined) {
    throw new Error('You can use useCookieConsents only in a child of CookieConsentsProvider');
  }

  return value;
};

const woltCookieOptions = getCookieOptions('cookie-consents', env.COOKIE_SDK_DOMAIN);

export const CookieConsentsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [consents, setConsents] = useCookie(getCookieName('cookie-consents'));
  const [showManageCookieConsentsModal, setShowManageCookieConsentsModal] = useState(false);
  const hasReceivedConsentEvent = !!consents;
  const showConsentsModal = useShowConsentsModal();
  const createWoltPagesLink = useCreateWoltPagesLink();
  const shouldShowConsentsModal = showConsentsModal && !hasReceivedConsentEvent;
  const contentsData = consents ? decodeCookieConsentsData(consents) : undefined;

  const openManageCookieConsentsModal = useCallback(() => {
    setShowManageCookieConsentsModal(true);
  }, []);

  const updateConsents = useCallback(
    (consents: CookieConsents) => {
      setConsents(encodeCookieConsentsData(consents), woltCookieOptions);
    },
    [setConsents],
  );

  const acceptConsents = useCallback(() => {
    const allConsents = {
      functional: true,
      analytics: true,
      marketing: true,
    };
    setConsents(encodeCookieConsentsData(allConsents), woltCookieOptions);
  }, [setConsents]);

  const value = useMemo(
    () => ({
      hasReceivedConsentEvent,
      consents: contentsData ?? {
        functional: false,
        analytics: false,
        marketing: false,
      },
      updateConsents,
      openManageCookieConsentsModal,
    }),
    [contentsData, hasReceivedConsentEvent, openManageCookieConsentsModal, updateConsents],
  );
  return (
    <CookieConsentsContext.Provider value={value}>
      {children}

      <ManageCookieConsentsController
        initialConsents={value.consents}
        privacyPolicyUrl={createWoltPagesLink('privacy')}
        showConsentsModal={shouldShowConsentsModal}
        showManageCookieConsentsModal={showManageCookieConsentsModal}
        setShowManageCookieConsentsModal={setShowManageCookieConsentsModal}
        onSubmitCookieConsents={updateConsents}
        onAcceptDefaultConsentsClick={acceptConsents}
      />
    </CookieConsentsContext.Provider>
  );
};
