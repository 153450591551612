import { colors, typographyCss } from '@creditornot/cb-ingredients';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Button } from '@creditornot/cb-button';
import { WindowNew } from '@creditornot/cb-icons';
import { useCallback } from 'react';

import { homeInteractionOrderDelivery } from 'telemetry/Avo';
import { LottieComponent } from 'modules/lotties';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';

const Banner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${colors.wolt4};
  padding: 24px;
  border-radius: 8px;
`;

const BannerText = styled.span`
  ${typographyCss.Body3()}
  display: block;
  margin: 16px 0;
`;

const CourierWaving = styled(LottieComponent).attrs({
  animation: 'courierWaving',
})`
  max-width: 280px;
`;

export const AllDeliveriesCompleteBanner = ({ onClick }: { onClick: () => void }) => {
  const { formatMessage } = useIntl();
  const homeInteractionOrderDeliveryEvent = useTelemetryInteractionEvent(
    homeInteractionOrderDelivery,
  );

  const handleOrderDeliveryClick = useCallback(() => {
    onClick();
    homeInteractionOrderDeliveryEvent({});
  }, [onClick, homeInteractionOrderDeliveryEvent]);

  return (
    <Banner>
      <CourierWaving />
      <BannerText>{formatMessage({ id: 'deliveries.no-pick-up-order-banner-title' })}</BannerText>
      <Button
        size="small"
        variant="blue"
        icon={<WindowNew />}
        iconPosition="right"
        onClick={handleOrderDeliveryClick}
      >
        {formatMessage({ id: 'deliveries.order-delivery' })}
      </Button>
    </Banner>
  );
};
