import { useCallback } from 'react';
import { css } from 'styled-components';

import { useAppModals } from 'modules/app-modals';
import { breakpoints } from 'modules/media';

import { OrderTutorial } from '../OrderTutorial';

const modalStyle = css`
  max-width: 720px;

  @media (max-width: ${breakpoints.medium}px) {
    max-width: 100%;
  }

  overflow: auto;
`;

const ORDER_TUTORIAL_VIDEO = 'ORDER_TUTORIAL_VIDEO';

export const useOpenOrderTutorialVideo = () => {
  const { openModal, closeModal } = useAppModals();
  const handleCloseModal = useCallback(() => {
    closeModal(ORDER_TUTORIAL_VIDEO);
  }, [closeModal]);

  return useCallback(
    () =>
      openModal(ORDER_TUTORIAL_VIDEO, {
        modalStyle,
        onDismiss: handleCloseModal,
        children: <OrderTutorial onClose={handleCloseModal} />,
      }),

    [handleCloseModal, openModal],
  );
};
