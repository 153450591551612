import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { Tag } from '@creditornot/cb-tag';

interface Props {
  tagValues?: string[] | null;
  invalidValues?: string[] | null;
  handleClearClick: (value: string, index: number) => void;
  className?: string;
}

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 154px;
  margin: 0 24px;
  padding-top: 24px;
  padding-bottom: 16px;
  border-top: 1px solid ${color.border};
`;

const StyledTag = styled(Tag)`
  margin-inline-end: 8px;
  margin-bottom: 8px;
`;

export const TagsContainer: React.FC<Props> = ({
  tagValues,
  invalidValues,
  handleClearClick,
  className,
}) => {
  if (!tagValues?.length) {
    return null;
  }
  return (
    <Root className={className}>
      {tagValues?.map((value, index) => (
        <StyledTag
          variant={invalidValues?.includes(value) ? 'lightRed' : 'lightBlue'}
          key={`${value}-${index}`}
          onRemoveClick={() => handleClearClick(value, index)}
        >
          {value}
        </StyledTag>
      ))}
    </Root>
  );
};
