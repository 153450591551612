import { useCallback } from 'react';
import { Empty } from '@creditornot/cb-placeholders';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { useMedia } from 'modules/media';
import { UserV2 } from 'modules/users/types';
import { PaginatedData } from 'types/PaginatedData';
import { useAdminsQueryParams } from 'views/admins/hooks';

import { AdminsDataTable } from './AdminsDataTable';
import { AdminsDataList } from './AdminsDataList';

interface Props {
  usersData: PaginatedData<UserV2> | undefined | null;
  isLoading: boolean;
  error: unknown;
}

const Root = styled.div`
  display: flex;
  align-items: center;
  margin-top: 100px;
  justify-content: center;
`;

export const AdminsList: React.FC<Props> = ({ usersData, isLoading, error }) => {
  const { formatMessage } = useIntl();
  const mediumView = useMedia('medium');
  const [{ ids: userIdsQueryParam = [] }, setQueryParams] = useAdminsQueryParams();

  const handleRowSelectionChange = useCallback(
    (selectedRowKeys: string[]) => {
      setQueryParams({
        ids: selectedRowKeys,
      });
    },
    [setQueryParams],
  );

  const isEmpty = !error && usersData?.results.length === 0 && !isLoading;

  if (isEmpty) {
    return (
      <Root>
        <Empty title={formatMessage({ id: 'admins.no-admins' })} />
      </Root>
    );
  }

  if (mediumView) {
    return (
      <AdminsDataList
        users={usersData?.results}
        error={error}
        loading={isLoading}
        selectedRowKeys={userIdsQueryParam}
        onRowSelectionChange={handleRowSelectionChange}
      />
    );
  }

  return (
    <AdminsDataTable
      usersData={usersData}
      error={error}
      loading={isLoading}
      selectedRowKeys={userIdsQueryParam}
      onRowSelectionChange={handleRowSelectionChange}
    />
  );
};
