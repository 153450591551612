import { useField } from 'formik';
import { Select, SelectOptionComponentProps } from '@creditornot/cb-select';
import { isNil } from 'ramda';
import { MenuActionItem } from '@creditornot/cb-menu';

import { useConfigs } from 'modules/configs';

type Props = {
  onCountryChange?: (country: string) => void;
  disabled?: boolean;
};

const CustomOption: React.FC<
  SelectOptionComponentProps<{
    label: string;
    value: string;
    flag: string;
    selected: boolean;
  }>
> = ({ option, onClick }) => {
  return (
    <MenuActionItem
      icon={<>{option.flag}</>}
      variant="black"
      selected={option.selected}
      onClick={onClick}
    >
      {option.label}
    </MenuActionItem>
  );
};

export const CountryField = ({ disabled, onCountryChange }: Props) => {
  const { configs } = useConfigs();
  const [{ value }, , { setValue }] = useField<string>('country');

  return (
    <Select
      inputId="country"
      value={value}
      onChange={(value) => {
        if (isNil(value)) {
          return;
        }
        onCountryChange?.(value);
        setValue(value);
      }}
      disabled={disabled}
      options={
        configs?.countries
          ? configs?.countries.map((country) => ({
              label: country.name,
              value: country.code,
              selected: country.code === value,
              flag: country.flag,
            }))
          : undefined
      }
      optionComponent={CustomOption}
    />
  );
};
