import { LocalizedMessage } from 'i18n';

interface AccountingNoteRequirementProps {
  orderCommentRequired: boolean;
  short?: boolean;
}

export const AccountingNoteRequirement = ({
  orderCommentRequired,
  short,
}: AccountingNoteRequirementProps) => {
  if (!orderCommentRequired) {
    return <LocalizedMessage messageKey={'common.optional'} />;
  }

  return short ? (
    <LocalizedMessage messageKey={'groups.require-note'} />
  ) : (
    <LocalizedMessage messageKey="common.accounting-note-required" />
  );
};
