import { convertFromAmountToCents } from 'utils';
import { DistanceRange } from 'views/settings/CompanySettings/B2bConfigSettings/types';

export const convertDistanceRangesToPriceIncrements = (
  distanceRanges: DistanceRange[],
  currency: string,
) =>
  distanceRanges.map(({ fee, distanceMax, distanceMin }) => ({
    fee: !fee || isNaN(fee) ? 0 : convertFromAmountToCents(fee, currency),
    distance_min: distanceMin,
    distance_max: distanceMax,
  }));
