import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { intersection } from 'ramda';
import { MultiSelect } from '@creditornot/cb-select';
import { useIntl } from 'react-intl';

import { breakpoints } from 'modules/media';
import { fetchGroups } from 'modules/groups';
import { Group } from 'modules/groups/types';
import { useFetch } from 'modules/react-query';

import { OrderFilterOrderType, OrderFilterStatus, orderTypes, statuses } from './types';

type OrdersFilterProps = {
  corporateId: string;
  onChange: (params: {
    status: OrderFilterStatus[];
    groups: string[];
    types: OrderFilterOrderType[];
  }) => void;
  hasSubscriptionBenefit: boolean;
  status: string[] | undefined;
  groups: string[] | undefined;
  types: string[] | undefined;
};

type OrderFilterOptionsProps = {
  hasSubscriptionBenefit: boolean;
  groups: Group[] | undefined;
};

const Root = styled(MultiSelect)`
  width: 360px;

  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
  }
`;

const useOrderFilterOptions = ({ hasSubscriptionBenefit, groups }: OrderFilterOptionsProps) => {
  const { formatMessage } = useIntl();

  const filterOptions = useMemo(
    () => [
      {
        label: formatMessage({ id: 'orders.type-column-title' }),
        options: [
          { label: formatMessage({ id: 'orders.type.regular' }), value: 'purchase' },
          { label: formatMessage({ id: 'orders.type.gift-card' }), value: 'giftcard' },
          ...(hasSubscriptionBenefit
            ? [{ label: formatMessage({ id: 'orders.type.subscription' }), value: 'subscription' }]
            : []),
        ],
      },
      {
        label: formatMessage({ id: 'orders.status-column-title' }),
        options: [
          { label: formatMessage({ id: 'common.pre-order' }), value: 'pre-order' },
          { label: formatMessage({ id: 'common.status.active' }), value: 'active' },
          { label: formatMessage({ id: 'orders.delivered-status' }), value: 'delivered' },
        ],
      },
    ],
    [formatMessage, hasSubscriptionBenefit],
  );

  if (groups?.length) {
    return filterOptions.concat({
      label: formatMessage({ id: 'orders.filter.groups-title' }),
      options: groups.map(({ name, id }) => ({ label: name, value: id })),
    });
  }
  return filterOptions;
};

export const OrdersFilter = ({
  corporateId,
  onChange,
  hasSubscriptionBenefit,
  status = [],
  groups = [],
  types = [],
}: OrdersFilterProps) => {
  const { data: groupsData } = useFetch({
    queryKey: ['OrdersFilter', 'Groups', corporateId],
    queryFn: () => fetchGroups(corporateId),
  });
  const groupOptions = useOrderFilterOptions({
    hasSubscriptionBenefit,
    groups: groupsData,
  });

  const { formatMessage } = useIntl();

  const handleFilterValuesChange = useCallback(
    (values: string[]) => {
      onChange({
        status: intersection(values as OrderFilterStatus[], statuses),
        groups: intersection(
          values,
          (groupsData || []).map(({ id }) => id),
        ),
        types: intersection(values as OrderFilterOrderType[], orderTypes),
      });
    },
    [groupsData, onChange],
  );

  return (
    <Root
      value={[...status, ...groups, ...types]}
      options={groupOptions}
      onChange={handleFilterValuesChange}
      placeholder={formatMessage({ id: 'orders.all-orders' })}
    />
  );
};
