import styled, { CSSProperties } from 'styled-components';
import { ReactElement, ReactNode } from 'react';
import { pxToRem } from '@creditornot/cb-utils';
import { ArrowLeft, ArrowRight } from '@creditornot/cb-icons';
import { TextButton, TextButtonProps } from '@creditornot/cb-button';
import { FormattedMessage } from 'react-intl';
import { typographyCss } from '@creditornot/cb-ingredients';

import { breakpoints } from 'modules/media';
import { useLanguageDirection } from 'i18n';

type LayoutWithLottieProps = {
  lottie: ReactNode;
  title: ReactElement<typeof FormattedMessage>;
  description: ReactElement<typeof FormattedMessage>;
  buttonText: string;
  buttonProps?: TextButtonProps;
  buttonTextHasArrow: boolean;
};

const Root = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${pxToRem(128)} ${pxToRem(12)} ${pxToRem(40)};
  text-align: center;

  @media (max-width: ${breakpoints.medium}px) {
    padding-top: ${pxToRem(64)};
  }

  @media (max-width: ${breakpoints.small}px) {
    padding-top: ${pxToRem(32)};
  }
`;

const Title = styled.h5`
  ${typographyCss.Heading5()}
  margin-top: ${pxToRem(40)};
`;

const Description = styled.div`
  ${typographyCss.Body2()}
  max-width: 460px;
  margin-top: ${pxToRem(40)};
`;

const StyledTextButton = styled(TextButton)`
  display: flex;
  align-items: center;
  margin-top: ${pxToRem(40)};
  padding: 0 ${pxToRem(20)};
  justify-content: center;
  height: 48px;
`;

const arrowStyle: CSSProperties = { marginInlineStart: pxToRem(6) };

export const LayoutWithLottie = ({
  lottie,
  title,
  description,
  buttonText,
  buttonProps,
  buttonTextHasArrow,
}: LayoutWithLottieProps) => {
  const direction = useLanguageDirection();
  return (
    <Root>
      {lottie}
      <Title>{title}</Title>
      <Description>{description}</Description>
      <StyledTextButton {...buttonProps}>
        {buttonText}
        {buttonTextHasArrow && direction === 'ltr' ? (
          <ArrowRight style={arrowStyle} />
        ) : (
          <ArrowLeft style={arrowStyle} />
        )}
      </StyledTextButton>
    </Root>
  );
};
