import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { Switch, SwitchProps } from '@creditornot/cb-switch';
import { typographyCss } from '@creditornot/cb-ingredients';
import { color } from '@creditornot/cb-ingredients/design-tokens';

interface Props {
  name: string;
  label?: string;
  className?: string;
  explanation?: string;
  switchProps?: Partial<SwitchProps>;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 36px;
`;

const Label = styled.label<{ $disabled: boolean }>`
  ${typographyCss.Body3()};
  margin-inline-end: 8px;
  color: ${(props) => (props.$disabled ? color.textDisabled : 'inherit')};
`;

const Explanation = styled.small`
  ${typographyCss.Small()};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSwitch = styled(Switch)`
  display: inline-block;
`;

const FormikSwitch: React.FC<Props> = ({ explanation, name, className, switchProps, label }) => {
  const [{ value }, , { setValue }] = useField<boolean>(name);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(!value);
      switchProps?.onChange?.(event);
    },
    [setValue, switchProps, value],
  );

  const checked = typeof value === 'boolean' ? value : value === 'true';

  return (
    <Root className={className}>
      <Container>
        {label && (
          <Label htmlFor={name} $disabled={!!switchProps?.disabled}>
            {label}
          </Label>
        )}
        <StyledSwitch name={name} checked={checked} {...switchProps} onChange={handleChange} />
      </Container>
      {explanation && <Explanation>{explanation}</Explanation>}
    </Root>
  );
};

export default FormikSwitch;
