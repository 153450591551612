import * as React from 'react';
import styled from 'styled-components';
import { InputContainer } from '@creditornot/cb-form';
import { TextButton } from '@creditornot/cb-button';
import { Plus } from '@creditornot/cb-icons';

import { LocalizedMessage } from 'i18n';
import { FormikPolicySelectField } from 'modules/groups/components';

interface Props {
  className?: string;
  onCreatePolicyClick?: () => void;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledInputContainer = styled(InputContainer)`
  margin-top: auto;
`;

export const SelectPolicyFormSection = ({ className, onCreatePolicyClick }: Props) => {
  return (
    <Root className={className}>
      <InputContainer>
        <FormikPolicySelectField
          selectProps={{
            clearable: false,
          }}
          name="policy_ids"
        />
      </InputContainer>
      <StyledInputContainer>
        <TextButton icon={<Plus />} onClick={onCreatePolicyClick}>
          <LocalizedMessage messageKey="groups.create-a-new-policy" />
        </TextButton>
      </StyledInputContainer>
    </Root>
  );
};
