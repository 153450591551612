import { useCallback } from 'react';
import { Button } from '@creditornot/cb-button';

import { View } from 'components';
import { useFetchAgreementParties } from 'modules/agreement-parties';
import { useDebounce } from 'modules/hooks';

import AgreementPartiesDataTable from './AgreementPartiesDataTable';
import AgreementPartyModal from './AgreementPartyModal';
import { useAgreementPartiesQueryParams } from './useAgreementPartiesQueryParams';

const AgreementParties = () => {
  const [
    { create_agreement_party, edit_agreement_party, legal_name, page, page_size },
    setQueryParams,
  ] = useAgreementPartiesQueryParams();
  const debouncedLegalName = useDebounce(legal_name, 500);
  const { data, isLoading, refetch } = useFetchAgreementParties({
    page_size,
    page,
    legal_name: debouncedLegalName,
  });

  const handleCreateClick = useCallback(() => {
    setQueryParams({
      create_agreement_party: true,
      edit_agreement_party: '',
    });
  }, [setQueryParams]);

  const handleEditClick = useCallback(
    (id: string) => {
      setQueryParams({
        create_agreement_party: false,
        edit_agreement_party: id,
      });
    },
    [setQueryParams],
  );

  const handleClose = useCallback(() => {
    setQueryParams({
      create_agreement_party: false,
      edit_agreement_party: '',
    });
  }, [setQueryParams]);

  const handleSuccess = useCallback(() => {
    setQueryParams({
      create_agreement_party: false,
      edit_agreement_party: '',
    });
    refetch();
  }, [refetch, setQueryParams]);

  return (
    <>
      <View
        title={'Agreement parties'}
        rightContent={
          <Button variant="blue" onClick={handleCreateClick} size="small">
            Create an agreement party
          </Button>
        }
      >
        <AgreementPartiesDataTable data={data} isLoading={isLoading} onEdit={handleEditClick} />
      </View>

      <AgreementPartyModal
        onClose={handleClose}
        onSuccess={handleSuccess}
        show={create_agreement_party || !!edit_agreement_party}
        agreementPartyId={edit_agreement_party}
      />
    </>
  );
};

export default AgreementParties;
