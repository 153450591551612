import { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Input } from '@creditornot/cb-input';
import { InputContainer } from '@creditornot/cb-form';

import { ConfirmationModal } from 'components';
import { LocalizedMessage, useI18n } from 'i18n';
import { Corporate } from 'modules/corporates/types';
import { useEditCorporateRestricted } from 'modules/corporates';

interface DisableCorporateAccountModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
  corporate: Corporate;
}

const StyledInputContainer = styled(InputContainer)`
  margin-top: 16px;
`;

const useHandleDisableCorporateConfirmInput = () => {
  const { getLocalizedMessage } = useI18n();
  const [text, setText] = useState('');
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setText(e.target.value);
  }, []);
  // Extract confirmation text from localized message
  const confirmText = useMemo(
    () =>
      getLocalizedMessage('corporate-form.disable-corporate-account.confirm-label').replace(
        /.*<b>|<\/b>.*/g,
        '',
      ),
    [getLocalizedMessage],
  );
  const clearInputValue = useCallback(() => setText(''), []);

  return {
    handleInputChange,
    text,
    confirmed: text === confirmText,
    clearInputValue,
  };
};

export const DisableCorporateAccountModal = ({
  show,
  onClose,
  onSuccess,
  corporate,
}: DisableCorporateAccountModalProps) => {
  const { handleInputChange, text, confirmed, clearInputValue } =
    useHandleDisableCorporateConfirmInput();
  const editCorporateRestricted = useEditCorporateRestricted();
  const { getLocalizedMessage } = useI18n();
  useEffect(clearInputValue, [clearInputValue, show]);
  const handleConfirm = useCallback(
    async () => editCorporateRestricted({ status: 'disabled' }),
    [editCorporateRestricted],
  );

  return (
    <ConfirmationModal
      title={<LocalizedMessage messageKey="corporate-form.disable-corporate-account.title" />}
      content={
        <div>
          <LocalizedMessage
            messageKey="corporate-form.disable-corporate-account.text"
            values={{
              corporate: <strong key={corporate.name}>{corporate.name}</strong>,
            }}
          />

          <StyledInputContainer
            label={getLocalizedMessage('corporate-form.disable-corporate-account.confirm-label', {
              b: (text: string) => <strong key={text}>{text}</strong>,
            })}
            labelHtmlFor="corporateName"
          >
            <Input value={text} name="corporateName" onChange={handleInputChange} />
          </StyledInputContainer>
        </div>
      }
      onClose={onClose}
      show={show}
      cancelButtonMesssge={<LocalizedMessage messageKey="common.cancel" />}
      confirmationButtonMessage={
        <LocalizedMessage messageKey="corporate-form.disable-corporate-account.confirm-button" />
      }
      confirmationButtonVariant="lightRed"
      onSuccess={() => setTimeout(onSuccess, 400)}
      onConfirm={confirmed ? handleConfirm : undefined}
    />
  );
};
