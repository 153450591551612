import styled from 'styled-components';
import { EyeOff as EyeOffIcon } from '@creditornot/cb-icons';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';

import { LocalizedMessage } from 'i18n';

const Root = styled.div`
  display: inline-block;
`;

const TooltipContent = styled.div`
  max-width: 200px;
`;

export const HiddenFromCompany = () => (
  <Root>
    <Popover
      renderArrow={false}
      placement="left"
      content={
        <TooltipContent>
          <LocalizedMessage messageKey="corporate-form.hidden-from-company-tooltip" />
        </TooltipContent>
      }
    >
      <EyeOffIcon height="16px" />
    </Popover>
  </Root>
);
