import React from 'react';
import styled from 'styled-components';
import { CaretDown, CaretUp, MagnifyingGlass } from '@creditornot/cb-icons';
import { MenuItemGroup, MenuLinkItem } from '@creditornot/cb-menu';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { Button } from '@creditornot/cb-button';
import { Input } from '@creditornot/cb-input';

interface Props {
  searchOptions: { label: string; value: string }[];
  selectedSearchOption: string;
  searchValue: string;
  onSearchOptionChange: (value: string) => void;
  onSearchValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearSearch: () => void;
  placeholder?: string;
  className?: string;
}

const Root = styled.div`
  display: flex;
  height: 36px;
`;

const StyledInput = styled(Input)`
  &,
  & > div {
    border-end-start-radius: 0;
    border-start-start-radius: 0;
  }
`;

const StyledMenuItemGroup = styled(MenuItemGroup)`
  padding: 0 8px;
`;

const StyledButton = styled(Button)`
  border-end-end-radius: 0;
  border-start-end-radius: 0;
`;

const MultiSearchInput = ({
  searchOptions,
  searchValue,
  onSearchValueChange,
  onClearSearch,
  selectedSearchOption,
  onSearchOptionChange,
  placeholder,
  className,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  return (
    <Root className={className}>
      <Popover
        contentZIndex={999}
        onOpenStart={() => setOpen(true)}
        onCloseStart={() => setOpen(false)}
        closeOnClickInside={true}
        renderArrow={false}
        showOnHover={false}
        placement="bottom-start"
        content={
          <StyledMenuItemGroup>
            {searchOptions.map((option) => (
              <MenuLinkItem
                key={option.value}
                active={selectedSearchOption === option.value}
                onClick={() => {
                  if (selectedSearchOption === option.value) {
                    return;
                  }
                  onSearchOptionChange(option.value);
                }}
              >
                {option.label}
              </MenuLinkItem>
            ))}
          </StyledMenuItemGroup>
        }
      >
        <StyledButton
          iconPosition="right"
          icon={open ? <CaretUp /> : <CaretDown />}
          size="small"
          variant="blue"
        >
          {searchOptions.find((option) => option.value === selectedSearchOption)?.label}
        </StyledButton>
      </Popover>
      <StyledInput
        icon={<MagnifyingGlass />}
        type="text"
        value={searchValue}
        onChange={onSearchValueChange}
        onClearClick={onClearSearch}
        placeholder={placeholder}
      />
    </Root>
  );
};

export default MultiSearchInput;
