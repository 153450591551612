import { Select } from '@creditornot/cb-select';

import { deliveryTimeOptions } from 'utils';

interface Props {
  value?: number;
  disabled?: boolean;
  className?: string;
  inputId: string;
  onChange: (value: number) => void;
  getDisabledTime?: (
    value: (typeof deliveryTimeOptions)[0],
    index: number,
    arr: typeof deliveryTimeOptions,
  ) => boolean;
}

// unfortunately, the select component only accepts string values
const mapOptionsToStringValue = (
  options: { value: number; label: string }[],
): { value: string; label: string }[] =>
  options.map((option) => ({ ...option, value: option.value.toString() }));

const TimeSelect = ({ onChange, value, disabled, className, inputId, getDisabledTime }: Props) => {
  return (
    <Select
      inputId={inputId}
      options={
        getDisabledTime
          ? mapOptionsToStringValue(
              deliveryTimeOptions.map((option, index, options) => ({
                ...option,
                disabled: !getDisabledTime(option, index, options),
              })),
            )
          : mapOptionsToStringValue(deliveryTimeOptions)
      }
      value={value?.toString()}
      disabled={disabled}
      onChange={(newvalue) => onChange(parseInt(newvalue ?? '', 10))}
      className={className}
      style={{ zIndex: 10 }}
    />
  );
};

export default TimeSelect;
