import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { IconButton } from '@creditornot/cb-button';
import { useCallback } from 'react';
import { MoreHorizontal } from '@creditornot/cb-icons';
import { colors } from '@creditornot/cb-ingredients';

import { DataList, EmptyState, UserStatus } from 'components';
import { LocalizedMessage, useI18n } from 'i18n';
import DropdownActionButton from 'components/DropdownButton';
import { UserInvite } from 'modules/invites/types';
import { useCorporate } from 'modules/corporates';
import { corporateFeatureFlag } from 'modules/corporates/utils';
import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';

import GroupTag from '../GroupTag';
import { useUsersModalsController } from '../hooks';

interface Props {
  invites?: UserInvite[] | null;
  className?: string;
  selectedRowKeys: string[];
  onRowSelectionChange: (selectedRowKeys: string[], selectedRecords: UserInvite[]) => void;
  error: unknown;
  loading: boolean;
  isFiltering: boolean;
}

type ComponentProps = {
  selectedRowKeys: string[];
};

const DataItem = styled.div<{
  isDisabled: boolean;
  isSelected: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  color: ${(props) => props.isDisabled && color.textDisabled};
  background-color: ${(props) => props.isSelected && colors.wolt12};
`;

const Container = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div<{ isDisabled?: boolean }>`
  color: ${(props) => (!props.isDisabled ? color.textSubdued : color.textDisabled)};
  font-weight: normal;
`;

const GroupsContainer = styled.div`
  font-size: 14px;
  margin-top: 6px;
`;

const StyledDropdownActionButton = styled(DropdownActionButton)`
  position: absolute;
  ${cssDirectionalValue({ ltr: 'right: 0;', rtl: 'left: 0;' })}
`;

const StyledRow = styled(Row)`
  padding-inline-end: 32px;
  position: relative;
`;

const StyledStatus = styled(UserStatus)`
  margin: 0 8px;
  display: inline-block;
`;

const UserInviteStatusIndicator = ({ isValid }: { isValid: boolean }) =>
  isValid ? <StyledStatus status="pending" /> : <StyledStatus status="expired" />;

const UserInviteTitle = ({
  userInvite,
  getLocalizedTimeAgo,
}: {
  userInvite: UserInvite;
  getLocalizedTimeAgo: (date: Date) => string;
}) =>
  userInvite.is_valid ? (
    <span>
      <LocalizedMessage messageKey="users.pending-invite" />
      {' – '}
      <LocalizedMessage
        messageKey="users.invited-time-ago"
        values={{
          timeAgo: getLocalizedTimeAgo(new Date(userInvite.modified_at)),
        }}
      />

      <UserInviteStatusIndicator isValid={userInvite.is_valid} />
    </span>
  ) : (
    <span>
      <LocalizedMessage messageKey="users.expired-invite" />
      <UserInviteStatusIndicator isValid={userInvite.is_valid} />
    </span>
  );

const useRenderDataItem = () => {
  const { getLocalizedTimeAgo } = useI18n();
  const { openResendUserInviteModal, openDeleteInviteModal } = useUsersModalsController();
  const { data: corporate } = useCorporate();
  const { groupEnabled } = corporateFeatureFlag(corporate);

  const renderDataItem = (dataItem: UserInvite, { selectedRowKeys }: ComponentProps) => {
    const isSelected = selectedRowKeys ? selectedRowKeys.includes(dataItem.id) : false;

    return (
      <DataItem isSelected={isSelected} isDisabled={false}>
        <Container>
          <StyledRow>
            <UserInviteTitle userInvite={dataItem} getLocalizedTimeAgo={getLocalizedTimeAgo} />

            {!selectedRowKeys.length && (
              <StyledDropdownActionButton
                showCaret={false}
                placement="bottom-end"
                actions={[
                  {
                    label: <LocalizedMessage messageKey={'common.resend'} />,
                    onClick: () => {
                      openResendUserInviteModal(dataItem.id);
                    },
                  },
                  {
                    label: <LocalizedMessage messageKey={'common.delete'} />,
                    onClick: () => openDeleteInviteModal(dataItem.id),
                  },
                ]}
              >
                <IconButton size="small" variant="black">
                  <MoreHorizontal />
                </IconButton>
              </StyledDropdownActionButton>
            )}
          </StyledRow>

          <Text isDisabled={false}>{dataItem.email}</Text>

          {groupEnabled && (
            <GroupsContainer>
              {dataItem.groups.map((gp) => (
                <GroupTag key={gp.id} isDisabled={!gp.active}>
                  {gp.name}
                </GroupTag>
              ))}
            </GroupsContainer>
          )}
        </Container>
      </DataItem>
    );
  };
  return renderDataItem;
};

const InvitesDataList: React.FC<Props> = ({
  invites,
  isFiltering,
  onRowSelectionChange,
  selectedRowKeys,
  ...rest
}) => {
  const renderDataItem = useRenderDataItem();

  const handleRowSelectionChange = useCallback(
    (_: UserInvite, selectedItemIds: string[]) => {
      const selectedRecord = (invites || []).reduce<UserInvite[]>((obj, val) => {
        const isSelected = selectedRowKeys.includes(val.id);
        if (!isSelected) {
          obj.push(val);
        }
        return obj;
      }, []);
      onRowSelectionChange(selectedItemIds, selectedRecord);
    },
    [invites, onRowSelectionChange, selectedRowKeys],
  );

  return (
    <DataList
      data={invites}
      getDataItemId={(invite) => invite.id}
      noData={
        <EmptyState icon="user">
          {isFiltering ? (
            <LocalizedMessage messageKey="common.no-users-found" />
          ) : (
            <LocalizedMessage messageKey="users.no-users" />
          )}
        </EmptyState>
      }
      onDataItemSelectionClick={handleRowSelectionChange}
      renderDataItem={renderDataItem}
      selectedDataItems={selectedRowKeys}
      renderDataItemProps={{ selectedRowKeys }}
      {...rest}
    />
  );
};

export default InvitesDataList;
