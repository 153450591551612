import { Route, Switch } from 'react-router-dom';

import OrdersView from './OrdersView';

const RootRoutes = () => (
  <Switch>
    <Route exact path="/:corporateId/orders" component={OrdersView} />
    <Route exact path="/:corporateId/orders/:orderId" component={OrdersView} />
  </Switch>
);

export default RootRoutes;
