import { useCallback } from 'react';

import { useDownloadFinalServiceAgreement } from 'modules/signupv2/useDownloadServiceAgreement';
import { downloadFile } from 'utils';
import { useFetchServiceAgreement } from 'modules/signupv2/useFetchServiceAgreement';

import { ServiceAgreement } from '../SignupV2/components/ServiceAgreement/ServiceAgreement';

export const ServiceAgreementContainer = () => {
  const { data: serviceAgreement } = useFetchServiceAgreement();
  const downloadServiceAgreement = useDownloadFinalServiceAgreement();

  const handleDownload = useCallback(async () => {
    const pdfData = await downloadServiceAgreement();
    downloadFile(new Blob([pdfData], { type: 'application/pdf' }), `WoltServiceAgreement.pdf`);
  }, [downloadServiceAgreement]);

  const acceptance = serviceAgreement?.documents.find(
    (doc) => doc.document_type === 'terms_and_conditions',
  )?.acceptance;

  if (!serviceAgreement?.id || !acceptance) {
    return null;
  }

  return (
    <ServiceAgreement
      date={new Date(acceptance.accepted_at)}
      agreedBy={`${acceptance.accepted_by_user_name.first} ${acceptance.accepted_by_user_name.last}`}
      onDownloadClick={handleDownload}
    />
  );
};
