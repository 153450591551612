import { intersection, pickBy, pick } from 'lodash';

import { EditCorporateData, EditCorporateRestrictedData } from 'modules/corporates/api';
import { isDefined } from 'utils';
import { FORM_KEYS as INVOICES_AND_RECEIPTS_FORM_KEYS } from 'views/settings/CompanySettingsV2/InvoicesAndReceiptsContainer';

// these are the keys that are compared against the defaults
const COMPARED_KEYS: Array<keyof (EditCorporateData & EditCorporateRestrictedData)> = [
  'billing_twice_a_month',
  'organise_invoice_by_groups',
  'organise_order_report_by_groups',
];

/**
 * For telemetry we need to know if user changed any of the defaults
 * @param newValues Changes made to the form
 * @returns boolean
 */
export const hasChangedInvoicingDefaults = (
  newValues: Partial<EditCorporateData & EditCorporateRestrictedData>,
) => {
  const comparedValues = pick(newValues, COMPARED_KEYS);
  return (
    intersection(Object.keys(pickBy(comparedValues, isDefined)), INVOICES_AND_RECEIPTS_FORM_KEYS)
      .length > 0
  );
};
