import { color } from '@creditornot/cb-ingredients/design-tokens';
import { colors } from '@creditornot/cb-ingredients';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { breakpoints } from 'modules/media';

import { CheckmarkIcon } from './CheckmarkIcon';

type SignupProgressProps = {
  className?: string;
  activeIndex: number;
};

const Root = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StepText = styled.div<{ active: boolean }>`
  font-weight: ${({ active }) => (active ? 700 : 500)};
  font-size: 14px;
  color: ${color.textSubdued};

  @media (max-width: ${breakpoints.small}px) {
    font-size: 12px;
  }
`;

const DashLine = styled.hr`
  border-top: 2px dashed ${color.border};
  border-bottom: none;
  border-inline: none;
  height: 22px;
  width: 80%;
`;

const StepCircle = styled.div<{ active: boolean; completed: boolean }>`
  display: flex;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 8px;
  border-radius: 50%;
  background-color: ${({ active, completed }) =>
    active ? color.bgFillBrand : completed ? colors.lime100 : color.bg};
  border: 1px solid ${color.border};
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  color: ${({ active, completed }) => (active ? 'white' : completed ? 'white' : color.textSubdued)};
`;

export const SignupProgress = ({ className, activeIndex }: SignupProgressProps) => {
  const { formatMessage } = useIntl();
  const isCompleted = (index: number) => {
    return index < activeIndex;
  };

  const isActive = (index: number) => {
    return index === activeIndex;
  };

  return (
    <Root className={className}>
      <StepsContainer>
        <StepContainer>
          <StepCircle active={isActive(1)} completed={isCompleted(1)}>
            {isCompleted(1) ? <CheckmarkIcon /> : 1}
          </StepCircle>
          <StepText active={isActive(1)}>
            {formatMessage({ id: 'signup.step1.progress.title' })}
          </StepText>
        </StepContainer>
        <DashLine />
        <StepContainer>
          <StepCircle active={isActive(2)} completed={isCompleted(2)}>
            {isCompleted(2) ? <CheckmarkIcon /> : 2}
          </StepCircle>
          <StepText active={isActive(2)}>
            {' '}
            {formatMessage({ id: 'signup.step2.progress.title' })}
          </StepText>
        </StepContainer>
        <DashLine />
        <StepContainer>
          <StepCircle active={isActive(3)} completed={isCompleted(3)}>
            {isCompleted(3) ? <CheckmarkIcon /> : 3}
          </StepCircle>
          <StepText active={isActive(3)}>
            {' '}
            {formatMessage({ id: 'signup.step3.progress.title' })}
          </StepText>
        </StepContainer>
      </StepsContainer>
    </Root>
  );
};
