import { DeliveryOrderResponse, Parcel, ParcelTag } from '@creditornot/wd-api-client';
import { useIntl } from 'react-intl';

import { convertGram } from './utils';

const useParcelTagDisplay = (tags: ParcelTag[]) => {
  const { formatMessage } = useIntl();
  const tagList = tags.map((tag) => {
    switch (tag) {
      case 'ALCOHOL':
        return formatMessage({ id: 'deliveries.delivery-details.alcohol' });
      case 'TOBACCO':
        return formatMessage({ id: 'deliveries.delivery-details.tobacco' });
      case 'MEDICINE':
        return formatMessage({ id: 'deliveries.delivery-details.medicine' });
      case 'UNSPECIFIED':
        return formatMessage({ id: 'deliveries.delivery-details.other' });
      default:
        return undefined;
    }
  });
  return tagList.filter((tag) => !!tag).join(', ');
};

const notEmptyParcel = (parcel: Parcel) =>
  parcel.dimensions.depthCm > 0 ||
  parcel.dimensions.widthCm > 0 ||
  parcel.dimensions.heightCm > 0 ||
  parcel.dimensions.weightGram > 0 ||
  !!parcel.price;

export type DeliveryDetail = {
  enabled: boolean;
  type:
    | 'large-heavy-parcel'
    | 'parcel'
    | 'no-contact'
    | 'pin-code-check'
    | 'age-check'
    | 'cash-to-collect';
  value?: string | JSX.Element;
};

export const useDeliveryDetails = (order: DeliveryOrderResponse): DeliveryDetail[] => {
  const { formatNumber } = useIntl();
  const idCheckParcels = order.parcels?.filter(
    (parcel) => parcel.dropoffRestrictions?.idCheckRequired === true,
  );
  const uniqueParcelTags = Array.from(new Set(idCheckParcels?.map((parcel) => parcel.tags).flat()));
  const parcelTags = useParcelTagDisplay(uniqueParcelTags);

  const totalParcelValues = order.parcels?.reduce(
    (total, parcel) => {
      return {
        weight: (total.weight += parcel.dimensions?.weightGram || 0),
        monetaryValue: (total.monetaryValue += parcel.price?.amounts || 0),
      };
    },
    {
      weight: 0,
      monetaryValue: 0,
    },
  );
  const parcelWeightText =
    !!totalParcelValues?.weight && totalParcelValues.weight > 0
      ? formatNumber(convertGram(totalParcelValues.weight, 'kg'), {
          style: 'unit',
          unit: 'kilogram',
        })
      : undefined;
  const parcelMonetaryValueText =
    !!totalParcelValues?.monetaryValue && totalParcelValues.monetaryValue > 0
      ? formatNumber(totalParcelValues.monetaryValue, {
          style: 'currency',
          currency: order.price.currency,
          currencyDisplay: 'code',
        })
      : undefined;

  return [
    {
      enabled: order.origin === 'DRIVE_UI' && !!order.parcels?.some(notEmptyParcel),
      type: 'large-heavy-parcel',
      value: [parcelMonetaryValueText, parcelWeightText].filter((text) => !!text).join(' / '),
    },
    {
      enabled: order.origin !== 'DRIVE_UI' && !!order.parcels?.length,
      type: 'parcel',
      value: [parcelMonetaryValueText, parcelWeightText].filter((text) => !!text).join(' / '),
    },
    {
      enabled: order.dropoff.options?.isNoContact === true,
      type: 'no-contact',
    },
    {
      enabled: order.handshakeDelivery?.isRequired === true,
      type: 'pin-code-check',
    },
    {
      enabled: !!idCheckParcels?.length,
      type: 'age-check',
      value: parcelTags,
    },
    {
      enabled: !!order.cash,
      type: 'cash-to-collect',
      value: order.cash
        ? formatNumber(order.cash.amountToCollect, {
            style: 'currency',
            currency: order.price.currency,
            currencyDisplay: 'code',
          })
        : undefined,
    },
  ];
};
