import React, { VFC, useCallback } from 'react';
import styled from 'styled-components';
import { Alert } from '@creditornot/cb-alert';
import { typographyCss } from '@creditornot/cb-ingredients';

import { env } from 'config';
import { useI18n } from 'i18n';
import { Header, ResponsiveContainer } from 'components';
import { useFetchDeliveries } from 'modules/deliveries';
import { useCorporateDeliverySettingsComplete } from 'modules/corporates';
import { processError } from 'utils';

import { FinalizeSettingsMessage } from './FinalizeSettingsMessage';
import { OrderCard } from './OrderCard';
import { AllDeliveriesCompleteBanner } from './AllDeliveriesCompleteBanner/AllDeliveriesCompleteBanner';

const StyledAlert = styled(Alert)`
  margin: 16px;
`;

const PickupSoonSection = styled(ResponsiveContainer)`
  flex-direction: column;
  padding-bottom: 16px;
`;

const StyledCardContainer = styled.div`
  margin-bottom: 12px;

  :nth-last-child(1) {
    margin: 0;
  }
`;

const Title = styled.span`
  ${typographyCss.Heading6()}
`;

const FinalizeSettingsMessageContainer = styled.div`
  margin: 24px;
  margin-top: 0;
`;

export const PickupSoonDeliveriesList: VFC<{ corporateId: string }> = ({ corporateId }) => {
  const { getLocalizedMessage } = useI18n();

  const { data: deliveriesData, error: deliveriesError } = useFetchDeliveries(
    {
      corporateId,
      deliveryStatus: 'SCHEDULED',
      pageSize: 50,
      from: undefined,
      to: undefined,
      userId: '',
      searchQuery: '',
      pageToken: '',
      sortBy: 'pickupEta',
      sortDirection: 'asc',
    },
    {
      refetchInterval: 60 * 1000,
    },
  );

  const handleOrderDeliveryClick = useCallback(() => {
    window.open(env.DAAS_SELF_SERVICE_URI, '_blank');
  }, []);

  const { data: deliverySettingsComplete, error: deliverySettingsCompleteError } =
    useCorporateDeliverySettingsComplete(corporateId);

  return (
    <>
      <Header
        leftItems={[
          <span key="pickup-soon">
            <Title>{getLocalizedMessage('deliveries.upcoming-pickups')}</Title>{' '}
            {/* <HeadingSecondaryText as="span">
              // TODO pickup soon count
            </HeadingSecondaryText> */}
          </span>,
        ]}
      />
      {deliveriesError && (
        <StyledAlert variant="error" title={processError(deliveriesError).message}>
          {processError(deliveriesError).data}
        </StyledAlert>
      )}

      {deliverySettingsCompleteError && (
        <StyledAlert variant="error" title={processError(deliverySettingsCompleteError).message}>
          {processError(deliverySettingsCompleteError).data}
        </StyledAlert>
      )}

      {deliverySettingsComplete === false && (
        <FinalizeSettingsMessageContainer>
          <FinalizeSettingsMessage corporateId={corporateId} />
        </FinalizeSettingsMessageContainer>
      )}

      <PickupSoonSection>
        {deliveriesData?.orders.length
          ? deliveriesData?.orders.map((order) => {
              return (
                <StyledCardContainer data-test-id="pickup-soon-order-card" key={order.id}>
                  <OrderCard order={order} />
                </StyledCardContainer>
              );
            })
          : null}

        {deliverySettingsComplete === true && deliveriesData?.orders.length === 0 && (
          <AllDeliveriesCompleteBanner onClick={handleOrderDeliveryClick} />
        )}
      </PickupSoonSection>
    </>
  );
};
