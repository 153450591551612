import { useLocation } from 'react-router';
import Bugsnag from '@bugsnag/browser';

import { componentsForRoute } from 'views/corporate';

import { Source, SourceValueType } from '../Avo';

const isValidCorporatePath = (path: string): boolean =>
  // Object.hasOwnProperty throws error here, see below:
  // https://stackoverflow.com/questions/39282873/object-hasownproperty-yields-the-eslint-no-prototype-builtins-error-how-to
  Object.prototype.hasOwnProperty.call(componentsForRoute, path);

/**
 * Returns the required corporate page source for Avo
 * @note The event shouldn't be sent to Avo if corporate path is invalid (the hook returns undefined in that case)
 */
export const useGetCorporateTelemetrySource = () => {
  const location = useLocation();
  const corporatePath = location.pathname.split('/')?.[2];
  const isValidPath = isValidCorporatePath(corporatePath);

  if (!isValidPath) {
    Bugsnag.notify(`Corporate telemetry event was called from an invalid path: ${corporatePath}`);
    return undefined;
  }

  switch (corporatePath) {
    // Source and path are different for "users" and "settings"
    case 'users':
      return Source.MEMBERS;
    case 'settings':
      return Source.COMPANY_SETTINGS;
    default:
      return corporatePath as SourceValueType;
  }
};
