import styled from 'styled-components';
import { MenuActionItem } from '@creditornot/cb-menu';
import { setMonth } from 'date-fns';

import { LocalizedDate } from 'i18n';

const MonthsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MenuActionItemRoot = styled(MenuActionItem)`
  text-align: center;
  width: 56px;
  height: 36px;
`;

const monthsTable: number[][] = [
  [0, 1, 2],
  [3, 4, 5],
  [6, 7, 8],
  [9, 10, 11],
];

const MonthPickerOptions = ({
  getIsSelectedMonth,
  onMonthClick,
}: {
  getIsSelectedMonth: (month: number) => boolean;
  onMonthClick: (month: number) => void;
}) => {
  return (
    <>
      {monthsTable.map((months) => (
        <MonthsRow key={JSON.stringify(months)}>
          {months.map((month) => {
            return (
              <MenuActionItemRoot
                key={month}
                value={month}
                variant="black"
                selected={getIsSelectedMonth(month)}
                onClick={() => onMonthClick(month)}
              >
                <LocalizedDate value={setMonth(new Date(), month)} month="short" />
              </MenuActionItemRoot>
            );
          })}
        </MonthsRow>
      ))}
    </>
  );
};

export default MonthPickerOptions;
