import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { colors, typographyCss } from '@creditornot/cb-ingredients';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { InfoCircleFilled } from '@creditornot/cb-icons';
import { useMemo } from 'react';

import { breakpoints, useMedia } from 'modules/media';
import { LocalizedMessage } from 'i18n';
import { EventStatistics } from 'modules/events/types';

import { EventBudgetUsage } from '../EventBudgetUsage';
import { StatisticsColumns } from './StatisticsColumns';
import { InformationItem } from './InformationItem';

interface EventStatisticsProps {
  isEndedEvent: boolean;
  eventStatistics?: EventStatistics;
}

const BudgetInformation = styled.div`
  grid-area: usage;
  padding: 24px;
  border-bottom: 1px solid ${color.border};
  border-inline-end: 1px solid ${color.border};
  min-height: 120px;

  @media (max-width: ${breakpoints.medium}px) {
    padding: 16px;
    min-height: unset;
  }
`;

const StyledStatisticsColumns = styled(StatisticsColumns)`
  grid-area: statistic;
`;

const ColoredHeading = styled.span<{ variant: 'black' | 'gray' | 'blue' | 'green' }>`
  color: ${({ variant }) =>
    ({
      black: color.text,
      gray: color.textSubdued,
      blue: colors.wolt100,
      green: colors.lime100,
    })[variant]};
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  ${typographyCss.Title2()}
  font-weight: bold;
`;

const Heading = styled.h3`
  ${typographyCss.Heading3()}
`;

const StyledInfoCircleFilled = styled(InfoCircleFilled)`
  width: 12px;
  height: 12px;
  margin-inline-start: 8px;
  vertical-align: baseline;
  fill: ${color.text};
`;

const PopoverContent = styled.span`
  ${typographyCss.Body3()}
  max-width: 280px;
  display: inline-block;
`;

export const EventStatisticsRow = ({ isEndedEvent, eventStatistics }: EventStatisticsProps) => {
  const isSmallView = useMedia('small');
  const unacceptedInvites = useMemo(
    () => [
      eventStatistics
        ? eventStatistics.total_invite_count -
          (eventStatistics.accepted_invite_count + eventStatistics.used_voucher_count)
        : 0,
    ],
    [eventStatistics],
  );

  const budgetPercentage = useMemo(() => {
    const percentage = eventStatistics
      ? (eventStatistics.purchase_total_amount / eventStatistics.total_budget_amount || 0) * 100
      : 0;
    return parseFloat(percentage.toFixed(1));
  }, [eventStatistics]);

  return (
    <>
      <BudgetInformation>
        <TitleRow>
          <Title>
            <LocalizedMessage messageKey="events.event-details.usage-label" />
          </Title>
          <Popover
            key="budget-usage"
            placement="top"
            content={
              <PopoverContent>
                <LocalizedMessage
                  messageKey="events.event-details.budget-tooltip"
                  values={{ amount: budgetPercentage }}
                />
              </PopoverContent>
            }
          >
            <StyledInfoCircleFilled />
          </Popover>
        </TitleRow>

        <div>
          <EventBudgetUsage eventStatistics={eventStatistics} />
        </div>
      </BudgetInformation>
      <StyledStatisticsColumns columns={isSmallView ? 1 : 3}>
        <InformationItem
          topContent={
            <TitleRow>
              <Title>
                {isEndedEvent ? (
                  <LocalizedMessage messageKey="events.event-statistics.used-invitations" />
                ) : (
                  <LocalizedMessage messageKey="events.event-statistics.active-participants" />
                )}
              </Title>
              <Popover
                key="invites-used"
                placement="top"
                content={
                  <PopoverContent>
                    {isEndedEvent ? (
                      <LocalizedMessage messageKey="events.event-statistics.used-tooltip" />
                    ) : (
                      <LocalizedMessage messageKey="events.event-statistics.active-tooltip" />
                    )}
                  </PopoverContent>
                }
              >
                <StyledInfoCircleFilled />
              </Popover>
            </TitleRow>
          }
          content={
            <Heading>
              <ColoredHeading variant="green">{`${
                eventStatistics?.used_voucher_count || 0
              }`}</ColoredHeading>
              <ColoredHeading variant="gray">{`/${
                eventStatistics?.total_invite_count || 0
              }`}</ColoredHeading>
            </Heading>
          }
        />
        <InformationItem
          topContent={
            <TitleRow>
              <Title>
                <LocalizedMessage messageKey="events.event-statistics.accepted-invitations" />
              </Title>
              <Popover
                key="ready-to-use"
                placement="top"
                content={
                  <PopoverContent>
                    {isEndedEvent ? (
                      <LocalizedMessage messageKey="events.event-statistics.accepted-tooltip-ended-event" />
                    ) : (
                      <LocalizedMessage messageKey="events.event-statistics.accepted-tooltip" />
                    )}
                  </PopoverContent>
                }
              >
                <StyledInfoCircleFilled />
              </Popover>
            </TitleRow>
          }
          content={
            <Heading>
              <ColoredHeading variant="black">{`${
                eventStatistics?.accepted_invite_count || 0
              }`}</ColoredHeading>
              <ColoredHeading variant="gray">{`/${
                eventStatistics?.total_invite_count || 0
              }`}</ColoredHeading>
            </Heading>
          }
        />
        <InformationItem
          topContent={
            <TitleRow>
              <Title>
                {isEndedEvent ? (
                  <LocalizedMessage messageKey="events.event-statistics.unused-invitations" />
                ) : (
                  <LocalizedMessage messageKey="events.event-statistics.pending-invitations" />
                )}
              </Title>
              <Popover
                key="ready-to-use"
                placement="top"
                content={
                  <PopoverContent>
                    {isEndedEvent ? (
                      <LocalizedMessage messageKey="events.event-statistics.unused-tooltip" />
                    ) : (
                      <LocalizedMessage messageKey="events.event-statistics.pending-tooltip" />
                    )}
                  </PopoverContent>
                }
              >
                <StyledInfoCircleFilled />
              </Popover>
            </TitleRow>
          }
          content={
            <Heading>
              <ColoredHeading variant="blue">{`${unacceptedInvites || 0}`}</ColoredHeading>
              <ColoredHeading variant="gray">{`/${
                eventStatistics?.total_invite_count || 0
              }`}</ColoredHeading>
            </Heading>
          }
        />
      </StyledStatisticsColumns>
    </>
  );
};
