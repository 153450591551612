import { MouseEventHandler, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { ModalHeader } from '@creditornot/cbt-modal';

import { useAppModals } from 'modules/app-modals';
import { ResponsiveContainer } from 'components';
import { breakpoints } from 'modules/media';
import { LocalizedMessage } from 'i18n';
import { resources } from 'modules/resources';

const modalStyle = css`
  max-width: 880px;

  @media (max-width: ${breakpoints.medium}px) {
    max-width: 100%;
  }

  overflow: auto;
`;

const Container = styled(ResponsiveContainer)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const ONBOARD_USERS_TUTORIAL_VIDEO = 'ONBOARD_USERS_TUTORIAL_VIDEO';

export const useOpenOnboardUsersTutorialVideo = () => {
  const { openModal, closeModal } = useAppModals();
  const handleCloseModal = useCallback(() => {
    closeModal(ONBOARD_USERS_TUTORIAL_VIDEO);
  }, [closeModal]);
  //   Prevent user from downloading the video
  const handleRightClick: MouseEventHandler<HTMLVideoElement> = useCallback(
    (e) => e.preventDefault(),
    [],
  );
  return useCallback(
    () =>
      openModal(ONBOARD_USERS_TUTORIAL_VIDEO, {
        children: (
          <div>
            <ModalHeader onCloseClick={handleCloseModal}>
              <LocalizedMessage messageKey="views.dashboard.onboard-users-tutorial-video-title" />
            </ModalHeader>
            <Container>
              <video
                onContextMenu={handleRightClick}
                height="100%"
                width="100%"
                controls
                autoPlay
                controlsList="nodownload"
                disablePictureInPicture
              >
                <source src={resources.videos.acceptInvitation} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Container>
          </div>
        ),
        modalStyle,
        onDismiss: handleCloseModal,
      }),
    [handleCloseModal, handleRightClick, openModal],
  );
};
