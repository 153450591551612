import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { InputContainer } from '@creditornot/cb-form';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { typographyCss } from '@creditornot/cb-ingredients';

import { breakpoints } from 'modules/media';
import { Divider, FormRowV2, FormSection } from 'components';
import { ScrollToFieldError } from 'components/FormikScrollToFieldError/FormikScrollToFieldError';

import { PolicyFormValues } from './types';
import PolicyNameField from './PolicyNameField';
import PolicyDescriptionField from './PolicyDescriptionField';
import PolicyAccountingNoteField from './PolicyAccountingNoteField';
import PolicyLimitSpendingField from './PolicyLimitSpendingField';
import PolicySpendingLimitAmountField from './PolicySpendingLimitAmountField';
import PolicySpendingLimitTimePeriodField from './PolicySpendingLimitTimePeriodField';
import PolicyLimitDeliveryTimeField from './PolicyLimitDeliveryTimeField';
import PolicyDeliveryDaysField from './PolicyDeliveryDaysField';
import PolicyDeliveryStartTimeField from './PolicyDeliveryStartTimeField';
import PolicyDeliveryEndTimeField from './PolicyDeliveryEndTimeField';
import { PolicyAllDayDeliveryField } from './PolicyAllDayDeliveryField';
import PolicyLimitDeliveryLocationField from './PolicyLimitDeliveryLocationField';
import PolicyDeliveryLocationFieldArray from './PolicyDeliveryLocationFieldArray';
import { PolicyCreditPurchaseField } from './PolicyCreditPurchaseField';
import { PolicyGroupsField } from './PolicyGroupsField';
import { AllowPurchaseFromNonRestaurantVenues } from './AllowPurchaseFromNonRestaurantVenues';
import { PolicyAllowRestaurantsField } from './PolicyAllowRestaurantsField';

type PolicyFormFieldsProps = {
  currency: string;
  hideGroupSelect?: boolean;
  corporateId: string;
};

const Root = styled.div`
  width: 100%;
`;

const StyledFormSection = styled(FormSection)`
  margin: 24px 24px 0;

  @media (max-width: ${breakpoints.small}px) {
    margin: 24px 0 0;
  }
`;

const StyledDivider = styled(Divider)`
  width: unset;
  margin: 12px -24px;

  @media (max-width: ${breakpoints.small}px) {
    margin: 12px -16px;
  }
`;

const RestrictionSubcontentContainer = styled.div`
  width: 100%;
  margin-top: 12px;
`;

const SpendingLimitAmountAndPeriodContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const SpendingLimitLabel = styled.span`
  ${typographyCss.Body3()}
  display: block;
  margin-bottom: 8px;
  user-select: none;
`;

const DeliveryTimeFieldsContainer = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.small}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const DeliveryTimeRangeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  gap: 24px;

  @media (max-width: ${breakpoints.large}px) {
    gap: 16px;
  }

  @media (max-width: ${breakpoints.small}px) {
    flex: 1;
    width: 100%;
  }
`;

const DeliveryTimePickerContainer = styled.div`
  display: flex;
`;

const SpendingLimitTimePeriodExplanation = styled.div`
  ${typographyCss.Small()};
  color: ${color.textSubdued};
  margin-top: 8px;
`;

const DeliveryTimeSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding: 0 10px;
`;

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: 12px;
  }
`;

const StyledAllowPurchaseFromNonRestaurantVenues = styled(AllowPurchaseFromNonRestaurantVenues)`
  margin-top: 8px;
`;

const StyledPolicyCreditPurchaseField = styled(PolicyCreditPurchaseField)`
  margin-top: 8px;
`;

const useResetDeliveryTimeOnToggleAllDayDelivery = () => {
  const {
    setFieldValue,
    values: { all_day_delivery, delivery_end_minute, delivery_start_minute },
  } = useFormikContext<PolicyFormValues>();
  useEffect(() => {
    if (all_day_delivery) {
      setFieldValue('delivery_start_minute', 0);
      setFieldValue('delivery_end_minute', 1440);
    } else {
      setFieldValue('delivery_start_minute', delivery_start_minute || 540);
      setFieldValue('delivery_end_minute', delivery_end_minute || 1020);
    }
  }, [all_day_delivery, delivery_end_minute, delivery_start_minute, setFieldValue]);
};

const PolicyFormFields = ({ currency, hideGroupSelect, corporateId }: PolicyFormFieldsProps) => {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext<PolicyFormValues>();
  useResetDeliveryTimeOnToggleAllDayDelivery();

  return (
    <Root>
      <ScrollToFieldError />
      <StyledFormSection
        title={formatMessage({ id: 'policies.general-information-section-title' })}
      >
        <FormRowV2
          title={formatMessage({ id: 'policies.policy-name' })}
          subtitle={formatMessage({ id: 'policies.policy-name-explanation' })}
          content={<PolicyNameField />}
        />
        <FormRowV2
          title={formatMessage({ id: 'policies.policy-description' })}
          subtitle={formatMessage({ id: 'policies.policy-description-explanation' })}
          content={<PolicyDescriptionField />}
        />
      </StyledFormSection>

      <StyledFormSection title={formatMessage({ id: 'policies.budget-details' })}>
        {!hideGroupSelect && (
          <FormRowV2
            testId="policy.group-select-row"
            title={formatMessage({ id: 'policies.table.groups' })}
            subtitle={formatMessage({ id: 'policies.groups-explanation' })}
            content={<PolicyGroupsField corporateId={corporateId} />}
          />
        )}
        <FormRowV2
          title={formatMessage({ id: 'policies.spending-limit-text' })}
          content={
            <>
              <StyledInputContainer label={formatMessage({ id: 'policies.spending-limit-label' })}>
                <PolicyLimitSpendingField />
              </StyledInputContainer>
              {['has-limit', 'exclude-delivery-fee'].includes(values.limit_spending) && (
                <RestrictionSubcontentContainer>
                  <SpendingLimitLabel>
                    <FormattedMessage id="policies.spending-limit-amount-label" />
                  </SpendingLimitLabel>
                  <SpendingLimitAmountAndPeriodContainer>
                    <PolicySpendingLimitAmountField currency={currency} />
                    <PolicySpendingLimitTimePeriodField />
                  </SpendingLimitAmountAndPeriodContainer>
                  {values.spending_limit_time_period === 'quarter' && (
                    <SpendingLimitTimePeriodExplanation>
                      {formatMessage({ id: 'policies.per-quarter.explanation' })}
                    </SpendingLimitTimePeriodExplanation>
                  )}
                </RestrictionSubcontentContainer>
              )}
            </>
          }
        />
      </StyledFormSection>

      <StyledFormSection title={formatMessage({ id: 'policies.delivery-options' })}>
        <FormRowV2
          title={formatMessage({ id: 'policies.time-restriction' })}
          subtitle={formatMessage({ id: 'policies.time-restriction-explanation' })}
          content={
            <>
              <InputContainer>
                <PolicyLimitDeliveryTimeField />
              </InputContainer>
              <StyledInputContainer>
                {values.limit_delivery_time === 'true' && (
                  <RestrictionSubcontentContainer>
                    <PolicyDeliveryDaysField />
                    <DeliveryTimeFieldsContainer>
                      <DeliveryTimeRangeContainer>
                        <DeliveryTimePickerContainer>
                          <DeliveryTimeSeparator style={{ paddingInlineStart: 0 }}>
                            <FormattedMessage id="policies.time-restriction.starting-date" />
                          </DeliveryTimeSeparator>
                          <PolicyDeliveryStartTimeField />
                          <DeliveryTimeSeparator>
                            <FormattedMessage id="policies.time-restriction.ending-date" />
                          </DeliveryTimeSeparator>
                          <PolicyDeliveryEndTimeField />
                        </DeliveryTimePickerContainer>
                        <PolicyAllDayDeliveryField />
                      </DeliveryTimeRangeContainer>
                    </DeliveryTimeFieldsContainer>
                  </RestrictionSubcontentContainer>
                )}
              </StyledInputContainer>
            </>
          }
        />
        <StyledDivider />
        <FormRowV2
          title={formatMessage({ id: 'policies.location-restrictions-text' })}
          content={
            <>
              <PolicyLimitDeliveryLocationField />
              <StyledInputContainer>
                {values.limit_delivery_location === 'true' && (
                  <RestrictionSubcontentContainer>
                    <PolicyDeliveryLocationFieldArray />
                  </RestrictionSubcontentContainer>
                )}
              </StyledInputContainer>
            </>
          }
        />
      </StyledFormSection>

      <StyledFormSection title={formatMessage({ id: 'policies.additional-requirements' })}>
        <FormRowV2
          title={formatMessage({ id: 'policies.accounting-note-text' })}
          subtitle={formatMessage({ id: 'policies.accounting-note-explanation' })}
          content={
            <StyledInputContainer label={formatMessage({ id: 'policies.accounting-note-label' })}>
              <PolicyAccountingNoteField />
            </StyledInputContainer>
          }
        />
        <StyledDivider />
        <FormRowV2
          title={formatMessage({ id: 'policies.venues-restriction-text' })}
          content={
            <>
              <StyledInputContainer
                label={formatMessage({ id: 'policies.venues-restriction-label' })}
              >
                <PolicyAllowRestaurantsField />
                <StyledAllowPurchaseFromNonRestaurantVenues />
                <StyledPolicyCreditPurchaseField />
              </StyledInputContainer>
            </>
          }
        />
      </StyledFormSection>
    </Root>
  );
};

export default PolicyFormFields;
