import { useIntersectionObserver } from '../use-intersection-observer';
import { UseSortedSetProps, useSortedSet, Action } from './useSortedSet';

interface UseInfiniteScroll<Item> extends UseSortedSetProps<Item> {
  onIntersect: (dispatch: React.Dispatch<Action<Item>>) => void;
  node: HTMLElement | null;
  updateOn?: number;
}

export function useInfiniteScroll<Data extends Record<string, any>>({
  onIntersect,
  node,
  getItemId,
  getSortBy,
  updateOn,
}: UseInfiniteScroll<Data>): [Data[], React.Dispatch<Action<Data>>] {
  const { data: sortedData, dispatch } = useSortedSet<Data>({
    getItemId,
    getSortBy,
  });

  useIntersectionObserver({
    node,
    updateOn,
    callback: (entry) => {
      if (!entry.isIntersecting) return;
      onIntersect(dispatch);
    },
  });

  return [sortedData, dispatch];
}
