import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useCallback } from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router';
import { SegmentedControl } from '@creditornot/cb-segmented-control';
import { useHistory, useLocation } from 'react-router-dom';

import { ModalV2, ModalHeader, ResponsiveContainer, Divider } from 'components';
import { LocalizedMessage, useI18n } from 'i18n';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { useProductTour } from 'modules/product-tour';
import {
  USER_INVITE_MODAL_SELECTOR,
  INVITE_USERS_BUTTON_SELECTOR,
  POLICIES_TAB_SELECTOR,
} from 'onboarding/selectors';
import { UserInvite } from 'modules/invites/types';
import { useAppModals } from 'modules/app-modals';

import InviteUserForm from './InviteUserForm';
import AddExistingUserForm from './AddExistingUserForm';

const NoAccess = styled(ResponsiveContainer)`
  color: ${color.textNegative};
  padding-bottom: 22px;
  padding-top: 22px;
`;

const Div = styled.div`
  height: 100px;
`;

const Container = styled.div`
  overflow: auto;
  height: auto;
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

interface NewUserModalProps {
  corporateId: string | null;
  onClose: () => void;
  onInviteSuccess: (invites: UserInvite[]) => void;
  onAddUserSuccess: () => void;
  show: boolean;
}

const NewUserModal = ({
  corporateId,
  onClose,
  onInviteSuccess,
  onAddUserSuccess,
  show,
}: NewUserModalProps) => {
  const { gotoStep, isInTourMode } = useProductTour();
  const { getLocalizedMessage } = useI18n();
  const location = useLocation();
  const history = useHistory();
  const { isWoltEmployeeWithCreate } = useIsUserWoltEmployee();
  const { hasOpenModal } = useAppModals();

  const handleClose = useCallback(() => {
    if (isInTourMode) {
      onClose();
      gotoStep(INVITE_USERS_BUTTON_SELECTOR);
    } else {
      onClose();
    }
  }, [gotoStep, isInTourMode, onClose]);

  const handleInviteSuccess = useCallback(
    (invites: UserInvite[]) => {
      if (isInTourMode) {
        gotoStep('');

        setTimeout(() => {
          gotoStep(POLICIES_TAB_SELECTOR);
        }, 500);
      } else {
        onInviteSuccess(invites);
      }
    },
    [gotoStep, isInTourMode, onInviteSuccess],
  );

  const handleSegmentChange = useCallback(
    (pathname: string) => {
      history.replace({ pathname });
    },
    [history],
  );

  return (
    <ModalV2
      onDismiss={handleClose}
      open={show}
      disableDismissOnOutsidePress={hasOpenModal}
      sizePreset={{ desktop: 'centered:extraLarge', mobile: 'fullScreen' }}
    >
      <Container data-product-tour={USER_INVITE_MODAL_SELECTOR}>
        <ModalHeader borderBottom={true} onClose={handleClose}>
          <LocalizedMessage messageKey="users.new-user-modal.title" />
        </ModalHeader>
        {isWoltEmployeeWithCreate && (
          <StyledResponsiveContainer>
            <SegmentedControl
              value={location.pathname}
              onChange={handleSegmentChange}
              options={[
                {
                  label: getLocalizedMessage('users.new-user-modal.invite-tab'),
                  value: `/${corporateId}/users/invite`,
                },
                {
                  label: getLocalizedMessage('users.new-user-modal.add-existing-tab'),
                  value: `/${corporateId}/users/add-existing`,
                },
              ]}
            />
          </StyledResponsiveContainer>
        )}
        <Divider />
        <Switch>
          <Route
            path={`/${corporateId}/users/invite`}
            render={() => (
              <InviteUserForm
                corporateId={corporateId}
                onClose={handleClose}
                onSuccess={handleInviteSuccess}
              />
            )}
          />
          <Route
            path={`/${corporateId}/users/add-existing`}
            render={() =>
              isWoltEmployeeWithCreate ? (
                <AddExistingUserForm
                  corporateId={corporateId}
                  onClose={handleClose}
                  onSuccess={onAddUserSuccess}
                />
              ) : (
                <NoAccess>
                  <LocalizedMessage messageKey="access-denied.access-denied" />
                </NoAccess>
              )
            }
          />
          <Route component={Div} />
        </Switch>
      </Container>
    </ModalV2>
  );
};

export default NewUserModal;
