import { useField } from 'formik';

import { useI18n } from 'i18n';
import { RadioInputCardGroup } from 'components';

const PolicyRestrictOrderTimeField = () => {
  const { getLocalizedMessage } = useI18n();
  const [limitDeliveryTimeFieldInput, , limitDeliveryTimeFieldHelpers] =
    useField<string>('limit_delivery_time');
  return (
    <RadioInputCardGroup
      name={limitDeliveryTimeFieldInput.name}
      value={limitDeliveryTimeFieldInput.value}
      onChange={(e) => limitDeliveryTimeFieldHelpers.setValue(e.target.value)}
      direction="row"
      stretched
      options={[
        {
          label: getLocalizedMessage('policies.any-time'),
          value: 'false',
        },
        {
          label: getLocalizedMessage('policies.specific-time'),
          value: 'true',
        },
      ]}
    />
  );
};

export default PolicyRestrictOrderTimeField;
