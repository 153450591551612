import { FormikInputField } from 'components';
import { FormikInputFieldProps } from 'components/FormikInputField';
import { createValidator, isRequired, isEmail } from 'validation';

const validate = createValidator([isRequired, isEmail]);

interface Props extends Omit<FormikInputFieldProps, 'name' | 'type' | 'required' | 'validate'> {
  alwaysShowError?: boolean;
}

export const ContactPersonEmailField = ({ alwaysShowError, ...rest }: Props) => {
  return (
    <FormikInputField
      name="contact_email"
      type="email"
      required={true}
      validate={validate}
      alwaysShowError={alwaysShowError}
      inputProps={{ id: 'contact_email' }}
      {...rest}
    />
  );
};
