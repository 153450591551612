import {
  addEvent,
  addViewEvent,
  telemetryLogin,
  telemetryLogout,
} from '@creditornot/browser-telemetry';

import { CustomDestination, LogTypeValueType } from 'telemetry/Avo';

type EventProperties = {
  log_type: Exclude<LogTypeValueType, 'performance' | 'result' | 'overlay'>;
  view?: string;
  event_name?: string;
  duration_ms?: number;
  attributes?: Record<string, any>;
};

type ViewEventProperties = {
  duration_ms: number;
  attributes?: Record<string, any>;
};

export const Gatekeeper: CustomDestination = {
  logEvent: (_: string, eventProperties: object): void => {
    const { log_type, view, event_name, ...otherEventProperties } =
      eventProperties as EventProperties;

    addEvent({
      log_type,
      view,
      event_name,
      attributes: otherEventProperties,
    });
  },

  // @args (userId: string, userProperties: object)
  setUserProperties: (): void => {
    // Set user properties
  },
  identify: (userId: string): void => {
    telemetryLogin(userId);
  },
  unidentify: (): void => {
    telemetryLogout();
  },
  logPage: (pageName: string, eventProperties: object): void => {
    const { duration_ms, ...otherEventProperties } = eventProperties as ViewEventProperties;
    addViewEvent(pageName, duration_ms || 0, otherEventProperties);
  },

  // @args (amount: number, eventProperties: object)
  revenue: (): void => {
    // Track Revenue
  },
};
