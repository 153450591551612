import { useContext } from 'react';

import { AppModalsContext } from './AppModalsContext';

export const useAppModals = () => {
  const { openModal, closeModal, hasOpenModal } = useContext(AppModalsContext);
  return {
    openModal,
    closeModal,
    hasOpenModal,
  };
};
