import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { ModalHeader } from '@creditornot/cbt-modal';
import { Radio } from '@creditornot/cb-radio';
import { useForm, FormProvider, useFormContext, Controller } from 'react-hook-form';
import { Button } from '@creditornot/cb-button';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert } from '@creditornot/cb-alert';

import { useAppModals } from 'modules/app-modals';
import { SupportedLanguageCode, supportedLanguages, useSetUserLocale } from 'i18n';
import { FormFooter, ResponsiveContainer } from 'components';
import { breakpoints } from 'modules/media';
import { useFetchCorporates } from 'modules/corporates';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { getLanguageCodeFromLocale } from 'i18n/LocaleDataProvider';

type ChangeLanguageFormValues = {
  languageCode: SupportedLanguageCode;
};

const modalStyle = css`
  max-width: 400px;
  overflow: auto;

  @media (max-width: ${breakpoints.small}px) {
    max-height: 100vh;
    max-width: unset;
    position: initial;
    height: auto;
  }
`;

const FormContainer = styled.div`
  max-height: 70vh;
  overflow: auto;

  @media (max-width: ${breakpoints.small}px) {
    max-height: unset;
  }
`;

const Container = styled(ResponsiveContainer)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const LanguageOptions = styled.div`
  width: 100%;
`;

const StyledAlert = styled(Alert)`
  margin: 12px 0;
`;

const Row = styled.div`
  & + & {
    margin-top: 12px;
  }
`;

const LANGUAGE_MODAL = 'LANGUAGE_MODAL';

const ChangeLanguageForm = () => {
  const { control } = useFormContext<ChangeLanguageFormValues>();
  const { formatMessage } = useIntl();
  const { isWoltEmployee } = useIsUserWoltEmployee();

  const { data: corporatesWithDaasEnabled } = useFetchCorporates(undefined, {
    select: (corporates) =>
      corporates.some((corporate) => corporate.products.daas_delivery_ui_enabled),
  });

  const [internalOnlyLanguages, publicLanguages] = [
    supportedLanguages.filter((language) => language.internal),
    supportedLanguages.filter((language) => {
      // Some languages are only available for DaaS users
      if (language.internal || (language.driveOnly && !corporatesWithDaasEnabled)) {
        return false;
      }
      return true;
    }),
  ];

  return (
    <FormContainer>
      <Container>
        <LanguageOptions>
          <Controller
            name="languageCode"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                {publicLanguages.map(({ code, label }) => (
                  <Row key={code}>
                    <Radio
                      key={code}
                      value={code}
                      name={code}
                      checked={value === code}
                      onChange={onChange}
                    >
                      {label}
                    </Radio>
                  </Row>
                ))}
                {isWoltEmployee && (
                  <>
                    <StyledAlert variant="info" size="small">
                      {formatMessage({ id: 'corporate-form.employee-disclaimer' })}
                    </StyledAlert>
                    {internalOnlyLanguages.map(({ code, label }) => (
                      <Row key={code}>
                        <Radio
                          key={code}
                          value={code}
                          name={code}
                          checked={value === code}
                          onChange={onChange}
                        >
                          {label}
                        </Radio>
                      </Row>
                    ))}
                  </>
                )}
              </>
            )}
          />
        </LanguageOptions>
      </Container>
    </FormContainer>
  );
};

export const useOpenLanguageChangeModal = () => {
  const { openModal, closeModal } = useAppModals();
  const { locale } = useIntl();
  const { setUserLanguage } = useSetUserLocale();
  const methods = useForm<ChangeLanguageFormValues>({
    defaultValues: {
      languageCode: getLanguageCodeFromLocale(locale) as SupportedLanguageCode,
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(LANGUAGE_MODAL);
  }, [closeModal]);
  const onSubmit = useCallback(
    (values: ChangeLanguageFormValues) => {
      setUserLanguage(values.languageCode);
      closeModal(LANGUAGE_MODAL);
    },
    [closeModal, setUserLanguage],
  );
  return useCallback(
    () =>
      openModal(LANGUAGE_MODAL, {
        children: (
          <div>
            <ModalHeader onCloseClick={handleCloseModal}>
              <FormattedMessage id="change-language-modal.title" />
            </ModalHeader>

            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <ChangeLanguageForm />
                <FormFooter
                  rightContent={
                    <>
                      <Button onClick={handleCloseModal} variant="lightBlue" size="small">
                        <FormattedMessage id="common.close" />
                      </Button>
                      <Button type="submit" variant="blue" size="small">
                        <FormattedMessage id="common.change" />
                      </Button>
                    </>
                  }
                />
              </form>
            </FormProvider>
          </div>
        ),
        modalStyle,
        onDismiss: handleCloseModal,
      }),
    [openModal, handleCloseModal, methods, onSubmit],
  );
};
