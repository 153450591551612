import { useField } from 'formik';

import { FormRowV2, RadioInputCardGroup } from 'components';

export const IsTippingEnabledField = () => {
  const [{ name, value }, , { setValue }] = useField<boolean>('is_tipping_enabled');

  return (
    <FormRowV2
      htmlFor="is_tipping_enabled"
      title="Tipping"
      content={
        <RadioInputCardGroup
          name={name}
          value={value ? 'true' : 'false'}
          onChange={(e) => setValue(e.target.value === 'true')}
          direction="row"
          stretched
          options={[
            {
              label: 'Enabled',
              value: 'true',
            },
            {
              label: 'Disabled',
              value: 'false',
            },
          ]}
        />
      }
    />
  );
};
