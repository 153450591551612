import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { env } from 'config';
import { useCorporate } from 'modules/corporates';

export const useCreateWoltPagesLink = () => {
  const { locale } = useIntl();
  const { data: corporate } = useCorporate();
  const { user } = useAuthState();

  const country = useMemo(() => corporate?.country || user?.country, [corporate, user]);

  return useCallback(
    (slug: string) => {
      if (locale && country) {
        const normalizedLocale = locale.split('-')[0];
        const normalizedCountry = country.toLowerCase();

        return `${env.WOLT_PAGES_URI}/${normalizedLocale}/${normalizedCountry}/${slug}`;
      }

      return `${env.WOLT_PAGES_URI}/${slug}`;
    },
    [country, locale],
  );
};
