import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { MenuItemGroup, MenuLinkItem } from '@creditornot/cb-menu';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { Spinner } from '@creditornot/cb-progress';
import { Input } from '@creditornot/cb-input';
import { CaretDown, CaretUp, MagnifyingGlass } from '@creditornot/cb-icons';
import { TextButton } from '@creditornot/cb-button';
import { Avatar } from '@creditornot/cb-avatar';
import { Alert } from '@creditornot/cb-alert';
import { typographyCss } from '@creditornot/cb-ingredients';

import { LocalizedMessage, useI18n } from 'i18n';
import { breakpoints } from 'modules/media';
import { useFetch } from 'modules/react-query';
import { fetchAllUsers, useFetchWoltUsers } from 'modules/users';
import { WoltUserNonNullable } from 'modules/users/types';
import { useCorporate } from 'modules/corporates';
import { useDebounce } from 'modules/hooks';
import { processError } from 'utils';

interface Props {
  selectedUser: WoltUserNonNullable | null;
  corporateId: string;
  onSelectUser: (user: WoltUserNonNullable) => void;
}

const Content = styled.div`
  max-height: 200px;
  overflow-y: auto;
  width: 450px;

  @media (max-width: ${breakpoints.small}px) {
    width: 300px;
  }

  padding: 8px;
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  margin-inline-start: 8px;
`;

const StyledMenuLinkItem = styled(MenuLinkItem)<{ disabled: boolean }>`
  margin-top: 8px;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`;

const Text = styled.span`
  ${typographyCss.Body3()}
  font-weight: 500;
`;

const SubText = styled.small`
  ${typographyCss.Small()}
`;

export const AsyncUserSelect: React.FC<Props> = ({ selectedUser, onSelectUser }) => {
  const { getLocalizedMessage } = useI18n();
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false);
  const { data: corporate } = useCorporate();
  const debouncedSearch = useDebounce(search, 500);
  const {
    data: corporateUsers,
    error: corporateUsersError,
    isFetching: corporateUsersLoading,
  } = useFetch({
    queryKey: ['AsyncUserSelect', 'users'],
    queryFn: () => (corporate ? fetchAllUsers(corporate.id) : Promise.resolve(null)),
  });
  const {
    data: woltUsers,
    error: woltUsersError,
    isFetching: woltUsersLoading,
  } = useFetchWoltUsers(debouncedSearch, {
    enabled: debouncedSearch.length > 2,
  });

  useEffect(() => {
    if (show) return;
    setSearch('');
  }, [show]);

  const isSearching = corporateUsersLoading || woltUsersLoading;

  const corporateUserIds = useMemo(
    () => corporateUsers?.map((corporateUser) => corporateUser.user_id),
    [corporateUsers],
  );
  const error = corporateUsersError || woltUsersError;
  const options = useMemo(
    () =>
      woltUsers?.map((woltuser) => ({
        disabled: isSearching || corporateUserIds?.includes(woltuser.user.id.$oid),
        id: woltuser.user.id.$oid,
        label: woltuser.user.email,
        value: woltuser,
      })),
    [corporateUserIds, isSearching, woltUsers],
  );

  return (
    <Popover
      contentZIndex={999999}
      show={show}
      onClickOutside={() => setShow(false)}
      showOnHover={false}
      placement="bottom-start"
      renderArrow={false}
      content={
        <Content>
          <MenuItemGroup>
            <Input
              value={search}
              placeholder={getLocalizedMessage(
                'users.new-user-modal.add-existing.select-user-placeholder',
              )}
              icon={isSearching ? <Spinner size="small" /> : <MagnifyingGlass />}
              onChange={(e) => setSearch(e.target.value)}
              autoFocus={true}
            />
            {!!error && (
              <div>
                <Alert variant="error" title={processError(error).message}>
                  {processError(error).data}
                </Alert>
              </div>
            )}

            {options?.length === 0 && !isSearching && (
              <div>
                <LocalizedMessage messageKey="common.no-users-found" />
              </div>
            )}

            {options?.map((op) => {
              return (
                <StyledMenuLinkItem
                  disabled={!!op.disabled}
                  key={op.id}
                  active={op.id === corporate?.id}
                  onClick={() => {
                    if (op.disabled) {
                      return;
                    }
                    onSelectUser(op.value);
                    setShow(false);
                  }}
                >
                  <ItemContent>
                    <Avatar size="medium" imgSrc={op.value.user.profile_picture.url} />
                    <Name>
                      <div>{`${op.value.user.name.first_name} ${op.value.user.name.last_name}`}</div>
                      <div>{op.value.user.email}</div>
                      {op.disabled && (
                        <div>
                          <SubText>
                            <LocalizedMessage messageKey="users.new-user-modal.user-select.already-a-member" />
                          </SubText>
                        </div>
                      )}
                    </Name>
                  </ItemContent>
                </StyledMenuLinkItem>
              );
            })}
          </MenuItemGroup>
        </Content>
      }
    >
      <TextButton
        onClick={() => setShow((state) => !state)}
        variant="blue"
        iconPosition="right"
        icon={show ? <CaretUp /> : <CaretDown />}
      >
        <Text>
          {selectedUser ? (
            `${selectedUser.user.name.first_name} ${selectedUser.user.name.last_name}`
          ) : (
            <LocalizedMessage messageKey="users.new-user-modal.add-existing.select-user" />
          )}
        </Text>
      </TextButton>
    </Popover>
  );
};

export default AsyncUserSelect;
