import { InputProps } from '@creditornot/cb-input';

import { FormikInputField } from 'components';
import { FormikInputFieldProps } from 'components/FormikInputField';
import { createValidator, isRequired } from 'validation';

const validate = createValidator([isRequired]);

interface Props extends Omit<FormikInputFieldProps, 'name' | 'type' | 'required' | 'validate'> {
  alwaysShowError?: boolean;
  inputProps?: Partial<InputProps>;
}

export const CompanyNameField = ({ alwaysShowError, inputProps, ...rest }: Props) => {
  return (
    <FormikInputField
      name="name"
      type="text"
      required={true}
      validate={validate}
      alwaysShowError={alwaysShowError}
      inputProps={{
        autoFocus: true,
        id: 'name',
        ...inputProps,
      }}
      {...rest}
    />
  );
};
