import styled from 'styled-components';
import { Formik } from 'formik';
import { Alert } from '@creditornot/cb-alert';
import { ModalFooter } from '@creditornot/cbt-modal';
import { Button } from '@creditornot/cb-button';
import { Checkmark } from '@creditornot/cb-icons';
import { useIntl } from 'react-intl';

import { ModalV2, ResponsiveContainer } from 'components';
import { LocalizedMessage } from 'i18n';
import { useEditGroup } from 'modules/groups';
import { Group } from 'modules/groups/types';
import { processError } from 'utils';

import GeneralInformationFormSection from './GeneralInformationFormSection';
import { EditGroupFormValues } from './types';

interface EditGroupInfoModalProps {
  corporateId?: string;
  onClose: () => void;
  onSuccess: () => void;
  show: boolean;
  group: Group | null | undefined;
}

const Container = styled(ResponsiveContainer)`
  & > div {
    width: 100%;
  }

  margin-top: 24px;
`;

const StyledModalFooter = styled(ModalFooter)`
  margin-top: 24px;

  button {
    margin-inline-start: auto;
  }

  button + button {
    margin-inline-start: 16px;
  }
`;

const EditGroupInfoModal = ({
  corporateId,
  onClose,
  onSuccess,
  show,
  group,
}: EditGroupInfoModalProps) => {
  const editGroup = useEditGroup();
  const { formatMessage } = useIntl();
  return (
    <ModalV2
      onDismiss={onClose}
      open={show}
      title={formatMessage({ id: 'groups-form.edit-group-info' })}
      sizePreset={{
        desktop: 'centered:large',
        mobile: 'fullScreen',
      }}
    >
      <Formik
        initialValues={{
          groupName: group ? group.name : '',
          description: group ? group.description : '',
        }}
        enableReinitialize={true}
        onSubmit={({ groupName, description }: EditGroupFormValues, actions) => {
          if (!corporateId || !group) {
            actions.setSubmitting(false);
            actions.setStatus({ error: 'Corporate id and group must be provided' });
            return;
          }

          return editGroup(group.id, {
            name: groupName,
            description: description,
          })
            .then(() => {
              actions.setSubmitting(false);
              actions.setStatus({ submitSucceeded: true });
              setTimeout(() => {
                onSuccess();
              }, 500);
            })
            .catch((error) => {
              actions.setStatus({ error });
              actions.setSubmitting(false);
            });
        }}
      >
        {({ status, handleSubmit, isSubmitting, isValid }) => (
          <>
            <Container>
              <GeneralInformationFormSection />
            </Container>
            {status?.error && (
              <Container>
                <Alert variant="error" title={processError(status.error).message}>
                  {processError(status.error).data}
                </Alert>
              </Container>
            )}
            <StyledModalFooter>
              <Button variant="lightBlue" size="small" onClick={onClose}>
                <LocalizedMessage messageKey="common.cancel" />
              </Button>
              <Button
                variant="blue"
                size="small"
                onClick={() => handleSubmit()}
                disabled={!isValid || isSubmitting || status?.submitSucceeded}
                loading={isSubmitting}
                icon={status?.submitSucceeded && <Checkmark />}
              >
                <LocalizedMessage messageKey="common.save" />
              </Button>
            </StyledModalFooter>
          </>
        )}
      </Formik>
    </ModalV2>
  );
};

export default EditGroupInfoModal;
