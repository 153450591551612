import { FormikInputField } from 'components';
import { createValidator, isRequired, isNumber, min } from 'validation';

const validate = createValidator([isRequired, isNumber, min(0)]);

interface Props {
  alwaysShowError?: boolean;
}

export const BasePriceField = ({ alwaysShowError }: Props) => {
  return (
    <FormikInputField
      name="base_price"
      type="text"
      required={true}
      validate={validate}
      alwaysShowError={alwaysShowError}
    />
  );
};
