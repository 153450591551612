import { RefObject, useEffect, useMemo, useRef, useState } from 'react';

/**
 * Check if a div element is in viewport. Usage: add the element ref (that's returned from this hook) to the
 * parent component and control visibility of the children.
 * @param {number} offset - Number of pixels up to the observable element from the top
 * @param {boolean} alwaysShow - Always show the element by default
 */
export const useElementVisibility = ({
  offset = 0,
  alwaysShow = false,
}: {
  offset?: number;
  alwaysShow?: boolean;
}): [boolean, RefObject<HTMLDivElement>] => {
  const [isInBounds, setIsInBounds] = useState(false);
  const element = useRef<HTMLDivElement>(null);
  const isVisible = useMemo(() => alwaysShow || isInBounds, [isInBounds, alwaysShow]);

  const handleScroll = () => {
    if (!element.current) {
      return;
    }

    const { top } = element.current.getBoundingClientRect();
    // Visibility is based on whether the element is within the viewport or in the bounds of the given offset from top
    setIsInBounds(top + offset >= 0 && top - offset <= window.innerHeight);
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);
    return () => document.removeEventListener('scroll', handleScroll, true);
  });

  return [isVisible, element];
};
