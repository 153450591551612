import { useField } from 'formik';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { RadioInputCardGroup } from 'components';

const StyledRadioInputCardGroup = styled(RadioInputCardGroup)`
  & > label:nth-child(2) {
    margin-inline-end: 0;
  }
`;

export const OrderOptionFieldV2 = () => {
  const { formatMessage } = useIntl();
  const [{ name, value }, , { setValue }] = useField<string>('organise_order_report_by_groups');

  return (
    <StyledRadioInputCardGroup
      name={name}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      direction="row"
      stretched
      options={[
        {
          label: formatMessage({ id: 'corporate-form.normal-order-option' }),
          value: 'false',
        },
        {
          label: formatMessage({ id: 'corporate-form.organized-by-groups-order-option' }),
          value: 'true',
        },
      ]}
    />
  );
};
