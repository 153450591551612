import styled from 'styled-components';
import { Button } from '@creditornot/cb-button';

import { LocalizedMessage } from 'i18n';
import SadFaceIcon from 'assets/images/sad-face.svg';

const Root = styled.div`
  position: fixed;
  background-color: white;
  inset: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Heading = styled.div`
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 30px;
`;

const Content = styled.div`
  max-width: 640px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 15px;
  line-height: 22px;
`;

const AppCrashError = () => (
  <Root>
    <SadFaceIcon />
    <br />
    <br />
    <Heading>
      <LocalizedMessage messageKey="app-crash.crash-happened" />
    </Heading>
    <Content>
      <LocalizedMessage messageKey="app-crash.further-explanation" />
    </Content>
    <br />
    <br />
    <Button size="small" variant="blue" onClick={() => window.location.reload()}>
      <LocalizedMessage messageKey="app-crash.reload" />
    </Button>
  </Root>
);

export default AppCrashError;
