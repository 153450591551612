import * as t from 'io-ts';
import { CorporatesApi } from '@creditornot/wd-api-client';
import { AxiosRequestConfig } from 'axios';

import { corporateServiceApiAgent, daasSelfServiceApiAgent, restaurantApiAgent } from 'apiAgents';
import { PaginatedData } from 'types/PaginatedData';
import { logValidationError } from 'validation';
import { ResponseStatus } from 'types';
import { env } from 'config';

import { Consents, Corporate, CorporateStatus, CorporateV2, TransactionLimits } from './types';

type Product = 'DELIVERY_UI' | 'WOLT_AT_WORK' | 'SUBSCRIPTION_BENEFIT';

export type CreateCorporateData = {
  address: string;
  billing_twice_a_month: boolean;
  business_id: string;
  city: string;
  contact_email: string;
  contact_name: string;
  contact_phone: string;
  country: string;
  credit_limit_amount: number;
  currency: string;
  customer_reference: string;
  electronic_invoicing_details: {
    edi: string;
    edi_operator: string;
    vat_exemption_disclaimer?: string;
  } | null;
  has_credit_limit: boolean;
  has_transaction_limits: boolean;
  industry: string | null;
  invoice_email_cc_recipients: string[];
  invoice_email: string;
  invoice_fee_percentage: number;
  invoice_overdue_interest: number;
  name: string;
  organise_invoice_by_groups: boolean;
  organise_order_report_by_groups: boolean;
  payment_term_delay_days: number;
  post_code: string;
  preferred_invoice_locale: string;
  products: Product[];
  single_receipt_email: string;
  transaction_limits: {
    daily_transactions_amount: number;
    single_transaction_amount: number;
  } | null;
  vat_id: string;
};

export const createCorporate = (data: CreateCorporateData) =>
  restaurantApiAgent.post<Corporate>('/v1/waw-api/corporates', data).then(({ data }) => {
    logValidationError('createCorporate', Corporate, data);
    return data;
  });

export type FetchCorporatesData = {
  country_code?: string;
  name?: string;
  statuses?: string[];
  products?: string[];
};

export type FetchCorporatesDataWithPagination = FetchCorporatesData & {
  page_size: number;
  page: number;
};

export const fetchCorporates = async ({
  country_code,
  name,
  statuses,
  products,
}: FetchCorporatesData) => {
  const { data } = await restaurantApiAgent.get<Array<Corporate>>('/v1/waw-api/corporates', {
    params: { pagination: false, name, country_code, statuses, products },
  });
  logValidationError('fetchCorporates', t.array(Corporate), data);
  return data;
};

export const fetchCorporatesWithPagination = async ({
  country_code,
  name,
  page_size,
  page,
  statuses,
  products,
}: FetchCorporatesDataWithPagination) => {
  const { data } = await restaurantApiAgent.get<PaginatedData<Corporate>>(
    '/v1/waw-api/corporates',
    {
      params: { name, country_code, pagination: true, page, page_size, statuses, products },
    },
  );
  logValidationError('fetchCorporatesWithPagination', t.array(Corporate), data.results);
  return data;
};

export const fetchCorporatesBy = async (name: string) => {
  const { data } = await restaurantApiAgent.get<Corporate[]>(`/v1/waw-api/corporates?name=${name}`);
  logValidationError('fetchCorporatesBy', t.array(Corporate), data);
  return data;
};

export const fetchCorporate = async (id: string, config?: AxiosRequestConfig<Corporate>) => {
  const { data } = await restaurantApiAgent.get<Corporate>(`/v1/waw-api/corporates/${id}`, config);
  logValidationError('fetchCorporate', Corporate, data);
  return data;
};

export const fetchCorporateV2 = async (id: string, config?: AxiosRequestConfig<Corporate>) => {
  const { data } = await corporateServiceApiAgent.get<CorporateV2>(
    `/portal-api/v1/corporates/${id}`,
    config,
  );
  logValidationError('fetchCorporateV2', CorporateV2, data);
  return data;
};

export type EditCorporateData = Partial<{
  address: string;
  billing_twice_a_month: boolean;
  business_id: string;
  city: string;
  contact_email: string;
  contact_name: string;
  contact_phone: string;
  country: string;
  customer_reference: string;
  electronic_invoicing_details: {
    edi: string;
    edi_operator: string;
    vat_exemption_disclaimer?: string;
  } | null;
  industry: string | null;
  invoice_email_cc_recipients: string[];
  invoice_email: string;
  is_electronic_invoicing_enabled: boolean;
  name: string;
  organise_invoice_by_groups: boolean;
  organise_order_report_by_groups: boolean;
  post_code: string;
  preferred_invoice_locale: string;
  single_receipt_email: string;
  status: CorporateStatus;
  vat_id: string;
}>;

export const editCorporate = async (corporateId: string, editCorporateData: EditCorporateData) => {
  const { data } = await restaurantApiAgent.patch<Corporate>(
    `/v1/waw-api/corporates/${corporateId}`,
    editCorporateData,
  );
  logValidationError('editCorporate', Corporate, data);
  return data;
};

export const editCorporateV2 = async (
  corporateId: string,
  editCorporateData: Partial<CorporateV2>,
) => {
  const { data } = await corporateServiceApiAgent.patch<CorporateV2>(
    `/portal-api/v1/corporates/${corporateId}`,
    editCorporateData,
  );
  logValidationError('editCorporateV2', CorporateV2, data);
  return data;
};

export type EditCorporateAsAdminData = {
  address?: string;
  city?: string;
  business_id?: string;
  contact_email?: string;
  contact_name?: string;
  contact_phone?: string;
  customer_reference?: string;
  invoice_email?: string;
  post_code?: string;
  preferred_invoice_locale?: string;
  single_receipt_email?: string;
  invoice_email_cc_recipients?: string[];
  industry?: string | null;
  vat_id?: string;
};

// For non-Wolt users to edit their company info
export const editCorporateAsAdmin = async (corporateId: string, data: EditCorporateAsAdminData) => {
  const res = await restaurantApiAgent.patch<Corporate>(
    `/v1/waw-api/corporates/${corporateId}`,
    data,
  );
  logValidationError('editCorporateAsAdmin', Corporate, res.data);
  return res.data;
};

export type EditCorporateRestrictedData = Partial<{
  status: CorporateStatus;
  is_wolt_company: boolean;
  invoice_fee_percentage: number;
  payment_term_delay_days: number;
  invoice_overdue_interest: number;
  has_credit_limit: boolean;
  credit_limit_amount: number;
  has_transaction_limits: boolean;
  transaction_limits: TransactionLimits;
  account_manager: string;
}>;

export const editCorporateRestricted = async (
  corporateId: string,
  data: EditCorporateRestrictedData,
) => {
  const res = await restaurantApiAgent.patch<Corporate>(
    `/v1/waw-api/corporates/${corporateId}/restricted`,
    data,
  );
  logValidationError('editCorporateRestricted', Corporate, res.data);
  return res.data;
};

export const deleteCorporate = async (corporateId: string) => {
  const res = await restaurantApiAgent.delete<Corporate>(`/v1/waw-api/corporates/${corporateId}`);
  logValidationError('deleteCorporate', ResponseStatus, res.data);
  return res.data;
};

export const fetchConsents = async (corporateId: string) => {
  const { data } = await corporateServiceApiAgent.get<Consents>(
    `/portal-api/v1/corporates/${corporateId}/consents`,
  );
  logValidationError('fetchConsents', Consents, data);
  return data;
};

export const selfServiceCorporateApiClient = new CorporatesApi(
  undefined,
  env.NODE_ENV === 'development' ? '/daas-self-service-api' : env.DAAS_SELF_SERVICE_API_URI,
  daasSelfServiceApiAgent,
);
