import { FormSection } from 'components';

import { CurrencyField } from './CurrencyField';
import {
  DefaultCreditLimitField,
  DefaultInvoiceFeeField,
  DefaultOverdueInterestField,
  DefaultPaymentTermDelayField,
} from './DefaultInvoiceSettingsFields';
import { IsTippingEnabledField } from './IsTippingEnabledField';
import { DailyTransactionLimitField, SingleTransactionLimitField } from './TransactionLimitField';

type Props = {
  alwaysShowError?: boolean;
  currency?: string;
};

export const InvoiceDetailsFormSection = ({ alwaysShowError, currency }: Props) => {
  return (
    <FormSection title="Invoice details">
      <CurrencyField />
      <SingleTransactionLimitField alwaysShowError={alwaysShowError} currency={currency} />
      <DailyTransactionLimitField alwaysShowError={alwaysShowError} currency={currency} />
      <DefaultCreditLimitField alwaysShowError={alwaysShowError} currency={currency} />
      <DefaultInvoiceFeeField alwaysShowError={alwaysShowError} />
      <DefaultOverdueInterestField alwaysShowError={alwaysShowError} />
      <DefaultPaymentTermDelayField alwaysShowError={alwaysShowError} />
      <IsTippingEnabledField />
    </FormSection>
  );
};
