import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { PaginatedData } from 'types/PaginatedData';
import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchEvents, FetchEventsParams } from './api';
import { Event } from './types';

export const useFetchEvents = <T = PaginatedData<Event>>(
  data: FetchEventsParams,
  config?: UseQueryOptions<PaginatedData<Event>, unknown, T>,
) => {
  const { data: corporate } = useCorporate();
  const queryFn = useCallback(() => {
    if (!corporate) {
      return Promise.reject('Corporate is missing');
    }
    return fetchEvents(corporate.id, data);
  }, [corporate, data]);
  return useQuery({ queryKey: ['events', corporate?.id, data], queryFn, ...config });
};
