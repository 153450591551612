import { EditCorporateData, EditCorporateRestrictedData } from 'modules/corporates/api';
import { convertFromAmountToCents, isDefined } from 'utils';

import {
  EditCorporateFormValues,
  ElectronicInvoicingDetailsFormValues,
} from '../../../views/settings/CompanySettings/types';

const formatElectronicInvoicingDetailsBeforeSending = (
  data: ElectronicInvoicingDetailsFormValues | null | undefined,
) => {
  if (!isDefined(data)) {
    return undefined;
  }

  if (!data) {
    return data;
  }

  return Object.values(data).every((field) => field === '') ? null : data;
};

export const formatInvoiceFormValuesBeforeSending = (
  {
    account_manager,
    billing_twice_a_month,
    credit_limit_amount,
    customer_reference,
    electronic_invoicing_details,
    invoice_email_cc_recipients,
    invoice_email,
    invoice_fee_percentage,
    invoice_overdue_interest,
    is_electronic_invoicing_enabled,
    organise_invoice_by_groups,
    organise_order_report_by_groups,
    payment_term_delay_days,
    preferred_invoice_locale,
    single_receipt_email,
    transaction_limits,
    vat_id,
  }: Partial<EditCorporateFormValues>,
  currency: string,
): EditCorporateData & EditCorporateRestrictedData => {
  return {
    account_manager: isDefined(account_manager) ? account_manager.trim() : undefined,
    billing_twice_a_month: isDefined(billing_twice_a_month)
      ? billing_twice_a_month === 'true'
      : undefined,
    credit_limit_amount: isDefined(credit_limit_amount)
      ? convertFromAmountToCents(credit_limit_amount, currency)
      : undefined,
    customer_reference: isDefined(customer_reference) ? customer_reference.trim() : undefined,
    electronic_invoicing_details:
      is_electronic_invoicing_enabled === false
        ? null
        : formatElectronicInvoicingDetailsBeforeSending(electronic_invoicing_details),
    has_credit_limit: isDefined(credit_limit_amount)
      ? Number.isInteger(Number.parseInt(credit_limit_amount))
      : undefined,
    has_transaction_limits:
      isDefined(transaction_limits) && transaction_limits !== null
        ? Number.isInteger(Number.parseInt(transaction_limits.daily_transactions_amount)) &&
          Number.isInteger(Number.parseInt(transaction_limits.single_transaction_amount))
        : undefined,
    invoice_email_cc_recipients: isDefined(invoice_email_cc_recipients)
      ? invoice_email_cc_recipients
      : undefined,
    invoice_email: isDefined(invoice_email) ? invoice_email.trim() : undefined,
    invoice_fee_percentage: isDefined(invoice_fee_percentage)
      ? Number.parseFloat(Number.parseFloat(invoice_fee_percentage.replace(',', '.')).toFixed(2))
      : undefined,
    invoice_overdue_interest: isDefined(invoice_overdue_interest)
      ? Number.parseInt(invoice_overdue_interest.replace(',', '.'))
      : undefined,
    is_electronic_invoicing_enabled,
    organise_invoice_by_groups: isDefined(organise_invoice_by_groups)
      ? organise_invoice_by_groups === 'true'
      : undefined,
    organise_order_report_by_groups: isDefined(organise_order_report_by_groups)
      ? organise_order_report_by_groups === 'true'
      : undefined,
    payment_term_delay_days: isDefined(payment_term_delay_days)
      ? Number.parseInt(payment_term_delay_days)
      : undefined,
    preferred_invoice_locale: isDefined(preferred_invoice_locale)
      ? preferred_invoice_locale.trim()
      : undefined,
    single_receipt_email: isDefined(single_receipt_email) ? single_receipt_email.trim() : undefined,
    transaction_limits:
      isDefined(transaction_limits) && transaction_limits !== null
        ? {
            daily_transactions_amount: convertFromAmountToCents(
              transaction_limits.daily_transactions_amount,
              currency,
            ),
            single_transaction_amount: convertFromAmountToCents(
              transaction_limits.single_transaction_amount,
              currency,
            ),
          }
        : undefined,
    vat_id: isDefined(vat_id) ? vat_id.trim() : undefined,
  };
};
