import { Alert } from '@creditornot/cb-alert';
import { elevationClassName } from '@creditornot/cb-ingredients';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { CoordinatesPicker, CoordinatesPickerProps } from './CoordinatesPicker';

type AlertVariantType = 'error' | 'info' | 'success' | 'warning';

type Props = CoordinatesPickerProps & {
  showInitialAlert: boolean;
  alertMessage: string;
  alertVariant?: AlertVariantType;
  showPlaceholderContainer?: boolean;
};

const AlertContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 16px;
  z-index: 2;
  width: calc(100% - 32px);
  border-radius: 8px;
`;

const Root = styled.div`
  position: relative;
  height: 290px;
`;

export const CoordinatesPickerWithAlert: FC<Props> = ({
  showInitialAlert,
  alertVariant = 'warning',
  alertMessage,
  showPlaceholderContainer = false,
  ...rest
}) => {
  const [showAlert, setShowAlert] = useState<boolean>(showInitialAlert);

  // Return placeholder div, so we can keep the same height as the original component
  if (showPlaceholderContainer) {
    return <Root />;
  }

  return (
    <Root>
      {showAlert && (
        <AlertContainer className={elevationClassName.medium}>
          <Alert onCloseButtonClick={() => setShowAlert(false)} size="small" variant={alertVariant}>
            {alertMessage}
          </Alert>
        </AlertContainer>
      )}
      <CoordinatesPicker style={{ borderRadius: 8 }} {...rest} />
    </Root>
  );
};
