import * as t from 'io-ts';

export type Invoice = t.TypeOf<typeof Invoice>;
export const Invoice = t.type({
  corporate_id: t.string,
  created_at: t.string,
  currency: t.string,
  email_sent_at: t.union([t.string, t.null]),
  end_date: t.string,
  id: t.string,
  modified_at: t.string,
  order_list_csv_url: t.string,
  order_list_pdf_url: t.string,
  payment_reference_id: t.string,
  pdf_url: t.string,
  receipts_archive_url: t.string,
  start_date: t.string,
  total_amount: t.number,
  vat_report_url: t.union([t.null, t.string]),
});
