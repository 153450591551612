import { color } from '@creditornot/cb-ingredients/design-tokens';
import React, { FC, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ThemeMode, Direction } from '@creditornot/cb-ingredients';
import { Keyframes, ThemeProvider as SCThemeProvider, createGlobalStyle } from 'styled-components';
import { CookbookProvider } from '@creditornot/cb-provider';

import { getLocalStorageItem, setLocalStorageItem } from 'modules/hooks';
import { useLanguageDirection } from 'i18n';

type ThemeProviderProps = {
  theme?: ThemeMode;
  children?: React.ReactNode;
};

interface CookbookSCTheme {
  cbDirection: Direction;
  cbThemeMode: ThemeMode;
}

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  [data-color-scheme="dark"] {
    color-scheme: dark;
  }

  [data-color-scheme="light"] {
    color-scheme: light;
  }
  
  *:focus {
    outline: none;
  }
  
  html {
    overflow: hidden;
  }
  
  body {
    color: ${color.text};
    background-color: ${color.bg};
    overflow: auto;
    font-feature-settings: 'kern';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  }

  svg {
    fill: ${color.text};
  }
`;

type ThemeContextValue = {
  themeMode: ThemeMode;
  setThemeMode: (theme: ThemeMode) => void;
};

const ThemeContext = createContext<ThemeContextValue>({
  themeMode: 'light',
  setThemeMode: () => {
    console.warn('Calling setThemeMode outside ThemeModeContext does nothing.');
  },
});

export const useThemeMode = (): ThemeContextValue => {
  return useContext(ThemeContext);
};

export function cssDirectionalValue<TValue extends string | Keyframes>(value: {
  [key in Direction]: TValue;
}) {
  return ({ theme: { cbDirection = 'ltr' } }: { theme: CookbookSCTheme }): TValue => {
    return value[cbDirection];
  };
}

const setScrollbarTheme = (theme: ThemeMode) => {
  // Remove scrollbars
  document.documentElement.style.overflow = 'hidden';
  // Trigger reflow so that overflow style is applied
  document.body.clientWidth;
  document.documentElement.setAttribute('data-color-scheme', theme);
  // Remove overflow style, which will bring back the scrollbar with the correct theme
  document.documentElement.style.overflow = '';
};

export const ThemeProvider: FC<ThemeProviderProps> = ({ children, theme }) => {
  const direction = useLanguageDirection();
  const [themeMode, setThemeMode] = useState<ThemeMode>(
    theme || getLocalStorageItem('themeMode') || 'light',
  );

  useEffect(() => setScrollbarTheme(themeMode), [themeMode]);

  const handleThemeModeChange = useCallback(
    (theme: ThemeMode) => {
      setLocalStorageItem('themeMode', theme);
      setThemeMode(theme);
    },
    [setThemeMode],
  );

  return (
    <ThemeContext.Provider value={{ themeMode, setThemeMode: handleThemeModeChange }}>
      <CookbookProvider themeMode={themeMode} direction={direction}>
        <SCThemeProvider
          theme={{
            cbDirection: direction,
            cbThemeMode: themeMode,
          }}
        >
          <GlobalStyle />

          {children}
        </SCThemeProvider>
      </CookbookProvider>
    </ThemeContext.Provider>
  );
};
