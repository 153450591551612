import styled from 'styled-components';
import { Column, DataTable } from '@creditornot/cb-data-table';
import { useIntl } from 'react-intl';

import { ApiErrorNotification, DataCellContent, ResponsiveContainer, UserName } from 'components';
import { useSubscriptionUsersQueryParams } from 'views/subscription/hooks/useSubscriptionUsersQueryParams';
import { SubscriptionUser } from 'modules/subscription/types';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';

import { SubscriptionUsersActionButtons } from './SubscriptionUsersActionButtons';
import { SubscriptionUserTag } from './SubscriptionUserTag';

interface Props {
  data?: SubscriptionUser[] | null;
  loading?: boolean;
  dataCellProps: ComponentProps;
  error?: any;
  pageCount?: number;
  selectedUsers: string[];
  onUserSelectionClick: (selectedRowKeys: string[], selectedRecords: SubscriptionUser[]) => void;
  onSelectAllUsers: () => void;
}

interface ComponentProps {
  onOpenDeleteUserModal: (id: string) => void;
}

const ErrorContainer = styled(ResponsiveContainer)`
  padding-top: 22px;
  padding-bottom: 22px;
`;

const useColumns = (): Column<SubscriptionUser, ComponentProps>[] => {
  const { formatMessage } = useIntl();
  const { isWoltEmployee } = useIsUserWoltEmployee();

  return [
    {
      id: 'name',
      name: formatMessage({ id: 'common.name' }),
      render: ({ record: { first_name, last_name, user_id, status } }) => {
        return (
          <DataCellContent disabled={status === 'disabled'}>
            <UserName
              userId={user_id}
              userName={`${first_name} ${last_name}`}
              shouldLinkToOpsTools={isWoltEmployee}
            />
          </DataCellContent>
        );
      },
    },
    {
      id: 'email',
      name: formatMessage({ id: 'common.email' }),
      render: ({ record: { status, email } }) => {
        return <DataCellContent disabled={status === 'disabled'}>{email}</DataCellContent>;
      },
    },
    {
      id: 'status',
      name: formatMessage({ id: 'common.status' }),
      style: {
        width: '15%',
      },
      render: ({ record: { status } }) => {
        return (
          <DataCellContent disabled={status === 'disabled'}>
            <SubscriptionUserTag status={status} />
          </DataCellContent>
        );
      },
    },
    {
      id: 'actions',
      style: {
        width: '8%',
      },
      name: '',
      render: ({ record: { user_id }, onOpenDeleteUserModal }) => {
        return (
          <SubscriptionUsersActionButtons
            id={user_id}
            onOpenDeleteUserModal={onOpenDeleteUserModal}
          />
        );
      },
    },
  ];
};

export const SubscriptionUserDataTable = ({
  data,
  loading,
  error,
  dataCellProps,
  pageCount,
  selectedUsers,
  onUserSelectionClick,
  onSelectAllUsers,
}: Props) => {
  const { formatMessage } = useIntl();
  const columns = useColumns();
  const [{ page = 1, page_size = 50 }, setQueryParams] = useSubscriptionUsersQueryParams();

  if (error) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={error} />
      </ErrorContainer>
    );
  }

  const hidePagination = loading || !data;

  return (
    <DataTable
      horizontalPadding="wide"
      dataSource={data ?? []}
      columns={columns}
      tableLayout="fixed"
      getRowKey={(user) => user.user_id}
      componentProps={{ ...dataCellProps }}
      loading={loading}
      rowSelection={{
        selectedRowKeys: selectedUsers,
        onChange: onUserSelectionClick,
        onSelectAll: onSelectAllUsers,
      }}
      emptyMessage={{ title: formatMessage({ id: 'wolt-plus.no-members' }) }}
      pagination={
        hidePagination
          ? undefined
          : {
              disableWhenLoading: true,
              pageCount: pageCount || 1,
              pageSize: page_size,
              activePage: page,
              onPageChange: (p) => setQueryParams({ page: p }),
              onPageSizeChange: (p) => {
                setQueryParams({ page: 1, page_size: p });
              },
              pageSizeOptions: [10, 20, 50, 100],
            }
      }
    />
  );
};
