import env from 'config/env';

// All resources are uploaded to this folder:
// https://github.com/creditornot/consumer-static-assets/tree/main/assets/corporate
const getResourcePathFromConsumerAssets = (name: string) =>
  `${env.CONSUMER_ASSETS_URI}/corporate/${name}`;

export const resources = {
  videos: {
    acceptInvitation: getResourcePathFromConsumerAssets('WfW_accept_invitation.mp4'),
    events: getResourcePathFromConsumerAssets('WfW_events_intro.mp4'),
    selectInvoicePaymentMethod: getResourcePathFromConsumerAssets(
      'select_invoice_payment_method.mp4',
    ),
    selectOrderDetails: getResourcePathFromConsumerAssets('select_order_details.mp4'),
    selectRestaurant: getResourcePathFromConsumerAssets('select_restaurant.mp4'),
  },
  guides: {
    groupOrder:
      'https://blog.wolt.com/fin/2020/11/06/teams-can-now-order-together-and-split-the-bill-with-wolt-at-work/',
    events: getResourcePathFromConsumerAssets('WfW_events_guide.pdf'),
    wfw_guide: getResourcePathFromConsumerAssets('WfW_guide.pdf'),
    multipayments: getResourcePathFromConsumerAssets('WfW_multipayments_guide.pdf'),
  },
};
