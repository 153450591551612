import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQueryClient } from 'modules/react-query';

import { deleteSubscriptionMembers } from './api';

type Props = {
  subscriptionId: string;
  userIds: Array<string>;
  invalidateDelay?: number;
};

export const useDeleteSubscriptionUsers = () => {
  const queryClient = useQueryClient();
  const { data: corporate } = useCorporate();

  return useCallback(
    async ({ subscriptionId, userIds, invalidateDelay }: Props) => {
      if (!corporate?.id) {
        return Promise.resolve();
      }
      await deleteSubscriptionMembers(corporate.id, subscriptionId, userIds);

      if (invalidateDelay) {
        setTimeout(() => {
          queryClient.invalidateQueries();
        }, invalidateDelay);
      } else {
        queryClient.invalidateQueries();
      }
    },
    [corporate?.id, queryClient],
  );
};
