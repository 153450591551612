import { FormikInputField } from 'components';
import { createValidator, isNumber, isRequired, min } from 'validation';

interface Props {
  alwaysShowError?: boolean;
  allowClear?: boolean;
  index: number;
}

export const CustomPriceFeeField = ({ alwaysShowError, index, allowClear = true }: Props) => {
  const validate = createValidator([isNumber, isRequired, min(0)]);
  return (
    <FormikInputField
      name={`price_increments.${index}.fee`}
      type="text"
      validate={validate}
      alwaysShowError={alwaysShowError}
      showErrorMessage={false}
      allowClear={allowClear}
    />
  );
};
