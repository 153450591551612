import { set, getYear } from 'date-fns';
import { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Input } from '@creditornot/cb-input';
import { Calendar } from '@creditornot/cb-icons';
import { MenuItemGroup } from '@creditornot/cb-menu';
import { LegacyPopover as Popover, PopoverPlacement } from '@creditornot/cb-popover';

import { useI18n } from 'i18n';

import { MonthValue } from './types';
import MonthPickerOptions from './MonthPickerOptions';
import MonthPickerNav from './MonthPickerNav';

interface Props {
  className?: string;
  onChange: (value: MonthValue) => void;
  onClear?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  value: MonthValue | null;
  displayValue?: string;
  placement?: PopoverPlacement;
}

const Root = styled.div`
  display: inline-block;
`;

const DropdownContent = styled(MenuItemGroup)`
  width: 240px;
  padding: 8px;
`;

const currentYear = getYear(new Date());

const MonthPicker: React.FC<Props> = ({
  className,
  disabled = false,
  onBlur,
  onChange,
  onClear,
  placement = 'bottom-end',
  displayValue,
  value,
}) => {
  const [yearValue, setYearValue] = useState(value?.year || currentYear);
  const [showOptions, setShowOptions] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const { getLocalizedDate } = useI18n();

  useEffect(() => {
    setTimeout(() => setYearValue(value?.year || currentYear), 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions]);

  const handleSelectedOptionClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setShowOptions((state) => !state);
    },
    [],
  );

  const handleMonthClick = useCallback(
    (month: number) => {
      onChange({
        month,
        year: yearValue,
      });
      onBlur?.();
      setShowOptions(false);
    },
    [onBlur, onChange, yearValue],
  );

  const handleLeftClick = useCallback(() => {
    setYearValue((state) => state - 1);
  }, []);

  const handleRightClick = useCallback(() => {
    setYearValue((state) => state + 1);
  }, []);

  const getIsSelectedMonth = useCallback(
    (month: number) => value?.month === month && value?.year === yearValue,
    [value?.month, value?.year, yearValue],
  );

  const handleClickOutSide = useCallback(() => {
    setShowOptions(false);
  }, []);

  return (
    <Root className={className} ref={rootRef}>
      <Popover
        onClickOutside={handleClickOutSide}
        placement={placement}
        show={disabled ? false : showOptions}
        renderArrow={false}
        disableContentPadding={true}
        content={
          <DropdownContent>
            <MonthPickerNav
              value={yearValue}
              onLeftClick={handleLeftClick}
              onRightClick={handleRightClick}
            />

            <MonthPickerOptions
              getIsSelectedMonth={getIsSelectedMonth}
              onMonthClick={handleMonthClick}
            />
          </DropdownContent>
        }
      >
        <Input
          icon={<Calendar />}
          disabled={disabled}
          readOnly={true}
          onClick={!disabled ? handleSelectedOptionClick : undefined}
          onClearClick={onClear}
          value={
            displayValue ||
            getLocalizedDate(
              set(new Date(), {
                year: value?.year,
                month: value?.month,
              }),
              {
                month: 'short',
                year: 'numeric',
              },
            )
          }
        />
      </Popover>
    </Root>
  );
};

export default MonthPicker;
