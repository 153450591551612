import { useCallback } from 'react';

import { useQuery, UseQueryOptions } from 'modules/react-query';

import { FetchCorporatesData, fetchCorporates } from './api';
import { Corporate, CorporateStatus } from './types';

const nonDisabledCorporateStatuses: CorporateStatus[] = [
  'submitted',
  'invite_pending',
  'details_pending',
  'verification_pending',
  'contract_pending',
  'active',
  'rejected',
];

export const useFetchCorporates = <T = Array<Corporate>>(
  data?: FetchCorporatesData,
  config?: UseQueryOptions<Array<Corporate>, unknown, T>,
) => {
  const queryFn = useCallback(() => {
    if (!data) {
      // Fetches all non-disabled corporates by default
      return fetchCorporates({ statuses: nonDisabledCorporateStatuses });
    }
    const { name, country_code, statuses, products } = data;
    return fetchCorporates({
      name,
      country_code,
      statuses,
      products,
    });
  }, [data]);
  return useQuery({
    queryKey: ['corporates', data],
    queryFn,
    ...config,
  });
};
