import { Route, Switch } from 'react-router-dom';

import { SettingsView } from './SettingsView';

export const RootRoutes = () => (
  <Switch>
    <Route exact path="/:corporateId/settings" component={SettingsView} />
    <Route exact path="/:corporateId/settings/delivery-settings" component={SettingsView} />
  </Switch>
);
