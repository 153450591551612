import styled from 'styled-components';
import { rem } from 'polished';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { UseFormReset } from 'react-hook-form';

import { Corporate } from 'modules/corporates/types';
import { ApiErrorNotification, FormSection, Spinner } from 'components';
import { useEditDriveConfig } from 'modules/corporates/useEditDriveConfig';

import { DriveConfig } from '../SignupV2/components/DriveConfig/DriveConfig';
import {
  B2bConfigFormProvider,
  transformB2bConfigToDefaultFormValues,
} from '../CompanySettings/B2bConfigSettings/B2bConfigFormProvider';
import { B2bConfigFormValues } from '../CompanySettings/B2bConfigSettings/types';

export type B2bConfigSettingsProps = {
  corporate: Corporate;
};

const SpinnerBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const StyledSpinner = styled(Spinner)`
  margin-top: ${rem(16)};
`;

const ApiErrorNotificationContainer = styled.div`
  width: 100%;
  display: flex;
  padding: ${rem(16)} ${rem(24)};
`;

export const DriveConfigContainer = ({ corporate }: B2bConfigSettingsProps) => {
  const { formatMessage } = useIntl();
  const [state, setState] = useState<'view' | 'edit'>('view');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const { mutateDriveConfig, mutateError, fetchState, fetchError, b2bConfigWithFallback } =
    useEditDriveConfig(corporate);

  const submitHandler = async (
    formData: B2bConfigFormValues,
    reset: UseFormReset<B2bConfigFormValues>,
  ) => {
    setShowErrorMessage(false);
    try {
      await mutateDriveConfig(formData);
      setState('view');
      reset(formData);
      document.getElementById('drive-config')?.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      setShowErrorMessage(true);
      console.error(error);
    }
  };

  // The user should be able to use the form with 404 error, so it's not handled here
  if (['loading', 'error'].includes(fetchState)) {
    return (
      <FormSection title={formatMessage({ id: 'groups-form.drive-configuration-section-title' })}>
        {fetchState === 'loading' && (
          <SpinnerBox>
            <StyledSpinner size="medium" />
          </SpinnerBox>
        )}
        {fetchState === 'error' && (
          <ApiErrorNotificationContainer>
            <ApiErrorNotification error={fetchError} />
          </ApiErrorNotificationContainer>
        )}
      </FormSection>
    );
  }

  return (
    <B2bConfigFormProvider b2bConfig={b2bConfigWithFallback} currency={corporate.currency}>
      <DriveConfig
        corporate={corporate}
        showError={showErrorMessage && !!mutateError}
        error={mutateError}
        is404={fetchState === '404Error'}
        renderMode={state}
        onEditClick={() => setState('edit')}
        onCancelClick={(reset) => {
          reset(transformB2bConfigToDefaultFormValues(b2bConfigWithFallback, corporate.currency));
          setState('view');
        }}
        submitHandler={submitHandler}
      />
    </B2bConfigFormProvider>
  );
};
