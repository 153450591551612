import { InputContainer } from '@creditornot/cb-form';

import { useI18n } from 'i18n';
import {
  createValidator,
  isRequired,
  isEmail,
  doesNotContainDuplicateEmails,
  isAListOfValidEmails,
  isDuplicateEmail,
} from 'validation';

import FormikEmailFileInput from './FormikEmailFileInput';

interface Props {
  className?: string;
}

const validateEmails = createValidator([
  isRequired,
  doesNotContainDuplicateEmails,
  isAListOfValidEmails,
]);

export const FileInputInviteUserForm: React.FC<Props> = ({ className }) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <InputContainer
      className={className}
      label={getLocalizedMessage('users.new-user-modal.import-users-button-label')}
      hint={getLocalizedMessage('users.new-user-modal.import-users-hint')}
    >
      <FormikEmailFileInput
        name="emails"
        validate={validateEmails}
        validateEmail={(email, index, emails) =>
          isDuplicateEmail(email, index, emails) || isEmail(email)
        }
      />
    </InputContainer>
  );
};
