import * as t from 'io-ts';

export type Summary = t.TypeOf<typeof Summary>;
export const Summary = t.type({
  active_users_amount: t.number,
  expiring_invites_amount: t.number,
  groups_amount: t.number,
  pending_invites_amount: t.number,
  policies_amount: t.number,
});

export type PurchaseSummary = t.TypeOf<typeof PurchaseSummary>;
export const PurchaseSummary = t.type({
  purchase_total_amount: t.number,
  purchase_count: t.number,
});
