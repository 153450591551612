import { GetCorporateResponse, SmsNotificationEventEnum } from '@creditornot/wd-api-client';
import { useState } from 'react';

import { useEditSelfServiceCorporate } from 'modules/corporates';
import { corporateDeliveryMessageSettingsComplete } from 'modules/corporates/utils';
import { useFetchB2bConfig, useUpdateB2bConfig } from 'modules/b2b-configs';
import { FormStates } from 'components/FormCard';

import { SmsNotificationsFormValues } from './types';
import { SmsNotificationsForm } from './SmsNotificationsForm';

export const SmsNotificationsSettings = ({
  selfServiceCorporate,
  corporateId,
}: {
  selfServiceCorporate: GetCorporateResponse;
  corporateId: string;
}) => {
  const editB2bConfig = useUpdateB2bConfig();
  const { data: b2bConfig } = useFetchB2bConfig(corporateId);
  const { mutateAsync: editCorporateSettings } = useEditSelfServiceCorporate();

  const [renderState, setRenderState] = useState<FormStates>(
    !corporateDeliveryMessageSettingsComplete(selfServiceCorporate) ? 'edit' : 'view',
  );

  const submitHandler = async ({
    pickedupMessage,
    receivedMessage,
  }: SmsNotificationsFormValues) => {
    await editCorporateSettings({
      woltCorporateId: corporateId,
      body: {
        smsNotifications: [
          { event: SmsNotificationEventEnum.PickedUp, messageText: pickedupMessage },
          { event: SmsNotificationEventEnum.Received, messageText: receivedMessage },
        ].filter((sms) => sms.messageText),
      },
    });
    if (b2bConfig) {
      await editB2bConfig.mutateAsync({
        corporateId,
        payload: {
          sms_notification_copy: receivedMessage,
        },
      });
    }
    setRenderState('view');
  };

  return (
    <SmsNotificationsForm
      selfServiceCorporate={selfServiceCorporate}
      renderState={renderState}
      onSubmit={submitHandler}
      onRenderStateChange={setRenderState}
    />
  );
};
