import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQueryClient } from 'modules/react-query';

import { resendEventInviteToUser } from './api';

export const useResendEventInviteToUser = () => {
  const queryClient = useQueryClient();
  const { data: corporate } = useCorporate();

  return useCallback(
    async (inviteId: string) => {
      if (!corporate) {
        return Promise.reject('Corporate is missing');
      }
      await resendEventInviteToUser(corporate.id, inviteId);
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 500);
    },
    [corporate, queryClient],
  );
};
