import styled from 'styled-components';
import { rem } from 'polished';
import React from 'react';
import { Input, InputAlert } from '@creditornot/cb-input';
import { Trashbin } from '@creditornot/cb-icons';
import { Alert } from '@creditornot/cb-alert';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, IconButton } from '@creditornot/cb-button';
import { AnimatePresence, motion } from 'framer-motion';

import { parseCurrency, useI18n } from 'i18n';
import { IconWithHelpText } from 'components';
import { convertFromAmountToCents } from 'utils';

import { B2bConfigFormValues } from '../types';

interface Props {
  className?: string;
  currency: string;
}

const InputWithNoArrow = styled(Input)`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    appearance: textfield;
  }
`;

const Root = styled.div``;

const StyledTh = styled.th`
  text-align: start;
  font-weight: 400;
`;

const AddNewRange = styled(Button)`
  margin-top: ${rem(8)};
`;

const StyledTd = styled.td`
  padding: 0 ${rem(4)} 0 0;
`;

const StyledTable = styled.table`
  table-layout: fixed;
  border-spacing: ${rem(8)} ${rem(8)};
`;

const ErrorInputAlert = styled(InputAlert).attrs({
  variant: 'error',
})`
  padding: ${rem(4)} 0;
`;

const StyledHelpIconWithTooltip = styled(IconWithHelpText).attrs({
  infoTooltipMaxWidth: 247,
  popoverProps: { contentZIndex: 1000 },
})`
  display: inline;
  align-self: baseline;
  margin-inline-start: ${rem(8)};
`;

type AnimatedErrorProps = {
  error: string | undefined;
  errorPrefix: string;
};

const AnimatedError = ({ error, errorPrefix }: AnimatedErrorProps) => {
  return (
    <AnimatePresence>
      {error && (
        <motion.tr
          initial={{ opacity: 0, height: '0' }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: '0' }}
        >
          <StyledTd colSpan={4}>
            <ErrorInputAlert>
              [{errorPrefix}]: {error}
            </ErrorInputAlert>
          </StyledTd>
        </motion.tr>
      )}
    </AnimatePresence>
  );
};

export const CustomPricingTable = ({ className, currency }: Props) => {
  const { getLocalizedMessage } = useI18n();

  const {
    control,
    register,
    watch,
    getFieldState,
    trigger,
    formState: { errors, touchedFields },
  } = useFormContext<B2bConfigFormValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'pricing.customPricing.distanceRanges',
  });

  const basePrice = watch('pricing.customPricing.basePrice');
  const priceIncrements = watch('pricing.customPricing.distanceRanges');
  const priceIncrementsMeta = getFieldState('pricing.customPricing.distanceRanges');

  const basePriceInCents =
    !basePrice || isNaN(basePrice) ? 0 : convertFromAmountToCents(basePrice, currency);
  const priceIncrementsInCents = priceIncrements.map(({ fee, distanceMax, distanceMin }) => {
    return {
      fee: !fee || isNaN(fee) ? 0 : convertFromAmountToCents(fee, currency),
      distanceMin,
      distanceMax,
    };
  });

  return (
    <Root className={className}>
      <StyledTable>
        <thead>
          <tr>
            <StyledTh>
              {getLocalizedMessage('corporate-form.custom-price.from')}
              <StyledHelpIconWithTooltip
                infoText={getLocalizedMessage('corporate-form.custom-price.from.help')}
              />
            </StyledTh>
            <StyledTh>
              {getLocalizedMessage('corporate-form.custom-price.to')}
              <StyledHelpIconWithTooltip
                infoText={getLocalizedMessage('corporate-form.custom-price.to.help')}
              />
            </StyledTh>
            <StyledTh>
              {getLocalizedMessage('corporate-form.custom-price.price')}
              <StyledHelpIconWithTooltip
                infoText={getLocalizedMessage('corporate-form.custom-price.price.help')}
              />
            </StyledTh>
            <StyledTh>
              {getLocalizedMessage('corporate-form.custom-price.total')}
              <StyledHelpIconWithTooltip
                infoText={getLocalizedMessage('corporate-form.custom-price.total.help')}
              />
            </StyledTh>
          </tr>
        </thead>
        <tbody>
          {fields.map((priceIncrement, index) => {
            const { ref: minRegisterRef, ...minRegister } = register(
              `pricing.customPricing.distanceRanges.${index}.distanceMin`,
              {
                valueAsNumber: true,
                onChange: () => {
                  touchedFields.pricing?.customPricing?.distanceRanges?.forEach(
                    (distanceRange, i) => {
                      if (distanceRange.distanceMin) {
                        trigger(`pricing.customPricing.distanceRanges.${i}.distanceMin`);
                      }
                      if (distanceRange.distanceMax) {
                        trigger(`pricing.customPricing.distanceRanges.${i}.distanceMax`);
                      }
                    },
                  );
                },
              },
            );
            const { ref: maxRegisterRef, ...maxRegister } = register(
              `pricing.customPricing.distanceRanges.${index}.distanceMax`,
              {
                valueAsNumber: true,
                onChange: () => {
                  touchedFields.pricing?.customPricing?.distanceRanges?.forEach(
                    (distanceRange, i) => {
                      if (distanceRange.distanceMin) {
                        trigger(`pricing.customPricing.distanceRanges.${i}.distanceMin`);
                      }
                      if (distanceRange.distanceMax) {
                        trigger(`pricing.customPricing.distanceRanges.${i}.distanceMax`);
                      }
                    },
                  );
                },
              },
            );
            const { ref: feeRegisterRef, ...feeRegister } = register(
              `pricing.customPricing.distanceRanges.${index}.fee`,
              {
                valueAsNumber: true,
              },
            );

            return (
              <React.Fragment key={priceIncrement.id}>
                <tr>
                  <StyledTd>
                    <InputWithNoArrow
                      {...minRegister}
                      type="number"
                      inputRef={minRegisterRef}
                      invalid={
                        !!errors.pricing?.customPricing?.distanceRanges?.[index]?.distanceMin
                          ?.message
                      }
                    />
                  </StyledTd>
                  <StyledTd>
                    <InputWithNoArrow
                      {...maxRegister}
                      type="number"
                      inputRef={maxRegisterRef}
                      invalid={
                        !!errors.pricing?.customPricing?.distanceRanges?.[index]?.distanceMax
                          ?.message
                      }
                    />
                  </StyledTd>
                  <StyledTd>
                    <InputWithNoArrow
                      {...feeRegister}
                      type="number"
                      inputRef={feeRegisterRef}
                      invalid={
                        !!errors.pricing?.customPricing?.distanceRanges?.[index]?.fee?.message
                      }
                      step={0.01}
                    />
                  </StyledTd>
                  <StyledTd>
                    <Input
                      value={parseCurrency(
                        priceIncrementsInCents[index].fee + basePriceInCents,
                        currency,
                      )}
                      disabled
                    />
                  </StyledTd>
                  <StyledTd>
                    <IconButton
                      size="small"
                      variant="red"
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      <Trashbin width={16} height={16} />
                    </IconButton>
                  </StyledTd>
                </tr>
                <AnimatedError
                  errorPrefix="From"
                  error={
                    errors.pricing?.customPricing?.distanceRanges?.[index]?.distanceMin?.message
                  }
                />
                <AnimatedError
                  errorPrefix="To"
                  error={
                    errors.pricing?.customPricing?.distanceRanges?.[index]?.distanceMax?.message
                  }
                />
                <AnimatedError
                  errorPrefix="Fee"
                  error={errors.pricing?.customPricing?.distanceRanges?.[index]?.fee?.message}
                />
              </React.Fragment>
            );
          })}
        </tbody>
      </StyledTable>
      {typeof priceIncrementsMeta.error?.message === 'string' && (
        <Alert variant="error" size="small">
          {priceIncrementsMeta.error.message}
        </Alert>
      )}
      <AddNewRange
        role="button"
        variant="lightBlue"
        size="small"
        onClick={() => {
          append(
            {
              distanceMax: 0,
              distanceMin: 0,
              fee: 0,
            },
            {
              shouldFocus: false,
            },
          );
        }}
      >
        + {getLocalizedMessage('corporate-form.custom-price.new-range')}
      </AddNewRange>
    </Root>
  );
};
