import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQuery, UseQueryOptions } from 'modules/react-query';
import { User } from 'modules/users/types';

import { fetchGroupUsers } from './api';

export const useFetchGroupUsers = <T = User[]>(
  groupId: string | null,
  config?: UseQueryOptions<User[], unknown, T>,
) => {
  const { data: corporate } = useCorporate();
  const queryFn = useCallback(() => {
    if (!corporate || !groupId) {
      return Promise.reject();
    }
    return fetchGroupUsers(corporate.id, groupId);
  }, [corporate, groupId]);
  return useQuery({
    queryKey: ['group-users', corporate?.id, groupId],
    queryFn,
    enabled: !!(corporate && groupId),
    ...config,
  });
};
