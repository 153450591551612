import styled from 'styled-components';
import { Tag } from '@creditornot/cb-tag';

interface Props {
  tagValues?: string[] | null;
  invalidValues?: string[] | null;
  handleClearClick: (value: string, index: number) => void;
  className?: string;
}

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 154px;
  margin: 92px 12px 4px;
`;

const StyledTag = styled(Tag)`
  margin: 0 8px 8px 0;
`;

const TagsContainer: React.FC<Props> = ({
  tagValues,
  invalidValues,
  handleClearClick,
  className,
}) => {
  return (
    <Root className={className}>
      {tagValues?.map((value, index) => (
        <StyledTag
          variant={invalidValues?.includes(value) ? 'lightRed' : 'lightBlue'}
          key={`${value}-${index}`}
          onRemoveClick={() => handleClearClick(value, index)}
        >
          {value}
        </StyledTag>
      ))}
    </Root>
  );
};

export default TagsContainer;
