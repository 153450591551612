import axios from 'axios';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import Lottie, { LottieProps } from 'react-lottie';

import { lottieAnimations, LottieSizes } from './utils';

type Props = Partial<LottieProps> & {
  className?: string;
  animation: keyof typeof lottieAnimations;
  size?: LottieSizes;
};

const useGetAnimationData = (animationUrl: string) => {
  const [animationData, setAnimationData] = useState<JSON | null>(null);
  useEffect(() => {
    const apiCall = axios.get(animationUrl);
    apiCall.catch(() => setAnimationData(null));
    apiCall.then(({ data }) => setAnimationData(data));
  }, [animationUrl, setAnimationData]);
  return animationData;
};

/**
 * TODO: add more transition
 */
export const LottieComponent = ({
  className,
  animation,
  size = 'wide',
  options,
  ...props
}: Props) => {
  const animationData = useGetAnimationData(lottieAnimations[animation](size));
  return (
    <motion.div
      className={className}
      transition={{
        duration: 1.2,
        ease: 'easeInOut',
      }}
      animate={{
        opacity: [0, 1],
      }}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          ...options,
          animationData,
        }}
        {...props}
      />
    </motion.div>
  );
};
