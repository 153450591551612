import {
  CorporatesApiUpdateCorporateRequest,
  GetCorporateResponse,
} from '@creditornot/wd-api-client';
import { useMutation, useQueryClient } from 'react-query';

import { selfServiceCorporateApiClient } from 'modules/corporates/api';

export const useEditSelfServiceCorporate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CorporatesApiUpdateCorporateRequest) => {
      const corporate = queryClient.getQueryData<GetCorporateResponse>([
        'delivery-settings',
        payload.woltCorporateId,
      ]);
      // read from store and keep old values to ensure all properties required in PUT requests are present
      return selfServiceCorporateApiClient.updateCorporate({
        ...(corporate ?? {}),
        ...payload,
      });
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries({ queryKey: ['delivery-settings', data.woltCorporateId] });
      },
    },
  );
};
