import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  B2bCustomerConfigResponse,
  DiscountType,
  DistanceBasedDeliveryPricing,
  PricingResponse,
} from '@creditornot/daas-core-api-client';

import { useWoltForm } from 'modules/react-hook-form/useWoltForm';
import { parseCurrency } from 'i18n';

import { B2bConfigFormValues, B2bPricing, Discount, DistanceRange } from './types';
import { b2bConfigYup } from './b2bConfigValidation';

type Props = {
  children: React.ReactNode;
  b2bConfig: B2bCustomerConfigResponse;
  currency: string;
};

const transformType = (type: PricingResponse['type']): B2bPricing['type'] => {
  switch (type) {
    case 'flat_fee':
      return 'flatFee';
    case 'distance_based':
      return 'distanceBased';
    case 'custom':
      return 'custom';
    default:
      throw new Error(`Unknown pricing type: ${type}`);
  }
};

const transformDiscountType = (type: DiscountType | undefined): Discount['type'] => {
  switch (type) {
    case 'flat':
      return 'flat';
    case 'total_price_percentage':
      return 'percentage';
    default:
      return 'noDiscount';
  }
};

const transformPricingRanges = (
  customPricing: DistanceBasedDeliveryPricing | undefined,
  currency: string,
): DistanceRange[] => {
  if (!customPricing) {
    return [{ distanceMax: 0, distanceMin: 0, fee: 0 }];
  }
  return customPricing.distance_ranges.map((range) => ({
    distanceMin: range.distance_min,
    distanceMax: range.distance_max,
    fee: parseCurrency(range.fee, currency),
  }));
};

const transformB2bConfigPriceResponse = (
  b2bConfig: B2bCustomerConfigResponse,
  currency: string,
): B2bConfigFormValues['pricing'] => {
  const { pricing } = b2bConfig;

  return {
    type: transformType(pricing?.type),
    discounts:
      pricing?.discounts && pricing.discounts.length > 0
        ? {
            type: transformDiscountType(pricing.discounts[0].type),
            percentageValue:
              pricing.discounts[0].type === 'total_price_percentage'
                ? pricing.discounts[0].value
                : 0,
            flatValue:
              pricing.discounts[0].type === 'flat'
                ? parseCurrency(pricing.discounts[0].value, currency)
                : 0,
          }
        : {
            type: 'noDiscount',
            percentageValue: 0,
            flatValue: 0,
          },
    customPricing: {
      basePrice: pricing?.custom_pricing
        ? parseCurrency(pricing.custom_pricing.base_price.amount, currency)
        : 0,
      distanceRanges: transformPricingRanges(pricing?.custom_pricing, currency),
    },
  };
};

export const transformB2bConfigToDefaultFormValues = (
  b2bConfig: B2bCustomerConfigResponse,
  currency: string,
) =>
  ({
    venueId: b2bConfig.dynamic_pickup_venue_id,
    merchantId: b2bConfig.wolt_merchant_id,
    isCashEnabled: b2bConfig.is_cash_enabled,
    isIntercomEnabled: b2bConfig.is_intercom_enabled,
    isCustomDeliveryDistance: !b2bConfig.delivery_distance_meters ? 'isNotCustom' : 'isCustom',
    deliveryDistance: b2bConfig.delivery_distance_meters || 0,
    pricing: transformB2bConfigPriceResponse(b2bConfig, currency),
  }) as B2bConfigFormValues;

export const B2bConfigFormProvider = ({ children, b2bConfig, currency }: Props) => {
  const intl = useIntl();

  const methods = useWoltForm<B2bConfigFormValues>({
    resolver: yupResolver(b2bConfigYup(intl)),
    defaultValues: transformB2bConfigToDefaultFormValues(b2bConfig, currency),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
