import * as t from 'io-ts';

export type GroupOrderOptionValue = t.TypeOf<typeof GroupOrderOptionValue>;
export const GroupOrderOptionValue = t.type({
  count: t.number,
  external_id: t.string,
  id: t.string,
  price: t.number,
});

export type GroupOrderItemOption = t.TypeOf<typeof GroupOrderItemOption>;
export const GroupOrderItemOption = t.type({
  id: t.string,
  values: t.array(GroupOrderOptionValue),
});

export type BasketItem = t.TypeOf<typeof BasketItem>;
export const BasketItem = t.type({
  baseprice: t.number,
  count: t.number,
  end_amount: t.number,
  id: t.string,
  options: t.array(GroupOrderItemOption),
  row_number: t.number,
});

export type ParticipantBasket = t.TypeOf<typeof ParticipantBasket>;
export const ParticipantBasket = t.type({
  comment: t.string,
  items: t.array(BasketItem),
});

export type Participant = t.TypeOf<typeof Participant>;
export const Participant = t.type({
  accounting_comment: t.union([t.null, t.string]),
  basket: ParticipantBasket,
  emoji: t.union([t.null, t.string]),
  first_name: t.string,
  guest_id: t.union([t.null, t.string]),
  payment_method_id: t.union([t.null, t.string]),
  last_name: t.union([t.null, t.string]),
  profile_picture_url: t.string,
  status: t.string,
  subscribed: t.boolean,
  user_id: t.string,
});

export type GroupOrderDetails = t.TypeOf<typeof GroupOrderDetails>;
export const GroupOrderDetails = t.type({
  host_id: t.string,
  id: t.string,
  participants: t.array(Participant),
  split_payment: t.boolean,
});
