import styled from 'styled-components';
import { Formik, Field, FieldProps, FormikHelpers } from 'formik';
import { InputContainer } from '@creditornot/cb-form';
import { Button } from '@creditornot/cb-button';
import { Checkmark } from '@creditornot/cb-icons';
import { typographyCss } from '@creditornot/cb-ingredients';
import { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  RadioInputCardGroup,
  FormFooter,
  ApiErrorNotification,
  ResponsiveContainer,
  FormikMultiSelectField,
} from 'components';
import { useEditUser } from 'modules/users';
import { Group } from 'modules/groups/types';
import { UserV2 } from 'modules/users/types';
import { shallowObjectDifferences } from 'utils';
import { corporateFeatureFlag } from 'modules/corporates/utils';
import { UI_SUCCESS_DELAY } from 'modules/common/constants';
import { Corporate } from 'modules/corporates/types';
import { formatToUuid } from 'modules/common/utils';

import { EditUserFormValues as FormValues } from '../types';

interface Props {
  corporate: Corporate;
  user: UserV2;
  groups: Group[] | undefined;
  onEditUserSuccess: () => void;
  onClose: () => void;
}

const Container = styled.div`
  overflow-y: auto;
  height: 100%;
  padding: 24px;
`;

const Title = styled.div`
  ${typographyCss.Title1()};
  margin-bottom: 24px;
`;

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: 24px;
  }
`;

const ApiErrorContainer = styled(ResponsiveContainer)`
  margin-bottom: 32px;
`;

export const EditUserForm: React.FC<Props> = ({
  corporate,
  user,
  groups,
  onClose,
  onEditUserSuccess,
}) => {
  const { formatMessage } = useIntl();
  const editUser = useEditUser();
  const { groupEnabled } = corporateFeatureFlag(corporate);

  const normalizedGroups = useMemo(() => {
    if (!groups) {
      return undefined;
    }

    // Group ids from r-api are not in UUID format, but group ids from corporate-service are UUID
    return groups.map((group) => ({ ...group, id: formatToUuid(group.id) }));
  }, [groups]);

  const initialValues: FormValues = useMemo(
    () => ({
      status: user.status,
      is_admin: user.is_admin.toString(),
      group_ids: user.groups.map((group) => group.id),
    }),
    [user],
  );

  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      try {
        const data = shallowObjectDifferences(values, initialValues);
        await editUser({ userId: user.user_id, data, invalidateDelay: UI_SUCCESS_DELAY });
        actions.setStatus({ submitSucceeded: true });

        setTimeout(() => {
          actions.resetForm();
          onEditUserSuccess();
        }, UI_SUCCESS_DELAY);
      } catch (error) {
        actions.setStatus({ error });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [editUser, initialValues, onEditUserSuccess, user],
  );

  return (
    <Formik
      initialValues={initialValues}
      initialStatus={{}}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ status: { error, submitSucceeded }, submitForm, isSubmitting, dirty }) => {
        return (
          <>
            <Container>
              <Title>
                <FormattedMessage id="users.edit-user.user-infomation" />
              </Title>

              <StyledInputContainer label={formatMessage({ id: 'common.name' })}>
                {`${user.name.first} ${user.name.last}`}
              </StyledInputContainer>

              <StyledInputContainer
                style={{ marginBottom: '48px' }}
                label={formatMessage({ id: 'common.email' })}
              >
                {user.email}
              </StyledInputContainer>

              <Title>
                <FormattedMessage id="user.edit-user.other-settings" />
              </Title>

              <StyledInputContainer label={formatMessage({ id: 'users.edit-user.status' })}>
                <Field name="status">
                  {({ field: { value, name, onChange } }: FieldProps) => (
                    <RadioInputCardGroup
                      onChange={onChange}
                      value={value}
                      direction="column"
                      name={name}
                      options={[
                        {
                          label: formatMessage({ id: 'common.status.active' }),
                          explanation: formatMessage(
                            { id: 'users.status.active-explanation' },
                            {
                              company_name: corporate.name,
                            },
                          ),
                          value: 'active',
                        },
                        {
                          label: formatMessage({ id: 'common.status.inactive' }),
                          explanation: formatMessage(
                            { id: 'users.status.inactive-explanation' },
                            {
                              company_name: corporate.name,
                            },
                          ),
                          value: 'disabled',
                        },
                      ]}
                    />
                  )}
                </Field>
              </StyledInputContainer>

              <StyledInputContainer label={formatMessage({ id: 'users.edit-user.assign-role' })}>
                <Field name="is_admin">
                  {({ field: { value, name, onChange } }: FieldProps) => (
                    <RadioInputCardGroup
                      onChange={onChange}
                      value={value}
                      direction="column"
                      name={name}
                      options={[
                        {
                          label: formatMessage({ id: 'users.edit-user.basic-user' }),
                          explanation: formatMessage(
                            { id: 'users.edit-user.basic-user-explanation' },
                            {
                              company_name: corporate.name,
                            },
                          ),
                          value: 'false',
                        },
                        {
                          label: formatMessage({ id: 'users.edit-user.admin' }),
                          explanation: formatMessage(
                            { id: 'users.edit-user.admin-explanation' },
                            {
                              company_name: corporate.name,
                            },
                          ),
                          value: 'true',
                        },
                      ]}
                    />
                  )}
                </Field>
              </StyledInputContainer>

              {groupEnabled && (
                <StyledInputContainer label={formatMessage({ id: 'users.edit-user.groups' })}>
                  <FormikMultiSelectField
                    name="group_ids"
                    options={
                      normalizedGroups
                        ? normalizedGroups.map((group) => ({
                            label: group.name,
                            value: group.id,
                          }))
                        : []
                    }
                    multiSelectProps={{
                      placeholder: formatMessage({ id: 'users.edit-user.select-groups' }),
                      menuPlacement: 'top',
                    }}
                  />
                </StyledInputContainer>
              )}

              {error && (
                <ApiErrorContainer>
                  <ApiErrorNotification error={error} />
                </ApiErrorContainer>
              )}
            </Container>

            <FormFooter
              rightContent={
                <>
                  <Button variant="lightBlue" size="small" onClick={onClose}>
                    <FormattedMessage id="common.cancel" />
                  </Button>
                  <Button
                    data-test-id="EditUserForm.SaveButton"
                    variant="blue"
                    size="small"
                    onClick={submitForm}
                    disabled={isSubmitting || submitSucceeded || !dirty}
                    icon={submitSucceeded && <Checkmark data-test-id="EditUserForm.Checkmark" />}
                    loading={isSubmitting}
                  >
                    <FormattedMessage id="common.save" />
                  </Button>
                </>
              }
            />
          </>
        );
      }}
    </Formik>
  );
};
