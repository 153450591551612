import styled from 'styled-components';
import { lighten } from 'polished';

import RadioInput from './RadioInput';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['input']> & {
  label: string;
};

const Root = styled.div<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  color: #404040;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const Label = styled.label<{ disabled?: boolean }>`
  user-select: none;
  margin-inline-start: 6px;
  font-size: 14px;
  line-height: 17px;
  color: ${({ disabled }) => disabled && '#838383'};

  :active {
    color: ${({ disabled }) => !disabled && lighten(0.15, '#404040')};
  }

  cursor: inherit;
`;

const LabelledRadioInput = ({
  name,
  id,
  checked,
  value,
  label,
  onChange,
  onFocus,
  onBlur,
  disabled,
  className,
}: Props) => {
  return (
    <Root disabled={disabled} className={className}>
      <RadioInput
        type="radio"
        name={name}
        id={id}
        checked={checked}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
      />
      <Label htmlFor={id} disabled={disabled}>
        {label}
      </Label>
    </Root>
  );
};

export default LabelledRadioInput;
