import { useMemo } from 'react';
import { useAuthState } from '@creditornot/wolt-auth/react';

import parseAuthPermissions from './parseAuthPermissions';

const usePermissions = () => {
  const { user } = useAuthState();

  const permissions = useMemo(
    () => (user ? parseAuthPermissions(user.permissions || []) : {}),
    [user],
  );

  return permissions;
};

export default usePermissions;
