import { defaultTo, pipe } from 'ramda';
import {
  StringParam,
  DelimitedArrayParam,
  BooleanParam,
  NumberParam,
  DateParam,
  ArrayParam,
} from 'use-query-params';

const isString = (value: unknown): value is string => typeof value === 'string';
const defaultToUndefined = defaultTo(undefined);
const defaultTo50 = defaultTo(50);
const isMonth = (value: any) => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].includes(value);
const isPageSize = (value: any) => [10, 20, 50, 100].includes(value);

export const NeverNullStringParam = {
  encode: StringParam.encode,
  decode: pipe(StringParam.decode, defaultToUndefined),
};

export const NeverNullArrayParam = {
  encode: ArrayParam.encode,
  decode: pipe(ArrayParam.decode, (value) => {
    const values = ([] as any[]).concat(value).filter(isString).filter(Boolean);
    return values.length === 0 ? undefined : values;
  }),
};

export const NeverNullDelimitedArrayParam = {
  encode: DelimitedArrayParam.encode,
  decode: pipe(DelimitedArrayParam.decode, (value) => {
    const values = ([] as any[]).concat(value).filter(isString).filter(Boolean);
    return values.length === 0 ? undefined : values;
  }),
};

export const NeverNullNumberParam = {
  encode: NumberParam.encode,
  decode: pipe(NumberParam.decode, defaultToUndefined),
};

export const NeverNullBooleanParam = {
  encode: BooleanParam.encode,
  decode: pipe(BooleanParam.decode, defaultToUndefined),
};

export const WoltPageSizeParam = {
  encode: NumberParam.encode,
  decode: pipe(NumberParam.decode, defaultTo50, (pageSize) =>
    isPageSize(pageSize) ? pageSize : 50,
  ),
};

export const MonthParam = {
  encode: NumberParam.encode,
  decode: pipe(NumberParam.decode, defaultToUndefined, (value) =>
    isMonth(value) ? value : undefined,
  ),
};

export const NeverNullDateParam = {
  encode: DateParam.encode,
  decode: pipe(DateParam.decode, defaultToUndefined),
};
