import { useEffect, useRef } from 'react';
import { Toast as CbToast, ToastProps as CbToastProps } from '@creditornot/cb-toast';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';

export type NotificationToastProps = CbToastProps & {
  autoCloseIn?: number;
  onClose?: () => void;
  show?: boolean;
  onAfterClose?: () => void;
};

const APP_ROOT_ID = 'root';
const APP_TOAST_ROOT = 'APP_TOAST_ROOT';

const useCreateToastsRootElement = () => {
  useEffect(() => {
    if (document.getElementById(APP_TOAST_ROOT)) {
      return;
    }
    const toastsRootElement = document.createElement('div');
    toastsRootElement.setAttribute('id', APP_TOAST_ROOT);
    toastsRootElement.style.setProperty('position', 'fixed');
    toastsRootElement.style.setProperty('z-index', '11');
    toastsRootElement.style.setProperty('top', '0');
    toastsRootElement.style.setProperty('right', '0');

    const root = document.getElementById(APP_ROOT_ID);
    root?.parentElement?.appendChild(toastsRootElement);
  }, []);
};

export const NotificationToast = ({
  onAfterClose,
  onClose,
  autoCloseIn,
  show = true,
  ...rest
}: NotificationToastProps) => {
  useCreateToastsRootElement();
  const toastElement = useRef(document.createElement('div'));
  const savedOnClose = useRef(() => {});
  savedOnClose.current = () => onClose?.();
  useEffect(() => {
    document.getElementById(APP_TOAST_ROOT)?.appendChild(toastElement.current);
    const cleanup = () => toastElement.current.remove();
    return cleanup;
  }, []);
  useEffect(() => {
    const call = () => {
      savedOnClose.current();
    };

    if (autoCloseIn) {
      const timeout = setTimeout(() => call(), autoCloseIn);
      return () => clearTimeout(timeout);
    }
  }, [autoCloseIn]);

  return (
    <>
      {createPortal(
        <AnimatePresence onExitComplete={onAfterClose}>
          {show && (
            <motion.div
              initial={{ x: 300, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{
                x: 300,
                opacity: 0,
                height: '0px',
                overflow: 'hidden',
                transition: {
                  duration: 0.3,
                },
              }}
            >
              <CbToast {...rest} onCloseButtonClick={onClose} />
            </motion.div>
          )}
        </AnimatePresence>,
        toastElement.current,
      )}
    </>
  );
};
