import { useMemo } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button } from '@creditornot/cb-button';

import { Header } from 'components';

import { useAdminsQueryParams } from '../hooks';

interface Props {
  onDeleteClick: () => void;
}

const StyledHeader = styled(Header)`
  :first-child {
    justify-content: end;
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  margin-inline-start: 16px;
  outline: none;
`;

export const ActionButtons = ({ onDeleteClick }: Props) => {
  const [{ ids = [] }] = useAdminsQueryParams();
  const isMultipleItemsSelected = ids.length > 0;

  const deletePanel = useMemo(() => {
    return (
      <StyledButton size="small" onClick={onDeleteClick} variant="lightRed">
        <FormattedMessage id="common.delete" />
      </StyledButton>
    );
  }, [onDeleteClick]);

  if (!isMultipleItemsSelected) {
    return null;
  }

  return <StyledHeader key="admins-header" borderBottom rightItems={[deletePanel]} />;
};
