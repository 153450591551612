import * as t from 'io-ts';

import { SpendingLimitPeriod } from 'modules/common/types';

export type DayOfWeek = t.TypeOf<typeof DayOfWeek>;
export const DayOfWeek = t.keyof({
  monday: null,
  tuesday: null,
  wednesday: null,
  thursday: null,
  friday: null,
  saturday: null,
  sunday: null,
});

export type DeliveryAreaType = t.TypeOf<typeof DeliveryAreaType>;
export const DeliveryAreaType = t.keyof({
  delivery: null,
  takeaway: null,
});

export type BudgetAllowance = t.TypeOf<typeof BudgetAllowance>;
export const BudgetAllowance = t.type({
  amount: t.number,
  currency: t.string,
  exclude_delivery_fee: t.boolean,
  period: SpendingLimitPeriod,
});

export type TimeRestriction = t.TypeOf<typeof TimeRestriction>;
export const TimeRestriction = t.type({
  day_of_week: DayOfWeek,
  start_minute: t.number,
  end_minute: t.number,
});

export type DeliveryArea = t.TypeOf<typeof DeliveryArea>;
export const DeliveryArea = t.type({
  created_at: t.string,
  description: t.string,
  id: t.string,
  latitude: t.number,
  longitude: t.number,
  modified_at: t.string,
  name: t.string,
  delivery_type: DeliveryAreaType,
  radius_meters: t.number,
});

export type Group = t.TypeOf<typeof Group>;
export const Group = t.type({
  id: t.string,
  name: t.string,
});

export type Policy = t.TypeOf<typeof Policy>;
export const Policy = t.type({
  allowed_non_restaurant_product_lines: t.array(t.string),
  all_non_restaurant_product_lines_allowed: t.boolean,
  budget_allowance: t.union([BudgetAllowance, t.null]),
  created_at: t.string,
  delivery_areas: t.array(DeliveryArea),
  description: t.string,
  giftcard_purchases_allowed: t.boolean,
  group_ids: t.array(t.string),
  groups: t.array(Group),
  id: t.string,
  modified_at: t.string,
  name: t.string,
  non_restaurant_purchases_allowed: t.boolean,
  order_comment_required: t.boolean,
  restaurant_purchases_allowed: t.boolean,
  times: t.array(TimeRestriction),
});
