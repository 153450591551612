import { ComponentProps } from 'react';

import { FormikFileInput } from 'components';
import {
  createValidator,
  isRequired,
  isEmail,
  doesNotContainDuplicateEmails,
  isAListOfValidEmails,
  isDuplicateEmail,
} from 'validation';

const validateEmails = createValidator([
  isRequired,
  doesNotContainDuplicateEmails,
  isAListOfValidEmails,
]);

export const FormikInviteEventUsersFileInput: React.FC<
  Partial<ComponentProps<typeof FormikFileInput>>
> = (props) => {
  return (
    <FormikFileInput
      name="emails"
      validate={validateEmails}
      validateValue={(email, index, emails) =>
        isDuplicateEmail(email, index, emails) || isEmail(email)
      }
      columnName="email"
      {...props}
    />
  );
};
