import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { useCorporate } from 'modules/corporates';

import { fetchCurrentServiceAgreement } from './api';

export const useFetchServiceAgreement = () => {
  const { data: corporate } = useCorporate();

  const queryFn = useCallback(() => {
    if (!corporate?.id) {
      return Promise.reject('Corporate is missing');
    }
    return fetchCurrentServiceAgreement(corporate.id);
  }, [corporate?.id]);

  return useQuery({
    queryKey: ['service-agreement', corporate?.id],
    queryFn,
    enabled: !!corporate?.id,
  });
};
