import * as t from 'io-ts';

import { GroupOrderDetails } from './GroupOrderDetails';

export type DeliveryLocation = t.TypeOf<typeof DeliveryLocation>;
export const DeliveryLocation = t.type({
  address: t.string,
  apartment: t.string,
  city: t.string,
  country: t.string,
  street: t.string,
});

export type VenueAddress = t.TypeOf<typeof VenueAddress>;
export const VenueAddress = t.type({
  city: t.string,
  country: t.string,
  post_code: t.string,
  street: t.string,
});

export type Venue = t.TypeOf<typeof Venue>;
export const Venue = t.type({
  address: VenueAddress,
  id: t.string,
  name: t.string,
  slug: t.string,
});

export type User = t.TypeOf<typeof User>;
export const User = t.type({
  email: t.string,
  id: t.string,
  name: t.string,
});

export type DeliveryMethod = t.TypeOf<typeof DeliveryMethod>;
export const DeliveryMethod = t.keyof({
  homedelivery: null,
  takeaway: null,
  eatin: null,
});

/**
 * @Note 'refunded' status will be deprecated at some point
 */
export type Status = t.TypeOf<typeof Status>;
export const Status = t.keyof({
  received: null,
  acknowledged: null,
  production: null,
  ready: null,
  delivered: null,
  refunded: null,
  fetched: null,
});

export type PaymentMethodGroupPolicy = t.TypeOf<typeof PaymentMethodGroupPolicy>;
export const PaymentMethodGroupPolicy = t.type({
  description: t.string,
  id: t.string,
  name: t.string,
});

export type PaymentMethodGroup = t.TypeOf<typeof PaymentMethodGroup>;
export const PaymentMethodGroup = t.type({
  description: t.string,
  id: t.string,
  name: t.string,
  policy: t.union([PaymentMethodGroupPolicy, t.null]),
});

export type PaymentType = t.TypeOf<typeof PaymentType>;
export const PaymentType = t.keyof({
  main: null,
  additional: null,
});

export type PaymentMethod = t.TypeOf<typeof PaymentMethod>;
export const PaymentMethod = t.type({
  id: t.string,
  type: t.string,
});

export type Payment = t.TypeOf<typeof Payment>;
export const Payment = t.type({
  amount: t.number,
  id: t.string,
  payment_method: PaymentMethod,
  refunded_amount: t.number,
  type: PaymentType,
});

export type OptionValue = t.TypeOf<typeof OptionValue>;
export const OptionValue = t.type({
  id: t.string,
  count: t.number,
  price: t.number,
  value: t.string,
});

export type ItemOption = t.TypeOf<typeof ItemOption>;
export const ItemOption = t.type({
  id: t.string,
  name: t.string,
  values: t.array(OptionValue),
});

export type OrderItem = t.TypeOf<typeof OrderItem>;
export const OrderItem = t.type({
  count: t.number,
  id: t.string,
  name: t.string,
  options: t.array(ItemOption),
  price: t.number,
  row_number: t.number,
});

export type OrderAmounts = t.TypeOf<typeof OrderAmounts>;
export const OrderAmounts = t.type({
  delivery: t.number,
  discounts: t.number,
  refunds: t.number,
  tips: t.number,
  total: t.number,
});

export type Order = t.TypeOf<typeof Order>;
export const Order = t.type({
  accounting_note: t.string,
  currency: t.string,
  id: t.string,
  is_group_order: t.boolean,
  status: Status,
  total_amount: t.number,
  user: User,
  /**
   * Preorder time in venue's local timezone
   */
  venue_preorder_time: t.union([t.null, t.string]),
  /**
   * Delivery time in venue's local timezone
   */
  venue_time_delivered: t.string,
  venue: Venue,
});

export type OrderDetails = t.TypeOf<typeof OrderDetails>;
export const OrderDetails = t.intersection([
  t.type({
    accounting_note: t.string,
    amounts: OrderAmounts,
    corporate_id: t.string,
    currency: t.string,
    delivery_method: DeliveryMethod,
    group_order: t.union([t.null, GroupOrderDetails]),
    id: t.string,
    items: t.array(OrderItem),
    modified_at: t.string,
    payment_method_group: t.union([PaymentMethodGroup, t.null]),
    payments: t.array(Payment),
    /**
     * Preorder time in UTC
     */
    preorder_time: t.union([t.null, t.string]),
    status: Status,
    /**
     * Delivery time in UTC
     */
    time_delivered: t.string,
    /**
     * Order time received in UTC
     */
    time_received: t.string,
    user: User,
    venue: Venue,
    /**
     * Preorder time in venue's local timezone
     */
    venue_preorder_time: t.union([t.null, t.string]),
    /**
     * Delivery time in venue's local timezone
     */
    venue_time_delivered: t.string,
    /**
     * Order time received in venue's local timezone
     */
    venue_time_received: t.string,
  }),
  t.partial({
    refund_receipt_pdf_urls: t.array(t.string),
    delivery_location: t.union([t.null, DeliveryLocation]),
  }),
]);
