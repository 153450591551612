import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { TextButton } from '@creditornot/cb-button';
import { Plus } from '@creditornot/cb-icons';

import { LocalizedMessage } from 'i18n';
import { useCreateGroupInModal } from 'modules/groups';
import { useProductTour } from 'modules/product-tour';

import { InviteUserFormValues } from './types';

export type FormikCreateGroupButtonField = {
  className?: string;
};

const CreateNewGroupButton = styled(TextButton).attrs({
  icon: <Plus />,
  variant: 'blue',
  size: 'small',
})``;

export const FormikCreateGroupButtonField = ({ className }: FormikCreateGroupButtonField) => {
  const createGroupInModal = useCreateGroupInModal();
  const { setFieldValue, values } = useFormikContext<InviteUserFormValues>();
  const { isInTourMode } = useProductTour();

  if (isInTourMode) {
    return null;
  }

  return (
    <CreateNewGroupButton
      className={className}
      onClick={async () => {
        try {
          const group = await createGroupInModal();
          setFieldValue('groups', [...values.groups, group.id]);
        } catch (error) {
          // expected reject from modal close
          if (!error) {
            return;
          }
          throw error;
        }
      }}
    >
      <LocalizedMessage messageKey="groups-form.create-group" />
    </CreateNewGroupButton>
  );
};
