import { useAuthState } from '@creditornot/wolt-auth/react';
import { useQuery, UseQueryOptions, QueryKey } from 'react-query';

export function useAuthenticatedReactQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>) {
  const isAuthenticated = !!useAuthState().user;
  return useQuery({
    ...options,
    enabled: isAuthenticated ? options?.enabled : false,
  });
}
