import { Redirect, Route } from 'react-router-dom';
import { Location } from 'history';

import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import LoggingInView from 'views/logging-in';
import AccessDeniedView from 'views/access-denied';
import { CorporatesView } from 'views/corporates';
import MultipleCorporatesView from 'views/multiple-corporates';
import { useFetchUserCorporateRole } from 'modules/users';

import { RouteWithSideNavbar } from './RouteWithSideNavBar';
import { RouteWithTopNavBar } from './RouteWithTopNavBar';

export const AuthenticatedRoutes: React.FC<{ location: Location }> = ({ location }) => {
  const { data: corporateRoles, isLoading: isLoadingCorporateRoles } = useFetchUserCorporateRole();
  const hasAccessToMultipleCorporates = (corporateRoles?.length || 0) > 1;
  const firstCorporateId = corporateRoles?.[0]?.resource_id.replaceAll('-', '');
  const { isWoltEmployee } = useIsUserWoltEmployee();
  const path = location.pathname;

  if (isLoadingCorporateRoles) {
    return <Route exact path={path} component={LoggingInView} />;
  }

  if (isWoltEmployee) {
    return <RouteWithSideNavbar exact path={path} component={CorporatesView} />;
  }

  if (hasAccessToMultipleCorporates) {
    return <RouteWithSideNavbar exact path={path} component={MultipleCorporatesView} />;
  }

  // User has access to exactly one corporate, but isn't a Wolt employee
  if (firstCorporateId) {
    return <Redirect to={`/${firstCorporateId}`} />;
  }

  return <RouteWithTopNavBar exact path={path} component={() => <AccessDeniedView />} />;
};
