import { TagVariant } from '@creditornot/cb-tag';
import { AxiosResponse } from 'axios';
import Encoding from 'encoding-japanese';

import { VenuePayout } from 'modules/merchantPayouts/types';

export const getStatus = (
  status: VenuePayout['transferStatus'],
): {
  color: TagVariant;
  text: string;
  key: 'rejected' | 'pending' | 'success' | 'not-applicable';
} => {
  switch (status) {
    case 'kyc_failed':
      return { color: 'lightRed', text: 'Rejected', key: 'rejected' };
    case 'in_progress':
      return { color: 'lightYellow', text: 'In progress', key: 'pending' };
    case 'not_applicable':
      return { color: 'lightBlue', text: 'No payout amount', key: 'not-applicable' };
    case 'sent_to_bank':
      return { color: 'lightBlue', text: 'Successful', key: 'success' };
    default:
      return { color: 'lightRed', text: 'Rejected', key: 'rejected' };
  }
};

const downloadFromUrl = (href: string, filename?: string) => {
  const anchor = document.createElement('a');
  anchor.href = href;
  if (filename) {
    anchor.download = filename;
  }
  anchor.click();
  // clean up
  anchor.remove();
  // don't forget to revoke your Blob URLs, or it will be held im browsers memory!
  URL.revokeObjectURL(href);
};
const download = (blobParts: BlobPart[], type: string, filename: string) => {
  const blob = new Blob(blobParts, { type });
  const objectURL = URL.createObjectURL(blob);
  downloadFromUrl(objectURL, filename);
};
const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

export const downloadEncodedCsv = (
  csv: string,
  type: string,
  filename: string,
  isJapanese: boolean,
) => {
  const utf8Array = new TextEncoder().encode(csv);
  // Japanese has a specific encoding standard and might be garbled if we don't encode as Shift-JIS
  if (isJapanese) {
    const sjisArray = Encoding.convert(utf8Array, { to: 'SJIS', from: 'UTF8' });
    const blobPart = new Uint8Array(sjisArray);
    download([blobPart], type, filename);
  } else {
    download([utf8Array], type, filename);
  }
};

export function downloadFileFromAxiosResponse(response: AxiosResponse<Blob | string>) {
  const disposition = response.request.getResponseHeader('Content-Disposition');
  let fileName = '';
  const matches = filenameRegex.exec(disposition);
  if (matches?.[1]) {
    fileName = matches[1].replace(/['"]/g, '');
  }
  const blob = new Blob([response.data], { type: response.headers['content-type'] });

  downloadFromUrl(URL.createObjectURL(blob), fileName);
}
