import { useCallback } from 'react';
import { GetCorporateResponse } from '@creditornot/wd-api-client';
import { AxiosError } from 'axios';

import { useQuery, UseQueryOptions } from 'modules/react-query';
import { selfServiceCorporateApiClient } from 'modules/corporates/api';

export const useFetchSelfServiceCorporate = <T = GetCorporateResponse>(
  woltCorporateId?: string,
  config?: UseQueryOptions<GetCorporateResponse, AxiosError, T>,
) => {
  const queryFn = useCallback(async () => {
    if (typeof woltCorporateId === 'undefined') {
      return Promise.reject(new Error('Invalid id'));
    } else {
      const { data } = await selfServiceCorporateApiClient.getCorporate({
        woltCorporateId: woltCorporateId,
      });
      return data;
    }
  }, [woltCorporateId]);

  return useQuery({
    queryKey: ['delivery-settings', woltCorporateId],
    queryFn,
    ...config,
    enabled: Boolean(woltCorporateId),
  });
};
