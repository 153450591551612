import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { AlertCircleFilled, InfoCircleFilled } from '@creditornot/cb-icons';
import { colors } from '@creditornot/cb-ingredients';

export type Variant = 'warning' | 'danger' | 'info';
export interface NotificationProps {
  variant?: Variant;
  children?: React.ReactNode;
  className?: string;
}

const rootStyles: Record<Variant, FlattenSimpleInterpolation> = {
  warning: css`
    background-color: ${color.bgSurfaceWarning};
  `,
  danger: css`
    background-color: ${colors.strawberry8};
    color: ${color.textNegative};
  `,
  info: css`
    background-color: ${color.border};
    color: ${color.text};
  `,
};

const Root = styled.div<{ variant: Variant }>`
  display: flex;
  min-height: 45px;
  border-radius: 8px;

  ${({ variant }) => rootStyles[variant]}
`;

const IconContainer = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const alertCircleStyles: Record<Variant, FlattenSimpleInterpolation> = {
  warning: css`
    & > path {
      fill: ${colors.banana100};
    }
  `,
  danger: css`
    & > path {
      fill: ${color.textNegative};
    }
  `,
  info: css`
    & > path {
      fill: ${color.text};
    }
  `,
};

const StyledAlertCircleFilled = styled(AlertCircleFilled)<{ variant: Variant }>`
  height: 16px;
  width: 16px;
  ${({ variant }) => alertCircleStyles[variant]}
`;

const StyledInfoCircleFilled = styled(InfoCircleFilled)`
  height: 16px;
  width: 16px;
  color: ${color.text};
`;

const Content = styled.div`
  width: 100%;
  font-size: 14px;
  padding: 12px 0;
  padding-inline-end: 12px;
`;

const iconConfigs: Record<Variant, React.ReactNode> = {
  danger: <StyledAlertCircleFilled variant="danger" />,
  warning: <StyledAlertCircleFilled variant="warning" />,
  info: <StyledInfoCircleFilled />,
};

const Notification = ({ variant = 'warning', children, className }: NotificationProps) => {
  return (
    <Root variant={variant} className={className}>
      <IconContainer>{iconConfigs[variant]}</IconContainer>
      <Content>{children}</Content>
    </Root>
  );
};

export default Notification;
