import { FormattedDate, useIntl } from 'react-intl';
import { Alert } from '@creditornot/cb-alert';
import { Input } from '@creditornot/cb-input';
import { useField } from 'formik';
import styled from 'styled-components';
import { isNil } from 'lodash';

import { FormSection, FormikInputField, FormViewText, FormRowV2 as Row } from 'components';
import { CorporateStatusTag } from 'modules/corporates/components';
import { Corporate } from 'modules/corporates/types';
import { processError } from 'utils';
import { ApiErrorContainer } from 'views/settings/CompanySettingsV2/ApiErrorContainer';

import { RenderMode } from '../../types';
import { EditButton } from '../EditButton';
import { CorporateStatusModifyButtons } from './CorporateStatusModifyButtons';
import { SaveCancelButtons } from '../SaveCancelButtons';

type Props = {
  renderMode: RenderMode;
  status: Corporate['status'];
  updatedAt: Corporate['status_modified_at'];
  isSubmitting?: boolean;
  dirty?: boolean;
  error?: any;
  onEditClick?: () => void;
  onCancelClick?: () => void;
  onSaveClick?: () => void;
  onReactivateClick: () => void;
  onDisableClick: () => void;
  onDeleteClick: () => void;
  onActivateNewClick: () => void;
};

const UpdatedAtField = ({ updatedAt }: { updatedAt: Corporate['status_modified_at'] }) => {
  const { formatDate } = useIntl();

  return (
    <Input
      value={formatDate(updatedAt, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })}
      disabled
    />
  );
};

const AccountManager = () => {
  const [{ value }] = useField('account_manager');

  return <FormViewText>{!isNil(value) && value !== '' ? value : '-'}</FormViewText>;
};

const CorporateStatusTagContainer = styled.div`
  min-height: 36px;
`;

const renderMap = {
  corporate_status: {
    view: ({ status }: Pick<Props, 'status'>) => (
      <CorporateStatusTagContainer>
        <CorporateStatusTag status={status} />
      </CorporateStatusTagContainer>
    ),
    edit: ({
      status,
      onActivateNewClick,
      onDeleteClick,
      onDisableClick,
      onReactivateClick,
    }: Pick<
      Props,
      'status' | 'onActivateNewClick' | 'onDeleteClick' | 'onDisableClick' | 'onReactivateClick'
    >) => (
      <CorporateStatusModifyButtons
        status={status}
        onActivateNewClick={onActivateNewClick}
        onDeleteClick={onDeleteClick}
        onDisableClick={onDisableClick}
        onReactivateClick={onReactivateClick}
      />
    ),
  },
  updated_at: {
    edit: ({ updatedAt }: { updatedAt: Corporate['status_modified_at'] }) => (
      <UpdatedAtField updatedAt={updatedAt} />
    ),
    view: ({ updatedAt }: { updatedAt: Corporate['status_modified_at'] }) => (
      <FormViewText>
        <FormattedDate
          value={new Date(updatedAt)}
          year="numeric"
          month="numeric"
          day="numeric"
          hour="numeric"
          minute="numeric"
        />
      </FormViewText>
    ),
  },
  account_manager: {
    edit: () => <FormikInputField name="account_manager" inputProps={{ id: 'account_manager' }} />,
    view: () => <AccountManager />,
  },
};

export const CorporateAccountForm = ({
  renderMode,
  status,
  updatedAt,
  isSubmitting,
  dirty,
  error,
  onEditClick,
  onCancelClick,
  onSaveClick,
  onActivateNewClick,
  onDeleteClick,
  onDisableClick,
  onReactivateClick,
}: Props) => {
  const { formatMessage } = useIntl();

  if (renderMode === 'signup') return null;

  return (
    <FormSection
      title={formatMessage({ id: 'corporate-form.company-account-title' })}
      topRightItem={onEditClick && <EditButton renderMode={renderMode} onEditClick={onEditClick} />}
    >
      <Alert variant="info" size="small">
        {formatMessage({ id: 'corporate-form.employee-disclaimer' })}
      </Alert>
      <Row
        title={formatMessage({ id: 'corporates-form.company-status' })}
        content={renderMap.corporate_status[renderMode]({
          status,
          onActivateNewClick,
          onDeleteClick,
          onDisableClick,
          onReactivateClick,
        })}
      />
      <Row
        title={formatMessage({ id: 'corporates-form.status-updated-at' })}
        content={renderMap.updated_at[renderMode]({ updatedAt })}
      />
      <Row
        title={formatMessage({ id: 'corporates-form.account_manager' })}
        content={renderMap.account_manager[renderMode]()}
      />
      {error && (
        <ApiErrorContainer>
          <Alert variant="error" size="medium" title={processError(error).message}>
            {processError(error).data}
          </Alert>
        </ApiErrorContainer>
      )}
      <SaveCancelButtons
        renderMode={renderMode}
        onCancelClick={onCancelClick}
        onSaveClick={onSaveClick}
        isSubmitting={isSubmitting}
        dirty={dirty}
      />
    </FormSection>
  );
};
