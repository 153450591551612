import { useField } from 'formik';

import { FormRowV2, FormSection, FormikInputField, RadioInputCardGroup } from 'components';
import { isRequired, createValidator } from 'validation';
import { CountryStatus } from 'modules/country-configs/types';

type Props = {
  alwaysShowError?: boolean;
};

const validate = createValidator([isRequired]);

const CompanyDetailsFormSection: React.FC<Props> = ({ alwaysShowError }) => {
  const [{ name, value }, , { setValue }] = useField<CountryStatus>('status');

  return (
    <FormSection title="Country details">
      <FormRowV2
        htmlFor="status"
        title="Status"
        content={
          <RadioInputCardGroup
            name={name}
            value={value}
            onChange={(e) => setValue(e.target.value as CountryStatus)}
            direction="row"
            stretched
            options={[
              {
                label: 'Active',
                value: 'active',
              },
              {
                label: 'Internal',
                value: 'internal',
              },
              {
                label: 'Disabled',
                value: 'disabled',
              },
            ]}
          />
        }
      />
      <FormRowV2
        htmlFor="wolt_company_name"
        title="Local Wolt company name"
        content={
          <FormikInputField
            name="wolt_company_name"
            type="text"
            required={true}
            validate={validate}
            alwaysShowError={alwaysShowError}
          />
        }
      />
    </FormSection>
  );
};

export default CompanyDetailsFormSection;
