import styled from 'styled-components';
import { TextButton } from '@creditornot/cb-button';
import { typographyCss } from '@creditornot/cb-ingredients';

import { LottieComponent } from 'modules/lotties';
import { LocalizedMessage } from 'i18n';
import { breakpoints } from 'modules/media';

type ContractPendingProps = {
  className?: string;
  onContactClick: () => void;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StyledBody2 = styled.div`
  ${typographyCss.Body2()}
  margin-top: 16px;
  display: block;
  max-width: 560px;

  @media (max-width: ${breakpoints.small}px) {
    ${typographyCss.Body3()}
  }
`;

const StyledBody3 = styled.span`
  ${typographyCss.Body3()}
  margin-top: 24px;
  display: block;
  max-width: 560px;
`;

const Heading = styled.div`
  ${typographyCss.Heading4()}
  /* Fill in the gap between lottie and heading */
  margin-top: -32px;
  max-width: 560px;

  @media (max-width: ${breakpoints.small}px) {
    ${typographyCss.Heading5()}
  }
`;

const StyledTextButton = styled(TextButton)`
  font-size: inherit;
`;

const MailSent = styled(LottieComponent).attrs({
  animation: 'mail',
  size: 'square',
})`
  max-width: 420px;
  min-height: 340px;
`;

export const ContractPending = ({ className, onContactClick }: ContractPendingProps) => {
  return (
    <Root className={className}>
      <MailSent />
      <Heading>
        <LocalizedMessage messageKey="views.dashboard.contract-pending-title" />
      </Heading>
      <StyledBody2>
        <LocalizedMessage messageKey="views.dashboard.contract-pending-text" />
      </StyledBody2>
      <StyledBody3>
        <LocalizedMessage
          messageKey="views.dashboard.contract-pending-contact"
          values={{
            link: (text: string) => (
              <StyledTextButton onClick={onContactClick}>{text}</StyledTextButton>
            ),
          }}
        />
      </StyledBody3>
    </Root>
  );
};
