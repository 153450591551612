import styled from 'styled-components';

import { useMedia } from 'modules/media';
import { useFetchMerchantPayouts } from 'modules/merchantPayouts';
import { ApiErrorNotification, ResponsiveContainer } from 'components';

import { ReportsDataTable } from '../components/ReportsDataTable';
import { useReportsQueryParams } from '../hooks';
import { ReportsDataList } from '../components/ReportsDataList';

const ErrorContainer = styled(ResponsiveContainer)`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const ReportsPayoutDataView = ({ corporateId }: { corporateId: string }) => {
  const mediumView = useMedia('medium');
  const queryData = useReportsQueryParams();

  const { data, isLoading, error } = useFetchMerchantPayouts(corporateId, {
    select: (data) => {
      if (!queryData[0].status || queryData[0].status.length === 0) return data;
      return data.filter((report) => queryData[0].status?.includes(report.transferStatus));
    },
  });

  if (error) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={error} />
      </ErrorContainer>
    );
  }

  return (
    <>
      {mediumView ? (
        <ReportsDataList
          venuePayouts={data || []}
          isLoading={isLoading}
          corporateId={corporateId}
        />
      ) : (
        <ReportsDataTable reportsData={data || []} loading={isLoading} corporateId={corporateId} />
      )}
    </>
  );
};
