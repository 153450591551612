export const CREATE_GROUP_BUTTON_SELECTOR = 'views.groups.create-group-button';
export const CREATE_GROUP_MODAL_SELECTOR = 'views.group.create-group-modal';
export const CREATE_POLICY_BUTTON_SELECTOR = 'views.policies.create-policy-button';
export const CREATE_POLICY_MODAL_SELECTOR = 'views.policies.create-policy-modal';
export const GROUPS_TAB_SELECTOR = 'views.groups.tab';
export const INVITE_USERS_BUTTON_SELECTOR = 'views.users.invite-user-button';
export const POLICIES_TAB_SELECTOR = 'views.policies.tab';
export const USER_GUIDE_SELECTOR = 'views.orders.user-guide-link';
export const USER_INVITE_MODAL_SELECTOR = 'views.users.invite-user-modal';
export const WELCOME_MODAL_SELECTOR = 'welcome-modal';
