import { GetCorporateResponse, SmsNotificationEventEnum } from '@creditornot/wd-api-client';
import { useMemo } from 'react';
import { B2bCustomerConfigResponse, DeliveryPricingType } from '@creditornot/daas-core-api-client';

import { useUpdateB2bConfig, useCreateB2bConfigs, useFetchB2bConfig } from 'modules/b2b-configs';
import { B2bConfigFormValues } from 'views/settings/CompanySettings/B2bConfigSettings/types';

import { Corporate } from './types';
import { useFetchSelfServiceCorporate } from './useFetchSelfServiceCorporate';
import {
  formatB2bConfigFormValuesForCreate,
  formatB2bConfigFormValuesForUpdate,
} from './formatFormValues';

type FetchState = 'loading' | 'error' | '404Error' | 'fetched';

const fallbackB2bConfig = (
  corporate: Corporate,
  selfServiceCorporateData: GetCorporateResponse | undefined,
): B2bCustomerConfigResponse => {
  return {
    corporate_id: corporate.id,
    wolt_merchant_id: '',
    dynamic_pickup_venue_id: '',
    delivery_distance_meters: 0,
    pricing: {
      type: DeliveryPricingType.FlatFee,
    },
    country_code: corporate.country,
    is_cash_enabled: false,
    is_intercom_enabled: false,
    sms_notification_copy:
      selfServiceCorporateData?.smsNotifications?.find(
        (sms) => sms.event === SmsNotificationEventEnum.Received,
      )?.messageText ?? '',
  };
};

export const useEditDriveConfig = (corporate: Corporate) => {
  const { mutateAsync: editB2bConfig, error: b2bConfigEditError } = useUpdateB2bConfig();
  const { mutateAsync: createB2bConfig, error: b2bConfigCreateError } = useCreateB2bConfigs();

  const mutateError = useMemo(
    () => b2bConfigEditError || b2bConfigCreateError,
    [b2bConfigEditError, b2bConfigCreateError],
  );

  const {
    data: selfServiceCorporateData,
    isLoading: corporateQueryLoading,
    error: corporateQueryError,
  } = useFetchSelfServiceCorporate(corporate.id);
  const {
    data: b2bConfigData,
    isLoading: b2bConfigQueryLoading,
    error: b2bConfigQueryError,
  } = useFetchB2bConfig(corporate.id);

  const fetchError = useMemo(
    () => b2bConfigQueryError || corporateQueryError,
    [b2bConfigQueryError, corporateQueryError],
  );

  const b2bConfigWithFallback = useMemo(
    () => b2bConfigData || fallbackB2bConfig(corporate, selfServiceCorporateData),
    [b2bConfigData, corporate, selfServiceCorporateData],
  );

  const state: FetchState = useMemo(() => {
    if (corporateQueryLoading || b2bConfigQueryLoading) {
      return 'loading';
    }
    if (b2bConfigQueryError?.response?.status === 404) {
      return '404Error';
    }
    if (fetchError) {
      return 'error';
    }
    return 'fetched';
  }, [corporateQueryLoading, b2bConfigQueryLoading, b2bConfigQueryError, fetchError]);

  const mutateDriveConfig = async (formData: B2bConfigFormValues) => {
    if (!selfServiceCorporateData) {
      throw new Error('Self service corporate data is not fetched');
    }
    if (b2bConfigData) {
      const updateB2bConfigPayload = formatB2bConfigFormValuesForUpdate({
        country: corporate.country,
        currency: corporate.currency,
        formData,
        selfServiceCorporate: selfServiceCorporateData,
      });
      await editB2bConfig({
        corporateId: corporate.id,
        payload: updateB2bConfigPayload,
      });
    } else {
      const createB2bConfigPayload = formatB2bConfigFormValuesForCreate({
        corporateId: corporate.id,
        formData,
        country: corporate.country,
        currency: corporate.currency,
        selfServiceCorporate: selfServiceCorporateData,
      });
      await createB2bConfig(createB2bConfigPayload);
    }
  };

  return {
    mutateDriveConfig,
    mutateError,
    fetchState: state,
    fetchError,
    b2bConfigWithFallback,
  };
};
