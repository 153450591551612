import queryString from 'query-string';
import uniq from 'ramda/src/uniq';
import { FieldMetaProps } from 'formik';
import { isValidPhoneNumber as isValidPhoneNumberLib } from 'libphonenumber-js';

import { type MessageIds, isMessageKey } from 'i18n';

export const createValidator =
  (validationFns: Array<(value: any) => Promise<string | undefined> | string | undefined>) =>
  (fieldValue: any) => {
    return validationFns.reduce<Promise<string | undefined>>((previousPromise, validationFn) => {
      return previousPromise.then((result) => {
        if (typeof result === 'string') {
          return previousPromise;
        } else {
          return Promise.resolve(validationFn(fieldValue));
        }
      });
    }, Promise.resolve(undefined));
  };

export const parseValidationMessageKeyAndValues = (validationString: string) => {
  const value = validationString.split('?')[0];

  if (isMessageKey(value)) {
    return {
      messageKey: value,
      ...(validationString.split('?')[1] && {
        values: queryString.parse(validationString.split('?')[1]),
      }),
    };
  }

  return undefined;
};

export function getFormikFieldError(
  { error, touched }: FieldMetaProps<unknown>,
  alwaysShowError = false,
) {
  if ((alwaysShowError && error) || (!alwaysShowError && error && touched)) {
    return parseValidationMessageKeyAndValues(error);
  }
  return undefined;
}

export const isRequired = (value: unknown): MessageIds | undefined => {
  if (value === null || value === undefined) {
    return 'validation.is_required';
  }

  if (Array.isArray(value) && !value.length) {
    return 'validation.is_required';
  }

  if (typeof value === 'string') {
    if (!value?.trim()) {
      return 'validation.is_required';
    }
  }
  return undefined;
};

export const isEmail = (value: string): MessageIds | undefined => {
  const validEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

  if (value === '' || validEmail.test(value)) {
    return undefined;
  }

  return 'validation.is_email';
};

export const isNumber = (value: string): MessageIds | undefined =>
  value && isNaN(Number(value.replace(',', '.'))) ? 'validation.is_number' : undefined;

export const isInteger = (value: string): MessageIds | undefined =>
  value && !Number.isInteger(Number(value.replace(',', '.'))) ? 'validation.is_integer' : undefined;

export const isGreaterThan = (referenceValue: number) => (value: string) =>
  value && Number(value.replace(',', '.')) <= referenceValue
    ? `validation.is-greater-than?value=${referenceValue}`
    : undefined;

const formatToNumber = (value: string | number) =>
  typeof value === 'string' ? Number(value.replace(',', '.')) : value;

export const min = (minValue: number) => (value: string | number) => {
  if (!value) {
    return undefined;
  }
  const normalizedValue = formatToNumber(value);
  return normalizedValue < minValue ? `validation.min?min=${minValue}` : undefined;
};

export const max = (maxValue: number) => (value: string | number) => {
  if (!value) {
    return undefined;
  }
  const normalizedValue = formatToNumber(value);
  return normalizedValue > maxValue ? `validation.max?max=${maxValue}` : undefined;
};

export const isFileExtensionValid =
  (acceptedExtensions: string) =>
  (value: File): MessageIds | undefined => {
    const extensions = acceptedExtensions.split(',');

    return !extensions.some((ext) => value.name.endsWith(ext))
      ? 'validation.incorrect-file-type'
      : undefined;
  };

export const doesNotContainDuplicateEmails = (values: string[]) =>
  uniq(values).length !== values.length
    ? `validation.does_not_contain_duplicate_emails`
    : undefined;

export const isNotShorterThan = (num: number) => (value: string) =>
  !!value && value.length < num ? `validation.shorter?min=${num}` : undefined;

export const isNotLongerThan = (num: number) => (value: string) =>
  !!value && value.length > num ? `validation.longer?max=${num}` : undefined;

export const isAListOfValidEmails = (value: string[]) =>
  !value.every((e) => typeof isEmail(e) !== 'string') ? 'validation.is_email' : undefined;

export const isDuplicateEmail = (value: string, index: number, values: string[]) =>
  values.indexOf(value) !== values.lastIndexOf(value) && values.indexOf(value) !== index
    ? `validation.does_not_contain_duplicate_emails`
    : undefined;

export const isValidPhoneNumber = (value: string) => {
  if (!value) {
    return undefined;
  }

  return isValidPhoneNumberLib(value) ? undefined : 'validation.is_valid_phone_number';
};

export const isCsvFile = (file: File) => file.name.endsWith('.csv');

export const isXlsxFile = (file: File) => file.name.endsWith('.xlsx');

export const emailInvalidCharactersRegex = /<|>|\?|!|'|"|\||\^|#|\$|%|&|\*|\(|\)|=/g;

export const emailParser = (email: string) => email.replace(emailInvalidCharactersRegex, '');

const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
const domainRE = /^[^\s.]+\.\S{2,}$/;

export const isURL = (value: string): MessageIds | undefined => {
  if (value) {
    if (typeof value !== 'string') {
      return 'validation.is_valid_url';
    }

    const match = value.match(protocolAndDomainRE);
    if (!match) {
      return 'validation.is_valid_url';
    }

    const everythingAfterProtocol = match[1];
    if (!everythingAfterProtocol) {
      return 'validation.is_valid_url';
    }

    if (domainRE.test(everythingAfterProtocol)) {
      return undefined;
    }

    return 'validation.is_valid_url';
  }

  return undefined;
};
