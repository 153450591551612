import { FormikMultiSelectField } from 'components';
import { useI18n } from 'i18n';
import { Group } from 'modules/groups/types';

interface Props {
  groups: Group[] | null | undefined;
}

export const FormikGroupsSelectField: React.FC<Props> = ({ groups }: Props) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <FormikMultiSelectField
      name="groups"
      options={(groups || [])
        .filter((group) => !group.add_to_all)
        .map((group) => ({
          label: group.name,
          value: group.id,
        }))}
      multiSelectProps={{
        placeholder: getLocalizedMessage('users.edit-user.select-groups'),
        menuPlacement: 'top',
        maxMenuHeight: 196,
      }}
    />
  );
};
