import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { env } from 'config';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 10 * 60 * 1000, // 10 minutes
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 0,
      staleTime: 10 * 60 * 1000,
      suspense: false,
    },
  },
});

export const WAWQueryClientProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    {env.NODE_ENV === 'development' && <ReactQueryDevtools />}
    {children}
  </QueryClientProvider>
);
