import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useState } from 'react';
import { pick } from 'lodash';

import { Corporate } from 'modules/corporates/types';
import { formatCorporateDataToFormValues } from 'modules/corporates/formatFormValues';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { useCountryConfig } from 'modules/country-configs';
import { useEditInvoicesAndReceipts } from 'modules/corporates/useEditInvoicesAndReceipts';

import { EditCorporateFormValues } from '../CompanySettings/types';
import { InvoicesAndReceiptsForm } from '../SignupV2/components/InvoicesAndReceipts/InvoicesAndReceiptsForm';

type Props = {
  corporate: Corporate;
};

export const FORM_KEYS = [
  'invoice_email',
  'invoice_email_cc_recipients',
  'single_receipt_email',
  'preferred_invoice_locale',
  'customer_reference',
  'billing_twice_a_month',
  'payment_term_delay_days',
  'invoice_overdue_interest',
  'organise_invoice_by_groups',
  'organise_order_report_by_groups',
  'is_electronic_invoicing_enabled',
  'electronic_invoicing_details',
  'credit_limit_amount',
  'transaction_limits',
  'invoice_fee_percentage',
  'has_transaction_limits',
];
type FormValues = Pick<
  EditCorporateFormValues,
  | 'invoice_email'
  | 'invoice_email_cc_recipients'
  | 'single_receipt_email'
  | 'preferred_invoice_locale'
  | 'customer_reference'
  | 'billing_twice_a_month'
  | 'payment_term_delay_days'
  | 'invoice_overdue_interest'
  | 'organise_invoice_by_groups'
  | 'organise_order_report_by_groups'
  | 'is_electronic_invoicing_enabled'
  | 'electronic_invoicing_details'
  | 'credit_limit_amount'
  | 'transaction_limits'
  | 'invoice_fee_percentage'
  | 'has_transaction_limits'
>;

export const InvoicesAndReceiptsContainer = ({ corporate }: Props) => {
  const [state, setState] = useState<'view' | 'edit'>('view');
  const initialValues = pick(formatCorporateDataToFormValues(corporate), FORM_KEYS) as FormValues;
  const { isWoltEmployeeWithUpdate } = useIsUserWoltEmployee();
  const { data: countryConfig } = useCountryConfig();

  const editInvoicesAndReceipts = useEditInvoicesAndReceipts(corporate);

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      try {
        await editInvoicesAndReceipts(initialValues, values);
        actions.setSubmitting(false);
        actions.setStatus({ submitSucceeded: true });
        actions.resetForm();
        setState('view');
      } catch (error) {
        actions.setSubmitting(false);
        actions.setStatus({ error });
      }
    },
    [editInvoicesAndReceipts, initialValues],
  );

  return (
    <Formik
      validateOnMount={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm, dirty, status, resetForm }) => (
        <Form>
          <InvoicesAndReceiptsForm
            isWoltEmployee={isWoltEmployeeWithUpdate}
            countryConfig={countryConfig}
            corporate={corporate}
            renderMode={state}
            onEditClick={() => setState('edit')}
            onCancelClick={() => {
              resetForm();
              setState('view');
            }}
            onSaveClick={submitForm}
            isSubmitting={isSubmitting || status?.submitSucceeded}
            dirty={dirty}
            error={status?.error}
          />
        </Form>
      )}
    </Formik>
  );
};
