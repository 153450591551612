import { useCallback, useMemo } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQuery, UseQueryOptions } from 'modules/react-query';
import { PaginatedData } from 'types/PaginatedData';

import { fetchInvites } from './api';
import { UserInvite } from './types';

type FetchInvitesData = {
  email?: string;
  groupIds?: string[];
  statuses?: string[];
  page: number;
  page_size: number;
};

export const useFetchInvites = <T = PaginatedData<UserInvite>>(
  { page, page_size, email, groupIds, statuses }: FetchInvitesData,
  config?: UseQueryOptions<PaginatedData<UserInvite>, unknown, T>,
) => {
  const { data: corporate } = useCorporate();
  const isExpired = useMemo(() => {
    const inviteStatuses = statuses?.filter((status) => ['pending', 'expired'].includes(status));
    if (inviteStatuses?.length === 2) {
      return undefined;
    }
    return inviteStatuses?.some((status) => status === 'expired');
  }, [statuses]);
  const queryFn = useCallback(() => {
    if (!corporate) {
      return Promise.reject();
    }
    return fetchInvites(corporate.id, {
      is_expired: isExpired,
      group_ids: groupIds,
      email: email,
      page,
      page_size,
    });
  }, [corporate, email, groupIds, isExpired, page, page_size]);
  return useQuery({
    queryKey: ['invites', corporate?.id, page, page_size, email, groupIds, isExpired],
    queryFn,
    enabled: !!corporate,
    ...config,
  });
};
