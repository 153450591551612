import styled from 'styled-components';
import { LegacyPopover as Popover, PopoverPlacement as Placement } from '@creditornot/cb-popover';
import {
  IconButton as CookBookIconButton,
  IconButtonBackgroundStyle as BackgroundStyle,
  IconButtonSize as Size,
  IconButtonVariant as Variant,
} from '@creditornot/cb-button';

interface Props {
  className?: string;
  testId?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  tooltipText?: React.ReactNode;
  tooltipPlacement?: Placement;
  variant?: Variant;
  size?: Size;
  background?: BackgroundStyle;
}

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const StyledPopover = styled(Popover)`
  height: 36px;
`;

const IconButtonWithHelpText: React.FC<Props> = ({
  className,
  testId,
  tooltipText,
  children,
  onClick,
  variant = 'black',
  tooltipPlacement = 'bottom',
  disabled = false,
  size = 'small',
  background = 'animated',
}) => {
  return (
    <Root className={className}>
      <StyledPopover renderArrow={false} placement={tooltipPlacement} content={tooltipText}>
        <CookBookIconButton
          data-test-id={testId}
          size={size}
          variant={variant}
          disabled={disabled}
          onClick={!disabled ? onClick : () => {}}
          background={background}
        >
          {children}
        </CookBookIconButton>
      </StyledPopover>
    </Root>
  );
};

export default IconButtonWithHelpText;
