import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useCorporate } from 'modules/corporates';

const useUsersModalsController = () => {
  const { data: corporate } = useCorporate();
  const history = useHistory();
  const closeModal = useCallback(() => {
    if (!corporate) {
      return;
    }
    const search = new URLSearchParams(history.location.search);
    search.delete('user');
    search.delete('invite');
    const pathname = `/${corporate.id}/users?${search.toString()}`;
    history.push(pathname);
  }, [corporate, history]);
  const openDeleteInviteModal = useCallback(
    (inviteId: string) => {
      const search = new URLSearchParams(history.location.search);
      search.set('invite', inviteId);
      const pathname = `${history.location.pathname}/delete-invites?${search.toString()}`;
      history.push(pathname);
    },
    [history],
  );
  const openDeleteInvitesModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    const pathname = `${history.location.pathname}/delete-invites?${search.toString()}`;
    history.push(pathname);
  }, [history]);
  const openDeleteUserModal = useCallback(
    (userId: string) => {
      const search = new URLSearchParams(history.location.search);
      search.set('user', userId);
      const pathname = `${history.location.pathname}/delete-users?${search.toString()}`;
      history.push(pathname);
    },
    [history],
  );
  const openDeleteUsersModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    const pathname = `${history.location.pathname}/delete-users?${search.toString()}`;
    history.push(pathname);
  }, [history]);
  const openEditUserModal = useCallback(
    (userId: string) => {
      const search = new URLSearchParams(history.location.search);
      const pathname = `${history.location.pathname}/edit-user/${userId}?${search.toString()}`;
      history.push(pathname);
    },
    [history],
  );
  const openResendUserInviteModal = useCallback(
    (inviteId: string) => {
      const search = new URLSearchParams(history.location.search);
      search.set('invite', inviteId);
      const pathname = `${history.location.pathname}/resend-invites?${search.toString()}`;
      history.push(pathname);
    },
    [history],
  );
  const openResendUserInvitesModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    const pathname = `${history.location.pathname}/resend-invites?${search.toString()}`;
    history.push(pathname);
  }, [history]);
  const openUserInviteModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    const pathname = `${history.location.pathname}/invite?${search.toString()}`;
    history.push(pathname);
  }, [history]);

  return {
    closeModal,
    openDeleteInviteModal,
    openDeleteInvitesModal,
    openDeleteUserModal,
    openDeleteUsersModal,
    openEditUserModal,
    openResendUserInviteModal,
    openResendUserInvitesModal,
    openUserInviteModal,
  };
};

export default useUsersModalsController;
