import { useCallback } from 'react';

import { ConfirmationModal } from 'components';
import { LocalizedMessage } from 'i18n';
import { useCorporate } from 'modules/corporates';
import { useEditGroup } from 'modules/groups';
import { Group } from 'modules/groups/types';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  show: boolean;
  group: Group | null | undefined;
}

export const EditGroupStatusModal = ({ onClose, onSuccess, show, group }: Props) => {
  const { data: corporate } = useCorporate();
  const editGroup = useEditGroup();
  const shouldShow = show && !!group && !!corporate;

  const handleConfirm = useCallback(async () => {
    if (!group) {
      throw 'Group is missing';
    }

    const active = !!group.active;
    editGroup(group.id, {
      active: !active,
    });
  }, [editGroup, group]);

  return (
    <ConfirmationModal
      onClose={onClose}
      onConfirm={handleConfirm}
      show={shouldShow}
      content={
        <LocalizedMessage
          messageKey={
            group?.active
              ? 'groups-form.edit-status.disable-description'
              : 'groups-form.edit-status.enable-description'
          }
          values={{
            corporate: corporate?.name,
            group: group?.name,
            strong: (text: string) => <strong>{text}</strong>,
          }}
        />
      }
      title={
        <LocalizedMessage
          messageKey={
            group?.active
              ? 'groups-form.edit-status.disable-title'
              : 'groups-form.edit-status.enable-title'
          }
        />
      }
      confirmationButtonMessage={
        <LocalizedMessage
          messageKey={
            group?.active
              ? 'groups-form.edit-status.disable-button'
              : 'groups-form.edit-status.enable-button'
          }
        />
      }
      confirmationButtonVariant={group?.active ? 'lightRed' : 'blue'}
      onSuccess={() => setTimeout(onSuccess, 500)}
    ></ConfirmationModal>
  );
};
