import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';
import { WoltLogo } from '@creditornot/cb-ingredients';
import { MenuItemGroup } from '@creditornot/cb-menu';
import { IconButton } from '@creditornot/cb-button';
import { Menu } from '@creditornot/cb-icons';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { NeverNullStringParam, useQueryParams, withDefault } from 'modules/react-query-params';
import { useMedia } from 'modules/media';
import { CountrySelect, FillViewportHeightDiv, ModalV2 } from 'components';
import { useConfigs } from 'modules/configs';
import { useLocalStorage } from 'modules/hooks';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { useCookieConsents } from 'modules/cookie-consents/CookieConsentsProvider';

import UserInfo from './UserInfo';
import Section from './Section';
import { NavLinks } from './NavLinks';
import { Guidance } from './Guidance';
import CorporateNavigation from '../CorporateNavigation';
import { Compliance } from './Compliance';

const Root = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex-basis: min-content;
  justify-content: space-between;
  min-width: 320px;
  max-width: 320px;
  overflow-y: auto;
  border-inline-end: 1px solid ${color.border};
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCompliance = styled(Compliance)`
  margin-top: 24px;
`;

const SmallViewRoot = styled(FillViewportHeightDiv)`
  padding: 24px;
  min-width: unset;
  border-inline-end: 1px solid ${color.border};
`;

const TopBarRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  border-bottom: 1px solid ${color.border};
  background-color: ${color.bg};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

const GuidanceSection = styled(Section)`
  padding-bottom: 24px;
`;

const StyledMenuItemGroup = styled(MenuItemGroup)`
  margin-top: 36px;
`;

const StyledWoltLogo = styled(WoltLogo)`
  fill: ${color.text};
  height: 16px;
`;

export const SideBarNavigation = () => {
  const medium = useMedia('medium');
  const [show, setShow] = useState(false);
  const [localstoragecountrycode] = useLocalStorage('countryCode', 'FIN');
  const { openManageCookieConsentsModal } = useCookieConsents();
  const [{ country }, setQueryParam] = useQueryParams({
    country: withDefault(NeverNullStringParam, localstoragecountrycode),
  });
  const isOnCoporatesPage = useRouteMatch({
    exact: true,
    path: '/',
  });
  const { user } = useAuthState();
  const { configs } = useConfigs();
  const handleCountryChange = useCallback(
    (code: string) => {
      setQueryParam({ country: code });
    },
    [setQueryParam],
  );
  const { isWoltEmployee } = useIsUserWoltEmployee();

  if (!user) {
    return null;
  }

  if (medium) {
    return (
      <>
        <TopBarRoot>
          {isOnCoporatesPage ? (
            <>
              <StyledWoltLogo />
              {isWoltEmployee && (
                <CountrySelect
                  value={country}
                  placement="bottom"
                  onChange={handleCountryChange}
                  options={
                    configs
                      ? configs.countries.map((country) => ({
                          label: country.name,
                          value: country.code,
                        }))
                      : []
                  }
                />
              )}
            </>
          ) : (
            <IconButton onClick={() => setShow((state) => !state)} size="small" variant="black">
              <Menu />
            </IconButton>
          )}

          <CorporateNavigation />

          <UserInfo />
        </TopBarRoot>

        <ModalV2 sizePreset="sideStartSheet:small" open={show} onDismiss={() => setShow(false)}>
          <SmallViewRoot>
            <Section>
              <Link to="/">
                <StyledWoltLogo />
              </Link>
            </Section>
            <StyledMenuItemGroup>
              <NavLinks onClick={() => setShow(false)} />
            </StyledMenuItemGroup>
            <GuidanceSection>
              <Guidance />
            </GuidanceSection>
            <StyledCompliance openManageCookieConsentsModal={openManageCookieConsentsModal} />
          </SmallViewRoot>
        </ModalV2>
      </>
    );
  }

  return (
    <Root>
      <TopSection>
        <Section>
          <Link to="/">
            <StyledWoltLogo />
          </Link>
        </Section>
        <Section>
          {isOnCoporatesPage ? (
            <>
              {isWoltEmployee && (
                <CountrySelect
                  value={country}
                  onChange={handleCountryChange}
                  options={
                    configs
                      ? configs.countries.map((country) => ({
                          label: country.name,
                          value: country.code,
                        }))
                      : []
                  }
                />
              )}
            </>
          ) : (
            <CorporateNavigation />
          )}
        </Section>
        <Section>
          <UserInfo />
        </Section>
        {!isOnCoporatesPage && (
          // don't render NavLinks on corporates page as it uses corporete id for API call
          <StyledMenuItemGroup>
            <NavLinks />
          </StyledMenuItemGroup>
        )}
        <Section>
          <Guidance />
        </Section>
      </TopSection>
      <StyledCompliance openManageCookieConsentsModal={openManageCookieConsentsModal} />
    </Root>
  );
};
