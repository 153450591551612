import { Route, Switch } from 'react-router-dom';

import AgreementPartiesView from './AgreementPartiesView';

const RootRoutes = () => {
  return (
    <Switch>
      <Route exact={true} path="/internal/agreement-parties" component={AgreementPartiesView} />
      <Route
        exact={true}
        path="/internal/agreement-parties/edit/:agreementPartyId"
        component={AgreementPartiesView}
      />
    </Switch>
  );
};

export default RootRoutes;
