import styled from 'styled-components';
import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { LocalizedMessage } from 'i18n';
import { EditPolicyForm } from 'modules/policies/components';
import { useCorporate } from 'modules/corporates';
import { View } from 'components';
import { breakpoints } from 'modules/media';

type Params = {
  corporateId: string;
  policyId: string;
};

const Root = styled(View)`
  width: 100%;
  max-width: 783px;
  margin: 80px auto auto;
  padding-bottom: 24px;

  @media (max-width: ${breakpoints.medium}px) {
    margin-top: 0;
  }
`;

export const EditPolicyView = () => {
  const history = useHistory();
  const match = useRouteMatch<Params>('/:corporateId/policies/:policyId');
  const { data: corporate } = useCorporate();
  const handleBackClick = useCallback(() => {
    if (!corporate) return;
    history.push(`/${corporate.id}/policies`);
  }, [corporate, history]);

  if (!match) {
    return null;
  }

  return (
    <Root
      onBackClick={handleBackClick}
      title={<LocalizedMessage messageKey="policies.edit-policy.title" />}
      headerStyle={{ paddingBottom: 0 }}
      hideHeaderBorder
    >
      <EditPolicyForm
        policyId={match.params.policyId}
        onSuccess={handleBackClick}
        onBackClick={handleBackClick}
      />
    </Root>
  );
};
