import { IconButton } from '@creditornot/cb-button';
import { MoreHorizontal } from '@creditornot/cb-icons';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { DropdownButton, Spinner } from 'components';
import { isDefined, processError } from 'utils';
import { VenuePayout } from 'modules/merchantPayouts/types';
import { fetchMerchantPayoutsCsv, fetchMerchantPayoutsZip } from 'modules/merchantPayouts/api';
import { LocalizedMessage } from 'i18n';
import { useNotificationToast } from 'modules/app-modals/useNotificationToast';
import { breakpoints } from 'modules/media';
import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';
import { invoicesInteractionDownload } from 'telemetry/Avo';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';

import { downloadEncodedCsv, downloadFileFromAxiosResponse } from '../utils';

type ReportsDropdownActionsProps = {
  report: VenuePayout;
  corporateId: string;
  isJapanese: boolean;
};

const DOWNLOAD_DOCUMENT_ERROR = 'DOWNLOAD_DOCUMENT_ERROR';

const SpinnerContainer = styled.div`
  max-height: 32px;

  ${cssDirectionalValue({
    ltr: 'margin-right: 8px;',
    rtl: 'margin-left: 8px;',
  })}
  @media (min-width: ${breakpoints.medium}px) {
    ${cssDirectionalValue({
      ltr: 'margin-right: 0px;',
      rtl: 'margin-left: 0px;',
    })}
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ReportsDropdownActions = ({
  report: { id, csvReportExists, transferStatus },
  corporateId,
  isJapanese,
}: ReportsDropdownActionsProps) => {
  const { formatMessage } = useIntl();
  const { openNotificationToast } = useNotificationToast();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const downloadReportEvent = useTelemetryInteractionEvent(invoicesInteractionDownload);

  const actions = useMemo(() => {
    const handleDownloadError = (error: unknown) => {
      openNotificationToast(DOWNLOAD_DOCUMENT_ERROR, {
        variant: 'error',
        autoCloseIn: 10 * 1000,
        title: processError(error).message,
        children: (
          <>
            {processError(error).data || (
              <LocalizedMessage messageKey="invoices.error-downloading-document" />
            )}
          </>
        ),
      });
    };

    return [
      {
        label: formatMessage({ id: 'reports.download-zip' }),
        onClick: async () => {
          downloadReportEvent({ resource: 'payout_report_pdf', source: 'payouts' });
          setIsDownloading(true);
          try {
            const zipData = await fetchMerchantPayoutsZip(corporateId, id);
            downloadFileFromAxiosResponse(zipData);
          } catch (error) {
            handleDownloadError(error);
          } finally {
            setIsDownloading(false);
          }
        },
      },
      csvReportExists
        ? {
            label: formatMessage({ id: 'reports.download-csv' }),
            onClick: async () => {
              downloadReportEvent({ resource: 'payout_report_csv', source: 'payouts' });
              setIsDownloading(true);
              try {
                const { data } = await fetchMerchantPayoutsCsv(corporateId, id);
                downloadEncodedCsv(data, 'text/csv', 'payouts.csv', isJapanese);
              } catch (error) {
                handleDownloadError(error);
              } finally {
                setIsDownloading(false);
              }
            },
          }
        : undefined,
    ].filter(isDefined);
  }, [
    formatMessage,
    csvReportExists,
    openNotificationToast,
    corporateId,
    id,
    isJapanese,
    downloadReportEvent,
  ]);

  if (!actions.length) {
    return null;
  }

  return (
    <>
      {isDownloading ? (
        <SpinnerContainer>
          <Spinner size="small" />
        </SpinnerContainer>
      ) : (
        <DropdownButton actions={actions}>
          <IconButton
            size="small"
            background="animated"
            variant="black"
            disabled={transferStatus === 'not_applicable'}
          >
            <MoreHorizontal />
          </IconButton>
        </DropdownButton>
      )}
    </>
  );
};
