import { ComponentProps } from 'react';

import { FormikInputField } from 'components';
import { createValidator, isNumber, isRequired, max, min } from 'validation';

export const FormikEventParticipantsBudgetInput: React.FC<
  Partial<ComponentProps<typeof FormikInputField>> & {
    currency: string;
  }
> = ({ currency, inputProps, ...props }) => {
  return (
    <FormikInputField
      validate={createValidator([isRequired, min(1), max(Number.MAX_SAFE_INTEGER), isNumber])}
      required={true}
      name="participant_budget_amount"
      inputProps={{
        suffixText: currency,
        ...inputProps,
      }}
      {...props}
    />
  );
};
