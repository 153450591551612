import { FormikInputField, FormikSwitch } from 'components';
import { isRequired } from 'validation';
import { useI18n } from 'i18n';

interface Props {
  alwaysShowError?: boolean;
  disabled?: boolean;
}

export const ElectronicInvoicingAddress = ({ alwaysShowError, disabled }: Props) => {
  return (
    <FormikInputField
      name="electronic_invoicing_details.edi"
      type="text"
      validate={disabled ? undefined : isRequired}
      alwaysShowError={alwaysShowError}
      disabled={disabled}
    />
  );
};

export const EDIOperatorId = ({ alwaysShowError, disabled }: Props) => {
  return (
    <FormikInputField
      name="electronic_invoicing_details.edi_operator"
      type="text"
      validate={disabled ? undefined : isRequired}
      alwaysShowError={alwaysShowError}
      disabled={disabled}
    />
  );
};

export const VATExemptionDisclaimer = ({ alwaysShowError, disabled }: Props) => {
  return (
    <FormikInputField
      name="electronic_invoicing_details.vat_exemption_disclaimer"
      type="text"
      alwaysShowError={alwaysShowError}
      disabled={disabled}
    />
  );
};

export const IsElectronicInvoicingDetailsEnabledField = ({ disabled }: { disabled?: boolean }) => {
  const { getLocalizedMessage } = useI18n();
  return (
    <FormikSwitch
      name="is_electronic_invoicing_enabled"
      label={getLocalizedMessage('corporate-form.is-electronic-invoicing-enabled')}
      switchProps={{
        inputProps: {
          id: 'is_electronic_invoicing_enabled',
        },
        disabled: disabled,
      }}
    />
  );
};

export const IsElectronicInvoicingDetailsEnabledFieldV2 = ({
  disabled,
}: {
  disabled?: boolean;
}) => {
  return (
    <FormikSwitch
      name="is_electronic_invoicing_enabled"
      switchProps={{
        inputProps: {
          id: 'is_electronic_invoicing_enabled',
        },
        disabled: disabled,
      }}
    />
  );
};
