import { color } from '@creditornot/cb-ingredients/design-tokens';
import { Input } from '@creditornot/cb-input';
import { MagnifyingGlass } from '@creditornot/cb-icons';
import { ChangeEventHandler, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Button } from '@creditornot/cb-button';
import { Alert } from '@creditornot/cb-alert';
import { Tabs } from '@creditornot/cb-tabs';

import { Header, ResponsiveContainer, ViewInfo } from 'components';
import { breakpoints, useMedia } from 'modules/media';
import { LocalizedMessage, useI18n } from 'i18n';
import { useDebounce } from 'modules/hooks';
import { useFetchEvent, useFetchEventUsers, useFetchEventStatistics } from 'modules/events';
import { LoadingState } from 'components/LoadingState';
import { processError, formatCamelCaseToSnakeCase } from 'utils';
import { useCorporate } from 'modules/corporates';
import { EventUser } from 'modules/events/types';

import { CancelEventModal } from './CancelEventModal';
import { DeleteUserEventModal } from './DeleteUserEventModal';
import { ResendEventInviteModal } from './ResendEventInviteModal';
import { EditEventNameModal } from './EditEventNameModal';
import { EditUpComingEventForm } from './EditUpComingEventForm';
import { EditOnGoingEventForm } from './EditOnGoingEventForm';
import { EventDetails } from './EventDetails';
import { EventStatisticsRow } from './EventStatisticsRow';
import { EventStatus } from './EventStatus';
import { EventUsersDataList } from './EventUsersDataList';
import { EventUsersDataTable } from './EventUsersDataTable';
import { InviteEventUsersModal } from './InviteEventUsersModal';
import { useEventsQueryParams, useOpenSendEventReminderSuccessNotification } from './hooks';
import { SendEventReminderModal } from './SendEventReminderModal';
import { EventUsersFilter } from './EventUsersFilter';

const EventData = styled.div`
  grid-area: details;
  border-inline-end: 1px solid ${color.border};
`;

const EventUsers = styled.div`
  grid-area: users;
`;

const Root = styled.div`
  display: grid;
  grid-template-columns: minmax(250px, 1fr) repeat(3, 1fr);
  grid-template-areas:
    'statistic-error statistic-error statistic-error statistic-error'
    'usage           statistic       statistic       statistic'
    'details         users           users           users';

  @media (max-width: ${breakpoints.medium}px) {
    grid-template:
      'tab'
      'statistic-error'
      'usage'
      'statistic'
      'details'
      'users';
  }
`;

const ErrorAlert = styled(Alert).attrs({
  variant: 'error',
  size: 'medium',
})`
  margin-top: 24px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;

const EventHeading = styled.h6`
  ${typographyCss.Heading6()}
`;

const StyledEditUpComingEventForm = styled(EditUpComingEventForm)`
  padding: 24px;

  @media (max-width: ${breakpoints.medium}px) {
    padding: 16px;
  }
`;

const StyledEditOnGoingEventForm = styled(EditOnGoingEventForm)`
  padding: 24px;

  @media (max-width: ${breakpoints.medium}px) {
    padding: 16px;
  }
`;

const StyledEventDetails = styled(EventDetails)`
  padding: 24px;

  @media (max-width: ${breakpoints.medium}px) {
    padding: 16px;
  }
`;

const StyledEventHeader = styled(Header)`
  min-height: 88px;

  @media (max-width: ${breakpoints.medium}px) {
    min-height: unset;
  }
`;

const StyledActionBar = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
  }
`;

const StyledInput = styled(Input)`
  width: 240px;
  margin-inline-end: 16px;

  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
    margin-inline-end: 0;
    margin-bottom: 16px;
  }
`;

const StyledEventUsersFilter = styled(EventUsersFilter)`
  width: 180px;

  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
  }
`;

const ViewInfoActionButtons = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
`;

const EventStatisticsError = styled(ResponsiveContainer)`
  grid-area: statistic-error;
`;

const EventTabs = styled(Tabs)`
  grid-area: tab;
  border-bottom: 1px solid ${color.border};
`;

export const EventView: React.FC<
  RouteComponentProps<{
    eventId: string;
    corporateId: string;
    actions:
      | 'cancel-event'
      | 'delete-user'
      | 'edit-event-name'
      | 'invite-users'
      | 'resend-invite'
      | 'send-reminder';
  }>
> = ({ match }) => {
  const { getLocalizedMessage } = useI18n();
  const { data: corporate } = useCorporate();
  const [{ page, page_size, event_user_invite_ids, email, active_tab, statuses }, setQueryParams] =
    useEventsQueryParams();
  const openSendEventReminderSuccessNotification = useOpenSendEventReminderSuccessNotification();
  const isMediumView = useMedia('medium');
  const {
    data: eventData,
    isLoading: isLoadingEvent,
    error: eventError,
  } = useFetchEvent(match.params.eventId);
  const debouncedEmail = useDebounce(email, 500);
  const {
    data: eventUsersData,
    isLoading: isLoadingEventUsers,
    error: eventUsersError,
  } = useFetchEventUsers(
    match.params.eventId,
    {
      page,
      page_size,
      email: debouncedEmail,
      statuses: statuses?.map(formatCamelCaseToSnakeCase),
    },
    { refetchInterval: 30 * 1000 },
  );
  const { data: eventStatistics, error: eventStatisticsError } = useFetchEventStatistics(
    match.params.eventId,
    {
      refetchInterval: 30 * 1000,
    },
  );
  const history = useHistory();

  const handleBackClick = useCallback(() => {
    history.push(`/${match.params.corporateId}/events`);
  }, [history, match.params.corporateId]);

  const handleCancelEventClick = useCallback(() => {
    history.push({
      pathname: `${history.location.pathname}/cancel-event`,
      search: history.location.search,
    });
  }, [history]);

  const handleModalClose = useCallback(() => {
    history.push({
      pathname: `/${match.params.corporateId}/events/info/${match.params.eventId}`,
      search: history.location.search,
    });
  }, [history, match.params.corporateId, match.params.eventId]);

  const handleDeleteUserEventModalClose = useCallback(() => {
    history.push({
      pathname: `/${match.params.corporateId}/events/info/${match.params.eventId}`,
      search: history.location.search,
    });
    setQueryParams({
      event_user_invite_ids: [],
    });
  }, [history, match.params.corporateId, match.params.eventId, setQueryParams]);

  const handleResendEventInviteModalClose = useCallback(() => {
    history.push({
      pathname: `/${match.params.corporateId}/events/info/${match.params.eventId}`,
      search: history.location.search,
    });
    setQueryParams({
      event_user_invite_ids: [],
    });
  }, [history, match.params.corporateId, match.params.eventId, setQueryParams]);

  const handleCancelEventSuccess = useCallback(() => {
    history.push(`/${match.params.corporateId}/events`);
  }, [history, match.params.corporateId]);

  const handleEditEvent = useCallback(() => {
    history.push({
      pathname: `${history.location.pathname}/edit-event-name`,
      search: history.location.search,
    });
  }, [history]);

  const handleDeleteUsers = useCallback(
    (eventUserIds: string[]) => {
      history.push({
        pathname: `${history.location.pathname}/delete-user`,
        search: history.location.search,
      });
      setQueryParams({
        event_user_invite_ids: eventUserIds,
      });
    },
    [history, setQueryParams],
  );

  const handleInviteEventUsersClick = useCallback(() => {
    history.push({
      pathname: `${history.location.pathname}/invite-users`,
      search: history.location.search,
    });
  }, [history]);

  const handleSendEventReminderClick = useCallback(() => {
    history.push({
      pathname: `${history.location.pathname}/send-reminder`,
      search: history.location.search,
    });
  }, [history]);

  const handleResendInviteClick = useCallback(
    ({ invite_id }: EventUser) => {
      history.push({
        pathname: `${history.location.pathname}/resend-invite`,
        search: history.location.search,
      });
      setQueryParams({
        event_user_invite_ids: [invite_id],
      });
    },
    [history, setQueryParams],
  );

  const handleSendEventReminderSuccess = useCallback(() => {
    history.push({
      pathname: `/${match.params.corporateId}/events/info/${match.params.eventId}`,
      search: history.location.search,
    });
    openSendEventReminderSuccessNotification();
  }, [
    history,
    match.params.corporateId,
    match.params.eventId,
    openSendEventReminderSuccessNotification,
  ]);

  const handlePageChange = useCallback(
    (page: number) => setQueryParams({ page }),
    [setQueryParams],
  );

  const handleOnPageSizeChange = useCallback(
    (pageSize: number) => setQueryParams({ page_size: pageSize }),
    [setQueryParams],
  );

  const handleDeleteUserClick = useCallback(
    ({ invite_id }: EventUser) => handleDeleteUsers([invite_id]),
    [handleDeleteUsers],
  );

  const handleEventTabChange = useCallback(
    (id: string) => {
      setQueryParams({
        active_tab: id,
      });
    },
    [setQueryParams],
  );

  const isEndedEvent = useMemo(
    () => ['cancelled', 'deleted', 'finished'].includes(eventData?.status ?? ''),
    [eventData?.status],
  );

  const handleUserSearchInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) =>
      setQueryParams({
        email: e.target.value,
      }),
    [setQueryParams],
  );

  const handleClearUserSearchInput = useCallback(
    () =>
      setQueryParams({
        email: '',
      }),
    [setQueryParams],
  );

  const isEventEditable = useMemo(() => eventData?.status === 'upcoming', [eventData?.status]);

  const isEventDeletable = useMemo(
    () => ['ongoing', 'upcoming'].includes(eventData?.status ?? ''),
    [eventData?.status],
  );

  const hasPendingEventInvite = useMemo(
    () => !!eventUsersData?.results.some((user) => ['pending', 'expired'].includes(user.status)),
    [eventUsersData?.results],
  );

  const isFiltering = !!statuses && statuses.length > 0;

  if (!corporate) {
    return null;
  }

  if (isLoadingEvent) {
    return (
      <ViewInfo onBackClick={handleBackClick}>
        <Container>
          <LoadingState>
            <LocalizedMessage messageKey="views.events.loading-event" />
          </LoadingState>
        </Container>
      </ViewInfo>
    );
  }

  const shouldShowEventData = (isMediumView && active_tab === 'event_statistic') || !isMediumView;
  const shouldShowEventUsers =
    (isMediumView && active_tab === 'event_participants') || !isMediumView;

  return (
    <>
      <ViewInfo
        onBackClick={handleBackClick}
        onEditClick={isEventEditable ? handleEditEvent : undefined}
        onDeleteClick={isEventDeletable ? handleCancelEventClick : undefined}
        topContent={eventData && <EventStatus status={eventData.status} />}
        rightContent={
          !isEndedEvent && (
            <ViewInfoActionButtons>
              {hasPendingEventInvite && (
                <Button
                  key="send-event-reminder-button"
                  size="small"
                  variant="lightBlue"
                  onClick={handleSendEventReminderClick}
                >
                  <LocalizedMessage messageKey="events.send-event-reminder-button" />
                </Button>
              )}

              <Button
                key="event-users-invite-button"
                size="small"
                variant="blue"
                onClick={handleInviteEventUsersClick}
              >
                <LocalizedMessage messageKey="events.create-event-form.invite-participants-button" />
              </Button>
            </ViewInfoActionButtons>
          )
        }
        title={eventData?.name}
      >
        <Root>
          {isMediumView && (
            <EventTabs
              activeTabId={active_tab}
              onChange={handleEventTabChange}
              items={[
                {
                  id: 'event_statistic',
                  label: getLocalizedMessage('events.tab.event-statistic'),
                },
                {
                  id: 'event_participants',
                  label: getLocalizedMessage('events.tab.event-participants'),
                },
              ]}
            />
          )}

          {shouldShowEventData && (
            <>
              <>
                {eventStatisticsError && (
                  <EventStatisticsError>
                    <ErrorAlert title={processError(eventStatisticsError).message}>
                      {processError(eventStatisticsError).data}
                    </ErrorAlert>
                  </EventStatisticsError>
                )}

                {eventStatistics && (
                  <EventStatisticsRow
                    isEndedEvent={isEndedEvent}
                    eventStatistics={eventStatistics}
                  />
                )}
              </>

              <EventData>
                <StyledEventHeader
                  borderBottom
                  leftItems={[
                    <EventHeading key="EventsHeading">
                      <LocalizedMessage messageKey="events.event-details.header" />
                    </EventHeading>,
                  ]}
                />
                {!!eventError && (
                  <ResponsiveContainer>
                    <ErrorAlert title={processError(eventError).message}>
                      {processError(eventError).data}
                    </ErrorAlert>
                  </ResponsiveContainer>
                )}

                {eventData &&
                  (eventData.status === 'upcoming' ? (
                    <StyledEditUpComingEventForm
                      eventData={eventData}
                      currency={corporate.currency}
                    />
                  ) : eventData.status === 'ongoing' ? (
                    <StyledEditOnGoingEventForm
                      eventData={eventData}
                      currency={corporate.currency}
                    />
                  ) : (
                    <StyledEventDetails eventData={eventData} currency={corporate.currency} />
                  ))}
              </EventData>
            </>
          )}
          {shouldShowEventUsers && (
            <EventUsers>
              <StyledEventHeader
                borderBottom
                leftItems={[
                  <EventHeading key="EventParticipants">
                    <LocalizedMessage
                      messageKey="events.participants.header"
                      values={{
                        number: eventUsersData?.total_items_count ?? 0,
                      }}
                    />
                  </EventHeading>,
                ]}
                rightItems={[
                  <StyledActionBar key="EventSearchAndInvite">
                    <StyledInput
                      key="event-users-search-input"
                      icon={<MagnifyingGlass />}
                      placeholder={getLocalizedMessage(
                        'events.participants.search-participants-placeholder',
                      )}
                      value={email}
                      onChange={handleUserSearchInputChange}
                      onClearClick={handleClearUserSearchInput}
                    />
                    <StyledEventUsersFilter isEndedEvent={isEndedEvent} key="event-users-filter" />
                  </StyledActionBar>,
                ]}
              />

              {!!eventUsersError && (
                <ResponsiveContainer>
                  <ErrorAlert title={processError(eventUsersError).message}>
                    {processError(eventUsersError).data}
                  </ErrorAlert>
                </ResponsiveContainer>
              )}

              {eventData && (
                <>
                  {isMediumView ? (
                    <EventUsersDataList
                      data={eventUsersData}
                      eventBudget={eventData.participant_budget_amount}
                      isEndedEvent={isEndedEvent}
                      currency={corporate?.currency}
                      onDeleteClick={handleDeleteUserClick}
                      onResendInviteClick={handleResendInviteClick}
                      onInviteClick={handleInviteEventUsersClick}
                      isLoading={isLoadingEventUsers}
                      isSearching={!!debouncedEmail}
                      isFiltering={isFiltering}
                    />
                  ) : (
                    <EventUsersDataTable
                      data={eventUsersData}
                      eventBudget={eventData.participant_budget_amount}
                      isEndedEvent={isEndedEvent}
                      currency={corporate?.currency}
                      page={page}
                      pageSize={page_size}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handleOnPageSizeChange}
                      onDeleteClick={handleDeleteUserClick}
                      onInviteClick={handleInviteEventUsersClick}
                      onResendInviteClick={handleResendInviteClick}
                      isLoading={isLoadingEventUsers}
                      isSearching={!!debouncedEmail}
                      isFiltering={isFiltering}
                    />
                  )}
                </>
              )}
            </EventUsers>
          )}
        </Root>
      </ViewInfo>

      {eventData && (
        <>
          <CancelEventModal
            eventId={match.params.eventId}
            show={match.params.actions === 'cancel-event'}
            eventStatus={eventData.status}
            onClose={handleModalClose}
            onSuccess={handleCancelEventSuccess}
          />

          <DeleteUserEventModal
            show={match.params.actions === 'delete-user'}
            onClose={handleDeleteUserEventModalClose}
            onSuccess={handleDeleteUserEventModalClose}
            eventId={match.params.eventId}
            eventUsers={
              eventUsersData?.results.filter(({ invite_id }) =>
                event_user_invite_ids?.includes(invite_id),
              ) || []
            }
          />

          <ResendEventInviteModal
            show={match.params.actions === 'resend-invite'}
            onClose={handleResendEventInviteModalClose}
            onSuccess={handleResendEventInviteModalClose}
            eventUsers={
              eventUsersData?.results.filter(({ invite_id }) =>
                event_user_invite_ids?.includes(invite_id),
              ) || []
            }
          />

          {hasPendingEventInvite && (
            <SendEventReminderModal
              show={match.params.actions === 'send-reminder'}
              eventId={match.params.eventId}
              onClose={handleModalClose}
              onSuccess={handleSendEventReminderSuccess}
            />
          )}

          <InviteEventUsersModal
            show={match.params.actions === 'invite-users'}
            eventData={eventData}
            onCloseClick={handleModalClose}
            onSuccess={handleModalClose}
          />

          <EditEventNameModal
            show={match.params.actions === 'edit-event-name'}
            event={eventData}
            onCloseClick={handleModalClose}
            onSuccess={handleModalClose}
          />
        </>
      )}
    </>
  );
};
