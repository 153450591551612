import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useState } from 'react';

import { Corporate, CorporateV2 } from 'modules/corporates/types';
import { formatCorporateDataToFormValuesV2 } from 'modules/corporates/formatFormValues';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { useEditDiscounts } from 'modules/corporates/useEditDiscounts';

import { Discounts } from '../SignupV2/components/Discounts/Discounts';
import { RenderMode } from '../SignupV2/types';

interface Props {
  corporate: Corporate;
  corporateV2: CorporateV2;
}

export const DiscountsContainer = ({ corporate, corporateV2 }: Props) => {
  const [state, setState] = useState<Exclude<RenderMode, 'signup'>>('view');
  const { isWoltEmployeeWithUpdate } = useIsUserWoltEmployee();
  const initialValues = formatCorporateDataToFormValuesV2(corporateV2);

  // Only show the discounts section for Wolt employees in companies where subscription benefit is enabled
  const shouldShow = isWoltEmployeeWithUpdate && corporate.products.subscription_benefit_enabled;

  const editDiscounts = useEditDiscounts();

  const onSubmit = useCallback(
    async (values: CorporateV2, actions: FormikHelpers<CorporateV2>) => {
      try {
        await editDiscounts(initialValues, values);
        actions.setSubmitting(false);
        actions.setStatus({ submitSucceeded: true });
        actions.resetForm();
        setState('view');
      } catch (error) {
        actions.setSubmitting(false);
        actions.setStatus({ error });
      }
    },
    [editDiscounts, initialValues],
  );

  if (!shouldShow) {
    return null;
  }

  return (
    <Formik
      validateOnMount={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm, dirty, status, resetForm }) => (
        <Form>
          <Discounts
            renderMode={state}
            onEditClick={() => setState('edit')}
            onCancelClick={() => {
              resetForm();
              setState('view');
            }}
            onSaveClick={submitForm}
            isSubmitting={isSubmitting || status?.submitSucceeded}
            dirty={dirty}
            error={status?.error}
          />
        </Form>
      )}
    </Formik>
  );
};
