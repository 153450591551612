import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { rem } from 'polished';
import { InputContainer } from '@creditornot/cb-form';

import { CustomPricingPreviewV2 } from 'modules/corporates/components';
import {
  B2bConfigFormValues,
  B2bPricing,
  Discount,
} from 'views/settings/CompanySettings/B2bConfigSettings/types';
import { LocalizedNumber } from 'i18n';
import { FormViewText, FormRowV2 as Row } from 'components';
import { convertFromAmountToCents } from 'utils';

import { convertDistanceRangesToPriceIncrements } from './utils';

const DeliveryDistanceContainer = styled.span`
  ${typographyCss.Body3()}
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem(12)};

  > span {
    color: ${color.textSubdued};
    margin-top: ${rem(12)};
  }
`;

const Content = styled.div`
  ${typographyCss.Body3()}
`;

export function DefaultReadOnlyBooleanRender({ value }: { value: boolean }) {
  const { formatMessage } = useIntl();

  return (
    <FormViewText>
      {formatMessage({ id: value ? 'common.enabled' : 'common.disabled' })}
    </FormViewText>
  );
}

export function DeliveryDistanceReadOnlyRender({
  value,
}: {
  value: B2bConfigFormValues['deliveryDistance'];
}) {
  const { formatMessage } = useIntl();

  return (
    <DeliveryDistanceContainer>
      {value === 0
        ? formatMessage({ id: 'corporate-form.delivery-settings.delivery-distance-default' })
        : formatMessage({ id: 'corporate-form.delivery-settings.delivery-distance-custom' })}

      <span>
        {value === 0
          ? formatMessage({
              id: 'corporate-form.delivery-settings.delivery-distance-default-explanation',
            })
          : `${value}m`}
      </span>
    </DeliveryDistanceContainer>
  );
}

export function DeliveryPricingReadOnlyRender({
  value,
  currency,
}: {
  value: B2bPricing;
  currency: string;
}) {
  const { formatMessage } = useIntl();
  const basePrice = value.customPricing?.basePrice;

  const priceIncrementsInCents = convertDistanceRangesToPriceIncrements(
    value.customPricing?.distanceRanges || [],
    currency,
  );
  const basePriceInCents =
    !basePrice || isNaN(basePrice) ? 0 : convertFromAmountToCents(basePrice, currency);

  return (
    <Row
      title={formatMessage({ id: 'corporate-form.delivery-fee' })}
      content={
        <Content>
          {value.type === 'custom' ? (
            <>
              Custom pricing
              <CustomPricingPreviewV2
                basePrice={basePriceInCents}
                priceIncrements={priceIncrementsInCents}
                currency={currency}
              />
            </>
          ) : (
            <>{value.type === 'flatFee' ? 'Flat rate' : 'Distance based'}</>
          )}
        </Content>
      }
    />
  );
}

export function DeliveryDiscountReadOnlyRender({
  value,
  currency,
  testId,
}: {
  value: B2bConfigFormValues['pricing']['discounts'];
  currency: string;
  testId?: string;
}) {
  const discountDisplay = (discount: Discount, currency: string) => {
    if (discount.type === 'flat' && discount.flatValue) {
      return (
        <LocalizedNumber
          value={convertFromAmountToCents(discount.flatValue, currency)}
          currency={currency}
        />
      );
    }
    if (discount.type === 'percentage' && discount.percentageValue) {
      return `${discount.percentageValue}%`;
    }
    return '-';
  };

  return (
    <InputContainer data-test-id={testId}>
      {value ? discountDisplay(value, currency) : '-'}
    </InputContainer>
  );
}
