import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { View, MultiSearchInput, Header } from 'components';
import { useMedia } from 'modules/media';
import MonthPicker from 'components/MonthPicker/MonthPicker';
import { isDefined } from 'utils';
import { useDebounce } from 'modules/hooks';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { ordersInteractionOrderDetails, ordersView } from 'telemetry/Avo';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';
import { useCorporate } from 'modules/corporates';
import { isSubscriptionBenefitOnlyCorporate } from 'modules/corporates/utils';

import OrderDetailsModal from './OrderDetailsModal';
import OrdersDataTable from './OrdersDataTable';
import OrdersDataList from './OrdersDataList';
import { useOrdersHistory } from './useOrderHistory';
import { OrdersFilter } from './OrdersFilter';

const StyledMonthPicker = styled(MonthPicker)`
  display: flex;
  align-items: center;
`;

const OrdersView = ({
  match,
  history,
}: RouteComponentProps<{ corporateId: string; orderId?: string }>) => {
  const { formatMessage } = useIntl();
  const ordersInteractionOrderDetailsEvent = useTelemetryInteractionEvent(
    ordersInteractionOrderDetails,
  );

  const mediumView = useMedia('medium');
  const { data: corporate } = useCorporate();

  const { params, setQueryParams, ordersData, error, isFetching } = useOrdersHistory();
  const {
    searchBy,
    searchQuery,
    month,
    year,
    status,
    types,
    groups,
    page,
    page_size: pageSize,
  } = params;

  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const isShowingOrdersInPrevious30Day = !isDefined(month) || !isDefined(year);

  const shouldShowLoadingOrdersData = isShowingOrdersInPrevious30Day
    ? ordersData === undefined && isFetching
    : isFetching;

  const handleSearchOptionChange = useCallback(
    (value: string) => setQueryParams({ searchBy: value, searchQuery: '' }),
    [setQueryParams],
  );

  const handleSearchValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setQueryParams({ searchQuery: e.target.value }),
    [setQueryParams],
  );

  const handleSearchValueClear = useCallback(
    () => setQueryParams({ searchQuery: '' }),
    [setQueryParams],
  );

  const handleMonthPickerValuesChange = useCallback(
    (value: { year: number; month: number }) => {
      setQueryParams(value);
    },
    [setQueryParams],
  );

  const openOrderDetailsModal = useCallback(
    (orderId: string) => {
      ordersInteractionOrderDetailsEvent({});
      history.push(`${history.location.pathname}/${orderId}${history.location.search}`);
    },
    [history, ordersInteractionOrderDetailsEvent],
  );

  const closeOrderDetailsModal = useCallback(() => {
    history.push(`/${match.params.corporateId}/orders${history.location.search}`);
  }, [history, match.params.corporateId]);

  const handleMonthPickerValueCleared = useCallback(() => {
    setQueryParams({
      year: undefined,
      month: undefined,
    });
  }, [setQueryParams]);

  const monthPickerValue = isDefined(year) && isDefined(month) ? { year, month } : null;
  const monthPickerDisplayValue = monthPickerValue
    ? undefined
    : formatMessage({ id: 'orders.month-picker.last-30-days' });

  const filters = useMemo(() => {
    // Search and order status/type filters are not relevant for subscription only companies
    if (!corporate || isSubscriptionBenefitOnlyCorporate(corporate)) {
      return [];
    }

    return [
      <MultiSearchInput
        key="OrdersSearchInput"
        searchOptions={[
          {
            label: formatMessage({ id: 'common.user-name' }),
            value: 'user',
          },
          {
            label: formatMessage({ id: 'common.restaurant' }),
            value: 'venue_name',
          },
          {
            label: formatMessage({ id: 'common.accounting-note' }),
            value: 'accounting_note',
          },
        ]}
        selectedSearchOption={searchBy || ''}
        onSearchOptionChange={handleSearchOptionChange}
        searchValue={searchQuery || ''}
        onSearchValueChange={handleSearchValueChange}
        onClearSearch={handleSearchValueClear}
      />,
      <OrdersFilter
        key="OrdersFilter"
        corporateId={match.params.corporateId}
        hasSubscriptionBenefit={corporate.products.subscription_benefit_enabled}
        onChange={setQueryParams}
        groups={groups}
        status={status}
        types={types}
      />,
    ];
  }, [
    corporate,
    formatMessage,
    groups,
    handleSearchOptionChange,
    handleSearchValueChange,
    handleSearchValueClear,
    match.params.corporateId,
    searchBy,
    searchQuery,
    setQueryParams,
    status,
    types,
  ]);

  return (
    <>
      <ViewEventComponent event={ordersView} />
      <View title={formatMessage({ id: 'views.orders' })}>
        <Header
          borderBottom
          leftItems={[
            ...filters,
            <StyledMonthPicker
              key="StyledMonthPicker"
              value={monthPickerValue}
              displayValue={monthPickerDisplayValue}
              onClear={monthPickerValue ? handleMonthPickerValueCleared : undefined}
              onChange={handleMonthPickerValuesChange}
              placement="bottom-start"
            />,
          ]}
        />
        {mediumView ? (
          <>
            <OrdersDataList
              data={ordersData ?? null}
              loading={shouldShowLoadingOrdersData}
              onDataItemClick={(orderId) => openOrderDetailsModal(orderId)}
              error={error}
              isBeingFiltered={!!debouncedSearchQuery || !!status?.length || !!types?.length}
            />
          </>
        ) : (
          <>
            <OrdersDataTable
              data={ordersData ?? null}
              page={page}
              pageSize={pageSize}
              onChange={setQueryParams}
              month={month}
              year={year}
              loading={shouldShowLoadingOrdersData}
              onRowClick={(orderId) => openOrderDetailsModal(orderId)}
              error={error}
            />
          </>
        )}
      </View>

      <OrderDetailsModal
        orderId={match.params.orderId}
        onClose={closeOrderDetailsModal}
        corporateId={match.params.corporateId}
      />
    </>
  );
};

export default OrdersView;
