import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { Alert } from '@creditornot/cb-alert';
import { InputContainer } from '@creditornot/cb-form';

import {
  ContactPersonEmailField,
  ContactPersonNameField,
  ContactPersonPhoneNumberField,
} from 'modules/corporates/components';
import { FormSection, FormViewText, FormRowV2 as Row } from 'components';
import { ApiErrorContainer } from 'views/settings/CompanySettingsV2/ApiErrorContainer';
import { processError } from 'utils';

import { RenderMode } from '../../types';
import { SaveCancelButtons } from '../SaveCancelButtons';
import { EditButton } from '../EditButton';

type Props = {
  renderMode: RenderMode;
  onEditClick?: () => void;
  onSaveClick?: () => void;
  onCancelClick?: () => void;
  isSubmitting?: boolean;
  dirty?: boolean;
  error?: any;
};

type FormValues = {
  contact_email: string;
  contact_name: string;
  contact_phone: string;
};

const defaultViewRender = ({ value }: { value?: string }) => <FormViewText>{value}</FormViewText>;

/**
 * A bit easier to read than the putting all of this logic in the render function.
 */
const renderMap: {
  [key in keyof FormValues]: {
    [key in Props['renderMode']]: (args: { value?: string; hint?: string }) => JSX.Element | null;
  };
} = {
  contact_email: {
    edit: ({ hint }) => (
      <InputContainer hint={hint}>
        <ContactPersonEmailField allowClear={false} />
      </InputContainer>
    ),
    signup: ({ hint }) => (
      <InputContainer hint={hint}>
        <ContactPersonEmailField allowClear={false} />
      </InputContainer>
    ),
    view: defaultViewRender,
  },
  contact_name: {
    edit: () => <ContactPersonNameField allowClear={false} />,
    signup: () => <ContactPersonNameField allowClear={false} />,
    view: defaultViewRender,
  },
  contact_phone: {
    edit: () => <ContactPersonPhoneNumberField allowClear={false} />,
    signup: () => <ContactPersonPhoneNumberField allowClear={false} />,
    view: defaultViewRender,
  },
};

export const ContactPersonForm = ({
  renderMode,
  onEditClick,
  onCancelClick,
  onSaveClick,
  isSubmitting,
  dirty,
  error,
}: Props) => {
  const { formatMessage } = useIntl();

  const { values } = useFormikContext<FormValues>();

  return (
    <FormSection
      testId="contact-information"
      title={formatMessage({ id: 'corporate-form.contact-person-title' })}
      topRightItem={onEditClick && <EditButton renderMode={renderMode} onEditClick={onEditClick} />}
    >
      <Row
        htmlFor="contact_name"
        title={formatMessage({ id: 'common.name' })}
        content={renderMap.contact_name[renderMode]({ value: values.contact_name })}
      />
      <Row
        htmlFor="contact_email"
        title={formatMessage({ id: 'common.work-email' })}
        content={renderMap.contact_email[renderMode]({
          value: values.contact_email,
          hint: formatMessage({ id: 'corporate-form.contact-person.email-description' }),
        })}
      />
      <Row
        htmlFor="contact_phone"
        title={formatMessage({ id: 'common.phone-number' })}
        content={renderMap.contact_phone[renderMode]({ value: values.contact_phone })}
      />
      {error && (
        <ApiErrorContainer>
          <Alert variant="error" size="medium" title={processError(error).message}>
            {processError(error).data}
          </Alert>
        </ApiErrorContainer>
      )}
      <SaveCancelButtons
        renderMode={renderMode}
        onSaveClick={onSaveClick}
        onCancelClick={onCancelClick}
        isSubmitting={isSubmitting}
        dirty={dirty}
      />
    </FormSection>
  );
};
