import { ConfirmationModal } from 'components';
import { LocalizedMessage } from 'i18n';
import { useDeletePolicy } from 'modules/policies';
import { Policy } from 'modules/policies/types';

interface Props {
  show: boolean;
  policyId?: string;
  policy?: Policy;
  onClose: () => void;
}

export const DeletePolicyModal = ({ show, policyId, policy, onClose }: Props) => {
  const deletePolicy = useDeletePolicy();

  return (
    <ConfirmationModal
      title={<LocalizedMessage messageKey="policies.delete-policy-title" />}
      content={
        policy && (
          <LocalizedMessage
            messageKey="policies.delete-policy-confirmation-question"
            values={{ policyName: policy.name }}
          />
        )
      }
      onClose={onClose}
      show={show}
      confirmationButtonMessage={<LocalizedMessage messageKey="common.delete" />}
      confirmationButtonVariant="lightRed"
      onSuccess={() =>
        setTimeout(() => {
          onClose();
        }, 500)
      }
      onConfirm={async () => {
        if (!policyId) {
          throw 'Policy id is missing';
        }
        await deletePolicy(policyId);
      }}
    />
  );
};
