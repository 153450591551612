import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQuery } from 'modules/react-query';

import { fetchCorporatePurchaseStatistics } from './api';

export const useCorporatePurchaseStatistics = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) => {
  const { data: corporate } = useCorporate();
  const queryFn = useCallback(() => {
    if (!corporate) {
      return Promise.reject();
    }
    return fetchCorporatePurchaseStatistics(corporate.id, startDate, endDate);
  }, [corporate, endDate, startDate]);
  return useQuery({
    queryKey: ['purchase-statistics', corporate?.id],
    queryFn,
    enabled: !!corporate,
  });
};
