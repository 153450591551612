import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { colors, elevationClassName, typographyCss } from '@creditornot/cb-ingredients';
import { Button, TextButtonLink } from '@creditornot/cb-button';
import { MouseEventHandler } from 'react';

import { ResponsiveContainer } from 'components';
import { breakpoints } from 'modules/media';
import { LocalizedMessage } from 'i18n';

export type CookieConsentsModalProps = {
  className?: string;
  onAcceptDefaultConsentsClick: MouseEventHandler<HTMLButtonElement>;
  onManageConsentsClick: MouseEventHandler<HTMLButtonElement>;
  show: boolean;
  privacyPolicyUrl: string | undefined;
};

type CookieConsentsBannerProps = {
  className?: string;
  onAcceptDefaultConsentsClick: MouseEventHandler<HTMLButtonElement>;
  onManageConsentsDialogClick: MouseEventHandler<HTMLButtonElement>;
  privacyPolicyUrl: string | undefined;
};

const Root = styled(motion.div)`
  z-index: 100001;
  position: fixed;
  inset: 0;
`;

const Background = styled(motion.div)`
  background-color: ${colors.pepper48};
  position: fixed;
  inset: 0;
`;

const BannerContainer = styled(ResponsiveContainer)`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  max-width: 1200px;
  padding-bottom: 24px;
`;

const CookieConsentsBannerRoot = styled.div`
  margin-top: auto;
  border-radius: 8px;
  min-height: 118px;
  padding: 32px;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.medium}px) {
    display: block;
    padding: 16px;
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
  }
`;

const Description = styled.p`
  ${typographyCss.Body3()}
  margin: 0;
  margin-inline-end: 32px;

  @media (max-width: ${breakpoints.medium}px) {
    margin-inline-end: 0;
    margin-bottom: 16px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  button + button {
    margin-inline-start: 16px;
  }

  @media (max-width: ${breakpoints.medium}px) {
    button {
      flex: 1;
    }
  }
`;

const CookieConsentsBanner = ({
  className,
  onAcceptDefaultConsentsClick,
  onManageConsentsDialogClick,
  privacyPolicyUrl,
}: CookieConsentsBannerProps) => {
  return (
    <CookieConsentsBannerRoot className={`${className} ${elevationClassName.medium}`}>
      <Description>
        <LocalizedMessage
          messageKey="gdpr-consents.banner.text"
          values={{
            a: (text: string) =>
              privacyPolicyUrl ? (
                <TextButtonLink href={privacyPolicyUrl} target="_blank" size="inherit">
                  {text}
                </TextButtonLink>
              ) : null,
          }}
        />
      </Description>

      <ButtonsContainer>
        <Button
          variant="lightBlue"
          size="small"
          onClick={onManageConsentsDialogClick}
          data-test-id="CookieConsentsBanner.ManageButton"
        >
          <LocalizedMessage messageKey="gdpr-consents.banner.manage-cookies-button" />
        </Button>

        <Button
          variant="blue"
          size="small"
          onClick={onAcceptDefaultConsentsClick}
          data-test-id="CookieConsentsBanner.AcceptButton"
        >
          <LocalizedMessage messageKey="gdpr-consents.banner.accept-button" />
        </Button>
      </ButtonsContainer>
    </CookieConsentsBannerRoot>
  );
};

export const CookieConsentsModal = ({
  className,
  show,
  onAcceptDefaultConsentsClick,
  onManageConsentsClick,
  privacyPolicyUrl,
}: CookieConsentsModalProps) => {
  return (
    // TODO - refactor to ModalV2
    <AnimatePresence>
      {show && (
        <>
          <Background key="bg" initial="initial" animate="animate" exit="exit" tabIndex={-1} />
          <Root
            className={className}
            initial={{ opacity: 0 }}
            animate={{ y: '0%', opacity: 1 }}
            exit={{ y: '100%' }}
          >
            <BannerContainer aria-live="assertive">
              <CookieConsentsBanner
                privacyPolicyUrl={privacyPolicyUrl}
                onAcceptDefaultConsentsClick={onAcceptDefaultConsentsClick}
                onManageConsentsDialogClick={onManageConsentsClick}
              />
            </BannerContainer>
          </Root>
        </>
      )}
    </AnimatePresence>
  );
};
