import { PermissionOption } from './types';
import parseAuthPermissions from './parseAuthPermissions';

const hasPermissions = (options: PermissionOption[], permissions: string[]) => {
  const userPermissions = parseAuthPermissions(permissions);

  if (!options.every((opt) => !!userPermissions[opt.resource])) {
    return false;
  }

  return options.every((permissionOption: PermissionOption) => {
    return permissionOption.operations.every((operation) =>
      userPermissions[permissionOption.resource].some(
        (permission) =>
          permission.operations.includes(operation) &&
          (!permissionOption.regions?.length ||
            permission.regions.includes('WOLT') ||
            permissionOption.regions.every((region) => permission.regions.includes(region))),
      ),
    );
  });
};

export default hasPermissions;
