import { corporateServiceApiAgent } from 'apiAgents';
import { logValidationError } from 'validation';

import { ServiceAgreement } from './types';

export const fetchCurrentServiceAgreement = async (corporateId: string) => {
  const { data } = await corporateServiceApiAgent.get<ServiceAgreement>(
    `/portal-api/v1/corporates/${corporateId}/service-agreements/effective`,
  );
  logValidationError('fetchCurrentServiceAgreement', ServiceAgreement, data);
  return data;
};

export const agreeServiceAgreement = async (corporateId: string, serviceAgreementId: string) => {
  const { data } = await corporateServiceApiAgent.post<void>(
    `/portal-api/v1/corporates/${corporateId}/service-agreements/${serviceAgreementId}/terms-and-conditions/accept`,
  );
  return data;
};

/**
 * Preview is downloaded before the user agrees to the service agreement.
 * @param corporateId string
 * @param serviceAgreementId string
 * @param language string
 * @returns
 */
export const downloadServiceAgreementPreview = async (
  corporateId: string,
  serviceAgreementId: string,
  language: string,
) => {
  const { data } = await corporateServiceApiAgent.get<BlobPart>(
    `/portal-api/v1/corporates/${corporateId}/service-agreements/${serviceAgreementId}/terms-and-conditions/preview`,
    {
      params: {
        language,
      },
      responseType: 'blob',
    },
  );
  return data;
};

export const downloadServiceAgreementFinal = async (
  corporateId: string,
  serviceAgreementId: string,
) => {
  const { data } = await corporateServiceApiAgent.get<BlobPart>(
    `/portal-api/v1/corporates/${corporateId}/service-agreements/${serviceAgreementId}/terms-and-conditions/final`,
    {
      responseType: 'blob',
    },
  );
  return data;
};
