import { useMemo } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Checkmark } from '@creditornot/cb-icons';
import { InputContainer } from '@creditornot/cb-form';
import { Button } from '@creditornot/cb-button';

import { ApiErrorNotification, FormFooter } from 'components';
import { LocalizedMessage, useI18n } from 'i18n';
import { Event } from 'modules/events/types';
import { useEditEvent } from 'modules/events';

import { FormikEventNameInput } from '../EventFormFields';

interface Props {
  onSuccess: (event: Event) => void;
  onClose: () => void;
  event: Event;
}

const Container = styled.div`
  padding: 32px 24px;
`;

const ErrorContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const EditEventNameForm = ({ onSuccess, onClose, event }: Props) => {
  const editEvent = useEditEvent();
  const { getLocalizedMessage } = useI18n();
  const initialValues = useMemo(() => {
    return {
      name: event.name,
    };
  }, [event.name]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      onSubmit={async (values, actions) => {
        try {
          const newEvent = await editEvent(event.id, {
            name: values.name,
          });

          actions.setSubmitting(false);
          actions.setStatus({ submitSucceeded: true });
          setTimeout(() => {
            onSuccess(newEvent);
          }, 500);
        } catch (error) {
          actions.setSubmitting(false);
          actions.setStatus({ error });
        }
      }}
    >
      {({ isSubmitting, status, submitForm }) => (
        <>
          <Container>
            <InputContainer label={getLocalizedMessage('events.create-event-form.event-name')}>
              <FormikEventNameInput alwaysShowError />
            </InputContainer>

            {status?.error && (
              <ErrorContainer>
                <ApiErrorNotification error={status.error} />
              </ErrorContainer>
            )}
          </Container>

          <FormFooter
            rightContent={
              <>
                <Button
                  size="small"
                  variant="lightBlue"
                  onClick={onClose}
                  disabled={isSubmitting || status?.submitSucceeded}
                >
                  <LocalizedMessage messageKey="common.close" />
                </Button>
                <Button
                  size="small"
                  variant="blue"
                  onClick={submitForm}
                  loading={isSubmitting}
                  disabled={isSubmitting || status?.submitSucceeded}
                  icon={status?.submitSucceeded && <Checkmark />}
                >
                  <LocalizedMessage messageKey="common.save" />
                </Button>
              </>
            }
          />
        </>
      )}
    </Formik>
  );
};
