import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { UseQueryOptions, useQuery } from 'modules/react-query';
import { PaginatedData } from 'types/PaginatedData';

import { fetchInvoices } from './api';
import { Invoice } from './types';

export type FetchInvoicesData = {
  page?: number;
  page_size?: number;
  pagination?: boolean;
};

export const useFetchInvoices = <T = PaginatedData<Invoice>>(
  data: FetchInvoicesData,
  config?: UseQueryOptions<PaginatedData<Invoice>, unknown, T>,
) => {
  const { data: corporate } = useCorporate();
  const queryFn = useCallback(() => {
    if (!corporate) {
      return Promise.reject();
    }
    const { page = 1, page_size = 20, pagination } = data;
    return fetchInvoices(corporate.id, {
      page,
      page_size,
      pagination,
    });
  }, [corporate, data]);
  return useQuery({
    queryKey: ['invoices', corporate?.id, data],
    queryFn,
    enabled: !!corporate,
    ...config,
  });
};
