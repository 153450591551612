import { Route, Switch } from 'react-router-dom';

import { CreatePolicyView } from './CreatePolicyView';
import { EditPolicyView } from './EditPolicyView';
import PoliciesView from './PoliciesView';

const RootRoutes = () => (
  <Switch>
    <Route exact path="/:corporateId/policies" component={PoliciesView} />
    <Route exact path="/:corporateId/policies/new" component={CreatePolicyView} />
    <Route exact path="/:corporateId/policies/delete/:policyId" component={PoliciesView} />
    <Route exact path="/:corporateId/policies/:policyId" component={EditPolicyView} />
  </Switch>
);

export default RootRoutes;
