import { useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { useFetchEvents, useOpenEventTutorialVideo } from 'modules/events';
import { Event } from 'modules/events/types';
import { useMedia } from 'modules/media';
import { useCorporate } from 'modules/corporates';
import { resources } from 'modules/resources';

import { useEventsQueryParams } from './hooks';
import { EventsViewComponent } from './components/EventsViewComponent';

export const EventsView: React.FC<
  RouteComponentProps<{
    corporateId: string;
    action: 'create-event' | 'cancel-event';
  }>
> = ({ match }) => {
  const { data: corporate } = useCorporate();
  const openEventTutorialVideo = useOpenEventTutorialVideo();
  const history = useHistory();
  const isMediumView = useMedia('medium');
  const [{ page, page_size, event_statuses = [] }, setQueryParams] = useEventsQueryParams();
  const {
    data: eventsData,
    isFetching: isFetchingEvents,
    error: eventsError,
  } = useFetchEvents({
    page,
    page_size,
    statuses: event_statuses,
  });
  const handleCreateEventClick = useCallback(() => {
    history.push({
      pathname: `${history.location.pathname}/create-event`,
      search: history.location.search,
    });
  }, [history]);
  const handleCloseClick = useCallback(() => {
    history.push({
      pathname: `/${match.params.corporateId}/events`,
      search: history.location.search,
    });
  }, [history, match.params.corporateId]);
  const handleEventClick = useCallback(
    (eventId: string) => {
      history.push(`/${match.params.corporateId}/events/info/${eventId}`);
    },
    [history, match.params.corporateId],
  );
  const handleSuccess = useCallback(
    (event: Event) => {
      history.push(`/${match.params.corporateId}/events/info/${event.id}`);
    },
    [history, match.params.corporateId],
  );
  const handleSuccessAndOpenInviteEventUsers = useCallback(
    (event: Event) => {
      history.push(`/${match.params.corporateId}/events/info/${event.id}/invite-users`);
    },
    [history, match.params.corporateId],
  );
  const handleLearnMoreClick = useCallback(() => {
    window.open(resources.guides.events);
  }, []);
  const handleStatusesChange = useCallback(
    (statuses: string[]) => {
      setQueryParams({ event_statuses: statuses });
    },
    [setQueryParams],
  );

  const isFiltering = event_statuses.length > 0;
  const isEmpty = eventsData?.total_items_count === 0 && !isFiltering;

  return (
    <EventsViewComponent
      corporate={corporate}
      action={match.params.action}
      isMediumView={isMediumView}
      openEventTutorialVideo={openEventTutorialVideo}
      isFetchingEvents={isFetchingEvents}
      eventsError={eventsError}
      eventsData={eventsData}
      eventStatuses={event_statuses}
      isEmpty={isEmpty}
      onCreateEventClick={handleCreateEventClick}
      onCloseClick={handleCloseClick}
      onEventClick={handleEventClick}
      onSuccess={handleSuccess}
      onSuccessAndOpenInviteEventUsers={handleSuccessAndOpenInviteEventUsers}
      onLearnMoreClick={handleLearnMoreClick}
      onStatusesChange={handleStatusesChange}
    />
  );
};
