import { useCallback, useState } from 'react';
import { Button } from '@creditornot/cb-button';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';

import { ModalV2 } from 'components';
import { LocalizedMessage } from 'i18n';
import { getLocalStorageItem, setLocalStorageItem } from 'modules/hooks';
import { LottieComponent } from 'modules/lotties';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 48px;
  width: max-content;
`;

const Title = styled.h5`
  ${typographyCss.Heading5()}
  margin-top: 32px;
  max-width: 380px;
`;

const Content = styled.span`
  ${typographyCss.Body3()}
  margin-top: 16px;
  max-width: 380px;
`;

const StyledButton = styled(Button)`
  margin-top: 32px;
`;

const GroupReady = styled(LottieComponent).attrs({
  animation: 'groupReady',
  size: 'wide',
})`
  width: 400px;
  min-height: 250px;
`;

export const EventWelcomeModal = () => {
  const isEventsWelcomeModalVisited = getLocalStorageItem('isEventsWelcomeModalVisited');
  const [show, setShow] = useState(!isEventsWelcomeModalVisited);
  const handleCloseClick = useCallback(() => {
    setLocalStorageItem('isEventsWelcomeModalVisited', true);
    setShow(false);
  }, []);
  const handleAcceptClick = useCallback(() => {
    setLocalStorageItem('isEventsWelcomeModalVisited', true);
    setShow(false);
  }, []);

  return (
    <ModalV2 onDismiss={handleCloseClick} open={show}>
      <Root>
        <GroupReady />
        <Title>
          <LocalizedMessage messageKey="events.welcome-modal.title" />
        </Title>
        <Content>
          <LocalizedMessage messageKey="events.welcome-modal.subtitle" />
        </Content>
        <StyledButton onClick={handleAcceptClick} size="small" variant="blue">
          <LocalizedMessage messageKey="events.welcome-modal.button" />
        </StyledButton>
      </Root>
    </ModalV2>
  );
};
