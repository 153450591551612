import { Button } from '@creditornot/cb-button';
import { FormattedMessage } from 'react-intl';

import { useSubscriptionModalsController } from '../hooks/useSubscriptionModalsController';

interface Props {
  className?: string;
  disabled?: boolean;
}

export const AddUsersButton = ({ className, disabled }: Props) => {
  const { openInviteModal } = useSubscriptionModalsController();

  return (
    <Button
      className={className}
      variant="blue"
      size="small"
      disabled={disabled}
      onClick={openInviteModal}
    >
      <FormattedMessage id="wolt-plus.add-users-button" />
    </Button>
  );
};
