import { MoreHorizontal } from '@creditornot/cb-icons';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconButton } from '@creditornot/cb-button';
import { typographyCss } from '@creditornot/cb-ingredients';

import { DataList, EmptyState, UserStatus } from 'components';
import { SubscriptionInvite } from 'modules/subscription/types';
import DropdownActionButton from 'components/DropdownButton';
import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';

interface Props {
  data?: SubscriptionInvite[] | null;
  loading: boolean;
  error: any;
  dataItemProps: {
    onOpenDeleteInviteModal: (id: string) => void;
    onOpenResendInviteModal: (id: string) => void;
  };
  selectedInvites: string[];
  onInviteSelectionClick: (selectedInvites: string[]) => void;
}

interface DataItemProps {
  invite: SubscriptionInvite;
  selectedInvitesLength: number;
  onOpenDeleteInviteModal: (id: string) => void;
  onOpenResendInviteModal: (id: string) => void;
}

const DataItemRoot = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Row = styled.div`
  ${typographyCss.Body3()};
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 8px;
  align-items: center;
  padding-inline-end: 36px;
  position: relative;
`;

const StyledDropdownActionButton = styled(DropdownActionButton)`
  position: absolute;
  ${cssDirectionalValue({ ltr: 'right: 0;', rtl: 'left: 0;' })}
`;

const DataItem = ({
  invite,
  selectedInvitesLength,
  onOpenDeleteInviteModal,
  onOpenResendInviteModal,
}: DataItemProps) => {
  return (
    <DataItemRoot>
      <Row>
        {invite.email}
        <UserStatus status={invite.status} />

        {!selectedInvitesLength && (
          <StyledDropdownActionButton
            showCaret={false}
            placement="bottom-end"
            actions={[
              {
                label: <FormattedMessage id={'common.resend'} />,
                onClick: () => onOpenResendInviteModal(invite.id),
              },
              {
                label: <FormattedMessage id={'common.delete'} />,
                onClick: () => onOpenDeleteInviteModal(invite.id),
              },
            ]}
          >
            <IconButton background="animated" size="small" variant="black">
              <MoreHorizontal />
            </IconButton>
          </StyledDropdownActionButton>
        )}
      </Row>
    </DataItemRoot>
  );
};

export const SubscriptionInviteDataList = ({
  data,
  loading,
  error,
  dataItemProps,
  selectedInvites,
  onInviteSelectionClick,
}: Props) => {
  const { formatMessage } = useIntl();
  return (
    <DataList
      data={data}
      loading={loading}
      error={error}
      getDataItemId={(dataItem) => dataItem.id}
      renderDataItemProps={dataItemProps}
      selectedDataItems={selectedInvites}
      onDataItemSelectionClick={(_, selectedInvites) => onInviteSelectionClick(selectedInvites)}
      noData={<EmptyState icon="user">{formatMessage({ id: 'wolt-plus.no-invites' })}</EmptyState>}
      renderDataItem={(invite, { onOpenDeleteInviteModal, onOpenResendInviteModal }) => (
        <DataItem
          invite={invite}
          selectedInvitesLength={selectedInvites.length}
          onOpenDeleteInviteModal={onOpenDeleteInviteModal}
          onOpenResendInviteModal={onOpenResendInviteModal}
        />
      )}
    />
  );
};
