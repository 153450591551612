import { useCallback } from 'react';

import { ConfirmationModal } from 'components';
import { LocalizedMessage } from 'i18n';
import { Corporate } from 'modules/corporates/types';
import { useEditCorporateRestricted } from 'modules/corporates';

type ActivateNewCorporateAccountModalProps = {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
  corporate: Corporate;
};

export const ActivateNewCorporateAccountModal = ({
  show,
  onClose,
  onSuccess,
  corporate,
}: ActivateNewCorporateAccountModalProps) => {
  const editCorporateRestricted = useEditCorporateRestricted();
  const handleConfirm = useCallback(
    async () => editCorporateRestricted({ status: 'active' }),
    [editCorporateRestricted],
  );

  return (
    <ConfirmationModal
      title={<LocalizedMessage messageKey="corporate-form.activate-new-corporate-account.title" />}
      content={
        <LocalizedMessage
          messageKey="corporate-form.activate-new-corporate-account.text"
          values={{
            corporate: <strong>{corporate.name}</strong>,
          }}
        />
      }
      onClose={onClose}
      show={show}
      cancelButtonMesssge={<LocalizedMessage messageKey="common.cancel" />}
      confirmationButtonMessage={
        <LocalizedMessage messageKey="corporate-form.activate-new-corporate-account.confirm-button" />
      }
      confirmationButtonVariant="blue"
      onSuccess={() => setTimeout(onSuccess, 400)}
      onConfirm={handleConfirm}
    />
  );
};
