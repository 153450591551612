import { useEffect, useState, useMemo } from 'react';

import breakpoints from './breakpoints.json';

const useMedia = (size: 'small' | 'medium' | 'large') => {
  const maxWidth = breakpoints[size];
  const MediaQueryList = useMemo(() => {
    return window.matchMedia(`(max-width: ${maxWidth}px)`);
  }, [maxWidth]);

  const [matches, setMatches] = useState(MediaQueryList.matches);

  useEffect(() => {
    const listener = (e: MediaQueryListEvent) => {
      if (e.matches) {
        setMatches(true);
      } else {
        setMatches(false);
      }
    };

    // use @deprecated for safari compatibility
    MediaQueryList.addListener(listener);

    return () => {
      MediaQueryList.removeListener(listener);
    };
  }, [MediaQueryList, size]);

  return matches;
};

export default useMedia;
