import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQueryClient } from 'modules/react-query';

import { resendSubscriptionInvites } from './api';

type Props = {
  subscriptionId: string;
  inviteIds: Array<string>;
  invalidateDelay?: number;
};

export const useResendSubscriptionInvites = () => {
  const queryClient = useQueryClient();
  const { data: corporate } = useCorporate();

  return useCallback(
    async ({ subscriptionId, inviteIds, invalidateDelay }: Props) => {
      if (!corporate?.id) {
        return Promise.resolve();
      }

      await resendSubscriptionInvites(corporate.id, subscriptionId, inviteIds);

      if (invalidateDelay) {
        setTimeout(() => {
          queryClient.invalidateQueries();
        }, invalidateDelay);
      } else {
        queryClient.invalidateQueries();
      }
    },
    [corporate?.id, queryClient],
  );
};
