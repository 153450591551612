import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';

export default styled.a`
  font-size: 14px;
  text-decoration: none;
  color: ${color.bgFillBrand};

  :visited {
    color: ${color.bgFillBrand};
  }

  :hover {
    color: ${color.bgFillBrandHovered};
  }

  :active {
    color: ${color.bgFillBrandSelected};
  }
`;
