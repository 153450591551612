import { color } from '@creditornot/cb-ingredients/design-tokens';
import { TextButton } from '@creditornot/cb-button';
import { WoltLogo, typographyCss } from '@creditornot/cb-ingredients';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { replaceStringWithJSX } from '../utils';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChatBubble = styled.div`
  width: 100%;
  margin-top: 8px;
  padding: 10px;
  background-color: ${color.bgSurfaceSecondary};
  border-radius: 8px;
`;

const StyledTextButton = styled(TextButton)`
  font-weight: 400;
`;

const Time = styled.div`
  ${typographyCss.Tiny()}
  color: ${color.textSubdued};
`;

const Text = styled.span`
  ${typographyCss.Body3()}
`;

const SubText = styled.small`
  ${typographyCss.Small()}
`;

const WoltLogoWithTime = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
`;

const WoltLogoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 40px;
  margin-inline-start: 8px;
  padding: 7px;
  border: 1px solid ${color.border};
  border-radius: 100px;
`;

export const SmsMessagePreview: React.FC<{
  className?: string;
  message: string;
  style?: CSSProperties;
}> = ({ message, ...props }) => {
  const { formatMessage, formatDate } = useIntl();

  return (
    <Root {...props}>
      <Text>{formatMessage({ id: 'deliveries.settings-form.sms-notification-preview' })} </Text>
      <ChatBubble>
        <SubText>
          {replaceStringWithJSX(
            message,
            'TRACKING_LINK',
            <StyledTextButton size="inherit" variant="blue">
              wolt.com/tracking-very-long-long-link
            </StyledTextButton>,
          )}
        </SubText>
      </ChatBubble>

      <WoltLogoWithTime>
        <Time>
          {formatDate(new Date(), {
            hour: 'numeric',
            minute: 'numeric',
          })}
        </Time>

        <WoltLogoContainer>
          <WoltLogo fill="black" width="24px" height="9px" />
        </WoltLogoContainer>
      </WoltLogoWithTime>
    </Root>
  );
};
