import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchGroup } from './api';
import { Group } from './types';

export const useFetchGroup = <T = Group>(
  groupId: string | null,
  config?: UseQueryOptions<Group, unknown, T>,
) => {
  const { data: corporate } = useCorporate();
  const queryFn = useCallback(() => {
    if (!corporate || !groupId) {
      return Promise.reject();
    }
    return fetchGroup(corporate.id, groupId);
  }, [corporate, groupId]);
  return useQuery({
    queryKey: ['group', corporate?.id, groupId],
    queryFn,
    enabled: !!(corporate && groupId),
    ...config,
  });
};
