import { useCallback } from 'react';
import { SegmentedControl } from '@creditornot/cb-segmented-control';
import { useIntl } from 'react-intl';

import { useSubscriptionUsersQueryParams } from 'views/subscription/hooks/useSubscriptionUsersQueryParams';

interface Props {
  className?: string;
  invitesCount?: number;
  usersCount?: number;
}

export const SubscriptionSegmentedControl: React.FC<Props> = ({
  className,
  invitesCount,
  usersCount,
}) => {
  const { formatMessage } = useIntl();
  const [{ showInvites }, setQueryParams] = useSubscriptionUsersQueryParams();

  const key = `control-${usersCount}-${invitesCount}`;

  const handleChange = useCallback(
    (value: 'users' | 'invites') => {
      setQueryParams({
        showInvites: value === 'invites' ? true : undefined,
        users: undefined,
        invites: undefined,
        page: 1,
      });
    },
    [setQueryParams],
  );

  return (
    <SegmentedControl
      key={key}
      className={className}
      options={[
        {
          value: 'users',
          label: `${formatMessage({ id: 'wolt-plus.segment.users' })}${
            usersCount ? `(${usersCount})` : ''
          }`,
        },
        {
          value: 'invites',
          label: `${formatMessage({ id: 'wolt-plus.segment.invites' })}${
            invitesCount ? `(${invitesCount})` : ''
          }`,
        },
      ]}
      value={showInvites ? 'invites' : 'users'}
      onChange={handleChange}
    />
  );
};
