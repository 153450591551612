import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { WoltLogo } from '@creditornot/cb-ingredients';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { ResponsiveContainer } from 'components';
import { authService } from 'modules/auth/authenticator';

import { UserDropdown } from './UserDropdown';

const Root = styled(ResponsiveContainer)`
  display: flex;
  justify-content: center;
  height: 70px;
  border-bottom: 1px solid ${color.border};
`;

const StyledLink = styled(Link)`
  display: flex;

  svg {
    fill: ${color.text};
  }
`;

const ContentContainer = styled.nav`
  display: flex;
  max-width: 1200px;
  width: 100%;
  align-items: center;
`;

const StyledUserDropdown = styled(UserDropdown)`
  margin-inline-start: auto;
`;

export const TopNavigationBar = () => {
  const { user } = useAuthState();

  return (
    <Root>
      <ContentContainer>
        <StyledLink to="/">
          <WoltLogo width="58px" height="58px" />
        </StyledLink>

        {user && (
          <StyledUserDropdown
            user={user}
            onLogoutClick={async () => {
              window.location.replace(authService.getLogoutUri());
            }}
          />
        )}
      </ContentContainer>
    </Root>
  );
};
