import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Trashbin } from '@creditornot/cb-icons';

import { IconButtonWithHelpText } from 'components';

import { useSubscriptionUsersQueryParams } from '../../../hooks/useSubscriptionUsersQueryParams';

interface Props {
  id: string;
  onOpenDeleteUserModal: (id: string) => void;
}

const StyledTrashbin = styled(Trashbin)`
  height: 16px;
  width: 16px;
  fill: ${color.textSubdued} !important;
`;

export const SubscriptionUsersActionButtons = ({ id, onOpenDeleteUserModal }: Props) => {
  const [{ users = [] }] = useSubscriptionUsersQueryParams();
  const { formatMessage } = useIntl();
  const handleOpenDeleteUsersModal = () => onOpenDeleteUserModal(id);
  const isMultipleItemsSelected = users.length > 0;

  if (isMultipleItemsSelected) {
    return null;
  }

  return (
    <IconButtonWithHelpText
      testId={`delete-subscription-user-${id}-button`}
      onClick={handleOpenDeleteUsersModal}
      tooltipText={formatMessage({ id: 'common.delete' })}
      tooltipPlacement="bottom"
      variant="grey"
    >
      <StyledTrashbin />
    </IconButtonWithHelpText>
  );
};
