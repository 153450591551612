import styled from 'styled-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { convertFromAmountToCents } from 'utils';
import { FormRowV2 as Row } from 'components';
import { CustomPricingPreviewV2 } from 'modules/corporates/components';
import { ReactHookInput } from 'components/ReactHookInput';

import { CustomPricingTable } from './CustomPricingTable';
import { B2bConfigFormValues } from '../types';

interface Props {
  currency: string;
}

const StyledRow = styled(Row)`
  margin-bottom: 16px;
`;

export const CustomPricingFormSectionV2 = ({ currency }: Props) => {
  const { formatMessage } = useIntl();

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<B2bConfigFormValues>();

  const basePrice = watch('pricing.customPricing.basePrice');
  const priceIncrements = watch('pricing.customPricing.distanceRanges');
  const basePriceRegister = register('pricing.customPricing.basePrice');

  const basePriceInCents =
    !basePrice || isNaN(basePrice) ? 0 : convertFromAmountToCents(basePrice, currency);
  const priceIncrementsInCents = priceIncrements.map(({ fee, distanceMax, distanceMin }) => {
    return {
      fee: !fee || isNaN(fee) ? 0 : convertFromAmountToCents(fee, currency),
      distance_min: distanceMin,
      distance_max: distanceMax,
    };
  });

  return (
    <>
      <StyledRow
        title={formatMessage({ id: 'corporate-form.delivery-settings.base-price' })}
        content={
          <ReactHookInput
            type="number"
            name="basePrice"
            register={basePriceRegister}
            errorMessage={errors.pricing?.customPricing?.basePrice?.message}
            inputProps={{ suffixText: currency, step: 0.01 }}
          />
        }
      />
      <StyledRow
        title={formatMessage({ id: 'corporate-form.custom-price.title' })}
        content={<CustomPricingTable currency={currency} />}
      />
      <StyledRow
        title={formatMessage({ id: 'corporate-form.custom-price.preview-with-vat' })}
        content={
          <CustomPricingPreviewV2
            priceIncrements={priceIncrementsInCents}
            currency={currency}
            basePrice={basePriceInCents}
          />
        }
      />
    </>
  );
};
