import { FormRow, InputContainer } from '@creditornot/cb-form';
import styled from 'styled-components';

import { FormikInputField } from 'components';
import { createValidator, isRequired } from 'validation';

const validate = createValidator([isRequired]);

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: 24px;
  }
`;

const AddressFormSection = () => {
  return (
    <FormRow label="Address">
      <StyledInputContainer label="Street">
        <FormikInputField validate={validate} required={true} name="address.street" />
      </StyledInputContainer>

      <StyledInputContainer label="City">
        <FormikInputField validate={validate} required={true} name="address.city" />
      </StyledInputContainer>
      <StyledInputContainer label="Post code">
        <FormikInputField validate={validate} required={true} name="address.post_code" />
      </StyledInputContainer>
      <StyledInputContainer label="Country">
        <FormikInputField validate={validate} required={true} name="address.country" />
      </StyledInputContainer>
    </FormRow>
  );
};

export default AddressFormSection;
