import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Spinner } from 'components';

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 45px 0;
  gap: 10px;
`;

const LoggingInMessage = styled(FormattedMessage)`
  color: ${color.textSubdued};
`;

const LoggingInView = () => {
  return (
    <Root>
      <Spinner size="small" />
      <LoggingInMessage id="logging-in.logging-in" />
    </Root>
  );
};

export default LoggingInView;
