import { Route, Switch } from 'react-router-dom';

import { AdminsView } from './AdminsView';

const RootRoutes = () => (
  <Switch>
    <Route exact path="/:corporateId/admins" component={AdminsView} />
    <Route exact path="/:corporateId/admins/:action" component={AdminsView} />
  </Switch>
);

export default RootRoutes;
