import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { typographyCss } from '@creditornot/cb-ingredients';

import { LottieComponent } from 'modules/lotties/LottieComponent';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { verificationView } from 'telemetry/Avo';

import { SignupProgress } from '../components/SignupProgress/SignupProgress';

type Props = {
  renderEditCorporateForm?: () => JSX.Element;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const Title = styled.h1`
  ${typographyCss.Heading1()};
  max-width: 620px;
  margin-bottom: 16px;
  text-align: center;
  font-weight: 600;
`;

const Subtitle = styled.span`
  ${typographyCss.Body2()};
  max-width: 620px;
  text-align: center;
`;

const StyledSignupProgress = styled(SignupProgress)`
  margin-inline: auto;
  margin-bottom: 32px;
`;

const MagnifyingGlasses = styled(LottieComponent).attrs({
  animation: 'magnifyingGlasses',
  size: 'wide',
})`
  max-width: 300px;
`;

export const SignupV2Step3 = ({ renderEditCorporateForm }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Root>
      <ViewEventComponent event={verificationView} />
      <StyledSignupProgress activeIndex={3} />
      <MagnifyingGlasses />
      <Title>{formatMessage({ id: 'signup.step3.title' })}</Title>
      <Subtitle>{formatMessage({ id: 'signup.step3.subtitle' })}</Subtitle>
      {renderEditCorporateForm?.()}
    </Root>
  );
};
