import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { useCorporate } from 'modules/corporates';

import { fetchConsents } from './api';

export const useFetchConsents = () => {
  const { data: corporate } = useCorporate();

  const queryFn = useCallback(() => {
    if (!corporate?.id) {
      return Promise.reject('Corporate is missing');
    }
    return fetchConsents(corporate.id);
  }, [corporate?.id]);

  return useQuery({
    queryKey: ['consents', corporate?.id],
    queryFn,
    enabled: !!corporate?.id,
  });
};
