import { useField } from 'formik';
import { useIntl } from 'react-intl';

import { RadioInputCardGroup } from 'components';

type Props = {
  className?: string;
};

export const BillingCycleFieldV2: React.FC<Props> = () => {
  const { formatMessage } = useIntl();
  const [{ name, value, onChange, onBlur }] = useField<string>('billing_twice_a_month');

  return (
    <RadioInputCardGroup
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      stretched
      direction="row"
      options={[
        {
          label: formatMessage({ id: 'corporate-form.billing_cycle-monthly' }),
          value: 'false',
          explanation: formatMessage({ id: 'corporate-form.billing_cycle-monthly-explanation' }),
        },
        {
          label: formatMessage({ id: 'corporate-form.billing_cycle-twice_a_month' }),
          value: 'true',
          explanation: formatMessage({
            id: 'corporate-form.billing_cycle-twice_a_month-explanation',
          }),
        },
      ]}
    />
  );
};
