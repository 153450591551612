import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { Button } from '@creditornot/cb-button';
import { FormattedMessage } from 'react-intl';
import { typographyCss } from '@creditornot/cb-ingredients';
import { useMemo } from 'react';

import { breakpoints } from 'modules/media';
import { Header } from 'components';

import { useSubscriptionUsersQueryParams } from '../../hooks/useSubscriptionUsersQueryParams';
import { SubscriptionSegmentedControl } from './SubscriptionSegmentedControl';

type Props = {
  onResendInvitesClick: () => void;
  onDeleteUsersClick: () => void;
  onDeleteInvitesClick: () => void;
  invitesCount?: number;
  usersCount?: number;
};

const RightContentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Text = styled.span`
  ${typographyCss.Body3()}
  padding: 0 8px;
  white-space: nowrap;
  color: ${color.textSubdued};
`;

const DeleteUsersButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const RightItemsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const DeleteUsersDisclaimer = styled.div`
  ${typographyCss.Body3()};
  font-style: italic;
  text-align: end;
  max-width: 300px;

  @media (max-width: ${breakpoints.large}px) {
    max-width: 240px;
  }

  @media (max-width: ${breakpoints.small}px) {
    max-width: 220px;
  }
`;

export const SubscriptionListHeader = ({
  onResendInvitesClick,
  onDeleteInvitesClick,
  onDeleteUsersClick,
  invitesCount,
  usersCount,
}: Props) => {
  const [{ users: usersQueryParam, invites: invitesQueryParam }] =
    useSubscriptionUsersQueryParams();

  const leftItems = (
    <SubscriptionSegmentedControl invitesCount={invitesCount} usersCount={usersCount} />
  );

  const rightItemsContent = useMemo(() => {
    if (usersQueryParam?.length) {
      return (
        <DeleteUsersButtonContainer>
          <DeleteUsersDisclaimer>
            <FormattedMessage id="wolt-plus.delete-users-disclaimer" />
          </DeleteUsersDisclaimer>
          <Button variant="lightRed" size="small" onClick={onDeleteUsersClick}>
            <FormattedMessage id="wolt-plus.delete-users-confirm-button" />
          </Button>
        </DeleteUsersButtonContainer>
      );
    }

    if (invitesQueryParam?.length) {
      return (
        <RightItemsContainer>
          <Text>
            <FormattedMessage
              id="common.selected_number"
              values={{ number: invitesQueryParam?.length }}
            />
          </Text>
          <Button variant="lightBlue" size="small" onClick={onResendInvitesClick}>
            <FormattedMessage id="common.resend" />
          </Button>
          <Button variant="lightRed" size="small" onClick={onDeleteInvitesClick}>
            <FormattedMessage id="common.delete" />
          </Button>
        </RightItemsContainer>
      );
    }

    return null;
  }, [
    invitesQueryParam,
    onDeleteInvitesClick,
    onDeleteUsersClick,
    onResendInvitesClick,
    usersQueryParam,
  ]);

  const rightItems = rightItemsContent ? (
    <RightContentContainer>{rightItemsContent}</RightContentContainer>
  ) : null;

  return <Header borderBottom leftItems={[leftItems]} rightItems={[rightItems]} />;
};
