import { Alert } from '@creditornot/cb-alert';
import { useAuthState } from '@creditornot/wolt-auth/react';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { TextButton } from '@creditornot/cb-button';
import styled from 'styled-components';

import { Corporate } from 'modules/corporates/types';
import { useAgreeServiceAgreement } from 'modules/signupv2/useAgreeServiceAgreement';
import { useFetchServiceAgreement } from 'modules/signupv2/useFetchServiceAgreement';
import { useDownloadServiceAgreement } from 'modules/signupv2/useDownloadServiceAgreement';
import { downloadFile, processError } from 'utils';
import { ResponsiveContainer } from 'components';
import { getCountryServiceAgreementLanguages } from 'modules/configs/countries';
import { useEditCorporate } from 'modules/corporates';
import { useIsUserCorporateManager, useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { APIFailure, processErrorBlob } from 'utils/processError';

import { SignupV2Step2 } from './SignupV2Step2';

type Props = {
  corporate: Corporate;
  isFetchingCorporate: boolean;
};

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const SignupV2Step2Container = ({ corporate, isFetchingCorporate }: Props) => {
  const { formatMessage } = useIntl();
  const { user } = useAuthState();
  const { isCorporateManager, isFetchingCorporateRoles } = useIsUserCorporateManager();
  const languages = getCountryServiceAgreementLanguages(corporate.country);
  const { isWoltEmployee } = useIsUserWoltEmployee();

  const [goingBack, setGoingBack] = useState(false);
  const [responseError, setResponseError] = useState<undefined | APIFailure>();

  const agreeServiceAgreement = useAgreeServiceAgreement();
  const downloadServiceAgreement = useDownloadServiceAgreement();
  const {
    data: serviceAgreementResponse,
    isLoading: isLoadingServiceAgreement,
    error: serviceAgreementError,
  } = useFetchServiceAgreement();
  const editCorporate = useEditCorporate();

  const handleBackClick = useCallback(async () => {
    setGoingBack(true);
    try {
      await editCorporate({ status: 'details_pending' });
    } catch (e) {
      const error = await processErrorBlob(e);

      if (error.message === '') {
        error.message = formatMessage({ id: 'common.exception.header' });
        error.data = formatMessage({ id: 'common.exception.description' });
      }

      setResponseError(error);
    } finally {
      setGoingBack(false);
    }
  }, [editCorporate, formatMessage]);

  if (serviceAgreementError) {
    return (
      <StyledResponsiveContainer>
        <Alert title={processError(serviceAgreementError).message} variant="error">
          {processError(serviceAgreementError).data}
        </Alert>
        {isWoltEmployee && (
          <TextButton size="medium" onClick={handleBackClick} disabled={goingBack}>
            {formatMessage({ id: 'signup.step2.back-button' })}
          </TextButton>
        )}
      </StyledResponsiveContainer>
    );
  }

  if (isLoadingServiceAgreement || !serviceAgreementResponse || !user) {
    return null;
  }

  const hasBeenDownloaded =
    serviceAgreementResponse?.documents.find((doc) => doc.document_type === 'terms_and_conditions')
      ?.last_accessed_at !== null;

  return (
    <SignupV2Step2
      user={user}
      companyName={corporate.name}
      onDownloadClick={async (language: string) => {
        const pdfData = await downloadServiceAgreement(serviceAgreementResponse.id, language);
        downloadFile(new Blob([pdfData], { type: 'application/pdf' }), `WoltServiceAgreement.pdf`);
      }}
      goingBack={goingBack}
      onBackClick={handleBackClick}
      hasBeenDownloaded={hasBeenDownloaded}
      languages={languages}
      isFetching={isFetchingCorporate || isFetchingCorporateRoles}
      isCorporateManager={isCorporateManager}
      onSubmit={async () => {
        await agreeServiceAgreement(serviceAgreementResponse.id);
      }}
      responseError={responseError}
      setResponseError={setResponseError}
    />
  );
};
