import { ComponentProps } from 'react';

import { FormikDateInputSelect } from 'components';
import { useI18n } from 'i18n';
import { isRequired } from 'validation';

const currentDate = new Date();

export const FormikEventStartDateInputSelect: React.FC<
  Partial<ComponentProps<typeof FormikDateInputSelect>>
> = ({ datePickerInputProps, ...props }) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <FormikDateInputSelect
      validate={isRequired}
      name="start_date"
      datePickerInputProps={{
        placeholder: getLocalizedMessage('common.date-picker-placeholder'),
        popoverPlacement: 'top-start',
        style: {
          width: '100%',
        },
        // Render on top of modal
        popoverZIndex: 9999,
        disabledDays: {
          before: currentDate,
        },
        ...datePickerInputProps,
      }}
      {...props}
    />
  );
};
