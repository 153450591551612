import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { ArrowLeft, ArrowRight, Pencil, Trashbin } from '@creditornot/cb-icons';
import { typographyCss } from '@creditornot/cb-ingredients';
import { IconButton, TextButton } from '@creditornot/cb-button';

import { LocalizedMessage, useLanguageDirection } from 'i18n';
import { breakpoints } from 'modules/media';

import Divider from './Divider';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Subtitle = styled.span`
  ${typographyCss.Body3()}
  color: ${color.textSubdued};
  margin-top: 8px;
  display: inline-block;
  max-width: 720px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 24px;

  @media (max-width: ${breakpoints.medium}px) {
    padding: 32px 16px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const RightContent = styled.div`
  min-width: max-content;
`;

const HeaderBody = styled.div`
  min-width: 0;
`;

const BottomContent = styled.div`
  min-width: 0;
  margin-top: 8px;
`;

const Heading5 = styled.h5`
  ${typographyCss.Heading5()}
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BackButton = styled(TextButton)`
  margin-bottom: 16px;
`;

const EditButton = styled(IconButton)`
  margin-inline-start: 12px;
`;

const DeleteButton = styled(IconButton)`
  margin-inline-start: 8px;
`;

interface Props {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  rightContent?: React.ReactNode;
  children?: React.ReactNode;
  bottomContent?: React.ReactNode;
  hideHeaderBorder?: boolean;
  className?: string;
  onBackClick?: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  actions?: React.ReactNode;
  headerStyle?: React.CSSProperties;
}

const View = ({
  title,
  rightContent,
  bottomContent,
  subtitle,
  children,
  className,
  onBackClick,
  onEditClick,
  onDeleteClick,
  hideHeaderBorder,
  headerStyle,
  ...props
}: Props) => {
  const direction = useLanguageDirection();
  return (
    <Root className={className} {...props}>
      <Header style={headerStyle}>
        <HeaderBody>
          {onBackClick && (
            <BackButton
              onClick={onBackClick}
              icon={direction === 'ltr' ? <ArrowLeft /> : <ArrowRight />}
              variant="black"
            >
              <LocalizedMessage messageKey="common.back" />
            </BackButton>
          )}

          {title && (
            <>
              <HeadingContainer>
                <Heading5>{title}</Heading5>

                {onEditClick && (
                  <EditButton size="small" onClick={onEditClick} variant="black">
                    <Pencil />
                  </EditButton>
                )}

                {onDeleteClick && (
                  <DeleteButton size="small" onClick={onDeleteClick} variant="black">
                    <Trashbin />
                  </DeleteButton>
                )}
              </HeadingContainer>
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
              {bottomContent && <BottomContent>{bottomContent}</BottomContent>}
            </>
          )}
        </HeaderBody>

        {rightContent && <RightContent>{rightContent}</RightContent>}
      </Header>
      {!hideHeaderBorder && <Divider />}

      <Body>{children}</Body>
    </Root>
  );
};

export default View;
