import * as t from 'io-ts';

export type Event = t.TypeOf<typeof Event>;
export const Event = t.type({
  corporate_id: t.string,
  description: t.string,
  end_time: t.string,
  id: t.string,
  name: t.string,
  participant_budget_amount: t.number,
  participant_count: t.number,
  start_time: t.string,
  status: t.keyof({
    ongoing: null,
    upcoming: null,
    cancelled: null,
    deleted: null,
    finished: null,
  }),
  invite_message: t.union([t.string, t.null]),
});

export type UserEventInvite = t.TypeOf<typeof UserEventInvite>;
export const UserEventInvite = t.type({
  corporate_id: t.string,
  created_at: t.string,
  email: t.string,
  event_id: t.string,
  id: t.string,
  is_corporate_manager: t.boolean,
  is_valid: t.boolean,
  modified_at: t.string,
  used_at: t.string,
  used_by_user_id: t.string,
  valid_until: t.string,
});

export type EventUser = t.TypeOf<typeof EventUser>;
export const EventUser = t.type({
  email: t.string,
  full_name: t.string,
  invite_id: t.string,
  status: t.keyof({
    active: null,
    used: null,
    pending: null,
    expired: null,
    not_used: null,
    ordered: null,
    accepted: null,
  }),
  usage_purchase_count: t.number,
  purchase_total_amount: t.number,
});

export type EventUsage = t.TypeOf<typeof EventUsage>;
export const EventUsage = t.type({
  purchase_count: t.number,
  purchase_total_amount: t.number,
});

export type EventStatistics = t.TypeOf<typeof EventStatistics>;
export const EventStatistics = t.type({
  accepted_invite_count: t.number,
  total_invite_count: t.number,
  used_voucher_count: t.number,
  purchase_count: t.number,
  purchase_total_amount: t.number,
  total_budget_amount: t.number,
});
