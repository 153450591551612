import styled from 'styled-components';
import { Field, FieldProps } from 'formik';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import { MenuActionItem, MenuItemGroup } from '@creditornot/cb-menu';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';

import { getLatLngFromGeocodingResult } from 'utils';
import { useI18n } from 'i18n';
import { SearchInput } from 'components';

const StyledMenuItemGroup = styled(MenuItemGroup)`
  padding: 8px;
  width: 360px;
`;

const StyledPopover = styled(Popover)`
  width: 100%;
`;
interface Props {
  index: number;
}

export const PolicyDeliveryLocationAddressField = ({ index }: Props) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <Field name={`delivery_locations.${index}.address`}>
      {({ field, form }: FieldProps) => {
        return (
          <PlacesAutocomplete
            value={field.value}
            onChange={(address) => {
              form.setFieldValue(field.name, address);
            }}
            onSelect={(address, placeId) => {
              form.setFieldValue(field.name, address);
              geocodeByPlaceId(placeId)
                .then((res) => getLatLngFromGeocodingResult(res[0]))
                .then((latLng) => {
                  form.setFieldValue(`delivery_locations.${index}.coordinates`, latLng);
                });
            }}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
              return (
                <StyledPopover
                  show={!!suggestions?.length}
                  renderArrow={false}
                  placement="bottom-start"
                  contentZIndex={9999}
                  content={
                    <StyledMenuItemGroup>
                      {suggestions.map((suggestion) => (
                        <MenuActionItem
                          {...getSuggestionItemProps(suggestion)}
                          key={suggestion.description}
                          selected={suggestion.active}
                        >
                          {suggestion.description}
                        </MenuActionItem>
                      ))}
                    </StyledMenuItemGroup>
                  }
                >
                  <SearchInput
                    {...getInputProps<{ placeholder: string }>({
                      placeholder: getLocalizedMessage('policies.location-address-placeholder'),
                    })}
                    onClearClick={() => {
                      form.setFieldValue(field.name, '');
                    }}
                    isLoading={loading}
                  />
                </StyledPopover>
              );
            }}
          </PlacesAutocomplete>
        );
      }}
    </Field>
  );
};
