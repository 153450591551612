import { useRef, useEffect, VFC } from 'react';
import { useIntersection } from 'react-use';

type Props = {
  onIntersect: () => void;
  options?: IntersectionObserverInit;
};
export const Waypoint: VFC<Props> = ({
  onIntersect,
  options = {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  },
}) => {
  const intersectionRef = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(intersectionRef, options);

  useEffect(() => {
    if (intersection && intersection.intersectionRatio >= 1) {
      onIntersect();
    }
  }, [intersection, onIntersect]);

  return <div ref={intersectionRef} />;
};
