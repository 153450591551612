import { color } from '@creditornot/cb-ingredients/design-tokens';
import { colors } from '@creditornot/cb-ingredients';
import styled, { css } from 'styled-components';

type RadioInputProps = React.PropsWithoutRef<JSX.IntrinsicElements['input']>;

const Input = styled.input<RadioInputProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
`;

const Root = styled.label<RadioInputProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  width: 16px;
  height: 16px;
`;

const Radio = styled.div<RadioInputProps>`
  align-items: center;
  border-radius: 50%;
  border: 1px solid;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  height: 16px;
  justify-content: center;
  transition: all 100ms ease;
  width: 16px;
  ${({ disabled, checked }) => {
    if (disabled) {
      return css`
        opacity: 0.4;
        cursor: not-allowed;
        border-color: ${color.textDisabled};
      `;
    } else if (checked) {
      return css`
        border-color: ${color.borderSelected};
      `;
    } else {
      return css`
        cursor: pointer;
        border-color: ${color.textDisabled};

        &:hover {
          box-shadow: 0 0 8px 0 ${colors.pepper12};
        }

        &:active {
          box-shadow: 0 0 2px 0 ${colors.pepper12};
        }
      `;
    }
  }}
`;

const RadioMarkedIcon = styled.div<RadioInputProps>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ disabled }) => (disabled ? color.textDisabled : color.bgFillBrand)};
`;

const RadioInput = ({ checked, className, disabled, ...rest }: RadioInputProps) => {
  return (
    <Root disabled={disabled}>
      <Input {...rest} disabled={disabled} type="radio" />
      <Radio className={className} disabled={disabled} checked={checked}>
        {checked && <RadioMarkedIcon disabled={disabled} />}
      </Radio>
    </Root>
  );
};

export default RadioInput;
