import { color } from '@creditornot/cb-ingredients/design-tokens';
import React from 'react';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';

import { breakpoints } from 'modules/media';

type Props = {
  className?: string;
  id?: string;
  title: string;
  children: React.ReactNode;
  topRightItem?: React.ReactNode;
  testId?: string;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  padding: 24px;
  gap: 12px;
  border: 1px solid ${color.border};
  border-radius: 10px;

  @media (max-width: ${breakpoints.small}px) {
    padding: 0 16px 24px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid ${color.border};
  }
`;

const Title = styled.h6`
  ${typographyCss.Heading6()}
`;

const TitleContainer = styled.div<{ margin: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ margin }) => (margin ? '12px' : '0')};

  @media (max-width: ${breakpoints.small}px) {
    margin-bottom: 0;
  }
`;

const TopRightContainer = styled.div`
  display: flex;
  margin-inline-start: auto;
`;

export function FormSection({ title, id, topRightItem, className, children, testId }: Props) {
  return (
    <Root id={id} className={className} data-test-id={testId}>
      <TitleContainer margin={!!children}>
        <Title>{title}</Title>
        {topRightItem && <TopRightContainer>{topRightItem}</TopRightContainer>}
      </TitleContainer>
      {children}
    </Root>
  );
}
