import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled, { css } from 'styled-components';
import { useCallback, useState } from 'react';

import { EmptyState, InfiniteScrollDataList, Spinner } from 'components';
import { LocalizedNumber, LocalizedDate, useI18n } from 'i18n';
import { useCorporate } from 'modules/corporates';
import { fetchInvoices } from 'modules/invoices';
import { Invoice } from 'modules/invoices/types';

import { InvoiceDropdownActions } from './InvoiceDropdownActions';

type InvoicesDataListProps = {
  onDownloadDocumentClick: (
    url: string,
    createdAt: string,
    document: string,
    invoiceId: string,
  ) => void;
  isDownloading: undefined | string;
};

type DataCellProps = {
  onDownloadDocumentClick: (
    url: string,
    createdAt: string,
    document: string,
    invoiceId: string,
  ) => void;
  isDownloading: undefined | string;
};

const DataItem = styled.div`
  width: 100%;
  display: flex;
  font-weight: 700;
  align-items: flex-start;
  color: ${color.text};
`;

const Container = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  flex: 1;
`;

const SpinnerContainer = styled.div`
  margin-top: 8px;
  margin-inline-end: 8px;
`;

const Text = styled.div<{
  isDisabled?: boolean;
}>`
  font-weight: normal;
  ${({ isDisabled }) => {
    if (isDisabled)
      return css`
        color: ${color.textDisabled};
      `;

    return css`
      color: ${color.text};
    `;
  }}
`;

const Title = styled.div<{
  isDisabled?: boolean;
}>`
  font-weight: 700;
  ${({ isDisabled }) => {
    if (isDisabled)
      return css`
        color: ${color.textDisabled};
      `;

    return css`
      font-weight: 700;
      color: ${color.text};
    `;
  }}
`;

const Subtitle = styled.div<{
  isDisabled?: boolean;
}>`
  margin-top: 2px;
  margin-bottom: 6px;
  font-weight: normal;
  ${({ isDisabled }) => {
    if (isDisabled)
      return css`
        color: ${color.textDisabled};
      `;

    return css`
      color: ${color.textSubdued};
    `;
  }}
`;

const renderDataItem = (
  invoice: Invoice,
  { onDownloadDocumentClick, isDownloading }: DataCellProps,
) => {
  const { id, payment_reference_id, start_date, end_date, total_amount, currency } = invoice;
  return (
    <DataItem>
      <Container>
        <Title>{payment_reference_id}</Title>
        <Subtitle>
          <LocalizedDate value={new Date(start_date)} />
          {' – '}
          <LocalizedDate value={new Date(end_date)} />
        </Subtitle>
        <Text>
          <LocalizedNumber value={total_amount} currency={currency} />
        </Text>
      </Container>
      {isDownloading === id ? (
        <SpinnerContainer>
          <Spinner size="small" />
        </SpinnerContainer>
      ) : (
        <InvoiceDropdownActions
          invoice={invoice}
          onDownloadDocumentClick={onDownloadDocumentClick}
        />
      )}
    </DataItem>
  );
};

export const InvoicesDataList = ({
  onDownloadDocumentClick,
  isDownloading,
}: InvoicesDataListProps) => {
  const { getLocalizedMessage } = useI18n();
  const { data: corporate } = useCorporate();
  const [page, setPage] = useState(1);

  const handleLoadDataSuccess = useCallback(() => setPage((p) => p + 1), []);
  const handleRefresh = useCallback(() => setPage(1), []);
  const loadInvoices = useCallback(
    () =>
      corporate
        ? fetchInvoices(corporate.id, {
            page,
            page_size: 20,
            pagination: true,
          })
            .then((data) => data.results)
            .catch((err) => err)
        : Promise.resolve([]),
    [corporate, page],
  );
  return (
    <InfiniteScrollDataList
      renderDataItem={renderDataItem}
      getDataItemId={(dataItem) => dataItem.id}
      getSortBy={(d1, d2) => (d1.start_date > d2.start_date ? -1 : 1)}
      loadData={loadInvoices}
      onRefresh={handleRefresh}
      clearDataOn={[]}
      renderDataItemProps={{ onDownloadDocumentClick, isDownloading }}
      onLoadDataSuccess={handleLoadDataSuccess}
      noData={
        <EmptyState
          subtitle={getLocalizedMessage('invoices.no-invoices-explanation')}
          icon="invoice"
        >
          {getLocalizedMessage('invoices.no-invoices')}
        </EmptyState>
      }
    />
  );
};
