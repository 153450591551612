import { useField, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { isNil } from 'lodash';

import { LocalizedNumber } from 'i18n';
import { useGetLanguageName } from 'utils';
import { EditCorporateFormValues } from 'views/settings/CompanySettings/types';
import { FormViewText } from 'components';

export function DefaultViewRender<T extends string>({ name }: { name: T }) {
  const [{ value }] = useField(name);

  return <FormViewText>{!isNil(value) && value !== '' ? value : '-'}</FormViewText>;
}
export function DefaultCurrencyRender({
  value,
  currency,
}: {
  value?: number | null;
  currency: string;
}) {
  return (
    <FormViewText>
      {!isNil(value) && Number.isInteger(value) ? (
        <LocalizedNumber value={value} currency={currency} />
      ) : (
        '-'
      )}
    </FormViewText>
  );
}
export const BillingCycle = () => {
  const [{ value: isTwice }] = useField<'true' | 'false'>('billing_twice_a_month');
  const { formatMessage } = useIntl();

  return (
    <FormViewText>
      {isTwice === 'true'
        ? formatMessage({ id: 'corporate-form.billing_cycle-twice_a_month' })
        : formatMessage({ id: 'corporate-form.billing_cycle-monthly' })}
    </FormViewText>
  );
};

export const InvoiceGroups = () => {
  const [{ value }] = useField<'true' | 'false'>('organise_invoice_by_groups');
  const { formatMessage } = useIntl();

  return (
    <FormViewText>
      {value === 'true'
        ? formatMessage({ id: 'corporate-form.organized-by-groups-invoice-option' })
        : formatMessage({ id: 'corporate-form.normal-invoice-option' })}
    </FormViewText>
  );
};

export const ReportGroups = () => {
  const [{ value }] = useField<'true' | 'false'>('organise_order_report_by_groups');
  const { formatMessage } = useIntl();

  return (
    <FormViewText>
      {value === 'true'
        ? formatMessage({ id: 'corporate-form.organized-by-groups-order-option' })
        : formatMessage({ id: 'corporate-form.normal-order-option' })}
    </FormViewText>
  );
};

export const InvoiceEmail = () => {
  const [{ value: invoiceEmail }] = useField('invoice_email');
  const [{ value: ccRecipients }] = useField('invoice_email_cc_recipients');

  return <FormViewText>{[invoiceEmail, ...ccRecipients].join(', ')}</FormViewText>;
};

export const PreferredInvoiceLocale = () => {
  const [{ value }] = useField('preferred_invoice_locale');
  const getLanguageName = useGetLanguageName();

  return <FormViewText>{getLanguageName(value)}</FormViewText>;
};

export const EInvoicingEnabled = () => {
  const { formatMessage } = useIntl();
  const {
    values: { is_electronic_invoicing_enabled },
  } = useFormikContext<EditCorporateFormValues>();
  return (
    <FormViewText>
      {is_electronic_invoicing_enabled
        ? formatMessage({ id: 'common.enabled' })
        : formatMessage({ id: 'common.disabled' })}
    </FormViewText>
  );
};
