import { Field, FieldProps, useFormikContext } from 'formik';
import { useMemo } from 'react';

import { Slider } from 'components';
import { DEFAULT_POLICY_TAKEAWAY_RADIUS } from 'modules/policies/constants';

import { PolicyFormValues } from './types';

interface Props {
  index: number;
}

// Default slider values in meters
const SLIDER_MIN = DEFAULT_POLICY_TAKEAWAY_RADIUS;
const SLIDER_MAX = 2000;
const SLIDER_STEP = 50;

export const PolicyTakeawayRadiusSlider = ({ index }: Props) => {
  const {
    values: { delivery_locations },
    setFieldValue,
  } = useFormikContext<PolicyFormValues>();
  const radius = useMemo(
    () => delivery_locations?.[index].takeaway_radius,
    [delivery_locations, index],
  );

  const handleIncrease = () => {
    const newValue = Number(radius) + SLIDER_STEP;
    if (newValue <= SLIDER_MAX) {
      setFieldValue(`delivery_locations.${index}.takeaway_radius`, newValue);
    }
  };

  const handleDecrease = () => {
    const newValue = Number(radius) - SLIDER_STEP;
    if (newValue >= SLIDER_MIN) {
      setFieldValue(`delivery_locations.${index}.takeaway_radius`, newValue);
    }
  };

  return (
    <Field name={`delivery_locations.${index}.takeaway_radius`}>
      {({ field, form }: FieldProps<number>) => (
        <Slider
          value={field.value}
          min={SLIDER_MIN}
          max={SLIDER_MAX}
          unit="m"
          step={SLIDER_STEP}
          onChange={(e) => form.setFieldValue(field.name, Number(e.target.value))}
          onIncrease={handleIncrease}
          onDecrease={handleDecrease}
        />
      )}
    </Field>
  );
};
