import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonVariant } from '@creditornot/cb-button';
import { Checkmark } from '@creditornot/cb-icons';
import { ModalFooter, ModalHeader } from '@creditornot/cbt-modal';
import { Alert } from '@creditornot/cb-alert';
import { typographyCss } from '@creditornot/cb-ingredients';

import { breakpoints, useMedia } from 'modules/media';
import { LocalizedMessage } from 'i18n';
import { processError } from 'utils';

import { ModalV2 } from './ModalV2';

interface Props<T> {
  onClose: () => void;
  onConfirm?: () => Promise<T>;
  onSuccess: (data: T) => void;
  show: boolean;
  title: React.ReactNode;
  content: React.ReactNode;
  confirmationButtonMessage: React.ReactNode;
  confirmationButtonVariant: ButtonVariant;
  confirmationButtonDisabled?: boolean;
  cancelButtonMesssge?: React.ReactNode;
}

const StyledModal = styled(ModalV2)`
  @media (max-width: ${breakpoints.small}px) {
    max-width: unset;
    position: initial;
    height: auto;
  }
`;

const ContentWrapper = styled.div`
  margin: 24px;
  min-height: 80px;
`;

const Content = styled.span`
  ${typographyCss.Body3()}
`;

const StyledAlert = styled(Alert)`
  margin-top: 16px;
`;

const CancelButton = styled(Button)`
  margin-inline-start: auto;
`;

const ConfirmationButton = styled(Button)`
  margin-inline-start: 16px;
`;

function ConfirmationModal<T>({
  onClose,
  onConfirm,
  show,
  content,
  title,
  confirmationButtonMessage,
  confirmationButtonVariant,
  confirmationButtonDisabled,
  cancelButtonMesssge = <LocalizedMessage messageKey="common.cancel" />,
  onSuccess,
}: Props<T>) {
  const small = useMedia('small');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<unknown>(null);
  useEffect(() => {
    setSuccess(false);
    setError(null);
  }, [show]);
  const handleSubmit = useCallback(async () => {
    if (!onConfirm) return;
    setIsSubmitting(true);
    setError(null);
    try {
      const data = await onConfirm();
      setSuccess(true);
      onSuccess(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsSubmitting(false);
    }
  }, [onConfirm, onSuccess]);

  return (
    <StyledModal onDismiss={onClose} open={show} disableContentPadding sizePreset="centered:large">
      <ModalHeader onCloseClick={onClose}>{title}</ModalHeader>

      <ContentWrapper>
        <Content>{content}</Content>

        {!!error && (
          <StyledAlert variant="error" title={processError(error).message}>
            {processError(error).data}
          </StyledAlert>
        )}
      </ContentWrapper>

      <ModalFooter>
        <CancelButton
          data-test-id="confirmation-modal-cancel-button"
          size="small"
          stretch={small}
          disabled={success || isSubmitting}
          onClick={onClose}
          variant="lightBlue"
        >
          {cancelButtonMesssge}
        </CancelButton>

        <ConfirmationButton
          data-test-id="confirmation-modal-confirm-button"
          size="small"
          stretch={small}
          onClick={handleSubmit}
          icon={success && <Checkmark />}
          disabled={success || isSubmitting || !onConfirm || confirmationButtonDisabled}
          loading={!success && isSubmitting}
          variant={confirmationButtonVariant}
        >
          {confirmationButtonMessage}
        </ConfirmationButton>
      </ModalFooter>
    </StyledModal>
  );
}

export default ConfirmationModal;
