import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { Trashbin } from '@creditornot/cb-icons';

import { IconButtonWithHelpText, DataList, EmptyState } from 'components';
import { useCorporate } from 'modules/corporates';
import { LocalizedMessage, useI18n } from 'i18n';
import { User } from 'modules/users/types';

interface GroupUsersDataListProps {
  data: User[] | null | undefined;
  isLoading: boolean;
  error: any;
  dataItemProps: {
    onRemoveUserClick: (user: User) => void;
    isDefaultGroup?: boolean;
  };
  groupName?: string;
  searchTerm: string;
  onAddUsersClick: () => void;
}

const DataItem = styled.div`
  display: flex;
  width: 100%;
`;

const StyledIconButtonWithHelpText = styled(IconButtonWithHelpText)`
  margin-inline-start: auto;
`;

const Content = styled.div`
  font-weight: 700;
  color: ${color.text};
`;

const SubContent = styled.div`
  color: ${color.textSubdued};
`;

const GroupUsersDataList = ({
  data,
  isLoading,
  error,
  dataItemProps,
  searchTerm,
  groupName,
  onAddUsersClick,
}: GroupUsersDataListProps) => {
  const { getLocalizedMessage } = useI18n();

  const { data: corporate } = useCorporate();

  return (
    <DataList
      data={data}
      getDataItemId={(dataItem) => dataItem.id}
      loading={isLoading}
      noData={
        searchTerm ? (
          <EmptyState icon="member">
            {getLocalizedMessage('groups.no-users-with-search-term', {
              searchTerm,
            })}
          </EmptyState>
        ) : (
          <EmptyState
            subtitle={getLocalizedMessage('groups.no-users-in-group-explanation', {
              paymentMethodName: [corporate?.name, groupName].filter(Boolean).join(' – '),
              b: (msg: string) => <b>{msg}</b>,
            })}
            icon="member"
            actionButtonText={getLocalizedMessage('groups.add-members-to-group')}
            onActionButtonClick={onAddUsersClick}
          >
            {getLocalizedMessage('groups.no-users-in-group')}
          </EmptyState>
        )
      }
      error={error}
      renderDataItemProps={dataItemProps}
      renderDataItem={(user, { onRemoveUserClick, isDefaultGroup }) => {
        return (
          <DataItem>
            <div>
              <Content>{user.full_name}</Content>
              <SubContent>{user.email}</SubContent>
            </div>

            {!isDefaultGroup && (
              <StyledIconButtonWithHelpText
                onClick={() => {
                  onRemoveUserClick(user);
                }}
                tooltipText={<LocalizedMessage messageKey="common.delete" />}
                tooltipPlacement="bottom"
                variant="grey"
              >
                <Trashbin height="16px" width="16px" />
              </StyledIconButtonWithHelpText>
            )}
          </DataItem>
        );
      }}
    />
  );
};

export default GroupUsersDataList;
