import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useCallback } from 'react';

import { ApiErrorNotification, LoadingState, ResponsiveContainer, View } from 'components';
import { useFetch } from 'modules/react-query';
import { fetchSubscription } from 'modules/subscription/api';
import { useFetchSubscriptionInvites } from 'modules/subscription/useFetchSubscriptionInvites';
import { useFetchSubscriptionUsers } from 'modules/subscription/useFetchSubscriptionUsers';
import { useCorporate } from 'modules/corporates';
import { useAcceptSubscriptionTerms } from 'modules/subscription/useAcceptSubscriptionTerms';
import { UI_SUCCESS_DELAY } from 'modules/common/constants';
import { useIsUserCorporateManager } from 'modules/wolt-permissions';
import { isSubscriptionBenefitOnlyCorporate } from 'modules/corporates/utils';

import { AddUsersButton, DeleteSubscriptionUsersModal } from './components';
import { useSubscriptionModalsController } from './hooks/useSubscriptionModalsController';
import { useSubscriptionUsersQueryParams } from './hooks/useSubscriptionUsersQueryParams';
import { AddSubscriptionUsersModal } from './components/AddSubscriptionUsersModal';
import { ResendSubscriptionInvitesModal } from './components/ResendSubscriptionInvitesModal';
import { DeleteSubscriptionInvitesModal } from './components/DeleteSubscriptionInvitesModal';
import { SubscriptionList } from './components/SubscriptionList';
import { SubscriptionLandingPage } from './components/SubscriptionLandingPage/SubscriptionLandingPage';
import { AcceptTermsModal } from './components/AcceptTermsModal';

const ErrorContainer = styled(ResponsiveContainer)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const SubscriptionsView: React.FC<
  RouteComponentProps<{
    action?: 'accept' | 'invite' | 'delete-users' | 'delete-invites' | 'resend';
    corporateId: string;
    userId?: string;
  }>
> = ({ match, history }) => {
  const { data: corporate } = useCorporate();
  const { isCorporateManager, isFetchingCorporateRoles } = useIsUserCorporateManager();
  const acceptTerms = useAcceptSubscriptionTerms();

  const {
    closeModal,
    openInviteModal,
    openAcceptModal,
    openDeleteUsersModal,
    openDeleteInvitesModal,
    openResendInvitesModal,
  } = useSubscriptionModalsController();
  const [{ page = 1, page_size = 50 }, setQueryParams] = useSubscriptionUsersQueryParams();

  const {
    data: subscriptionData,
    isFetching: isFetchingSubscription,
    error: subscriptionError,
  } = useFetch({
    queryKey: ['subscription', match.params.corporateId],
    queryFn: () => fetchSubscription(match.params.corporateId),
  });

  const {
    data: usersData,
    isFetching: isFetchingUsers,
    error: usersError,
  } = useFetchSubscriptionUsers(subscriptionData?.id, {
    page,
    page_size,
  });

  const {
    data: invitesData,
    isFetching: isFetchingInvites,
    error: invitesError,
  } = useFetchSubscriptionInvites(subscriptionData?.id, {
    page,
    page_size,
  });

  const handleGetStartedClick = useCallback(() => {
    openAcceptModal();
  }, [openAcceptModal]);

  const handleConsentSubmit = useCallback(
    async (content: string) => {
      if (!subscriptionData) {
        return;
      }
      await acceptTerms({
        content,
        subscriptionId: subscriptionData.id,
        invalidateDelay: UI_SUCCESS_DELAY,
      });
      closeModal();
      openInviteModal();
    },
    [acceptTerms, closeModal, openInviteModal, subscriptionData],
  );

  const handleOpenDeleteUserModal = useCallback(
    (id: string) => {
      setQueryParams({ user: id });
      openDeleteUsersModal();
    },
    [openDeleteUsersModal, setQueryParams],
  );

  const handleOpenDeleteInviteModal = useCallback(
    (id: string) => {
      setQueryParams({ invite: id });
      openDeleteInvitesModal();
    },
    [openDeleteInvitesModal, setQueryParams],
  );

  const handleOpenResendInviteModal = useCallback(
    (id: string) => {
      setQueryParams({ invite: id });
      openResendInvitesModal();
    },
    [openResendInvitesModal, setQueryParams],
  );

  const handleDeleteUsersSuccess = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    search.delete('user');
    search.delete('users');
    const pathname = `/${match.params.corporateId}/subscriptions`;
    history.push({ pathname, search: search.toString() });
  }, [history, match.params.corporateId]);

  const handleDeleteInvitesSuccess = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    search.delete('invite');
    search.delete('invites');
    const pathname = `/${match.params.corporateId}/subscriptions`;
    history.push({ pathname, search: search.toString() });
  }, [history, match.params.corporateId]);

  const handleResendInvitesSuccess = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    search.delete('invite');
    search.delete('invites');
    const pathname = `/${match.params.corporateId}/subscriptions`;
    history.push({ pathname, search: search.toString() });
  }, [history, match.params.corporateId]);

  const shouldShowList = subscriptionData?.status !== 'created';

  if (!corporate) {
    return null;
  }

  const isSubscriptionOnlyCorporate = isSubscriptionBenefitOnlyCorporate(corporate);

  if (isFetchingSubscription) {
    return (
      <LoadingState>
        <FormattedMessage id="common.loading" />
      </LoadingState>
    );
  }

  if (subscriptionError) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={subscriptionError} />
      </ErrorContainer>
    );
  }

  return (
    <>
      {subscriptionData ? (
        shouldShowList ? (
          <View
            title={<FormattedMessage id="wolt-plus.title" />}
            subtitle={<FormattedMessage id="wolt-plus.subtitle" />}
            rightContent={<AddUsersButton />}
          >
            <SubscriptionList
              usersData={usersData?.results}
              usersLoading={isFetchingUsers}
              usersError={usersError}
              usersDataItemProps={{ onOpenDeleteUserModal: handleOpenDeleteUserModal }}
              usersPageCount={usersData?.total_pages_count}
              usersCount={usersData?.total_items_count}
              invitesData={invitesData?.results}
              invitesLoading={isFetchingInvites}
              invitesError={invitesError}
              invitesDataItemProps={{
                onOpenDeleteInviteModal: handleOpenDeleteInviteModal,
                onOpenResendInviteModal: handleOpenResendInviteModal,
              }}
              invitesPageCount={invitesData?.total_pages_count}
              invitesCount={invitesData?.total_items_count}
            />
          </View>
        ) : (
          <SubscriptionLandingPage
            onGetStartedClick={handleGetStartedClick}
            subscriptionPrice={subscriptionData?.price?.monthly}
            currency={corporate.currency}
          />
        )
      ) : null}

      {subscriptionData?.id && subscriptionData.status !== 'created' && (
        <>
          <AddSubscriptionUsersModal
            show={match.params.action === 'invite'}
            corporateId={match.params.corporateId}
            subscriptionId={subscriptionData.id}
            isSubscriptionOnlyCorporate={isSubscriptionOnlyCorporate}
            onClose={closeModal}
          />

          <ResendSubscriptionInvitesModal
            show={match.params.action === 'resend'}
            subscriptionId={subscriptionData.id}
            onResendInvitesSuccess={handleResendInvitesSuccess}
            onClose={closeModal}
          />

          <DeleteSubscriptionInvitesModal
            show={match.params.action === 'delete-invites'}
            onDeleteInvitesSuccess={handleDeleteInvitesSuccess}
            onClose={closeModal}
          />

          <DeleteSubscriptionUsersModal
            show={match.params.action === 'delete-users'}
            subscriptionId={subscriptionData.id}
            onDeleteUsersSuccess={handleDeleteUsersSuccess}
            onClose={closeModal}
          />
        </>
      )}

      {subscriptionData?.status === 'created' && (
        <AcceptTermsModal
          show={match.params.action === 'accept'}
          corporate={corporate}
          isFetching={isFetchingCorporateRoles}
          isCorporateManager={isCorporateManager}
          onSubmit={handleConsentSubmit}
          onClose={closeModal}
        />
      )}
    </>
  );
};
