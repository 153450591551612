import { useEffect, useRef } from 'react';

export const useCreateDomElement = (id: string, rootId: string) => {
  const targetElement = useRef(document.createElement('div'));

  useEffect(() => {
    targetElement.current.setAttribute('id', id);

    const root = document.getElementById(rootId);

    root?.parentElement?.appendChild(targetElement.current);

    return () => {
      try {
        if (targetElement.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          root?.parentElement?.removeChild(targetElement.current);
        }
        // eslint-disable-next-line no-empty
      } catch {}
    };
  }, [id, rootId]);

  return targetElement.current;
};
