import { Alert } from '@creditornot/cb-alert';
import { FormattedMessage } from 'react-intl';

import { type MessageIds } from 'i18n';

type Props = {
  alwaysShowError?: boolean;
  endDateError?: MessageIds;
  startDateError?: MessageIds;
  className?: string;
};

export const FormikDateErrorAlert: React.FC<Props> = ({
  endDateError,
  startDateError,
  className,
}) => {
  const error = startDateError || endDateError;
  return (
    <>
      {error && (
        <Alert className={className} size="small" variant="error">
          <FormattedMessage id={error} />
        </Alert>
      )}
    </>
  );
};
