import { useI18n } from 'i18n';
import { FormikInputField } from 'components';
import { createValidator, isRequired, isNotLongerThan } from 'validation';

const validate = createValidator([isRequired, isNotLongerThan(20)]);

interface Props {
  alwaysShowError?: boolean;
}

const PolicyNameField = ({ alwaysShowError }: Props) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <FormikInputField
      testId="policy.name-input"
      name="name"
      type="text"
      required={true}
      validate={validate}
      alwaysShowError={alwaysShowError}
      inputProps={{
        placeholder: getLocalizedMessage('policies.policy-name-placeholder'),
      }}
    />
  );
};

export default PolicyNameField;
