import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { useMemo } from 'react';

import { breakpoints } from 'modules/media';

type Props = {
  leftItems?: React.ReactNode[] | null;
  rightItems?: React.ReactNode[] | null;
  className?: string;
  borderBottom?: boolean;
};

const Root = styled.div<{ borderBottom?: boolean }>`
  padding: 16px 24px;
  min-height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${color.border};
  border-bottom: ${({ borderBottom }) => !borderBottom && 'none'};

  @media (max-width: ${breakpoints.medium}px) {
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Items = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.medium}px) {
    & + & {
      margin-top: 16px;
    }

    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Item = styled.div`
  display: inline-block;

  & + & {
    margin-inline-start: 16px;
  }

  @media (max-width: ${breakpoints.medium}px) {
    & + & {
      margin-top: 16px;
      margin-inline-start: unset;
    }

    width: 100%;
  }
`;

export const Header = ({ leftItems, rightItems, className, borderBottom }: Props) => {
  const displayLeftItems = useMemo(() => leftItems?.filter(Boolean), [leftItems]);
  const displayRightItems = useMemo(() => rightItems?.filter(Boolean), [rightItems]);
  return (
    <Root className={className} borderBottom={borderBottom}>
      {displayLeftItems?.length ? (
        <Items>
          {displayLeftItems.map((item, index) => (
            <Item key={index}>{item}</Item>
          ))}
        </Items>
      ) : null}
      {displayRightItems?.length ? (
        <Items>
          {displayRightItems.map((item, index) => (
            <Item key={index}>{item}</Item>
          ))}
        </Items>
      ) : null}
    </Root>
  );
};
