import React, { useCallback } from 'react';

import { ConfirmationModal } from 'components';
import { LocalizedMessage } from 'i18n';
import { useDeleteGroup } from 'modules/groups';
import { Group } from 'modules/groups/types';

interface DeleteGroupModalProps {
  onClose: () => void;
  goToGroupsView: () => void;
  show: boolean;
  group: Group | null | undefined;
}

export const DeleteGroupModal = ({
  onClose,
  goToGroupsView,
  show,
  group,
}: DeleteGroupModalProps) => {
  const deleteGroup = useDeleteGroup();
  const handleConfirm = useCallback(async () => {
    group ? await deleteGroup(group.id) : await Promise.resolve(null);
  }, [group, deleteGroup]);

  return (
    <ConfirmationModal
      onClose={onClose}
      onConfirm={handleConfirm}
      show={show}
      content={
        <LocalizedMessage
          messageKey="groups-form.delete-group-confirmation-message"
          values={{
            group: group?.name || '-',
          }}
        />
      }
      title={<LocalizedMessage messageKey="groups-form.delete-group" />}
      confirmationButtonMessage={<LocalizedMessage messageKey="common.delete" />}
      confirmationButtonVariant="lightRed"
      onSuccess={() => setTimeout(goToGroupsView, 400)}
    />
  );
};
