import { useCallback } from 'react';
import styled from 'styled-components';
import { IconButton } from '@creditornot/cb-button';
import { ArrowLeft, ArrowRight } from '@creditornot/cb-icons';
import { ModalHeader } from '@creditornot/cbt-modal';

import { useAppModals } from 'modules/app-modals';
import { LocalizedMessage, useLanguageDirection } from 'i18n';
import { useCorporate } from 'modules/corporates';

import { CreateGroupForm } from './components';
import { Group } from './types';

const Root = styled.div`
  width: 100%;
  padding-bottom: 24px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const BackButton = styled(IconButton).attrs({
  size: 'small',
  variant: 'black',
})`
  margin-inline-end: 8px;
`;

const CREATE_GROUP_MODAL = 'CREATE_GROUP_MODAL';

export const useCreateGroupInModal = () => {
  const direction = useLanguageDirection();
  const { openModal, closeModal } = useAppModals();
  const { data: corporate } = useCorporate();
  const { hasOpenModal } = useAppModals();

  return useCallback(
    () =>
      new Promise<Group>((resolve, reject) => {
        openModal(CREATE_GROUP_MODAL, {
          children: (
            <Root>
              <ModalHeader>
                <Title>
                  <BackButton
                    onClick={() => {
                      closeModal(CREATE_GROUP_MODAL);
                      reject();
                    }}
                  >
                    {direction === 'ltr' ? <ArrowLeft /> : <ArrowRight />}
                  </BackButton>
                  <LocalizedMessage messageKey="groups-form.create-group" />
                </Title>
              </ModalHeader>

              {corporate && (
                <CreateGroupForm
                  corporateId={corporate.id}
                  onSuccess={(group) => {
                    resolve(group);
                    closeModal(CREATE_GROUP_MODAL);
                  }}
                  onCancel={() => {
                    closeModal(CREATE_GROUP_MODAL);
                    reject();
                  }}
                />
              )}
            </Root>
          ),
          disableDismissOnOutsidePress: hasOpenModal,
          sizePreset: {
            desktop: 'centered:extraLarge',
            mobile: 'fullScreen',
          },
        });
      }),
    [closeModal, corporate, direction, hasOpenModal, openModal],
  );
};
