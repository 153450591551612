import styled from 'styled-components';

import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';

type Props = {
  className?: string;
  component: React.ReactNode;
};

const Root = styled.div`
  ${cssDirectionalValue({ ltr: '', rtl: 'transform: scaleX(-1);' })}
`;

export const ImageRtlSupport = ({ className, component }: Props) => {
  return <Root className={className}>{component}</Root>;
};
