import { RadioCard } from '@creditornot/cb-radio';
import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { TextButton } from '@creditornot/cb-button';

import { useI18n } from 'i18n';
import { InformationDisplay, ResponsiveContainer } from 'components';
import { breakpoints } from 'modules/media';

import {
  FormikInviteEventUsersCustomMessageTextarea,
  FormikEventInviteTagInput,
  FormikInviteEventUsersFileInput,
  FormikInviteEventUsersSaveMessageSwitch,
} from './EventFormFields';
import { CreateEventFormData } from './types';

interface Props {
  className?: string;
}

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
`;

const StyledInputContainer = styled(InformationDisplay)`
  & + & {
    margin-top: 16px;
  }
`;

const RadioCards = styled.div`
  display: flex;
`;

const StyledRadioCard = styled(RadioCard)`
  width: 50%;
  align-items: flex-start;

  & + & {
    margin-inline-start: 16px;
  }
`;

const RevertToDefaultMessageButton = styled(TextButton).attrs({
  variant: 'blue',
  size: 'small',
})`
  text-align: end;
`;

const CustomMessageLabelRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
  }
`;

type InputType = 'INPUT_EMAIL' | 'IMPORT_BY_FILE';

export const InviteEventUsersFormSection: React.FC<Props> = ({ className }) => {
  const { getLocalizedMessage } = useI18n();
  const [inputType, setInputType] = useState<InputType>('INPUT_EMAIL');
  const formik = useFormikContext<CreateEventFormData>();
  const handleRevertToDefaultMessage = useCallback(
    () =>
      formik.setFieldValue(
        'customMessage',
        getLocalizedMessage(
          'events.create-event-form.invite-participants.customize-message-placeholder',
        ),
      ),
    [formik, getLocalizedMessage],
  );

  return (
    <StyledResponsiveContainer className={className}>
      <StyledInputContainer
        labelColor="black"
        label={
          <CustomMessageLabelRow>
            {getLocalizedMessage(
              'events.create-event-form.invite-participants.customize-message-label',
            )}
            <RevertToDefaultMessageButton onClick={handleRevertToDefaultMessage}>
              {getLocalizedMessage(
                'events.create-event-form.invite-participants.revert-to-default-message',
              )}
            </RevertToDefaultMessageButton>
          </CustomMessageLabelRow>
        }
      >
        <FormikInviteEventUsersCustomMessageTextarea />
        <FormikInviteEventUsersSaveMessageSwitch />
      </StyledInputContainer>

      <StyledInputContainer
        labelColor="black"
        label={getLocalizedMessage('events.create-event-form.invite-participants.how-to-invite')}
      >
        <RadioCards>
          <StyledRadioCard
            readOnly
            label={getLocalizedMessage(
              'events.create-event-form.invite-participants.import-manually-title',
            )}
            checked={inputType === 'INPUT_EMAIL'}
            subContent={getLocalizedMessage(
              'events.create-event-form.invite-participants.import-manually-subtitle',
            )}
            onClick={() => {
              setInputType('INPUT_EMAIL');
              formik.setFieldValue('emails', null);
            }}
          />
          <StyledRadioCard
            readOnly
            label={getLocalizedMessage(
              'events.create-event-form.invite-participants.upload-csv-title',
            )}
            subContent={getLocalizedMessage(
              'events.create-event-form.invite-participants.upload-csv-subtitle',
            )}
            checked={inputType === 'IMPORT_BY_FILE'}
            onClick={() => {
              setInputType('IMPORT_BY_FILE');
              formik.setFieldValue('emails', null);
            }}
          />
        </RadioCards>
      </StyledInputContainer>
      {inputType === 'INPUT_EMAIL' && (
        <StyledInputContainer
          labelColor="black"
          label={getLocalizedMessage('events.create-event-form.invite-participants.textarea-label')}
        >
          <FormikEventInviteTagInput />
        </StyledInputContainer>
      )}

      {inputType === 'IMPORT_BY_FILE' && (
        <StyledInputContainer
          labelColor="black"
          label={getLocalizedMessage(
            'events.create-event-form.invite-participants.upload-csv-label',
          )}
          explanation={getLocalizedMessage(
            'events.create-event-form.invite-participants.upload-csv-help-text',
            {
              strong: (text: string) => <strong>{text}</strong>,
            },
          )}
        >
          <FormikInviteEventUsersFileInput />
        </StyledInputContainer>
      )}
    </StyledResponsiveContainer>
  );
};
