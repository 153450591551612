import { useMemo, useState } from 'react';
import { RadioCard } from '@creditornot/cb-radio';
import { InputContainer } from '@creditornot/cb-form';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Alert } from '@creditornot/cb-alert';
import { Button } from '@creditornot/cb-button';
import { Checkmark } from '@creditornot/cb-icons';
import { typographyCss } from '@creditornot/cb-ingredients';

import {
  ApiErrorNotification,
  FileInputInviteUserForm,
  FormFooter,
  FormikCheckboxField,
  TagInputInviteUserForm,
} from 'components';
import { useI18n, LocalizedMessage } from 'i18n';
import { event } from 'modules/google-analytics';
import { useProductTour } from 'modules/product-tour';
import { fetchGroups } from 'modules/groups';
import { useInviteUsers } from 'modules/invites';
import { UserInvite } from 'modules/invites/types';
import { useFetch } from 'modules/react-query';
import { useCorporate } from 'modules/corporates';
import { corporateFeatureFlag } from 'modules/corporates/utils';

import { transformInviteEmailsToInviteUsersData } from '../formValuesTransformers';
import { InviteUserFormValues } from '../types';
import { FormikCreateGroupButtonField } from '../FormikCreateGroupButtonField';
import CreateGroupForm from './CreateGroupForm';
import { FormikGroupsSelectField } from './FormikGroupsSelectField';

interface InviteUserFormProps {
  onClose: () => void;
  onSuccess: (invites: UserInvite[]) => void;
  corporateId: string | null;
}

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: 24px;
  }
`;

const StyledFormikCreateGroupButtonField = styled(FormikCreateGroupButtonField)`
  margin-top: 16px;
`;

const RadioCards = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
`;

const Container = styled.div`
  padding: 24px;
`;

const StyledAlert = styled(Alert)`
  margin-top: 16px;
`;

const Text = styled.span`
  ${typographyCss.Body3()}
`;

const SubText = styled.small`
  ${typographyCss.Small()}
`;

const Explanation = styled.div`
  margin-inline-start: 24px;
`;

const ENTER_USER_EMAILS = 'ENTER_USER_EMAILS';

const InviteUserForm = ({ onClose, onSuccess, corporateId }: InviteUserFormProps) => {
  const [formType, setFormType] = useState<'ENTER_USER_EMAILS' | 'IMPORT_USER_EMAILS'>(
    ENTER_USER_EMAILS,
  );
  const { isInTourMode } = useProductTour();
  const [showCreateGroupForm, setShowCreateGroupForm] = useState(false);
  const { getLocalizedMessage } = useI18n();
  const { data: corporate } = useCorporate();
  const {
    data: groupsData,
    refetch: refreshGroupsData,
    error: groupsError,
  } = useFetch({
    queryKey: ['InviteUserForm', 'Groups', corporateId],
    queryFn: () => (corporateId ? fetchGroups(corporateId) : Promise.reject()),
    enabled: !!corporateId,
  });
  const inviteUsers = useInviteUsers();
  const autoAddUsersGroups = useMemo(
    () => groupsData?.filter((group) => group.add_to_all),
    [groupsData],
  );

  const { groupEnabled } = corporateFeatureFlag(corporate);

  return (
    <Formik
      initialValues={{ emails: null, is_corporate_manager: false, groups: [] }}
      onSubmit={async (values: InviteUserFormValues, actions) => {
        if (!corporateId) {
          actions.setSubmitting(false);
          actions.setStatus({ error: 'Corporate id must be provided' });
          return;
        }

        const autoAddUsersGroups = (groupsData || []).filter((group) => group.add_to_all);

        const groupIds = [...values.groups, ...autoAddUsersGroups.map(({ id }) => id)];

        if (!values.emails) {
          return;
        }

        try {
          const invites = await inviteUsers(
            transformInviteEmailsToInviteUsersData({
              emails: values.emails,
              is_corporate_manager: values.is_corporate_manager,
              group_ids: groupIds,
            }),
          );
          actions.setStatus({ submitSucceeded: true });
          setTimeout(() => onSuccess(invites), 500);
          if (isInTourMode) {
            event({
              category: 'new_user_onboarding',
              label: 'invite_users',
              action: 'create',
            });
          }
        } catch (error) {
          actions.setStatus({
            error,
          });
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, submitForm, status, values: { groups }, setFieldValue }) => {
        return showCreateGroupForm ? (
          <CreateGroupForm
            corporateId={corporateId}
            error={groupsError}
            onBack={() => setShowCreateGroupForm(false)}
            onSuccess={(group) => {
              refreshGroupsData();
              setFieldValue('groups', [...groups, group.id]);
              setTimeout(() => {
                setShowCreateGroupForm(false);
              }, 500);
            }}
          />
        ) : (
          <>
            <Container>
              <StyledInputContainer
                label={getLocalizedMessage('users.new-user-modal.invite-option')}
              >
                <RadioCards>
                  <RadioCard
                    label={getLocalizedMessage('users.new-user-modal.invite-users-radio-label')}
                    subContent={getLocalizedMessage('users.new-user-modal.invite-option-by-text')}
                    checked={formType === 'ENTER_USER_EMAILS'}
                    onChange={() => setFormType('ENTER_USER_EMAILS')}
                  />
                  <RadioCard
                    label={getLocalizedMessage('users.new-user-modal.import-users-radio-label')}
                    onChange={() => {
                      setFormType('IMPORT_USER_EMAILS');
                      setFieldValue('emails', []);
                      setFieldValue('is_corporate_manager', false);
                    }}
                    subContent={getLocalizedMessage('users.new-user-modal.invite-option-by-file')}
                    checked={formType === 'IMPORT_USER_EMAILS'}
                  />
                </RadioCards>
              </StyledInputContainer>

              <StyledInputContainer>
                {formType === 'ENTER_USER_EMAILS' ? (
                  <TagInputInviteUserForm />
                ) : (
                  <FileInputInviteUserForm />
                )}
              </StyledInputContainer>

              <StyledInputContainer>
                <FormikCheckboxField name="is_corporate_manager">
                  <Text>
                    <LocalizedMessage messageKey="users.add-user-to-be-admin" />
                  </Text>
                </FormikCheckboxField>
                <Explanation>
                  <SubText>{getLocalizedMessage('users.admin-role-explanation')}</SubText>
                </Explanation>
              </StyledInputContainer>

              {groupEnabled && (
                <StyledInputContainer
                  label={getLocalizedMessage('users.new-user-modal.groups-label')}
                >
                  <FormikGroupsSelectField groups={groupsData ?? []} />
                  {!!autoAddUsersGroups?.length && (
                    <StyledAlert size="small" variant="info">
                      <LocalizedMessage
                        messageKey="users.auto-add-users-group-notification"
                        values={{
                          strong: (text: string) => <strong>{text}</strong>,
                          groups: autoAddUsersGroups?.map(({ name }) => name).join(', '),
                        }}
                      />
                    </StyledAlert>
                  )}
                  <StyledFormikCreateGroupButtonField />
                </StyledInputContainer>
              )}

              {(status?.error || groupsError) && (
                <StyledInputContainer>
                  <ApiErrorNotification error={status.error || groupsError} />
                </StyledInputContainer>
              )}
            </Container>
            <FormFooter
              rightContent={
                <>
                  <Button size="small" variant="lightBlue" onClick={onClose}>
                    <LocalizedMessage messageKey="common.cancel" />
                  </Button>
                  <Button
                    size="small"
                    variant="blue"
                    data-test-id="invite-button"
                    onClick={submitForm}
                    disabled={isSubmitting || status?.submitSucceeded}
                    loading={isSubmitting}
                    icon={
                      status?.submitSucceeded && (
                        <Checkmark data-test-id="invite-button.checkmark" />
                      )
                    }
                  >
                    <LocalizedMessage messageKey="users.new-user-modal.invite.invite-button" />
                  </Button>
                </>
              }
            />
          </>
        );
      }}
    </Formik>
  );
};

export default InviteUserForm;
