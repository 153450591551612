import { color } from '@creditornot/cb-ingredients/design-tokens';
import { typographyCss } from '@creditornot/cb-ingredients';
import React from 'react';
import styled from 'styled-components';
import { Button } from '@creditornot/cb-button';
import { useIntl, FormattedMessage } from 'react-intl';

import useMedia from 'modules/media/useMedia';

import { WoltPlusShoppingBagIcon } from './WoltPlusShoppingBagIcon';
import { WoltPlusIcon } from './WoltPlusIcon';

interface Props {
  onDismiss: () => void;
  onLearnMoreClick: () => void;
}

const Root = styled.div<{ $small: boolean }>`
  border: 1px solid ${color.border};
  border-radius: 8px;
  display: flex;
  flex-flow: row ${({ $small }) => ($small ? 'wrap' : 'nowrap')};
  justify-content: end;
  align-items: center;
  padding-inline: ${({ $small }) => ($small ? '16px' : '4px')} 16px;
  margin: 24px;
`;

const StyledWoltPlusShoppingBagIcon = styled(WoltPlusShoppingBagIcon)`
  display: flex;
  flex: 0 0 95.16px;
`;

const StyledButton = styled(Button)`
  margin-inline-start: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
  flex: 0 0 auto;
`;

const TextContainer = styled.div<{ $small: boolean }>`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-top: ${({ $small }) => ($small ? '16px' : '8px')};
  margin-bottom: 8px;
`;

const Title = styled.h2`
  ${typographyCss.Heading6()}
  color: ${color.text};
  font-weight: 600;
`;

const Subtitle = styled.div`
  ${typographyCss.Body3()};
  color: ${color.text};
  font-weight: 400;
`;

export const SubscriptionAdBanner: React.FC<Props> = ({ onLearnMoreClick, onDismiss }) => {
  const { formatMessage } = useIntl();
  const medium = useMedia('medium');
  const small = useMedia('small');

  return (
    <Root $small={small}>
      {!small && <StyledWoltPlusShoppingBagIcon />}
      <TextContainer $small={small}>
        <Title>
          <FormattedMessage
            id="wolt-plus.ad-banner.title"
            values={{
              icon: () => <WoltPlusIcon color={color.text} backgroundColor={color.bg} />,
            }}
          />
        </Title>
        <Subtitle>
          <FormattedMessage
            id="wolt-plus.ad-banner.subtitle"
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </Subtitle>
      </TextContainer>
      <StyledButton onClick={onDismiss} variant="white" size={medium ? 'small' : 'large'}>
        {formatMessage({ id: 'wolt-plus.ad-banner.dismiss-button' })}
      </StyledButton>
      <StyledButton
        onClick={onLearnMoreClick}
        variant="lightBlue"
        size={medium ? 'small' : 'large'}
      >
        {formatMessage({ id: 'wolt-plus.ad-banner.cta-button' })}
      </StyledButton>
    </Root>
  );
};
