import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled, { css } from 'styled-components';
import { IconButton } from '@creditornot/cb-button';
import { colors, typographyCss } from '@creditornot/cb-ingredients';
import { Minus, Plus } from '@creditornot/cb-icons';
import { Direction, pxToRem } from '@creditornot/cb-utils';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';

import { useLanguageDirection } from 'i18n';
import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';

interface Props {
  min: number;
  max: number;
  step?: number;
  unit?: string;
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onIncrease: () => void;
  onDecrease: () => void;
}

const RangeWrapper = styled.div<{
  isRtl?: boolean;
}>`
  margin: ${pxToRem(12)} 0;
  display: flex;
  align-items: center;
  padding-top: 44px;
  direction: ${(props) => (props.isRtl ? 'rtl' : 'ltr')};
`;

const RangeInput = styled.input<{
  isRtl?: boolean;
}>`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${pxToRem(28)};
  appearance: none;
  cursor: pointer;
  outline: none;
  overflow: hidden; /* slider progress trick */
  border-radius: ${pxToRem(16)};

  /* Track: webkit browsers */
  &::-webkit-slider-runnable-track {
    height: ${pxToRem(28)};
    background: ${color.bgSurfaceSecondary};
    border-radius: ${pxToRem(16)};
  }

  /* Track: Mozilla Firefox */
  &::-moz-range-track {
    height: ${pxToRem(28)};
    background: ${color.bgSurfaceSecondary};
    border-radius: ${pxToRem(16)};
  }

  /* Thumb: webkit */
  &::-webkit-slider-thumb {
    appearance: none;
    height: ${pxToRem(28)}; /* creating a custom design */
    width: ${pxToRem(28)};
    background-color: ${color.bgFillInverse};
    border-radius: 50%;
    border: 2px solid ${color.borderSelected};
    box-shadow: ${(props) => (props.isRtl ? pxToRem(411) : pxToRem(-411))} 0 0 ${pxToRem(400)}
      ${colors.wolt100}; /* slider progress trick */
  }

  /* Thumb: Firefox */
  &::-moz-range-thumb {
    height: ${pxToRem(26)};
    width: ${pxToRem(26)};
    background-color: ${color.bgFillInverse};
    border-radius: 50%;
    border: 2px solid ${color.borderSelected};
    box-shadow: ${(props) => (props.isRtl ? pxToRem(411) : pxToRem(-411))} 0 0 ${pxToRem(400)}
      ${colors.wolt100}; /* slider progress trick */
  }
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${pxToRem(28)};
  margin: 0 ${pxToRem(12)};
`;

const Content = styled.span`
  ${typographyCss.Body3()};
  font-weight: 500;
`;

const PopoverWrapper = styled.div<{ percentage: number; direction: Direction }>`
  position: absolute;
  ${({ percentage, direction }) => {
    // Different offset based on language direction
    const offsetEnd = direction === 'ltr' ? 14 : 22;
    const offset = ((100 - percentage) / 100) * 28 - offsetEnd; // Calculate the offset from -offsetEnd to offsetEnd
    return css`
      ${cssDirectionalValue({ ltr: 'left', rtl: 'right' })}: calc(${percentage}% + ${offset}px);
    `;
  }}
  color: transparent;
  transform: translateX(-50%);
`;

const StyledIconButton = styled(IconButton)`
  position: relative;
`;

export const Slider = ({
  min,
  max,
  unit,
  step,
  value,
  onChange,
  onIncrease,
  onDecrease,
}: Props) => {
  const direction = useLanguageDirection();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowLeft') {
      event.preventDefault();
      onDecrease();
    } else if (event.key === 'ArrowRight') {
      event.preventDefault();
      onIncrease();
    }
  };

  return (
    <RangeWrapper isRtl={direction === 'rtl'}>
      <StyledIconButton
        data-test-id="decrease"
        aria-label="Decrease Value"
        size="small"
        variant="blue"
        onClick={onDecrease}
      >
        <Minus />
      </StyledIconButton>
      <SliderContainer>
        <RangeInput
          isRtl={direction === 'rtl'}
          type="range"
          min={min}
          max={max}
          step={step}
          onChange={onChange}
          value={value}
          aria-label="Slider Range"
          tabIndex={0}
          onKeyDown={handleKeyDown}
          data-test-id="slider"
        />
        <PopoverWrapper percentage={((value - min) / (max - min)) * 100} direction={direction}>
          <Popover
            show
            contentZIndex={1}
            content={<Content>{unit ? `${value} ${unit}` : value}</Content>}
            placement="top"
            fallbackPlacements={['top']}
            positionStrategy="absolute"
          >
            <div>-</div>
          </Popover>
        </PopoverWrapper>
      </SliderContainer>
      <StyledIconButton
        data-test-id="increase"
        aria-label="Increase Value"
        size="small"
        variant="blue"
        onClick={onIncrease}
      >
        <Plus />
      </StyledIconButton>
    </RangeWrapper>
  );
};
