import styled from 'styled-components';
import { Field, FieldProps, useFormikContext } from 'formik';

import { PolicyFormValues } from './types';
import TimeSelect from './TimeSelect';

const StyledTimeSelect = styled(TimeSelect)`
  max-width: fit-content;
`;

const PolicyDeliveryEndTimeField = () => {
  const { values } = useFormikContext<PolicyFormValues>();

  return (
    <Field name="delivery_end_minute">
      {({ field, form }: FieldProps<PolicyFormValues['delivery_end_minute']>) => {
        return (
          <StyledTimeSelect
            inputId="delivery_end_minute"
            value={field.value}
            getDisabledTime={(time) => values.delivery_start_minute < time.value}
            onChange={(value: number) => {
              form.setFieldValue(field.name, value);

              const allDay = values.delivery_start_minute === 0 && value === 1440;

              if (allDay) {
                form.setFieldValue('all_day_delivery', true);
              }

              if (values.all_day_delivery && !allDay) {
                form.setFieldValue('all_day_delivery', false);
              }
            }}
          />
        );
      }}
    </Field>
  );
};

export default PolicyDeliveryEndTimeField;
