import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuActionItem, MenuItemGroup, MenuLinkItem } from '@creditornot/cb-menu';
import { CaretDown, CaretUp, MagnifyingGlass } from '@creditornot/cb-icons';
import styled from 'styled-components';
import { Spinner } from '@creditornot/cb-progress';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { Input } from '@creditornot/cb-input';
import { TextButton } from '@creditornot/cb-button';
import { array } from 'io-ts';
import { typographyCss } from '@creditornot/cb-ingredients';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFetch, useQueryClient } from 'modules/react-query';
import { fetchCorporatesBy, useCorporate } from 'modules/corporates';
import { Corporate } from 'modules/corporates/types';
import { useMedia } from 'modules/media';
import { useDebounce } from 'modules/hooks';

const Content = styled.div`
  max-height: 200px;
  overflow-y: auto;

  /* sideBar - sideBarPadding - (caret + caretMargin) */
  width: 252px;
  padding: 8px 8px 0;
`;

const Text = styled.span`
  ${typographyCss.Body3()}
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  /* sideBar - sideBarPadding - (caret + caretMargin) */
  max-width: 252px;
`;

const Wrapper = styled.div`
  margin-top: 8px;
`;

const queryKey = 'AsyncCorporateSelect';

const useFetchCorporatesByName = (name: string | null) => {
  return useFetch({
    queryKey: [queryKey, 'useFetchCorporatesByName', name],
    queryFn: () => (name ? fetchCorporatesBy(name) : Promise.reject()),
    enabled: !!name,
  });
};

const useCacheCorporates = () => {
  const queryClient = useQueryClient();
  const cacheData = queryClient.getQueriesData(queryKey);
  return array(Corporate).is(cacheData) ? cacheData : null;
};

const AsyncCorporateSelect = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false);
  const mediumView = useMedia('medium');
  const { data: corporate } = useCorporate();
  const debouncedSearch = useDebounce(encodeURIComponent(search), 500);
  const { data, error, isFetching } = useFetchCorporatesByName(
    debouncedSearch.length > 1 ? debouncedSearch : null,
  );
  const cacheCorporates = useCacheCorporates();
  const isSearching = isFetching && search.length > 1;

  useEffect(() => {
    if (show) return;
    setSearch('');
  }, [show]);

  const handleCorporateChange = useCallback(
    (corporate: Corporate) => {
      history.push(`/${corporate.id}`);
      setTimeout(() => setShow(false), 50);
    },
    [history],
  );

  const content = useMemo(() => {
    if (error) {
      return (
        <Wrapper>
          <FormattedMessage id="navigation.corporate-select.error" />
        </Wrapper>
      );
    }

    if (data?.length === 0) {
      return (
        <Wrapper>
          <FormattedMessage id="navigation.corporate-select.no-options" />
        </Wrapper>
      );
    }

    if (data?.length) {
      return data.map((cp) => {
        return (
          <MenuLinkItem
            key={JSON.stringify(cp)}
            active={cp.id === corporate?.id}
            onClick={() => handleCorporateChange(cp)}
          >
            {cp.name}
          </MenuLinkItem>
        );
      });
    }

    if (cacheCorporates?.length) {
      return cacheCorporates.map((cp: any) => {
        return (
          <MenuLinkItem
            key={JSON.stringify(cp)}
            active={cp.id === corporate?.id}
            onClick={() => handleCorporateChange(cp)}
          >
            {cp.name}
          </MenuLinkItem>
        );
      });
    }
  }, [error, data, cacheCorporates, corporate?.id, handleCorporateChange]);

  return (
    <Popover
      contentZIndex={999}
      show={show}
      onClickOutside={() => setShow(false)}
      showOnHover={false}
      placement={mediumView ? 'bottom' : 'bottom-start'}
      renderArrow={false}
      content={
        <Content>
          <MenuItemGroup>
            <Input
              value={search}
              placeholder={formatMessage({ id: 'common.search' })}
              icon={isSearching ? <Spinner size="small" /> : <MagnifyingGlass />}
              onChange={(e) => setSearch(e.target.value)}
              autoFocus
            />
            {content}

            <MenuActionItem variant="blue" onClick={() => history.push('/')}>
              <FormattedMessage id="navigation.corporate-select.view-corporates" />
            </MenuActionItem>
          </MenuItemGroup>
        </Content>
      }
    >
      <TextButton
        onClick={() => setShow((state) => !state)}
        variant="black"
        iconPosition="right"
        icon={show ? <CaretUp /> : <CaretDown />}
      >
        <Text>
          {corporate?.name || formatMessage({ id: 'navigation.corporate-select.placeholder' })}
        </Text>
      </TextButton>
    </Popover>
  );
};

export default AsyncCorporateSelect;
