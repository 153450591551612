import styled from 'styled-components';
import { FormRow } from '@creditornot/cb-form';

import { useI18n } from 'i18n';

import { ContactPersonNameField } from './ContactPersonNameField';
import { ContactPersonEmailField } from './ContactPersonEmailField';
import { ContactPersonPhoneNumberField } from './ContactPersonPhoneNumberField';

const StyledFormSection = styled(FormRow)`
  border-top: none;
`;

interface Props {
  isEditForm?: boolean;
}

export const ContactPersonFormSection = ({ isEditForm = false }: Props) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <StyledFormSection label={getLocalizedMessage('corporate-form.contact-person-title')}>
      <ContactPersonNameField alwaysShowError={isEditForm} />
      <ContactPersonEmailField alwaysShowError={isEditForm} />
      <ContactPersonPhoneNumberField alwaysShowError={isEditForm} />
    </StyledFormSection>
  );
};
