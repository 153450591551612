import { useIntl } from 'react-intl';

import { ModalV2 } from 'components';
import { Event } from 'modules/events/types';

import { EditEventNameForm } from './EditEventNameForm';

interface Props {
  onCloseClick: () => void;
  onSuccess: (event: Event) => void;
  show: boolean;
  event: Event;
}

export const EditEventNameModal = ({ onCloseClick, show, onSuccess, event }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <ModalV2
      onDismiss={onCloseClick}
      open={show}
      title={formatMessage({ id: 'events.edit-event-name-form.title' })}
      sizePreset={{
        desktop: 'centered:large',
        mobile: 'fullScreen',
      }}
    >
      <EditEventNameForm onSuccess={onSuccess} onClose={onCloseClick} event={event} />
    </ModalV2>
  );
};
