import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Column, DataTable } from '@creditornot/cb-data-table';
import { useIntl } from 'react-intl';

import {
  DataCellContent,
  ResponsiveContainer,
  ApiErrorNotification,
  UserStatus,
  UserName,
} from 'components';
import { useCorporate } from 'modules/corporates';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { UserV2 } from 'modules/users/types';
import { PaginatedData } from 'types/PaginatedData';
import { corporateFeatureFlag } from 'modules/corporates/utils';
import { isDefined } from 'utils';

import AdminTag from './AdminTag';
import ActionButtons from '../ActionButtons';
import GroupTag from '../GroupTag';
import { useUsersModalsController, useUsersQueryParams } from '../hooks';

interface Props {
  usersData: PaginatedData<UserV2> | null | undefined;
  className?: string;
  selectedRowKeys: string[];
  onRowSelectionChange: (selectedRowKeys: string[]) => void;
  error: unknown;
  loading: boolean;
  isFiltering: boolean;
}

const StyledAdminTag = styled(AdminTag)`
  margin: 0 8px;
`;

const ErrorContainer = styled(ResponsiveContainer)`
  padding-top: 22px;
  padding-bottom: 22px;
`;

const DataCellContainer = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.4;
    `}
`;

const useColumns = () => {
  const { formatMessage } = useIntl();
  const { openDeleteUserModal, openEditUserModal } = useUsersModalsController();
  const { data: corporate } = useCorporate();
  const { isWoltEmployee } = useIsUserWoltEmployee();
  const { groupEnabled } = corporateFeatureFlag(corporate);

  const columns: (Column<UserV2, unknown> | undefined)[] = [
    {
      id: 'name',
      name: formatMessage({ id: 'users.column-title.name' }),
      render: ({ record: user }) => {
        if (!corporate) {
          return null;
        }
        return (
          <DataCellContainer isDisabled={user.status === 'disabled'}>
            <UserName
              userId={user.user_id}
              userName={`${user.name.first} ${user.name.last}`}
              shouldLinkToOpsTools={isWoltEmployee}
            />

            {user.is_admin && <StyledAdminTag isDisabled={user.status === 'disabled'} />}
          </DataCellContainer>
        );
      },
    },
    {
      id: 'email',
      name: formatMessage({ id: 'users.column-title.email' }),
      render: ({ record: user }) => {
        return <DataCellContent disabled={false}>{user.email}</DataCellContent>;
      },
    },
    groupEnabled
      ? {
          id: 'groups',
          name: formatMessage({ id: 'users.column-title.groups' }),
          render: ({ record: user }) => {
            return (
              <>
                {user.groups
                  .sort((g1, g2) => (g1.name > g2.name ? 1 : -2))
                  .map((group) => (
                    <GroupTag key={group.id} isDisabled={!group.active}>
                      {group.name}
                    </GroupTag>
                  ))}
              </>
            );
          },
        }
      : undefined,
    {
      id: 'status',
      name: formatMessage({ id: 'users.column-title.status' }),
      style: {
        width: '15%',
      },
      render: ({ record: user }) => {
        return (
          <DataCellContent disabled={user.status === 'disabled'}>
            <UserStatus status={user.status} />
          </DataCellContent>
        );
      },
    },
    {
      id: 'actions',
      style: {
        width: '8%',
      },
      name: '',
      render: ({ record: user }) => {
        return (
          <ActionButtons
            onEditClick={() => openEditUserModal(user.user_id)}
            onDeleteClick={() => openDeleteUserModal(user.user_id)}
          />
        );
      },
    },
  ];

  return columns.filter((column): column is Column<UserV2, unknown> => isDefined(column));
};

export const UsersDataTable: React.FC<Props> = ({
  usersData,
  isFiltering,
  selectedRowKeys,
  loading,
  error,
  onRowSelectionChange,
}) => {
  const columns = useColumns();
  const { formatMessage } = useIntl();
  const [{ page = 1, page_size = 20 }, setQueryParams] = useUsersQueryParams();
  const hideRowSelection = loading || (isFiltering && usersData?.results?.length === 0);

  const handleSelectAll = useCallback(() => {
    const isAllSeleted = selectedRowKeys.length === usersData?.results?.length;
    if (isAllSeleted) {
      onRowSelectionChange([]);
      return;
    }
    onRowSelectionChange((usersData?.results || []).map((d) => d.user_id));
  }, [usersData, onRowSelectionChange, selectedRowKeys.length]);

  if (error) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={error} />
      </ErrorContainer>
    );
  }

  const hidePagination = loading || usersData?.total_items_count === 0;

  return (
    <DataTable
      horizontalPadding="wide"
      columns={columns}
      dataSource={usersData?.results || []}
      getRowKey={(user) => user.user_id}
      emptyMessage={{
        title: formatMessage({ id: 'users.users-view.no-users' }),
        description: formatMessage({ id: 'users.users-view.no-users-description' }),
      }}
      rowSelection={
        hideRowSelection
          ? undefined
          : {
              selectedRowKeys,
              onChange: onRowSelectionChange,
              onSelectAll: handleSelectAll,
            }
      }
      loading={loading}
      pagination={
        hidePagination
          ? undefined
          : {
              disableWhenLoading: true,
              pageCount: Math.ceil(
                (usersData?.total_items_count ?? 0) / (usersData?.page_size ?? 1),
              ),
              pageSize: page_size,
              activePage: page,
              onPageChange: (p) => setQueryParams({ page: p }),
              onPageSizeChange: (p) => {
                setQueryParams({ page: 1, page_size: p });
              },
              pageSizeOptions: [10, 20, 50, 100],
            }
      }
    />
  );
};
