import React from 'react';
import { CookieConsents } from '@creditornot/cwc-cookie-sdk';
import { useIntl } from 'react-intl';

import { ModalV2 } from 'components';

import { ManageCookieConsentsForm } from './ManageCookieConsentsForm';

export type ManageCookieConsentsModalProps = {
  show: boolean;
  onDismiss: () => void;
  initialConsents: CookieConsents;
  onSubmit: (consents: CookieConsents) => void;
};

export const ManageCookieConsentsModal = ({
  show,
  onSubmit,
  onDismiss,
  initialConsents,
}: ManageCookieConsentsModalProps) => {
  const { formatMessage } = useIntl();
  return (
    <ModalV2
      open={show}
      onDismiss={onDismiss}
      title={formatMessage({ id: 'gdpr-consents.manage.title' })}
      sizePreset={{
        desktop: 'centered:large',
        mobile: 'fullScreen',
      }}
    >
      <ManageCookieConsentsForm initialValues={initialConsents} onSubmit={onSubmit} />
    </ModalV2>
  );
};
