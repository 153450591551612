import { AuthProvider } from '@creditornot/wolt-auth/react';
import { createRoot } from 'react-dom/client';

import { authenticator } from 'modules/auth/authenticator';
import { avoInit } from 'telemetry/avoInit';

import env from './config/env';
import Root from './Root';

import 'core-js/stable';
import 'css.escape';
import 'regenerator-runtime/runtime';
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31245
import type {} from 'styled-components/cssprop';
import '@creditornot/cb-ingredients/theme/default.css';

console.info(`Node env: ${env.NODE_ENV}, Version: ${env.APP_VERSION}.`);

avoInit();

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <AuthProvider authenticator={authenticator}>
    <Root />
  </AuthProvider>,
);
