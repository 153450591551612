import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQueryClient } from 'modules/react-query';

import { editGroup, EditGroupData } from './api';

export const useEditGroup = () => {
  const queryClient = useQueryClient();
  const { data: corporate } = useCorporate();

  return useCallback(
    async (groupId: string, data: EditGroupData) => {
      if (!corporate?.id) {
        return Promise.reject('Corporate is missing');
      }
      const result = await editGroup(corporate.id, groupId, data);
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 500);
      return result;
    },
    [corporate?.id, queryClient],
  );
};
