import {
  Switch,
  Route,
  RouteComponentProps,
  Redirect,
  RouteProps,
  useHistory,
} from 'react-router-dom';
import { Alert } from '@creditornot/cb-alert';
import styled from 'styled-components';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { B2bCustomerConfigResponse } from '@creditornot/daas-core-api-client';
import { validate as uuidValidate } from 'uuid';

import OrdersView from 'views/orders';
import UsersView from 'views/users';
import GroupsView from 'views/groups';
import PoliciesView from 'views/policies';
import SubscriptionsView from 'views/subscription';
import AdminsView from 'views/admins';
import { InvoicesView } from 'views/invoices';
import { SettingsView } from 'views/settings';
import { EventsView } from 'views/events';
import { ReportsView } from 'views/reports';
import { Home, SelfServiceSignupHome } from 'views/home';
import { DeliveryHomeView } from 'views/delivery-home';
import { useFetchCorporate } from 'modules/corporates';
import { OnboardingProvider } from 'onboarding';
import { LoadingState } from 'components/LoadingState';
import { ResponsiveContainer } from 'components';
import { useConfigs } from 'modules/configs';
import { DeliveriesView } from 'views/deliveries';
import { setMetadataResource } from 'telemetry/metadata';
import { SignupV2 } from 'views/settings/SignupV2/SignupV2';
import { HomeSignupV2 } from 'views/home/HomeSignupV2';
import { isSubscriptionBenefitOnlyCorporate } from 'modules/corporates/utils';
import { Corporate } from 'modules/corporates/types';
import { getCorporateRoutes } from 'modules/corporates/getCorporateRoutes';
import { View } from 'navigation/types';
import { useFetchB2bConfig } from 'modules/b2b-configs';
import { formatToUuid } from 'modules/common/utils';

type CorporateRoutesProps = {
  corporate: Corporate;
  b2bConfig?: B2bCustomerConfigResponse;
};

type ComponentsForRoute = {
  [key in View]: {
    component: RouteProps['component'];
    daasComponent?: RouteProps['component'];
  };
};

const AlertContainer = styled(ResponsiveContainer)`
  margin-top: 24px;
  margin-bottom: 24px;

  & > div {
    width: 100%;
  }
`;

export const componentsForRoute: ComponentsForRoute = {
  home: { component: Home, daasComponent: DeliveryHomeView },
  orders: { component: OrdersView },
  deliveries: { component: DeliveriesView },
  users: { component: UsersView },
  admins: { component: AdminsView },
  groups: { component: GroupsView },
  policies: { component: PoliciesView },
  subscriptions: { component: SubscriptionsView },
  invoices: { component: InvoicesView },
  reports: { component: ReportsView },
  events: { component: EventsView },
  settings: { component: SettingsView },
};

const Routes = ({ corporate, b2bConfig }: CorporateRoutesProps) => {
  const routes = getCorporateRoutes(corporate, b2bConfig);

  if (!routes?.length) {
    return null;
  }

  const driveEnabled = corporate.products.daas_delivery_ui_enabled;

  const isSubscriptionOnlyCorporate = isSubscriptionBenefitOnlyCorporate(corporate);
  const defaultRoute: View = isSubscriptionOnlyCorporate ? 'subscriptions' : 'home';

  return (
    <Switch>
      {routes.map((route) => {
        const { component, daasComponent } = componentsForRoute[route.name];
        const selectedComponent = driveEnabled ? daasComponent ?? component : component;

        return (
          <Route
            path={`/:corporateId/${route.name}`}
            key={route.name}
            component={selectedComponent}
          />
        );
      })}
      <Redirect to={`/${corporate.id}/${defaultRoute}`} />
    </Switch>
  );
};

const useValidateCorporateId = (corporateId: string) => {
  const history = useHistory();

  useEffect(() => {
    const formattedToUUID = formatToUuid(corporateId);

    if (!uuidValidate(formattedToUUID)) {
      history.replace('/');
    }
  }, [corporateId, history]);
};

export const CorporateView = ({ match }: RouteComponentProps<{ corporateId: string }>) => {
  useValidateCorporateId(match.params.corporateId);
  const { configs, isLoading: isLoadingConfigs } = useConfigs();

  const {
    data: corporate,
    isLoading: isLoading,
    error: corporateError,
  } = useFetchCorporate(match.params.corporateId, {
    retry: 3,
    retryDelay: 3 * 1000,
  });

  const { data: b2bConfig, isLoading: isB2bConfigLoading } = useFetchB2bConfig(
    corporate?.id || null,
  );

  useEffect(() => {
    if (corporate) {
      setMetadataResource({ corporate_country: corporate.country, corporate_id: corporate.id });
    }

    return () => {
      setMetadataResource({ corporate_country: undefined, corporate_id: undefined });
    };
  }, [corporate]);

  if ((isLoading && !corporate) || (isLoadingConfigs && !configs) || isB2bConfigLoading) {
    return (
      <LoadingState>
        <FormattedMessage id="corporate.loading-corporate" />
      </LoadingState>
    );
  }

  if (corporateError || !corporate) {
    return (
      <AlertContainer>
        <Alert variant="error">
          <FormattedMessage id="corporate.nothing-found" />
        </Alert>
      </AlertContainer>
    );
  }

  if (
    [
      'submitted',
      'invite_pending',
      'details_pending',
      'verification_pending',
      'contract_pending',
    ].includes(corporate.status)
  ) {
    if (corporate.signup_flow_version === 2) {
      return (
        <Switch>
          <Route path="/:corporateId/home" component={HomeSignupV2} />
          <Route path="/:corporateId/settings" component={SignupV2} />
          <Redirect to={`/${corporate.id}/settings`} />
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route path="/:corporateId/home" component={SelfServiceSignupHome} />
          <Route path="/:corporateId/settings" component={SettingsView} />
          <Redirect to={`/${corporate.id}/home`} />
        </Switch>
      );
    }
  }

  if (corporate.products.waw_enabled) {
    return (
      <OnboardingProvider>
        <Routes corporate={corporate} />
      </OnboardingProvider>
    );
  }

  return <Routes corporate={corporate} b2bConfig={b2bConfig} />;
};
