import { useField } from 'formik';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { CheckboxCard } from 'components';

export type PolicyCreditPurchaseFieldProps = {
  className?: string;
};

export const PolicyCreditPurchaseField = ({ className }: PolicyCreditPurchaseFieldProps) => {
  const { formatMessage } = useIntl();
  const [{ value, name }, , { setValue }] = useField<boolean>('giftcard_purchases_allowed');
  const handleChange = useCallback(() => {
    setValue(!value);
  }, [setValue, value]);
  return (
    <CheckboxCard
      className={className}
      name={name}
      checked={value}
      label={formatMessage({ id: 'policies.allow-purchase-from-gift-card-venues' })}
      stretched
      onChange={handleChange}
    />
  );
};
