import { useCallback } from 'react';

import {
  useQueryParams,
  WoltPageSizeParam,
  NeverNullNumberParam,
  withDefault,
} from 'modules/react-query-params';

interface QueryParams {
  page: number;
  page_size: number;
}

export const useInvoicesQueryParams = (): [QueryParams, (query: Partial<QueryParams>) => void] => {
  const query = useQueryParams({
    page: withDefault(NeverNullNumberParam, 1),
    page_size: WoltPageSizeParam,
  });
  const [params, setReactQueryParams] = query;
  const setQueryParams: typeof setReactQueryParams = useCallback(
    (queries) => {
      const shouldResetPage = 'page_size' in queries;
      setReactQueryParams(({ page, ...staledQueries }) => ({
        ...staledQueries,
        page: shouldResetPage ? 1 : page,
        ...queries,
      }));
    },
    [setReactQueryParams],
  );
  return [params, setQueryParams] as typeof query;
};
