import { pickBy } from 'lodash';

import { NetsuiteConfig, CountryConfig } from 'modules/country-configs/types';
import { CreateCountryConfigData, EditCountryConfigData } from 'modules/country-configs';
import {
  convertCentsToDisplayAmount,
  convertFromAmountToCents,
  nestedObjectDifferences,
} from 'utils';

import { CountryConfigFormValues, NetsuiteConfigFormValues } from './types';

const isEmpty = (data: unknown) => data === null || data === undefined;

export const formatNetsuitApiDataToFormValues = (data: NetsuiteConfig) => {
  const keys = Object.keys(data) as (keyof NetsuiteConfigFormValues)[];

  return keys.reduce((acc, key) => {
    switch (key) {
      case 'subsidiary_id':
        return {
          ...acc,
          [key]: !isEmpty(data[key]) ? (data[key] as number).toString() : '',
        };

      default:
        return {
          ...acc,
          [key]: !isEmpty(data[key]) ? data[key] : '',
        };
    }
  }, {} as NetsuiteConfigFormValues);
};

export const formatCountryConfigApiDataToFormValues = (
  countryConfig: CountryConfig,
): CountryConfigFormValues => ({
  agreement_party_id: countryConfig.agreement_party_id,
  currency: countryConfig.currency,
  default_corporate_credit_limit_amount: countryConfig.default_corporate_credit_limit_amount
    ? convertCentsToDisplayAmount(
        countryConfig.default_corporate_credit_limit_amount,
        countryConfig.currency,
      )
    : '',
  default_corporate_invoice_fee_percentage:
    countryConfig.default_corporate_invoice_fee_percentage?.toString() ?? '',
  default_corporate_payment_term_delay_days:
    countryConfig.default_corporate_payment_term_delay_days?.toString() ?? '',
  default_corporate_invoice_overdue_interest:
    countryConfig.default_corporate_invoice_overdue_interest?.toString() ?? '',
  include_main_document_order_list: countryConfig.include_main_document_order_list,
  include_main_document_vat_id: countryConfig.include_main_document_vat_id,
  include_sub_invoice_bank_transfer_note: countryConfig.include_sub_invoice_bank_transfer_note,
  include_sub_invoice_issuer_details: countryConfig.include_sub_invoice_issuer_details,
  is_tipping_enabled: countryConfig.is_tipping_enabled,
  enabled_products: countryConfig.enabled_products,
  local_bank_account_numbers: countryConfig.local_bank_account_numbers,
  main_invoice_document_type: countryConfig.main_invoice_document_type,
  netsuite_config:
    countryConfig.netsuite_config &&
    formatNetsuitApiDataToFormValues(countryConfig.netsuite_config),
  requires_separate_merchant_invoices: countryConfig.requires_separate_merchant_invoices,
  requires_wolt_sub_invoices: countryConfig.requires_wolt_sub_invoices,
  send_invoices_to_merchants: countryConfig.send_invoices_to_merchants,
  service_fee_document_location: countryConfig.service_fee_document_location,
  service_fee_vat_explanation: countryConfig.service_fee_vat_explanation,
  service_fee_vat_percentage: countryConfig.service_fee_vat_percentage
    ? countryConfig.service_fee_vat_percentage.toString()
    : '0',
  standalone_wolt_country: countryConfig.standalone_wolt_country,
  status: countryConfig.status,
  transaction_limits: {
    daily_transactions_amount: convertCentsToDisplayAmount(
      countryConfig.transaction_limits.daily_transactions_amount,
      countryConfig.currency,
    ),
    single_transaction_amount: convertCentsToDisplayAmount(
      countryConfig.transaction_limits.single_transaction_amount,
      countryConfig.currency,
    ),
  },
  wolt_company_account_bic: countryConfig.wolt_company_account_bic,
  wolt_company_account_iban: countryConfig.wolt_company_account_iban,
  wolt_company_name: countryConfig.wolt_company_name,
});

export const formatNetsuitFormValuesToSubmitData = ({
  bank_account_name,
  country_name,
  delivery_item_name,
  department_name,
  purchase_item_name,
  separated_delivery_fee,
  subsidiary_id,
  tips_item_name,
  discount_item_name,
  giftcard_item_name,
}: NetsuiteConfigFormValues): NetsuiteConfig => ({
  subsidiary_id: subsidiary_id === '' ? null : Number.parseInt(subsidiary_id),
  bank_account_name,
  country_name,
  delivery_item_name,
  department_name,
  purchase_item_name,
  separated_delivery_fee,
  tips_item_name,
  discount_item_name,
  giftcard_item_name,
});

export const formatCreateCountryConfigFormValuesToSubmitData = (
  values: CountryConfigFormValues,
  countryCode: string,
  currency: string,
): CreateCountryConfigData => ({
  agreement_party_id: values.agreement_party_id ? values.agreement_party_id : undefined,
  country_code: countryCode,
  currency: values.currency,
  default_corporate_credit_limit_amount: convertFromAmountToCents(
    values.default_corporate_credit_limit_amount,
    currency,
  ),
  default_corporate_invoice_fee_percentage: Number.parseFloat(
    values.default_corporate_invoice_fee_percentage,
  ),
  default_corporate_payment_term_delay_days: Number.parseInt(
    values.default_corporate_payment_term_delay_days,
  ),
  default_corporate_invoice_overdue_interest: Number.parseInt(
    values.default_corporate_invoice_overdue_interest,
  ),
  include_main_document_order_list: values.include_main_document_order_list,
  include_main_document_vat_id: values.include_main_document_vat_id,
  include_sub_invoice_bank_transfer_note: values.include_sub_invoice_bank_transfer_note,
  include_sub_invoice_issuer_details: values.include_sub_invoice_issuer_details,
  is_tipping_enabled: values.is_tipping_enabled,
  enabled_products: values.enabled_products,
  local_bank_account_numbers: values.local_bank_account_numbers,
  main_invoice_document_type: values.main_invoice_document_type,
  netsuite_config: values.netsuite_config
    ? formatNetsuitFormValuesToSubmitData(values.netsuite_config)
    : null,
  requires_separate_merchant_invoices: values.requires_separate_merchant_invoices,
  requires_wolt_sub_invoices: values.requires_wolt_sub_invoices,
  send_invoices_to_merchants: values.send_invoices_to_merchants,
  service_fee_document_location: values.service_fee_document_location,
  service_fee_vat_explanation: values.service_fee_vat_explanation,
  service_fee_vat_percentage:
    values.service_fee_vat_percentage !== ''
      ? Number.parseInt(values.service_fee_vat_percentage)
      : null,
  standalone_wolt_country: values.standalone_wolt_country,
  status: values.status,
  transaction_limits: {
    single_transaction_amount: convertFromAmountToCents(
      values.transaction_limits.single_transaction_amount,
      currency,
    ),
    daily_transactions_amount: convertFromAmountToCents(
      values.transaction_limits.daily_transactions_amount,
      currency,
    ),
  },
  wolt_company_account_bic: values.wolt_company_account_bic,
  wolt_company_account_iban: values.wolt_company_account_iban,
  wolt_company_name: values.wolt_company_name,
});

export const formatEditCountryConfigFormValuesToSubmitData = (
  values: CountryConfigFormValues,
  initialValues: CountryConfigFormValues,
  currency: string,
): EditCountryConfigData => {
  const updatedValues = nestedObjectDifferences(values, initialValues);

  const data = {
    agreement_party_id: updatedValues.agreement_party_id ?? undefined,
    currency: updatedValues.currency,
    default_corporate_credit_limit_amount: updatedValues.default_corporate_credit_limit_amount
      ? convertFromAmountToCents(updatedValues.default_corporate_credit_limit_amount, currency)
      : undefined,
    default_corporate_invoice_fee_percentage: updatedValues.default_corporate_invoice_fee_percentage
      ? Number.parseFloat(updatedValues.default_corporate_invoice_fee_percentage)
      : undefined,
    default_corporate_payment_term_delay_days:
      updatedValues.default_corporate_payment_term_delay_days
        ? Number.parseInt(updatedValues.default_corporate_payment_term_delay_days)
        : undefined,
    default_corporate_invoice_overdue_interest:
      updatedValues.default_corporate_invoice_overdue_interest
        ? Number.parseInt(updatedValues.default_corporate_invoice_overdue_interest)
        : undefined,
    include_main_document_order_list: updatedValues.include_main_document_order_list,
    include_main_document_vat_id: updatedValues.include_main_document_vat_id,
    include_sub_invoice_bank_transfer_note: updatedValues.include_sub_invoice_bank_transfer_note,
    include_sub_invoice_issuer_details: updatedValues.include_sub_invoice_issuer_details,
    is_tipping_enabled: updatedValues.is_tipping_enabled,
    enabled_products: updatedValues.enabled_products,
    local_bank_account_numbers: updatedValues.local_bank_account_numbers,
    main_invoice_document_type: updatedValues.main_invoice_document_type,
    netsuite_config: updatedValues.netsuite_config
      ? formatNetsuitFormValuesToSubmitData(updatedValues.netsuite_config)
      : undefined,
    requires_separate_merchant_invoices: updatedValues.requires_separate_merchant_invoices,
    requires_wolt_sub_invoices: updatedValues.requires_wolt_sub_invoices,
    send_invoices_to_merchants: updatedValues.send_invoices_to_merchants,
    service_fee_document_location: updatedValues.service_fee_document_location,
    service_fee_vat_explanation: updatedValues.service_fee_vat_explanation,
    service_fee_vat_percentage: updatedValues.service_fee_vat_percentage
      ? Number.parseInt(updatedValues.service_fee_vat_percentage)
      : undefined,
    standalone_wolt_country: updatedValues.standalone_wolt_country,
    status: updatedValues.status,
    transaction_limits: updatedValues.transaction_limits
      ? {
          single_transaction_amount: convertFromAmountToCents(
            updatedValues.transaction_limits.single_transaction_amount,
            currency,
          ),
          daily_transactions_amount: convertFromAmountToCents(
            updatedValues.transaction_limits.daily_transactions_amount,
            currency,
          ),
        }
      : undefined,
    wolt_company_account_bic: updatedValues.wolt_company_account_bic,
    wolt_company_account_iban: updatedValues.wolt_company_account_iban,
    wolt_company_name: updatedValues.wolt_company_name,
  };

  // Filter out all undefined values
  return pickBy(data, (v) => v !== undefined);
};
