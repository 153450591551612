export const replaceStringWithJSX = (
  text: string,
  separator: string,
  component: React.ReactElement,
): React.ReactNode[] | string => {
  if (!text) {
    return text;
  }
  return text.split(separator).map((part, index, array) => {
    if (index === array.length - 1) {
      return part;
    }
    return [part, component];
  });
};
