import styled from 'styled-components';
import { Formik } from 'formik';
import { Button } from '@creditornot/cb-button';
import { Checkmark } from '@creditornot/cb-icons';
import { FormRow } from '@creditornot/cb-form';

import { FormFooter } from 'components';
import { useEditAgreementParty } from 'modules/agreement-parties';
import { AgreementParty } from 'modules/agreement-parties/types';

import BankAccountsField from './BankAccountsField';
import VatsField from './VatsField';
import GeneralInfomationFormSection from './GeneralInfomationFormSection';
import AddressFormSection from './AddressFormSection';
import {
  formatAgreementPartyApiDataToEditFormValues,
  formatEditAgreementPartyFormValuesToSubmitData,
} from '../utils';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  data: AgreementParty;
}

const Form = styled.form`
  overflow: auto;
  padding: 24px;
`;

const EditAgreementPartyForm: React.FC<Props> = ({ onSuccess, onClose, data }) => {
  const editAgreementParty = useEditAgreementParty();
  return (
    <Formik
      initialValues={formatAgreementPartyApiDataToEditFormValues(data)}
      onSubmit={async (values, actions) => {
        try {
          await editAgreementParty(data.id, formatEditAgreementPartyFormValuesToSubmitData(values));

          actions.setSubmitting(false);
          actions.setStatus({ submitSucceeded: true });

          setTimeout(() => {
            onSuccess();
          }, 500);
        } catch (error) {
          actions.setStatus({ error });
          actions.setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, isValid, dirty, status }) => (
        <Form onSubmit={handleSubmit}>
          <GeneralInfomationFormSection />

          <AddressFormSection />

          <FormRow label="Bank accounts">
            <BankAccountsField />
          </FormRow>

          <FormRow label={'VATs'}>
            <VatsField />
          </FormRow>

          <FormFooter
            css={`
              padding: 12px 0px;
            `}
            rightContent={
              <div>
                <Button
                  onClick={onClose}
                  disabled={isSubmitting}
                  variant="lightBlue"
                  type="button"
                  size="small"
                >
                  Cancel
                </Button>
                <Button
                  loading={isSubmitting}
                  icon={status?.submitSucceeded && <Checkmark />}
                  disabled={isSubmitting || !isValid || !dirty || status?.submitSucceeded}
                  type="submit"
                  size="small"
                  variant="blue"
                >
                  Edit
                </Button>
              </div>
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditAgreementPartyForm;
