import { useHistory } from 'react-router-dom';
import { FC, useEffect } from 'react';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { env } from 'config';
import { initialize, set, pageview } from 'modules/google-analytics';
import { useCookieConsents } from 'modules/cookie-consents/CookieConsentsProvider';

const useInitGA = () => {
  const { user } = useAuthState();
  const { hasReceivedConsentEvent, consents } = useCookieConsents();
  const history = useHistory();

  useEffect(() => {
    if (!env.GA_TRACKING_ID || !user || !hasReceivedConsentEvent || !consents.analytics) {
      return;
    }

    initialize(env.GA_TRACKING_ID, {
      gaOptions: { userId: user.id },
    });

    /**
     * GA config:
     * dimension1: user_id
     * dimension2: is_wolt_employee
     * **/

    const unlisten = history.listen(() => {
      set({
        page: window.location.pathname,
        dimension1: user.id,
        dimension2: user.permissions?.some((permission) => /wolt_at_work/.test(permission))
          ? 'true'
          : 'false',
      });
      pageview(window.location.pathname);
    });

    return unlisten;
  }, [consents.analytics, hasReceivedConsentEvent, history, user]);
};

export const GoogleAnalyticsProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  useInitGA();
  return <>{children} </>;
};
