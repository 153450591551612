import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Formik, FormikHelpers } from 'formik';
import { Checkmark } from '@creditornot/cb-icons';
import { Button } from '@creditornot/cb-button';

import { ApiErrorNotification, FormFooter } from 'components';
import { LocalizedMessage } from 'i18n';
import { Event } from 'modules/events/types';
import { useCreateEvent } from 'modules/events';

import { CreateEventFormData } from '../types';
import { formatCreateEventFormValuesToApiData } from '../utils';
import { AddEventDetailsFormSection } from '../AddEventDetailsFormSection';

interface Props {
  onSuccess: (event: Event) => void;
  onSuccessAndOpenInviteEventUsers: (event: Event) => void;
  currency: string;
}

const ErrorContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const initialValues: CreateEventFormData = {
  name: '',
  description: '',
  start_minutes: '',
  start_date: null,
  end_minutes: '',
  end_date: null,
  participant_budget_amount: '',
};

export const CreateEventForm = ({
  onSuccess,
  onSuccessAndOpenInviteEventUsers,
  currency,
}: Props) => {
  const createEvent = useCreateEvent();
  const [shouldOpenInviteUsersForm, setShouldOpenInviteUsersForm] = useState(false);
  const handleSubmit = useCallback(
    async (values: CreateEventFormData, actions: FormikHelpers<CreateEventFormData>) => {
      try {
        const newEvent = await createEvent(formatCreateEventFormValuesToApiData(values, currency));
        actions.setSubmitting(false);
        actions.setStatus({ submitSucceeded: true });
        setTimeout(() => {
          if (shouldOpenInviteUsersForm) {
            onSuccessAndOpenInviteEventUsers(newEvent);
          } else {
            onSuccess(newEvent);
          }
        }, 500);
      } catch (error) {
        actions.setSubmitting(false);
        actions.setStatus({ error });
      }
    },
    [createEvent, currency, onSuccess, onSuccessAndOpenInviteEventUsers, shouldOpenInviteUsersForm],
  );
  return (
    <Formik initialValues={initialValues} validateOnMount onSubmit={handleSubmit}>
      {({ isSubmitting, status, submitForm }) => (
        <>
          <AddEventDetailsFormSection />

          {status?.error && (
            <ErrorContainer>
              <ApiErrorNotification error={status.error} />
            </ErrorContainer>
          )}

          <FormFooter
            rightContent={
              <>
                <Button
                  size="small"
                  variant="lightBlue"
                  onClick={submitForm}
                  disabled={isSubmitting || status?.submitSucceeded}
                  loading={!shouldOpenInviteUsersForm ? isSubmitting : undefined}
                  icon={status?.submitSucceeded && !shouldOpenInviteUsersForm && <Checkmark />}
                >
                  <LocalizedMessage messageKey="events.create-event-form.finish-creating-button" />
                </Button>
                <Button
                  size="small"
                  variant="blue"
                  onClick={() => {
                    setShouldOpenInviteUsersForm(true);
                    submitForm();
                  }}
                  loading={shouldOpenInviteUsersForm ? isSubmitting : undefined}
                  disabled={isSubmitting || status?.submitSucceeded}
                  icon={status?.submitSucceeded && shouldOpenInviteUsersForm && <Checkmark />}
                >
                  <LocalizedMessage messageKey="events.create-event-form.next-button" />
                </Button>
              </>
            }
          />
        </>
      )}
    </Formik>
  );
};
