import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useState } from 'react';
import { Alert } from '@creditornot/cb-alert';
import { pick } from 'lodash';

import { Corporate } from 'modules/corporates/types';
import { formatCorporateDataToFormValues } from 'modules/corporates/formatFormValues';
import { processError } from 'utils';
import { useEditCorporateAccount } from 'modules/corporates/useEditCorporateAcount';

import { CorporateAccountForm } from '../SignupV2/components/CorporateAccountForm/CorporateAccountForm';
import { EditCorporateFormValues } from '../CompanySettings/types';
import { ApiErrorContainer } from './ApiErrorContainer';

type Props = {
  corporate: Corporate;
  onReactivateClick: () => void;
  onDisableClick: () => void;
  onDeleteClick: () => void;
  onActivateNewClick: () => void;
};

const FORM_KEYS = ['account_manager'];
type FormValues = Pick<EditCorporateFormValues, 'account_manager'>;

export const CorporateAccountContainer = ({
  corporate,
  onActivateNewClick,
  onDeleteClick,
  onDisableClick,
  onReactivateClick,
}: Props) => {
  const [state, setState] = useState<'view' | 'edit'>('view');
  const initialValues = pick(formatCorporateDataToFormValues(corporate), FORM_KEYS) as FormValues;

  const editContactInformation = useEditCorporateAccount(corporate);

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      try {
        await editContactInformation(initialValues, values);
        actions.setSubmitting(false);
        actions.setStatus({ submitSucceeded: true });
        actions.resetForm();
        setState('view');
      } catch (error) {
        actions.setSubmitting(false);
        actions.setStatus({ error });
      }
    },
    [editContactInformation, initialValues],
  );

  return (
    <Formik
      validateOnMount={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm, dirty, status, resetForm }) => (
        <Form>
          <CorporateAccountForm
            renderMode={state}
            onEditClick={() => setState('edit')}
            onCancelClick={() => {
              resetForm();
              setState('view');
            }}
            onSaveClick={submitForm}
            onActivateNewClick={onActivateNewClick}
            onDeleteClick={onDeleteClick}
            onDisableClick={onDisableClick}
            onReactivateClick={onReactivateClick}
            isSubmitting={isSubmitting || status?.submitSucceeded}
            dirty={dirty}
            error={status?.error}
            status={corporate.status}
            updatedAt={corporate.status_modified_at}
          />
          {status?.error && (
            <ApiErrorContainer>
              <Alert variant="error" size="medium" title={processError(status.error).message}>
                {processError(status.error).data}
              </Alert>
            </ApiErrorContainer>
          )}
        </Form>
      )}
    </Formik>
  );
};
