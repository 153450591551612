import { B2bCustomerConfigUpdateRequest } from '@creditornot/daas-core-api-client';

import { useMutation, useQueryClient } from 'modules/react-query';

import { b2bConfigApiClient } from './api';

type UpdateB2bConfigParams = {
  payload: B2bCustomerConfigUpdateRequest;
  corporateId: string;
};

export const useUpdateB2bConfig = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, corporateId }: UpdateB2bConfigParams) => {
      return b2bConfigApiClient.corporateUpdateB2bConfig({
        corporateId: corporateId,
        b2bCustomerConfigUpdateRequest: payload,
      });
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries(['b2bConfigs', data.corporate_id]);
      },
    },
  );
};

export const useDeleteB2bConfigCorporateBranding = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ corporateId }: { corporateId: string }) => {
      return b2bConfigApiClient.corporateDeleteB2bConfigCorporateBranding({
        corporateId: corporateId,
      });
    },
    {
      onSuccess: (_, { corporateId }) => {
        queryClient.invalidateQueries(['b2bConfigs', corporateId]);
      },
    },
  );
};
