import { GetCorporateResponse, SmsNotificationEventEnum } from '@creditornot/wd-api-client';
import { IntlFormatters, useIntl } from 'react-intl';
import { Textarea } from '@creditornot/cb-input';
import { InputContainer } from '@creditornot/cb-form';
import styled from 'styled-components';
import { Alert } from '@creditornot/cb-alert';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Tag } from '@creditornot/cb-tag';
import { pxToRem } from '@creditornot/cb-utils';
import * as yup from 'yup';
import { ReactNode, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { processError } from 'utils';
import { FormCard, FormRow, FormSaveCancelButtons, FormStates } from 'components/FormCard';
import { useWoltForm } from 'modules/react-hook-form/useWoltForm';

import { replaceStringWithJSX } from '../utils';
import { SmsNotificationsFormValues } from './types';
import { SmsMessagePreview } from './SmsMessagePreview';

type InformationalViewProps = {
  requiredErrorMessage: string | null;
  message?: string;
};

const messageValidationYup = (formatMessage: IntlFormatters<ReactNode>['formatMessage']) =>
  yup
    .string()
    .defined()
    .test(
      'isValid',
      formatMessage({ id: 'deliveries.settings-form.tracking-link-missing' }),
      (value) => !value || value?.match(/^.*TRACKING_LINK.*$/) !== null,
    );

const smsNotificationsYup = (
  formatMessage: IntlFormatters<ReactNode>['formatMessage'],
): yup.ObjectSchema<SmsNotificationsFormValues> =>
  yup.object().shape(
    {
      receivedMessage: messageValidationYup(formatMessage).when('pickedupMessage', {
        is: (val: string) => !val,
        then: (schema) => schema.required(),
      }),
      pickedupMessage: messageValidationYup(formatMessage).when('receivedMessage', {
        is: (val: string) => !val,
        then: (schema) => schema.required(),
      }),
    },
    [['receivedMessage', 'pickedupMessage']],
  );

const StyledTextAreaContainer = styled(InputContainer)`
  margin-bottom: 4px;
`;

const StyledSmsMessagePreview = styled(SmsMessagePreview)`
  margin-top: 23px;
`;

const StyledAlert = styled(Alert)`
  margin-top: 8px;
`;

const Container = styled.div`
  ${typographyCss.Body3()}
  display: flex;
  min-height: ${pxToRem(36)};
  margin-inline-end: 16px;
`;

const Text = styled.span`
  ${typographyCss.Body3()}
`;

const InformationalView = ({ message, requiredErrorMessage }: InformationalViewProps) => {
  if (requiredErrorMessage) {
    return (
      <Container>
        <Alert variant="error" size="small">
          {requiredErrorMessage}
        </Alert>
      </Container>
    );
  }
  return (
    <Container>
      <Text>
        {message
          ? replaceStringWithJSX(
              message,
              'TRACKING_LINK',
              <Tag size="tiny" variant="lightBlack">
                TRACKING_LINK
              </Tag>,
            )
          : '-'}
      </Text>
    </Container>
  );
};

export const SmsNotificationsForm = ({
  selfServiceCorporate,
  renderState,
  onSubmit,
  onRenderStateChange,
}: {
  selfServiceCorporate: GetCorporateResponse;
  renderState: FormStates;
  onSubmit: (values: SmsNotificationsFormValues) => Promise<void>;
  onRenderStateChange: (state: FormStates) => void;
}) => {
  const { formatMessage } = useIntl();

  const pickedUpMessage = selfServiceCorporate.smsNotifications?.find(
    (sms) => sms.event === SmsNotificationEventEnum.PickedUp,
  );

  const receivedMessage = selfServiceCorporate.smsNotifications?.find(
    (sms) => sms.event === SmsNotificationEventEnum.Received,
  );

  const {
    register,
    formState: { errors, isDirty, isSubmitting },
    watch,
    reset,
    handleSubmit,
  } = useWoltForm<SmsNotificationsFormValues>({
    resolver: yupResolver(smsNotificationsYup(formatMessage)),
    defaultValues: {
      pickedupMessage: pickedUpMessage?.messageText ?? '',
      receivedMessage: receivedMessage?.messageText ?? '',
    },
  });

  const [apiError, setApiError] = useState<unknown | null>(null);

  const [watchReceivedMessage, watchPickedupMessage] = watch([
    'receivedMessage',
    'pickedupMessage',
  ]);

  const isNoMessageSet = !pickedUpMessage && !receivedMessage;

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        setApiError(null);
        try {
          await onSubmit(data);
          reset(data);
          setApiError(null);
        } catch (error) {
          setApiError(processError(error));
        }
      })}
    >
      <FormCard
        title={formatMessage({
          id: 'deliveries.settings-form.sms-notifications-section-title',
        })}
        renderState={renderState}
        onRenderStateChange={onRenderStateChange}
      >
        <FormRow
          title={formatMessage({
            id: 'deliveries.settings-form.sms-confirmation',
          })}
        >
          <FormRow.ViewMode>
            <InformationalView
              message={receivedMessage?.messageText}
              requiredErrorMessage={
                isNoMessageSet
                  ? formatMessage({
                      id: 'validation.please-add-confirmation-sms-notification',
                    })
                  : null
              }
            />
          </FormRow.ViewMode>
          <FormRow.EditMode>
            <>
              <StyledTextAreaContainer
                label={formatMessage({ id: 'deliveries.settings-form.customize-your-message' })}
                labelHtmlFor="receivedMessage"
              >
                <Textarea
                  {...register('receivedMessage')}
                  name="receivedMessage"
                  maxLength={200}
                  placeholder={formatMessage({
                    id: 'deliveries.settings-form.sms-notification-confirmation-message-placeholder',
                  })}
                  invalid={!!errors.receivedMessage}
                />
              </StyledTextAreaContainer>
              {watchReceivedMessage && <StyledSmsMessagePreview message={watchReceivedMessage} />}
              {errors.receivedMessage && (
                <StyledAlert variant="error" size="small">
                  {errors.receivedMessage.message}
                </StyledAlert>
              )}
            </>
          </FormRow.EditMode>
        </FormRow>
        <FormRow
          title={formatMessage({
            id: 'deliveries.settings-form.sms-pickup',
          })}
        >
          <FormRow.ViewMode>
            <InformationalView
              message={pickedUpMessage?.messageText}
              requiredErrorMessage={
                isNoMessageSet
                  ? formatMessage({
                      id: 'validation.please-add-confirmation-sms-notification',
                    })
                  : null
              }
            />
          </FormRow.ViewMode>
          <FormRow.EditMode>
            <>
              <StyledTextAreaContainer
                label={formatMessage({ id: 'deliveries.settings-form.customize-your-message' })}
                labelHtmlFor="pickedupMessage"
              >
                <Textarea
                  {...register('pickedupMessage')}
                  name="pickedupMessage"
                  maxLength={200}
                  placeholder={formatMessage({
                    id: 'deliveries.settings-form.sms-notification-confirmation-message-placeholder',
                  })}
                  invalid={!!errors.pickedupMessage}
                />
              </StyledTextAreaContainer>
              {watchPickedupMessage && <StyledSmsMessagePreview message={watchPickedupMessage} />}
              {errors.pickedupMessage && (
                <StyledAlert variant="error" size="small">
                  {errors.pickedupMessage.message}
                </StyledAlert>
              )}
            </>
          </FormRow.EditMode>
        </FormRow>
        {!!apiError && (
          <Alert variant="error" size="medium" title={processError(apiError).message}>
            {processError(apiError).data}
          </Alert>
        )}
        <FormRow>
          <FormRow.EditMode>
            <FormSaveCancelButtons
              dirty={isDirty}
              isSubmitting={isSubmitting}
              onCancelClick={() => {
                setApiError(null);
                onRenderStateChange('view');
                reset();
              }}
            />
          </FormRow.EditMode>
        </FormRow>
      </FormCard>
    </form>
  );
};
