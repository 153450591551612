import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCorporate } from 'modules/corporates';

import { inviteExistingMembersToSubscription, inviteSubscriptionMembersByEmail } from './api';

type PropsWithEmails = {
  subscriptionId: string;
  onSuccess?: () => void;
  emails: Array<string>;
  invalidateDelay?: number;
};

type PropsWithUserIds = {
  subscriptionId: string;
  onSuccess?: () => void;
  userIds: Array<string>;
  invalidateDelay?: number;
};

type Props = PropsWithEmails | PropsWithUserIds;

export const useInviteSubscriptionMembers = () => {
  const { data: corporate } = useCorporate();
  const queryClient = useQueryClient();

  return useCallback(
    async (props: Props) => {
      if (!corporate?.id) {
        return Promise.resolve(null);
      }
      if ('emails' in props) {
        await inviteSubscriptionMembersByEmail(corporate.id, props.subscriptionId, props.emails);
      } else if ('userIds' in props) {
        await inviteExistingMembersToSubscription(
          corporate.id,
          props.subscriptionId,
          props.userIds,
        );
      }

      setTimeout(() => {
        queryClient.invalidateQueries();
      }, props.invalidateDelay ?? 0);
      props.onSuccess?.();
    },
    [corporate?.id, queryClient],
  );
};
