import { MoreHorizontal } from '@creditornot/cb-icons';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { typographyCss } from '@creditornot/cb-ingredients';
import { IconButton } from '@creditornot/cb-button';

import { DataCellContent, DataList, EmptyState } from 'components';
import DropdownActionButton from 'components/DropdownButton';
import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';
import { SubscriptionUser } from 'modules/subscription/types';

import { SubscriptionUserTag } from './SubscriptionUserTag';

interface Props {
  data?: SubscriptionUser[] | null;
  loading: boolean;
  error: any;
  dataItemProps: {
    onOpenDeleteUserModal: (id: string) => void;
  };
  selectedUsers: string[];
  onUserSelectionClick: (selectedUsers: string[]) => void;
}

interface DataItemProps {
  user: SubscriptionUser;
  selectedUsersLength: number;
  onOpenDeleteUserModal: (id: string) => void;
}

const DataItemRoot = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledStatus = styled(SubscriptionUserTag)`
  margin-inline-start: 8px;
`;

const Row = styled.div`
  ${typographyCss.Body3()};
  display: flex;
  flex-flow: column wrap;
  flex: 1;
  gap: 4px;
  justify-content: center;
  padding-inline-end: 36px;
  position: relative;
`;

const StyledDropdownActionButton = styled(DropdownActionButton)`
  position: absolute;
  ${cssDirectionalValue({ ltr: 'right: 0;', rtl: 'left: 0;' })}
`;

const DataItem = ({ user, selectedUsersLength, onOpenDeleteUserModal }: DataItemProps) => {
  return (
    <DataItemRoot>
      <Row>
        <DataCellContent subContent={user.email}>
          {`${user.first_name} ${user.last_name}`}
          <StyledStatus status={user.status} />
        </DataCellContent>
        {!selectedUsersLength && (
          <StyledDropdownActionButton
            showCaret={false}
            placement="bottom-end"
            actions={[
              {
                label: <FormattedMessage id={'common.delete'} />,
                onClick: () => onOpenDeleteUserModal(user.user_id),
              },
            ]}
          >
            <IconButton background="animated" size="small" variant="black">
              <MoreHorizontal />
            </IconButton>
          </StyledDropdownActionButton>
        )}
      </Row>
    </DataItemRoot>
  );
};

export const SubscriptionUserDataList = ({
  data,
  loading,
  error,
  dataItemProps,
  selectedUsers,
  onUserSelectionClick,
}: Props) => {
  const { formatMessage } = useIntl();
  return (
    <DataList
      data={data}
      loading={loading}
      error={error}
      getDataItemId={(dataItem) => dataItem.user_id}
      renderDataItemProps={dataItemProps}
      selectedDataItems={selectedUsers}
      onDataItemSelectionClick={(_, selectedUsers) => onUserSelectionClick(selectedUsers)}
      noData={<EmptyState icon="user">{formatMessage({ id: 'wolt-plus.no-members' })}</EmptyState>}
      renderDataItem={(user, { onOpenDeleteUserModal }) => (
        <DataItem
          user={user}
          onOpenDeleteUserModal={onOpenDeleteUserModal}
          selectedUsersLength={selectedUsers.length}
        />
      )}
    />
  );
};
