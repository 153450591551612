import { useRouteMatch } from 'react-router';

import { useIsOnInternalOrLoginPage } from 'modules/hooks';

import { useFetchCorporate } from './useFetchCorporate';

export const useCorporate = () => {
  const isOnInternalOrLoginPage = useIsOnInternalOrLoginPage();
  const match = useRouteMatch<{
    corporateId: string;
  }>('/:corporateId');
  const id =
    !isOnInternalOrLoginPage && match?.params.corporateId ? match.params.corporateId : null;
  return useFetchCorporate(id);
};
