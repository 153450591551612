import { color } from '@creditornot/cb-ingredients/design-tokens';
import { Alert } from '@creditornot/cb-alert';
import { typographyCss } from '@creditornot/cb-ingredients';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

const Root = styled.div<Pick<Props, 'borderBottom'>>`
  display: flex;
  flex-direction: column;

  & + & {
    padding-top: 16px;
  }

  ${({ borderBottom }) =>
    borderBottom &&
    css`
      padding-bottom: 16px;
      border-bottom: 1px solid ${color.border};
    `}
`;
const labelStylesRecord = {
  grey: css`
    color: ${color.textSubdued};
  `,
  black: css`
    color: ${color.text};
  `,
};

const Label = styled.div<Pick<Props, 'labelColor'>>`
  ${typographyCss.Body3()}
  display: block;
  user-select: none;
  ${({ labelColor = 'grey' }) => labelStylesRecord[labelColor]};
`;

const InfoText = styled.span`
  ${typographyCss.Body3()}
  margin-top: 4px;
  display: block;
`;

const StyledAlert = styled(Alert)`
  margin-top: 8px;
`;

interface Props {
  label?: React.ReactNode;
  labelColor?: 'black' | 'grey';
  labelStyle?: CSSProperties;
  borderBottom?: boolean;
  explanation?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
}

const InformationDisplay = ({
  label,
  explanation,
  className,
  children,
  labelColor = 'grey',
  labelStyle,
  borderBottom = false,
}: Props) => {
  return (
    <Root borderBottom={borderBottom} className={className}>
      {label && (
        <Label labelColor={labelColor} style={labelStyle}>
          {label}
        </Label>
      )}
      <InfoText>{children}</InfoText>
      {explanation && (
        <StyledAlert variant="info" size="small">
          {explanation}
        </StyledAlert>
      )}
    </Root>
  );
};

export default InformationDisplay;
