import { FormRowV2, FormikInputField } from 'components';
import { createValidator, isNumber, isRequired } from 'validation';

interface Props {
  alwaysShowError?: boolean;
}

interface CreditLimitProps {
  alwaysShowError?: boolean;
  currency?: string;
}

const validate = createValidator([isRequired, isNumber]);

export const DefaultCreditLimitField = ({ alwaysShowError, currency }: CreditLimitProps) => {
  return (
    <FormRowV2
      htmlFor="default_corporate_credit_limit_amount"
      title={`Default credit limit (${currency})`}
      content={
        <FormikInputField
          name="default_corporate_credit_limit_amount"
          type="text"
          required={false}
          alwaysShowError={alwaysShowError}
          validate={isNumber}
        />
      }
    />
  );
};

export const DefaultPaymentTermDelayField = ({ alwaysShowError }: Props) => {
  return (
    <FormRowV2
      htmlFor="default_corporate_payment_term_delay_days"
      title="Default payment due in (days)"
      content={
        <FormikInputField
          name="default_corporate_payment_term_delay_days"
          type="text"
          required={true}
          validate={validate}
          alwaysShowError={alwaysShowError}
        />
      }
    />
  );
};

export const DefaultInvoiceFeeField = ({ alwaysShowError }: Props) => {
  return (
    <FormRowV2
      htmlFor="default_corporate_invoice_fee_percentage"
      title="Default service fee (%)"
      content={
        <FormikInputField
          name="default_corporate_invoice_fee_percentage"
          type="text"
          required={true}
          validate={validate}
          alwaysShowError={alwaysShowError}
        />
      }
    />
  );
};

export const DefaultOverdueInterestField = ({ alwaysShowError }: Props) => {
  return (
    <FormRowV2
      htmlFor="default_corporate_invoice_overdue_interest"
      title="Default overdue interest rate (%)"
      content={
        <FormikInputField
          name="default_corporate_invoice_overdue_interest"
          type="text"
          required={true}
          validate={validate}
          alwaysShowError={alwaysShowError}
        />
      }
    />
  );
};
