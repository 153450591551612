import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuLinkItem } from '@creditornot/cb-menu';
import { CaretDown, CaretUp } from '@creditornot/cb-icons';
import styled from 'styled-components';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { TextButton } from '@creditornot/cb-button';
import type { User } from '@creditornot/wolt-auth';
import { typographyCss } from '@creditornot/cb-ingredients';
import { FormattedMessage, useIntl } from 'react-intl';

import { ApiErrorNotification } from 'components';
import { useCorporate, useFetchCorporates } from 'modules/corporates';
import { Role } from 'modules/users/types';
import { useMedia } from 'modules/media';

interface Props {
  corporateRoles: Role[] | null;
  selectedUser: User | null;
}

const Item = styled.div`
  margin-top: 8px;
`;

const Content = styled.div`
  max-height: 200px;
  overflow-y: auto;

  /* sideBar - sideBarPadding - (caret + caretMargin) */
  width: 252px;
`;

const Text = styled.span`
  ${typographyCss.Body3()}
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  /* sideBar - sideBarPadding - (caret + caretMargin) */
  max-width: 252px;
`;

const RoleBasedCorporateSelect: React.FC<Props> = () => {
  const { formatMessage } = useIntl();
  const { data: corporate } = useCorporate();
  const history = useHistory();
  const mediumView = useMedia('medium');
  const [show, setShow] = useState(false);
  const { data: corporatesData, error } = useFetchCorporates();

  const handleCorporateChange = useCallback(
    (corporateId: string) => {
      if (!corporatesData?.length || !corporateId) {
        return;
      }

      history.push(`/${corporateId}`);
      setTimeout(() => setShow(false), 50);
    },
    [corporatesData, history],
  );

  return (
    <Popover
      contentZIndex={999}
      show={show}
      onClickOutside={() => setShow(false)}
      showOnHover={false}
      placement={mediumView ? 'bottom' : 'bottom-start'}
      renderArrow={false}
      content={
        <Content>
          {!!error && (
            <Item>
              <ApiErrorNotification error={error} />
            </Item>
          )}

          {!corporatesData?.length && (
            <Item>
              <FormattedMessage id="navigation.corporate-select.no-options" />
            </Item>
          )}

          {corporatesData?.map(({ name, id }) => {
            return (
              <Item key={id}>
                <MenuLinkItem
                  active={corporate?.id === id}
                  onClick={() => handleCorporateChange(id)}
                >
                  {name}
                </MenuLinkItem>
              </Item>
            );
          })}
        </Content>
      }
    >
      <TextButton
        onClick={() => setShow((state) => !state)}
        variant="black"
        iconPosition="right"
        icon={show ? <CaretUp /> : <CaretDown />}
      >
        <Text>
          {corporate?.name || formatMessage({ id: 'navigation.corporate-select.placeholder' })}
        </Text>
      </TextButton>
    </Popover>
  );
};

export default RoleBasedCorporateSelect;
