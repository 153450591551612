import { useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from '@creditornot/cb-button';

import { View } from 'components';
import { LocalizedMessage } from 'i18n';
import { useMedia } from 'modules/media';
import { CREATE_POLICY_BUTTON_SELECTOR } from 'onboarding/selectors';
import { useFetchPolicies } from 'modules/policies';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { policiesView } from 'telemetry/Avo';

import PoliciesDataTable from './PoliciesDataTable';
import PoliciesDataList from './PoliciesDataList';
import { DeletePolicyModal } from './DeletePolicyModal';

const PoliciesView = ({
  match,
  history,
}: RouteComponentProps<{ corporateId: string; policyId: string }>) => {
  const mediumViewport = useMedia('medium');

  const { data: policiesData, isLoading: isLoadingPoliciesData, error } = useFetchPolicies();

  const hanldeEditPolicyModalOpen = useCallback(
    (policyId: string) =>
      history.push(`${history.location.pathname}/${policyId}${history.location.search}`),
    [history],
  );

  const handleCreatePolicyModalOpen = useCallback(
    () => history.push(`${history.location.pathname}/new${history.location.search}`),
    [history],
  );

  const handleDeletePolicyModalOpen = useCallback(
    (policyId: string) =>
      history.push(`${history.location.pathname}/delete/${policyId}${history.location.search}`),
    [history],
  );

  const handleModalClose = useCallback(() => {
    history.push(`/${match.params.corporateId}/policies${history.location.search}`);
  }, [history, match.params.corporateId]);

  return (
    <>
      <ViewEventComponent event={policiesView} />
      <View
        title={<LocalizedMessage messageKey="views.policies" />}
        subtitle={<LocalizedMessage messageKey="policies.explanation" />}
        rightContent={
          <Button
            data-test-id="create-new-policy-button"
            variant="blue"
            size="small"
            data-product-tour={CREATE_POLICY_BUTTON_SELECTOR}
            onClick={handleCreatePolicyModalOpen}
          >
            <LocalizedMessage messageKey="policies.new-policy-button-title" />
          </Button>
        }
      >
        {mediumViewport ? (
          <PoliciesDataList
            data={policiesData ?? null}
            loading={isLoadingPoliciesData}
            error={error}
            dataItemProps={{
              onOpenEditPolicyModal: hanldeEditPolicyModalOpen,
              onOpenDeletePolicyModal: handleDeletePolicyModalOpen,
            }}
            onCreatePolicyClick={handleCreatePolicyModalOpen}
          />
        ) : (
          <PoliciesDataTable
            data={policiesData ?? null}
            loading={isLoadingPoliciesData}
            error={error}
            dataCellProps={{
              onOpenEditPolicyModal: hanldeEditPolicyModalOpen,
              onOpenDeletePolicyModal: handleDeletePolicyModalOpen,
            }}
            onCreatePolicyClick={handleCreatePolicyModalOpen}
          />
        )}

        <DeletePolicyModal
          show={match.path === '/:corporateId/policies/delete/:policyId' && !!match.params.policyId}
          policyId={match.params.policyId}
          policy={policiesData?.find((policy) => policy.id === match.params.policyId)}
          onClose={handleModalClose}
        />
      </View>
    </>
  );
};

export default PoliciesView;
