import { Radio } from '@creditornot/cb-radio';
import styled from 'styled-components';
import { rem } from 'polished';
import { Controller, useFormContext } from 'react-hook-form';

import { useI18n } from 'i18n';

import { B2bConfigFormValues } from '../types';

type Props = {
  className?: string;
};

const Root = styled.div``;

const StyledRadio = styled(Radio)`
  display: block;
  margin-top: ${rem(8)};
`;

export const DiscountRadioFields = ({ className }: Props) => {
  const { getLocalizedMessage } = useI18n();
  const { control } = useFormContext<B2bConfigFormValues>();
  return (
    <Controller
      control={control}
      name="pricing.discounts.type"
      render={({ field: { onChange, value, onBlur, name } }) => {
        return (
          <Root className={className}>
            {[
              {
                value: 'noDiscount',
                checked: value === 'noDiscount',
                message: getLocalizedMessage('corporate-form.delivery-discount.no-discount'),
              },

              {
                value: 'flat',
                checked: value === 'flat',
                message: getLocalizedMessage('corporate-form.delivery-discount.flat-amount'),
              },
              {
                value: 'percentage',
                checked: value === 'percentage',
                message: getLocalizedMessage('corporate-form.delivery-discount.percentage-amount'),
              },
            ].map((radio) => (
              <StyledRadio
                key={radio.value}
                value={radio.value}
                checked={radio.checked}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
              >
                {radio.message}
              </StyledRadio>
            ))}
          </Root>
        );
      }}
    />
  );
};
