import * as t from 'io-ts';

import { restaurantApiAgent } from 'apiAgents';
import { User } from 'modules/users/types';
import { PaginatedData, ResponseStatus } from 'types';
import { logValidationError } from 'validation';

import { Group } from './types';

export type CreateGroupData = {
  name: string;
  description?: string;
};

export const createGroup = async (corporateId: string, createGroupData: CreateGroupData) => {
  const { data } = await restaurantApiAgent.post<Group>(
    `/v1/waw-api/corporates/${corporateId}/groups`,
    createGroupData,
  );
  logValidationError('createGroup', Group, data);
  return data;
};

export type EditGroupData = Partial<
  Pick<Group, 'active' | 'add_to_all' | 'name' | 'description'> & {
    policy_ids: string[];
  }
>;

export const editGroup = async (
  corporateId: string,
  groupId: string,
  editGroupData: EditGroupData,
) => {
  const { data } = await restaurantApiAgent.patch<Group>(
    `/v1/waw-api/corporates/${corporateId}/groups/${groupId}`,
    editGroupData,
  );
  logValidationError('editGroup', Group, data);
  return data;
};

export const deleteGroup = async (corporateId: string, groupId: string) => {
  const { data } = await restaurantApiAgent.delete<Group>(
    `/v1/waw-api/corporates/${corporateId}/groups/${groupId}`,
  );
  logValidationError('deleteGroup', ResponseStatus, data);
  return data;
};

export const fetchGroup = async (corporateId: string, groupId: string) => {
  const { data } = await restaurantApiAgent.get<Group>(
    `/v1/waw-api/corporates/${corporateId}/groups/${groupId}`,
  );
  logValidationError('fetchGroup', Group, data);
  return data;
};

export const fetchGroups = async (corporateId: string) => {
  const { data } = await restaurantApiAgent.get<Group[]>(
    `/v1/waw-api/corporates/${corporateId}/groups`,
  );
  logValidationError('fetchGroups', t.array(Group), data);
  return data;
};

export type FetchPaginatedGroupsParams = {
  page: number;
  search?: string;
  page_size: number;
};

export const fetchPaginatedGroups = async (
  corporateId: string,
  params: FetchPaginatedGroupsParams,
) => {
  const { data } = await restaurantApiAgent.get<PaginatedData<Group>>(
    `/v1/waw-api/corporates/${corporateId}/groups`,
    {
      params: {
        ...params,
        pagination: true,
      },
    },
  );
  logValidationError('fetchGroups', t.array(Group), data.results);
  return data;
};

export const fetchGroupUsers = async (corporateId: string, groupId: string) => {
  const { data } = await restaurantApiAgent.get<User[]>(
    `/v1/waw-api/corporates/${corporateId}/groups/${groupId}/users`,
  );
  logValidationError('fetchGroupUsers', t.array(User), data);
  return data;
};

export const addGroupUsers = async (corporateId: string, groupId: string, userIds: string[]) => {
  const { data } = await restaurantApiAgent.post(
    `/v1/waw-api/corporates/${corporateId}/groups/${groupId}/payment-methods`,
    {
      user_ids: userIds,
    },
  );
  return data;
};
