import { useState } from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import { Checkbox } from '@creditornot/cb-checkbox';

import { FormikTagInputField } from 'components';
import {
  createValidator,
  isEmail,
  isAListOfValidEmails,
  doesNotContainDuplicateEmails,
  isDuplicateEmail,
} from 'validation';
import { useI18n } from 'i18n';

interface Props {
  alwaysShowError?: boolean;
}

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 8px;
`;

const validate = createValidator([doesNotContainDuplicateEmails, isAListOfValidEmails]);

const validateTag = (value: string, index: number, values: string[]) =>
  isEmail(value) || isDuplicateEmail(value, index, values);

export const SendInvoiceCopyToEmailsField = ({ alwaysShowError }: Props) => {
  const { getLocalizedMessage } = useI18n();
  const [{ value }] = useField<string[] | null>('invoice_email_cc_recipients');
  const [showField, setShowField] = useState(!!value?.length);

  return (
    <>
      <StyledCheckbox checked={showField} onChange={() => setShowField((state) => !state)}>
        {getLocalizedMessage('corporate-form.send-copies-of-the-invoice-to-other-emails')}
      </StyledCheckbox>

      {showField && (
        <FormikTagInputField
          name="invoice_email_cc_recipients"
          placeholder="email1@example.com, email2@example.com, email3@example.com"
          validate={validate}
          validateTag={validateTag}
          alwaysShowError={alwaysShowError}
          separateValuesBy={/[\s|,|;|:]/}
          setTagValueByKeyCodes={[13, 32, 44, 58, 59]}
          tagInputProp={{
            styles: { minHeight: '64px' },
          }}
        />
      )}
    </>
  );
};
