import { useCallback, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

import { useCookieConsents } from 'modules/cookie-consents/CookieConsentsProvider';

/**
 * Returns previous value if the comparison function returns false.
 */
function usePreviousWhen<TValue>(
  value: TValue,
  shouldUpdate: (value: TValue, previousValue: TValue) => boolean,
) {
  const currentValue = useRef<TValue | undefined>(undefined);
  if (!currentValue.current || shouldUpdate(value, currentValue.current)) {
    currentValue.current = value;
  }
  return currentValue.current;
}

export const useIsTelemetryAllowed = () => {
  const { consents: cookieConsents } = useCookieConsents();
  return cookieConsents.analytics;
};

export const useTelemetryInteractionEvent = <T,>(interactionEvent: (props: T) => void) => {
  const isTelemetryAllowed = useIsTelemetryAllowed();

  return useCallback(
    (props: T) => {
      if (!isTelemetryAllowed) return;
      interactionEvent(props);
    },
    [isTelemetryAllowed, interactionEvent],
  );
};

export const useTelemetryViewEvent = (viewEvent: () => void) => {
  const isTelemetryAllowed = useIsTelemetryAllowed();
  // Only track view event when the view event actually changes
  // This prevents the view event from being called when the component is re-rendered
  const activeEvent = usePreviousWhen(viewEvent, (...props) => !isEqual(...props));

  useEffect(() => {
    return () => {
      if (!isTelemetryAllowed) return;
      activeEvent();
    };
  }, [isTelemetryAllowed, activeEvent]);

  useEffect(() => {
    if (!isTelemetryAllowed) return;

    const visibilityListener = () => {
      if (document.hidden) {
        activeEvent();
      }

      return;
    };

    document.addEventListener('visibilitychange', visibilityListener);
    return () => document.removeEventListener('visibilitychange', visibilityListener);
  }, [isTelemetryAllowed, activeEvent]);
};
