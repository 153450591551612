import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { Checkmark } from '@creditornot/cb-icons';
import { Button } from '@creditornot/cb-button';
import { typographyCss } from '@creditornot/cb-ingredients';
import Bugsnag from '@bugsnag/browser';

import { ApiErrorNotification, FormFooter } from 'components';
import { useCorporate } from 'modules/corporates';
import { LocalizedMessage } from 'i18n';
import { geocode, getLatLngFromGeocodingResult } from 'utils';
import { Policy } from 'modules/policies/types';
import { Corporate } from 'modules/corporates/types';
import { useEditPolicy, useFetchPolicy } from 'modules/policies';
import { LoadingState } from 'components/LoadingState';
import { breakpoints } from 'modules/media';

import { PolicyFormValues } from './types';
import PolicyFormFields from './PolicyFormFields';
import {
  formatPolicyDataToEditPolicyFormValues,
  formatPolicyFormValuesBeforeSending,
} from './formDataFormatters';

type EditPolicyFormProps = {
  title?: string;
  policyId: string;
  onSuccess?: (policy: Policy) => void;
  onBackClick?: () => void;
  className?: string;
};

type EditPolicyFormComponentProps = {
  policy: Policy;
  corporate: Corporate;
  title?: string;
  onBackClick?: () => void;
  className?: string;
  onSuccess?: (policy: Policy) => void;
};

const ApiErrorContainer = styled.div`
  margin: 24px;

  @media (max-width: ${breakpoints.medium}px) {
    margin: 24px 16px;
  }
`;

const Title = styled.div`
  ${typographyCss.Title1()}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 392px;
  margin-inline-end: 24px;

  @media (max-width: ${breakpoints.small}px) {
    flex-direction: column-reverse;
    gap: 12px;
    margin-inline-end: 0;
    width: unset;
  }
`;

const useInitialValues = (
  corporate: Corporate,
  policy: Policy,
  deps: Array<Policy | Corporate | null | undefined>,
) => {
  const [initialValues, setInitialValues] = useState<PolicyFormValues>(
    formatPolicyDataToEditPolicyFormValues(policy, corporate.currency),
  );

  const geocodeAddressAndSetDeliveryLocation = useCallback(
    async (address: string) => {
      const res = await geocode(address);
      const latLng = await getLatLngFromGeocodingResult(res[0]);

      if (!latLng) {
        Bugsnag.notify(
          `Address for corporate with id ${corporate.id} cannot be decoded: ${latLng}`,
        );
      }

      setInitialValues(formatPolicyDataToEditPolicyFormValues(policy, corporate.currency, latLng));
    },
    [corporate, policy],
  );

  useEffect(() => {
    geocodeAddressAndSetDeliveryLocation(
      [corporate?.address, corporate?.city].filter(Boolean).join(' '),
    )
      .catch(() => {
        return geocodeAddressAndSetDeliveryLocation(
          [corporate?.post_code, corporate?.city].filter(Boolean).join(' '),
        );
      })
      .catch(() => {
        return geocodeAddressAndSetDeliveryLocation([corporate?.city].filter(Boolean).join(' '));
      })
      .catch(() => {
        return geocodeAddressAndSetDeliveryLocation([corporate?.country].filter(Boolean).join(' '));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return initialValues;
};

const EditPolicyFormComponent = ({
  policy,
  title,
  onSuccess,
  onBackClick,
  className,
  corporate,
}: EditPolicyFormComponentProps) => {
  const editPolicy = useEditPolicy();
  const initialValues = useInitialValues(corporate, policy, [corporate, policy]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={async (values, actions) => {
        if (!corporate) {
          actions.setStatus({ error: 'Corporate is missing' });
          actions.setSubmitting(false);
          return;
        }
        try {
          const newPolicy = await editPolicy(
            policy.id,
            formatPolicyFormValuesBeforeSending(values, corporate.currency),
          );
          actions.setSubmitting(false);
          actions.setStatus({ submitSucceeded: true });
          onSuccess?.(newPolicy);
        } catch (error) {
          actions.setStatus({ error });
          actions.setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, status, isSubmitting, dirty, isValid }) => (
        <Form className={className}>
          {title && <Title>{title}</Title>}

          <PolicyFormFields currency={corporate.currency} corporateId={corporate.id} />

          {status?.error && (
            <ApiErrorContainer>
              <ApiErrorNotification error={status.error} />
            </ApiErrorContainer>
          )}

          <FormFooter
            hideBorder
            rightContent={
              <ButtonWrapper>
                <Button size="large" stretch onClick={onBackClick} variant="lightBlue">
                  <LocalizedMessage messageKey="common.back" />
                </Button>
                <Button
                  data-test-id="edit-policy.save-button"
                  size="large"
                  stretch
                  onClick={() => handleSubmit()}
                  variant="blue"
                  disabled={!dirty || !isValid || isSubmitting || status?.submitSucceeded}
                  loading={isSubmitting}
                  icon={status?.submitSucceeded && <Checkmark />}
                >
                  <LocalizedMessage messageKey="common.save" />
                </Button>
              </ButtonWrapper>
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export const EditPolicyForm = ({
  policyId,
  onSuccess,
  title,
  onBackClick,
  className,
}: EditPolicyFormProps) => {
  const { data: policy, error, isFetching, isLoading } = useFetchPolicy(policyId);
  const { data: corporate } = useCorporate();

  if (isFetching || isLoading) {
    return (
      <LoadingState>
        <LocalizedMessage messageKey="common.loading" />
      </LoadingState>
    );
  }

  if (error) {
    return (
      <ApiErrorContainer>
        <ApiErrorNotification error={error} />
      </ApiErrorContainer>
    );
  }

  if (!policy || !corporate) {
    return null;
  }
  return (
    <EditPolicyFormComponent
      className={className}
      policy={policy}
      onSuccess={onSuccess}
      corporate={corporate}
      title={title}
      onBackClick={onBackClick}
    />
  );
};
