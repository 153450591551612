import { useIntl } from 'react-intl';

import { ModalV2 } from 'components';
import { Event } from 'modules/events/types';

import { InviteEventUsersForm } from './InviteEventUsersForm';

interface Props {
  onCloseClick: () => void;
  onSuccess: () => void;
  show: boolean;
  eventData: Event;
}

export const InviteEventUsersModal: React.FC<Props> = ({
  onCloseClick,
  show,
  onSuccess,
  eventData,
}) => {
  const { formatMessage } = useIntl();
  return (
    <ModalV2
      onDismiss={onCloseClick}
      open={show}
      title={formatMessage({ id: 'events.invite-event-users-form.title' })}
      sizePreset={{
        desktop: 'centered:large',
        mobile: 'fullScreen',
      }}
    >
      <InviteEventUsersForm
        eventData={eventData}
        onCloseClick={onCloseClick}
        onSuccess={onSuccess}
      />
    </ModalV2>
  );
};
