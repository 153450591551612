import styled, { css } from 'styled-components';
import { ModalFooter } from '@creditornot/cbt-modal';

import { breakpoints } from 'modules/media';

export type FormFooterProps = {
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  className?: string;
  hideBorder?: boolean;
};

const Root = styled(ModalFooter)<{ hideBorder: boolean }>`
  button + button {
    margin-inline-start: 12px;
  }

  min-height: auto;

  @media (max-width: ${breakpoints.small}px) {
    display: block;
  }
  ${({ hideBorder }) =>
    hideBorder &&
    css`
      border-top: none;
    `}
`;

const RightContent = styled.div`
  margin-inline-start: auto;

  @media (max-width: ${breakpoints.small}px) {
    display: block;
    margin-inline-start: unset;
    width: 100%;

    button {
      width: 100%;
    }

    button + button {
      margin-inline-start: unset;
      margin-top: 12px;
    }
  }
`;

const LeftContent = styled.div`
  margin-inline-end: 16px;

  @media (max-width: ${breakpoints.small}px) {
    display: block;
    margin-inline-end: unset;
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const FormFooter = ({
  className,
  leftContent,
  rightContent,
  hideBorder = false,
}: FormFooterProps) => {
  return (
    <Root className={className} hideBorder={hideBorder}>
      {leftContent && <LeftContent>{leftContent}</LeftContent>}
      {rightContent && <RightContent>{rightContent}</RightContent>}
    </Root>
  );
};
