import * as yup from 'yup';
import { IntlFormatters } from 'react-intl';
import { ReactNode } from 'react';

import { B2bConfigFormValues, Discount, DistanceRange, B2bPricing } from './types';

type TrueYupContextOptions = yup.ValidateOptions<yup.AnyObject> & { index: number };

const emptyStringToUndefined = (value: string, originalValue: string | number) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return undefined;
  }
  return value;
};

const yupTransformedNumber = yup.number().transform(emptyStringToUndefined);

const discountYup: (t: IntlFormatters<ReactNode>) => yup.Schema<Discount> = (t) =>
  yup.object().shape({
    type: yup.string().oneOf(['noDiscount', 'percentage', 'flat']).required(),
    percentageValue: yupTransformedNumber
      .min(0, t.formatMessage({ id: 'validation.is_greater_than' }, { value: 0 }))
      .when('type', {
        is: 'percentage',
        then: (schema) => schema.required(),
      }),
    flatValue: yupTransformedNumber
      .min(0, t.formatMessage({ id: 'validation.is_greater_than' }, { value: 0 }))
      .when('type', {
        is: 'flat',
        then: (schema) => schema.required(),
      }),
  });
const pricingYup: (t: IntlFormatters<ReactNode>) => yup.Schema<B2bPricing> = (t) =>
  yup.object().shape({
    type: yup
      .string()
      .oneOf(['flatFee', 'distanceBased', 'custom'])
      .required(t.formatMessage({ id: 'validation.is_required' })),
    discounts: discountYup(t),
    customPricing: yup.object().shape({
      basePrice: yupTransformedNumber
        .required(t.formatMessage({ id: 'validation.is_required' }))
        .typeError(t.formatMessage({ id: 'validation.is_number' })),
      distanceRanges: yup
        .array(
          yup
            .object({
              distanceMin: yupTransformedNumber
                .integer(t.formatMessage({ id: 'validation.is_integer' }))
                .required(t.formatMessage({ id: 'validation.is_required' }))
                .typeError(t.formatMessage({ id: 'validation.is_integer' })),
              distanceMax: yupTransformedNumber
                .integer(t.formatMessage({ id: 'validation.is_integer' }))
                .required(t.formatMessage({ id: 'validation.is_required' }))
                .typeError(t.formatMessage({ id: 'validation.is_integer' })),
              fee: yupTransformedNumber
                .required(t.formatMessage({ id: 'validation.is_required' }))
                .typeError(t.formatMessage({ id: 'validation.is_number' })),
            })
            .test('Min distance test', '', ({ distanceMin }, context) => {
              const options = context.options as TrueYupContextOptions;
              const index = options.index;

              const isZero = distanceMin === 0;
              const isFirst = index === 0;

              if (isFirst && !isZero) {
                return context.createError({
                  path: `${context.path}.distanceMin`,
                  message: 'First from distance needs to be 0',
                });
              }
              if (!isFirst && distanceMin !== context.parent[index - 1].distanceMax) {
                return context.createError({
                  path: `${context.path}.distanceMin`,
                  message: 'Distance needs to be the same as latest max distance',
                });
              }
              return true;
            })
            .test('Max distance test', '', ({ distanceMin, distanceMax }, context) => {
              const options = context.options as TrueYupContextOptions;
              const index = options.index;
              const array = context.parent as DistanceRange[];

              const isLast = index === array.length - 1;

              if (isLast && distanceMax !== 0) {
                return context.createError({
                  path: `${context.path}.distanceMax`,
                  message: 'Last to distance has to be 0',
                });
              }
              if (!isLast && distanceMin >= distanceMax) {
                return context.createError({
                  path: `${context.path}.distanceMax`,
                  message: 'Distance has to be more then the minimum distance',
                });
              }
              return true;
            }),
        )
        .required(t.formatMessage({ id: 'validation.is_required' })),
    }),
  });

export const b2bConfigYup: (t: IntlFormatters<ReactNode>) => yup.Schema<B2bConfigFormValues> = (
  t,
) =>
  yup.object().shape({
    merchantId: yup.string().required(t.formatMessage({ id: 'validation.is_required' })),
    venueId: yup.string().required(t.formatMessage({ id: 'validation.is_required' })),
    isCashEnabled: yup.boolean().required(t.formatMessage({ id: 'validation.is_required' })),
    isIntercomEnabled: yup.boolean().required(t.formatMessage({ id: 'validation.is_required' })),
    isCustomDeliveryDistance: yup
      .string()
      .oneOf(['isCustom', 'isNotCustom'])
      .required(t.formatMessage({ id: 'validation.is_required' })),
    deliveryDistance: yupTransformedNumber
      .integer(t.formatMessage({ id: 'validation.is_integer' }))
      .when('isCustomDeliveryDistance', {
        is: 'isCustom',
        then: (schema) => schema.min(1).required(),
      }),
    pricing: pricingYup(t),
  });
