import { useReducer } from 'react';

export interface UseSortedSetProps<Item> {
  getItemId: (item: Item) => string;
  getSortBy: (a: Item, b: Item) => 1 | -1;
}

export type State<Item> = Item[];

export type Action<Item> =
  | { type: 'ADD_ITEM'; payload: Item[] | null | undefined }
  | { type: 'CLEAR' };

const mergeRight = <Item extends Record<string, any>>({
  left,
  right,
  getItemId,
  getSortBy,
}: {
  left: Item[];
  right: Item[];
  getItemId: (item: Item) => string;
  getSortBy: (a: Item, b: Item) => 1 | -1 | 0;
}): Item[] => {
  if (!right.length || !left.length) {
    return [...left, ...right];
  }

  const [firstLeft, ...restLeft] = left;
  const [firstRight, ...restRight] = right;

  const isRightSideHasNewValue = getItemId(firstRight) === getItemId(firstLeft);

  if (isRightSideHasNewValue) {
    return [firstRight, ...mergeRight({ left: restLeft, right: restRight, getItemId, getSortBy })];
  }

  if (getSortBy(firstLeft, firstRight) === -1) {
    return [firstLeft, ...mergeRight({ left: right, right: restLeft, getItemId, getSortBy })];
  }

  return [firstRight, ...mergeRight({ left, right: restRight, getItemId, getSortBy })];
};

const createDataReducer =
  <Item extends Record<string, any>>(
    getItemId: (item: Item) => string,
    getSortBy: (a: Item, b: Item) => 1 | -1,
  ) =>
  (state: State<Item>, actions: Action<Item>) => {
    switch (actions.type) {
      case 'ADD_ITEM':
        return mergeRight({
          left: state,
          right: (actions.payload || []).sort(getSortBy),
          getItemId,
          getSortBy,
        });
      case 'CLEAR':
        return [];

      default:
        return state;
    }
  };

export const useSortedSet = <Item extends Record<string, any>>({
  getItemId,
  getSortBy,
}: UseSortedSetProps<Item>) => {
  const dataReducer = createDataReducer(getItemId, getSortBy);

  const reducer = (state: State<Item>, actions: Action<Item>) => dataReducer(state, actions);

  const [data, dispatch] = useReducer(reducer, []);

  return {
    data,
    dispatch,
  };
};
