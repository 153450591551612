import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQueryClient } from 'modules/react-query';

import { removePolicyFromGroup } from './api';

export const useRemovePolicyFromGroup = () => {
  const queryClient = useQueryClient();
  const { data: corporate } = useCorporate();

  return useCallback(
    async (groupId: string, policyId: string) => {
      if (!corporate?.id) {
        return Promise.resolve();
      }
      await removePolicyFromGroup(corporate.id, groupId, policyId);
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 500);
    },
    [corporate?.id, queryClient],
  );
};
