import { corporateServiceApiAgent, restaurantApiAgent } from 'apiAgents';
import { logValidationError } from 'validation';

import { PurchaseSummary, Summary } from './types';

export const fetchSummary = async (corporateId: string) => {
  const { data } = await restaurantApiAgent.get<Summary>(
    `/v1/waw-api/corporates/${corporateId}/summary`,
  );
  logValidationError('fetchSummary', Summary, data);
  return data;
};

export type FetchPurchaseAmountData = {
  received_after: string;
  received_before: string;
};

export const fetchCorporatePurchaseStatistics = async (
  corporateId: string,
  startDate: Date,
  endDate: Date,
) => {
  const { data } = await corporateServiceApiAgent.get<PurchaseSummary>(
    `/portal-api/v1/corporates/${corporateId}/statistics`,
    { params: { period_start: startDate, period_end: endDate } },
  );
  logValidationError('fetchSummary', PurchaseSummary, data);
  return data;
};
