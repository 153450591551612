import { color } from '@creditornot/cb-ingredients/design-tokens';
import { colors } from '@creditornot/cb-ingredients';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import * as turf from '@turf/turf';
import {
  Layer,
  Map,
  MapRef,
  Marker,
  MarkerDragEvent,
  NavigationControl,
  Source,
} from 'react-map-gl';
import styled from 'styled-components';

import { env } from 'config';
import { useThemeMode } from 'modules/waw-theme/ThemeProvider';
import { calculateZoomLevel } from 'components/CoordinatesPicker/utils';
import { LocationMarkerPin } from 'components/CoordinatesPicker/LocationMarkerPin';
import { useLanguageDirection } from 'i18n';

import { VenueMarkerPin } from './VenueMarkerPin';

export type Coordinates = {
  lat: number;
  lng: number;
};

export type CoordinatesPickerProps = {
  location: Coordinates;
  onLocationChange?: (e: MarkerDragEvent) => void;
  /** Marker radius in meters */
  radius?: number;
  style?: CSSProperties;
  venueLocations?: Coordinates[];
  disableMarkerDrag?: boolean;
};

type RadiusProps = {
  radius: number;
  marker: Coordinates;
};

const MapContainer = styled.div`
  width: 100%;
  height: 100%;

  .mapboxgl-ctrl-group {
    color: ${color.text};
    cursor: pointer;
    background: ${color.bg};
    border: none;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 ${colors.pepper12};

    > button {
      border: none;
    }

    .mapboxgl-ctrl-zoom-in > .mapboxgl-ctrl-icon {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 16 16"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 8C0 8.55228 0.447715 9 1 9H6.83333C6.92538 9 7 9.07462 7 9.16667V15C7 15.5523 7.44772 16 8 16C8.55228 16 9 15.5523 9 15V9.16667C9 9.07462 9.07462 9 9.16667 9H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H9.16667C9.07462 7 9 6.92538 9 6.83333V1C9 0.447715 8.55228 0 8 0C7.44772 0 7 0.447715 7 1V6.83333C7 6.92538 6.92538 7 6.83333 7H1C0.447715 7 0 7.44772 0 8Z"/></svg>');
    }

    .mapboxgl-ctrl-zoom-out > .mapboxgl-ctrl-icon {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" viewBox="0 0 16 2" ><path fill-rule="evenodd" clip-rule="evenodd" d="M1 2.00024H15C15.5523 2.00024 16 1.55253 16 1.00024C16 0.447959 15.5523 0.000244141 15 0.000244141H1C0.447715 0.000244141 0 0.447959 0 1.00024C0 1.55253 0.447715 2.00024 1 2.00024Z"/></svg>');
    }
  }
`;

const StyledLocationMarkerPin = styled(LocationMarkerPin)`
  display: block;
  height: 40px;
  width: 40px;
`;

const StyledVenueMarkerPin = styled(VenueMarkerPin)`
  display: block;
`;

const DEFAULT_MAP_ZOOM = 14.3;

const MarkerRadius = ({ radius, marker }: RadiusProps) => {
  const circleData = turf.circle([marker.lng, marker.lat], radius);

  return (
    <Source type="geojson" data={circleData}>
      <Layer
        type="fill"
        paint={{
          'fill-color': colors.wolt100,
          'fill-opacity': 0.3,
        }}
      />
    </Source>
  );
};

export const CoordinatesPicker: React.FC<CoordinatesPickerProps> = ({
  location,
  onLocationChange,
  radius,
  venueLocations,
  disableMarkerDrag = false,
  style,
}) => {
  const mapRef = useRef<MapRef>(null);
  const direction = useLanguageDirection();
  const { themeMode } = useThemeMode();
  const [marker, setMarker] = useState(location);

  useEffect(() => {
    setMarker(location);
    mapRef.current?.easeTo({ center: [location.lng, location.lat], duration: 800 });
  }, [location]);

  return (
    <MapContainer>
      <Map
        ref={mapRef}
        mapStyle={
          themeMode === 'light'
            ? 'mapbox://styles/mapbox/light-v10'
            : 'mapbox://styles/mapbox/dark-v10'
        }
        mapboxAccessToken={env.MAPBOX_TOKEN}
        style={{ height: '100%', width: '100%', ...style }}
        initialViewState={{
          /*
            When radius is available, we're calculating the zoom level by adding extra distance to fit the radius circle to the map with enough padding.
            Example: if radius is 150m and we calculate the initial zoom level with 1200m distance, then the radius circle
                     will have the same relative size on the map as if we'd pass 1000m radius with 8000m distance
          */
          zoom: radius ? calculateZoomLevel(location.lat, 8 * radius) : DEFAULT_MAP_ZOOM,
          longitude: location.lng,
          latitude: location.lat,
        }}
        maxZoom={17}
      >
        <Marker
          style={{ zIndex: 5 }}
          latitude={marker.lat}
          longitude={marker.lng}
          anchor="bottom"
          draggable={!disableMarkerDrag}
          onDrag={(e) => setMarker(e.lngLat)}
          onDragEnd={onLocationChange}
        >
          <StyledLocationMarkerPin />
        </Marker>
        {!!radius && <MarkerRadius marker={marker} radius={radius / 1000} />}
        {venueLocations && (
          <>
            {venueLocations.map((venue, index) => (
              <Marker
                key={`venue-${index}`}
                latitude={venue.lat}
                longitude={venue.lng}
                anchor="bottom"
                draggable={false}
              >
                <StyledVenueMarkerPin />
              </Marker>
            ))}
          </>
        )}
        <NavigationControl
          position={direction === 'ltr' ? 'bottom-right' : 'bottom-left'}
          showCompass={false}
        />
      </Map>
    </MapContainer>
  );
};
