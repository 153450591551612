import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { PaginatedData } from 'types/PaginatedData';
import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchEventUsers, FetchEventUsersParams } from './api';
import { EventUser } from './types';

export const useFetchEventUsers = <T = PaginatedData<EventUser>>(
  eventId: string | null,
  params: FetchEventUsersParams,
  config?: UseQueryOptions<PaginatedData<EventUser>, unknown, T>,
) => {
  const { data: corporate } = useCorporate();
  const queryFn = useCallback(() => {
    if (!corporate || !eventId) {
      return Promise.reject('Corporate or eventId is missing');
    }
    return fetchEventUsers(corporate.id, eventId, params);
  }, [corporate, eventId, params]);
  return useQuery({
    queryKey: ['event-users', corporate?.id, eventId, params],
    queryFn,
    ...config,
  });
};
