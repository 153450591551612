import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled, { css } from 'styled-components';
import { colors, typographyCss } from '@creditornot/cb-ingredients';
import { Checkbox } from '@creditornot/cb-checkbox';
import { FormEventHandler } from 'react';

import { breakpoints } from 'modules/media';

interface CheckboxCardProps {
  name?: string;
  label: string;
  className?: string;
  onChange?: FormEventHandler<HTMLLabelElement>;
  width?: number;
  checked?: boolean;
  disabled?: boolean;
  stretched?: boolean;
}

interface RootProps {
  disabled: boolean;
  checked: boolean;
  width?: number;
  stretched: boolean;
}

const Root = styled.label<RootProps>`
  border: 1px solid;
  border-radius: 8px;
  box-shadow: 0 0 2px 0 ${colors.pepper12};
  padding: 12px 12px 15px;
  display: flex;
  align-items: center;
  transition: all 250ms ease;
  cursor: pointer;

  &:has(:focus-visible) {
    outline: none;
    box-shadow: 0 0 0 3px ${color.outlineFocused};
  }

  ${({ stretched, width }) => css`
    width: ${width ? `${width}px` : 'unset'};
    flex: ${stretched && 1};
  `}

  ${({ disabled, checked }) => {
    if (disabled) {
      return css`
        opacity: 0.8;
        cursor: not-allowed;
        border-color: ${color.border};
      `;
    } else if (checked) {
      return css`
        background-color: ${colors.wolt8};
        border-color: ${color.borderSelected};
      `;
    } else {
      return css`
        border-color: ${color.border};

        &:hover {
          box-shadow: 0 0 8px 0 ${colors.pepper12};
        }

        &:active {
          box-shadow: 0 0 2px 0 ${colors.pepper12};
        }
      `;
    }
  }}

  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
    margin-inline-end: 0;
    margin-bottom: 0;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline-start: 8px;
`;

const Text = styled.span`
  ${typographyCss.Body3()}
`;

export const CheckboxCard = ({
  name,
  label,
  className,
  disabled = false,
  checked = false,
  width,
  stretched = false,
  onChange,
}: CheckboxCardProps) => {
  return (
    <Root
      className={className}
      checked={checked}
      disabled={disabled}
      width={width}
      stretched={stretched}
      onChange={onChange}
    >
      {/* Since the Checkbox is controlled by the card component, this should be read-only */}
      <Checkbox
        readOnly
        name={name}
        checked={checked}
        disabled={disabled}
        inputProps={disabled ? undefined : { inputStyle: { cursor: 'pointer' } }}
      />
      <Body>
        <Text>{label}</Text>
      </Body>
    </Root>
  );
};
