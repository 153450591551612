import {
  endOfToday,
  startOfDay,
  sub,
  differenceInHours,
  differenceInMinutes,
  hoursToMinutes,
} from 'date-fns';

export const last30DaysDateRange = (): { from: Date; to: Date } => ({
  from: startOfDay(sub(new Date(), { days: 30 })),
  to: endOfToday(),
});

/**
 * @param laterDate
 * @param earlierDate
 * @returns hours difference, rounds to half hour
 */
export const differenceInHourRoundToHalfHour = (laterDate: Date, earlierDate: Date) => {
  const minutesToHour =
    differenceInMinutes(laterDate, earlierDate, { roundingMethod: 'ceil' }) -
    hoursToMinutes(differenceInHours(laterDate, earlierDate));

  if (minutesToHour <= 20) {
    // round down
    return differenceInHours(laterDate, earlierDate);
  }

  if (minutesToHour > 20 && minutesToHour <= 40) {
    // return hour left and a half
    return differenceInHours(laterDate, earlierDate) + 0.5;
  }

  if (minutesToHour > 40) {
    // round up
    return differenceInHours(laterDate, earlierDate, {
      roundingMethod: 'ceil',
    });
  }
};
