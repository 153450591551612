import { useCallback } from 'react';

import { useQueryClient } from 'modules/react-query';

import { editCountryConfig, EditCountryConfigData } from './api';

export const useEditCountryConfig = () => {
  const queryClient = useQueryClient();
  return useCallback(
    async (id: string, data: EditCountryConfigData) => {
      const result = await editCountryConfig(id, data);
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 500);
      return result;
    },
    [queryClient],
  );
};
