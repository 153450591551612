import * as t from 'io-ts';
import { pipe } from 'fp-ts/lib/pipeable';
import { fold } from 'fp-ts/lib/Either';
import { array } from 'fp-ts/lib/Array';
import { formatValidationError as ioTsformatValidationError } from 'io-ts-reporters';
import { unary } from 'ramda';

import { env } from 'config';

export const decodeData = <RuntimeValue, OutputValue, InputValue, Result>(
  input: InputValue,
  type: t.Type<RuntimeValue, OutputValue, InputValue>,
  onFailure: (errors: t.Errors) => Result,
  onSuccess: (runtime: RuntimeValue) => Result,
) => pipe(type.decode(input), fold(onFailure, onSuccess));

export const formatValidationError = (errors: t.Errors) =>
  array.compact(errors.map(unary(ioTsformatValidationError))).join('\n');

export function logValidationError<RuntimeValue, OutputValue, InputValue>(
  info: string,
  type: t.Type<RuntimeValue, OutputValue, InputValue>,
  data: InputValue,
) {
  const onFailure = (errors: t.Errors) => {
    const errorMessage = formatValidationError(errors);
    console.error(`${info}:\n${errorMessage}\nReceiving data: `, data);
  };
  if (env.NODE_ENV === 'development') {
    decodeData(data, type, onFailure, () => undefined);
  }
}
