import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';

import { EmptyState, InfiniteScrollDataList } from 'components';
import { Corporate } from 'modules/corporates/types';
import { LocalizedMessage } from 'i18n';
import { CorporateStatusTag } from 'modules/corporates/components';
import { formatCamelCaseToSnakeCase } from 'utils';
import { fetchCorporatesWithPagination } from 'modules/corporates/api';

import { useCorporatesQueryParams } from './useCorporatesQueryParams';

interface Props {
  className?: string;
  onDataItemClick: (corporate: Corporate) => void;
}

const DataItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  align-items: flex-start;
  color: ${color.text};
`;

const Container = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  flex: 1;
`;

const StatusContainer = styled.div`
  margin-top: 8px;
`;

const Title = styled.div`
  ${typographyCss.Title2()}
  color: ${color.textSubdued};
`;

const Subtitle = styled.span`
  ${typographyCss.Body3()}
  color: ${color.textSubdued};
`;

const renderDataItem = (dataItem: Corporate) => {
  return (
    <DataItem>
      <Container>
        <Title>{dataItem.name}</Title>
        <Subtitle>{dataItem.business_id}</Subtitle>
        {dataItem.products.daas_delivery_ui_enabled && (
          <Subtitle>
            {<LocalizedMessage messageKey="corporates.product-line.wolt-drive" />}
          </Subtitle>
        )}
        {dataItem.products.waw_enabled && (
          <Subtitle>
            <LocalizedMessage messageKey="corporates.product-line.wolt-at-work" />
          </Subtitle>
        )}
      </Container>
      <StatusContainer>
        <CorporateStatusTag status={dataItem.status} />
      </StatusContainer>
    </DataItem>
  );
};

const CorporatesDataList: React.FC<Props> = ({ onDataItemClick }) => {
  const {
    params: { country, search, corporate_status, products },
  } = useCorporatesQueryParams();
  const [page, setPage] = useState(1);

  const handleLoadDataSuccess = useCallback(() => setPage((p) => p + 1), []);
  const handleRefresh = useCallback(() => setPage(1), []);
  const loadCorporates = useCallback(
    () =>
      fetchCorporatesWithPagination({
        name: search,
        country_code: country,
        page,
        page_size: 20,
        statuses: corporate_status?.map(formatCamelCaseToSnakeCase),
        products: products?.map(formatCamelCaseToSnakeCase),
      })
        .then((data) => data.results)
        .catch((err) => err),
    [country, page, search, corporate_status, products],
  );

  return (
    <InfiniteScrollDataList
      renderDataItem={renderDataItem}
      getDataItemId={({ id }) => id}
      getSortBy={(d1, d2) => (d1.name > d2.name ? 1 : -1)}
      loadData={loadCorporates}
      onLoadDataSuccess={handleLoadDataSuccess}
      onRefresh={handleRefresh}
      noData={
        <EmptyState icon="policy">
          <LocalizedMessage messageKey="corporates.no-corporates" />
        </EmptyState>
      }
      clearDataOn={[country, search, corporate_status?.join(',') || '', products?.join(',') || '']}
      onDataItemClick={onDataItemClick}
      hideSpinner={!!search}
    />
  );
};

export default CorporatesDataList;
