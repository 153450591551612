import styled from 'styled-components';
import { TextButton } from '@creditornot/cb-button';
import { Note, VideoPlayer } from '@creditornot/cb-icons';
import { FormattedMessage } from 'react-intl';
import { typographyCss } from '@creditornot/cb-ingredients';

import createPolicyImageSrc from 'assets/images/create-policy.png';
import createCompanyEventsImageSrc from 'assets/images/create-company-events.png';
import invitePeopleImageSrc from 'assets/images/invite-people.png';
import invoicePaymentMethodImageSrc from 'assets/images/invoice-payment-method.png';
import multipaymentImageSrc from 'assets/images/multipayment.png';
import { useMedia } from 'modules/media';

import { Columns } from '../views/home/Columns';
import { InformationItem } from '../views/home/InformationItem';

type ResourcesColumnsProps = {
  className?: string;
  onEventTutorialVideoClick: () => void;
  onEventGuideClick: () => void;
  onOnboardUsersTutorialVideoClick: () => void;
  onOrderTutorialVideoClick: () => void;
  onOrderGuideClick: () => void;
  onMultipaymentGuideClick?: () => void;
};

const Image = styled.img`
  width: 100%;
  min-width: 200px;
  min-height: 180px;
  display: block;
  margin: auto;
`;

const Title = styled.span`
  ${typographyCss.Body3({ weight: 'bold' })}
  margin-top: 12px;
  display: block;
`;

const Content = styled.span`
  ${typographyCss.Body3()}
`;

const TextButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledTextButton = styled(TextButton)`
  display: block;
  text-align: start;
  white-space: normal;
`;

const Root = styled(Columns)`
  overflow: auto;
`;

const ResourcesColumns = ({
  className,
  onEventTutorialVideoClick,
  onOnboardUsersTutorialVideoClick,
  onOrderGuideClick,
  onOrderTutorialVideoClick,
  onEventGuideClick,
  onMultipaymentGuideClick,
}: ResourcesColumnsProps) => {
  const isMediumView = useMedia('medium');
  const isSmallView = useMedia('small');
  const columns = isSmallView ? 1 : isMediumView ? 2 : 4;

  return (
    <Root
      title={<FormattedMessage id="views.dashboard.resources" />}
      columns={columns}
      className={className}
    >
      {onMultipaymentGuideClick ? (
        <InformationItem
          topContent={
            <div>
              <Image src={multipaymentImageSrc} />
              <Title>
                <FormattedMessage id="views.dashboard.multipayment-resources" />
              </Title>
            </div>
          }
          content={
            <Content>
              <FormattedMessage id="views.dashboard.multipayment-resources-text" />
            </Content>
          }
          bottomContent={
            <StyledTextButton icon={<Note />} onClick={onMultipaymentGuideClick}>
              <FormattedMessage id="views.dashboard.multipayment-resources-cta" />
            </StyledTextButton>
          }
        />
      ) : (
        <InformationItem
          topContent={
            <div>
              <Image src={createPolicyImageSrc} />
              <Title>
                <FormattedMessage id="views.dashboard.policies-resources" />
              </Title>
            </div>
          }
          content={
            <Content>
              <FormattedMessage id="views.dashboard.policies-resources-text" />
            </Content>
          }
        />
      )}
      <InformationItem
        topContent={
          <div>
            <Image src={createCompanyEventsImageSrc} />
            <Title>
              <FormattedMessage id="views.dashboard.events-resources" />
            </Title>
          </div>
        }
        content={
          <Content>
            <FormattedMessage id="views.dashboard.events-resources-text" />
          </Content>
        }
        bottomContent={
          <TextButtonContainer>
            <StyledTextButton icon={<VideoPlayer />} onClick={onEventTutorialVideoClick}>
              <FormattedMessage id="views.dashboard.open-events-tutorial" />
            </StyledTextButton>
            <StyledTextButton icon={<Note />} onClick={onEventGuideClick}>
              <FormattedMessage id="views.dashboard.open-events-guide" />
            </StyledTextButton>
          </TextButtonContainer>
        }
      />
      <InformationItem
        topContent={
          <div>
            <Image src={invitePeopleImageSrc} />
            <Title>
              <FormattedMessage id="views.dashboard.invite-resources" />
            </Title>
          </div>
        }
        content={
          <Content>
            <FormattedMessage id="views.dashboard.invite-resources-text" />
          </Content>
        }
        bottomContent={
          <StyledTextButton icon={<VideoPlayer />} onClick={onOnboardUsersTutorialVideoClick}>
            <FormattedMessage id="views.dashboard.invite-resources-cta" />
          </StyledTextButton>
        }
      />
      <InformationItem
        topContent={
          <div>
            <Image src={invoicePaymentMethodImageSrc} />
            <Title>
              <FormattedMessage id="views.dashboard.place-order-resources" />
            </Title>
          </div>
        }
        content={
          <Content>
            <FormattedMessage id="views.dashboard.place-order-resources-text" />
          </Content>
        }
        bottomContent={
          <TextButtonContainer>
            <StyledTextButton icon={<VideoPlayer />} onClick={onOrderTutorialVideoClick}>
              <FormattedMessage id="views.dashboard.open-order-tutorial" />
            </StyledTextButton>
            <StyledTextButton icon={<Note />} onClick={onOrderGuideClick}>
              <FormattedMessage id="views.dashboard.place-order-resources-cta" />
            </StyledTextButton>
          </TextButtonContainer>
        }
      />
    </Root>
  );
};

export default ResourcesColumns;
