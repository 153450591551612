import { useI18n } from 'i18n';

type EventDateTimeProps = {
  dateTime: string;
};

export const EventDateTime = ({ dateTime }: EventDateTimeProps) => {
  const { getLocalizedDateTime } = useI18n();
  return (
    <>
      {getLocalizedDateTime(new Date(dateTime), {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })}
    </>
  );
};
