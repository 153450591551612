import { SegmentedControl } from '@creditornot/cb-segmented-control';
import { useIntl } from 'react-intl';

import { useReportsQueryParams } from '../hooks';

type Props = {
  className?: string;
};

export const ReportsSegmentedControl = ({ className }: Props) => {
  const { formatMessage } = useIntl();
  const [{ tabName }, setQueryParams] = useReportsQueryParams();
  const handleChange = (value: 'payouts' | 'invoices') => {
    setQueryParams({ tabName: value === 'payouts' ? 'payouts' : 'invoices' });
  };

  return (
    <SegmentedControl
      className={className}
      options={[
        {
          value: 'invoices',
          label: formatMessage({ id: 'reports.invoices' }),
        },
        {
          value: 'payouts',
          label: formatMessage({ id: 'reports.payouts' }),
        },
      ]}
      value={tabName === 'payouts' ? 'payouts' : 'invoices'}
      onChange={handleChange}
    />
  );
};
