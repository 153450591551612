import { GetCorporateResponse } from '@creditornot/wd-api-client';
import { useState } from 'react';

import { FormStates } from 'components/FormCard';
import { useEditSelfServiceCorporate } from 'modules/corporates';

import { CustomerSupportContactInformationForm } from './CustomerSupportContactInformationForm';
import { CustomerSupportContactInformationFormValues } from './types';

export const CustomerSupportContactInformationSettings = ({
  selfServiceCorporate,
  corporateId,
}: {
  selfServiceCorporate: GetCorporateResponse;
  corporateId: string;
}) => {
  const { mutateAsync: editCorporateSettings } = useEditSelfServiceCorporate();
  const [renderState, setRenderState] = useState<FormStates>('view');

  const submitHandler = async ({
    email,
    phoneNumber,
    url,
  }: CustomerSupportContactInformationFormValues) => {
    await editCorporateSettings({
      woltCorporateId: corporateId,
      body: {
        email,
        phoneNumber,
        url,
      },
    });
    setRenderState('view');
  };

  return (
    <CustomerSupportContactInformationForm
      renderState={renderState}
      selfServiceCorporate={selfServiceCorporate}
      onSubmit={submitHandler}
      onRenderStateChange={setRenderState}
    />
  );
};
