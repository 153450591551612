import { DateTimeParam, useQueryParams, withDefault } from 'modules/react-query-params';

import { last30DaysDateRange } from './utils';

export const useDeliveryStatisticsQueryParams = () => {
  const defaultDateRange = last30DaysDateRange();
  const query = useQueryParams({
    from: withDefault(DateTimeParam, defaultDateRange.from),
    to: withDefault(DateTimeParam, defaultDateRange.to),
  });
  const [params, setQueryParams] = query;
  return { params, setQueryParams };
};
