import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { FormHeader, FormRow, InputContainer } from '@creditornot/cb-form';
import { rem } from 'polished';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { LocalizedMessage, useI18n } from 'i18n';
import { breakpoints } from 'modules/media';
import {
  BillingCycleField,
  CreditLimitField,
  CustomerReferenceField,
  EDIOperatorId,
  ElectronicInvoicingAddress,
  InvoicingEmailField,
  IsElectronicInvoicingDetailsEnabledField,
  OverdueInterestField,
  PaymentDueInField,
  PreferredInvoiceLanguageField,
  SendInvoiceCopyToEmailsField,
  VATExemptionDisclaimer,
  VatIdField,
} from 'modules/corporates/components';
import { CountryConfig } from 'modules/country-configs/types';
import { useCountryConfig } from 'modules/country-configs';

import { CreateCorporateFormValues } from '../types';

interface Props {
  countryConfig: CountryConfig;
}

const Root = styled.div`
  border-bottom: 1px solid ${color.border};
`;

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: ${rem(24)};
  }
`;

const StyledFormRow = styled(FormRow)`
  @media (max-width: ${breakpoints.medium}px) {
    flex-direction: column;

    & > div {
      margin-bottom: 8px;
    }
  }
`;

export const DriveInvoicingSettingsFormSection = ({
  countryConfig: {
    currency,
    default_corporate_payment_term_delay_days,
    default_corporate_invoice_overdue_interest,
  },
}: Props) => {
  const { getLocalizedMessage } = useI18n();
  const {
    values: { country, is_electronic_invoicing_enabled },
    setFieldValue,
  } = useFormikContext<CreateCorporateFormValues>();

  const { data: selectedCountryConfig } = useCountryConfig(country);
  const currencyToDisplay = selectedCountryConfig?.currency ?? currency;

  useEffect(() => {
    setFieldValue(
      'payment_term_delay_days',
      default_corporate_payment_term_delay_days?.toString() || '14',
    );
    setFieldValue(
      'invoice_overdue_interest',
      default_corporate_invoice_overdue_interest?.toString() || '0',
    );
    setFieldValue('credit_limit_amount', '');
  }, [
    setFieldValue,
    default_corporate_payment_term_delay_days,
    default_corporate_invoice_overdue_interest,
  ]);

  return (
    <Root>
      <FormHeader horizontalPadding>
        <LocalizedMessage messageKey="corporate-form.invoicing-section-title" />
      </FormHeader>
      <StyledFormRow
        horizontalPadding
        required
        label={getLocalizedMessage('corporate-form.billing_cycle')}
        labelHtmlFor="billing_twice_a_month"
      >
        <BillingCycleField />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        required
        label={getLocalizedMessage('corporate-form.vat_id')}
        labelHtmlFor="vat_id"
        labelAlign="center"
      >
        <VatIdField />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        label={getLocalizedMessage('corporate-form.customer_reference')}
        labelHtmlFor="customer_reference"
        labelAlign="center"
      >
        <CustomerReferenceField />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        label={getLocalizedMessage('corporate-form.preferred_invoice_language')}
        labelHtmlFor="preferred_invoice_locale"
        labelAlign="center"
      >
        <PreferredInvoiceLanguageField />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        required
        label={getLocalizedMessage('corporate-form.payment_due_in')}
        labelHtmlFor="payment_term_delay_days"
        labelAlign="center"
      >
        <PaymentDueInField />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        required
        label={getLocalizedMessage('corporate-form.overdue_interest')}
        labelHtmlFor="invoice_overdue_interest"
        labelAlign="center"
      >
        <OverdueInterestField disabled={true} />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        required
        label={getLocalizedMessage('corporate-form.invoicing_email-label')}
        labelHtmlFor="invoice_email"
      >
        <StyledInputContainer
          labelHtmlFor="invoice_email"
          label={getLocalizedMessage('corporate-form.invoicing_email')}
          hint={getLocalizedMessage('corporate-form.invoicing_email_explanation')}
        >
          <InvoicingEmailField />
        </StyledInputContainer>
        <StyledInputContainer>
          <SendInvoiceCopyToEmailsField />
        </StyledInputContainer>
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        label={getLocalizedMessage('corporate-form.credit_limit')}
        labelHtmlFor="credit_limit_amount"
        labelAlign="center"
      >
        <CreditLimitField currency={currencyToDisplay} />
      </StyledFormRow>
      {selectedCountryConfig?.is_electronic_invoicing_enabled && (
        <StyledFormRow
          horizontalPadding
          label={getLocalizedMessage('corporate-form.electronic-invoicing-details.label')}
          labelHtmlFor="transaction_limits.single_transaction_amount"
        >
          <StyledInputContainer>
            <IsElectronicInvoicingDetailsEnabledField />
          </StyledInputContainer>
          {is_electronic_invoicing_enabled && (
            <>
              <StyledInputContainer
                labelHtmlFor="electronic_invoicing_details.edi"
                label={getLocalizedMessage('corporate-form.electronic-invoicing-details.edi')}
              >
                <ElectronicInvoicingAddress alwaysShowError />
              </StyledInputContainer>
              <StyledInputContainer
                labelHtmlFor="electronic_invoicing_details.edi_operator"
                label={getLocalizedMessage(
                  'corporate-form.electronic-invoicing-details.edi-operator',
                )}
              >
                <EDIOperatorId alwaysShowError />
              </StyledInputContainer>
              <StyledInputContainer
                labelHtmlFor="electronic_invoicing_details.vat_exemption_disclaimer"
                label={getLocalizedMessage(
                  'corporate-form.electronic-invoicing-details.vat-exemption-disclaimer',
                )}
              >
                <VATExemptionDisclaimer alwaysShowError />
              </StyledInputContainer>
            </>
          )}
        </StyledFormRow>
      )}
    </Root>
  );
};
