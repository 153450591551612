import styled from 'styled-components';
import { ArrowLeft, ArrowRight, MoreHorizontal, Pencil, Trashbin } from '@creditornot/cb-icons';
import { IconButton, TextButton } from '@creditornot/cb-button';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { MenuActionItem, MenuItemGroup } from '@creditornot/cb-menu';
import { typographyCss } from '@creditornot/cb-ingredients';

import { LocalizedMessage, useLanguageDirection } from 'i18n';
import { breakpoints, useMedia } from 'modules/media';

import Divider from '../Divider';

export type ViewInfoProps = {
  title?: React.ReactNode;
  topContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  children?: React.ReactNode;
  hideHeaderBorder?: boolean;
  className?: string;
  onBackClick?: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  editButtonContent?: React.ReactNode;
  deleteButtonContent?: React.ReactNode;
};

type HeaderBodyComponentProps = {
  title: React.ReactNode;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  editButtonContent?: React.ReactNode;
  deleteButtonContent?: React.ReactNode;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Header = styled.div`
  padding: 24px;
  display: grid;
  grid-template-rows: auto;
  grid-template-areas:
    'header header'
    'body   right';

  @media (max-width: ${breakpoints.medium}px) {
    padding: 24px 16px;
    grid-template-areas:
      'header'
      'body'
      'right';
  }
`;

const HeaderTop = styled.div`
  grid-area: header;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const HeaderBody = styled.div`
  grid-area: body;
  min-width: 0;
`;

const RightContent = styled.div`
  grid-area: right;
  padding-inline-start: 24px;
  margin-inline-start: auto;

  @media (max-width: ${breakpoints.medium}px) {
    margin-top: 12px;
    padding-inline-start: 0;
    margin-inline-start: 0;
  }
`;

const TopContent = styled.div`
  margin-inline-start: 16px;
`;

const HeadingWrapper = styled.div`
  flex: 1;
  min-width: 0;
  max-width: 900px;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.medium}px) {
    max-width: 100%;
  }
`;

const Heading5 = styled.h5`
  ${typographyCss.Heading5()}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: ${breakpoints.medium}px) {
    white-space: normal;
  }
`;

const BackButton = styled(TextButton).attrs({
  variant: 'black',
  children: <LocalizedMessage messageKey="common.back" />,
})``;

const EditIconButton = styled(IconButton).attrs({
  size: 'small',
  variant: 'black',
  children: <Pencil />,
})`
  margin-inline-start: 12px;
`;

const DeleteIconButton = styled(IconButton).attrs({
  size: 'small',
  variant: 'black',
  children: <Trashbin />,
})`
  margin-inline-start: 8px;
`;

const MoreActionsIconButton = styled(IconButton).attrs({
  size: 'small',
  variant: 'black',
  children: <MoreHorizontal />,
})``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledMenuItemGroup = styled(MenuItemGroup)`
  padding: 0 8px;
`;

const StyledPopover = styled(Popover)`
  margin-inline-start: 12px;
`;

const HeaderBodyComponent = ({
  title,
  onEditClick,
  onDeleteClick,
  editButtonContent,
  deleteButtonContent,
}: HeaderBodyComponentProps) => {
  const isSmallView = useMedia('small');
  // Only show the actions icon if there's at least one clickable action
  const showSmallViewActionsIcon = isSmallView && (!!onEditClick || !!onDeleteClick);

  return (
    <>
      {title && (
        <HeadingWrapper>
          <Heading5>{title}</Heading5>

          {showSmallViewActionsIcon ? (
            <StyledPopover
              placement="left"
              renderArrow
              showOnHover={false}
              closeOnClickInside
              content={
                <StyledMenuItemGroup>
                  {onEditClick && (
                    <MenuActionItem variant="black" onClick={onEditClick}>
                      <>{editButtonContent || ' Edit'}</>
                    </MenuActionItem>
                  )}

                  {onDeleteClick && (
                    <MenuActionItem variant="black" onClick={onDeleteClick}>
                      {deleteButtonContent || ' Delete'}
                    </MenuActionItem>
                  )}
                </StyledMenuItemGroup>
              }
            >
              <MoreActionsIconButton />
            </StyledPopover>
          ) : (
            <>
              {onEditClick && <EditIconButton onClick={onEditClick} />}

              {onDeleteClick && <DeleteIconButton onClick={onDeleteClick} />}
            </>
          )}
        </HeadingWrapper>
      )}
    </>
  );
};

export const ViewInfo = ({
  title,
  rightContent,
  children,
  className,
  onBackClick,
  onEditClick,
  onDeleteClick,
  hideHeaderBorder,
  topContent,
  editButtonContent,
  deleteButtonContent,
}: ViewInfoProps) => {
  const direction = useLanguageDirection();
  return (
    <Root className={className}>
      <Header>
        <HeaderTop>
          {onBackClick && (
            <BackButton
              onClick={onBackClick}
              icon={direction === 'ltr' ? <ArrowLeft /> : <ArrowRight />}
            />
          )}
          {topContent && <TopContent>{topContent}</TopContent>}
        </HeaderTop>

        {title && (
          <HeaderBody>
            <HeaderBodyComponent
              title={title}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              editButtonContent={editButtonContent}
              deleteButtonContent={deleteButtonContent}
            />
          </HeaderBody>
        )}

        {rightContent && <RightContent>{rightContent}</RightContent>}
      </Header>

      {!hideHeaderBorder && <Divider />}

      <Content>{children}</Content>
    </Root>
  );
};
