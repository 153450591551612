import { TextButton, TextButtonProps } from '@creditornot/cb-button';
import { LegacyPopover as Popover, LegacyPopoverPlacement } from '@creditornot/cb-popover';
import { MenuActionItem, MenuItemGroup } from '@creditornot/cb-menu';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { CaretDown, CaretUp, MagnifyingGlass } from '@creditornot/cb-icons';
import styled from 'styled-components';
import { Input } from '@creditornot/cb-input';
import { typographyCss } from '@creditornot/cb-ingredients';

type CountryOption = {
  disabled?: boolean;
  value: string;
  label: string;
};

type OwnProps = {
  options: CountryOption[];
  onChange: (value: string) => void;
  className?: string;
  value: string | null | undefined;
  placeholder?: string | undefined;
  inputPlaceholder?: string | undefined;
  emptyMessage?: string | undefined;
  placement?: LegacyPopoverPlacement | undefined;
};

type Props = Partial<Omit<TextButtonProps, 'onChange'>> & OwnProps;

const StyledMenuItemGroup = styled(MenuItemGroup)`
  padding: 0 8px;
  width: 254px;
  max-height: 320px;
  overflow: auto;
`;

const NoOption = styled.span`
  ${typographyCss.Body3()}
  display: inline-block;
  padding: 8px 0;
`;

export const CountrySelect: React.FC<Props> = ({
  options,
  onChange,
  className,
  value,
  placeholder = 'Select a country',
  inputPlaceholder = 'Search',
  emptyMessage = 'No country found',
  placement = 'bottom-start',
  ...buttonProps
}) => {
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false);
  const handleClick = useCallback(() => {
    setShow((state) => !state);
    setTimeout(() => {
      setSearch('');
    }, 200);
  }, []);
  const displayLabel = useMemo(
    () => options.find((option) => option.value === value)?.label ?? placeholder,
    [options, placeholder, value],
  );
  const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);
  const handleChange = useCallback(
    (country: string) => {
      onChange(country);
      setShow(false);
      setTimeout(() => {
        setSearch('');
      }, 200);
    },
    [onChange],
  );

  const filteredOptions = options.filter((option) =>
    option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
  );

  return (
    <div className={className}>
      <Popover
        contentZIndex={999}
        onClickOutside={handleClick}
        renderArrow={false}
        showOnHover={false}
        show={show}
        placement={placement}
        content={
          <StyledMenuItemGroup>
            <Input
              icon={<MagnifyingGlass />}
              value={search}
              onChange={handleSearch}
              placeholder={inputPlaceholder}
            />
            {filteredOptions.length ? (
              filteredOptions.map((option, index) => (
                <MenuActionItem
                  onClick={() => handleChange(option.value)}
                  selected={value === option.value}
                  variant="black"
                  key={`${JSON.stringify(options)}-${index}`}
                >
                  {option.label}
                </MenuActionItem>
              ))
            ) : (
              <NoOption>{emptyMessage}</NoOption>
            )}
          </StyledMenuItemGroup>
        }
      >
        <TextButton
          iconPosition="right"
          icon={show ? <CaretUp /> : <CaretDown />}
          size="medium"
          variant="black"
          {...buttonProps}
          onClick={handleClick}
        >
          {displayLabel}
        </TextButton>
      </Popover>
    </div>
  );
};
