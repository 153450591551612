import { useCallback } from 'react';

import { useMedia } from 'modules/media';
import { SubscriptionUser } from 'modules/subscription/types';

import { useSubscriptionUsersQueryParams } from '../../../hooks/useSubscriptionUsersQueryParams';
import { SubscriptionUserDataList } from './SubscriptionUserDataList';
import { SubscriptionUserDataTable } from './SubscriptionUserDataTable';

interface Props {
  data?: SubscriptionUser[] | null;
  loading: boolean;
  error: any;
  dataItemProps: {
    onOpenDeleteUserModal: (id: string) => void;
  };
  pageCount?: number;
}

export const SubscriptionUserList = ({ data, loading, error, dataItemProps, pageCount }: Props) => {
  const mediumViewport = useMedia('medium');
  const [{ users: selectedUsers = [] }, setQueryParams] = useSubscriptionUsersQueryParams();

  const handleUserSelectionClick = useCallback(
    (selectedUsers: string[]) => {
      setQueryParams({
        users: selectedUsers.length > 0 ? selectedUsers : undefined,
      });
    },
    [setQueryParams],
  );

  const handleSelectAllUsers = useCallback(() => {
    const isAllSelected = selectedUsers.length === data?.length;
    setQueryParams({
      users: isAllSelected ? undefined : data?.map((user) => user.user_id),
    });
  }, [data, selectedUsers.length, setQueryParams]);

  return mediumViewport ? (
    <SubscriptionUserDataList
      data={data}
      loading={loading}
      error={error}
      dataItemProps={dataItemProps}
      selectedUsers={selectedUsers}
      onUserSelectionClick={handleUserSelectionClick}
    />
  ) : (
    <SubscriptionUserDataTable
      data={data}
      loading={loading}
      error={error}
      dataCellProps={dataItemProps}
      pageCount={pageCount}
      selectedUsers={selectedUsers}
      onUserSelectionClick={handleUserSelectionClick}
      onSelectAllUsers={handleSelectAllUsers}
    />
  );
};
