import { useIntl } from 'react-intl';

interface Props {
  currency: string;
}

const LocalizedCurrencySymbol = ({ currency }: Props) => {
  const intl = useIntl();

  return (
    <span>
      {intl
        .formatNumber(0, {
          currency,
          style: 'currency',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim()}
    </span>
  );
};

export default LocalizedCurrencySymbol;
