import { useField } from 'formik';
import { Alert } from '@creditornot/cb-alert';

import { FormRowV2, FormSection, RadioInputCardGroup } from 'components';
import { Products } from 'modules/country-configs/types';

interface ToggleProps {
  name: keyof Products;
}

const productConfig = {
  drive_api: { title: 'Drive API', disabled: true },
  drive_delivery_ui: { title: 'Wolt Drive', disabled: true },
  subscription_benefit: { title: 'Wolt+', disabled: false },
  wolt_for_work: { title: 'Wolt for Work', disabled: false },
};

const ProductToggle = ({ name }: ToggleProps) => {
  const [{ value }, , { setValue }] = useField<boolean>(`enabled_products.${name}`);
  const config = productConfig[name];

  return (
    <FormRowV2
      htmlFor={`enabled_products.${name}`}
      title={config.title}
      content={
        <RadioInputCardGroup
          name={name}
          value={value ? 'true' : 'false'}
          onChange={(e) => setValue(e.target.value === 'true')}
          disabled={config.disabled}
          direction="row"
          stretched
          options={[
            {
              label: 'Enabled',
              value: 'true',
            },
            {
              label: 'Disabled',
              value: 'false',
            },
          ]}
        />
      }
    />
  );
};

export const EnabledProductsFormSection = () => {
  const [{ value: enabledProducts }] = useField<Products>('enabled_products');

  return (
    <FormSection title="Products">
      <Alert variant="info" size="small">
        Changes made in the products section only affect new corporate accounts created after the
        change. Note: Drive products cannot be modified from the country config
      </Alert>
      {(Object.keys(enabledProducts) as Array<keyof Products>).map((product) => (
        <ProductToggle key={product} name={product} />
      ))}
    </FormSection>
  );
};
