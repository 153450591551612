import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAuthState } from '@creditornot/wolt-auth/react';

import ProductTourProvider, { useProductTour } from 'modules/product-tour';
import { useMedia } from 'modules/media';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { getLocalStorageItem } from 'modules/hooks';
import { useCorporate } from 'modules/corporates';

import { WoltAtWorkWelcomeModal } from './WoltAtWorkWelcomeModal';
import { useOnboardingConfig } from './useOnboardingConfig';

// DISABLE onboarding in mobile at the momment
const usePreventOnboardingInMobile = () => {
  const isMediumView = useMedia('medium');
  const { end, isInTourMode } = useProductTour();

  useEffect(() => {
    if (isMediumView) {
      end();
    }
  }, [isMediumView, isInTourMode, end]);
};

const InitializeOnboarding = () => {
  const { start, end, isInTourMode } = useProductTour();
  const history = useHistory();
  const { isWoltEmployee } = useIsUserWoltEmployee();
  const { data: corporate } = useCorporate();

  useEffect(() => {
    const isFirstTimeUser = getLocalStorageItem('isFirstTimeUser');
    if (isFirstTimeUser !== false && !!corporate) {
      start();
    } else {
      end();
    }
  }, [corporate, start, end, history]);

  useEffect(() => {
    if (corporate && isInTourMode) {
      if (isWoltEmployee) {
        history.push(`/${corporate.id}/home`);
      } else {
        history.push(`/${corporate.id}/orders`);
      }
    }
  }, [corporate, isWoltEmployee, history, isInTourMode]);

  return null;
};

const Onboarding = () => {
  const isLoggedIn = !!useAuthState().user;
  usePreventOnboardingInMobile();

  return (
    <>
      {isLoggedIn && <InitializeOnboarding />}
      <WoltAtWorkWelcomeModal />
    </>
  );
};

export const OnboardingProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const onboardingConfig = useOnboardingConfig();

  return (
    <ProductTourProvider config={onboardingConfig}>
      {children}

      <Onboarding />
    </ProductTourProvider>
  );
};
