import { useMemo } from 'react';

import { useFetchUserCorporateRole } from 'modules/users';
import { useCorporate } from 'modules/corporates';

const useIsUserCorporateManager = () => {
  const { data: corporate } = useCorporate();

  const { data: corporateRoles, isFetching: isFetchingCorporateRoles } =
    useFetchUserCorporateRole();

  const isCorporateManager = useMemo(() => {
    if (!corporate || !corporateRoles || isFetchingCorporateRoles) {
      return false;
    }
    const matchingCorporateRole = corporateRoles.find(
      (corporateRole) =>
        corporateRole.resource_id === corporate.id && corporateRole.role === 'corporate_manager',
    );
    return !!matchingCorporateRole;
  }, [corporate, corporateRoles, isFetchingCorporateRoles]);

  return { isCorporateManager, isFetchingCorporateRoles };
};

export default useIsUserCorporateManager;
