import { color } from '@creditornot/cb-ingredients/design-tokens';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { typographyCss } from '@creditornot/cb-ingredients';
import styled from 'styled-components';
import { DateRangePickerInput } from '@creditornot/cb-date-picker';
import { RouteComponentProps, useHistory } from 'react-router';
import { endOfDay, isSameDay, startOfDay } from 'date-fns';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { LocalizedNumber, useI18n } from 'i18n';
import { View, Header, ApiErrorNotification, ResponsiveContainer } from 'components';
import { useFetchDeliveryCount } from 'modules/deliveries';
import { breakpoints } from 'modules/media';
import { useFetchSelfServiceCorporate } from 'modules/corporates';
import { useLocalStorage } from 'modules/hooks';
import { corporateDeliverySettingsComplete } from 'modules/corporates/utils';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';
import { homeInteractionCalendar } from 'telemetry/Avo';

import { DeliveriesOnboardingModal } from './DeliveriesOnboardingModal';
import { useDeliveryStatisticsQueryParams } from './useDeliveryStatisticQueryParams';
import { PickupSoonDeliveriesList } from './PickupSoonDeliveriesList';
import { last30DaysDateRange } from './utils';

// hack to stretch input. Root component of DateRangePickerInput is a div with display: inline-block
const DatePickerContainer = styled.div`
  > div {
    display: unset;
  }
`;

const DataCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DataCard = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${color.border};
  border-inline-end: 1px solid ${color.border};
  min-height: 180px;
  width: 50%;

  @media (max-width: ${breakpoints.small}px) {
    padding: 24px 16px;
    min-height: unset;
    width: 100%;
  }
`;

const Title = styled.h6`
  ${typographyCss.Heading6()}
`;

const DataCardTitle = styled.div`
  ${typographyCss.Body2()}
  display: block;
  margin-bottom: 8px;
`;

const Content = styled.div`
  ${typographyCss.Heading1()}
`;

const ErrorContainer = styled(ResponsiveContainer)`
  padding-top: 24px;
  padding-bottom: 24px;
`;

const isLast30Days = (dateRange: { from: Date; to: Date }) => {
  const last30Days = last30DaysDateRange();
  return isSameDay(last30Days.from, dateRange.from) && isSameDay(last30Days.to, dateRange.to);
};

export const DeliveryHomeView: FC<RouteComponentProps<{ corporateId: string }>> = ({ match }) => {
  const { getLocalizedMessage } = useI18n();
  const history = useHistory();
  const { locale } = useIntl();

  const homeInteractionCalendarEvent = useTelemetryInteractionEvent(homeInteractionCalendar);

  const { user } = useAuthState();
  const {
    params: { from, to },
    setQueryParams,
  } = useDeliveryStatisticsQueryParams();

  const { error: countError, data: countData } = useFetchDeliveryCount({
    corporateId: match.params.corporateId,
    from: from.toISOString(),
    to: to.toISOString(),
    userId: '',
  });

  const { data: corporateSupportSettingsMissing } = useFetchSelfServiceCorporate(
    match.params.corporateId,
    { select: (data) => corporateDeliverySettingsComplete(data) === false },
  );

  const [onboardingModalSeen, setOnboardingModalSeen] = useLocalStorage<string[]>(
    'wolt-drive-onboarding-modal-closed',
    [],
  );

  const defaultShowOnboardingModal =
    corporateSupportSettingsMissing && !onboardingModalSeen.includes(match.params.corporateId);

  const setOnboardingModalClosedCookie = () => {
    setOnboardingModalSeen([...onboardingModalSeen, match.params.corporateId]);
  };

  return (
    <View
      title={
        user?.name && (
          <FormattedMessage id="views.dashboard.title" values={{ name: user.name.first_name }} />
        )
      }
    >
      {corporateSupportSettingsMissing === true && (
        <DeliveriesOnboardingModal
          defaultShow={defaultShowOnboardingModal}
          onConfirm={() => {
            history.push(`/${match.params.corporateId}/settings/delivery-settings`);
            setOnboardingModalClosedCookie();
          }}
          onClose={setOnboardingModalClosedCookie}
        />
      )}

      <Header
        leftItems={[
          <Title key="heading">
            <FormattedMessage id="deliveries.overview" />
          </Title>,
          <DatePickerContainer key="dateRangePicker" onClick={homeInteractionCalendarEvent}>
            <DateRangePickerInput
              locale={locale}
              data-test-id="date-picker-input"
              value={!isLast30Days({ from, to }) ? { from, to } : undefined}
              onChange={(dateRange) => {
                if (!dateRange) {
                  setQueryParams({ from: undefined, to: undefined });
                  return;
                }

                setQueryParams({ from: startOfDay(dateRange.from), to: endOfDay(dateRange.to) });
              }}
              placeholder={getLocalizedMessage('orders.month-picker.last-30-days')}
            />
          </DatePickerContainer>,
        ]}
      />
      {!!countError && (
        <ErrorContainer>
          <ApiErrorNotification error={countError} />
        </ErrorContainer>
      )}
      {countData && (
        <DataCardContainer data-test-id="delivery-statistic-container">
          <DataCard>
            <DataCardTitle>
              <FormattedMessage id="deliveries.total-deliveries" />
            </DataCardTitle>
            <Content>{countData.totalCount}</Content>
          </DataCard>
          {countData.totalCost && (
            <DataCard>
              <DataCardTitle>
                <FormattedMessage id="deliveries.total-delivery-cost" />
                {countData.totalCost.currency && `(${countData.totalCost.currency})`}
              </DataCardTitle>

              <Content>
                <LocalizedNumber
                  minimumFractionDigits={2}
                  value={countData.totalCost.amount}
                  disableCurrencyParser
                />
              </Content>
            </DataCard>
          )}
        </DataCardContainer>
      )}
      <PickupSoonDeliveriesList corporateId={match.params.corporateId} />
    </View>
  );
};
