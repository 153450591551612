import { InputContainer } from '@creditornot/cb-form';

import { FormRowV2, FormikInputField } from 'components';
import { createValidator, isNumber, isRequired } from 'validation';

interface Props {
  alwaysShowError?: boolean;
  currency?: string;
}

const validate = createValidator([isRequired, isNumber]);

export const SingleTransactionLimitField = ({ alwaysShowError, currency }: Props) => {
  return (
    <FormRowV2
      htmlFor="transaction_limits.single_transaction_amount"
      title={`Single transaction limits (${currency})`}
      content={
        <InputContainer hint="This is the default limit for all users in the country">
          <FormikInputField
            name="transaction_limits.single_transaction_amount"
            type="text"
            required={true}
            validate={validate}
            alwaysShowError={alwaysShowError}
          />
        </InputContainer>
      }
    />
  );
};

export const DailyTransactionLimitField = ({ alwaysShowError, currency }: Props) => {
  return (
    <FormRowV2
      htmlFor="transaction_limits.daily_transactions_amount"
      title={`Daily transaction limits (${currency})`}
      content={
        <InputContainer hint="This is the default daily limit for all users in the country">
          <FormikInputField
            name="transaction_limits.daily_transactions_amount"
            type="text"
            required={true}
            validate={validate}
            alwaysShowError={alwaysShowError}
          />
        </InputContainer>
      }
    />
  );
};
