import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useCorporate } from 'modules/corporates';

export const useSubscriptionModalsController = () => {
  const { data: corporate } = useCorporate();
  const history = useHistory();

  const closeModal = useCallback(() => {
    if (!corporate) {
      return;
    }
    const search = new URLSearchParams(history.location.search);
    search.delete('user');
    search.delete('invite');
    const pathname = `/${corporate.id}/subscriptions?${search.toString()}`;
    history.push(pathname);
  }, [corporate, history]);

  const openAcceptModal = useCallback(() => {
    const pathname = `${history.location.pathname}/accept`;
    history.push(pathname);
  }, [history]);

  const openInviteModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    const pathname = `${history.location.pathname}/invite?${search.toString()}`;
    history.push(pathname);
  }, [history]);

  const openDeleteUsersModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    const pathname = `${history.location.pathname}/delete-users?${search.toString()}`;
    history.push(pathname);
  }, [history]);

  const openDeleteInvitesModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    const pathname = `${history.location.pathname}/delete-invites?${search.toString()}`;
    history.push(pathname);
  }, [history]);

  const openResendInvitesModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    const pathname = `${history.location.pathname}/resend?${search.toString()}`;
    history.push(pathname);
  }, [history]);

  return {
    closeModal,
    openAcceptModal,
    openInviteModal,
    openDeleteUsersModal,
    openDeleteInvitesModal,
    openResendInvitesModal,
  };
};
