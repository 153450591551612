import { color } from '@creditornot/cb-ingredients/design-tokens';
import { typographyCss } from '@creditornot/cb-ingredients';
import styled from 'styled-components';

import { DataList, InformationDisplay, RtlCaret } from 'components';
import { PaginatedData } from 'types';
import { Event } from 'modules/events/types';
import { LocalizedMessage, LocalizedNumber } from 'i18n';
import { useCorporate } from 'modules/corporates';

import { EventStatus } from './EventStatus';

interface Props {
  data: PaginatedData<Event> | null | undefined;
  className?: string;
  error: unknown;
  isLoading: boolean;
  onDataItemClick: (event: Event) => void;
}

const Description = styled.small`
  ${typographyCss.Small()}
  display: block;
`;

const CaretEnd = styled(RtlCaret).attrs({ alignment: 'end' })`
  height: 16px;
  width: 16px;
  margin-inline-start: auto;

  & > path {
    fill: ${color.textSubdued};
  }
`;

const Content = styled.small`
  ${typographyCss.Small()}
  display: block;
  margin-top: 4px;
  margin-bottom: 8px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const EventsDataList = ({ data, onDataItemClick, className, isLoading, error }: Props) => {
  const { data: corporate } = useCorporate();
  if (!data) {
    return null;
  }

  return (
    <DataList
      data={data?.results}
      getDataItemId={(dataItem) => dataItem.id}
      onDataItemClick={onDataItemClick}
      renderDataItem={({
        participant_count,
        name,
        participant_budget_amount,
        status,
        description,
      }) => (
        <Item>
          <InformationDisplay labelColor="black" label={<strong>{name}</strong>}>
            {description && <Description>{description}</Description>}
            <Content>
              <LocalizedMessage
                messageKey="events.number-of-participants"
                values={{
                  number: participant_count,
                }}
              />
              {' • '}
              <LocalizedMessage
                messageKey="events.participants-budget"
                values={{
                  amount: (
                    <LocalizedNumber
                      value={participant_budget_amount}
                      currency={corporate?.currency}
                    />
                  ),
                }}
              />
            </Content>
            <EventStatus status={status} />
          </InformationDisplay>

          <CaretEnd />
        </Item>
      )}
      className={className}
      error={error}
      loading={isLoading}
    />
  );
};
