import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { Trashbin, Pencil } from '@creditornot/cb-icons';

import { useI18n } from 'i18n';
import { IconButtonWithHelpText } from 'components';

interface ActionButtonsProps {
  policyId: string;
  onEditClick?: () => void;
  onDeleteClick: () => void;
}

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const StyledPencil = styled(Pencil)`
  height: 16px;
  width: 16px;
  fill: ${color.textSubdued} !important;
`;

const StyledTrashbin = styled(Trashbin)`
  height: 16px;
  width: 16px;
  fill: ${color.textSubdued} !important;
`;

const ActionButtons = ({ policyId, onDeleteClick, onEditClick }: ActionButtonsProps) => {
  const { getLocalizedMessage } = useI18n();
  return (
    <Root>
      {onEditClick && (
        <IconButtonWithHelpText
          testId={`edit-policy-${policyId}-button`}
          onClick={onEditClick}
          tooltipText={getLocalizedMessage('common.edit')}
          tooltipPlacement="bottom"
          variant="grey"
        >
          <StyledPencil />
        </IconButtonWithHelpText>
      )}
      {onDeleteClick && (
        <IconButtonWithHelpText
          testId={`delete-policy-${policyId}-button`}
          onClick={onDeleteClick}
          tooltipText={getLocalizedMessage('common.delete')}
          tooltipPlacement="bottom"
          variant="grey"
        >
          <StyledTrashbin />
        </IconButtonWithHelpText>
      )}
    </Root>
  );
};

export default ActionButtons;
