import React, { FC, useCallback, useMemo, useState } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { ModalV2, NotificationToast } from 'components';

import { AppModalsContext, AppModalData, AppNotificationToastsData } from './AppModalsContext';

const StyledNotificationToast = styled(NotificationToast)<{
  notificationToastStyle?: FlattenSimpleInterpolation;
}>`
  margin-top: 24px;
  margin-inline-end: 24px;
  width: 420px;

  /* Ensure toasts stay on top of everything */
  z-index: 99999;
  ${({ notificationToastStyle }) => notificationToastStyle && notificationToastStyle};
`;

export const AppModalsProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [modals, setModals] = useState<AppModalData[]>([]);
  const [toasts, setToast] = useState<AppNotificationToastsData[]>([]);
  const openModal = useCallback((id: string, modalProps: Omit<AppModalData, 'id'>) => {
    setModals((modals) =>
      modals.filter((modal) => modal.id !== id).concat({ id, show: true, ...modalProps }),
    );
  }, []);
  const closeModal = useCallback((id: string) => {
    setModals((modals) =>
      modals.map((modal) => ({
        ...modal,
        show: modal.id === id ? false : modal.show,
      })),
    );
  }, []);
  const handleAfterModalClosed = useCallback((id: string) => {
    setModals((modals) => modals.filter((modal) => modal.id !== id));
  }, []);
  const openNotificationToast = useCallback(
    (id: string, toastProps: Omit<AppNotificationToastsData, 'id'>) => {
      setToast((toasts) => toasts.filter((toast) => toast.id !== id).concat({ id, ...toastProps }));
    },
    [],
  );
  const closeNotificationToast = useCallback((id: string) => {
    setToast((toasts) =>
      toasts.map((toast) => ({
        ...toast,
        show: toast.id === id ? false : toast.show,
      })),
    );
  }, []);
  const handleAfterToastClosed = useCallback((id: string) => {
    setToast((toasts) => toasts.filter((toast) => toast.id !== id));
  }, []);
  const hasOpenModal = useMemo(() => modals.some((modal) => modal.show), [modals]);

  return (
    <AppModalsContext.Provider
      value={{
        modals,
        openModal,
        closeModal,
        toasts,
        openNotificationToast,
        closeNotificationToast,
        hasOpenModal,
      }}
    >
      {children}

      {toasts.map(({ id, ...props }) => (
        <StyledNotificationToast
          key={id}
          onClose={() => {
            closeNotificationToast(id);
          }}
          onAfterClose={() => {
            handleAfterToastClosed(id);
          }}
          {...props}
        />
      ))}

      {modals.map(({ id, show, ...props }) => (
        <ModalV2
          onUnmount={() => {
            handleAfterModalClosed(id);
          }}
          key={id}
          open={!!show}
          {...props}
        />
      ))}
    </AppModalsContext.Provider>
  );
};
