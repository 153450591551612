import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { CoordinatesPickerWithAlert } from 'components/CoordinatesPicker';
import { useElementVisibility } from 'modules/policies/useElementVisibility';

import { PolicyFormValues } from './types';

interface Props {
  index: number;
}

const Root = styled.div`
  position: relative;
  height: 290px;
  margin-top: 8px;
`;

export const PolicyTakeawayLocationMap = ({ index }: Props) => {
  const { formatMessage } = useIntl();
  const {
    values: { delivery_locations },
  } = useFormikContext<PolicyFormValues>();
  const location = useMemo(() => delivery_locations?.[index], [delivery_locations, index]);

  const numberOfMaps = useMemo(() => delivery_locations.length, [delivery_locations]);

  const [showMap, containerRef] = useElementVisibility({
    // The map component itself is 290px in height, so this offset will render around 10-11 maps at once (but not exceed the 16 map limit)
    offset: 3000, // px
    // Browsers can only handle max 16 WebGL contexts, so the amount of maps rendered on one page is limited (each delivery location can have max 2 maps)
    // Note: the most recently added map in the delivery location list should always be visible
    alwaysShow: numberOfMaps === index + 1 || numberOfMaps < 8,
  });

  return (
    <Root ref={containerRef}>
      <CoordinatesPickerWithAlert
        showInitialAlert
        disableMarkerDrag
        alertMessage={formatMessage({ id: 'policies.takeaway-pin-explanation' })}
        alertVariant="info"
        showPlaceholderContainer={!showMap}
        location={location.coordinates}
        radius={location.takeaway_radius}
        style={{ borderRadius: 8 }}
      />
    </Root>
  );
};
