import { useField } from 'formik';
import styled from 'styled-components';

import { useI18n } from 'i18n';
import { RadioInputCardGroup } from 'components';

const StyledRadioInputCardGroup = styled(RadioInputCardGroup)`
  & > label:nth-child(2) {
    margin-inline-end: 0;
  }
`;

const PolicySpendingLimitField = () => {
  const { getLocalizedMessage } = useI18n();
  const [limitSpendingFieldInput, , limitSpendingFieldHelpers] = useField<string>('limit_spending');

  return (
    <StyledRadioInputCardGroup
      name={limitSpendingFieldInput.name}
      onChange={(e) => limitSpendingFieldHelpers.setValue(e.target.value)}
      value={limitSpendingFieldInput.value}
      direction="row"
      stretched
      options={[
        {
          label: getLocalizedMessage('policies.set-no-spending-limit'),
          value: 'no-limit',
        },
        {
          label: getLocalizedMessage('policies.set-spending-limit'),
          value: 'has-limit',
        },
        // For later use
        // W@W with allow user to exclude delivery fee from
        // personal budget
        // if (data.budget_allowance?.exclude_delivery_fee) {
        //   return 'exclude-delivery-fee';
        // }
        // {
        //   label: getLocalizedMessage('policies.set-spending-limit-exclude-delivery-fee'),
        //   value: 'exclude-delivery-fee',
        //   explanation: getLocalizedMessage(
        //     'policies.set-spending-limit-exclude-delivery-fee-explanation',
        //   ),
        // },
      ]}
    />
  );
};

export default PolicySpendingLimitField;
