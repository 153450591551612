import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';

import { DataList, EmptyState } from 'components';
import RightArrow from 'assets/images/right-arrow.svg';
import Caret from 'assets/images/caret-down-2.svg';
import { useI18n, LocalizedNumber } from 'i18n';
import { PaginatedData } from 'types/PaginatedData';
import { Order } from 'modules/orders/types';
import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';

import { OrderStatus } from './OrderStatus';

interface OrdersDataListProps {
  data: PaginatedData<Order> | null;
  loading?: boolean;
  onDataItemClick: (id: string) => void;
  error?: any;
  isBeingFiltered: boolean;
}

const StyledCaret = styled(Caret)`
  height: 22px;
  width: 22px;
  ${cssDirectionalValue({
    ltr: 'transform: rotate(-90deg) translateX(50%);',
    rtl: 'transform: rotate(90deg) translateX(-50%);',
  })}
  position: absolute;
  top: 50%;

  ${cssDirectionalValue({
    ltr: 'right: -9px;',
    rtl: 'left: -9px;',
  })}
  & > path {
    fill: ${color.textSubdued};
  }
`;

const StyledRightArrow = styled(RightArrow)`
  ${cssDirectionalValue({
    ltr: 'transform: scale(0.65);',
    rtl: 'transform: rotate(180deg) scale(0.65);',
  })}
  margin-inline-end: 4px;
`;

const StyledOrderStatus = styled(OrderStatus)`
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
`;

const LeftContent = styled.div`
  font-weight: 500;
  letter-spacing: -0.15px;
  line-height: 20px;
`;

const RightContent = styled.div`
  padding-inline-end: 24px;
  position: relative;
`;

const DataItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const OrdersDataList = ({
  data,
  error,
  isBeingFiltered,
  loading,
  onDataItemClick,
}: OrdersDataListProps) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <DataList
      data={data?.results}
      getDataItemId={({ id }) => id}
      error={error}
      onDataItemClick={({ id }) => onDataItemClick(id)}
      renderDataItem={({
        venue: { name: venueName },
        status,
        user: { name: userName },
        total_amount,
        currency,
        venue_preorder_time,
      }) => {
        return (
          <DataItem>
            <LeftContent>
              <div>{venueName}</div>
              <div>
                <StyledRightArrow />
                {userName}
              </div>
              <div>
                <StyledOrderStatus status={status} isPreOrder={!!venue_preorder_time} />
              </div>
            </LeftContent>

            <RightContent>
              <LocalizedNumber value={total_amount} currency={currency} />
              <StyledCaret />
            </RightContent>
          </DataItem>
        );
      }}
      loading={loading}
      noData={
        <EmptyState
          subtitle={
            !isBeingFiltered ? getLocalizedMessage('orders.no-orders-explanation') : undefined
          }
          icon="order"
        >
          {getLocalizedMessage('orders.no-orders')}
        </EmptyState>
      }
    />
  );
};

export default OrdersDataList;
