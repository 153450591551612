import { DeliveryOrderApi, Configuration } from '@creditornot/wd-api-client';

import { env } from 'config';
import { daasSelfServiceApiAgent } from 'apiAgents';

const selfServiceApiBaseConfig = new Configuration({
  baseOptions: {
    headers: {
      'wolt-app': 'WOLT_AT_WORK',
    },
  },
});

export const deliveryOrderApiClient = new DeliveryOrderApi(
  selfServiceApiBaseConfig,
  env.NODE_ENV === 'development' ? '/daas-self-service-api' : env.DAAS_SELF_SERVICE_API_URI,
  daasSelfServiceApiAgent,
);
