import { AxiosError } from 'axios';
import { createContext } from 'react';

import { Configs } from './types';

interface ConfigContext {
  isLoading: boolean;
  configs: Configs | null;
  error: AxiosError | null;
}

export default createContext<ConfigContext>({
  isLoading: false,
  configs: null,
  error: null,
});
