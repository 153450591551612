import { useCallback } from 'react';
import { css } from 'styled-components';

import { breakpoints } from 'modules/media';
import { LocalizedMessage, useI18n } from 'i18n';
import { useNotificationToast } from 'modules/app-modals';

const notificationToastCss = css`
  width: 420px;

  @media (max-width: ${breakpoints.small}px) {
    width: 320px;
  }
`;

const removePolicyFromGroupToastId = 'REMOVE_POLICY_FROM_GROUP_TOAST_ID';

export const useOpenRemovePolicyFromGroupToast = () => {
  const { getLocalizedMessage } = useI18n();
  const { openNotificationToast, closeNotificationToast } = useNotificationToast();

  return useCallback(
    ({ policyName, numberOfPolicies }: { policyName: string; numberOfPolicies: number }) => {
      openNotificationToast(removePolicyFromGroupToastId, {
        variant: 'info',
        notificationToastStyle: notificationToastCss,
        title: getLocalizedMessage('toast.after-policy-delete-title'),
        autoCloseIn: 30 * 1000,
        onCloseButtonClick: () => closeNotificationToast(removePolicyFromGroupToastId),
        onClose: () => closeNotificationToast(removePolicyFromGroupToastId),
        children: (
          <LocalizedMessage
            messageKey="toast.after-policy-delete-content"
            values={{
              policy: policyName,
              numberOfPolicies,
              b: (msg: string) => <b key="toast.after-policy-delete-content">{msg}</b>,
            }}
          />
        ),
      });
    },
    [closeNotificationToast, getLocalizedMessage, openNotificationToast],
  );
};
