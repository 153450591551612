import { Select } from '@creditornot/cb-select';
import { useField } from 'formik';

import { useConfigs } from 'modules/configs';

interface Props {
  name: string;
  validate?: (value: any) => string | void | Promise<string | void>;
}

const CountrySelectField: React.FC<Props> = ({ name, validate }) => {
  const { configs } = useConfigs();
  const [{ value }, , { setValue }] = useField<string | null>({
    name,
    validate,
  });

  return (
    <Select
      inputId="country"
      value={value}
      onChange={(value) => setValue(value)}
      options={configs?.countries
        .map((country) => country.code)
        .filter((currency, index, array) => array.indexOf(currency) === index)
        .map((code) => ({
          label: code,
          value: code,
        }))}
    />
  );
};

export default CountrySelectField;
