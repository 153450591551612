export function transformInviteEmailsToInviteUsersData(values: {
  emails: string[];
  is_corporate_manager: boolean;
  group_ids: string[];
}) {
  return {
    invites: values.emails.map((email) => ({
      email: email.trim(),
      is_corporate_manager: !!values.is_corporate_manager,
      group_ids: values.group_ids,
    })),
  };
}
