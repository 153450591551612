import { setMetadata, getMetadata, Metadata } from '@creditornot/browser-telemetry';

export const setMetadataResource = (
  newMetadata: Pick<Metadata, 'corporate_id' | 'corporate_country' | 'is_wolt_employee'>,
) => {
  setMetadata({
    ...getMetadata(),
    ...newMetadata,
  });
};
