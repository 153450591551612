import { FormikInputField } from 'components';
import { createValidator, isInteger, isNumber } from 'validation';
import { PriceIncrement } from 'views/corporates/create-corporate/CreateCorporateForm/types';

interface Props {
  index: number;
  fieldArray: PriceIncrement[];
  allowClear?: boolean;
}

export const CustomPriceFromField = ({ index, fieldArray, allowClear = true }: Props) => {
  const validationFn = (valueStr: string) => {
    const distanceMin = Number(valueStr);
    if (index === 0 && distanceMin != 0) {
      return 'validation.is_zero';
    }
    if (index === 0) {
      return undefined;
    }
    if (
      !isNaN(Number(fieldArray[index - 1].distance_max)) &&
      Number(fieldArray[index - 1].distance_max) !== distanceMin
    ) {
      return 'validation.should_equal';
    }
    return undefined;
  };

  const validate = createValidator([isInteger, validationFn, isNumber]);
  return (
    <FormikInputField
      name={`price_increments.${index}.distance_min`}
      type="text"
      validate={validate}
      showErrorMessage={false}
      allowClear={allowClear}
    />
  );
};
