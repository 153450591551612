import { Alert } from '@creditornot/cb-alert';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { FormSection, FormViewText, FormikInputField, FormRowV2 as Row } from 'components';
import { ApiErrorContainer } from 'views/settings/CompanySettingsV2/ApiErrorContainer';
import { isNotEmptyOrZero, processError } from 'utils';
import { createValidator, min, max } from 'validation';
import { CorporateV2 } from 'modules/corporates/types';

import { EditButton } from '../EditButton';
import { RenderMode } from '../../types';
import { SaveCancelButtons } from '../SaveCancelButtons';

type Props = {
  renderMode: Exclude<RenderMode, 'signup'>;
  onEditClick?: () => void;
  onSaveClick?: () => void;
  onCancelClick?: () => void;
  isSubmitting?: boolean;
  dirty?: boolean;
  error?: any;
};

const HalfWidth = styled.div`
  width: 50%;
`;

const validate = createValidator([min(0), max(100)]);

const DefaultViewRender = ({ value }: { value?: number | string }) => (
  <FormViewText>{isNotEmptyOrZero(value) ? value : '-'}</FormViewText>
);

/**
 * A bit easier to read than the putting all of this logic in the render function.
 */
const renderMap: {
  [key: string]: {
    [key in Props['renderMode']]: (args: {
      value?: string | number;
      hint?: string;
    }) => JSX.Element | null;
  };
} = {
  subscription_fee_discount: {
    edit: () => (
      <HalfWidth>
        <FormikInputField
          validate={validate}
          type="number"
          name="invoice_discounts.subscription_fee.percentage"
        />
      </HalfWidth>
    ),
    view: DefaultViewRender,
  },
};

export const Discounts = ({
  renderMode,
  onEditClick,
  onSaveClick,
  onCancelClick,
  isSubmitting,
  dirty,
  error,
}: Props) => {
  const { values } = useFormikContext<CorporateV2>();
  const { formatMessage } = useIntl();

  return (
    <FormSection
      title={formatMessage({ id: 'corporate-form.discounts' })}
      topRightItem={onEditClick && <EditButton renderMode={renderMode} onEditClick={onEditClick} />}
    >
      <Alert variant="info" size="small">
        {formatMessage({ id: 'corporate-form.employee-disclaimer' })}
      </Alert>
      <Row
        title={formatMessage({ id: 'corporate-form.discounts.wolt-plus' })}
        content={renderMap['subscription_fee_discount'][renderMode]({
          value: values.invoice_discounts?.subscription_fee?.percentage,
        })}
      />
      {error && (
        <ApiErrorContainer>
          <Alert variant="error" size="medium" title={processError(error).message}>
            {processError(error).data}
          </Alert>
        </ApiErrorContainer>
      )}
      <SaveCancelButtons
        renderMode={renderMode}
        onSaveClick={onSaveClick}
        onCancelClick={onCancelClick}
        isSubmitting={isSubmitting}
        dirty={dirty}
      />
    </FormSection>
  );
};
