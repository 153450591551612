import { useField } from 'formik';
import { MultiSelect, MultiSelectProps } from '@creditornot/cb-select';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { getFormikFieldError } from 'validation/formik';

import { FormikFieldContainer } from './FormikFieldContainer';

export type BaseOption = { label: string; value: string };

export type OptionGroup<Option extends BaseOption> = {
  label: string | React.ReactElement;
  options: Option[];
};
export interface Props<Option extends BaseOption> {
  alwaysShowError?: boolean;
  name: string;
  validate?: (value: any) => string | void | Promise<string | void>;
  className?: string;
  options: (Option | OptionGroup<Option>)[];
  multiSelectProps?: Partial<MultiSelectProps<Option>>;
}

export function FormikMultiSelectField<Option extends BaseOption>({
  alwaysShowError = false,
  name,
  validate,
  className,
  multiSelectProps,
  options,
}: Props<Option>) {
  const { formatMessage } = useIntl();

  const [{ value }, meta, { setValue, setTouched }] = useField({
    name,
    validate,
  });

  const formikFieldError = useMemo(
    () => getFormikFieldError(meta, alwaysShowError),
    [alwaysShowError, meta],
  );

  const errorMessage = useMemo(
    () =>
      formikFieldError &&
      formatMessage({ id: formikFieldError.messageKey }, formikFieldError.values),
    [formatMessage, formikFieldError],
  );

  return (
    <FormikFieldContainer errorMessage={errorMessage} className={className}>
      <MultiSelect
        onChange={(value) => setValue(value)}
        options={options}
        value={value}
        invalid={!!errorMessage}
        placeholder={formatMessage({ id: 'common.select-placeholder' })}
        {...multiSelectProps}
        onBlur={() => !meta.touched && setTouched(true)}
      />
    </FormikFieldContainer>
  );
}
