import { useField } from 'formik';

import { useI18n } from 'i18n';
import { RadioInputCardGroup } from 'components';

const PolicyRestrictOrderTimeField = () => {
  const { getLocalizedMessage } = useI18n();
  const [limitDeliveryLocationFieldInput, , limitDeliveryLocationFieldHelpers] =
    useField<string>('limit_delivery_location');

  return (
    <RadioInputCardGroup
      name={limitDeliveryLocationFieldInput.name}
      value={limitDeliveryLocationFieldInput.value}
      onChange={(e) => limitDeliveryLocationFieldHelpers.setValue(e.target.value)}
      direction="row"
      stretched
      options={[
        {
          label: getLocalizedMessage('policies.any-location'),
          value: 'false',
        },
        {
          label: getLocalizedMessage('policies.specific-location'),
          value: 'true',
        },
      ]}
    />
  );
};

export default PolicyRestrictOrderTimeField;
