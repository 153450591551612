import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { IconButton } from '@creditornot/cb-button';
import { typographyCss } from '@creditornot/cb-ingredients';
import { MoreHorizontal } from '@creditornot/cb-icons';
import { Avatar } from '@creditornot/cb-avatar';
import { useState } from 'react';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { useMedia } from 'modules/media';
import { UserMenuButton } from 'components';

import { UserInfoDropdown } from './UserInfoDropdown';

const Root = styled.div`
  display: flex;
  line-height: 12px;
  align-items: center;
`;

const Container = styled.div`
  width: calc(100% - ${16 + 36}px);
  padding-inline-start: 16px;
  flex: 1;
`;

const Username = styled.span`
  ${typographyCss.Body3()}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Email = styled.div`
  ${typographyCss.Small()}
  color: ${color.textSubdued};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledAvatar = styled(Avatar)`
  min-width: 36px;
  cursor: pointer;
`;

const UserInfo = () => {
  const { user } = useAuthState();
  const medium = useMedia('medium');
  const [open, setOpen] = useState<boolean>(false);

  const userNameInitials = `${user?.name.first_name.charAt(0)}${user?.name.last_name.charAt(0)}`;

  if (!user) {
    return null;
  }

  if (medium) {
    return (
      <UserInfoDropdown showUserInfo show={open} onClickOutside={() => setOpen(false)}>
        <UserMenuButton
          caretDirection={open ? 'up' : 'down'}
          user={user}
          onClick={() => setOpen((state) => !state)}
        />
      </UserInfoDropdown>
    );
  }

  return (
    <Root>
      <StyledAvatar imgSrc={user?.profile_picture?.url} size="small" initials={userNameInitials} />
      <Container>
        <Username>{`${user.name.first_name} ${user.name.last_name}`}</Username>
        <Email>{user.email}</Email>
      </Container>
      <UserInfoDropdown>
        <IconButton background="staticWhite" size="tiny" variant="black">
          <MoreHorizontal />
        </IconButton>
      </UserInfoDropdown>
    </Root>
  );
};

export default UserInfo;
