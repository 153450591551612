import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCallback } from 'react';

import { LottieComponent } from 'modules/lotties';
import { breakpoints } from 'modules/media';
import { LayoutWithLottie } from 'components/LayoutWithLottie';
import { authService } from 'modules/auth/authenticator';

const GirlWires = styled(LottieComponent).attrs({
  animation: 'girlWires',
})`
  height: 200px;

  @media (max-width: ${breakpoints.small}px) {
    max-height: 150px;
  }
`;

const AccessDeniedView = () => {
  const { formatMessage } = useIntl();

  const logout = useCallback(() => {
    window.location.replace(authService.getLogoutUri());
  }, []);

  return (
    <LayoutWithLottie
      lottie={<GirlWires />}
      title={<FormattedMessage id="views.access-denied.title" />}
      description={<FormattedMessage id="views.access-denied.subtitle" />}
      buttonText={formatMessage({ id: 'views.access-denied.link' })}
      buttonProps={{ onClick: logout }}
      buttonTextHasArrow
    />
  );
};

export default AccessDeniedView;
