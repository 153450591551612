import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { BUGSNAG_API_KEY, env } from 'config';

export const bugsnagClient = Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  appVersion: env.APP_VERSION,
  releaseStage: env.NODE_ENV,
  enabledReleaseStages: ['production'],
  redactedKeys: ['access_token', /^password$/i],
  logger: null,
  metadata: {
    NODE_ENV: env.NODE_ENV,
    APP_VERSION: env.APP_VERSION,
  },
  plugins: [new BugsnagPluginReact()],
});
