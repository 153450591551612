import { ReactNode, useContext } from 'react';

import { FormRowV2 } from 'components';

import { FormCardStatusContext } from './FormCard';

type RowProps = {
  title?: string;
  subtitle?: string;
  className?: string;
  subtitleOnlyForEdit?: boolean;
  children?: ReactNode;
};

const FormRow = ({ title, subtitle, subtitleOnlyForEdit, className, children }: RowProps) => {
  const { renderState } = useContext(FormCardStatusContext);
  const showSubtitle = subtitleOnlyForEdit ? renderState === 'edit' : true;

  return (
    <FormRowV2
      className={className}
      title={title}
      subtitle={showSubtitle ? subtitle : undefined}
      content={children}
    />
  );
};

const ViewMode = ({ children }: { children: React.ReactNode }) => {
  const { renderState } = useContext(FormCardStatusContext);
  return renderState === 'view' ? <>{children}</> : null;
};

const EditMode = ({ children }: { children: React.ReactNode }) => {
  const { renderState } = useContext(FormCardStatusContext);
  return renderState === 'edit' ? <>{children}</> : null;
};

FormRow.ViewMode = ViewMode;
FormRow.EditMode = EditMode;

export { FormRow };
