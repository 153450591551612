import styled from 'styled-components';
import { FormHeader, FormRow, InputContainer } from '@creditornot/cb-form';

import { LocalizedMessage, useI18n } from 'i18n';
import { breakpoints } from 'modules/media';
import {
  CompanyNameField,
  BusinessIdField,
  CountryField,
  StreetAddressField,
  PostalCodeField,
  CityField,
  ContactPersonNameField,
  ContactPersonEmailField,
  ContactPersonPhoneNumberField,
  CompanyIndustryField,
  CompanyTypeField,
} from 'modules/corporates/components';
import { CountryConfig } from 'modules/country-configs/types';

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: 24px;
  }
`;

const StyledFormRow = styled(FormRow)`
  @media (max-width: ${breakpoints.medium}px) {
    flex-direction: column;

    & > div {
      margin-bottom: 8px;
    }
  }
`;

interface Props {
  countryConfig: CountryConfig;
  onCountryChange: (country: string) => void;
  isEditForm?: boolean;
}

export const GeneralInformationFormSection = ({
  countryConfig,
  onCountryChange,
  isEditForm = false,
}: Props) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <div>
      <FormHeader horizontalPadding>
        <LocalizedMessage messageKey="corporate-form.general-information-section-title" />
      </FormHeader>
      <StyledFormRow
        horizontalPadding
        labelHtmlFor="name"
        label={getLocalizedMessage('corporate-form.name')}
        required
        labelAlign="center"
      >
        <CompanyNameField alwaysShowError={isEditForm} />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        labelHtmlFor="business_id"
        required
        label={getLocalizedMessage('common.business_id')}
        labelAlign="center"
      >
        <BusinessIdField alwaysShowError={isEditForm} />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        labelHtmlFor="country"
        required
        label={getLocalizedMessage('common.country')}
        labelAlign="center"
      >
        <CountryField onCountryChange={onCountryChange} />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        labelHtmlFor="address"
        required
        label={getLocalizedMessage('common.address')}
      >
        <StyledInputContainer
          labelHtmlFor="address"
          label={getLocalizedMessage('common.street_address')}
        >
          <StreetAddressField alwaysShowError={isEditForm} />
        </StyledInputContainer>
        <StyledInputContainer
          labelHtmlFor="post_code"
          label={getLocalizedMessage('common.postal_code')}
        >
          <PostalCodeField alwaysShowError={isEditForm} />
        </StyledInputContainer>
        <StyledInputContainer labelHtmlFor="city" label={getLocalizedMessage('common.city')}>
          <CityField alwaysShowError={isEditForm} />
        </StyledInputContainer>
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        labelHtmlFor="contact_name"
        required
        label={getLocalizedMessage('corporate-form.contact-person-title')}
      >
        <StyledInputContainer
          labelHtmlFor="contact_name"
          label={getLocalizedMessage('common.name')}
        >
          <ContactPersonNameField alwaysShowError={isEditForm} />
        </StyledInputContainer>
        <StyledInputContainer
          labelHtmlFor="contact_email"
          label={getLocalizedMessage('common.email')}
        >
          <ContactPersonEmailField alwaysShowError={isEditForm} />
        </StyledInputContainer>
        <StyledInputContainer
          labelHtmlFor="contact_phone"
          label={getLocalizedMessage('common.phone-number')}
        >
          <ContactPersonPhoneNumberField alwaysShowError={isEditForm} />
        </StyledInputContainer>
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        labelHtmlFor="industry"
        label={getLocalizedMessage('common.industry')}
        labelAlign="center"
      >
        <CompanyIndustryField menuPlacement="top" />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        labelHtmlFor="company-type"
        label={getLocalizedMessage('corporate-form.company-type')}
      >
        <CompanyTypeField countryConfig={countryConfig} />
      </StyledFormRow>
    </div>
  );
};
