import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled, { css } from 'styled-components';
import { colors } from '@creditornot/cb-ingredients';

import { RadioInputGroup, RadioInput } from 'components';
import { breakpoints } from 'modules/media';

import { RadioInputCardGroupProps, RadioInputCardProps } from './types';

interface RootProps {
  disabled: boolean;
  direction: 'row' | 'column';
  checked: boolean;
  width: number;
  stretched: boolean;
}

const Root = styled.label<RootProps>`
  border: 1px solid;
  border-radius: 8px;
  box-shadow: 0 0 2px 0 ${colors.pepper12};
  padding: 12px 12px 15px;
  display: flex;
  align-items: center;
  transition: all 250ms ease;

  &:has(:focus-visible) {
    outline: none;
    box-shadow: 0 0 0 3px ${color.outlineFocused};
  }

  ${({ disabled, checked }) => {
    if (disabled) {
      return css`
        opacity: 0.4;
        cursor: not-allowed;
        border-color: ${color.border};
      `;
    } else if (checked) {
      return css`
        background-color: ${colors.wolt8};
        border-color: ${color.borderSelected};
      `;
    } else {
      return css`
        cursor: pointer;
        border-color: ${color.border};

        &:hover {
          box-shadow: 0 0 8px 0 ${colors.pepper12};
        }

        &:active {
          box-shadow: 0 0 2px 0 ${colors.pepper12};
        }
      `;
    }
  }}

  ${({ width, stretched, direction }) => {
    if (direction === 'row') {
      return css`
        width: ${`${width}px`};
        flex: ${stretched && 1};
      `;
    }
  }}

  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
    margin-inline-end: 0;
    margin-bottom: 0;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline-start: 8px;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.15px;
  line-height: 20px;
`;

const Explanation = styled.div`
  padding-top: 4px;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 16px;
  color: ${color.textSubdued};
`;

const RadioInputCard = ({
  disabled = false,
  checked = false,
  option,
  name,
  direction = 'column',
  optionWidth = 200,
  stretched = false,
  ...rest
}: RadioInputCardProps) => {
  return (
    <Root
      data-test-id={`${name}-${option.value}`}
      checked={checked}
      disabled={disabled}
      direction={direction}
      width={optionWidth}
      stretched={stretched}
      htmlFor={`${name}-${option.value}`}
    >
      <RadioInput
        disabled={disabled}
        checked={checked}
        name={name}
        {...rest}
        value={option.value}
      />
      <Body>
        <Text>{option.label}</Text>
        {option.explanation && <Explanation>{option.explanation}</Explanation>}
      </Body>
    </Root>
  );
};

const RadioInputCardGroup = (props: RadioInputCardGroupProps) => {
  return <RadioInputGroup {...props} render={RadioInputCard} />;
};

export default RadioInputCardGroup;
