import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { useIntl } from 'react-intl';
import { DistanceBasedDeliveryPriceRange } from '@creditornot/daas-core-api-client';

import { LocalizedCurrencySymbol, parseCurrency } from 'i18n';

export type CustomPricingPreviewProps = {
  priceIncrements: DistanceBasedDeliveryPriceRange[];
  basePrice: number;
  currency: string;
  className?: string;
};

const Body3 = styled.span`
  ${typographyCss.Body3()}
`;

export const CustomPricingPreviewV2 = ({
  basePrice,
  currency,
  className,
  priceIncrements,
}: CustomPricingPreviewProps) => {
  const { formatMessage } = useIntl();
  const localCurencySymbol = LocalizedCurrencySymbol({ currency });
  return (
    <div className={className}>
      <Body3>
        <b>{priceIncrements.length}</b>{' '}
        {formatMessage({ id: 'corporate-form.custom-price.price-distance-ranges' })}
        {priceIncrements.map((increment, index) => {
          return (
            <p key={index}>
              {increment.distance_min} m to{' '}
              {increment.distance_max === 0 ? 0 : increment.distance_max - 1} m:{' '}
              {parseCurrency(basePrice + increment.fee, currency)}
              {localCurencySymbol}
            </p>
          );
        })}
      </Body3>
    </div>
  );
};
