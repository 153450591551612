import {
  LegacyPopover as Popover,
  LegacyPopoverPlacement as PopoverPlacement,
} from '@creditornot/cb-popover';
import { MenuActionItem, MenuItemGroup } from '@creditornot/cb-menu';
import styled from 'styled-components';

interface Props {
  showCaret?: boolean;
  placement?: PopoverPlacement;
  content?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  css?: string;
  actions?: {
    label?: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }[];
}

const StyledMenuItemGroup = styled(MenuItemGroup)`
  padding: 0 8px;
`;

const StyledPopover = styled(Popover)`
  height: 36px;
`;

const Root = styled.div`
  height: 36px;
`;

const DropdownActionButton: React.FC<Props> = ({
  placement = 'left',
  children,
  actions,
  content,
  showCaret = true,
  className,
  css,
}) => {
  return (
    <Root className={className} css={css}>
      <StyledPopover
        renderArrow={showCaret}
        placement={placement}
        showOnHover={false}
        content={
          content ?? (
            <StyledMenuItemGroup>
              {actions?.map((action, index) => {
                return (
                  <MenuActionItem key={index} variant="black" onClick={action?.onClick}>
                    {action.label}
                  </MenuActionItem>
                );
              })}
            </StyledMenuItemGroup>
          )
        }
      >
        {children}
      </StyledPopover>
    </Root>
  );
};

export default DropdownActionButton;
