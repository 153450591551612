import { useCallback } from 'react';
import { Column, DataTable } from '@creditornot/cb-data-table';
import styled from 'styled-components';
import { Pencil } from '@creditornot/cb-icons';
import { FormattedDate } from 'react-intl';

import { PaginatedData } from 'types/PaginatedData';
import { SearchInput, DataCellContent, IconButtonWithHelpText } from 'components';
import { AgreementParty } from 'modules/agreement-parties/types';

import { useAgreementPartiesQueryParams } from './useAgreementPartiesQueryParams';

interface Props {
  data: PaginatedData<AgreementParty> | null | undefined;
  onEdit: (id: string) => void;
  isLoading: boolean;
}

const StyledSearchInput = styled(SearchInput)`
  max-width: 340px;
`;

const columns: Column<AgreementParty, { onEdit: Props['onEdit'] }>[] = [
  {
    id: 'legal_name',
    name: 'Legal name',
    render: ({ record }) => (
      <DataCellContent
        subContent={
          <>
            Created at <FormattedDate value={new Date(record.created_at)} />
          </>
        }
      >
        {record.legal_name}
      </DataCellContent>
    ),
  },
  {
    id: 'country_code',
    name: 'Country code',
    render: ({ record }) => <div>{record.country_code}</div>,
  },
  {
    id: 'actions',
    name: '',
    alignContent: 'right',
    render: ({ record, onEdit }) => (
      <div
        style={{
          marginInlineStart: 'auto',
          display: 'inline-block',
        }}
      >
        <IconButtonWithHelpText
          onClick={() => onEdit(record.id)}
          variant="grey"
          tooltipText="Edit"
          tooltipPlacement="left"
        >
          <Pencil height="16px" />
        </IconButtonWithHelpText>
      </div>
    ),
  },
];

const AgreementPartiesDataTable: React.FC<Props> = ({ data, isLoading, onEdit }) => {
  const [{ legal_name, page }, setQueryParams] = useAgreementPartiesQueryParams();

  const isSearching = !!legal_name;

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setQueryParams({
        legal_name: e.target.value,
      }),
    [setQueryParams],
  );

  const handleClearSearch = useCallback(
    () =>
      setQueryParams({
        legal_name: '',
      }),
    [setQueryParams],
  );

  const handlePageChange = useCallback(
    (page: number) =>
      setQueryParams({
        page,
      }),
    [setQueryParams],
  );

  return (
    <DataTable
      header={
        <StyledSearchInput
          placeholder="Search by legal name"
          type="text"
          value={legal_name}
          onChange={handleSearch}
          onClearClick={handleClearSearch}
        />
      }
      horizontalPadding="wide"
      loading={isLoading}
      dataSource={data?.results || []}
      columns={columns}
      getRowKey={({ id }) => id}
      emptyMessage={{
        title: isSearching
          ? "We couldn't find any agreement parties with that legal name, sorry! 😕"
          : 'No agreement party is created yet',
      }}
      componentProps={{
        onEdit,
      }}
      pagination={
        data
          ? {
              activePage: page,
              disableWhenLoading: true,
              onPageChange: handlePageChange,
              pageCount: data.page,
            }
          : undefined
      }
    />
  );
};

export default AgreementPartiesDataTable;
