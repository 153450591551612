import { color } from '@creditornot/cb-ingredients/design-tokens';
import { OrderCardContainer, OrderCardContainerHighlightText } from '@creditornot/daas-common';
import { DeliveryOrderResponse } from '@creditornot/wd-api-client';
import { differenceInCalendarDays, differenceInMinutes } from 'date-fns';
import React, { forwardRef } from 'react';
import Bugsnag from '@bugsnag/browser';
import styled from 'styled-components';

import { useMedia } from 'modules/media';
import { useI18n } from 'i18n';

import { differenceInHourRoundToHalfHour } from './utils';

const StyledOrderCard = styled(OrderCardContainer)`
  svg {
    fill: ${color.textInverse};
  }
`;

export const OrderCard = forwardRef<
  HTMLDivElement,
  {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    order: DeliveryOrderResponse;
  }
>(({ order, onClick }, ref) => {
  const isSmallView = useMedia('small');
  const { getLocalizedMessage, getLocalizedDateTime } = useI18n();

  const { id, dropoff, parcels, pickup, orderNumber } = order;
  const minUntilPickup = pickup?.eta ? differenceInMinutes(new Date(pickup.eta), new Date()) : 60;

  if (!pickup.eta) {
    Bugsnag.notify(`Pickup eta is empty for order ${id}`);
    return null;
  }
  return (
    <StyledOrderCard
      sender={pickup?.sender?.name}
      eta={new Date(pickup.eta)}
      layoutVariant={isSmallView ? 'mini' : 'full'}
      onClick={onClick}
      orderId={`#${orderNumber ?? ''}`}
      parcel={getLocalizedMessage('deliveries.parcel-with-count', {
        itemCount: parcels?.length ?? 0,
      })}
      pickupStatusSubtitle={(type) => {
        if (!pickup.eta) return null;
        switch (type) {
          case 'inAnHour':
            return `(${getLocalizedDateTime(new Date(pickup.eta), {
              hour: '2-digit',
              hour12: false,
              minute: '2-digit',
            })})`;
          case 'late':
            return null;
          case 'scheduledDifferentDay':
            return getLocalizedMessage('deliveries.scheduled-for-date-time', {
              date: getLocalizedDateTime(new Date(pickup.eta), {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }),
              time: getLocalizedDateTime(new Date(pickup.eta), {
                hour: '2-digit',
                hour12: false,
                minute: '2-digit',
              }),
            });
          case 'scheduledSameDay':
            return getLocalizedMessage('deliveries.scheduled-for-today', {
              time: getLocalizedDateTime(new Date(pickup.eta), {
                hour: '2-digit',
                hour12: false,
                minute: '2-digit',
              }),
            });
        }
      }}
      pickupStatusText={(type) => {
        if (!pickup.eta) return null;
        switch (type) {
          case 'inAnHour':
            return getLocalizedMessage('deliveries.estimated-pickup-min', {
              b: (text: string) => (
                <OrderCardContainerHighlightText key={text}>{text}</OrderCardContainerHighlightText>
              ),
              count: minUntilPickup,
            });
          case 'late':
            return getLocalizedMessage('deliveries.pick-up-is-late', {
              b: (text: string) => (
                <OrderCardContainerHighlightText key={text}>{text}</OrderCardContainerHighlightText>
              ),
              count: Math.abs(minUntilPickup),
            });
          case 'scheduledDifferentDay':
            return getLocalizedMessage('deliveries.estimated-pickup-day', {
              b: (text: string) => (
                <OrderCardContainerHighlightText key={text}>{text}</OrderCardContainerHighlightText>
              ),
              count: differenceInCalendarDays(new Date(pickup.eta), new Date()),
            });
          case 'scheduledSameDay':
            return getLocalizedMessage('deliveries.estimated-pickup-hour', {
              b: (text: string) => (
                <OrderCardContainerHighlightText key={text}>{text}</OrderCardContainerHighlightText>
              ),
              count: differenceInHourRoundToHalfHour(new Date(pickup.eta), new Date()),
            });
        }
      }}
      recipient={dropoff?.recipient?.name || ''}
      ref={ref}
    />
  );
});
