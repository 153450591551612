import styled from 'styled-components';

const Root = styled.div`
  margin-top: 36px;

  :nth-child(1) {
    margin: 0;
  }
`;

const Section: React.FC<{ children?: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  return (
    <Root className={className}>
      <div>{children}</div>
    </Root>
  );
};

export default Section;
