import { color } from '@creditornot/cb-ingredients/design-tokens';
import { DataTable, Column, RowClickFn } from '@creditornot/cb-data-table';
import { MessageBubbleTextFilled } from '@creditornot/cb-icons';
import { typographyCss } from '@creditornot/cb-ingredients';
import { isToday, intlFormatDistance } from 'date-fns';
import { useCallback, useState, useEffect, FC } from 'react';
import styled from 'styled-components';
import {
  DeliveryOrderApiListDeliveryOrdersRequest as ListDeliveryOrdersRequest,
  DeliveryOrderResponse as DeliveryOrder,
  DeliveryOrderResponse,
} from '@creditornot/wd-api-client';
import { SequentialPagination } from '@creditornot/daas-common';
import { rem } from 'polished';
import { Alert } from '@creditornot/cb-alert';
import { FormattedDate, useIntl } from 'react-intl';
// eslint-disable-next-line no-restricted-imports -- TODO migrate to color token

import { useFetchDeliveries } from 'modules/deliveries';
import { LocalizedNumber } from 'i18n';
import { useDebounce } from 'modules/hooks';
import { DataCellContent } from 'components';
import { useCorporateDeliverySettingsComplete } from 'modules/corporates';
import { FinalizeSettingsMessage } from 'views/delivery-home/FinalizeSettingsMessage';
import { processError } from 'utils';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';

import { DeliveryStatusTag } from './DeliveryStatusTag';
import { IntineraryField } from './IntineraryField';
import { useDeliveriesQueryParams } from './useDeliveriesQueryParams';
import { PageSizeSelector } from './PageSizeSelector';
import { DeliveryDetailsModal } from './DeliveryDetailsModal';
import { DeliveryDetail, useDeliveryDetails } from './useDeliveryDetails';

const StyledDataTable = styled(DataTable)`
  tbody {
    vertical-align: top;
  }

  tbody > tr td {
    padding-top: 16px;
    padding-bottom: 16px;
  }
` as typeof DataTable;

const FinalizeSettingsMessageContainer = styled.div`
  margin: ${rem('24px')};
`;

const OrderNumber = styled.div`
  ${typographyCss.Title2()}
  color: ${color.text};
`;

const StyledAlert = styled(Alert)`
  margin: 16px 24px;
`;

const PaginationContainer = styled.div`
  display: grid;
  grid-column-gap: 12px;
  grid-template-areas: 'empty pagination pageSizeSelector';
  grid-template-columns: 1fr 3fr 1fr;
  padding: 16px 24px;
  border-top: 1px solid ${color.border};
`;

const StyledSequentialPagination = styled(SequentialPagination)`
  grid-area: pagination;
  place-self: center center;
  max-width: 100%;
`;

const StyledPageSizeSelector = styled(PageSizeSelector)`
  grid-area: pageSizeSelector;
  place-self: center end;
`;

const DeliveryDetailsItem = styled.div`
  & + & {
    margin-top: 4px;
  }
`;

const DeliveryDetailsItemSubContent = styled.div`
  ${typographyCss.Small()};
  color: ${color.textSubdued};
`;

const StyledMessageBubble = styled(MessageBubbleTextFilled)`
  width: 12px;
  margin-inline-start: 8px;
  fill: ${color.textSubdued};
`;

const DeliveryDetailDataCell: React.FC<{ order: DeliveryOrder }> = ({ order }) => {
  const details = useDeliveryDetails(order);
  const { formatMessage } = useIntl();
  const renderTitle = (detailType: DeliveryDetail['type']) => {
    switch (detailType) {
      case 'large-heavy-parcel':
        return formatMessage({ id: 'deliveries.delivery-details.large-heavy-parcel' });
      case 'parcel':
        return formatMessage(
          { id: 'deliveries.parcel-with-count' },
          {
            itemCount: order.parcels?.length ?? 0,
          },
        );
      case 'no-contact':
        return formatMessage({ id: 'deliveries.delivery-details.no-contact' });
      case 'pin-code-check':
        return formatMessage({ id: 'deliveries.delivery-details.pin-code-check' });
      case 'age-check':
        return formatMessage({ id: 'deliveries.delivery-details.age-check' });
      case 'cash-to-collect':
        return formatMessage({ id: 'deliveries.delivery-details.cash-to-collect' });
      default:
        return '';
    }
  };

  return (
    <>
      {details
        .filter((detail) => detail.enabled)
        .map((detail) => (
          <DeliveryDetailsItem key={detail.type}>
            {renderTitle(detail.type)}
            {(detail.type === 'large-heavy-parcel' || detail.type === 'parcel') &&
            order.parcels?.some((parcel) => !!parcel.description) ? (
              <StyledMessageBubble />
            ) : null}
            {detail.value && (
              <DeliveryDetailsItemSubContent>{detail.value}</DeliveryDetailsItemSubContent>
            )}
          </DeliveryDetailsItem>
        ))}
    </>
  );
};

const useColumns = (): Column<DeliveryOrder, Record<string, unknown>>[] => {
  const { formatMessage, locale } = useIntl();

  const columns: Column<DeliveryOrder, Record<string, unknown>>[] = [
    {
      id: 'order-info',
      name: formatMessage({ id: 'deliveries.order' }),
      render: ({ record }) => (
        <DataCellContent
          subContent={
            record.merchantOrderReferenceId &&
            record.merchantOrderReferenceId !== record.orderNumber && (
              // not display merchant ref when it is the same with orderNumber because backend has a behavior that if merchant ref is empty, the ref and order number will be the same
              // r-api does not receive a separate orderNumber field but it is generated from merchant ref and daas-core controls what orderNumber is generated by sending merchant ref to r-api
              <span>{record.merchantOrderReferenceId}</span>
            )
          }
        >
          <div data-test-id={`order-number-${record.id}`}>
            <OrderNumber>#{record.orderNumber}</OrderNumber>
          </div>
        </DataCellContent>
      ),
      alignContentVertical: 'top',
    },
    {
      id: 'created-at',
      name: formatMessage({ id: 'deliveries.created-at' }),
      render: ({ record }) => (
        <DataCellContent
          subContent={
            record.createdAt
              ? intlFormatDistance(new Date(record.createdAt), new Date(), {
                  locale,
                  numeric: 'always',
                })
              : undefined
          }
        >
          {record.createdAt ? (
            <FormattedDate value={new Date(record.createdAt)} dateStyle="short" timeStyle="short" />
          ) : null}
        </DataCellContent>
      ),
      alignContentVertical: 'top',
    },
    {
      id: 'itinerary',
      name: formatMessage({ id: 'deliveries.itinerary' }),
      render: ({ record }) => <IntineraryField order={record} />,
      alignContentVertical: 'top',
    },
    {
      id: 'recipient',
      name: formatMessage({ id: 'deliveries.recipients' }),
      render: ({ record }) => (
        <div data-test-id={`delivery-recipient-${record.id}`}>
          <DataCellContent subContent={record.dropoff?.recipient?.phoneNumber}>
            {record.dropoff?.recipient?.name}
          </DataCellContent>
        </div>
      ),
      alignContentVertical: 'top',
    },
    {
      id: 'fee',
      name: formatMessage({ id: 'deliveries.delivery-fee' }),
      render: ({ record }) =>
        record.price ? (
          <div data-test-id={`delivery-price-${record.id}`}>
            <DataCellContent>
              <LocalizedNumber
                value={record.price.amounts}
                currency={record.price.currency}
                disableCurrencyParser
              />
            </DataCellContent>
          </div>
        ) : null,
      alignContentVertical: 'top',
    },
    {
      id: 'delivery-details',
      name: formatMessage({ id: 'deliveries.delivery-details-column' }),
      render: ({ record }) => <DeliveryDetailDataCell order={record} />,
      alignContentVertical: 'top',
    },
    {
      id: 'status',
      name: formatMessage({ id: 'deliveries.status' }),
      render: ({ record }) => (
        <>
          <div data-test-id={`delivery-status-${record.id}`}>
            <DeliveryStatusTag status={record.status} />
          </div>
        </>
      ),
      alignContentVertical: 'top',
    },
  ];
  return columns;
};

const useFilters = () => {
  const {
    params: { from, to, pageSize, deliveryStatus, searchQuery },
    setQueryParams,
  } = useDeliveriesQueryParams();
  const [activePage, setActivePage] = useState(1);
  const [pageToken, setPageToken] = useState('');

  useEffect(() => {
    setActivePage(1);
    setPageToken('');
  }, [from, to, pageSize, deliveryStatus, searchQuery]);

  const nextPage = useCallback((pageToken: string) => {
    setActivePage((value) => value + 1);
    setPageToken(pageToken);
  }, []);

  const previousPage = useCallback((pageToken: string) => {
    setActivePage((value) => value - 1);
    setPageToken(pageToken);
  }, []);

  return [
    {
      from,
      to,
      pageSize,
      deliveryStatus,
      searchQuery,
      activePage,
      pageToken,
    },
    { setQueryParams, nextPage, previousPage },
  ] as const;
};

const calculateTotalPage = (totalResult: number, pageSize: number) => {
  return totalResult < pageSize ? 1 : Math.ceil(totalResult / pageSize);
};

/**
 * @param newCount can be undefined between useQuery calls
 * @description persist the latest totalCount and skip undefined value between useQuery calls. For the number of page count to stay the same
 */
const useTotalCount = (newCount: number | undefined) => {
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (newCount) {
      setTotalCount(newCount);
    }
  }, [newCount]);

  return totalCount;
};

export const DeliveriesTable: FC<{
  corporateId: string;
}> = ({ corporateId }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentOrder, setCurrentOrder] = useState<DeliveryOrderResponse | undefined>(undefined);

  const { isWoltEmployeeWithOpsToolsAccess } = useIsUserWoltEmployee();

  const { formatMessage } = useIntl();
  const [
    { activePage, from, to, pageSize, deliveryStatus, searchQuery, pageToken },
    { setQueryParams, nextPage, previousPage },
  ] = useFilters();
  const columns = useColumns();
  const debouncedSearch = useDebounce(searchQuery, 500);

  const listDeliveryOrdersParams: ListDeliveryOrdersRequest = {
    corporateId,
    userId: '',
    from: from.toISOString(),
    to: to.toISOString(),
    pageSize,
    pageToken,
    deliveryStatus: (deliveryStatus as ListDeliveryOrdersRequest['deliveryStatus']) || undefined,
    searchQuery: debouncedSearch,
    sortBy: 'createdAt',
    sortDirection: 'desc',
  };

  const { data, isLoading, error } = useFetchDeliveries(listDeliveryOrdersParams, {
    refetchInterval: isToday(to) ? 30 * 1000 : undefined,
  });

  const totalCount = useTotalCount(data?.totalCount);

  const { data: deliverySettingsComplete, error: deliverySettingsCompleteError } =
    useCorporateDeliverySettingsComplete(corporateId);

  const handleNextPageClick = useCallback(() => {
    if (data?.pagination?.nextPageToken) {
      nextPage(data.pagination.nextPageToken);
    }
  }, [data, nextPage]);

  const handlePrevPageClick = useCallback(() => {
    if (data?.pagination?.prevPageToken) {
      previousPage(data.pagination.prevPageToken);
    }
  }, [data, previousPage]);

  const handleOrderClick: RowClickFn<DeliveryOrderResponse> = useCallback((_, order) => {
    setIsModalOpen(true);
    setCurrentOrder(order);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  if (error) {
    return (
      <StyledAlert variant="error" title={processError(error).message}>
        {processError(error).data}
      </StyledAlert>
    );
  }

  if (deliverySettingsCompleteError) {
    return (
      <StyledAlert variant="error" title={processError(deliverySettingsCompleteError).message}>
        {processError(deliverySettingsCompleteError).data}
      </StyledAlert>
    );
  }

  return (
    <>
      {currentOrder && (
        <DeliveryDetailsModal
          onClose={handleModalClose}
          order={currentOrder}
          show={isModalOpen}
          showOpsToolsLink={isWoltEmployeeWithOpsToolsAccess}
        />
      )}

      {deliverySettingsComplete === true && (
        <>
          <StyledDataTable
            columns={columns}
            dataSource={data?.orders ?? []}
            emptyMessage={{
              description: formatMessage({ id: 'deliveries.all-orders-description' }),
              title: formatMessage({ id: 'deliveries.no-orders-description' }),
            }}
            getRowKey={(record) => record.woltOrderReferenceId}
            isRowClickable={() => true}
            onRowClick={handleOrderClick}
            horizontalPadding="wide"
            loading={isLoading}
            roundedCorners={false}
            verticalSpacing="spacious"
          />
          <PaginationContainer>
            <StyledSequentialPagination
              activePage={activePage}
              onNextClick={handleNextPageClick}
              onPrevClick={handlePrevPageClick}
              pages={calculateTotalPage(totalCount, pageSize)}
            />
            <StyledPageSizeSelector
              onChange={(size) => setQueryParams({ pageSize: size })}
              options={[10, 20, 50, 100]}
              value={pageSize}
            />
          </PaginationContainer>
        </>
      )}

      {deliverySettingsComplete === false && (
        <FinalizeSettingsMessageContainer>
          <FinalizeSettingsMessage corporateId={corporateId} />
        </FinalizeSettingsMessageContainer>
      )}
    </>
  );
};
