import { LocalizedMessage, LocalizedNumber } from 'i18n';
import { SpendingLimitPeriod } from 'modules/common/types';

import { budgetPeriodMessages } from './utils';

type DescriptionProps = {
  period: SpendingLimitPeriod;
  className?: string;
};

type ContentProps = {
  className?: string;
  currency: string | null;
  amount: number | null;
};

const Content = ({ amount, className, currency }: ContentProps) => {
  return (
    <span className={className}>
      {amount !== null && currency ? (
        <LocalizedNumber value={amount} currency={currency} />
      ) : (
        <LocalizedMessage messageKey="common.unlimited" />
      )}
    </span>
  );
};

const Description = ({ period, className }: DescriptionProps) => (
  <span className={className}>{budgetPeriodMessages[period]}</span>
);

export { Content, Description };
