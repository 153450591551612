import { useIntl } from 'react-intl';
import { InputContainer } from '@creditornot/cb-form';
import { Button } from '@creditornot/cb-button';
import styled from 'styled-components';

import { Corporate } from 'modules/corporates/types';

type Props = {
  onReactivateClick: () => void;
  onDeleteClick: () => void;
  onDisableClick: () => void;
  onActivateNewClick: () => void;
  status: Corporate['status'];
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const CorporateStatusModifyButtons = ({
  onReactivateClick,
  onDeleteClick,
  onDisableClick,
  onActivateNewClick,
  status,
}: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Root>
      {status !== 'active' && status !== 'disabled' && (
        <InputContainer hint={formatMessage({ id: 'corporate-form.activate-new-account-text' })}>
          <Button onClick={onActivateNewClick} size="small" variant="lightBlue">
            {formatMessage({ id: 'corporate-form.activate-new-account-button' })}
          </Button>
        </InputContainer>
      )}
      {status === 'disabled' && (
        <InputContainer hint={formatMessage({ id: 'corporate-form.activate-account-text' })}>
          <Button onClick={onReactivateClick} size="small" variant="lightBlue">
            {formatMessage({ id: 'corporate-form.activate-account-button' })}
          </Button>
        </InputContainer>
      )}
      {status === 'active' && (
        <InputContainer hint={formatMessage({ id: 'corporate-form.disable-account-text' })}>
          <Button onClick={onDisableClick} size="small" variant="lightRed">
            {formatMessage({ id: 'corporate-form.disable-account-button' })}
          </Button>
        </InputContainer>
      )}
      <InputContainer hint={formatMessage({ id: 'corporate-form.delete-account-text' })}>
        <Button size="small" variant="lightRed" onClick={onDeleteClick}>
          {formatMessage({ id: 'corporate-form.delete-account-button' })}
        </Button>
      </InputContainer>
    </Root>
  );
};
