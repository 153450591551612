import { color } from '@creditornot/cb-ingredients/design-tokens';
import { typographyCss } from '@creditornot/cb-ingredients';
import styled from 'styled-components';
import {
  LegacyPopover as Popover,
  LegacyPopoverProps as PopoverProps,
} from '@creditornot/cb-popover';
import { HelpCircleFilled } from '@creditornot/cb-icons';
import { rem } from 'polished';

export type PlacementValues = 'top' | 'bottom' | 'left' | 'right';

type Props = {
  className?: string;
  infoText: string;
  infoTooltipMaxWidth: number;
  placement?: PlacementValues;
  popoverProps: Omit<PopoverProps, 'content' | 'disabledContentPadding' | 'children' | 'placement'>;
};

const Root = styled.div``;

const PopoverContent = styled.div<{ infoTooltipMaxWidth?: number }>`
  ${typographyCss.Body3()}
  padding: ${rem(16)};
  max-width: ${({ infoTooltipMaxWidth }) =>
    infoTooltipMaxWidth ? `${infoTooltipMaxWidth}px` : 'none'};
`;

const HelpIcon = styled(HelpCircleFilled)`
  width: ${rem(12)};
  fill: ${color.text};
`;

export const IconWithHelpText = ({
  className,
  infoText,
  infoTooltipMaxWidth,
  popoverProps,
  placement = 'top',
}: Props) => {
  return (
    <Root className={className}>
      <Popover
        disableContentPadding={true}
        placement={placement}
        content={
          <PopoverContent infoTooltipMaxWidth={infoTooltipMaxWidth}>{infoText}</PopoverContent>
        }
        {...popoverProps}
      >
        <HelpIcon />
      </Popover>
    </Root>
  );
};
