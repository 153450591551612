import { Permission } from './types';

const parsePermission = (permission: string): Permission => {
  const [resource, operations = '', regions = ''] = permission.split(':');

  return {
    resource,
    operations: operations.split(''),
    regions: regions.split(','),
  };
};

const parseAuthPermissions = (permissions: string[]): { [resource: string]: Permission[] } => {
  const parsed = permissions.map(parsePermission);
  const initialAcc: { [resource: string]: Permission[] } = {};

  return parsed.reduce(
    (acc, permission) => ({
      ...acc,
      [permission.resource]: [...(acc[permission.resource] || []), permission],
    }),
    initialAcc,
  );
};

export default parseAuthPermissions;
