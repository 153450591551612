import { useMemo } from 'react';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { requiredCookieConsentCountries } from 'modules/configs/countries';

/**
 * TODO: Add more condition checks
 * [x] Country require cookie consents
 * [ ] Locale from URL
 * [ ] User geo ip location
 */
export const useShowConsentsModal = () => {
  const { user } = useAuthState();

  return useMemo(
    () =>
      requiredCookieConsentCountries.some(
        (country) => user?.country === country.code && country.requiresCookieConsent,
      ),
    [user],
  );
};
