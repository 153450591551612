import { ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import debounce from 'lodash.debounce';
import { color } from '@creditornot/cb-ingredients/design-tokens';

import { ResponsiveContainer } from 'components';
import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';

type ScrollableContainerProps = {
  children: ReactNode;
  className?: string;
};

const List = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
`;

const Root = styled(ResponsiveContainer)<{
  atTopEdge: boolean;
  atBottomEdge: boolean;
}>`
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 0;
    ${cssDirectionalValue({ ltr: 'left: 0;', rtl: 'right: 0;' })}
    z-index: 1;
    pointer-events: none;
    transition: all 0.5s ease;
  }

  &::before {
    background: linear-gradient(to bottom, ${color.bg} 0%, #fff0);
    top: 0;
  }

  &::after {
    background: linear-gradient(to top, ${color.bg} 0%, #fff0);
    bottom: 0;
  }

  ${({ atBottomEdge }) =>
    atBottomEdge &&
    css`
      &::after {
        bottom: -36px;
      }
    `}

  ${({ atTopEdge }) =>
    atTopEdge &&
    css`
      &::before {
        top: -36px;
      }
    `}
`;

const useIsAtEdges = () => {
  const [ref, setRef] = useState<HTMLUListElement | null>(null);
  const [atTopEdge, setAtTopEdge] = useState(false);
  const [atBottomEdge, setAtBottomEdge] = useState(false);

  useEffect(() => {
    if (!ref) return;

    const handleScroll = debounce(() => {
      const atTop = ref.scrollTop === 0;
      const atBottom = ref.scrollHeight - ref.scrollTop <= ref.clientHeight;
      setAtTopEdge(atTop);
      setAtBottomEdge(atBottom);
    }, 10);

    ref.addEventListener('scroll', handleScroll);

    return () => {
      ref.removeEventListener('scroll', handleScroll);
    };
  }, [ref]);

  useEffect(() => {
    if (!ref) return;

    const resizeObserver = new ResizeObserver(
      debounce(() => {
        const hasVerticalScrollbar = ref.scrollHeight > ref.clientHeight;
        setAtTopEdge(!hasVerticalScrollbar || ref.scrollTop === 0);
        setAtBottomEdge(
          !hasVerticalScrollbar || ref.scrollTop + ref.clientHeight >= ref.scrollHeight,
        );
      }, 10),
    );

    resizeObserver.observe(ref);

    return () => {
      resizeObserver.unobserve(ref);
    };
  }, [ref]);

  return { setRef, atTopEdge, atBottomEdge };
};

export const ScrollableContainer = ({ children, ...rest }: ScrollableContainerProps) => {
  const { setRef, atBottomEdge, atTopEdge } = useIsAtEdges();

  return (
    <Root atBottomEdge={atBottomEdge} atTopEdge={atTopEdge}>
      <List {...rest} ref={setRef}>
        {children}
      </List>
    </Root>
  );
};
