import styled from 'styled-components';
import { IconButton } from '@creditornot/cb-button';

import { RtlCaret } from 'components';

interface Props {
  onLeftClick: () => void;
  onRightClick: () => void;
  value: number;
}
const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FirstRowItem = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
`;

const MonthPickerNav: React.FC<Props> = ({ onLeftClick, value, onRightClick }) => (
  <Root>
    <IconButton size="tiny" background="animated" variant="black" onClickCapture={onLeftClick}>
      <RtlCaret alignment="start" />
    </IconButton>
    <FirstRowItem>{value}</FirstRowItem>
    <IconButton size="tiny" background="animated" variant="black" onClickCapture={onRightClick}>
      <RtlCaret alignment="end" />
    </IconButton>
  </Root>
);

export default MonthPickerNav;
