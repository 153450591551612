import { isNil } from 'ramda';

import { Corporate, CorporateV2 } from 'modules/corporates/types';
import { convertCentsToDisplayAmount } from 'utils';

import { EditCorporateFormValues } from '../../../views/settings/CompanySettings/types';

export const formatCorporateDataToFormValues = (corporate: Corporate): EditCorporateFormValues => {
  const singleTransactionAmount = corporate.transaction_limits
    ? convertCentsToDisplayAmount(
        corporate.transaction_limits.single_transaction_amount,
        corporate.currency,
      )
    : '';
  const dailyTransactionsAmount = corporate.transaction_limits
    ? convertCentsToDisplayAmount(
        corporate.transaction_limits.daily_transactions_amount,
        corporate.currency,
      )
    : '';

  return {
    account_manager: corporate.account_manager ?? '',
    address: corporate.address,
    billing_twice_a_month: corporate.billing_twice_a_month ? 'true' : 'false',
    business_id: corporate.business_id ?? '',
    city: corporate.city,
    contact_email: corporate.contact_email,
    contact_name: corporate.contact_name,
    contact_phone: corporate.contact_phone,
    country: corporate.country,
    credit_limit_amount: !isNil(corporate.credit_limit_amount)
      ? convertCentsToDisplayAmount(corporate.credit_limit_amount, corporate.currency)
      : '',
    customer_reference: corporate.customer_reference || '',
    electronic_invoicing_details: {
      edi: corporate.electronic_invoicing_details?.edi ?? '',
      edi_operator: corporate.electronic_invoicing_details?.edi_operator ?? '',
      vat_exemption_disclaimer:
        corporate.electronic_invoicing_details?.vat_exemption_disclaimer ?? '',
    },
    has_credit_limit: corporate.has_credit_limit,
    has_transaction_limits: corporate.has_transaction_limits,
    industry: corporate.industry ?? '',
    invoice_email_cc_recipients: corporate.invoice_email_cc_recipients,
    invoice_email: corporate.invoice_email ?? '',
    invoice_fee_percentage: String(corporate.invoice_fee_percentage),
    invoice_overdue_interest: String(corporate.invoice_overdue_interest),
    is_electronic_invoicing_enabled: !!corporate.electronic_invoicing_details,
    name: corporate.name,
    organise_invoice_by_groups: corporate.organise_invoice_by_groups ? 'true' : 'false',
    organise_order_report_by_groups: corporate.organise_order_report_by_groups ? 'true' : 'false',
    payment_term_delay_days: String(corporate.payment_term_delay_days),
    post_code: corporate.post_code,
    preferred_invoice_locale: corporate.preferred_invoice_locale || '',
    single_receipt_email: corporate.single_receipt_email || '',
    transaction_limits: {
      single_transaction_amount: singleTransactionAmount,
      daily_transactions_amount: dailyTransactionsAmount,
    },
    vat_id: corporate.vat_id ?? '',
  };
};

export const formatCorporateDataToFormValuesV2 = (corporate: CorporateV2): CorporateV2 => {
  return {
    invoice_discounts: {
      subscription_fee: {
        percentage: corporate.invoice_discounts?.subscription_fee?.percentage ?? 0,
      },
    },
  };
};
