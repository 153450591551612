import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ConfirmationModal } from 'components';
import { useDeleteSubscriptionInvites } from 'modules/subscription/useDeleteSubscriptionInvites';
import { UI_SUCCESS_DELAY } from 'modules/common/constants';

import { useSubscriptionUsersQueryParams } from '../hooks/useSubscriptionUsersQueryParams';

interface Props {
  show: boolean;
  onDeleteInvitesSuccess: () => void;
  onClose: () => void;
}

export const DeleteSubscriptionInvitesModal = ({
  show,
  onClose,
  onDeleteInvitesSuccess,
}: Props) => {
  const { formatMessage } = useIntl();
  const deleteInvites = useDeleteSubscriptionInvites();
  const [{ invites: invitesQueryParams, invite: inviteQueryParam }] =
    useSubscriptionUsersQueryParams();

  const inviteIds = useMemo(
    () => (inviteQueryParam ? [inviteQueryParam] : invitesQueryParams),
    [inviteQueryParam, invitesQueryParams],
  );

  const handleConfirm = useCallback(async () => {
    if (!inviteIds) {
      return Promise.reject('Invite ids are missing');
    }

    await deleteInvites({ inviteIds, invalidateDelay: UI_SUCCESS_DELAY });
  }, [deleteInvites, inviteIds]);

  const numberOfUsers = invitesQueryParams?.length || 1;

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      onConfirm={handleConfirm}
      title={formatMessage(
        { id: 'wolt-plus.delete-invites-modal.title' },
        { count: numberOfUsers },
      )}
      content={formatMessage(
        { id: 'wolt-plus.delete-invites-modal.text' },
        { count: numberOfUsers },
      )}
      confirmationButtonMessage={formatMessage({ id: 'common.delete' })}
      confirmationButtonVariant="lightRed"
      onSuccess={() => setTimeout(onDeleteInvitesSuccess, UI_SUCCESS_DELAY)}
    />
  );
};
