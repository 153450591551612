import { InputProps } from '@creditornot/cb-input';
import { useMemo } from 'react';

import { FormikInputField } from 'components';
import { FormikInputFieldProps } from 'components/FormikInputField';
import countries from 'modules/configs/countries';
import { useCorporate } from 'modules/corporates/useCorporate';
import { createValidator, isRequired } from 'validation';

const validate = createValidator([isRequired]);

interface Props extends Omit<FormikInputFieldProps, 'name' | 'type' | 'required' | 'validate'> {
  alwaysShowError?: boolean;
  inputProps?: Partial<InputProps>;
}

export const BusinessIdField = ({ alwaysShowError, inputProps, ...rest }: Props) => {
  const { data: corporate } = useCorporate();

  const placeholder = useMemo(() => {
    if (!corporate) {
      return undefined;
    }

    const country = countries.find((country) => country.code === corporate.country);
    return country?.identifiers?.businessIdFormat;
  }, [corporate]);

  return (
    <FormikInputField
      name="business_id"
      type="text"
      required={true}
      validate={validate}
      alwaysShowError={alwaysShowError}
      inputProps={{ id: 'business_id', placeholder: placeholder, ...inputProps }}
      {...rest}
    />
  );
};
