import styled from 'styled-components';
import { Button, TextButton } from '@creditornot/cb-button';
import { typographyCss } from '@creditornot/cb-ingredients';

import { LocalizedMessage } from 'i18n';
import { breakpoints } from 'modules/media';

type BillingInformationProps = {
  className?: string;
  onAddBillingInformationClick: () => void;
  onContactClick: () => void;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StyledBody2 = styled.div`
  ${typographyCss.Body2()}
  margin-top: 16px;
  display: block;
  max-width: 540px;

  @media (max-width: ${breakpoints.small}px) {
    ${typographyCss.Body3()}
  }
`;

const StyledBody3 = styled.span`
  ${typographyCss.Body3()}
  margin-top: 24px;
  display: block;
`;

const StyledButton = styled(Button)`
  margin-top: 24px;
`;

const Heading = styled.div`
  ${typographyCss.Heading4()}
  @media (max-width: ${breakpoints.small}px) {
    ${typographyCss.Heading5()}
  }
`;

export const BillingInformation = ({
  className,
  onAddBillingInformationClick,
  onContactClick,
}: BillingInformationProps) => {
  return (
    <Root className={className}>
      <Heading>
        <LocalizedMessage messageKey="views.dashboard.billing-information-title" />
      </Heading>

      <StyledBody2>
        <LocalizedMessage messageKey="views.dashboard.billing-information-text" />
      </StyledBody2>

      <StyledButton onClick={onAddBillingInformationClick} size="large" variant="blue">
        <LocalizedMessage messageKey="views.dashboard.add-billing-information-button" />
      </StyledButton>
      <StyledBody3>
        <LocalizedMessage
          messageKey="views.dashboard.billing-information-contact"
          values={{
            link: (text: string) => <TextButton onClick={onContactClick}>{text}</TextButton>,
          }}
        />
      </StyledBody3>
    </Root>
  );
};
