import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';

import OrdersIcon from 'assets/images/order-icon.svg';
import UserIcon from 'assets/images/user-icon.svg';
import GroupIcon from 'assets/images/group-icon.svg';
import PolicyIcon from 'assets/images/policy-icon.svg';
import InvoiceIcon from 'assets/images/invoice-icon.svg';

import ResponsiveContainer from './ResponsiveContainer';

interface Props {
  children: React.ReactNode;
  icon: 'order' | 'user' | 'group' | 'member' | 'policy' | 'invoice';
  subtitle?: React.ReactNode;
  actionButtonText?: React.ReactNode;
  onActionButtonClick?: () => void;
  className?: string;
}

const Root = styled(ResponsiveContainer)`
  display: flex;
  justify-content: center;
  padding-top: 64px;
  padding-bottom: 64px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 550px;
  text-align: center;
`;

const Title = styled.h3`
  font-size: 16px;
  color: ${color.textSubdued};
  font-weight: 700;
  margin-top: 24px;
`;

const Subtitle = styled.h4`
  font-size: 12px;
  line-height: 18px;
  color: ${color.textSubdued};
  font-weight: 400;
  margin-top: 6px;
`;

const ActionButton = styled.span`
  font-size: 14px;
  color: ${color.textBrand};
  margin-top: 24px;
  font-weight: 500;

  :hover {
    cursor: pointer;
  }
`;

const icons = {
  order: OrdersIcon,
  user: UserIcon,
  group: GroupIcon,
  member: UserIcon,
  policy: PolicyIcon,
  invoice: InvoiceIcon,
};

const EmptyState = ({
  children,
  icon,
  subtitle,
  actionButtonText,
  onActionButtonClick,
  className,
}: Props) => {
  const Icon = icons[icon];

  return (
    <Root className={className}>
      <Content>
        <Icon />
        <Title>{children}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {actionButtonText && (
          <ActionButton role="button" onClick={onActionButtonClick}>
            {actionButtonText}
          </ActionButton>
        )}
      </Content>
    </Root>
  );
};

export default EmptyState;
