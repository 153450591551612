import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ConfirmationModal } from 'components';
import { useDeleteSubscriptionUsers } from 'modules/subscription/useDeleteSubscriptionUsers';
import { UI_SUCCESS_DELAY } from 'modules/common/constants';

import { useSubscriptionUsersQueryParams } from '../hooks/useSubscriptionUsersQueryParams';

interface Props {
  show: boolean;
  subscriptionId: string;
  onDeleteUsersSuccess: () => void;
  onClose: () => void;
}

export const DeleteSubscriptionUsersModal = ({
  show,
  subscriptionId,
  onClose,
  onDeleteUsersSuccess,
}: Props) => {
  const { formatMessage } = useIntl();
  const deleteUsers = useDeleteSubscriptionUsers();
  const [{ users: usersQueryParam, user: userQueryParam }] = useSubscriptionUsersQueryParams();

  const userIds = useMemo(
    () => (userQueryParam ? [userQueryParam] : usersQueryParam),
    [userQueryParam, usersQueryParam],
  );

  const handleConfirm = useCallback(async () => {
    if (!userIds) {
      return Promise.reject('User ids are missing');
    }

    await deleteUsers({ subscriptionId, userIds, invalidateDelay: UI_SUCCESS_DELAY });
  }, [deleteUsers, subscriptionId, userIds]);
  const numberOfUsers = usersQueryParam?.length || 1;

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      onConfirm={handleConfirm}
      title={formatMessage({ id: 'wolt-plus.delete-users-modal.title' }, { count: numberOfUsers })}
      content={formatMessage({ id: 'wolt-plus.delete-users-modal.text' }, { count: numberOfUsers })}
      confirmationButtonMessage={formatMessage({ id: 'common.delete' })}
      confirmationButtonVariant="lightRed"
      onSuccess={() => setTimeout(onDeleteUsersSuccess, UI_SUCCESS_DELAY)}
    />
  );
};
