import { FormattedDate } from 'react-intl';

interface LocalizedDateProps {
  value: Date;
  timeZone?: string;
  weekday?: 'long' | 'short' | 'narrow';
  year?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
  day?: 'numeric' | '2-digit';
}

/**s
 * @deprecated Use FormattedDate from react-intl instead
 */
const LocalizedDate = ({ value, timeZone, weekday, year, month, day }: LocalizedDateProps) => {
  return (
    <FormattedDate
      value={value}
      timeZone={timeZone}
      weekday={weekday}
      year={year}
      month={month}
      day={day}
    />
  );
};

export default LocalizedDate;
