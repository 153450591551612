import { useCallback } from 'react';
import styled from 'styled-components';
import { Alert } from '@creditornot/cb-alert';
import { Button } from '@creditornot/cb-button';
import { Checkmark } from '@creditornot/cb-icons';
import { FormikHelpers, Formik, Field, FieldProps } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { InputContainer } from '@creditornot/cb-form';

import { ModalV2, FormFooter } from 'components';
import { useAppModals } from 'modules/app-modals';
import { UI_SUCCESS_DELAY } from 'modules/common/constants';
import { useAddUser } from 'modules/users';
import { processError } from 'utils';
import { ApiErrorContainer } from 'views/settings/CompanySettingsV2/ApiErrorContainer';
import { WoltUserNonNullable } from 'modules/users/types';
import { Corporate } from 'modules/corporates/types';

import { AsyncUserSelect } from './AsyncUserSelect';

interface Props {
  corporate: Corporate;
  onClose: () => void;
  onSuccess: () => void;
  show: boolean;
}

const Container = styled.div`
  overflow: auto;
  height: auto;
  margin: 16px 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 24px;
`;

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: 24px;
  }
`;

const StyledAlert = styled(Alert)`
  flex: 1;
`;

interface FormValues {
  selectedUser: WoltUserNonNullable | null;
}

const initialValues: FormValues = {
  selectedUser: null,
};

export const AddAdminModal = ({ show, corporate, onClose, onSuccess }: Props) => {
  const { formatMessage } = useIntl();
  const { hasOpenModal } = useAppModals();
  const addUser = useAddUser();

  const handleSubmit = useCallback(
    async ({ selectedUser }: FormValues, actions: FormikHelpers<FormValues>) => {
      if (!selectedUser) {
        actions.setSubmitting(false);
        return;
      }

      try {
        await addUser({
          userData: {
            user_id: selectedUser.user.id.$oid,
            // Assign admin rights by default -> not possible to add regular users in this modal
            is_admin: true,
            group_ids: [],
          },
          invalidateDelay: UI_SUCCESS_DELAY,
        });
        actions.setStatus({ submitSucceeded: true });
        setTimeout(onSuccess, UI_SUCCESS_DELAY);
      } catch (error) {
        actions.setStatus({ error });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [addUser, onSuccess],
  );

  return (
    <ModalV2
      onDismiss={onClose}
      open={show}
      disableDismissOnOutsidePress={hasOpenModal}
      sizePreset={{ desktop: 'centered:extraLarge', mobile: 'fullScreen' }}
      title={formatMessage({ id: 'admins.add-admin-modal.title' })}
    >
      <Formik initialValues={initialValues} initialStatus={{ error: null }} onSubmit={handleSubmit}>
        {({ values: { selectedUser }, status, isSubmitting, submitForm }) => (
          <>
            <Container>
              <Row>
                <StyledAlert size="small" variant="info">
                  {formatMessage({ id: 'admins.add-admin-modal.explanation' })}
                </StyledAlert>
              </Row>

              <Row style={{ marginTop: 20, marginBottom: 20 }}>
                <Field name="selectedUser">
                  {({ field, form: { setFieldValue } }: FieldProps) => (
                    <AsyncUserSelect
                      corporateId={corporate.id}
                      onSelectUser={(user) => setFieldValue(field.name, user)}
                      selectedUser={selectedUser}
                    />
                  )}
                </Field>
              </Row>

              <Row>
                <StyledInputContainer label={formatMessage({ id: 'common.name' })}>
                  {selectedUser?.user.name
                    ? `${selectedUser.user.name.first_name} ${selectedUser.user.name.last_name}`
                    : '–'}
                </StyledInputContainer>
                <StyledInputContainer label={formatMessage({ id: 'common.email' })}>
                  {selectedUser?.user.email ?? '–'}
                </StyledInputContainer>
                <StyledInputContainer label={formatMessage({ id: 'common.phone-number' })}>
                  {selectedUser?.user.phone_number ?? '–'}
                </StyledInputContainer>
              </Row>

              {status?.error && (
                <ApiErrorContainer>
                  <StyledAlert variant="error" title={processError(status?.error).message}>
                    {processError(status?.error).data}
                  </StyledAlert>
                </ApiErrorContainer>
              )}
            </Container>

            <FormFooter
              rightContent={
                <>
                  <Button variant="lightBlue" size="small" onClick={onClose}>
                    <FormattedMessage id="common.cancel" />
                  </Button>
                  <Button
                    variant="blue"
                    size="small"
                    onClick={submitForm}
                    disabled={isSubmitting || status?.submitSucceeded || !selectedUser}
                    loading={isSubmitting}
                    icon={status?.submitSucceeded && <Checkmark />}
                  >
                    <FormattedMessage id="common.add" />
                  </Button>
                </>
              }
            />
          </>
        )}
      </Formik>
    </ModalV2>
  );
};
