import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useState } from 'react';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Button } from '@creditornot/cb-button';

import { ModalV2, ResponsiveContainer } from 'components';
import { LocalizedMessage } from 'i18n';
import { breakpoints } from 'modules/media';
import { LottieComponent } from 'modules/lotties';

const Container = styled(ResponsiveContainer)`
  padding: 48px;
  display: block;
  max-width: 480px;
  text-align: center;

  @media (max-width: ${breakpoints.small}px) {
    padding: 32px;
  }
`;

const Title = styled.h5`
  ${typographyCss.Heading5()}
  margin-top: 32px;
`;

const Subtitle = styled.div`
  font-size: 18px;
  margin: auto;
  margin-top: 16px;
  color: ${color.textDisabled};
  line-height: 24px;
`;

const FinalizeSettingsButton = styled(Button).attrs({
  variant: 'blue',
  size: 'small',
})`
  margin-top: 32px;
`;

const AlmostDone = styled(LottieComponent).attrs({
  animation: 'highFiveHands',
})`
  max-width: 420px;
  min-height: 256px;

  @media (max-width: ${breakpoints.small}px) {
    min-height: 100px;
  }
`;

export const DeliveriesOnboardingModal = ({
  defaultShow = false,
  onConfirm,
  onClose,
}: {
  defaultShow?: boolean;
  onConfirm: () => void;
  onClose: () => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(defaultShow);

  return (
    <ModalV2
      open={isModalOpen}
      onDismiss={() => {
        onClose();
        setIsModalOpen(false);
      }}
    >
      <Container>
        <AlmostDone />

        <Title>
          <LocalizedMessage messageKey="deliveries.onboarding.almost-done" />
        </Title>
        <Subtitle>
          <LocalizedMessage messageKey="deliveries.onboarding.almost-done-message-description" />
        </Subtitle>

        <div>
          <FinalizeSettingsButton onClick={onConfirm}>
            <LocalizedMessage messageKey="deliveries.onboarding.add-contact-details" />
          </FinalizeSettingsButton>
        </div>
      </Container>
    </ModalV2>
  );
};
