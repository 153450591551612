import { GetCorporateResponse } from '@creditornot/wd-api-client';
import { AxiosError } from 'axios';

import { UseQueryOptions } from 'modules/react-query';

import { useFetchSelfServiceCorporate } from './useFetchSelfServiceCorporate';
import { corporateDeliverySettingsComplete } from './utils';

export const useCorporateDeliverySettingsComplete = (
  corporateId?: string,
  config?: UseQueryOptions<GetCorporateResponse, AxiosError, boolean>,
) => {
  return useFetchSelfServiceCorporate(corporateId, {
    select: (data) => corporateDeliverySettingsComplete(data),
    ...config,
  });
};
