import { color } from '@creditornot/cb-ingredients/design-tokens';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { InfoCircleFilled } from '@creditornot/cb-icons';

import { LocalizedMessage, LocalizedNumber } from 'i18n';
import { Payment as IndividualPayment } from 'modules/orders/types';

import { PaymentInfo } from './PaymentInfo';

type BasePaymentProps = {
  accountingComment: ReactNode;
  currency: string;
};

type SinglePaymentProps = BasePaymentProps & {
  totalAmount: number;
  paymentMethodName?: ReactNode;
  title: ReactNode;
  tooltipContent?: ReactNode;
  name: ReactNode;
};

type MultiPaymentProps = BasePaymentProps & { payments: IndividualPayment[] };

type PaymentProps = SinglePaymentProps | MultiPaymentProps;

const Tooltip = styled.div`
  max-width: 180px;
`;

const StyledPopover = styled(Popover)`
  margin-inline-start: 8px;
`;

const StyledInfoCircleFilled = styled(InfoCircleFilled)`
  vertical-align: text-top;
  height: 16px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  font-weight: 500;
  justify-content: space-between;

  & + & {
    padding-top: 36px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.span`
  ${typographyCss.Body3()}
`;

const PaymentTitle = styled.div`
  ${typographyCss.Body3({ weight: 'bold' })}
`;

const Root = styled.div`
  padding-bottom: 16px;

  & + & {
    padding-top: 16px;
    border-top: 1px solid ${color.border};
  }
`;

const Container = styled.div<{ gap: number }>`
  & + & {
    padding-top: ${({ gap }) => gap}px;
  }
`;

const ErrorText = styled.div`
  color: ${color.textNegative};
`;

const localizedPaymentTypes = {
  main: <LocalizedMessage messageKey="common.invoice" />,
  additional: <LocalizedMessage messageKey="orders.details.additional-payment" />,
};

export const Payment = (props: PaymentProps) => {
  // Show details for total amount only
  if ('totalAmount' in props) {
    const {
      name,
      title,
      tooltipContent,
      accountingComment,
      paymentMethodName,
      totalAmount,
      currency,
    } = props;

    return (
      <Root>
        <Row>
          <Title>
            {title}
            {tooltipContent && (
              <StyledPopover
                contentZIndex={99999}
                placement="top"
                content={
                  <Tooltip>
                    <Content>{tooltipContent}</Content>
                  </Tooltip>
                }
              >
                <StyledInfoCircleFilled />
              </StyledPopover>
            )}
          </Title>
          <LocalizedNumber value={totalAmount} currency={currency} />
        </Row>
        <PaymentInfo
          name={name}
          accountingComment={accountingComment}
          paymentMethodName={paymentMethodName}
        />
      </Root>
    );
  }

  // Show multipayment details
  return (
    <Root style={{ paddingTop: 12, paddingBottom: 8 }}>
      {props.payments.map(({ id, type, amount, refunded_amount }) => {
        // Add larger gap between payments if there is an accounting note and refund
        const gap = !!refunded_amount && props.accountingComment ? 38 : 24;
        return (
          <Container key={id} gap={gap}>
            <Row>
              <Title>
                <PaymentTitle>{localizedPaymentTypes[type]}</PaymentTitle>
                {type === 'additional' && (
                  <StyledPopover
                    contentZIndex={99999}
                    placement="top"
                    content={
                      <Tooltip>
                        <Content>
                          <LocalizedMessage messageKey="orders.details.additional-payment-tooltip" />
                        </Content>
                      </Tooltip>
                    }
                  >
                    <StyledInfoCircleFilled />
                  </StyledPopover>
                )}
              </Title>
              <LocalizedNumber value={amount} currency={props.currency} />
            </Row>
            {!!refunded_amount && (
              <Row
                key={id}
                style={{
                  paddingTop: 4,
                  paddingBottom: type === 'main' && props.accountingComment ? 8 : 0,
                }}
              >
                <strong>
                  <LocalizedMessage messageKey="orders.details.refund" />
                </strong>
                <ErrorText>
                  <LocalizedNumber withSign value={-refunded_amount} currency={props.currency} />
                </ErrorText>
              </Row>
            )}
            {type === 'main' && <PaymentInfo accountingComment={props.accountingComment} />}
          </Container>
        );
      })}
    </Root>
  );
};
