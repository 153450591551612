import { useCallback } from 'react';

import { useQueryClient } from 'modules/react-query';

import { deleteCorporate } from './api';

export const useDeleteCorporate = () => {
  const queryClient = useQueryClient();
  return useCallback(
    async (corporateId: string) => {
      const result = await deleteCorporate(corporateId);
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 500);
      return result;
    },
    [queryClient],
  );
};
