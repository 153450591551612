import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchPolicy } from './api';
import { Policy } from './types';

export const useFetchPolicy = <T = Policy>(
  policyId: string | null,
  config?: UseQueryOptions<Policy, unknown, T>,
) => {
  const { data: corporate } = useCorporate();
  const queryFn = useCallback(() => {
    if (!corporate || !policyId) {
      return Promise.reject();
    }
    return fetchPolicy(corporate.id, policyId);
  }, [corporate, policyId]);
  return useQuery({
    queryKey: ['policy', corporate?.id, policyId],
    queryFn,
    enabled: !!(corporate && policyId),
    ...config,
  });
};
