import { Checkbox, CheckboxProps } from '@creditornot/cb-checkbox';
import { useField } from 'formik';
import { ReactNode } from 'react';

interface Props {
  checkboxProps?: Partial<CheckboxProps>;
  className?: string;
  name: string;
  required?: boolean;
  children?: ReactNode;
}

export const FormikCheckboxField = ({
  checkboxProps,
  className,
  name,
  required,
  children,
}: Props) => {
  const [field] = useField(name);
  return (
    <Checkbox
      className={className}
      required={required}
      checked={field.value}
      {...checkboxProps}
      {...field}
    >
      {children}
    </Checkbox>
  );
};
