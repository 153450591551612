import { useCallback, useState } from 'react';
import { loadAsync } from 'jszip';

const xlsxSharedStringsFieldName = 'xl/sharedStrings.xml';

const xlsxSheetFieldName = 'xl/worksheets/sheet1.xml';

const fileReader = new FileReader();

export const convertExcelColumnToNumber = (text: string, acc = 0): number =>
  text === ''
    ? acc
    : convertExcelColumnToNumber(
        text.slice(1),
        acc + Math.pow(26, text.length - 1) * (text.charCodeAt(0) - 65),
      );

export const useFileReader = () => {
  const [csv, setCsv] = useState<string[] | null>(null);
  const [xlsx, setXlsx] = useState<(string | undefined)[][] | null>(null);
  const [isParsing, setIsParsing] = useState(false);

  const readCsv = useCallback((file: File) => {
    setCsv(null);
    setXlsx(null);
    setIsParsing(true);

    fileReader.abort();
    fileReader.readAsText(file);

    return new Promise<string[]>((resolve, reject) => {
      fileReader.onload = () => {
        setIsParsing(false);
        if (typeof fileReader.result === 'string') {
          const data = fileReader.result.split(/[,|\n]/).map((d) => d.trim());

          setCsv(data);
          resolve(data);
        }
      };

      fileReader.onerror = (err: any) => {
        setIsParsing(false);
        reject(err);
      };
    });
  }, []);

  const readXlsx = useCallback((file: File) => {
    const parser = new DOMParser();

    setCsv(null);
    setXlsx(null);
    setIsParsing(true);

    return new Promise<string[][]>((resolve, reject) => {
      loadAsync(file)
        .then((zip) =>
          Promise.all(
            [xlsxSharedStringsFieldName, xlsxSheetFieldName].map((key) =>
              zip
                .file(key)
                ?.async('text')
                .then((content) => parser.parseFromString(content, 'text/xml')),
            ),
          ),
        )
        .then(([sharedStringsNode, sheetNode]) => {
          if (!sharedStringsNode || !sheetNode) {
            return null;
          }

          const sheetDataNode = Array.from(sheetNode.all).find(
            (node) => node.tagName === 'sheetData',
          );
          const sheetData = !sheetDataNode ? [] : Array.from(sheetDataNode.children);
          const sharedStringsData = Array.from(sharedStringsNode.children[0].children);

          const sharedStringKeyValues: Record<string, string> = sharedStringsData.reduce(
            (acc, child, index) => ({ ...acc, [index]: child.textContent }),
            {},
          );

          const firstExcelColumn = sheetData
            .flatMap((rowNode) =>
              Array.from(rowNode.children[0].attributes[0].value.replace(/[0-9]/g, '')),
            )
            .reduce((acc, val) => (acc < val ? acc : val), 'A');

          const lastExcelColumn = sheetData
            .flatMap((rowNode) =>
              Array.from(
                rowNode.children[rowNode.children.length - 1].attributes[0].value.replace(
                  /[0-9]/g,
                  '',
                ),
              ),
            )
            .reduce((acc, val) => (acc > val ? acc : val), 'A');

          const firstColumn = convertExcelColumnToNumber(firstExcelColumn);

          const lastColumn = convertExcelColumnToNumber(lastExcelColumn);

          const totalColumns = lastColumn - firstColumn + 1;

          const table = Array.from(Array(sheetData.length), () => new Array(totalColumns));

          sheetData.forEach((rowNode, rowIndex) =>
            Array.from(rowNode.children).forEach((colNode) => {
              const [excelColumn] = colNode.attributes[0].value.split(/(\d+)/);

              const columnIndex = convertExcelColumnToNumber(excelColumn) - firstColumn;

              const value = colNode.children[0]?.textContent
                ? sharedStringKeyValues[colNode.children[0].textContent]
                : undefined;

              table[rowIndex][columnIndex] = value;
            }),
          );

          setIsParsing(false);
          setXlsx(table);

          resolve(table);
        })
        .catch((err) => {
          setIsParsing(false);
          reject(err);
        });
    });
  }, []);

  return { csv, xlsx, readXlsx, isParsing, readCsv };
};
