import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { ModalV2 } from 'components';
import { useAppModals } from 'modules/app-modals';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { subscriptionInviteMembersView } from 'telemetry/Avo';

import { AddUsersForm } from './AddUsersForm';

interface Props {
  onClose: () => void;
  show: boolean;
  corporateId: string;
  subscriptionId: string;
  isSubscriptionOnlyCorporate: boolean;
}

const Container = styled.div`
  overflow: auto;
  height: auto;
`;

export const AddSubscriptionUsersModal = ({
  onClose,
  show,
  corporateId,
  subscriptionId,
  isSubscriptionOnlyCorporate,
}: Props) => {
  const { formatMessage } = useIntl();
  const { hasOpenModal } = useAppModals();

  return (
    <ModalV2
      onDismiss={onClose}
      title={formatMessage({ id: 'users.new-user-modal.title' })}
      open={show}
      disableDismissOnOutsidePress={hasOpenModal}
      sizePreset={{ desktop: 'centered:extraLarge', mobile: 'fullScreen' }}
    >
      <ViewEventComponent event={subscriptionInviteMembersView} />
      <Container>
        <AddUsersForm
          onClose={onClose}
          corporateId={corporateId}
          subscriptionId={subscriptionId}
          isSubscriptionOnlyCorporate={isSubscriptionOnlyCorporate}
        />
      </Container>
    </ModalV2>
  );
};
