import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { useField } from 'formik';
import { typographyCss } from '@creditornot/cb-ingredients';
import { useCallback } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { breakpoints } from 'modules/media';

const WeekdayWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const StyledWeekday = styled.label`
  position: relative;
  width: 92px;

  @media (max-width: ${breakpoints.large}px) {
    width: unset;
    flex: 1;
  }
`;

const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const StyledWeekdayLabel = styled.span`
  ${typographyCss.Body3()}
  padding: 8px;
  border-radius: 8px;
  background-color: ${color.bgSurfaceDisabled};
  color: ${color.textSubdued};
  display: inline-block;
  width: 100%;
  text-align: center;
  cursor: pointer;
`;

const StyledHiddenInput = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;

  &:checked + ${StyledWeekdayLabel} {
    background-color: ${color.bgSurfaceBrand};
    color: ${color.textBrand};
    font-weight: 500;
  }

  &:focus-within + ${StyledWeekdayLabel} {
    outline: 4px solid ${color.bgSurfaceBrandPressed};
  }
`;

const validate = (value: string[]) => {
  if (!value.length) {
    return 'validation.no-day-selected';
  }

  return undefined;
};

const PolicyDeliveryDaysField = () => {
  const { formatMessage } = useIntl();
  const [{ value }, , { setValue }] = useField<string[]>({
    name: 'delivery_days',
    validate,
  });

  const handleOptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newValue;

      if (value?.includes(e.target.value)) {
        newValue = value.filter((val) => val !== e.target.value);
      } else if (value) {
        newValue = [...value, e.target.value];
      } else {
        newValue = [e.target.value];
      }

      setValue(newValue);
    },
    [setValue, value],
  );

  return (
    <WeekdayWrapper>
      {daysOfWeek.map((day) => (
        <StyledWeekday key={day}>
          <StyledHiddenInput
            name="weekday"
            type="checkbox"
            value={day}
            checked={value.includes(day)}
            onChange={handleOptionChange}
          />
          <StyledWeekdayLabel>
            {formatMessage({ id: `common.weekday.${day}` as MessageDescriptor['id'] })}
          </StyledWeekdayLabel>
        </StyledWeekday>
      ))}
    </WeekdayWrapper>
  );
};

export default PolicyDeliveryDaysField;
