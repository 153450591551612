import { useCallback } from 'react';

import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchAgreementParty } from './api';
import { AgreementParty } from './types';

export const useFetchAgreementParty = <T = AgreementParty>(
  id: string | null,
  config?: UseQueryOptions<AgreementParty, unknown, T>,
) => {
  const queryFn = useCallback(() => {
    if (!id) {
      return Promise.reject();
    }
    return fetchAgreementParty(id);
  }, [id]);
  return useQuery({ queryKey: ['agreement-party', id], queryFn, enabled: !!id, ...config });
};
