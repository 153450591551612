import { Input, InputProps } from '@creditornot/cb-input';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FormikFieldContainer } from 'components/FormikFieldContainer';

type Props = {
  className?: string;
  errorMessage: string | undefined;
  register: UseFormRegisterReturn;
  type?: 'string' | 'number';
  inputProps?: Partial<InputProps>;
  name?: string;
};

export const ReactHookInput = ({
  className,
  errorMessage,
  register,
  type,
  inputProps,
  name,
}: Props) => {
  const RegisterWithNoRef = { ...register, ref: undefined };
  return (
    <FormikFieldContainer className={className} errorMessage={errorMessage}>
      <Input
        {...RegisterWithNoRef}
        ref={register.ref}
        invalid={!!errorMessage}
        type={type}
        id={name}
        {...inputProps}
      />
    </FormikFieldContainer>
  );
};
