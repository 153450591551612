import styled from 'styled-components';
import { FieldArray, useFormikContext } from 'formik';
import { Plus, Trashbin } from '@creditornot/cb-icons';
import { TextButton } from '@creditornot/cb-button';
import { InputContainer } from '@creditornot/cb-form';

import { FormikInputField } from 'components';
import { createValidator, isRequired } from 'validation';

import { CreateArgreementPartyFormValues } from '../types';
import CountrySelectField from './CountrySelectField';

const VatsSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 532px;

  & + & {
    margin-top: 24px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.span`
  font-size: 14px;
  margin-bottom: 10px;
`;

const RemoveBankAccountButton = styled(TextButton)`
  font-size: 14px;
  font-weight: 600;
  margin-inline-start: 5px;
  margin-bottom: 10px;
`;

const AddBankAccountButton = styled(TextButton)`
  font-weight: 600;
  font-size: 14px;
`;

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: 24px;
  }
`;

const validate = createValidator([isRequired]);

const VatsField = () => {
  const { values, validateForm } = useFormikContext<CreateArgreementPartyFormValues>();

  return (
    <FieldArray name="vats">
      {(arrayHelpers) => {
        return (
          <>
            {values?.vats?.map((_account, index) => (
              <VatsSection key={index}>
                <TitleContainer>
                  <Title>{`VAT #${index + 1}`}</Title>

                  <RemoveBankAccountButton
                    icon={<Trashbin height="16px" />}
                    onClick={(e) => {
                      e.preventDefault();

                      arrayHelpers.remove(index);
                      setTimeout(() => {
                        validateForm();
                      }, 100);
                      /* There's a bug in Formik where using remove arrayHelper can cause the an empty array to be
                        to the error object under the key of the field array: https://github.com/jaredpalmer/formik/issues/784 */
                    }}
                  >
                    Remove
                  </RemoveBankAccountButton>
                </TitleContainer>
                <StyledInputContainer label="VAT id">
                  <FormikInputField
                    name={`vats.${index}.vat_id`}
                    validate={validate}
                    required={true}
                  />
                </StyledInputContainer>

                <StyledInputContainer label="Country code">
                  <CountrySelectField name={`vats.${index}.country_code`} validate={validate} />
                </StyledInputContainer>
              </VatsSection>
            ))}
            <AddBankAccountButton
              icon={<Plus height="16px" />}
              variant="blue"
              onClick={(e) => {
                e.preventDefault();
                arrayHelpers.push({ vat_id: '', country_code: '' });
              }}
              style={{
                marginTop: values?.vats?.length ? '16px' : '0px',
              }}
            >
              {values?.vats?.length ? 'Add another VAT' : 'Add VAT'}
            </AddBankAccountButton>
          </>
        );
      }}
    </FieldArray>
  );
};

export default VatsField;
