import { useHistory } from 'react-router-dom';
import { TextButton } from '@creditornot/cb-button';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import { last } from 'ramda';

import { useCorporate } from 'modules/corporates';
import { LocalizedMessage } from 'i18n';

interface Props {
  groups: {
    id: string;
    name: string;
  }[];
}

const Link = styled.span`
  text-decoration: underline;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
`;

export const GroupsDataCellContent = ({ groups }: Props) => {
  const history = useHistory();
  const [showMore, setShowMore] = useState(false);
  const { data: corporate } = useCorporate();

  const gotoGroupInfo = useCallback(
    (id: string) => {
      if (!corporate) return;
      history.push(`/${corporate.id}/groups/info/${id}`);
    },
    [corporate, history],
  );

  if (!groups?.length) {
    return <div>{'–'} </div>;
  }

  if (groups.length === 1) {
    return <Link onClick={() => gotoGroupInfo(groups[0].id)}>{groups[0].name}</Link>;
  }

  if (groups.length <= 4) {
    return (
      <div
        style={{
          maxHeight: '120px',
          overflow: 'auto',
        }}
      >
        {groups.map((group, index) => (
          <div key={`${JSON.stringify(group)}-${index}`}>
            <Link onClick={() => gotoGroupInfo(group.id)}>{group.name}</Link>
            {last(groups)?.id !== group.id && ','}
          </div>
        ))}
      </div>
    );
  }

  const filteredGroups = showMore ? groups : groups.slice(0, 4);

  return (
    <div>
      {filteredGroups.map((group, index) => (
        <div key={`${JSON.stringify(group)}-${index}`}>
          <Link onClick={() => gotoGroupInfo(group.id)}>{group.name}</Link>
          {last(groups)?.id !== group.id && ','}
        </div>
      ))}

      {showMore ? (
        <TextButton onClick={() => setShowMore(false)} variant="grey">
          <LocalizedMessage messageKey="common.show-less" />
        </TextButton>
      ) : (
        <>
          ...{' '}
          <TextButton onClick={() => setShowMore(true)} variant="grey">
            <LocalizedMessage messageKey="common.show-more" />
          </TextButton>
        </>
      )}
    </div>
  );
};
