import { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Input } from '@creditornot/cb-input';
import { MagnifyingGlass } from '@creditornot/cb-icons';
import { Button } from '@creditornot/cb-button';

import { Header, View } from 'components';
import { breakpoints, useMedia } from 'modules/media';
import { useI18n } from 'i18n';
import { Corporate } from 'modules/corporates/types';

import CreateCorporateModal from './create-corporate/CreateCorporateModal';
import CorporatesDataList from './CorporatesDataList';
import { CorporateDataTable } from './CorporateDataTable';
import { useCorporatesQueryParams } from './useCorporatesQueryParams';
import { CorporateFilter } from './CorporateFilter';

const StyledSearchInput = styled(Input)`
  max-width: 240px;

  @media (max-width: ${breakpoints.medium}px) {
    max-width: unset;
  }
`;

export const CorporatesView = () => {
  const mediumView = useMedia('medium');
  const { getLocalizedMessage } = useI18n();
  const history = useHistory();
  const {
    params: {
      search: nameQueryParam,
      corporate_status: statusQueryParam,
      products: productLineQueryParam,
      country: countryCodeQueryParam,
      createCorporateModal,
    },
    setQueryParams,
  } = useCorporatesQueryParams();

  const handleDataItemClick = useCallback(
    (corporate: Corporate) => {
      history.push(`/${corporate.id}`);
    },
    [history],
  );

  const handleCreateCorporateModalOpen = useCallback(() => {
    setQueryParams({ createCorporateModal: 'open' });
  }, [setQueryParams]);

  const handleCreateCorporateModalClose = useCallback(() => {
    setQueryParams({ createCorporateModal: undefined });
  }, [setQueryParams]);

  const handleCorporateStatusChange = useCallback(
    (statuses: string[]) => {
      setQueryParams({
        corporate_status: statuses,
      });
    },
    [setQueryParams],
  );

  const handleProductLineChange = useCallback(
    (productLines: string[]) => {
      setQueryParams({
        products: productLines,
      });
    },
    [setQueryParams],
  );

  return (
    <View
      title={getLocalizedMessage('corporates.page-title')}
      rightContent={
        <Button variant="blue" size="small" onClick={handleCreateCorporateModalOpen}>
          {getLocalizedMessage('corporates.create-corporate-button')}
        </Button>
      }
    >
      <Header
        key="corporateViewHeader"
        borderBottom
        leftItems={[
          <StyledSearchInput
            key="corporateSearchInput"
            type="text"
            icon={<MagnifyingGlass />}
            value={nameQueryParam}
            onChange={(e) =>
              setQueryParams({
                search: e.target.value,
              })
            }
            onClearClick={() =>
              setQueryParams({
                search: undefined,
              })
            }
            placeholder={getLocalizedMessage('common.search')}
          />,
          <CorporateFilter
            key="corporateFilter"
            statusQueryParam={statusQueryParam}
            productLineQueryParam={productLineQueryParam}
            onCorporateStatusChange={handleCorporateStatusChange}
            onProductLineChange={handleProductLineChange}
          />,
        ]}
      />

      {mediumView ? (
        <CorporatesDataList onDataItemClick={handleDataItemClick} />
      ) : (
        <CorporateDataTable onDataItemClick={handleDataItemClick} />
      )}

      <CreateCorporateModal
        show={createCorporateModal === 'open'}
        selectedCountryCode={countryCodeQueryParam}
        onClose={handleCreateCorporateModalClose}
      />
    </View>
  );
};
