import { GetCorporateResponse } from '@creditornot/wd-api-client';
import { Discount, DiscountType } from '@creditornot/daas-core-api-client';

import { convertFromAmountToCents } from 'utils';

import { Corporate } from './types';

type CorporateFeature = {
  deliveryEnabled: boolean;
  groupEnabled: boolean;
  wfwEnabled: boolean;
};

type DiscountConvertProps = {
  currency: string;
  discountType: DiscountType | 'no_discount';
  flatDiscount: string;
  percentageDiscount: string;
};

// TODO move other features checker here, eg events, policies.
export const corporateFeatureFlag = (corporate?: Corporate): CorporateFeature => ({
  deliveryEnabled: !!corporate?.products.daas_delivery_ui_enabled,
  groupEnabled: !corporate?.products.daas_delivery_ui_enabled,
  wfwEnabled: !!corporate?.products.waw_enabled,
});

export const corporateDeliveryMessageSettingsComplete = (corporateData: GetCorporateResponse) => {
  return Boolean(corporateData.smsNotifications && corporateData.smsNotifications.length > 0);
};

export const corporateDeliverySupportInfoSettingsComplete = (
  corporateData: GetCorporateResponse,
) => {
  const isSupportInfoMissing =
    !corporateData.phoneNumber && !corporateData.email && !corporateData.url;
  return !isSupportInfoMissing;
};

export const convertToDiscountArray = ({
  currency,
  discountType,
  flatDiscount,
  percentageDiscount,
}: DiscountConvertProps): Discount[] => {
  if (discountType === DiscountType.Flat) {
    return [{ type: DiscountType.Flat, value: convertFromAmountToCents(flatDiscount, currency) }];
  }
  if (discountType === DiscountType.TotalPricePercentage) {
    return [{ type: DiscountType.TotalPricePercentage, value: +percentageDiscount }];
  }
  return [];
};

export const corporateDeliverySettingsComplete = (corporateData: GetCorporateResponse) => {
  return (
    corporateDeliveryMessageSettingsComplete(corporateData) &&
    corporateDeliverySupportInfoSettingsComplete(corporateData)
  );
};

export const isWfWOnlyCorporate = (corporate: Corporate) =>
  corporate.products.waw_enabled && !corporate.products.subscription_benefit_enabled;

export const isSubscriptionBenefitOnlyCorporate = (corporate: Corporate) =>
  !corporate.products.waw_enabled && corporate.products.subscription_benefit_enabled;
