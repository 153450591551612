import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Direction } from '@creditornot/cb-utils';

import en from './messages/en.json';
import type { MessageIds } from './types';

export type SupportedLanguageCode =
  | 'az'
  | 'cs'
  | 'da'
  | 'de'
  | 'el'
  | 'en'
  | 'et'
  | 'fi'
  | 'fr'
  | 'he'
  | 'hu'
  | 'is'
  | 'ja'
  | 'kk'
  | 'lt'
  | 'lv'
  | 'no'
  | 'pl'
  | 'ru'
  | 'sk'
  | 'sq'
  | 'sr'
  | 'sv'
  | 'uz';

type Language = {
  code: SupportedLanguageCode;
  label: string;
  rtl?: boolean;
  // Determines if the languages is only shown for internal Wolt employees
  internal?: boolean;
  // Temporary value for languages that are only shown for users with access to at least one Drive company
  driveOnly?: boolean;
};

export const isMessageKey = (key: unknown): key is MessageIds =>
  typeof key === 'string' && !!(en as Record<string, any>)[key];

export const supportedLanguages: Language[] = [
  { code: 'az', label: 'Azerbaijani', internal: true },
  { code: 'cs', label: 'Čeština' },
  { code: 'da', label: 'Dansk', internal: true },
  { code: 'de', label: 'Deutsch' },
  { code: 'en', label: 'English' },
  { code: 'et', label: 'Eesti' },
  { code: 'fi', label: 'Suomi', internal: true },
  { code: 'fr', label: 'Français', internal: true },
  { code: 'he', label: 'עִבְרִית', rtl: true },
  { code: 'hu', label: 'Magyar' },
  { code: 'is', label: 'Íslenska', internal: true },
  { code: 'ja', label: '日本語' },
  { code: 'kk', label: 'ქართული', internal: true },
  { code: 'lt', label: 'Lietuvių' },
  { code: 'lv', label: 'Latviešu' },
  { code: 'no', label: 'Norsk' },
  { code: 'pl', label: 'Polski' },
  { code: 'ru', label: 'Русский', driveOnly: true },
  { code: 'sk', label: 'Slovak' },
  { code: 'sq', label: 'Shqip', internal: true },
  { code: 'sr', label: 'Srpski', internal: true },
  { code: 'sv', label: 'Svenska' },
  { code: 'uz', label: 'Oʻzbekcha', internal: true },
];

export const fallbackLanguage: Partial<Record<SupportedLanguageCode, string[]>> = {
  en: ['en-GB', 'en-US'],
};

export const useLanguageDirection = () => {
  const { locale } = useIntl();

  const direction: Direction = useMemo(() => {
    const rtl = supportedLanguages.find((language) => language.code === locale)?.rtl;
    return rtl ? 'rtl' : 'ltr';
  }, [locale]);

  return direction;
};
