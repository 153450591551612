import * as t from 'io-ts';

import { corporateServiceApiAgent, restaurantApiAgent } from 'apiAgents';
import { ResponseStatus, PaginatedData } from 'types';
import { logValidationError } from 'validation';

import { WoltUser, Role, WoltUserNonNullable, UserV2, UserStatus, UserV2BasicInfo } from './types';

export const fetchUser = async (corporateId: string, userId: string) => {
  const { data } = await corporateServiceApiAgent.get<UserV2>(
    `/portal-api/v1/corporates/${corporateId}/users/${userId}`,
  );
  logValidationError('fetchUser', UserV2, data);
  return data;
};

export type FetchUsersParams = {
  page: number;
  search?: string;
  page_size: number;
  group_ids?: string[];
  statuses?: string[];
};

export type AddUserData = {
  user_id: string;
  is_admin: boolean;
  group_ids: string[];
};

export type EditUserData = Partial<{
  is_admin: string;
  status: UserStatus;
  group_ids: string[];
}>;

export const fetchUsers = async (
  corporateId: string,
  { page, page_size, statuses, group_ids, search }: FetchUsersParams,
) => {
  const { data } = await corporateServiceApiAgent.get<PaginatedData<UserV2>>(
    `/portal-api/v1/corporates/${corporateId}/users`,
    {
      params: {
        page_size,
        page,
        search: search?.trim(),
        statuses: statuses?.join(','),
        group_ids: group_ids?.join(','),
      },
    },
  );
  logValidationError('fetchUsers', t.array(UserV2), data.results);
  return data;
};

export const addUser = async (corporateId: string, userData: AddUserData) => {
  const { data } = await corporateServiceApiAgent.post<UserV2>(
    `/portal-api/v1/corporates/${corporateId}/users`,
    userData,
  );
  logValidationError('addUser', UserV2, data);
  return data;
};

export const fetchAllUsers = async (corporateId: string) => {
  const { data } = await corporateServiceApiAgent.get<Array<UserV2BasicInfo>>(
    `/portal-api/v1/corporates/${corporateId}/users-with-limited-details`,
  );
  logValidationError('fetchAllUsers', t.array(UserV2BasicInfo), data);
  return data;
};

export const deleteUsers = async (corporateId: string, userIds: string[]) => {
  const { data } = await corporateServiceApiAgent.delete(
    `/portal-api/v1/corporates/${corporateId}/users`,
    { data: { user_ids: userIds } },
  );
  logValidationError('deleteUsers', ResponseStatus, data);
  return data;
};

export const fetchWoltUsers = async (searchQuery: string) => {
  const { data } = await restaurantApiAgent.post<{
    results: WoltUser[];
  }>(`/v1/opstools/users/search`, {
    q: searchQuery,
  });
  logValidationError('fetchWoltUsers', t.array(WoltUser), data.results);
  return data.results.filter((userEntity) => !!userEntity.user) as WoltUserNonNullable[];
};

export const editUser = async (corporateId: string, userId: string, editUserData: EditUserData) => {
  const { data } = await corporateServiceApiAgent.patch(
    `/portal-api/v1/corporates/${corporateId}/users/${userId}`,
    editUserData,
  );
  logValidationError('editUser', t.UnknownRecord, data);
  return data;
};

export const fetchUserCorporateRoles = async () => {
  const { data } = await restaurantApiAgent.get<Role[]>(`/v1/waw-api/user-permissions`);
  logValidationError('fetchCorporateRoles', t.array(Role), data);
  return data;
};
