import { useField } from 'formik';
import { RadioCard } from '@creditornot/cb-radio';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { ChangeEvent, ChangeEventHandler, useCallback } from 'react';

import { CreateCorporateData } from 'modules/corporates/api';
import { CountryConfig } from 'modules/country-configs/types';

interface Props {
  countryConfig: CountryConfig;
}

interface DynamicProductCardProps {
  value: CreateCorporateData['products'];
  onChange: ChangeEventHandler<HTMLInputElement>;
  countryConfig: CountryConfig;
}

const RadioCards = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
`;

// Cards are rendered based on the enabled country config products for Wolt for Work and Wolt+
const DynamicProductCard = ({ value, onChange, countryConfig }: DynamicProductCardProps) => {
  const { formatMessage } = useIntl();

  const isWfWEnabled = countryConfig.enabled_products.wolt_for_work;
  const isSubscriptionBenefitEnabled = countryConfig.enabled_products.subscription_benefit;

  if (isWfWEnabled && isSubscriptionBenefitEnabled) {
    return (
      <RadioCard
        inputProps={{ id: 'company-type' }}
        label={formatMessage({ id: 'corporates.product-line.wolt-at-work' })}
        subContent={formatMessage({
          id: 'corporate-form.company-type.wolt_at_work_with_subscription.subcontent',
        })}
        value={['WOLT_AT_WORK', 'SUBSCRIPTION_BENEFIT'] satisfies CreateCorporateData['products']}
        name="products"
        checked={value.includes('WOLT_AT_WORK') && value.includes('SUBSCRIPTION_BENEFIT')}
        onChange={onChange}
      />
    );
  }

  if (isWfWEnabled) {
    return (
      <RadioCard
        inputProps={{ id: 'company-type' }}
        label={formatMessage({ id: 'corporates.product-line.wolt-at-work' })}
        subContent={formatMessage({ id: 'corporate-form.company-type.wolt_at_work.subcontent' })}
        value={['WOLT_AT_WORK'] satisfies CreateCorporateData['products']}
        name="products"
        checked={value.includes('WOLT_AT_WORK')}
        onChange={onChange}
      />
    );
  }

  if (isSubscriptionBenefitEnabled) {
    return (
      <RadioCard
        inputProps={{ id: 'company-type' }}
        label={formatMessage({ id: 'corporates.product-line.wolt-plus' })}
        subContent={formatMessage({
          id: 'corporate-form.company-type.subscription_benefit.subcontent',
        })}
        value={['SUBSCRIPTION_BENEFIT'] satisfies CreateCorporateData['products']}
        name="products"
        checked={value.includes('SUBSCRIPTION_BENEFIT')}
        onChange={onChange}
      />
    );
  }

  return null;
};

export const CompanyTypeField = ({ countryConfig }: Props) => {
  const [{ value }, , { setValue }] = useField<CreateCorporateData['products']>('products');
  const { formatMessage } = useIntl();

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // event.target.value from RadioCard returns strings, even if the card value is array
      const values = e.target.value;
      const valuesArray = values.split(',') as CreateCorporateData['products'];
      setValue(valuesArray);
    },
    [setValue],
  );

  return (
    <>
      <RadioCards>
        <DynamicProductCard value={value} onChange={handleChange} countryConfig={countryConfig} />
        <RadioCard
          inputProps={{ id: 'company-type' }}
          label={formatMessage({ id: 'corporates.product-line.wolt-drive' })}
          subContent={formatMessage({ id: 'corporate-form.company-type.wolt_drive.subcontent' })}
          value={['DELIVERY_UI'] satisfies CreateCorporateData['products']}
          checked={value.includes('DELIVERY_UI')}
          onChange={handleChange}
          name="products"
        />
      </RadioCards>
    </>
  );
};
