import { Field, FieldProps } from 'formik';
import { useIntl } from 'react-intl';

import { RadioInputCardGroup } from 'components';

interface Props {
  index: number;
}

export const PolicyLimitTakeawayField = ({ index }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <Field name={`delivery_locations.${index}.allow_takeaway`}>
      {({ field, form }: FieldProps<string>) => (
        <RadioInputCardGroup
          name={field.name}
          value={field.value}
          onChange={(e) => form.setFieldValue(field.name, e.target.value)}
          direction="row"
          optionWidth={100}
          options={[
            {
              label: formatMessage({ id: 'common.yes' }),
              value: 'true',
            },
            {
              label: formatMessage({ id: 'common.no' }),
              value: 'false',
            },
          ]}
        />
      )}
    </Field>
  );
};
