import { useMedia } from 'modules/media';
import { InvoicesDataList } from 'views/invoices/InvoicesDataList';
import { InvoicesDataTable } from 'views/invoices/InvoicesDataTable';
import { useHandleDownloadInvoiceDocumentFromUrl } from 'views/invoices/useHandleDownloadInvoiceDocumentFromUrl';

export const ReportsInvoiceDataView = () => {
  const mediumView = useMedia('medium');
  const [handleDownloadDocumentFromURL, isDownloading] = useHandleDownloadInvoiceDocumentFromUrl();

  return (
    <>
      {mediumView ? (
        <InvoicesDataList
          onDownloadDocumentClick={handleDownloadDocumentFromURL}
          isDownloading={isDownloading}
        />
      ) : (
        <InvoicesDataTable
          onDownloadDocumentClick={handleDownloadDocumentFromURL}
          isDownloading={isDownloading}
        />
      )}
    </>
  );
};
