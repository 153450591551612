import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchPolicies } from './api';
import { Policy } from './types';

export const useFetchPolicies = <T = Policy[]>(config?: UseQueryOptions<Policy[], unknown, T>) => {
  const { data: corporate } = useCorporate();
  const queryFn = useCallback(() => {
    if (!corporate) {
      return Promise.reject();
    }
    return fetchPolicies(corporate.id);
  }, [corporate]);
  return useQuery({
    queryKey: ['policies', corporate?.id],
    queryFn,
    enabled: !!corporate,
    ...config,
  });
};
