import { useAuthState } from '@creditornot/wolt-auth/react';
import { Redirect, RouteProps, useLocation } from 'react-router-dom';

import { setLocalStorageItem } from 'modules/hooks';

import { RouteWithSideNavbar } from './RouteWithSideNavBar';

export const ProtectedRoute = (props: RouteProps) => {
  const { user } = useAuthState();
  const location = useLocation();

  if (!user) {
    setLocalStorageItem(
      'userWasRedirectedFrom',
      location ? `${location.pathname}${location.search}` : undefined,
    );
    return <Redirect to={{ pathname: '/' }} />;
  }

  return <RouteWithSideNavbar {...props} />;
};
