import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useField } from 'formik';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Textarea, TextareaProps } from '@creditornot/cb-input';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { getFormikFieldError } from 'validation';

import { FormikFieldContainer } from './FormikFieldContainer';

export type FormikTextareaFieldProps = {
  name: string;
  validate?: (value: string) => string | void | Promise<string | void>;
  alwaysShowError?: boolean;
  className?: string;
  testId?: string;
  textareaProps?: TextareaProps;
};

const CharacterLength = styled.small<{ invalid: boolean }>`
  ${typographyCss.Small()}
  display: block;
  text-align: end;
  color: ${({ invalid }) => (invalid ? color.textNegative : color.textSubdued)};
`;

const StyledTextarea = styled(Textarea)`
  height: 100%;
`;

export const FormikTextareaField = ({
  alwaysShowError = false,
  className,
  name,
  testId,
  textareaProps,
  validate,
}: FormikTextareaFieldProps) => {
  const { formatMessage } = useIntl();
  const [field, meta] = useField<string>({ name, validate });

  const formikValidationError = useMemo(
    () => getFormikFieldError(meta, alwaysShowError),
    [alwaysShowError, meta],
  );

  const errorMessage = useMemo(
    () =>
      formikValidationError &&
      formatMessage({ id: formikValidationError.messageKey }, formikValidationError.values),
    [formikValidationError, formatMessage],
  );

  return (
    <FormikFieldContainer errorMessage={errorMessage} className={className}>
      <StyledTextarea
        data-test-id={testId}
        invalid={!!formikValidationError}
        id={name}
        {...field}
        {...textareaProps}
      />
      {textareaProps?.maxLength && (
        <CharacterLength invalid={!!formikValidationError}>
          {field.value?.length || 0}/{textareaProps.maxLength}
        </CharacterLength>
      )}
    </FormikFieldContainer>
  );
};
