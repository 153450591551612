import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Button } from '@creditornot/cb-button';
import { Tag } from '@creditornot/cb-tag';
import React, { useCallback, useMemo } from 'react';
import Bugsnag from '@bugsnag/js';
import { BugsnagPluginReactResult } from '@bugsnag/plugin-react';
import { Spinner } from '@creditornot/cb-progress';

import { Anchor, Divider, InformationDisplay, ModalV2, ResponsiveContainer } from 'components';
import { LocalizedMessage, LocalizedNumber, useI18n } from 'i18n';
import { useFetchOrderDetails } from 'modules/orders';
import { env } from 'config';
import { downloadFile, isDefined } from 'utils';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { getReceipts } from 'modules/orders/api';
import { useFetch } from 'modules/react-query';
import { ErrorNotification } from 'components/ErrorNotification';
import { PaymentMethodGroup } from 'modules/orders/types';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';
import { ordersInteractionDownloadReceipt } from 'telemetry/Avo';

import {
  GroupOrderItem,
  OrderInfoRows,
  OrderItem,
  Payment,
  OrderDetailsInformation,
  OrderDetailsModalHeader,
} from './components';
import { PaymentInfo } from './components/PaymentInfo';

type OrderDetailsModalProps = {
  onClose: () => void;
  corporateId: string;
  orderId?: string | null;
};

const Container = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const StickyDivider = styled(Divider)`
  position: sticky;
  top: 0;
`;

const SpinnerContainer = styled(ResponsiveContainer)`
  margin-top: 48px;
`;

const useIsShowModal = () => {
  const { orderId } = useParams<{ orderId?: string }>();

  return !!orderId;
};

const Row = styled(ResponsiveContainer)`
  display: block;
  padding-top: 32px;
  padding-bottom: 24px;

  & + & {
    border-top: 1px solid ${color.border};
  }
`;

const OrderHostDisplay = styled(InformationDisplay)`
  span {
    margin-top: 0;
  }

  div:not(:first-child) {
    margin-top: 4px;
  }
`;

const LastRow = styled(ResponsiveContainer)`
  border-top: 1px solid ${color.border};
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: flex-end;
`;

const Title = styled.div`
  ${typographyCss.Title1()}
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

const OrderSubTotal = styled.div`
  padding-bottom: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GroupOrderItemError = () => {
  return (
    <Row>
      <ErrorNotification title="Error">
        <LocalizedMessage messageKey="orders.details.something-went-wrong" />
      </ErrorNotification>
    </Row>
  );
};

const OrderDetailsModal = ({ onClose, orderId, corporateId }: OrderDetailsModalProps) => {
  const ErrorBoundary = useMemo(
    () => (Bugsnag.getPlugin('react') as BugsnagPluginReactResult).createErrorBoundary(React),
    [],
  );

  const isShowModal = useIsShowModal();
  const { getLocalizedMessage } = useI18n();
  const { isWoltEmployee } = useIsUserWoltEmployee();
  const ordersInteractionDownloadReceiptEvent = useTelemetryInteractionEvent(
    ordersInteractionDownloadReceipt,
  );

  const { data: order, isLoading: isFetchingOrderDetails } = useFetchOrderDetails(orderId || null);

  const { refetch: triggerDownloadReceipt, isFetching: isDownloadingReceipt } = useFetch({
    queryKey: ['OrderDetailsModal', 'download-receipt', orderId],
    queryFn: () =>
      orderId ? getReceipts(corporateId, orderId) : Promise.reject('Order is missing'),
    onSuccess: (receipt: Blob) => {
      downloadFile(receipt, `${orderId ?? ''}.zip`);
    },
    enabled: false,
  });

  const isSplitPayment = order?.group_order?.split_payment;

  const numberOfPayments = isSplitPayment
    ? order?.group_order?.participants.length || 0
    : order?.payments.length;

  const allParticipants = useMemo(
    () =>
      // Move host into first position
      order?.group_order?.participants.sort((p) =>
        p.user_id === order?.group_order?.host_id ? -1 : 0,
      ),
    [order],
  );

  const itemSubTotal = useMemo(
    () => order?.items.reduce((acc, val) => acc + val.price, 0) ?? 0,
    [order],
  );

  const getPaymentMethodName = (paymentMethodGroup: PaymentMethodGroup | null | undefined) => {
    if (!paymentMethodGroup) return null;
    return [paymentMethodGroup.name, paymentMethodGroup.policy?.name].filter(isDefined).join(', ');
  };

  const paymentMethodName = getPaymentMethodName(order?.payment_method_group);

  const handleDownloadReceipt = useCallback(async () => {
    if (!order) return;
    ordersInteractionDownloadReceiptEvent({ purchaseId: order.id });
    triggerDownloadReceipt();
  }, [order, ordersInteractionDownloadReceiptEvent, triggerDownloadReceipt]);

  return (
    <ModalV2
      onDismiss={onClose}
      sizePreset="sideEndSheet:large"
      open={isShowModal}
      title={order?.venue.name}
      HeaderComponent={({ children, onCloseClick }) => (
        <OrderDetailsModalHeader
          onClose={onCloseClick}
          order={order}
          isWoltEmployee={isWoltEmployee}
        >
          {children}
        </OrderDetailsModalHeader>
      )}
    >
      <Container>
        {isFetchingOrderDetails && (
          <SpinnerContainer>
            <Spinner size="small" />
          </SpinnerContainer>
        )}

        {order && (
          <ErrorBoundary FallbackComponent={GroupOrderItemError}>
            <Row style={{ paddingTop: 0 }}>
              <OrderHostDisplay
                label={
                  order.group_order && getLocalizedMessage('orders.details.additional-payment')
                }
              >
                <div>
                  {isWoltEmployee ? (
                    <Anchor
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${env.OPS_TOOLS_URI}/users/${order.user.id}`}
                    >
                      {order.user.name}
                    </Anchor>
                  ) : (
                    order.user.name
                  )}
                </div>
                <div>{order.user.email}</div>
                {paymentMethodName && <div>{paymentMethodName}</div>}
              </OrderHostDisplay>
            </Row>
            <StickyDivider />
            <Row>
              <InformationDisplay
                labelColor="black"
                label={
                  <Title>
                    <LocalizedMessage messageKey="orders.details.order-information" />
                  </Title>
                }
              >
                <OrderDetailsInformation order={order} isWoltEmployee={isWoltEmployee} />
              </InformationDisplay>
            </Row>

            <Row>
              <InformationDisplay
                labelColor="black"
                label={
                  <Title>
                    <LocalizedMessage messageKey="orders.details.order-items" />

                    {order.group_order && (
                      <Tag variant="lightBlack">
                        <LocalizedMessage
                          messageKey="orders.details.total-participants"
                          values={{
                            participants: order.group_order?.participants.length || 0,
                          }}
                        />
                      </Tag>
                    )}
                  </Title>
                }
              >
                <OrderSubTotal>
                  <LocalizedMessage messageKey="orders.details.item-subtotal" />
                  <LocalizedNumber value={itemSubTotal} currency={order.currency} />
                </OrderSubTotal>

                <Divider />

                {order.group_order
                  ? order.group_order.participants.map((participant, index) => (
                      <GroupOrderItem
                        key={index}
                        showContent
                        orderItems={order.items}
                        participant={participant}
                        isHost={participant.user_id === order.group_order?.host_id}
                        currency={order.currency}
                      />
                    ))
                  : order.items.map((orderItem, index) => (
                      <OrderItem key={index} orderItem={orderItem} currency={order.currency} />
                    ))}

                <OrderInfoRows order={order} />
              </InformationDisplay>
            </Row>
            <Row>
              <InformationDisplay
                labelColor="black"
                label={
                  <Title>
                    <LocalizedMessage messageKey="orders.details.payment-information" />
                    {numberOfPayments && (
                      <Tag variant="lightBlack">
                        <LocalizedMessage
                          messageKey="orders.details.total-payment"
                          values={{ number: numberOfPayments }}
                        />
                      </Tag>
                    )}
                  </Title>
                }
              >
                {isSplitPayment && allParticipants ? (
                  allParticipants.map(
                    (
                      { user_id, accounting_comment = '', basket, first_name, last_name },
                      index,
                    ) => {
                      const isHost = user_id === order.group_order?.host_id;
                      const accountingNote = isHost ? order.accounting_note : accounting_comment;
                      const basketTotal = basket.items.reduce(
                        (acc, val) => acc + val.end_amount,
                        0,
                      );
                      const payAmount = isHost
                        ? basketTotal +
                          order.amounts.tips +
                          order.amounts.delivery -
                          order.amounts.discounts
                        : basketTotal;
                      const paymentMethodName = isHost
                        ? getPaymentMethodName(order.payment_method_group)
                        : '';

                      // host paid group order with participant accounting note
                      if (accountingNote && !isHost && !payAmount) {
                        return (
                          <PaymentInfo
                            key={user_id}
                            name={`${first_name} ${last_name ?? ''}`}
                            accountingComment={accountingNote}
                          />
                        );
                      }

                      if (!payAmount) {
                        return null;
                      }

                      return (
                        <Payment
                          title={
                            <strong>
                              <LocalizedMessage messageKey="orders.details.payment" />
                              {` ${index + 1}`}
                            </strong>
                          }
                          tooltipContent={
                            isHost && (
                              <LocalizedMessage messageKey="orders.details.payment-by-host-tooltip" />
                            )
                          }
                          key={user_id}
                          paymentMethodName={paymentMethodName}
                          totalAmount={payAmount}
                          accountingComment={accountingNote}
                          currency={order.currency}
                          name={<>{`${first_name} ${last_name ?? ''}`}</>}
                        />
                      );
                    },
                  )
                ) : (
                  <Payment
                    payments={order.payments}
                    accountingComment={order.accounting_note}
                    currency={order.currency}
                  />
                )}
              </InformationDisplay>
            </Row>
            {/*  
              In the future, we will stop populating 'refunded' order status for new orders
              So, order.status === 'refunded' is only added for backwards compatibility
            */}
            {(order.status === 'delivered' || order.status === 'refunded') && (
              <LastRow>
                <Button
                  data-test-id="download-receipt-button"
                  loading={isDownloadingReceipt}
                  disabled={isDownloadingReceipt}
                  variant="lightBlue"
                  size="small"
                  onClick={handleDownloadReceipt}
                >
                  <LocalizedMessage messageKey="orders.details.download-receipt" />
                </Button>
              </LastRow>
            )}
          </ErrorBoundary>
        )}
      </Container>
    </ModalV2>
  );
};

export default OrderDetailsModal;
