import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCorporate } from 'modules/corporates';

import { agreeServiceAgreement } from './api';

export const useAgreeServiceAgreement = () => {
  const { data: corporate } = useCorporate();
  const queryClient = useQueryClient();

  return useCallback(
    async (serivceAgreementId: string) => {
      if (!corporate?.id) {
        return Promise.reject('Corporate is missing');
      }
      const result = await agreeServiceAgreement(corporate.id, serivceAgreementId);
      queryClient.invalidateQueries();
      return result;
    },
    [corporate?.id, queryClient],
  );
};
