import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQueryClient } from 'modules/react-query';

import { inviteUsers, InviteUserData } from './api';

export const useInviteUsers = () => {
  const queryClient = useQueryClient();
  const { data: corporate } = useCorporate();

  return useCallback(
    async (data: InviteUserData) => {
      if (!corporate?.id) {
        return Promise.reject('Corporate is missing');
      }
      const res = await inviteUsers(corporate.id, data);
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 500);
      return res;
    },
    [queryClient, corporate?.id],
  );
};
