import { FormikInputField } from 'components';
import { createValidator, isRequired, isNumber, isInteger, min, max } from 'validation';

const validate = createValidator([isRequired, isNumber, isInteger, min(0), max(100)]);

interface Props {
  alwaysShowError?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
}

export const OverdueInterestField = ({ alwaysShowError, disabled, allowClear }: Props) => {
  return (
    <FormikInputField
      name="invoice_overdue_interest"
      type="text"
      required={true}
      validate={validate}
      alwaysShowError={alwaysShowError}
      inputProps={{ disabled }}
      allowClear={allowClear}
    />
  );
};
