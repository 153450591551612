import { useCallback, useMemo } from 'react';
import { intersection } from 'ramda';
import { MultiSelect } from '@creditornot/cb-select';
import styled from 'styled-components';

import { useI18n } from 'i18n';

import { useEventsQueryParams } from './hooks';

interface Props {
  className?: string;
  isEndedEvent: boolean;
}

const Root = styled(MultiSelect)`
  width: 240px;
`;

const useEventUsersFilterOptions = (isEndedEvent: boolean) => {
  const { getLocalizedMessage } = useI18n();
  const statusOptions = useMemo(
    () => [
      {
        label: getLocalizedMessage('events.participants.voucher-column-title'),
        options: isEndedEvent
          ? [
              {
                label: getLocalizedMessage('events.participants.voucher-status-used'),
                value: 'used',
              },
              {
                label: getLocalizedMessage('events.participants.voucher-status-not-used'),
                value: 'notUsed',
              },
            ]
          : [
              {
                label: getLocalizedMessage('events.participants.voucher-status-pending'),
                value: 'pending',
              },
              {
                label: getLocalizedMessage('events.participants.voucher-status-accepted'),
                value: 'accepted',
              },
              {
                label: getLocalizedMessage('events.participants.voucher-status-ordered'),
                value: 'ordered',
              },
            ],
      },
    ],
    [getLocalizedMessage, isEndedEvent],
  );

  return statusOptions;
};

const statuses = ['used', 'notUsed', 'pending', 'accepted', 'ordered'];

export const EventUsersFilter = ({ isEndedEvent, className }: Props) => {
  const [{ statuses: statusQueryParam = [] }, setQueryParams] = useEventsQueryParams();
  const { getLocalizedMessage } = useI18n();
  const clearSearchParams = useCallback(() => {
    setQueryParams({
      statuses: [],
    });
  }, [setQueryParams]);
  const handleFilterChange = useCallback(
    (values: string[]) => {
      clearSearchParams();
      setQueryParams({
        statuses: intersection(values, statuses),
      });
    },
    [clearSearchParams, setQueryParams],
  );

  return (
    <Root
      className={className}
      onChange={handleFilterChange}
      placeholder={getLocalizedMessage('events.user-filter.title')}
      value={[...statusQueryParam]}
      options={useEventUsersFilterOptions(isEndedEvent)}
    />
  );
};
