import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Column, DataTable } from '@creditornot/cb-data-table';
import { useIntl } from 'react-intl';

import { DataCellContent, ResponsiveContainer, ApiErrorNotification, UserName } from 'components';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { UserV2 } from 'modules/users/types';
import { PaginatedData } from 'types/PaginatedData';
import { isDefined } from 'utils';

import { useAdminsModalsController, useAdminsQueryParams } from '../../hooks';
import { TableActionButtons } from './TableActionButtons';

interface Props {
  className?: string;
  usersData: PaginatedData<UserV2> | null | undefined;
  selectedRowKeys: string[];
  onRowSelectionChange: (selectedRowKeys: string[]) => void;
  error: unknown;
  loading: boolean;
}

const ErrorContainer = styled(ResponsiveContainer)`
  padding-top: 22px;
  padding-bottom: 22px;
`;

const DataCellContainer = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.4;
    `}
`;

const useColumns = () => {
  const { formatMessage } = useIntl();
  const { openDeleteUserModal } = useAdminsModalsController();
  const { isWoltEmployee } = useIsUserWoltEmployee();

  const columns: (Column<UserV2, unknown> | undefined)[] = [
    {
      id: 'name',
      name: formatMessage({ id: 'common.name' }),
      render: ({ record: user }) => {
        return (
          <DataCellContainer isDisabled={user.status === 'disabled'}>
            <UserName
              userId={user.user_id}
              userName={`${user.name.first} ${user.name.last}`}
              shouldLinkToOpsTools={isWoltEmployee}
            />
          </DataCellContainer>
        );
      },
    },
    {
      id: 'email',
      name: formatMessage({ id: 'common.email' }),
      render: ({ record: user }) => {
        return <DataCellContent disabled={false}>{user.email}</DataCellContent>;
      },
    },
    {
      id: 'actions',
      style: {
        width: '8%',
      },
      name: '',
      render: ({ record: user }) => {
        return <TableActionButtons onDeleteClick={() => openDeleteUserModal(user.user_id)} />;
      },
    },
  ];

  return columns.filter((column): column is Column<UserV2, unknown> => isDefined(column));
};

export const AdminsDataTable: React.FC<Props> = ({
  usersData,
  selectedRowKeys,
  loading,
  error,
  onRowSelectionChange,
}) => {
  const columns = useColumns();
  const { formatMessage } = useIntl();
  const [{ page = 1, page_size = 50 }, setQueryParams] = useAdminsQueryParams();
  const hideRowSelection = loading || usersData?.results?.length === 0;

  const handleSelectAll = useCallback(() => {
    const isAllSeleted = selectedRowKeys.length === usersData?.results?.length;
    if (isAllSeleted) {
      onRowSelectionChange([]);
      return;
    }
    onRowSelectionChange((usersData?.results || []).map((d) => d.user_id));
  }, [usersData, onRowSelectionChange, selectedRowKeys.length]);

  if (error) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={error} />
      </ErrorContainer>
    );
  }

  const hidePagination = loading || usersData?.total_items_count === 0;

  return (
    <DataTable
      horizontalPadding="wide"
      columns={columns}
      dataSource={usersData?.results || []}
      getRowKey={(user) => user.user_id}
      emptyMessage={{
        title: formatMessage({ id: 'admins.no-admins' }),
      }}
      rowSelection={
        hideRowSelection
          ? undefined
          : {
              selectedRowKeys,
              onChange: onRowSelectionChange,
              onSelectAll: handleSelectAll,
            }
      }
      loading={loading}
      pagination={
        hidePagination
          ? undefined
          : {
              disableWhenLoading: true,
              pageCount: Math.ceil(
                (usersData?.total_items_count ?? 0) / (usersData?.page_size ?? 1),
              ),
              pageSize: page_size,
              activePage: page,
              onPageChange: (p) => setQueryParams({ page: p }),
              onPageSizeChange: (p) => {
                setQueryParams({ page: 1, page_size: p });
              },
              pageSizeOptions: [10, 20, 50, 100],
            }
      }
    />
  );
};
