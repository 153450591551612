import * as t from 'io-ts';

import { corporateServiceApiAgent } from 'apiAgents';
import { logValidationError } from 'validation';

import {
  PaginatedSubscriptionData,
  Subscription,
  SubscriptionInvite,
  SubscriptionUser,
} from './types';

export const fetchSubscription = async (corporateId: string) => {
  const { data } = await corporateServiceApiAgent.get<Subscription>(
    `/portal-api/v1/corporates/${corporateId}/subscription-benefit`,
  );
  return data;
};

export type FetchPaginatedSubscriptionParams = {
  page: number;
  page_size: number;
};

export const fetchPaginatedSubscriptionInvites = async (
  corporateId: string,
  subscriptionId: string,
  params: FetchPaginatedSubscriptionParams,
) => {
  const { data } = await corporateServiceApiAgent.get<
    PaginatedSubscriptionData<SubscriptionInvite>
  >(`/portal-api/v1/corporates/${corporateId}/subscription-benefits/${subscriptionId}/invites`, {
    params: {
      ...params,
      pagination: true,
    },
  });
  logValidationError(
    'fetchPaginatedSubscriptionInvites',
    t.array(SubscriptionInvite),
    data.results,
  );
  return data;
};

export const fetchPaginatedSubscriptionUsers = async (
  corporateId: string,
  subscriptionId: string,
  params: FetchPaginatedSubscriptionParams,
) => {
  const { data } = await corporateServiceApiAgent.get<PaginatedSubscriptionData<SubscriptionUser>>(
    `/portal-api/v1/corporates/${corporateId}/subscription-benefits/${subscriptionId}/members`,
    {
      params: {
        ...params,
        pagination: true,
      },
    },
  );
  logValidationError('fetchPaginatedSubscriptionUsers', t.array(SubscriptionUser), data.results);
  return data;
};

export const fetchSubscriptionUserIds = async (corporateId: string, subscriptionId: string) => {
  const { data } = await corporateServiceApiAgent.get<Array<string>>(
    `/portal-api/v1/corporates/${corporateId}/subscription-benefits/${subscriptionId}/members/user-ids`,
  );
  return data;
};

export const acceptSubscriptionTerms = async (
  corporateId: string,
  subscriptionId: string,
  content: string,
) => {
  const { data } = await corporateServiceApiAgent.post<void>(
    `/portal-api/v1/corporates/${corporateId}/subscription-benefits/${subscriptionId}/terms/accept`,
    { terms_content: content },
  );
  return data;
};

export const inviteExistingMembersToSubscription = async (
  corporateId: string,
  subscriptionId: string,
  userIds: Array<string>,
) => {
  const { data } = await corporateServiceApiAgent.post<void>(
    `/portal-api/v1/corporates/${corporateId}/subscription-benefits/${subscriptionId}/members`,
    { user_ids: userIds },
  );
  return data;
};

export const inviteSubscriptionMembersByEmail = async (
  corporateId: string,
  subscriptionId: string,
  emails: Array<string>,
) => {
  const { data } = await corporateServiceApiAgent.post<void>(
    `/portal-api/v1/corporates/${corporateId}/subscription-benefits/${subscriptionId}/invite`,
    { emails },
  );
  return data;
};

export const resendSubscriptionInvites = async (
  corporateId: string,
  subscriptionId: string,
  inviteIds: Array<string>,
) => {
  const { data } = await corporateServiceApiAgent.post<void>(
    `/portal-api/v1/corporates/${corporateId}/subscription-benefits/${subscriptionId}/resend-invites`,
    { invite_ids: inviteIds },
  );
  return data;
};

export const deleteSubscriptionInvites = async (corporateId: string, inviteIds: Array<string>) => {
  const { data } = await corporateServiceApiAgent.delete(
    `/portal-api/v1/corporates/${corporateId}/user-invites`,
    { data: { invite_ids: inviteIds } },
  );
  return data;
};

export const deleteSubscriptionMembers = async (
  corporateId: string,
  subscriptionId: string,
  userIds: Array<string>,
) => {
  const { data } = await corporateServiceApiAgent.delete(
    `/portal-api/v1/corporates/${corporateId}/subscription-benefits/${subscriptionId}/members`,
    { data: { user_ids: userIds } },
  );
  return data;
};
