import { Redirect, RouteProps } from 'react-router-dom';

import { useIsUserWoltEmployee } from 'modules/wolt-permissions';

import { RouteWithSideNavbar } from './RouteWithSideNavBar';

export const InternalRoute = (props: RouteProps) => {
  const { isWoltEmployee } = useIsUserWoltEmployee();

  if (!isWoltEmployee) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return <RouteWithSideNavbar {...props} />;
};
