import { useField } from 'formik';
import styled from 'styled-components';

import { useI18n } from 'i18n';
import { RadioInputCardGroup } from 'components';

const StyledRadioInputCardGroup = styled(RadioInputCardGroup)`
  & > label:nth-child(2) {
    margin-inline-end: 0;
  }
`;

const PolicyAccountingNoteField = () => {
  const { getLocalizedMessage } = useI18n();
  const [{ name, value }, , { setValue }] = useField<string>('order_comment_required');

  return (
    <StyledRadioInputCardGroup
      name={name}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      direction="row"
      stretched
      options={[
        {
          label: getLocalizedMessage('common.optional'),
          value: 'optional',
        },
        {
          label: getLocalizedMessage('common.required'),
          value: 'required',
        },
      ]}
    />
  );
};

export default PolicyAccountingNoteField;
