import { FormRow, InputContainer } from '@creditornot/cb-form';
import { Alert } from '@creditornot/cb-alert';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { Button, TextButton } from '@creditornot/cb-button';
import { useCallback, useMemo, useState } from 'react';
import { Checkmark, Plus } from '@creditornot/cb-icons';
import styled from 'styled-components';

import { LocalizedMessage, useI18n } from 'i18n';
import { event } from 'modules/google-analytics';
import { useProductTour } from 'modules/product-tour';
import { useCreateGroup, useEditGroup } from 'modules/groups';
import { FormFooter, FormikInputField, FormikTextareaField, ResponsiveContainer } from 'components';
import { createValidator, isNotLongerThan, isRequired } from 'validation';
import { Group } from 'modules/groups/types';
import { processError } from 'utils';
import { useCorporate } from 'modules/corporates';
import { User } from 'modules/users/types';
import { useCreatePolicyInModal } from 'modules/policies';
import { UI_SUCCESS_DELAY } from 'modules/common/constants';

import { FormikPolicySelectField } from './FormikPolicySelectField';

export type CreateGroupFormProps = {
  corporateId: string;
  onSuccess: (group: Group) => void;
  onSuccessAndOpenAddUserModal?: (group: Group) => void;
  onCancel?: () => void;
};

export type FormikCreateGroupFormProps = {
  corporateId: string;
  shouldOpenAddUsersFormAfterSubmit: boolean;
  onCreateAndAddUsersClick?: () => void;
  onCancel?: () => void;
};

export type CreateGroupFormValues = {
  add_to_all: boolean;
  groupName: string;
  description: string;
  users: User[];
  policy_ids: string[];
};

const StyledInputContainer = styled(InputContainer)`
  margin-top: 24px;
`;

const AlertContainer = styled(ResponsiveContainer)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const CreatePolicyButton = styled(TextButton).attrs({
  icon: <Plus />,
  variant: 'blue',
  size: 'small',
})`
  margin-top: 16px;
`;

const initialValues: CreateGroupFormValues = {
  groupName: '',
  description: '',
  users: [],
  policy_ids: [],
  add_to_all: false,
};

const FormikCreateGroupForm = ({
  corporateId,
  shouldOpenAddUsersFormAfterSubmit,
  onCreateAndAddUsersClick,
  onCancel,
}: FormikCreateGroupFormProps) => {
  const { data: corporate } = useCorporate();
  const { submitForm, isSubmitting, status, setStatus, setFieldValue } = useFormikContext();
  const createPolicyInModal = useCreatePolicyInModal();
  const { isInTourMode } = useProductTour();
  const { getLocalizedMessage } = useI18n();
  const handleCreateAndAddUsersClick = useMemo(
    () =>
      onCreateAndAddUsersClick
        ? () => {
            submitForm();
            onCreateAndAddUsersClick();
          }
        : undefined,
    [onCreateAndAddUsersClick, submitForm],
  );
  const handleCloseErrorAlertClick = useCallback(async () => {
    setStatus({ ...status, error: undefined });
  }, [setStatus, status]);
  const handleCreatePolicyClick = useCallback(async () => {
    try {
      const policy = await createPolicyInModal();
      setFieldValue('policy_ids', [policy.id]);
    } catch (error) {
      if (!error) {
        return;
      }
      setStatus({ error });
    }
  }, [createPolicyInModal, setFieldValue, setStatus]);

  return (
    <>
      <FormRow
        horizontalPadding
        labelHtmlFor="groupName"
        label={getLocalizedMessage('groups-form.general-information-section-title')}
        required
      >
        <InputContainer
          labelHtmlFor="groupName"
          label={getLocalizedMessage('groups-form.group-name')}
        >
          <FormikInputField
            required
            name="groupName"
            validate={createValidator([isRequired, isNotLongerThan(35)])}
            inputProps={{
              placeholder: getLocalizedMessage('groups-form.group-name-placeholder'),
            }}
          />
        </InputContainer>
        <StyledInputContainer
          labelHtmlFor="description"
          label={getLocalizedMessage('groups-form.description')}
        >
          <FormikTextareaField
            name="description"
            textareaProps={{
              maxLength: 200,
              style: {
                minHeight: '150px',
              },
            }}
          />
        </StyledInputContainer>
      </FormRow>

      {corporateId && (
        <FormRow
          horizontalPadding
          labelHtmlFor="description"
          label={getLocalizedMessage('groups-form.add-policy-section-title')}
        >
          <Alert size="medium" variant="info">
            <LocalizedMessage
              messageKey="groups-form.policies-explanation"
              values={{ paymentMethodName: <strong>{corporate?.name}</strong> }}
            />
          </Alert>
          <StyledInputContainer label={getLocalizedMessage('groups.add-group-policy')}>
            <FormikPolicySelectField
              name="policy_ids"
              selectProps={{
                menuPlacement: 'top',
              }}
            />
          </StyledInputContainer>
          {!isInTourMode && (
            <CreatePolicyButton onClick={handleCreatePolicyClick}>
              <LocalizedMessage messageKey="groups.create-a-new-policy" />
            </CreatePolicyButton>
          )}
        </FormRow>
      )}

      {status?.error && (
        <AlertContainer>
          <Alert
            onCloseButtonClick={handleCloseErrorAlertClick}
            style={{ flex: 1 }}
            variant="error"
            size="medium"
            title={processError(status.error).message}
          >
            {processError(status.error).data}
          </Alert>
        </AlertContainer>
      )}

      <FormFooter
        rightContent={
          <>
            {onCancel && (
              <Button
                onClick={onCancel}
                disabled={isSubmitting || status?.submitSucceeded}
                size="small"
                variant="lightBlue"
              >
                <LocalizedMessage messageKey="common.cancel" />
              </Button>
            )}
            {handleCreateAndAddUsersClick && (
              <Button
                onClick={handleCreateAndAddUsersClick}
                loading={isSubmitting && shouldOpenAddUsersFormAfterSubmit ? true : undefined}
                disabled={isSubmitting || status?.submitSucceeded}
                icon={shouldOpenAddUsersFormAfterSubmit && status?.submitSucceeded && <Checkmark />}
                size="small"
                variant="lightBlue"
              >
                <LocalizedMessage messageKey="groups.groups-form.create-group-and-add-members" />
              </Button>
            )}
            <Button
              data-test-id="CreateGroupForm.SubmitButton"
              onClick={submitForm}
              disabled={isSubmitting || status?.submitSucceeded}
              loading={isSubmitting && !shouldOpenAddUsersFormAfterSubmit ? true : undefined}
              icon={
                !shouldOpenAddUsersFormAfterSubmit &&
                status?.submitSucceeded && <Checkmark data-test-id="CreateGroupForm.Checkmark" />
              }
              size="small"
              variant="blue"
            >
              <LocalizedMessage messageKey="groups-form.create-group" />
            </Button>
          </>
        }
      />
    </>
  );
};

export const CreateGroupForm = ({
  corporateId,
  onSuccess,
  onSuccessAndOpenAddUserModal,
  onCancel,
}: CreateGroupFormProps) => {
  const [shouldOpenAddUsersFormAfterSubmit, setShouldOpenAddUsersFormAfterSubmit] = useState(false);
  const { isInTourMode } = useProductTour();
  const createGroup = useCreateGroup();
  const editGroup = useEditGroup();
  const handleCreateAndAddUsersClick = useCallback(
    () => setShouldOpenAddUsersFormAfterSubmit(true),
    [],
  );
  const handleSubmit = useCallback(
    async (
      { groupName, description, policy_ids, add_to_all }: CreateGroupFormValues,
      actions: FormikHelpers<CreateGroupFormValues>,
    ) => {
      actions.setSubmitting(false);
      actions.setStatus({ submitSucceeded: true });
      try {
        const group = await createGroup({
          name: groupName,
          description: description,
        });

        const editedGroup = await editGroup(group.id, {
          policy_ids,
          add_to_all,
        });

        if (shouldOpenAddUsersFormAfterSubmit) {
          setTimeout(() => onSuccessAndOpenAddUserModal?.(editedGroup), UI_SUCCESS_DELAY);
        } else {
          setTimeout(() => onSuccess(editedGroup), UI_SUCCESS_DELAY);
        }
        if (isInTourMode) {
          event({
            category: 'new_user_onboarding',
            label: 'create_group',
            action: 'create',
          });
        }
      } catch (error) {
        actions.setStatus({ error });
        actions.setSubmitting(false);
      }
    },
    [
      createGroup,
      editGroup,
      isInTourMode,
      onSuccess,
      onSuccessAndOpenAddUserModal,
      shouldOpenAddUsersFormAfterSubmit,
    ],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <FormikCreateGroupForm
        corporateId={corporateId}
        shouldOpenAddUsersFormAfterSubmit={shouldOpenAddUsersFormAfterSubmit}
        onCreateAndAddUsersClick={
          onSuccessAndOpenAddUserModal ? handleCreateAndAddUsersClick : undefined
        }
        onCancel={onCancel}
      />
    </Formik>
  );
};
