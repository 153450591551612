import { color } from '@creditornot/cb-ingredients/design-tokens';
import React from 'react';
import styled from 'styled-components';

import { LocalizedMessage, LocalizedNumber } from 'i18n';
import { Divider } from 'components';
import { OrderDetails } from 'modules/orders/types';

type OrderInfoRowsProps = {
  order: OrderDetails;
  className?: string;
};

const Root = styled.div`
  border-top: 1px solid ${color.border};
`;

const PaymentBreakdownRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: 400;

  & + & {
    border-top: 1px solid ${color.border};
  }
`;

const PayableAmount = styled(PaymentBreakdownRow)`
  font-weight: 700;
`;

const DangerLocalizedNumber = styled(LocalizedNumber)`
  color: ${color.textNegative};
`;

export const OrderInfoRows = ({
  order: {
    currency,
    amounts: {
      delivery: deliveryFee,
      discounts: discountAmount,
      refunds: refunded,
      tips: tipsAmount,
      total: totalAmount,
    },
  },
  className,
}: OrderInfoRowsProps) => {
  return (
    <Root className={className}>
      {!!deliveryFee && (
        <PaymentBreakdownRow>
          <LocalizedMessage messageKey="orders.details.delivery-fee" />
          <LocalizedNumber withSign value={deliveryFee} currency={currency} />
        </PaymentBreakdownRow>
      )}
      {!!tipsAmount && (
        <PaymentBreakdownRow>
          <LocalizedMessage messageKey="orders.details.courier-tip" />
          <LocalizedNumber withSign value={tipsAmount} currency={currency} />
        </PaymentBreakdownRow>
      )}
      {!!discountAmount && (
        <PaymentBreakdownRow>
          <LocalizedMessage messageKey="orders.details.credits" />
          <DangerLocalizedNumber withSign value={-discountAmount} currency={currency} />
        </PaymentBreakdownRow>
      )}
      {!!refunded && (
        <PaymentBreakdownRow>
          <LocalizedMessage messageKey="orders.details.refund" />
          <DangerLocalizedNumber withSign value={-refunded} currency={currency} />
        </PaymentBreakdownRow>
      )}
      <Divider />
      <PayableAmount>
        <LocalizedMessage messageKey="orders.details.total-payable-amount" />
        <LocalizedNumber value={totalAmount || 0} currency={currency} />
      </PayableAmount>
    </Root>
  );
};
