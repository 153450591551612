import { useI18n } from 'i18n';
import { FormikInputField } from 'components';
import { createValidator, isRequired, isNotLongerThan } from 'validation';

const validate = createValidator([isRequired, isNotLongerThan(20)]);

interface Props {
  index: number;
}

const PolicyDeliveryLocationNameField = ({ index }: Props) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <FormikInputField
      validate={validate}
      name={`delivery_locations.${index}.name`}
      type="text"
      inputProps={{
        placeholder: getLocalizedMessage('policies.location-name-placeholder'),
      }}
    />
  );
};

export default PolicyDeliveryLocationNameField;
