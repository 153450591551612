import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { useCorporate } from 'modules/corporates/useCorporate';
import { isDefined, isEmptyObject } from 'utils';
import { fetchOrders } from 'modules/orders/api';

import { OrderFilterOrderType, OrderFilterStatus } from './types';

const get30DaysRange = () => {
  const startedDay = new Date();
  startedDay.setFullYear(startedDay.getFullYear(), startedDay.getMonth() - 1, startedDay.getDate());

  const endedDay = new Date();
  endedDay.setFullYear(endedDay.getFullYear(), endedDay.getMonth(), endedDay.getDate());

  return {
    startedDay: startedDay.toISOString(),
    endedDay: endedDay.toISOString(),
  };
};

const getMonthDaysRange = (month: number, year: number) => {
  const startedDay = new Date();
  startedDay.setFullYear(year, month, 1);
  startedDay.setHours(0, 0, 0, 0);

  const endedDay = new Date();
  endedDay.setFullYear(year, month + 1, 0);
  endedDay.setHours(23, 59, 59, 0);

  return {
    startedDay: startedDay.toISOString(),
    endedDay: endedDay.toISOString(),
  };
};

const DEFAULT_PAGE_SIZE = 50;

export const useOrdersHistory = () => {
  const { data: corporate } = useCorporate();

  const [params, setQueryParamsState] = useState<{
    page?: number;
    searchBy?: string;
    searchQuery?: string;
    status?: OrderFilterStatus[];
    month?: number;
    year?: number;
    page_size?: number;
    groups?: string[];
    types?: OrderFilterOrderType[];
  }>({
    page: 1,
    searchBy: 'user',
    searchQuery: '',
    status: [],
    month: undefined,
    year: undefined,
    page_size: DEFAULT_PAGE_SIZE,
    groups: [],
    // Default filter should only be applied for corporates with both WfW and
    // subscription benefit, otherwise all orders can be shown by default
    types:
      corporate?.products.subscription_benefit_enabled && corporate?.products.waw_enabled
        ? ['purchase', 'giftcard']
        : [],
  });

  const enabled = !!corporate && !isEmptyObject(params);

  const queryFn = useCallback(() => {
    if (!enabled) {
      return Promise.reject();
    }
    const {
      status,
      page = 1,
      page_size = DEFAULT_PAGE_SIZE,
      searchBy,
      searchQuery,
      groups,
      types,
    } = params;
    const { startedDay, endedDay } =
      isDefined(params.month) && isDefined(params.year)
        ? getMonthDaysRange(params.month, params.year)
        : get30DaysRange();

    const isPreOrder = status && status.length > 0 ? status.includes('pre-order') : undefined;
    const newStatuses = status?.filter((status) => status !== 'pre-order');
    const includeSearchBy = searchBy && !!searchQuery;
    return fetchOrders(corporate.id, {
      page,
      page_size,
      searchBy: includeSearchBy ? searchBy : undefined,
      searchQuery,
      group_ids: groups?.length ? groups : undefined,
      is_preorder: isPreOrder,
      statuses: newStatuses?.length ? newStatuses : undefined,
      purchase_types: types?.length ? types : undefined,
      received_after: startedDay,
      received_before: endedDay,
    });
  }, [corporate?.id, params, enabled]);

  const {
    data: ordersData,
    error,
    isFetching,
  } = useQuery({
    queryKey: ['orders', corporate?.id, params],
    queryFn,
    enabled,
    refetchInterval: !isDefined(params.month) || !isDefined(params.year) ? 30000 : undefined,
  });

  const setQueryParams: (queries: typeof params) => void = useCallback(
    (queries) => {
      const shouldResetPage =
        'groups' in queries ||
        'month' in queries ||
        'page_size' in queries ||
        'status' in queries ||
        'searchQuery' in queries ||
        'year' in queries ||
        'types' in queries;

      const newQueries = {
        page: shouldResetPage ? 1 : queries.page,
        ...queries,
      };

      setQueryParamsState((oldQueries) => ({ ...oldQueries, ...newQueries }));
    },
    [setQueryParamsState],
  );

  return { params, setQueryParams, ordersData, error, isFetching } as const;
};
