import { useCallback } from 'react';

import {
  useQueryParams,
  NeverNullStringParam,
  NeverNullNumberParam,
  NeverNullDelimitedArrayParam,
  WoltPageSizeParam,
  withDefault,
} from 'modules/react-query-params';

export const useEventsQueryParams = () => {
  const query = useQueryParams({
    page: withDefault(NeverNullNumberParam, 1),
    page_size: WoltPageSizeParam,
    event_statuses: NeverNullDelimitedArrayParam,
    email: NeverNullStringParam,
    event_user_invite_ids: NeverNullDelimitedArrayParam,
    statuses: NeverNullDelimitedArrayParam,
    active_tab: withDefault(NeverNullStringParam, 'event_statistic'),
  });
  const [params, setReactQueryParams] = query;

  const setQueryParams: typeof setReactQueryParams = useCallback(
    (queries) => {
      const shouldResetPage =
        'page_size' in queries ||
        'event_statuses' in queries ||
        'email' in queries ||
        'statuses' in queries;
      setReactQueryParams(({ page, ...staledQueries }) => ({
        ...staledQueries,
        page: shouldResetPage ? 1 : page,
        ...queries,
      }));
    },
    [setReactQueryParams],
  );
  return [params, setQueryParams] as const;
};
