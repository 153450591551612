import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { InputContainer } from '@creditornot/cb-form';
import { Checkmark } from '@creditornot/cb-icons';
import { Button } from '@creditornot/cb-button';
import { typographyCss } from '@creditornot/cb-ingredients';

import {
  ApiErrorNotification,
  FormikInputField,
  FormikTextareaField,
  FormFooter,
} from 'components';
import { LocalizedMessage, useI18n } from 'i18n';
import { createValidator, isRequired, isNotLongerThan } from 'validation';
import { useCreateGroup } from 'modules/groups';
import { Group } from 'modules/groups/types';

import { CreateGroupFormValues } from '../types';

interface Props {
  error: unknown;
  onBack: () => void;
  onSuccess: (group: Group) => void;
  corporateId: string | null;
}

const StyledInputContainer = styled(InputContainer)`
  margin-top: 24px;
`;

const Title = styled.div`
  ${typographyCss.Title1()};
`;

const Container = styled.div`
  padding: 24px;
`;

const validateGroupName = createValidator([isRequired, isNotLongerThan(20)]);

const CreateGroupForm: React.FC<Props> = ({ onSuccess, corporateId, onBack, error }) => {
  const { getLocalizedMessage } = useI18n();
  const createGroup = useCreateGroup();

  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
      }}
      onSubmit={(values: CreateGroupFormValues, actions) => {
        if (corporateId) {
          return createGroup(values)
            .then((group) => {
              actions.setSubmitting(false);
              actions.setStatus({ submitSucceeded: true });
              onSuccess(group);
            })
            .catch((error: unknown) => {
              actions.setSubmitting(false);
              actions.setStatus({ error });
            });
        }
      }}
    >
      {({ isSubmitting, status, submitForm }) => (
        <Form>
          <Container>
            <Title>
              <LocalizedMessage messageKey="users.new-user-modal.create-group-form-title" />
            </Title>

            <StyledInputContainer label={getLocalizedMessage('groups-form.group-name')}>
              <FormikInputField
                validate={validateGroupName}
                required={true}
                inputProps={{
                  placeholder: getLocalizedMessage('groups-form.group-name-placeholder'),
                }}
                name="name"
              />
            </StyledInputContainer>

            <StyledInputContainer label={getLocalizedMessage('groups-form.description')}>
              <FormikTextareaField
                name="description"
                textareaProps={{
                  maxLength: 200,
                  style: {
                    minHeight: '150px',
                  },
                }}
              />
            </StyledInputContainer>

            {(status?.error || error) && (
              <StyledInputContainer>
                <ApiErrorNotification error={status.error || error} />
              </StyledInputContainer>
            )}
          </Container>

          <FormFooter
            rightContent={
              <>
                <Button size="small" variant="lightBlue" onClick={onBack}>
                  <LocalizedMessage messageKey="common.back" />
                </Button>
                <Button
                  size="small"
                  variant="blue"
                  onClick={submitForm}
                  disabled={isSubmitting || status?.submitSucceeded}
                  loading={isSubmitting}
                  icon={status?.submitSucceeded && <Checkmark />}
                >
                  <LocalizedMessage messageKey="common.create" />
                </Button>
              </>
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreateGroupForm;
