import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';

import { OrderItem as OrderItemType } from 'modules/orders/types';
import { LocalizedNumber } from 'i18n';

type OrderItemProps = {
  className?: string;
  currency: string;
  orderItem: OrderItemType;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;

  & + & {
    border-top: 1px solid ${color.border};
  }
`;

const Content = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
`;

const PayAmount = styled.div`
  margin-inline-start: auto;
`;

const OrderItemComponent = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  p {
    padding: 0;
    margin: 0;
  }

  li {
    display: flex;
  }

  li + li {
    color: ${color.textSubdued};
    margin-top: 4px;
  }
`;

export const OrderItem = ({ className, currency, orderItem }: OrderItemProps) => {
  return (
    <Root className={className}>
      <Content>
        <OrderItemComponent>
          <li>
            {`${orderItem.count} × ${orderItem.name}`}

            <PayAmount>
              <LocalizedNumber value={orderItem.price} currency={currency} />
            </PayAmount>
          </li>

          {orderItem.options.map((option, index) => {
            const optionTotal = option.values.reduce(
              (total, val) => total + val.count * val.price,
              0,
            );

            return (
              <li key={index}>
                <p>
                  {`${option.name}${option.values.length ? ':' : ''} ${option.values
                    .map((value) => value.value)
                    .join(', ')}`}

                  {!!optionTotal && (
                    <>
                      {' ('}
                      <LocalizedNumber withSign value={optionTotal} currency={currency} />
                      {')'}
                    </>
                  )}
                </p>
              </li>
            );
          })}
        </OrderItemComponent>
      </Content>
    </Root>
  );
};
