import styled from 'styled-components';

import { FormikInputField } from 'components';
import { createValidator, isRequired, isNumber, isGreaterThan } from 'validation';

const validate = createValidator([isRequired, isNumber, isGreaterThan(0)]);

interface Props {
  alwaysShowError?: boolean;
  currency: string;
}

const StyledFormikInputField = styled(FormikInputField)`
  flex: 1;
`;

const PolicySpendingLimitAmountField = ({ alwaysShowError, currency }: Props) => {
  return (
    <StyledFormikInputField
      testId="policy.spending-limit-amount-input"
      name="spending_limit_amount"
      type="text"
      required={true}
      validate={validate}
      alwaysShowError={alwaysShowError}
      allowClear={false}
      inputProps={{
        suffixText: currency,
      }}
    />
  );
};

export default PolicySpendingLimitAmountField;
