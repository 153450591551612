import { ConfirmationModal } from 'components';
import { LocalizedMessage } from 'i18n';
import { useDeleteEvent } from 'modules/events';
import { Event } from 'modules/events/types';

interface Props {
  show: boolean;
  eventId: string;
  onClose: () => void;
  onSuccess: () => void;
  eventStatus: Event['status'];
}

export const CancelEventModal = ({ show, eventId, onClose, onSuccess, eventStatus }: Props) => {
  const deleteEvent = useDeleteEvent();

  return (
    <ConfirmationModal
      title={
        eventStatus === 'ongoing' ? (
          <LocalizedMessage messageKey="events.cancel-ongoing-event-modal.title" />
        ) : (
          <LocalizedMessage messageKey="events.delete-upcoming-event-modal.title" />
        )
      }
      content={
        eventStatus === 'ongoing' ? (
          <LocalizedMessage messageKey="events.cancel-ongoing-event-modal.text" />
        ) : (
          <LocalizedMessage messageKey="events.delete-upcoming-event-modal.text" />
        )
      }
      onClose={onClose}
      show={show}
      cancelButtonMesssge={
        eventStatus === 'ongoing' ? (
          <LocalizedMessage messageKey="events.cancel-ongoing-event-modal.close-button" />
        ) : (
          <LocalizedMessage messageKey="events.delete-upcoming-event-modal.cancel-button" />
        )
      }
      confirmationButtonMessage={
        eventStatus === 'ongoing' ? (
          <LocalizedMessage messageKey="events.cancel-ongoing-event-modal.cancel-button" />
        ) : (
          <LocalizedMessage messageKey="events.delete-upcoming-event-modal.delete-button" />
        )
      }
      confirmationButtonVariant="lightRed"
      onSuccess={() => setTimeout(onSuccess, 500)}
      onConfirm={async () => {
        await deleteEvent(eventId);
      }}
    />
  );
};
