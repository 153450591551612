import { useCallback, useMemo } from 'react';
import { Empty } from '@creditornot/cb-placeholders';
import { TextButton } from '@creditornot/cb-button';
import styled from 'styled-components';

import { useCorporate } from 'modules/corporates';
import { useMedia } from 'modules/media';
import { UserV2 } from 'modules/users/types';
import { LocalizedMessage, useI18n } from 'i18n';
import { PaginatedData } from 'types/PaginatedData';

import { UsersDataTable } from './UsersDataTable';
import { UsersDataList } from './UsersDataList';
import { useUsersQueryParams } from '../hooks';

interface Props {
  usersData: PaginatedData<UserV2> | undefined | null;
  isLoading: boolean;
  error: unknown;
}
const Root = styled.div`
  display: flex;
  align-items: center;
  margin-top: 100px;
  justify-content: center;
`;

export const UsersDataView: React.FC<Props> = ({
  usersData,
  isLoading: isLoadingUsers,
  error: usersError,
}) => {
  const { data: corporate } = useCorporate();
  const { getLocalizedMessage } = useI18n();
  const mediumView = useMedia('medium');
  const [
    { users: userIdsQueryParam = [], groups: groupsQueryParam, status: statusQueryParam },
    setQueryParams,
  ] = useUsersQueryParams();

  const gotoInvitesView = useCallback(() => {
    setQueryParams({
      showInvites: true,
      invites: undefined,
      users: undefined,
      status: undefined,
    });
  }, [setQueryParams]);

  const isFiltering = useMemo(
    () => [...(groupsQueryParam || []), ...(statusQueryParam || [])].length > 0,
    [groupsQueryParam, statusQueryParam],
  );

  const handleRowSelectionChange = useCallback(
    (selectedRowKeys: string[]) => {
      setQueryParams({
        users: selectedRowKeys,
      });
    },
    [setQueryParams],
  );

  if (!corporate) {
    return null;
  }

  const isEmpty = !usersError && usersData?.results.length === 0 && !isFiltering && !isLoadingUsers;

  if (isEmpty) {
    return (
      <Root>
        <Empty
          title={getLocalizedMessage('users.users-view.empty.no-users')}
          description={
            <LocalizedMessage messageKey="users.users-view.empty.no-users-description" />
          }
          footer={
            <TextButton onClick={gotoInvitesView}>
              <LocalizedMessage messageKey="users.users-view.empty.users-button" />
            </TextButton>
          }
        />
      </Root>
    );
  }

  return mediumView ? (
    <UsersDataList
      users={usersData?.results}
      error={usersError}
      loading={isLoadingUsers}
      selectedRowKeys={userIdsQueryParam}
      onRowSelectionChange={handleRowSelectionChange}
    />
  ) : (
    <UsersDataTable
      usersData={usersData}
      error={usersError}
      isFiltering={isFiltering}
      loading={isLoadingUsers}
      selectedRowKeys={userIdsQueryParam}
      onRowSelectionChange={handleRowSelectionChange}
    />
  );
};
