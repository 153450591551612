import { useCallback } from 'react';

import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchCorporate } from './api';
import { Corporate } from './types';

export const useFetchCorporate = <T = Corporate>(
  id: string | null,
  config?: UseQueryOptions<Corporate, unknown, T>,
) => {
  const queryFn = useCallback(() => {
    if (!id) {
      return Promise.reject();
    }
    return fetchCorporate(id);
  }, [id]);
  return useQuery({ queryKey: ['corporate', id], queryFn, enabled: !!id, ...config });
};
