import { FormRowV2, FormSection, FormikInputField } from 'components';
import { isRequired, createValidator, isNumber } from 'validation';

type Props = {
  alwaysShowError?: boolean;
};

const validate = createValidator([isRequired]);

const validateSubsidiaryId = createValidator([isRequired, isNumber]);

const NetsuiteConfigFormSection: React.FC<Props> = ({ alwaysShowError }) => {
  return (
    <FormSection title="Netsuite config">
      <FormRowV2
        htmlFor="netsuite_config.bank_account_name"
        title="Bank account name"
        content={
          <FormikInputField
            name="netsuite_config.bank_account_name"
            type="text"
            required={true}
            validate={validate}
            alwaysShowError={alwaysShowError}
          />
        }
      />

      <FormRowV2
        htmlFor="netsuite_config.country_name"
        title="Country name"
        content={
          <FormikInputField
            name="netsuite_config.country_name"
            type="text"
            required={true}
            validate={validate}
            alwaysShowError={alwaysShowError}
          />
        }
      />

      <FormRowV2
        htmlFor="netsuite_config.delivery_item_name"
        title="Delivery item name"
        content={
          <FormikInputField
            name="netsuite_config.delivery_item_name"
            type="text"
            required={true}
            validate={validate}
            alwaysShowError={alwaysShowError}
          />
        }
      />

      <FormRowV2
        htmlFor="netsuite_config.department_name"
        title="Department name"
        content={<FormikInputField name="netsuite_config.department_name" type="text" />}
      />

      <FormRowV2
        htmlFor="netsuite_config.purchase_item_name"
        title="Purchase item name"
        content={
          <FormikInputField
            name="netsuite_config.purchase_item_name"
            type="text"
            required={true}
            validate={validate}
            alwaysShowError={alwaysShowError}
          />
        }
      />

      <FormRowV2
        htmlFor="netsuite_config.subsidiary_id"
        title="Subsidiary id"
        content={
          <FormikInputField
            name="netsuite_config.subsidiary_id"
            type="text"
            required={true}
            validate={validateSubsidiaryId}
            alwaysShowError={alwaysShowError}
          />
        }
      />

      <FormRowV2
        htmlFor="netsuite_config.tips_item_name"
        title="Tips item name"
        content={
          <FormikInputField
            name="netsuite_config.tips_item_name"
            type="text"
            required={true}
            validate={validate}
            alwaysShowError={alwaysShowError}
          />
        }
      />
    </FormSection>
  );
};

export default NetsuiteConfigFormSection;
