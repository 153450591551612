import { useEffect, useState, useCallback, forwardRef } from 'react';
import { MagnifyingGlass } from '@creditornot/cb-icons';
import { Input, InputProps } from '@creditornot/cb-input';
import { Spinner } from '@creditornot/cb-progress';

import { useDebounce } from 'modules/hooks';

export type Props = InputProps & {
  onSearch?: (text: string) => void;
  debounceTime?: number;
  isLoading?: boolean;
};

export const SearchInput = forwardRef<HTMLInputElement, Props>(
  ({ onSearch, debounceTime = 350, onChange, isLoading, ...rest }, ref) => {
    const [text, setText] = useState<string>('');
    const debouncedText = useDebounce(text, debounceTime);

    useEffect(() => {
      onSearch?.(debouncedText);
    }, [debouncedText, onSearch]);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value);
        onChange?.(e);
      },
      [onChange],
    );

    return (
      <Input
        onChange={handleChange}
        icon={isLoading ? <Spinner size="small" /> : <MagnifyingGlass />}
        inputRef={ref}
        {...rest}
        type="text"
      />
    );
  },
);
