import { color } from '@creditornot/cb-ingredients/design-tokens';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '@creditornot/cb-ingredients';

import CloseIcon from 'assets/images/close.svg';

interface CloseButtonProps {
  variant?: 'primary' | 'secondary' | 'danger';
  size?: 'small' | 'normal' | 'large';
  showBackground?: boolean;
  onClick?: () => void;
}

const Background = styled.div<{
  size?: CloseButtonProps['size'];
  variant?: CloseButtonProps['variant'];
}>`
  border-radius: 50%;
  height: 130%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition:
    height 150ms ease-in-out,
    width 150ms ease-in-out,
    background 150ms ease-in-out;
  width: 130%;

  ${({ variant = 'primary' }) =>
    variant === 'primary'
      ? css`
          background: ${colors.pepper4};
        `
      : variant === 'secondary'
        ? css`
            background: ${colors.wolt4};
          `
        : css`
            background: ${colors.strawberry4};
          `}
`;

const StyledCloseIcon = styled(CloseIcon)<{
  size?: 'small' | 'normal' | 'large';
  variant?: 'primary' | 'secondary' | 'danger';
}>`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 150ms ease-in-out;

  & > path {
    ${({ variant = 'primary' }) =>
      variant === 'primary'
        ? css`
            fill: ${color.text};
          `
        : variant === 'secondary'
          ? css`
              fill: ${color.textBrand};
            `
          : css`
              fill: ${color.textNegative};
            `}
  }

  ${({ size = 'normal' }) =>
    size === 'small'
      ? css`
          height: 8px;
          width: 8px;
        `
      : size === 'normal'
        ? css`
            height: 12px;
            width: 12px;
          `
        : size === 'large' &&
          css`
            height: 14px;
            width: 14px;
          `}
`;

const Close = styled.button<CloseButtonProps>`
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
  position: relative;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 3px ${color.outlineFocused};
    border-radius: 16px;
  }

  ${({ size = 'normal' }) =>
    size === 'small'
      ? css`
          height: 16px;
          width: 16px;
        `
      : size === 'normal'
        ? css`
            height: 20px;
            width: 20px;
          `
        : size === 'large' &&
          css`
            height: 24px;
            width: 24px;
          `}

  &:hover > ${Background} {
    height: 140%;
    width: 140%;

    ${({ variant = 'primary' }) =>
      variant === 'primary'
        ? css`
            background-color: ${color.border};
          `
        : variant === 'secondary'
          ? css`
              background-color: ${colors.wolt8};
            `
          : css`
              background-color: ${colors.strawberry8};
            `}
  }

  &:active > ${Background} {
    height: 135%;
    width: 135%;

    ${({ variant = 'primary' }) =>
      variant === 'primary'
        ? css`
            background-color: ${color.border};
          `
        : variant === 'secondary'
          ? css`
              background-color: ${colors.wolt16};
            `
          : css`
              background-color: ${colors.strawberry16};
            `}
  }

  &:active > ${StyledCloseIcon} {
    transform: translate(-50%, -50%) scale(0.8);
  }
`;

const CloseButton = ({ size, variant, showBackground = true, ...rest }: CloseButtonProps) => (
  <Close size={size} {...rest}>
    <StyledCloseIcon variant={variant} size={size} />
    {showBackground && <Background variant={variant} />}
  </Close>
);

export default CloseButton;
