import { FormattedMessage } from 'react-intl';

import { MessageIds } from './types';

interface LocalizedMessageProps {
  messageKey: MessageIds;
  values?: {
    [key: string]: any;
  };
  className?: string;
}

/**
 * @deprecated Use FormattedMessage from react-intl instead
 */
const LocalizedMessage = ({ messageKey, values, className }: LocalizedMessageProps) => {
  return <FormattedMessage id={messageKey} values={values} css={className} />;
};

export default LocalizedMessage;
