import { Tag } from '@creditornot/cb-tag';

import { LocalizedMessage } from 'i18n';
import { Event } from 'modules/events/types';

interface Props {
  status: Event['status'];
  className?: string;
}

const statusRecord = {
  ongoing: (
    <Tag size="small" variant="lightGreen">
      <LocalizedMessage messageKey="events.status.ongoing" />
    </Tag>
  ),
  upcoming: (
    <Tag size="small" variant="lightDarkBlue">
      <LocalizedMessage messageKey="events.status.upcoming" />
    </Tag>
  ),
  cancelled: (
    <Tag size="small" variant="lightRed">
      <LocalizedMessage messageKey="events.status.canceled" />
    </Tag>
  ),
  deleted: (
    <Tag size="small" variant="lightRed">
      <LocalizedMessage messageKey="events.status.canceled" />
    </Tag>
  ),
  finished: (
    <Tag size="small" variant="lightBlack">
      <LocalizedMessage messageKey="events.status.finished" />
    </Tag>
  ),
};

export const EventStatus: React.FC<Props> = ({ status, className }) => {
  return <div className={className}>{statusRecord[status]}</div>;
};
