import styled from 'styled-components';
import { FormHeader, FormRow, InputContainer } from '@creditornot/cb-form';
import { useFormikContext } from 'formik';

import { LocalizedMessage, useI18n } from 'i18n';
import { breakpoints } from 'modules/media';
import { useCountryConfig } from 'modules/country-configs';
import {
  BillingCycleField,
  CreditLimitField,
  CustomerReferenceField,
  InvoicingEmailField,
  InvoicingFeeField,
  InvoicingOptionField,
  OrderOptionField,
  OverdueInterestField,
  PaymentDueInField,
  PreferredInvoiceLanguageField,
  ReceiptEmailField,
  SendInvoiceCopyToEmailsField,
  VatIdField,
  EDIOperatorId,
  ElectronicInvoicingAddress,
  VATExemptionDisclaimer,
  SingleTransactionLimitField,
  DailyTransactionLimitField,
  IsElectronicInvoicingDetailsEnabledField,
} from 'modules/corporates/components';

import { CreateCorporateFormValues } from '../types';

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: 24px;
  }
`;

const StyledFormRow = styled(FormRow)`
  @media (max-width: ${breakpoints.medium}px) {
    flex-direction: column;

    & > div {
      margin-bottom: 8px;
    }
  }
`;

interface Props {
  currency: string;
}

export const InvoicingDetailsFormSection = ({ currency }: Props) => {
  const { getLocalizedMessage, getLocalizedNumber } = useI18n();
  const {
    values: { country, is_electronic_invoicing_enabled, products },
  } = useFormikContext<CreateCorporateFormValues>();
  const { data: selectedCountryConfig } = useCountryConfig(country);
  const currencyToDisplay = selectedCountryConfig?.currency ?? currency;
  const wfwEnabled = products.includes('WOLT_AT_WORK');

  return (
    <div>
      <FormHeader horizontalPadding>
        <LocalizedMessage messageKey="corporate-form.invoicing-section-title" />
      </FormHeader>
      <StyledFormRow
        horizontalPadding
        required
        label={getLocalizedMessage('corporate-form.billing_cycle')}
        labelHtmlFor="billing_twice_a_month"
      >
        <BillingCycleField />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        required
        label={getLocalizedMessage('corporate-form.vat_id')}
        labelHtmlFor="vat_id"
        labelAlign="center"
      >
        <VatIdField />
      </StyledFormRow>

      <StyledFormRow
        horizontalPadding
        label={getLocalizedMessage('corporate-form.customer_reference')}
        labelHtmlFor="customer_reference"
        labelAlign="center"
      >
        <CustomerReferenceField />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        label={getLocalizedMessage('corporate-form.preferred_invoice_language')}
        labelHtmlFor="preferred_invoice_locale"
        labelAlign="center"
      >
        <PreferredInvoiceLanguageField />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        required
        label={getLocalizedMessage('corporate-form.invoicing_fee')}
        labelHtmlFor="invoice_fee_percentage"
        labelAlign="center"
      >
        <InvoicingFeeField />
      </StyledFormRow>

      <StyledFormRow
        horizontalPadding
        required
        label={getLocalizedMessage('corporate-form.payment_due_in')}
        labelHtmlFor="payment_term_delay_days"
        labelAlign="center"
      >
        <PaymentDueInField />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        required
        label={getLocalizedMessage('corporate-form.overdue_interest')}
        labelHtmlFor="invoice_overdue_interest"
        labelAlign="center"
      >
        <OverdueInterestField />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        required
        label={getLocalizedMessage('corporate-form.invoicing_email-label')}
        labelHtmlFor="invoice_email"
      >
        <StyledInputContainer
          labelHtmlFor="invoice_email"
          label={getLocalizedMessage('corporate-form.invoicing_email')}
          hint={getLocalizedMessage('corporate-form.invoicing_email_explanation')}
        >
          <InvoicingEmailField />
        </StyledInputContainer>
        <StyledInputContainer>
          <SendInvoiceCopyToEmailsField />
        </StyledInputContainer>
      </StyledFormRow>
      {wfwEnabled && (
        <>
          <StyledFormRow
            horizontalPadding
            label={getLocalizedMessage('corporate-form.invoice-organization')}
          >
            <InvoicingOptionField />
          </StyledFormRow>
          <StyledFormRow
            horizontalPadding
            label={getLocalizedMessage('corporate-form.order-organization')}
          >
            <OrderOptionField />
          </StyledFormRow>
        </>
      )}
      <StyledFormRow
        horizontalPadding
        label={getLocalizedMessage('corporate-form.receipt-email')}
        labelHtmlFor="single_receipt_email"
      >
        <StyledInputContainer
          hint={getLocalizedMessage('corporate-form.receipt-email-explanation')}
        >
          <ReceiptEmailField />
        </StyledInputContainer>
      </StyledFormRow>

      <StyledFormRow
        horizontalPadding
        label={getLocalizedMessage('corporate-form.credit_limit')}
        labelHtmlFor="credit_limit_amount"
        labelAlign="center"
      >
        <CreditLimitField currency={currencyToDisplay} />
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        label={getLocalizedMessage('corporate-form.set-transaction-limit')}
        labelHtmlFor="transaction_limits.single_transaction_amount"
      >
        <StyledInputContainer
          labelHtmlFor="transaction_limits.single_transaction_amount"
          label={getLocalizedMessage('corporate-form.single-transaction-limit')}
          hint={getLocalizedMessage('corporate-form.single-transaction-limit-explanation', {
            amount: getLocalizedNumber(
              selectedCountryConfig?.transaction_limits?.single_transaction_amount ?? 0,
              { currency: currencyToDisplay },
            ),
          })}
        >
          <SingleTransactionLimitField currency={currencyToDisplay} />
        </StyledInputContainer>
        <StyledInputContainer
          labelHtmlFor="transaction_limits.daily_transactions_amount"
          label={getLocalizedMessage('corporate-form.daily-transaction-limit')}
          hint={getLocalizedMessage('corporate-form.daily-transaction-limit-explanation', {
            amount: getLocalizedNumber(
              selectedCountryConfig?.transaction_limits?.daily_transactions_amount ?? 0,
              { currency: currencyToDisplay },
            ),
          })}
        >
          <DailyTransactionLimitField currency={currencyToDisplay} />
        </StyledInputContainer>
      </StyledFormRow>

      {selectedCountryConfig?.is_electronic_invoicing_enabled && (
        <StyledFormRow
          horizontalPadding
          label={getLocalizedMessage('corporate-form.electronic-invoicing-details.label')}
          labelHtmlFor="transaction_limits.single_transaction_amount"
        >
          <StyledInputContainer>
            <IsElectronicInvoicingDetailsEnabledField />
          </StyledInputContainer>
          {is_electronic_invoicing_enabled && (
            <>
              <StyledInputContainer
                labelHtmlFor="electronic_invoicing_details.edi"
                label={getLocalizedMessage('corporate-form.electronic-invoicing-details.edi')}
              >
                <ElectronicInvoicingAddress alwaysShowError />
              </StyledInputContainer>
              <StyledInputContainer
                labelHtmlFor="electronic_invoicing_details.edi_operator"
                label={getLocalizedMessage(
                  'corporate-form.electronic-invoicing-details.edi-operator',
                )}
              >
                <EDIOperatorId alwaysShowError />
              </StyledInputContainer>
              <StyledInputContainer
                labelHtmlFor="electronic_invoicing_details.vat_exemption_disclaimer"
                label={getLocalizedMessage(
                  'corporate-form.electronic-invoicing-details.vat-exemption-disclaimer',
                )}
              >
                <VATExemptionDisclaimer alwaysShowError />
              </StyledInputContainer>
            </>
          )}
        </StyledFormRow>
      )}
    </div>
  );
};
