import { useField } from 'formik';
import { useIntl } from 'react-intl';

import { RadioInputCardGroup } from 'components';

export const InvoicingOptionField = () => {
  const { formatMessage } = useIntl();
  const [organiseInvoiceByGroupsInput, , organiseInvoiceByGroupsHelpers] = useField<boolean>(
    'organise_invoice_by_groups',
  );

  return (
    <RadioInputCardGroup
      name={organiseInvoiceByGroupsInput.name}
      value={String(organiseInvoiceByGroupsInput.value)}
      onChange={(e) => organiseInvoiceByGroupsHelpers.setValue(e.target.value === 'true')}
      direction="row"
      stretched
      options={[
        {
          label: formatMessage({ id: 'corporate-form.normal-invoice-option' }),
          value: 'false',
        },
        {
          label: formatMessage({ id: 'corporate-form.organized-by-groups-invoice-option' }),
          value: 'true',
        },
      ]}
    />
  );
};
