import { ComponentProps } from 'react';

import { FormikInputField } from 'components';
import { useI18n } from 'i18n';
import { createValidator, isNotLongerThan, isRequired } from 'validation';

export const FormikEventNameInput: React.FC<Partial<ComponentProps<typeof FormikInputField>>> = ({
  inputProps,
  ...props
}) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <FormikInputField
      validate={createValidator([isRequired, isNotLongerThan(80)])}
      required={true}
      name="name"
      inputProps={{
        id: 'name',
        placeholder: getLocalizedMessage('events.create-event-form.event-name-placeholder'),
        ...inputProps,
      }}
      {...props}
    />
  );
};
