import React, { useState, useCallback, useEffect, MouseEventHandler, useRef } from 'react';
import styled from 'styled-components';
import {
  Form,
  Formik,
  FormikHelpers,
  FormikTouched,
  FormikValues,
  setNestedObjectValues,
  useFormikContext,
} from 'formik';
import { useHistory } from 'react-router-dom';
import { SmsNotification, SmsNotificationEventEnum } from '@creditornot/wd-api-client';

import { LocalizedMessage, useI18n } from 'i18n';
import { useConfigs } from 'modules/configs';
import { ResponsiveContainer, ApiErrorNotification, EmptyState } from 'components';
import { createCorporate, selfServiceCorporateApiClient } from 'modules/corporates';
import { useCountryConfig } from 'modules/country-configs';
import { CountryConfig } from 'modules/country-configs/types';
import { LoadingState } from 'components/LoadingState';
import { DEFAULT_LANGUAGE_CODE } from 'i18n/LocaleDataProvider';
import { b2bConfigApiClient } from 'modules/b2b-configs';
import { convertCentsToDisplayAmount } from 'utils';
import { CreateCorporateData } from 'modules/corporates/api';

import { CreateCorporateFormValues } from './types';
import {
  GeneralInformationFormSection,
  InvoicingDetailsFormSection,
  DriveDeliverySettingsFormSection,
  DriveInvoicingSettingsFormSection,
  DriveCustomPricingSettingsFormSection,
  NextStepFormFooter,
} from './components';
import {
  mapFormValuesToCreateDriveConfigRequest,
  formatCreateCorporateFormValuesBeforeSending,
} from './utils';

export type CreateCorporateFormProps = {
  selectedCountryCode?: string;
  onClose: () => void;
};

type CreateCorporateFormComponentProps = {
  countryConfig: CountryConfig;
  initialValues: CreateCorporateFormValues;
  onSubmit: (
    values: CreateCorporateFormValues,
    actions: FormikHelpers<CreateCorporateFormValues>,
  ) => Promise<void> | undefined;
  onCountryChange: (country: string) => void;
};

type createCorporateRequest = {
  values: CreateCorporateFormValues;
  currency: string;
  smsNotifications: SmsNotification[];
  fallbackSms: string;
};

type WizardProps<FormValues extends FormikValues> = {
  steps: React.ReactNode[];
  initialValues: FormValues;
  countryConfig: CountryConfig;
  onSubmit: (values: FormValues, actions: FormikHelpers<FormValues>) => Promise<void> | void;
  onChange: (values: FormValues) => void;
};

const Container = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const ApiErrorContainer = styled(ResponsiveContainer)`
  margin-top: 24px;
  margin-bottom: 24px;
  display: block;
`;

const getInitialProducts = (
  countryConfig: CountryConfig | undefined,
): CreateCorporateData['products'] => {
  if (!countryConfig) {
    return [];
  }

  const isWfWEnabled = countryConfig.enabled_products.wolt_for_work;
  const isSubscriptionBenefitEnabled = countryConfig.enabled_products.subscription_benefit;

  if (isWfWEnabled && isSubscriptionBenefitEnabled) {
    return ['WOLT_AT_WORK', 'SUBSCRIPTION_BENEFIT'];
  }

  if (isWfWEnabled) {
    return ['WOLT_AT_WORK'];
  }

  if (isSubscriptionBenefitEnabled) {
    return ['SUBSCRIPTION_BENEFIT'];
  }

  return ['DELIVERY_UI'];
};

const useInitialValues = ({
  countryConfig,
  selectedCountryCode = 'FIN',
}: {
  countryConfig?: CountryConfig;
  selectedCountryCode?: string;
}): CreateCorporateFormValues => {
  const { configs } = useConfigs();
  return {
    address: '',
    base_price: '0',
    billing_twice_a_month: 'false',
    business_id: '',
    city: '',
    contact_email: '',
    contact_name: '',
    contact_phone: '',
    country: selectedCountryCode,
    credit_limit_amount: countryConfig?.default_corporate_credit_limit_amount
      ? convertCentsToDisplayAmount(
          countryConfig.default_corporate_credit_limit_amount,
          countryConfig.currency,
        )
      : '',
    customer_reference: '',
    delivery_distance: 'default',
    delivery_price_type: 'flat_fee',
    discount_type: 'no_discount',
    flat_discount: '',
    percentage_discount: '',
    electronic_invoicing_details: countryConfig?.is_electronic_invoicing_enabled
      ? {
          edi: '',
          edi_operator: '',
          vat_exemption_disclaimer: '',
        }
      : null,
    has_credit_limit: true,
    industry: '',
    invoice_email_cc_recipients: [],
    invoice_email: '',
    invoice_fee_percentage:
      countryConfig?.default_corporate_invoice_fee_percentage?.toString() ?? '0',
    invoice_overdue_interest:
      countryConfig?.default_corporate_invoice_overdue_interest?.toString() ?? '',
    is_electronic_invoicing_enabled: false,
    maximum_distance_in_meters: '0',
    merchant_id: '',
    name: '',
    payment_term_delay_days:
      countryConfig?.default_corporate_payment_term_delay_days?.toString() ?? '',
    post_code: '',
    price_increments: [{ distance_max: '0', distance_min: '0', fee: '0' }],
    preferred_invoice_locale:
      (selectedCountryCode && configs?.utils.getLanguageWithCountryCode(selectedCountryCode)) ||
      'fi',
    single_receipt_email: '',
    vat_id: '',
    has_transaction_limits: false,
    transaction_limits: {
      daily_transactions_amount: '',
      single_transaction_amount: '',
    },
    organise_invoice_by_groups: 'false',
    organise_order_report_by_groups: 'false',
    products: getInitialProducts(countryConfig),
    venue_id: '',
  };
};

const WizardStep = <FormValues extends FormikValues>({
  step,
  stepNumber,
  isLastStep,
  totalSteps,
  previous,
  onChange,
  countryConfig,
}: {
  step: React.ReactNode;
  stepNumber: number;
  isLastStep: boolean;
  totalSteps: number;
  previous: () => void;
  onChange: (values: FormValues) => void;
  countryConfig: CountryConfig;
}) => {
  const { status, isSubmitting, values, validateForm, setTouched, submitForm, setFieldValue } =
    useFormikContext<FormValues>();

  // Reset products if country changes -> dynamic product form values might be rerendered
  useEffect(() => {
    setFieldValue('products', getInitialProducts(countryConfig));
    setFieldValue('organise_invoice_by_groups', false);
    setFieldValue('organise_order_report_by_groups', false);
  }, [countryConfig, setFieldValue]);

  useEffect(() => {
    onChange(values);
  }, [values, onChange]);

  const handleNextClick: MouseEventHandler<HTMLButtonElement> = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      // set touched to show validation errors for all fields
      setTouched(setNestedObjectValues<FormikTouched<FormValues>>(errors, true));
      return;
    }
    submitForm();
  };

  return (
    <Form>
      {step}
      <NextStepFormFooter
        currentStep={stepNumber + 1}
        totalSteps={totalSteps}
        onBackClick={stepNumber > 0 ? () => previous() : undefined}
        onNextClick={handleNextClick}
        isLastStep={isLastStep}
        disabled={isSubmitting || status?.submitSucceeded}
        loading={isSubmitting}
        submitSucceeded={!!status?.submitSucceeded}
      />
      {status?.error && (
        <ApiErrorContainer>
          <ApiErrorNotification error={status.error} />
        </ApiErrorContainer>
      )}
    </Form>
  );
};

const Wizard = <FormValues extends FormikValues>({
  steps,
  initialValues,
  countryConfig,
  onSubmit,
  onChange,
}: WizardProps<FormValues>) => {
  const [snapshot, setSnapshot] = useState(initialValues);

  const [stepNumber, setStepNumber] = useState(0);
  const totalSteps = steps.length;
  const Step = steps[stepNumber];
  const isLastStep = stepNumber === totalSteps - 1;

  const containerRef = useRef<HTMLDivElement | null>(null);

  const next = (values: FormValues) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
    containerRef?.current?.scrollTo(0, 0);
  };

  const previous = () => {
    setStepNumber(Math.max(stepNumber - 1, 0));
    containerRef?.current?.scrollTo(0, 0);
  };

  const handleSubmit = async (values: FormValues, bag: FormikHelpers<FormValues>) => {
    if (isLastStep) {
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      next(values);
    }
  };

  return (
    <Container ref={containerRef}>
      <Formik initialValues={snapshot} onSubmit={handleSubmit}>
        <WizardStep
          step={Step}
          stepNumber={stepNumber}
          isLastStep={isLastStep}
          totalSteps={totalSteps}
          countryConfig={countryConfig}
          previous={previous}
          onChange={onChange}
        />
      </Formik>
    </Container>
  );
};

const CreateCorporateFormComponent = ({
  countryConfig,
  initialValues,
  onSubmit,
  onCountryChange,
}: CreateCorporateFormComponentProps) => {
  const [products, setProducts] = useState<CreateCorporateFormValues['products']>(
    initialValues.products,
  );
  const [deliveryPriceType, setDeliveryType] = useState(initialValues.delivery_price_type);

  const handleChange = useCallback((values: CreateCorporateFormValues) => {
    setProducts(values.products);
    setDeliveryType(values.delivery_price_type);
  }, []);

  return (
    <>
      <Wizard
        initialValues={initialValues}
        countryConfig={countryConfig}
        onSubmit={onSubmit}
        onChange={handleChange}
        steps={[
          <GeneralInformationFormSection
            key="general-info"
            countryConfig={countryConfig}
            onCountryChange={onCountryChange}
          />,
          (products.includes('WOLT_AT_WORK') || products.includes('SUBSCRIPTION_BENEFIT')) && (
            <InvoicingDetailsFormSection
              currency={countryConfig.currency}
              key="invoicing-details"
            />
          ),
          products.includes('DELIVERY_UI') && (
            <DriveDeliverySettingsFormSection
              currency={countryConfig.currency}
              key="drive-delivery-settings"
            />
          ),
          products.includes('DELIVERY_UI') && deliveryPriceType === 'custom' && (
            <DriveCustomPricingSettingsFormSection
              currency={countryConfig.currency}
              key="drive-custom-pricing-settings"
            />
          ),
          products.includes('DELIVERY_UI') && (
            <DriveInvoicingSettingsFormSection
              countryConfig={countryConfig}
              key="drive-invoicing-settings"
            />
          ),
        ].filter((step): step is React.ReactElement => !!step)}
      />
    </>
  );
};

const requestCreateCorporate = async ({
  values,
  currency,
  smsNotifications,
  fallbackSms,
}: createCorporateRequest) => {
  const corporate = await createCorporate({
    currency,
    ...formatCreateCorporateFormValuesBeforeSending(values, currency),
  });

  if (values.products.includes('DELIVERY_UI')) {
    const b2bPayload = mapFormValuesToCreateDriveConfigRequest({
      corporateId: corporate.id,
      values: values,
      smsNotificationCopy:
        smsNotifications.find((sms) => sms.event === SmsNotificationEventEnum.Received)
          ?.messageText || fallbackSms,
      currency,
    });

    const driveRequests = await Promise.all([
      selfServiceCorporateApiClient.createCorporate({
        body: {
          email: '',
          name: corporate.name,
          phoneNumber: '',
          smsNotifications,
          url: '',
          woltCorporateId: corporate.id,
        },
      }),
      b2bConfigApiClient.corporateAddB2bConfig({
        corporateId: corporate.id,
        b2bCustomerConfigCreateRequest: b2bPayload,
      }),
    ]);
    return { corporate, driveRequests };
  }

  return { corporate };
};

export const CreateCorporateForm = ({ onClose, selectedCountryCode }: CreateCorporateFormProps) => {
  const { getLocalizedMessageFromCustomLocale, getLocalizedMessage } = useI18n();
  const history = useHistory();
  const { configs } = useConfigs();
  const [country, setCountry] = useState(selectedCountryCode);

  const { data: countryConfig, isLoading: isLoadingCountryConfig } = useCountryConfig(country);
  const initialValues = useInitialValues({ countryConfig, selectedCountryCode });

  const handleSubmit = useCallback(
    async (
      values: CreateCorporateFormValues,
      actions: FormikHelpers<CreateCorporateFormValues>,
    ) => {
      if (!configs) {
        actions.setSubmitting(false);
        actions.setStatus({ error: 'Configs must be provided' });
        return;
      }
      const currency = configs.utils.getCurrencyWithCountryCode(values.country);
      const languageCode =
        configs.utils.getLanguageWithCountryCode(values.country) ?? DEFAULT_LANGUAGE_CODE;
      const smsNotifications: SmsNotification[] = [
        {
          event: SmsNotificationEventEnum.PickedUp,
          messageText: await getLocalizedMessageFromCustomLocale(
            languageCode,
            'deliveries.default-sms-notification-pickup-message',
            {
              companyName: values.name,
            },
          ),
        },
        {
          event: SmsNotificationEventEnum.Received,

          messageText: await getLocalizedMessageFromCustomLocale(
            languageCode,
            'deliveries.default-sms-notification-confirmation-message',
            {
              companyName: values.name,
            },
          ),
        },
      ];
      if (!currency) {
        actions.setStatus({
          error:
            'Currency for the company was not able to be determined based on the country selected',
        });
        return;
      }

      return requestCreateCorporate({
        values: values,
        currency: currency,
        smsNotifications: smsNotifications,
        fallbackSms: getLocalizedMessage(
          'deliveries.default-sms-notification-confirmation-message',
        ),
      })
        .then(({ corporate }) => {
          actions.setSubmitting(false);
          actions.setStatus({ submitSucceeded: true });
          setTimeout(() => {
            onClose();
            history.push(`${corporate.id}/home`);
          }, 500);
        })
        .catch((error) => {
          actions.setStatus({ error });
          actions.setSubmitting(false);
        });
    },
    [configs, getLocalizedMessageFromCustomLocale, onClose, history, getLocalizedMessage],
  );
  const gotoCountryConfiguration = useCallback(() => {
    history.push(`/internal/country-configs?country=${selectedCountryCode}`);
  }, [history, selectedCountryCode]);

  if (isLoadingCountryConfig) {
    return (
      <LoadingState>
        <LocalizedMessage messageKey="common.loading" />
      </LoadingState>
    );
  }

  if (!countryConfig) {
    return (
      <EmptyState
        icon="policy"
        actionButtonText="Configure country"
        onActionButtonClick={gotoCountryConfiguration}
      >
        {`${configs?.utils.getNameWithCountryCode(
          selectedCountryCode || '',
        )} configs is missing or unconfigured`}
      </EmptyState>
    );
  }

  return (
    <CreateCorporateFormComponent
      countryConfig={countryConfig}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onCountryChange={setCountry}
    />
  );
};
