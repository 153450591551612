import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { InfoCircleFilled } from '@creditornot/cb-icons';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { colors, typographyCss } from '@creditornot/cb-ingredients';
import type { User } from '@creditornot/wolt-auth';
import { FormattedMessage, useIntl } from 'react-intl';

import { View, Notification, ResourcesColumns } from 'components';
import { LocalizedNumber } from 'i18n';
import { homeView } from 'telemetry/Avo';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { PurchaseSummary, Summary } from 'modules/summary/types';
import { useMedia } from 'modules/media';
import { Corporate } from 'modules/corporates/types';

import { Columns } from '../Columns';
import { InformationItem } from '../InformationItem';
import { SubscriptionAdBanner } from './SubscriptionAdBanner/SubscriptionAdBanner';

const StyledNotification = styled(Notification)`
  margin-top: 8px;
`;

const TopContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledInfoCircleFilled = styled(InfoCircleFilled)`
  height: 14px;
  width: 14px;
`;

const StyledFormattedMessage = styled(FormattedMessage)`
  display: inline-block;
`;

const PopoverContent = styled.div`
  padding: 8px;
  max-width: 320px;
`;

const StyledPopover = styled(Popover)`
  display: flex;
  align-items: center;
`;

const ColoredHeading = styled.h1<{
  variant: 'black' | 'gray' | 'blue' | 'green';
}>`
  ${typographyCss.Heading1()}
  color: ${({ variant }) =>
    ({
      black: color.text,
      gray: color.textSubdued,
      blue: colors.wolt100,
      green: colors.lime100,
    })[variant]};
`;

type Props = {
  user: User | undefined;
  corporate: Corporate;
  summaryData: Summary;
  purchaseStatistics: PurchaseSummary | undefined;
  onOpenOrderTutorialVideoClick: () => void;
  onOnboardUsersTutorialVideoClick: () => void;
  onEventTutorialVideoClick: () => void;
  onOrderGuideClick: () => void;
  onEventGuideClick: () => void;
  onMultipaymentGuideClick?: () => void;
  onSubscriptionDismissClick: (() => void) | undefined;
  onSubscriptionLearnMoreClick: (() => void) | undefined;
  showSubscriptionAdBanner: boolean;
  displayDate: string;
};

export const HomeViewComponent = ({
  user,
  corporate,
  summaryData,
  purchaseStatistics,
  onEventTutorialVideoClick,
  onOnboardUsersTutorialVideoClick,
  onOpenOrderTutorialVideoClick,
  onOrderGuideClick,
  onEventGuideClick,
  onMultipaymentGuideClick,
  onSubscriptionDismissClick,
  onSubscriptionLearnMoreClick,
  showSubscriptionAdBanner,
  displayDate,
}: Props) => {
  const { formatMessage } = useIntl();
  const isSmallView = useMedia('small');
  const isMediumView = useMedia('medium');
  const numberOfColumns = isSmallView ? 1 : isMediumView ? 2 : 4;

  return (
    <>
      <ViewEventComponent event={homeView} />
      {showSubscriptionAdBanner && onSubscriptionDismissClick && onSubscriptionLearnMoreClick && (
        <SubscriptionAdBanner
          onDismiss={onSubscriptionDismissClick}
          onLearnMoreClick={onSubscriptionLearnMoreClick}
        />
      )}
      <View
        title={
          user?.name && (
            <div data-test-id="home-dashboard-title">
              {formatMessage({ id: 'views.dashboard.title' }, { name: user.name.first_name })}
            </div>
          )
        }
        subtitle={<FormattedMessage id="views.dashboard.subtitle" />}
      >
        <Columns columns={numberOfColumns}>
          <InformationItem
            topContent={<FormattedMessage id="views.dashboard.active-users" />}
            content={
              <ColoredHeading variant="green">{summaryData.active_users_amount}</ColoredHeading>
            }
          />
          <InformationItem
            topContent={<FormattedMessage id="views.dashboard.pending-invites" />}
            content={
              <div>
                <ColoredHeading variant="blue">{summaryData.pending_invites_amount}</ColoredHeading>
                {summaryData.expiring_invites_amount > 0 && (
                  <StyledNotification variant="warning">
                    <FormattedMessage
                      id="views.dashboard.total-expired-invites"
                      values={{ number: summaryData.expiring_invites_amount }}
                    />
                  </StyledNotification>
                )}
              </div>
            }
          />
          <InformationItem
            topContent={<FormattedMessage id="views.dashboard.numbers-of-groups" />}
            content={<ColoredHeading variant="black">{summaryData.groups_amount}</ColoredHeading>}
          />
          <InformationItem
            topContent={<FormattedMessage id="views.dashboard.numbers-of-policies" />}
            content={<ColoredHeading variant="black">{summaryData.policies_amount}</ColoredHeading>}
          />
        </Columns>

        {purchaseStatistics && (
          <Columns
            title={<FormattedMessage id="views.dashboard.company-usage" />}
            subtitle={displayDate}
            columns={isSmallView ? 1 : 2}
          >
            <InformationItem
              topContent={
                <TopContent>
                  <StyledFormattedMessage id="views.dashboard.purchase-counts-title" />
                  <StyledPopover
                    showOnHover={true}
                    placement="top"
                    content={
                      <PopoverContent>
                        <FormattedMessage id="views.dashboard.purchase-counts-description" />
                      </PopoverContent>
                    }
                  >
                    <StyledInfoCircleFilled />
                  </StyledPopover>
                </TopContent>
              }
              content={
                <ColoredHeading variant="black">{purchaseStatistics.purchase_count}</ColoredHeading>
              }
            />
            <InformationItem
              topContent={
                <TopContent>
                  <StyledFormattedMessage
                    id="views.dashboard.purchase-amount-title"
                    values={{
                      currency: corporate.currency,
                    }}
                  />
                  <StyledPopover
                    showOnHover={true}
                    placement="top"
                    content={
                      <PopoverContent>
                        <FormattedMessage id="views.dashboard.purchase-amount-description" />
                      </PopoverContent>
                    }
                  >
                    <StyledInfoCircleFilled />
                  </StyledPopover>
                </TopContent>
              }
              content={
                <ColoredHeading variant="black">
                  <LocalizedNumber
                    value={purchaseStatistics.purchase_total_amount}
                    currency={corporate.currency}
                    currencyDisplay="none"
                  />
                </ColoredHeading>
              }
            />
          </Columns>
        )}

        <ResourcesColumns
          onEventTutorialVideoClick={onEventTutorialVideoClick}
          onOnboardUsersTutorialVideoClick={onOnboardUsersTutorialVideoClick}
          onOrderTutorialVideoClick={onOpenOrderTutorialVideoClick}
          onOrderGuideClick={onOrderGuideClick}
          onEventGuideClick={onEventGuideClick}
          onMultipaymentGuideClick={onMultipaymentGuideClick}
        />
      </View>
    </>
  );
};
