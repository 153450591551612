import { isEqual } from 'lodash';

export function shallowObjectDifferences<
  T extends Record<string, any>,
  U extends Record<string, any>,
>(obj1: T, obj2: U): Partial<T> {
  const obj1Keys = Object.keys(obj1);

  return obj1Keys.reduce((acc, key) => {
    if (typeof obj1[key] === 'object') {
      return !isEqual(obj1[key], obj2[key])
        ? {
            ...acc,
            [key]: obj1[key],
          }
        : acc;
    }

    if (obj1[key] !== obj2[key]) {
      return {
        ...acc,
        [key]: obj1[key],
      };
    }

    return acc;
  }, {});
}
