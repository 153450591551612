import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ConfirmationModal } from 'components';
import { useResendSubscriptionInvites } from 'modules/subscription/useResendSubscriptionInvites';
import { UI_SUCCESS_DELAY } from 'modules/common/constants';

import { useSubscriptionUsersQueryParams } from '../hooks/useSubscriptionUsersQueryParams';

interface Props {
  show: boolean;
  subscriptionId: string;
  onResendInvitesSuccess: () => void;
  onClose: () => void;
}

export const ResendSubscriptionInvitesModal = ({
  show,
  subscriptionId,
  onResendInvitesSuccess,
  onClose,
}: Props) => {
  const { formatMessage } = useIntl();
  const resendInvites = useResendSubscriptionInvites();

  const [{ invites: invitesQueryParams, invite: inviteQueryParam }] =
    useSubscriptionUsersQueryParams();

  const inviteIds = useMemo(
    () => (inviteQueryParam ? [inviteQueryParam] : invitesQueryParams),
    [inviteQueryParam, invitesQueryParams],
  );

  const handleConfirm = useCallback(async () => {
    if (!inviteIds) {
      return Promise.reject('Invite ids are missing');
    }

    await resendInvites({ subscriptionId, inviteIds, invalidateDelay: UI_SUCCESS_DELAY });
  }, [inviteIds, resendInvites, subscriptionId]);

  const numberOfUsers = invitesQueryParams?.length || 1;

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      onConfirm={handleConfirm}
      title={formatMessage(
        { id: 'wolt-plus.resend-invites-modal.title' },
        { count: numberOfUsers },
      )}
      content={formatMessage(
        { id: 'wolt-plus.resend-invites-modal.text' },
        { count: numberOfUsers },
      )}
      confirmationButtonMessage={formatMessage({ id: 'common.resend' })}
      confirmationButtonVariant="blue"
      onSuccess={() => setTimeout(onResendInvitesSuccess, UI_SUCCESS_DELAY)}
    />
  );
};
