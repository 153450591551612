import { useCallback } from 'react';
import styled from 'styled-components';

import { useAppModals } from 'modules/app-modals';
import { LocalizedMessage, useI18n } from 'i18n';
import { View } from 'components';

import { CreatePolicyForm } from './components';
import { Policy } from './types';

const Root = styled(View)`
  width: 100%;
  max-width: 783px;
  margin: auto;
  padding-bottom: 24px;
`;

const CREATE_POLICY_MODAL = 'CREATE_POLICY_MODAL';

export const useCreatePolicyInModal = () => {
  const { openModal, closeModal } = useAppModals();
  const { getLocalizedMessage } = useI18n();

  return useCallback(
    () =>
      new Promise<Policy>((resolve, reject) => {
        openModal(CREATE_POLICY_MODAL, {
          children: (
            <Root
              onBackClick={() => {
                closeModal(CREATE_POLICY_MODAL);
                reject();
              }}
              title={<LocalizedMessage messageKey="policies.create-policy-title" />}
              headerStyle={{ paddingBottom: 0 }}
              hideHeaderBorder
            >
              <CreatePolicyForm
                hideGroupSelect
                onSubmitSuccess={(policy) => {
                  resolve(policy);
                  closeModal(CREATE_POLICY_MODAL);
                }}
                onClose={() => {
                  closeModal(CREATE_POLICY_MODAL);
                  reject();
                }}
                secondaryButtonText={getLocalizedMessage('common.cancel')}
                onSecondaryButtonClick={() => {
                  closeModal(CREATE_POLICY_MODAL);
                  reject();
                }}
              />
            </Root>
          ),
          // 1 over modal in ModalV2
          sizePreset: 'fullScreen',
        });
      }),
    [closeModal, getLocalizedMessage, openModal],
  );
};
