import { InputContainer } from '@creditornot/cb-form';

import { FormikTagInputField } from 'components';
import { useI18n } from 'i18n';
import {
  createValidator,
  isRequired,
  isEmail,
  doesNotContainDuplicateEmails,
  isAListOfValidEmails,
  isDuplicateEmail,
} from 'validation';

interface Props {
  className?: string;
}

const validateEmails = createValidator([
  isRequired,
  doesNotContainDuplicateEmails,
  isAListOfValidEmails,
]);

export const TagInputInviteUserForm: React.FC<Props> = ({ className }) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <InputContainer
      label={getLocalizedMessage('users.new-user-modal.invite-users-textarea-label')}
      labelHtmlFor="emailInput"
      className={className}
    >
      <FormikTagInputField
        name="emails"
        placeholder="email1@example.com, email2@example.com, email3@example.com"
        validate={validateEmails}
        validateTag={(tag, index, tags) => isEmail(tag) || isDuplicateEmail(tag, index, tags)}
        separateValuesBy={/[\s|,|;|:]/}
        setTagValueByKeyCodes={[13, 32, 44, 58, 59]}
        tagInputProp={{
          id: 'emailInput',
          styles: { minHeight: '134px' },
        }}
      />
    </InputContainer>
  );
};
