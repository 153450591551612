import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Tabs } from '@creditornot/cb-tabs';

import { useI18n } from 'i18n';
import { useCorporate, useCorporateV2 } from 'modules/corporates';
import { View } from 'components';

import { DeliverySettingsView } from './DeliverySettings';
import { CompanySettingsV2 } from './CompanySettingsV2/CompanySettingsV2';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline: 8px;
  flex-basis: 720px;
  max-width: 820px;
  margin-inline: auto;
`;

const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid ${color.border};
  margin-bottom: 32px;
`;

export const SettingsView = ({ match }: RouteComponentProps<{ corporateId: string }>) => {
  const history = useHistory();
  const { getLocalizedMessage } = useI18n();
  const { data: corporate } = useCorporate();
  const { data: corporateV2 } = useCorporateV2();

  const activeTab = match.path.includes('/delivery-settings')
    ? 'delivery-settings'
    : 'company-settings';
  const handleTabClick = useCallback(
    (event: string) => {
      if (event === 'delivery-settings') {
        history.push(`/${match.params.corporateId}/settings/delivery-settings`);
      } else {
        history.push(`/${match.params.corporateId}/settings`);
      }
    },
    [history, match.params.corporateId],
  );

  if (!corporate) {
    return null;
  }

  return (
    <View
      title={
        corporate.products.daas_delivery_ui_enabled
          ? getLocalizedMessage('views.settings')
          : getLocalizedMessage('views.company-settings')
      }
    >
      {corporate.products.daas_delivery_ui_enabled && (
        <StyledTabs
          activeTabId={activeTab}
          onChange={handleTabClick}
          items={[
            {
              id: 'company-settings',
              label: getLocalizedMessage('views.settings.company-settings'),
            },
            {
              id: 'delivery-settings',
              label: getLocalizedMessage('views.settings.delivery-settings'),
            },
          ]}
        />
      )}
      <Container>
        {activeTab === 'company-settings' && (
          <CompanySettingsV2 corporate={corporate} corporateV2={corporateV2} />
        )}
        {activeTab === 'delivery-settings' && <DeliverySettingsView corporate={corporate} />}
      </Container>
    </View>
  );
};
