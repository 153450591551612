import styled from 'styled-components';
import { MultiSelect } from '@creditornot/cb-select';
import { useCallback } from 'react';

import { useI18n } from 'i18n';
import { Divider } from 'components';
import { breakpoints } from 'modules/media';

const PRODUCT_LINE_VALUES = ['woltAtWork', 'deliveryUi'] as const;
const CORPORATE_STATUS_VALUES = [
  'submitted',
  'invitePending',
  'detailsPending',
  'verificationPending',
  'contractPending',
  'active',
  'disabled',
] as const;

// optimally these types would be used for input query params but
// currently that requires a lot of type casting
type CorporateStatus = (typeof CORPORATE_STATUS_VALUES)[number];
type ProductLine = (typeof PRODUCT_LINE_VALUES)[number];

interface Props {
  statusQueryParam?: string[];
  productLineQueryParam?: string[];
  onCorporateStatusChange: (option: CorporateStatus[]) => void;
  onProductLineChange: (option: ProductLine[]) => void;
}

const StyledMultiSelect = styled(MultiSelect)`
  width: 240px;

  @media (max-width: ${breakpoints.medium}px) {
    width: unset;
  }
`;

export const CorporateFilter = ({
  statusQueryParam = [],
  productLineQueryParam = [],
  onCorporateStatusChange,
  onProductLineChange,
}: Props) => {
  const { getLocalizedMessage } = useI18n();

  const onChange = useCallback(
    (filters: string[]) => {
      const productLineFilters = PRODUCT_LINE_VALUES.filter((value) => filters.includes(value));
      const corporateStatusFilters = CORPORATE_STATUS_VALUES.filter((value) =>
        filters.includes(value),
      );

      onProductLineChange(productLineFilters as ProductLine[]);
      onCorporateStatusChange(corporateStatusFilters as CorporateStatus[]);
    },
    [onCorporateStatusChange, onProductLineChange],
  );

  return (
    <StyledMultiSelect
      placeholder={getLocalizedMessage('corporates.status.filter-placeholder')}
      options={[
        {
          label: getLocalizedMessage('corporates.status.filter-title'),
          options: [
            {
              label: getLocalizedMessage('corporates.status.submitted'),
              value: 'submitted',
            },
            {
              label: getLocalizedMessage('corporates.status.invite-pending'),
              value: 'invitePending',
            },
            {
              label: getLocalizedMessage('corporates.status.details-pending'),
              value: 'detailsPending',
            },
            {
              label: getLocalizedMessage('corporates.status.verification-pending'),
              value: 'verificationPending',
            },
            {
              label: getLocalizedMessage('corporates.status.contract-pending'),
              value: 'contractPending',
            },
            {
              label: getLocalizedMessage('corporates.status.active'),
              value: 'active',
            },
          ],
        },
        {
          label: getLocalizedMessage('corporates.product-line'),
          options: [
            {
              label: getLocalizedMessage('corporates.product-line.wolt-at-work'),
              value: 'woltAtWork',
            },
            {
              label: getLocalizedMessage('corporates.product-line.wolt-drive'),
              value: 'deliveryUi',
            },
          ],
        },
        {
          label: <Divider />,
          options: [
            {
              label: getLocalizedMessage('corporates.status.disabled'),
              value: 'disabled',
            },
          ],
        },
      ]}
      value={[...statusQueryParam, ...productLineQueryParam]}
      onChange={onChange}
    />
  );
};
