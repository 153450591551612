import { FormattedTime } from 'react-intl';

interface LocalizedTimeProps {
  value: Date;
  timeZone?: string;
  hour12?: boolean;
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
  second?: 'numeric' | '2-digit';
  timeZoneName?: 'long' | 'short';
}

/**
 *
 * @deprecated Use FormattedTime from react-intl instead
 */
const LocalizedTime = ({
  value,
  timeZone,
  hour12,
  hour,
  minute,
  second,
  timeZoneName,
}: LocalizedTimeProps) => {
  return (
    <FormattedTime
      value={value}
      timeZone={timeZone}
      hour12={hour12}
      hour={hour}
      minute={minute}
      second={second}
      timeZoneName={timeZoneName}
    />
  );
};

export default LocalizedTime;
