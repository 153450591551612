import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ModalV2 } from 'components';
import { Group } from 'modules/groups/types';
import { useAppModals } from 'modules/app-modals';

import { ChangeGroupPolicyForm } from './ChangeGroupPolicyForm';

interface Props {
  group: Group | null | undefined;
  onClose: () => void;
  onSuccess: () => void;
  show: boolean;
}

const ContentComponent = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ChangeGroupPolicyModal: React.FC<Props> = ({ onClose, onSuccess, show, group }) => {
  const hasPolicy = !!group?.policies[0];
  const { formatMessage } = useIntl();
  const { hasOpenModal } = useAppModals();

  const title = hasPolicy
    ? formatMessage({ id: 'groups.change-group-policy' })
    : formatMessage({ id: 'groups.add-group-policy' });

  return (
    <ModalV2
      onDismiss={onClose}
      open={show}
      title={title}
      sizePreset={{
        desktop: 'centered:large',
        mobile: 'fullScreen',
      }}
      // if create policy screen is open, don't close this modal on outside click
      disableDismissOnOutsidePress={hasOpenModal}
      ContentComponent={({ children }) => {
        return <ContentComponent>{children}</ContentComponent>;
      }}
    >
      {group && <ChangeGroupPolicyForm group={group} onSuccess={onSuccess} onClose={onClose} />}
    </ModalV2>
  );
};
