import { useCallback, useState } from 'react';

import { useInviteSubscriptionMembers } from 'modules/subscription';
import { useSubscriptionModalsController } from 'views/subscription/hooks/useSubscriptionModalsController';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';
import {
  InviteMethod,
  SubscriptionInviteMembersDataInviteMembersProperties,
  subscriptionInviteMembersDataInviteMembers,
} from 'telemetry/Avo';
import { UI_SUCCESS_DELAY } from 'modules/common/constants';

import { AddUsersFormComponent, FormType } from './AddUsersFormComponent';

interface Props {
  onClose: () => void;
  corporateId: string;
  subscriptionId: string;
  isSubscriptionOnlyCorporate: boolean;
}

const getTelemetryEventType = (
  formType: FormType,
): SubscriptionInviteMembersDataInviteMembersProperties => {
  switch (formType) {
    case 'ENTER_USER_EMAILS':
      return { inviteMethod: InviteMethod.MANUAL_ENTRY };
    case 'IMPORT_USER_EMAILS':
      return { inviteMethod: InviteMethod.CSV_IMPORT };
    case 'INVITE_EXISTING_USERS':
      return { inviteMethod: InviteMethod.ADD_EXISTING_MEMBERS };
  }
};

export const AddUsersForm = ({
  onClose,
  corporateId,
  subscriptionId,
  isSubscriptionOnlyCorporate,
}: Props) => {
  const inviteMembers = useInviteSubscriptionMembers();

  const defaultFormType = isSubscriptionOnlyCorporate
    ? 'ENTER_USER_EMAILS'
    : 'INVITE_EXISTING_USERS';

  const [formType, setFormType] = useState<FormType>(defaultFormType);

  const { closeModal } = useSubscriptionModalsController();

  const handleSubmitSuccess = useCallback(() => {
    setTimeout(() => {
      closeModal();
    }, UI_SUCCESS_DELAY);
  }, [closeModal]);

  const subscriptionInviteMembersDataInviteMembersEvent = useTelemetryInteractionEvent(
    subscriptionInviteMembersDataInviteMembers,
  );

  const handleSubmit = useCallback(
    async (values: { emails: string[]; userIds: string[] }) => {
      subscriptionInviteMembersDataInviteMembersEvent(getTelemetryEventType(formType));
      if (formType === 'INVITE_EXISTING_USERS') {
        const userIds = values.userIds as Array<string>;
        await inviteMembers({
          subscriptionId,
          userIds,
          onSuccess: handleSubmitSuccess,
          invalidateDelay: UI_SUCCESS_DELAY,
        });
      } else {
        const emails = values.emails as Array<string>;
        await inviteMembers({
          subscriptionId,
          emails,
          onSuccess: handleSubmitSuccess,
          invalidateDelay: UI_SUCCESS_DELAY,
        });
      }
    },
    [
      formType,
      handleSubmitSuccess,
      inviteMembers,
      subscriptionId,
      subscriptionInviteMembersDataInviteMembersEvent,
    ],
  );

  return (
    <AddUsersFormComponent
      onClose={onClose}
      corporateId={corporateId}
      subscriptionId={subscriptionId}
      isSubscriptionOnlyCorporate={isSubscriptionOnlyCorporate}
      formType={formType}
      onFormTypeChange={setFormType}
      onSubmit={handleSubmit}
    />
  );
};
