import { FormikInputField } from 'components';
import { FormikInputFieldProps } from 'components/FormikInputField';
import { createValidator, isEmail } from 'validation';

const validate = createValidator([isEmail]);

interface Props extends Omit<FormikInputFieldProps, 'name' | 'type' | 'validate'> {
  alwaysShowError?: boolean;
}

export const ReceiptEmailField = ({ alwaysShowError, ...rest }: Props) => {
  return (
    <FormikInputField
      name="single_receipt_email"
      type="email"
      validate={validate}
      alwaysShowError={alwaysShowError}
      {...rest}
    />
  );
};
