import { MenuActionItem } from '@creditornot/cb-menu';
import { Select, SelectOptionComponentProps } from '@creditornot/cb-select';
import { isNil } from 'ramda';
import { useField } from 'formik';
import { useMemo } from 'react';

import { useConfigs } from 'modules/configs';
import { useGetLanguageName } from 'utils';

const CustomOption: React.FC<
  SelectOptionComponentProps<{
    label: string;
    value: string;
    selected: boolean;
    icon: string;
  }>
> = ({ option, onClick }) => {
  return (
    <MenuActionItem
      icon={<>{option.icon}</>}
      variant="black"
      selected={option.selected}
      onClick={onClick}
    >
      {option.label}
    </MenuActionItem>
  );
};

export const PreferredInvoiceLanguageField = () => {
  const { configs } = useConfigs();
  const [{ value }, , { setValue }] = useField<string>('preferred_invoice_locale');
  const getLanguageName = useGetLanguageName();

  const options = useMemo(() => {
    if (!configs) return;

    return [
      {
        label: getLanguageName('en'),
        value: 'en',
        selected: value === 'en',
        icon: '🇬🇧',
      },
      ...configs.countries.map((country) => ({
        label: getLanguageName(country.language),
        value: country.language,
        icon: country.flag,
        selected: value === country.language,
      })),
    ];
  }, [configs, getLanguageName, value]);

  return (
    <Select
      value={value}
      onChange={(value) => !isNil(value) && setValue(value)}
      options={options}
      optionComponent={CustomOption}
    />
  );
};
