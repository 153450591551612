import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQueryClient } from 'modules/react-query';

import { acceptSubscriptionTerms } from './api';

type Props = {
  content: string;
  subscriptionId: string;
  invalidateDelay?: number;
};

export const useAcceptSubscriptionTerms = () => {
  const queryClient = useQueryClient();
  const { data: corporate } = useCorporate();

  return useCallback(
    async ({ content, subscriptionId, invalidateDelay }: Props) => {
      if (!corporate?.id) {
        return Promise.resolve();
      }
      await acceptSubscriptionTerms(corporate.id, subscriptionId, content);

      setTimeout(() => {
        queryClient.invalidateQueries();
      }, invalidateDelay ?? 0);
    },
    [corporate?.id, queryClient],
  );
};
