import { useCallback } from 'react';

import { PaginatedData } from 'types/PaginatedData';
import { useQuery, UseQueryOptions } from 'modules/react-query';
import { isEmptyObject } from 'utils';

import { FetchCorporatesDataWithPagination, fetchCorporatesWithPagination } from './api';
import { Corporate } from './types';

export const useFetchCorporatesWithPagination = <T = PaginatedData<Corporate>>(
  data: FetchCorporatesDataWithPagination,
  config?: UseQueryOptions<PaginatedData<Corporate>, unknown, T>,
) => {
  const disabled = isEmptyObject(data);
  const queryFn = useCallback(() => {
    if (disabled) {
      return Promise.reject();
    }
    const { page = 1, page_size = 20, name, country_code, statuses, products } = data;
    return fetchCorporatesWithPagination({
      page,
      page_size,
      name,
      country_code,
      statuses,
      products,
    });
  }, [data, disabled]);
  return useQuery({ queryKey: ['corporates', data], queryFn, enabled: !disabled, ...config });
};
