import { useCallback, useState } from 'react';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { env } from 'config';
import { useI18n } from 'i18n';
import { useCorporate } from 'modules/corporates';
import { useOpenEventTutorialVideo } from 'modules/events';
import { event } from 'modules/google-analytics';
import { resources } from 'modules/resources';

import { getCurrentMonthDateRange } from './utils';
import { useOpenOrderTutorialVideo, useOpenOnboardUsersTutorialVideo } from './hooks';
import { HomeViewComponent } from './components/HomeViewComponent';

const useDisplayDate = (startDate: Date, endDate: Date) => {
  const { getLocalizedDate } = useI18n();

  const formattedStartDate = getLocalizedDate(startDate, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  const formattedEndDate = getLocalizedDate(endDate, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  return formattedStartDate === formattedEndDate
    ? formattedStartDate
    : `${formattedStartDate} - ${formattedEndDate}`;
};

const useReceivedDates = () => {
  const [dates] = useState(getCurrentMonthDateRange());
  return dates;
};

export const HomeSignupV2 = () => {
  const { user } = useAuthState();

  const openOrderTutorialVideo = useOpenOrderTutorialVideo();
  const openEventTutorialVideo = useOpenEventTutorialVideo();
  const openOnboardUsersTutorialVideo = useOpenOnboardUsersTutorialVideo();
  const { data: corporate } = useCorporate();
  const { startDate, endDate } = useReceivedDates();

  const multipaymentCountryList = env.MULTIPAYMENT_COUNTRIES?.split(',');
  const shouldShowMultipaymentResource =
    !!corporate &&
    !corporate.products.daas_delivery_ui_enabled &&
    multipaymentCountryList?.includes(corporate.country);

  const handleOpenOrderTutorialVideoClick = useCallback(() => {
    openOrderTutorialVideo();
    event({
      category: 'dashboard',
      action: 'click',
      label: 'order_tutorial',
    });
  }, [openOrderTutorialVideo]);
  const handleOnboardUsersTutorialVideoClick = useCallback(() => {
    openOnboardUsersTutorialVideo();
    event({
      category: 'dashboard',
      action: 'click',
      label: 'accepting_company_invite_tutorial',
    });
  }, [openOnboardUsersTutorialVideo]);
  const handleEventTutorialVideoClick = useCallback(() => {
    openEventTutorialVideo();
    event({
      category: 'dashboard',
      action: 'click',
      label: 'company_event_tutorial',
    });
  }, [openEventTutorialVideo]);
  const handleOrderGuideClick = useCallback(() => {
    window.open(resources.guides.groupOrder);
    event({
      category: 'dashboard',
      action: 'click',
      label: 'order_guide',
    });
  }, []);
  const handleEventGuideClick = useCallback(() => {
    window.open(resources.guides.events);
    event({
      category: 'dashboard',
      action: 'click',
      label: 'company_event_guide',
    });
  }, []);
  const handleMultipaymentGuideClick = useCallback(() => {
    window.open(resources.guides.multipayments);
    event({
      category: 'dashboard',
      action: 'click',
      label: 'multipayment_guide',
    });
  }, []);
  const displayDate = useDisplayDate(startDate, endDate);

  if (!corporate) {
    return null;
  }

  return (
    <HomeViewComponent
      user={user}
      corporate={corporate}
      purchaseStatistics={undefined}
      summaryData={{
        active_users_amount: 1,
        expiring_invites_amount: 0,
        groups_amount: 0,
        pending_invites_amount: 0,
        policies_amount: 0,
      }}
      onOpenOrderTutorialVideoClick={handleOpenOrderTutorialVideoClick}
      onOnboardUsersTutorialVideoClick={handleOnboardUsersTutorialVideoClick}
      onEventTutorialVideoClick={handleEventTutorialVideoClick}
      onOrderGuideClick={handleOrderGuideClick}
      onEventGuideClick={handleEventGuideClick}
      onMultipaymentGuideClick={
        shouldShowMultipaymentResource ? handleMultipaymentGuideClick : undefined
      }
      onSubscriptionDismissClick={undefined}
      onSubscriptionLearnMoreClick={undefined}
      showSubscriptionAdBanner={false}
      displayDate={displayDate}
    />
  );
};
