import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { IconButton } from '@creditornot/cb-button';
import { useCallback } from 'react';
import { Empty } from '@creditornot/cb-placeholders';
import { MoreHorizontal } from '@creditornot/cb-icons';
import { colors } from '@creditornot/cb-ingredients';
import { FormattedMessage, useIntl } from 'react-intl';

import { env } from 'config';
import { DataList, Anchor, UserStatus } from 'components';
import DropdownActionButton from 'components/DropdownButton';
import { UserV2 } from 'modules/users/types';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { useCorporate } from 'modules/corporates';
import { corporateFeatureFlag } from 'modules/corporates/utils';
import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';

import AdminTag from './AdminTag';
import GroupTag from '../GroupTag';
import { useUsersModalsController } from '../hooks';

interface Props {
  users?: UserV2[] | null;
  className?: string;
  selectedRowKeys: string[];
  onRowSelectionChange: (selectedRowKeys: string[], selectedRecords: UserV2[]) => void;
  error: unknown;
  loading: boolean;
}

type ComponentProps = {
  selectedRowKeys: string[];
};

const DataItem = styled.div<{
  isDisabled: boolean;
  isSelected: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  color: ${(props) => props.isDisabled && color.textDisabled};
  background-color: ${(props) => props.isSelected && colors.wolt12};
`;

const Container = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div`
  color: ${color.textSubdued};
  font-weight: normal;
`;

const GroupsContainer = styled.div`
  font-size: 14px;
  margin-top: 6px;
`;

const StyledStatus = styled(UserStatus)`
  margin: 0 8px;
  display: inline-block;
`;

const UserTitle = ({
  user,
  shouldLinkToOpsTools,
}: {
  user: UserV2;
  shouldLinkToOpsTools: boolean;
}) => {
  const fullName = `${user.name.first} ${user.name.last}`;

  if (shouldLinkToOpsTools) {
    return (
      <Anchor
        target="_blank"
        rel="noopener noreferrer"
        href={`${env.OPS_TOOLS_URI}/users/${user.user_id}`}
      >
        {fullName} <StyledStatus status={user.status} />
      </Anchor>
    );
  }

  return (
    <span>
      {fullName} <StyledStatus status={user.status} />
    </span>
  );
};

const StyledDropdownActionButton = styled(DropdownActionButton)`
  position: absolute;
  ${cssDirectionalValue({ ltr: 'right: 0;', rtl: 'left: 0;' })}
`;

const StyledRow = styled(Row)`
  padding-inline-end: 32px;
  position: relative;
`;

const useRenderDataItem = () => {
  const { openDeleteUserModal, openEditUserModal } = useUsersModalsController();
  const { data: corporate } = useCorporate();
  const { isWoltEmployee } = useIsUserWoltEmployee();
  const { groupEnabled } = corporateFeatureFlag(corporate);

  const renderDataItem = (user: UserV2, { selectedRowKeys }: ComponentProps) => {
    const isSelected = selectedRowKeys ? selectedRowKeys.includes(user.user_id) : false;

    return (
      <DataItem isSelected={isSelected} isDisabled={false}>
        <Container>
          <StyledRow>
            <UserTitle user={user} shouldLinkToOpsTools={isWoltEmployee} />

            {user.is_admin && <AdminTag isDisabled={false} />}

            {!selectedRowKeys.length && (
              <StyledDropdownActionButton
                showCaret={false}
                placement="bottom-end"
                actions={[
                  {
                    label: <FormattedMessage id="common.edit" />,
                    onClick: () => openEditUserModal(user.user_id),
                  },
                  {
                    label: <FormattedMessage id="common.delete" />,
                    onClick: () => openDeleteUserModal(user.user_id),
                  },
                ]}
              >
                <IconButton background="animated" size="small" variant="black">
                  <MoreHorizontal />
                </IconButton>
              </StyledDropdownActionButton>
            )}
          </StyledRow>

          <Text>{user.email}</Text>

          {groupEnabled && (
            <GroupsContainer>
              {user.groups.map((group) => (
                <GroupTag key={group.id} isDisabled={!group.active}>
                  {group.name}
                </GroupTag>
              ))}
            </GroupsContainer>
          )}
        </Container>
      </DataItem>
    );
  };
  return renderDataItem;
};

export const UsersDataList: React.FC<Props> = ({
  users,
  onRowSelectionChange,
  selectedRowKeys,
  ...rest
}) => {
  const renderDataItem = useRenderDataItem();
  const { formatMessage } = useIntl();

  const handleRowSelectionChange = useCallback(
    (_: UserV2, selectedItemIds: string[]) => {
      const selectedRecord = (users || []).reduce<UserV2[]>((obj, val) => {
        const isSelected = selectedRowKeys.includes(val.user_id);
        if (!isSelected) {
          obj.push(val);
        }
        return obj;
      }, []);
      onRowSelectionChange(selectedItemIds, selectedRecord);
    },
    [users, onRowSelectionChange, selectedRowKeys],
  );

  return (
    <DataList
      data={users}
      getDataItemId={(user) => user.user_id}
      noData={
        <Empty
          title={formatMessage({ id: 'users.users-view.no-users' })}
          description={formatMessage({ id: 'users.users-view.no-users-description' })}
        />
      }
      onDataItemSelectionClick={handleRowSelectionChange}
      renderDataItem={renderDataItem}
      selectedDataItems={selectedRowKeys}
      renderDataItemProps={{ selectedRowKeys }}
      {...rest}
    />
  );
};
