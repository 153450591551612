import * as t from 'io-ts';

import { restaurantApiAgent } from 'apiAgents';
import { logValidationError } from 'validation';

import { CountryConfig } from './types';

export const fetchCountryConfigs = async () => {
  const { data } = await restaurantApiAgent.get<CountryConfig[]>('/v1/waw-api/country-configs');
  logValidationError('fetchCountryConfigs', t.array(CountryConfig), data);
  return data;
};

export type CreateCountryConfigData = Pick<
  CountryConfig,
  | 'country_code'
  | 'currency'
  | 'default_corporate_credit_limit_amount'
  | 'default_corporate_invoice_fee_percentage'
  | 'default_corporate_payment_term_delay_days'
  | 'default_corporate_invoice_overdue_interest'
  | 'enabled_products'
  | 'include_main_document_order_list'
  | 'include_main_document_vat_id'
  | 'include_sub_invoice_bank_transfer_note'
  | 'include_sub_invoice_issuer_details'
  | 'is_tipping_enabled'
  | 'local_bank_account_numbers'
  | 'main_invoice_document_type'
  | 'netsuite_config'
  | 'requires_separate_merchant_invoices'
  | 'requires_wolt_sub_invoices'
  | 'send_invoices_to_merchants'
  | 'service_fee_document_location'
  | 'service_fee_vat_explanation'
  | 'service_fee_vat_percentage'
  | 'status'
  | 'standalone_wolt_country'
  | 'transaction_limits'
  | 'wolt_company_account_bic'
  | 'wolt_company_account_iban'
  | 'wolt_company_name'
> & {
  agreement_party_id?: string;
};

export type EditCountryConfigData = Partial<
  Pick<
    CountryConfig,
    | 'agreement_party_id'
    | 'currency'
    | 'default_corporate_credit_limit_amount'
    | 'default_corporate_invoice_fee_percentage'
    | 'default_corporate_payment_term_delay_days'
    | 'default_corporate_invoice_overdue_interest'
    | 'include_main_document_order_list'
    | 'include_main_document_vat_id'
    | 'include_sub_invoice_bank_transfer_note'
    | 'include_sub_invoice_issuer_details'
    | 'is_tipping_enabled'
    | 'enabled_products'
    | 'local_bank_account_numbers'
    | 'main_invoice_document_type'
    | 'netsuite_config'
    | 'requires_separate_merchant_invoices'
    | 'requires_wolt_sub_invoices'
    | 'send_invoices_to_merchants'
    | 'service_fee_document_location'
    | 'service_fee_vat_explanation'
    | 'service_fee_vat_percentage'
    | 'standalone_wolt_country'
    | 'status'
    | 'transaction_limits'
    | 'wolt_company_account_bic'
    | 'wolt_company_account_iban'
    | 'wolt_company_name'
  >
>;

export const createCountryConfig = async (createCountryConfigData: CreateCountryConfigData) => {
  const { data } = await restaurantApiAgent.post<CountryConfig>(
    `/v1/waw-api/country-configs`,
    createCountryConfigData,
  );
  logValidationError('createCountryConfig', CountryConfig, data);
  return data;
};

export const editCountryConfig = async (
  id: string,
  editCountryConfigData: EditCountryConfigData,
) => {
  const { data } = await restaurantApiAgent.patch<CountryConfig>(
    `/v1/waw-api/country-configs/${id}`,
    editCountryConfigData,
  );
  logValidationError('editCountryConfig', CountryConfig, data);
  return data;
};
