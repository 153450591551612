import { useCallback } from 'react';
import { css } from 'styled-components';

import { LocalizedMessage, useI18n } from 'i18n';
import { useNotificationToast } from 'modules/app-modals';
import { breakpoints } from 'modules/media';

const SEND_EVENT_REMINDER_TOAST_ID = 'SEND_EVENT_REMINDER_TOAST_ID';

const notificationToastCss = css`
  width: 420px;

  @media (max-width: ${breakpoints.small}px) {
    width: 320px;
  }
`;

export const useOpenSendEventReminderSuccessNotification = () => {
  const { getLocalizedMessage } = useI18n();
  const { openNotificationToast, closeNotificationToast } = useNotificationToast();
  return useCallback(
    () =>
      openNotificationToast(SEND_EVENT_REMINDER_TOAST_ID, {
        variant: 'success',
        notificationToastStyle: notificationToastCss,
        title: getLocalizedMessage('events.send-event-reminder-success-title'),
        onCloseButtonClick: () => closeNotificationToast(SEND_EVENT_REMINDER_TOAST_ID),
        autoCloseIn: 10 * 1000,
        onClose: () => closeNotificationToast(SEND_EVENT_REMINDER_TOAST_ID),
        children: <LocalizedMessage messageKey="events.send-event-reminder-success-text" />,
      }),
    [closeNotificationToast, getLocalizedMessage, openNotificationToast],
  );
};
