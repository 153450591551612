import styled from 'styled-components';
import { MultiSelect } from '@creditornot/cb-select';

import { useI18n } from 'i18n';
import { Header } from 'components';

interface Props {
  className?: string;
  eventStatuses?: string[];
  onChange: (option: string[]) => void;
}

const StyledMultiSelect = styled(MultiSelect)`
  width: 240px;
`;

export const Panel = ({ className, eventStatuses = [], onChange }: Props) => {
  const { getLocalizedMessage } = useI18n();
  return (
    <Header
      leftItems={[
        <StyledMultiSelect
          key="StyledMultiSelect"
          value={eventStatuses}
          onChange={onChange}
          placeholder={getLocalizedMessage('events.filters.input-placeholder')}
          options={[
            { label: getLocalizedMessage('events.status.canceled'), value: 'cancelled' },
            { label: getLocalizedMessage('events.status.finished'), value: 'finished' },
            { label: getLocalizedMessage('events.status.ongoing'), value: 'ongoing' },
            { label: getLocalizedMessage('events.status.upcoming'), value: 'upcoming' },
          ]}
        />,
      ]}
      borderBottom
      className={className}
    ></Header>
  );
};
