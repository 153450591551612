import { useCallback } from 'react';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import styled from 'styled-components';
import { Checkmark } from '@creditornot/cb-icons';
import { Button } from '@creditornot/cb-button';
import { Alert } from '@creditornot/cb-alert';
import { ModalFooter } from '@creditornot/cbt-modal';

import { ResponsiveContainer } from 'components';
import { LocalizedMessage } from 'i18n';
import { useCorporate } from 'modules/corporates';
import { useEditGroup } from 'modules/groups';
import { Group } from 'modules/groups/types';
import { useCreatePolicyInModal } from 'modules/policies';
import { processError } from 'utils';

import { SelectPolicyFormValues } from './types';
import { SelectPolicyFormSection } from './SelectPolicyFormSection';

type ChangeGroupPolicyFormProps = {
  group: Group;
  onClose: () => void;
  onSuccess: () => void;
};

type FormikChangeGroupPolicyFormContentProps = {
  hasPolicy: boolean;
  onClose: () => void;
};

const Container = styled(ResponsiveContainer)`
  margin-top: 24px;
  flex: 1;

  & > div {
    width: 100%;
  }
`;

const StyledModalFooter = styled(ModalFooter)`
  margin-top: 24px;

  button {
    margin-inline-start: auto;
  }

  button + button {
    margin-inline-start: 16px;
  }
`;

const FormikChangeGroupPolicyFormContent = ({
  hasPolicy,
  onClose,
}: FormikChangeGroupPolicyFormContentProps) => {
  const { status, submitForm, isSubmitting, dirty, setFieldValue, setStatus } =
    useFormikContext<SelectPolicyFormValues>();
  const createPolicyInModal = useCreatePolicyInModal();
  const handleCreatePolicyClick = useCallback(async () => {
    try {
      const policy = await createPolicyInModal();
      setFieldValue('policy_ids', [policy.id]);
    } catch (error) {
      setStatus({ error });
    }
  }, [createPolicyInModal, setFieldValue, setStatus]);
  return (
    <>
      <Container>
        <SelectPolicyFormSection onCreatePolicyClick={handleCreatePolicyClick} />
      </Container>
      {status?.error && (
        <Container>
          <Alert variant="error" title={processError(status.error).message}>
            {processError(status.error).data}
          </Alert>
        </Container>
      )}
      <StyledModalFooter>
        <Button
          disabled={isSubmitting || status?.submitSucceeded}
          onClick={onClose}
          variant="lightBlue"
          size="small"
        >
          <LocalizedMessage messageKey="common.cancel" />
        </Button>

        <Button
          onClick={submitForm}
          size="small"
          icon={status?.submitSucceeded && <Checkmark />}
          disabled={isSubmitting || status?.submitSucceeded || !dirty}
          loading={isSubmitting}
          variant="blue"
        >
          {hasPolicy ? (
            <LocalizedMessage messageKey="common.change" />
          ) : (
            <LocalizedMessage messageKey="common.add" />
          )}
        </Button>
      </StyledModalFooter>
    </>
  );
};

export const ChangeGroupPolicyForm = ({
  group,
  onClose,
  onSuccess,
}: ChangeGroupPolicyFormProps) => {
  const editGroup = useEditGroup();
  const { data: corporate } = useCorporate();
  const initialValues: SelectPolicyFormValues = {
    policy_ids: group?.policies[0]?.id ? [group.policies[0].id] : [],
  };
  const hasPolicy = !!group?.policies[0];
  const handleSubmit = useCallback(
    ({ policy_ids }: SelectPolicyFormValues, actions: FormikHelpers<SelectPolicyFormValues>) => {
      if (!policy_ids || !group || !corporate) {
        actions.setSubmitting(false);
        actions.setStatus({ error: 'Corporate, policy_ids and group must be provided' });
        return;
      }

      return editGroup(group.id, {
        policy_ids,
      })
        .then(() => {
          actions.setSubmitting(false);
          actions.setStatus({ submitSucceeded: true });
          setTimeout(onSuccess, 400);
        })
        .catch((error: any) => {
          actions.setStatus({ error });
          actions.setSubmitting(false);
        });
    },
    [corporate, editGroup, group, onSuccess],
  );

  if (!corporate) {
    return null;
  }

  return (
    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit}>
      <FormikChangeGroupPolicyFormContent hasPolicy={hasPolicy} onClose={onClose} />
    </Formik>
  );
};
