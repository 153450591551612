import { useMemo } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import jsonHighlight from 'react-syntax-highlighter/dist/cjs/languages/hljs/json';
import queryString from 'query-string';

import { ErrorNotification } from 'components/ErrorNotification';

SyntaxHighlighter.registerLanguage('json', jsonHighlight);

/** Error response (error.response) from an Axios API request */
interface PartialAxiosErrorResponse {
  config: {
    method?: string;
    url?: string;
    params?: any;
  };
  data?: any;
  status: number;
  statusText: string;
}

/** Error  from an Axios API request */
interface PartialAxiosError {
  response?: PartialAxiosErrorResponse;
}

type APIError = PartialAxiosError | PartialAxiosErrorResponse | string | any;

interface Props {
  /** Additional error information shown below the title */
  children?: React.ReactNode;
  error: APIError;
}

const isPartialAxiosErrorResponse = (error: APIError): error is PartialAxiosErrorResponse => {
  return !!error && typeof error !== 'string' && !!error.status && !!error.config;
};

const isPartialAxiosError = (error: APIError): error is PartialAxiosError => {
  return (
    !!error &&
    typeof error !== 'string' &&
    !!error.response &&
    isPartialAxiosErrorResponse(error.response)
  );
};

const getErrorResponseURL = (response: PartialAxiosErrorResponse) => {
  const { method, url, params } = response.config;

  if (!url) {
    return undefined;
  }

  return `${method ? method.toUpperCase() : ''} ${url}${
    !!params && Object.keys(params).length ? '?' : ''
  }${params ? queryString.stringify(params) : ''}`;
};

const getErrorResponseText = (response: PartialAxiosErrorResponse) => {
  const { data } = response;

  if (data && typeof data === 'string') {
    return data;
  }

  if (data?.message && typeof data.message === 'string') {
    return data.message;
  }

  if (data?.msg && typeof data.msg === 'string') {
    return data.msg;
  }

  return undefined;
};

const getErrorResponseTitle = (response: PartialAxiosErrorResponse) => {
  return [response.status, response.statusText].filter(Boolean).join(' ');
};

const APIErrorNotification = ({ children, error, ...rest }: Props) => {
  const url: string | undefined = useMemo(() => {
    if (isPartialAxiosError(error) && error.response) {
      return getErrorResponseURL(error.response);
    }

    if (isPartialAxiosErrorResponse(error)) {
      return getErrorResponseURL(error);
    }

    return undefined;
  }, [error]);

  const errorTitle: string | undefined = useMemo(() => {
    if (!error) {
      return 'Unknown error';
    }

    if (isPartialAxiosError(error) && error.response) {
      return getErrorResponseTitle(error.response);
    }

    if (isPartialAxiosErrorResponse(error)) {
      return getErrorResponseTitle(error);
    }

    if (error instanceof Error && error.message) {
      return 'Error';
    }

    if (typeof error === 'string') {
      return error;
    }

    return undefined;
  }, [error]);

  const errorContent: string = useMemo(() => {
    if (!error) {
      return '<missing error data>';
    }

    if (isPartialAxiosError(error) && error.response) {
      return getErrorResponseText(error.response);
    }

    if (isPartialAxiosErrorResponse(error)) {
      return getErrorResponseText(error);
    }

    if (typeof error === 'string') {
      return undefined;
    }

    if (error instanceof Error && error.message) {
      return error.message;
    }

    return JSON.stringify(error);
  }, [error]);

  const jsonData: Record<string, any> | undefined = useMemo(() => {
    if (isPartialAxiosError(error) && error.response) {
      return error.response.data;
    }

    if (isPartialAxiosErrorResponse(error)) {
      return error.data;
    }

    if (error instanceof Error) {
      return undefined;
    }

    if (typeof error === 'string') {
      return undefined;
    }

    if (typeof error === 'object') {
      return error;
    }

    return undefined;
  }, [error]);

  return (
    <ErrorNotification {...rest} jsonData={jsonData} title={errorTitle} url={url}>
      {children || errorContent}
    </ErrorNotification>
  );
};

export default APIErrorNotification;
