import { useCallback, useMemo } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';

import { View } from 'components';
import { LocalizedMessage } from 'i18n';
import { useFetchInvites } from 'modules/invites';
import { useFetchUsers } from 'modules/users';
import { useDebounce } from 'modules/hooks';
import { UserInvite } from 'modules/invites/types';
import { useCorporate } from 'modules/corporates';
import { corporateFeatureFlag } from 'modules/corporates/utils';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { membersView } from 'telemetry/Avo';

import { ResendUserInvitesModal } from './ResendUserInvitesModal';
import { RemoveUsersModal } from './RemoveUsersModal';
import { RemoveInvitesModal } from './RemoveInvitesModal';
import { EditUserModal } from './EditUserModal';
import NewUserModal from './NewUserModal';
import { InviteButton } from './InviteButton';
import { UsersDataView } from './UsersDataView';
import InvitesDataView from './InvitesDataView';
import { UsersPanel } from './UsersPanel';
import {
  useUsersModalsController,
  useUsersQueryParams,
  useOpenAddUsersWithoutGroupToast,
} from './hooks';

export const UsersView: React.FC<
  RouteComponentProps<{
    action?:
      | 'invite'
      | 'add-existing'
      | 'create-and-add'
      | 'delete-users'
      | 'delete-invites'
      | 'resend-invites'
      | 'edit-user';
    corporateId: string;
    userId?: string;
  }>
> = ({ match }) => {
  const { data: corporate } = useCorporate();
  const history = useHistory();
  const openAddUsersWithoutGroupToast = useOpenAddUsersWithoutGroupToast();
  const { closeModal } = useUsersModalsController();
  const [
    {
      showInvites,
      user: userIdQueryParam,
      invite: userInviteIdQueryParam,
      users: userIdsQueryParam = [],
      invites: userInviteIdsQueryParam = [],
      search: searchQueryParam,
      groups,
      status,
      page = 1,
      page_size = 50,
    },
    setQueryParams,
  ] = useUsersQueryParams();
  const debouncedSearchQueryParam = useDebounce(searchQueryParam, 500);

  const {
    data: invitesData,
    isLoading: isLoadingInvites,
    error: invitesError,
  } = useFetchInvites({
    email: debouncedSearchQueryParam ?? '',
    groupIds: groups,
    statuses: status,
    page,
    page_size,
  });

  const {
    data: usersData,
    isLoading: isLoadingUsers,
    error: usersError,
  } = useFetchUsers({
    group_ids: groups,
    statuses: status,
    page,
    page_size,
    search: debouncedSearchQueryParam,
  });

  const { groupEnabled } = corporateFeatureFlag(corporate);

  const emailsFromUserParams = useMemo(() => {
    if ((!userIdQueryParam && !userIdsQueryParam) || !usersData) {
      return [];
    }

    const ids = userIdQueryParam ? [userIdQueryParam] : userIdsQueryParam;

    const users = usersData.results.filter((user) => ids.includes(user.user_id));
    return users.map((user) => user.email);
  }, [userIdQueryParam, userIdsQueryParam, usersData]);

  const handleInviteUsersSuccess = useCallback(
    (invites: UserInvite[]) => {
      const noGroupAdded = invites[0].groups.length === 0;
      if (noGroupAdded && corporate && groupEnabled) {
        openAddUsersWithoutGroupToast({
          corporateId: corporate.id,
          corporateName: corporate.name,
          numberOfUsers: invites.length,
        });
      }

      closeModal();
    },
    [closeModal, corporate, openAddUsersWithoutGroupToast, groupEnabled],
  );

  const handleAddUsersSuccess = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleEditUserSuccess = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleCloseResendUserInvitesModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    search.delete('invite');
    search.delete('invites');
    const pathname = `/${match.params.corporateId}/users?${search.toString()}`;
    history.push(pathname);
  }, [history, match.params.corporateId]);

  const handleResendUserInvitesSuccess = useCallback(() => {
    handleCloseResendUserInvitesModal();
  }, [handleCloseResendUserInvitesModal]);

  const handleCloseUsersModal = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    search.delete('user');
    search.delete('invite');
    const pathname = `/${match.params.corporateId}/users?${search.toString()}`;
    history.push(pathname);
  }, [history, match.params.corporateId]);

  const handleRemoveUsersSuccess = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    search.delete('user');
    search.delete('users');
    search.delete('invite');
    search.delete('invites');
    const pathname = `/${match.params.corporateId}/users`;
    history.push({ pathname, search: search.toString() });
  }, [history, match.params.corporateId]);

  const handleRemoveInvitesSuccess = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    search.delete('invites');
    search.delete('invite');

    const pathname = `/${match.params.corporateId}/users`;
    history.push({ pathname, search: search.toString() });
  }, [history, match.params.corporateId]);

  const handleCheckBoxClick = useCallback(() => {
    if (showInvites) {
      setQueryParams({
        invites:
          userInviteIdsQueryParam.length === invitesData?.results?.length
            ? undefined
            : invitesData?.results?.map(({ id }) => id),
      });
      return;
    }

    setQueryParams({
      users:
        userIdsQueryParam.length === usersData?.results.length
          ? undefined
          : usersData?.results?.map(({ user_id }) => user_id),
    });
  }, [
    invitesData,
    setQueryParams,
    showInvites,
    userIdsQueryParam.length,
    userInviteIdsQueryParam.length,
    usersData,
  ]);

  if (!corporate) {
    return null;
  }

  return (
    <>
      <ViewEventComponent event={membersView} />
      <View title={<LocalizedMessage messageKey="views.users" />} rightContent={<InviteButton />}>
        <UsersPanel
          corporateId={corporate.id}
          onCheckBoxClick={handleCheckBoxClick}
          numberOfInvites={invitesData?.total_items_count ?? 0}
          numberOfUsers={usersData?.total_items_count ?? 0}
        />
        {showInvites ? (
          <InvitesDataView
            invitesData={invitesData}
            error={invitesError}
            isLoading={isLoadingInvites}
          />
        ) : (
          <UsersDataView usersData={usersData} isLoading={isLoadingUsers} error={usersError} />
        )}
      </View>

      <NewUserModal
        show={['invite', 'add-existing', 'create-and-add'].includes(match.params.action || '')}
        corporateId={match.params.corporateId}
        onInviteSuccess={handleInviteUsersSuccess}
        onAddUserSuccess={handleAddUsersSuccess}
        onClose={closeModal}
      />

      <EditUserModal
        show={'edit-user' === match.params.action}
        corporate={corporate}
        onClose={closeModal}
        onEditUserSuccess={handleEditUserSuccess}
        userId={match.params.userId}
      />

      <ResendUserInvitesModal
        show={match.params.action === 'resend-invites'}
        selectedInviteIds={
          userInviteIdQueryParam ? [userInviteIdQueryParam] : userInviteIdsQueryParam
        }
        onResendUserInvitesSuccess={handleResendUserInvitesSuccess}
        onClose={handleCloseResendUserInvitesModal}
      />

      <RemoveUsersModal
        show={match.params.action === 'delete-users'}
        userIds={userIdQueryParam ? [userIdQueryParam] : userIdsQueryParam}
        emails={emailsFromUserParams}
        onRemoveUsersSuccess={handleRemoveUsersSuccess}
        onClose={handleCloseUsersModal}
      />

      <RemoveInvitesModal
        show={match.params.action === 'delete-invites'}
        selectedInviteIds={
          userInviteIdQueryParam ? [userInviteIdQueryParam] : userInviteIdsQueryParam
        }
        onRemoveInvitesSuccess={handleRemoveInvitesSuccess}
        onClose={handleCloseUsersModal}
      />
    </>
  );
};
