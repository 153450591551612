import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { FieldArray, useFormikContext } from 'formik';
import { typographyCss } from '@creditornot/cb-ingredients';
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoCircleFilled } from '@creditornot/cb-icons';
import { TextButton } from '@creditornot/cb-button';

import { Divider } from 'components';
import {
  DEFAULT_POLICY_LOCATION_RADIUS,
  DEFAULT_POLICY_TAKEAWAY_RADIUS,
} from 'modules/policies/constants';
import { env } from 'config';

import { PolicyTakeawayLocationMap } from './PolicyTakeawayLocationMap';
import { PolicyTakeawayRadiusSlider } from './PolicyTakeawayRadiusSlider';
import PolicyDeliveryLocationPicker from './PolicyDeliveryLocationField';
import { PolicyDeliveryLocationAddressField } from './PolicyDeliveryLocationAddressField';
import PolicyDeliveryLocationNameField from './PolicyDeliveryLocationNameField';
import { PolicyLimitTakeawayField } from './PolicyLimitTakeawayField';
import { PolicyFormValues } from './types';

const LocationSection = styled.div`
  & + & {
    margin-top: 18px;
    padding-top: 18px;
    border-top: 1px solid ${color.border};
  }
`;

const LocationHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
`;

const Hint = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

const StyledInfoCircleFilledIcon = styled(InfoCircleFilled)`
  align-self: flex-start;
  width: 12px;
  height: 16px;
`;

const HintLabel = styled.small`
  ${typographyCss.Small()}
`;

const LocationPickerContainer = styled.div`
  height: 290px;
`;

const LocationHeaderTitle = styled.span`
  ${typographyCss.Body3()}
`;

const StyledDivider = styled(Divider)`
  margin: 24px 0;
`;

const LocationNameTitle = styled.div`
  ${typographyCss.Body3()}
  margin-bottom: 8px;
`;

const LocationRadiusTitle = styled.div`
  ${typographyCss.Body3()}
  margin: 12px 0 8px;
`;

const LocationRemoveButton = styled(TextButton)`
  font-weight: 600;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 3px ${color.outlineFocused};
  }
`;

const StyledRequiredSpan = styled.span`
  color: ${color.textNegative};
`;

const PolicyDeliveryLocationAddressFieldWrapper = styled.div`
  margin: 12px 0;
`;

const AddAnotherLocationButton = styled(TextButton)`
  font-weight: 600;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 3px ${color.outlineFocused};
  }
`;

const PolicyDeliveryLocationFieldArray = () => {
  const { formatMessage } = useIntl();

  const { values, validateForm } = useFormikContext<PolicyFormValues>();

  return (
    <FieldArray name="delivery_locations">
      {(arrayHelpers) => (
        <>
          {values.delivery_locations.map(({ allow_takeaway }, index) => (
            <LocationSection key={index}>
              {values.delivery_locations.length > 1 && (
                <LocationHeaderSection>
                  <LocationHeaderTitle>
                    <FormattedMessage id="policies.location-title" values={{ number: index + 1 }} />
                  </LocationHeaderTitle>
                  <LocationRemoveButton
                    role="button"
                    onClick={() => {
                      arrayHelpers.remove(index);
                      setTimeout(() => {
                        validateForm();
                      }, 100);
                      /* There's a bug in Formik where using remove arrayHelper can cause the an empty array to be
                      to the error object under the key of the field array: https://github.com/jaredpalmer/formik/issues/784 */
                    }}
                  >
                    <FormattedMessage id="common.remove" />
                  </LocationRemoveButton>
                </LocationHeaderSection>
              )}
              <LocationPickerContainer>
                <PolicyDeliveryLocationPicker index={index} />
              </LocationPickerContainer>
              <PolicyDeliveryLocationAddressFieldWrapper>
                <LocationNameTitle>{formatMessage({ id: 'common.address' })}</LocationNameTitle>
                <PolicyDeliveryLocationAddressField index={index} />
              </PolicyDeliveryLocationAddressFieldWrapper>
              <LocationNameTitle>
                {formatMessage({ id: 'policies.location-name' })}
                <StyledRequiredSpan> *</StyledRequiredSpan>
              </LocationNameTitle>
              <PolicyDeliveryLocationNameField index={index} />
              {env.ENABLE_POLICY_TAKEAWAY_RESTRICTION === 'true' && (
                <>
                  <LocationNameTitle style={{ marginTop: 24 }}>
                    {formatMessage({ id: 'policies.takeaway-restriction' })}
                  </LocationNameTitle>
                  <PolicyLimitTakeawayField index={index} />
                  {allow_takeaway === 'true' && (
                    <>
                      <LocationRadiusTitle>
                        {formatMessage({ id: 'policies.takeaway-radius' })}
                      </LocationRadiusTitle>
                      <PolicyTakeawayLocationMap index={index} />
                      <PolicyTakeawayRadiusSlider index={index} />
                      <Hint>
                        <StyledInfoCircleFilledIcon />
                        <HintLabel>
                          {formatMessage(
                            {
                              id: 'policies.takeaway-radius-explanation',
                            },
                            { radius: DEFAULT_POLICY_TAKEAWAY_RADIUS },
                          )}
                        </HintLabel>
                      </Hint>
                    </>
                  )}
                </>
              )}
            </LocationSection>
          ))}
          <StyledDivider />
          <AddAnotherLocationButton
            role="button"
            onClick={() => {
              arrayHelpers.push({
                name: '',
                address: '',
                coordinates:
                  values.delivery_locations[values.delivery_locations.length - 1].coordinates,
                radius: DEFAULT_POLICY_LOCATION_RADIUS,
                allow_takeaway: 'false',
                takeaway_radius: DEFAULT_POLICY_TAKEAWAY_RADIUS,
              } satisfies PolicyFormValues['delivery_locations'][number]);
            }}
          >
            <FormattedMessage id={'policies.add-another-location'} />
          </AddAnotherLocationButton>
        </>
      )}
    </FieldArray>
  );
};

export default PolicyDeliveryLocationFieldArray;
