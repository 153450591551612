import { useCallback } from 'react';

import { useQuery, UseQueryOptions } from 'modules/react-query';
import { PaginatedData } from 'types/PaginatedData';

import { fetchAgreementParties, FetchAgreementPartiesParams } from './api';
import { AgreementParty } from './types';

export const useFetchAgreementParties = <T = PaginatedData<AgreementParty>>(
  params: FetchAgreementPartiesParams,
  config?: UseQueryOptions<PaginatedData<AgreementParty>, unknown, T>,
) => {
  const queryFn = useCallback(() => {
    return fetchAgreementParties(params);
  }, [params]);
  return useQuery({ queryKey: ['agreement-parties', params], queryFn, ...config });
};
