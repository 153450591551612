import { useCallback } from 'react';

import { ConfirmationModal } from 'components';
import { LocalizedMessage } from 'i18n';
import { useCorporate } from 'modules/corporates';
import { useEditGroup } from 'modules/groups';
import { Group } from 'modules/groups/types';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  show: boolean;
  group: Group | null | undefined;
}

export const AutomaticallyAddUserModal = ({ onClose, onSuccess, show, group }: Props) => {
  const { data: corporate } = useCorporate();
  const editGroup = useEditGroup();

  const handleConfirm = useCallback(async () => {
    if (!group) {
      throw 'Group is missing';
    }

    const add_to_all = !!group.add_to_all;

    editGroup(group.id, {
      add_to_all: !add_to_all,
    });
  }, [editGroup, group]);

  return (
    <ConfirmationModal
      onClose={onClose}
      onConfirm={handleConfirm}
      show={show}
      content={
        <LocalizedMessage
          messageKey={
            group?.add_to_all
              ? 'groups-form.auto-add-all-new-users.disabled-confirmation-description'
              : 'groups-form.auto-add-all-new-users.confirmation-description'
          }
          values={{
            strong: (text: string) => <strong>{text}</strong>,
            corporate: corporate?.name,
            group: group?.name,
          }}
        />
      }
      title={
        <LocalizedMessage
          messageKey={
            group?.add_to_all
              ? 'groups-form.auto-add-all-new-users.disabled-confirmation-title'
              : 'groups-form.auto-add-all-new-users.confirmation-title'
          }
        />
      }
      confirmationButtonMessage={
        <LocalizedMessage
          messageKey={
            group?.add_to_all
              ? 'groups-form.auto-add-all-new-users.disable-button'
              : 'groups-form.auto-add-all-new-users.enable-button'
          }
        />
      }
      confirmationButtonVariant={group?.add_to_all ? 'lightRed' : 'blue'}
      onSuccess={() => setTimeout(onSuccess, 500)}
    ></ConfirmationModal>
  );
};
