import { useCallback } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { ConfirmationModal } from 'components';
import { useDeleteUsers } from 'modules/users';
import { UI_SUCCESS_DELAY } from 'modules/common/constants';

interface Props {
  show: boolean;
  userIds: string[];
  emails: string[];
  onRemoveUsersSuccess: () => void;
  onClose: () => void;
}

const Container = styled.div`
  min-height: 80px;
`;

type RemoveUsersModalTitleProps = { numberOfUsers: number };

const RemoveUsersModalTitle = ({ numberOfUsers }: RemoveUsersModalTitleProps) => {
  if (numberOfUsers > 1) {
    return <FormattedMessage id="users.delete-users.title" />;
  }
  return <FormattedMessage id="users.delete-user.title" />;
};

export const RemoveUsersModal = ({
  show,
  userIds,
  emails,
  onRemoveUsersSuccess,
  onClose,
}: Props) => {
  const deleteUsers = useDeleteUsers();

  const handleConfirm = useCallback(async () => {
    await deleteUsers({ userIds, invalidateDelay: UI_SUCCESS_DELAY });
  }, [userIds, deleteUsers]);

  return (
    <ConfirmationModal
      onClose={onClose}
      onConfirm={handleConfirm}
      show={show}
      content={
        <Container>
          {emails.length > 5 ? (
            <FormattedMessage
              id="users.delete-users.confirmation-question-count"
              values={{
                count: emails.length,
              }}
            />
          ) : (
            <FormattedMessage
              id="users.delete-users.confirmation-question"
              values={{
                users: emails.join(', '),
              }}
            />
          )}
        </Container>
      }
      title={<RemoveUsersModalTitle numberOfUsers={userIds.length} />}
      confirmationButtonMessage={<FormattedMessage id="common.delete" />}
      confirmationButtonVariant="lightRed"
      onSuccess={() => setTimeout(onRemoveUsersSuccess, UI_SUCCESS_DELAY)}
    />
  );
};
