import * as t from 'io-ts';

import { PaginatedData } from 'types/PaginatedData';

export type PaginatedSubscriptionData<Data> = PaginatedData<Data> & { total_pages_count: number };

export const SubscriptionStatus = t.keyof({
  created: null,
  active: null,
  deactivated: null,
  terms_accepted: null,
});
export type SubscriptionStatus = t.TypeOf<typeof SubscriptionStatus>;

export const SubscriptionPrice = t.type({
  monthly: t.type({
    amount: t.number,
    currency: t.string,
  }),
});
export type SubscriptionPrice = t.TypeOf<typeof SubscriptionPrice>;

export const Subscription = t.intersection([
  t.type({
    id: t.string,
    corporate_id: t.string,
    created_at: t.string,
    status: SubscriptionStatus,
  }),
  t.partial({
    price: SubscriptionPrice,
  }),
]);
export type Subscription = t.TypeOf<typeof Subscription>;

export const SubscriptionInviteStatus = t.keyof({
  pending: null,
  expired: null,
});
export type SubscriptionInviteStatus = t.TypeOf<typeof SubscriptionInviteStatus>;

export const SubscriptionInvite = t.type({
  id: t.string,
  status: SubscriptionInviteStatus,
  email: t.string,
});
export type SubscriptionInvite = t.TypeOf<typeof SubscriptionInvite>;

export const SubscriptionUserStatus = t.keyof({
  subscribed: null,
  not_subscribed: null,
  disabled: null,
});
export type SubscriptionUserStatus = t.TypeOf<typeof SubscriptionUserStatus>;

export const SubscriptionUser = t.type({
  user_id: t.string,
  first_name: t.string,
  last_name: t.string,
  email: t.string,
  status: SubscriptionUserStatus,
});
export type SubscriptionUser = t.TypeOf<typeof SubscriptionUser>;
