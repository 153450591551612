import { useIntl } from 'react-intl';

import { ModalV2 } from 'components';
import { Event } from 'modules/events/types';

import { CreateEventForm } from './CreateEventForm';

interface Props {
  onCloseClick: () => void;
  onSucess: (event: Event) => void;
  show: boolean;
  currency: string;
  onSuccessAndOpenInviteEventUsers: (event: Event) => void;
}

export const CreateEventModal = ({
  onCloseClick,
  show,
  onSucess,
  onSuccessAndOpenInviteEventUsers,
  currency,
}: Props) => {
  const { formatMessage } = useIntl();
  return (
    <ModalV2
      onDismiss={onCloseClick}
      open={show}
      title={formatMessage({ id: 'events.create-event-form.title' })}
      sizePreset={{
        desktop: 'centered:large',
        mobile: 'fullScreen',
      }}
    >
      <CreateEventForm
        onSuccess={onSucess}
        onSuccessAndOpenInviteEventUsers={onSuccessAndOpenInviteEventUsers}
        currency={currency}
      />
    </ModalV2>
  );
};
