const parseEnvString = (envString: string) => {
  return envString ? JSON.parse(window.atob(envString)) : {};
};
const env: {
  ANALYZE_BUNDLE: string;
  APP_ENV: string;
  APP_VERSION: string;
  AUTH_API_URI: string;
  CASH_ENABLED_COUNTRIES: string;
  CDN: string;
  CONSUMER_ASSETS_URI: string;
  CORPORATE_SERVICE_URI: string;
  DAAS_SELF_SERVICE_API_URI: string;
  DAAS_SELF_SERVICE_URI: string;
  DEVELOPMENT_LOCALE: string;
  FAVICON: string;
  GA_TRACKING_ID: string;
  GATEKEEPER_URI: string;
  GATEKEEPER_API_KEY: string;
  GOOGLE_LOGIN_URI: string;
  HANDSHAKE_ENABLED_COUNTRIES: string;
  HOST: string;
  INTERCOM_APP_ID: string;
  NODE_ENV: 'development' | 'production' | 'test';
  OPS_TOOLS_URI: string;
  PORT: number;
  PUBLIC_GOOGLE_LOGIN_URI: string;
  RESTAURANT_API_URI: string;
  WOLT_AT_WORK_URI: string;
  WOLT_COM_URI: string;
  WOLF_API_URI: string;
  WOLT_PAGES_URI: string;
  DAAS_CORE_API_URI: string;
  ENABLE_POLICY_TAKEAWAY_RESTRICTION: string;
  COOKIE_SDK_DOMAIN: string;
  MERCHANT_PAYOUT_URI: string;
  MULTIPAYMENT_COUNTRIES: string;
  MAPBOX_TOKEN: string;
} = parseEnvString((window as any).__env);

export default env;
