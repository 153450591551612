import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ConfirmationModal } from 'components';
import { LocalizedMessage } from 'i18n';
import { useResendInvite } from 'modules/invites';

interface Props {
  show: boolean;
  selectedInviteIds: string[] | null;
  onResendUserInvitesSuccess: () => void;
  onClose: () => void;
}

export const ResendUserInvitesModal = ({
  show,
  onClose,
  selectedInviteIds,
  onResendUserInvitesSuccess,
}: Props) => {
  const { formatMessage } = useIntl();
  const resendUserInvite = useResendInvite();

  const handleConfirm = useCallback(async () => {
    if (!selectedInviteIds) {
      return Promise.reject('Invite ids are missing');
    }

    await resendUserInvite(selectedInviteIds);
  }, [selectedInviteIds, resendUserInvite]);

  return (
    <ConfirmationModal
      onClose={onClose}
      onConfirm={handleConfirm}
      show={show}
      content={formatMessage(
        { id: 'users.resend-invite.confirmation-text-count' },
        {
          count: selectedInviteIds?.length,
        },
      )}
      title={
        selectedInviteIds && selectedInviteIds.length > 1 ? (
          <LocalizedMessage messageKey="users.resend-invites.title" />
        ) : selectedInviteIds && selectedInviteIds.length === 1 ? (
          <LocalizedMessage messageKey="users.resend-invite.title" />
        ) : null
      }
      confirmationButtonMessage={<LocalizedMessage messageKey="common.resend" />}
      confirmationButtonVariant="blue"
      onSuccess={() => setTimeout(onResendUserInvitesSuccess, 500)}
    />
  );
};
