import { CaretLeft, CaretRight } from '@creditornot/cb-icons';

import { useLanguageDirection } from 'i18n';

type Props = Omit<React.SVGProps<SVGSVGElement>, 'ref'> &
  React.RefAttributes<SVGSVGElement> & {
    alignment: 'start' | 'end';
    className?: string;
  };

/** Language direction (ltr or rtl) aware Caret icon */
export const RtlCaret = ({ className, alignment, ...rest }: Props) => {
  const direction = useLanguageDirection();

  if (
    (direction === 'ltr' && alignment === 'start') ||
    (direction === 'rtl' && alignment === 'end')
  ) {
    return <CaretLeft className={className} {...rest} />;
  }

  return <CaretRight className={className} {...rest} />;
};
