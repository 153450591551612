import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchPaginatedSubscriptionUsers, FetchPaginatedSubscriptionParams } from './api';
import { PaginatedSubscriptionData, SubscriptionUser } from './types';

export const useFetchSubscriptionUsers = <T = PaginatedSubscriptionData<SubscriptionUser>>(
  subscriptionId: string | undefined,
  params: FetchPaginatedSubscriptionParams,
  config?: UseQueryOptions<PaginatedSubscriptionData<SubscriptionUser>, unknown, T>,
) => {
  const { data: corporate } = useCorporate();
  const queryFn = useCallback(() => {
    if (!corporate || !subscriptionId) {
      return Promise.reject();
    }
    return fetchPaginatedSubscriptionUsers(corporate.id, subscriptionId, params);
  }, [corporate, params, subscriptionId]);
  return useQuery({
    queryKey: ['subscription-users', corporate?.id, params],
    queryFn,
    enabled: !!corporate && !!subscriptionId,
    ...config,
  });
};
