import { color } from '@creditornot/cb-ingredients/design-tokens';
import { colors } from '@creditornot/cb-ingredients';
import styled, { css } from 'styled-components';
import { Checkbox } from '@creditornot/cb-checkbox';
import { Alert } from '@creditornot/cb-alert';

import { processError } from 'utils';

import ResponsiveContainer from '../ResponsiveContainer';
import Spinner from '../Spinner';

export interface Props<DataItem, DataCellProps> {
  className?: string;
  data?: DataItem[] | null;
  error?: any;
  renderDataItem: (dataItem: DataItem, dataCellProps: DataCellProps) => React.ReactNode;
  loading?: boolean;
  noData?: React.ReactNode;
  renderDataItemProps?: DataCellProps;
  getDataItemId: (dataItem: DataItem) => string;
  getDisabledDataItem?: (dataItem: DataItem) => boolean;
  onDataItemSelectionClick?: (dataItem: DataItem, selectedItemIds: string[]) => void;
  onDataItemClick?: (dataItem: DataItem) => void;
  selectedDataItems?: string[] | null;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(ResponsiveContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
`;

type DataItemProps = {
  leftContent?: React.ReactNode;
  children?: React.ReactNode;
  isClickable?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isDisabled?: boolean;
  isSelected?: boolean;
};

const DataItemRoot = styled(ResponsiveContainer)<
  Pick<DataItemProps, 'isClickable' | 'isDisabled' | 'isSelected'>
>`
  display: flex;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${color.border};
  ${({ isClickable }) =>
    isClickable &&
    css`
      &:hover {
        background-color: ${colors.wolt4};
      }

      &:active {
        background-color: ${colors.wolt8};
      }
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${colors.wolt4};
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.6;
    `}
`;
const LeftContent = styled.div`
  margin-top: 2px;
  margin-inline-end: 16px;
`;

const DataItem = ({
  leftContent,
  children,
  isClickable,
  isDisabled,
  isSelected,
  onClick,
}: DataItemProps) => {
  return (
    <DataItemRoot
      isClickable={isClickable}
      isDisabled={isDisabled}
      isSelected={isSelected}
      onClick={onClick}
    >
      {leftContent && <LeftContent>{leftContent}</LeftContent>}
      {children}
    </DataItemRoot>
  );
};

export function DataList<DataItem, DataCellProps>({
  className,
  data,
  error,
  getDataItemId,
  getDisabledDataItem,
  loading,
  noData,
  onDataItemSelectionClick,
  onDataItemClick,
  renderDataItem,
  renderDataItemProps,
  selectedDataItems,
}: Props<DataItem, DataCellProps>) {
  if (error || loading || data?.length === 0) {
    return (
      <Container>
        {error && (
          <Alert title={processError(error).message} size="medium" variant="error">
            {processError(error).data}
          </Alert>
        )}
        {loading && <Spinner size="large" />}
        {data?.length === 0 && noData}
      </Container>
    );
  }
  return (
    <Root className={className}>
      {data?.map((dataItem) => {
        const selected = selectedDataItems
          ? selectedDataItems.includes(getDataItemId(dataItem))
          : false;

        const newSelectedItems = selected
          ? (selectedDataItems || []).filter((item) => getDataItemId(dataItem) !== item)
          : (selectedDataItems || []).concat(getDataItemId(dataItem));

        return (
          <DataItem
            key={getDataItemId(dataItem)}
            isClickable={!!onDataItemClick}
            onClick={() => onDataItemClick?.(dataItem)}
            isDisabled={getDisabledDataItem?.(dataItem) || false}
            isSelected={selected}
            leftContent={
              onDataItemSelectionClick && (
                <Checkbox
                  onClick={() => onDataItemSelectionClick(dataItem, newSelectedItems)}
                  checked={selected}
                />
              )
            }
          >
            {renderDataItem(dataItem, renderDataItemProps as DataCellProps)}
          </DataItem>
        );
      })}
    </Root>
  );
}
