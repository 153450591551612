import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchSummary } from './api';
import { Summary } from './types';

export const useFetchSummary = <T = Summary>(config?: UseQueryOptions<Summary, unknown, T>) => {
  const { data: corporate } = useCorporate();
  const queryFn = useCallback(() => {
    if (!corporate) {
      return Promise.reject();
    }
    return fetchSummary(corporate.id);
  }, [corporate]);
  return useQuery({
    queryKey: ['summary', corporate?.id],
    queryFn,
    enabled: !!corporate,
    ...config,
  });
};
