import styled from 'styled-components';
import { pxToRem } from '@creditornot/cb-utils';
import { typographyCss } from '@creditornot/cb-ingredients';

const FormViewText = styled.span`
  ${typographyCss.Body3()}
  display: flex;
  min-height: ${pxToRem(36)};
`;

export default FormViewText;
