export const woltAtWorkEmail = `woltatwork@wolt.com`;
export const woltDriveEmail = `daas-support@wolt.com`;

export const createFeedbackHrefFrom = (company: string, companyType?: 'waw' | 'daas') => {
  if (companyType === 'daas') {
    return `mailto:${woltDriveEmail}?subject=Drive%20UI%20admin%20feedback%20from%20${company}`;
  } else {
    return `mailto:${woltAtWorkEmail}?subject=Wolt%20at%20Work%20feedback%20from%20${company}`;
  }
};
