import { FormikInputField } from 'components';
import { createValidator, isInteger, isNumber, isRequired, min } from 'validation';

//We are using min(1) here because if it is 0 then the BE will read it as a falsy value and default back to delivery configurations in ops-tools
const validate = createValidator([isRequired, isNumber, isInteger, min(1)]);

interface Props {
  alwaysShowError?: boolean;
}

export const DistanceInMetersField = ({ alwaysShowError }: Props) => {
  return (
    <FormikInputField
      name="maximum_distance_in_meters"
      type="text"
      required={true}
      validate={validate}
      alwaysShowError={alwaysShowError}
    />
  );
};
