import { Controller } from 'react-hook-form';
import { GetCorporateResponse } from '@creditornot/wd-api-client';
import { Alert } from '@creditornot/cb-alert';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { useIntl, IntlFormatters } from 'react-intl';
import { ReactNode, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { env } from 'config';
import { RadioInputCardGroup, FormViewText } from 'components';
import { ReactHookInput } from 'components/ReactHookInput';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { processError } from 'utils';
import { FormCard, FormRow, FormSaveCancelButtons, FormStates } from 'components/FormCard';
import { useWoltForm } from 'modules/react-hook-form/useWoltForm';

import { DeliveryPreferenceFormValues } from './types';

const deliveryPreferenceYup: (
  t: IntlFormatters<ReactNode>,
) => yup.Schema<DeliveryPreferenceFormValues> = (t) => {
  return yup.object().shape(
    {
      isHandshakeRequired: yup
        .boolean()
        .when('isNoContactDelivery', ([isNoContactDelivery]) => {
          return isNoContactDelivery ? yup.boolean().oneOf([false]) : yup.boolean();
        })
        .required(t.formatMessage({ id: 'validation.is_required' })),
      isIdCheckRequired: yup
        .boolean()
        .when('isNoContactDelivery', ([isNoContactDelivery]) => {
          return isNoContactDelivery ? yup.boolean().oneOf([false]) : yup.boolean();
        })
        .required(t.formatMessage({ id: 'validation.is_required' })),
      isNoContactDelivery: yup
        .boolean()
        .when(
          ['isIdCheckRequired', 'isHandshakeRequired'],
          ([isIdCheckRequired, isHandshakeRequired]) => {
            return isIdCheckRequired || isHandshakeRequired
              ? yup.boolean().oneOf([false])
              : yup.boolean();
          },
        )
        .required(t.formatMessage({ id: 'validation.is_required' })),
      corporateLabel: yup.string().default(''),
    },
    [
      ['isIdCheckRequired', 'isNoContactDelivery'],
      ['isNoContactDelivery', 'isHandshakeRequired'],
    ],
  );
};

const Subtitle = styled.div`
  ${typographyCss.Body3()};
  margin-bottom: 12px;
`;

const FormAlert = styled(Alert)`
  margin: 0;
`;

const EmployeeAlert = styled(Alert)<{ bigMargin?: boolean }>`
  margin-top: ${({ bigMargin }) => (bigMargin ? '24px' : '0')};
`;

const StyledFormRow = styled(FormRow)<{ bigMargin?: boolean }>`
  margin-bottom: ${({ bigMargin }) => bigMargin && '24px'};
`;

export const DeliveryPreferenceForm = ({
  selfServiceCorporate,
  renderState,
  countryCode,
  onSubmit,
  onRenderStateChange,
}: {
  selfServiceCorporate: GetCorporateResponse;
  renderState: FormStates;
  countryCode: string;
  onSubmit: (values: DeliveryPreferenceFormValues) => Promise<void>;
  onRenderStateChange: (state: FormStates) => void;
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const isHandshakeEnabledCountry =
    env.HANDSHAKE_ENABLED_COUNTRIES.split(',').includes(countryCode);

  const [apiError, setApiError] = useState<unknown | null>(null);

  const {
    control,
    register,
    formState: { errors, isDirty, isSubmitting },
    watch,
    reset,
    handleSubmit,
  } = useWoltForm<DeliveryPreferenceFormValues>({
    defaultValues: {
      isIdCheckRequired: selfServiceCorporate.isIdCheckRequired,
      isNoContactDelivery: selfServiceCorporate.isNoContactDelivery,
      isHandshakeRequired: selfServiceCorporate.isHandshakeRequired,
      corporateLabel: selfServiceCorporate.label || '',
    },
    resolver: yupResolver(deliveryPreferenceYup(intl)),
  });
  const { isWoltEmployeeWithUpdate } = useIsUserWoltEmployee();

  const options = [
    {
      label: formatMessage({
        id: 'common.optional',
      }),
      value: 'optional',
    },
    {
      label: formatMessage({
        id: 'common.required',
      }),
      value: 'required',
    },
  ];

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        setApiError(null);
        try {
          await onSubmit(data);
          reset(data);
          setApiError(null);
        } catch (error) {
          setApiError(processError(error));
        }
      })}
    >
      <FormCard
        title={formatMessage({ id: 'deliveries.settings-form.dropoff-requirements' })}
        renderState={renderState}
        onRenderStateChange={onRenderStateChange}
      >
        {isHandshakeEnabledCountry && (
          <StyledFormRow
            bigMargin={renderState === 'edit'}
            title={formatMessage({
              id: 'deliveries.settings-form.dropoff-requirements.handshake-title',
            })}
            subtitle={formatMessage({
              id: 'deliveries.settings-form.dropoff-requirements.handshake-subtitle',
            })}
            subtitleOnlyForEdit
          >
            <FormRow.ViewMode>
              <FormViewText>
                {selfServiceCorporate.isHandshakeRequired
                  ? formatMessage({
                      id: 'common.required',
                    })
                  : formatMessage({
                      id: 'common.optional',
                    })}
              </FormViewText>
            </FormRow.ViewMode>
            <FormRow.EditMode>
              <Controller
                name="isHandshakeRequired"
                control={control}
                render={({ field: { onChange, value, name, onBlur } }) => (
                  <>
                    <Subtitle>
                      {formatMessage({
                        id: 'deliveries.settings-form.dropoff-requirements.preference-question',
                      })}
                    </Subtitle>
                    <RadioInputCardGroup
                      name={name}
                      value={value ? 'required' : 'optional'}
                      onBlur={onBlur}
                      disabled={watch('isNoContactDelivery') && !value}
                      onChange={() => {
                        onChange(value ? false : true);
                      }}
                      stretched
                      direction="row"
                      options={options}
                    />
                  </>
                )}
              />
            </FormRow.EditMode>
          </StyledFormRow>
        )}
        <StyledFormRow
          bigMargin={renderState === 'edit'}
          title={formatMessage({
            id: 'deliveries.settings-form.dropoff-requirements.age-check-title',
          })}
          subtitle={formatMessage({
            id: 'deliveries.settings-form.dropoff-requirements.age-check-subtitle',
          })}
          subtitleOnlyForEdit
        >
          <FormRow.ViewMode>
            <FormViewText>
              {selfServiceCorporate.isIdCheckRequired
                ? formatMessage({
                    id: 'common.required',
                  })
                : formatMessage({
                    id: 'common.optional',
                  })}
            </FormViewText>
          </FormRow.ViewMode>
          <FormRow.EditMode>
            <Controller
              name="isIdCheckRequired"
              control={control}
              render={({ field: { onChange, value, name, onBlur } }) => (
                <>
                  <Subtitle>
                    {formatMessage({
                      id: 'deliveries.settings-form.dropoff-requirements.preference-question',
                    })}
                  </Subtitle>
                  <RadioInputCardGroup
                    name={name}
                    value={value ? 'required' : 'optional'}
                    onBlur={onBlur}
                    disabled={watch('isNoContactDelivery') && !value}
                    onChange={() => {
                      onChange(value ? false : true);
                    }}
                    stretched
                    direction="row"
                    options={options}
                  />
                </>
              )}
            />
          </FormRow.EditMode>
        </StyledFormRow>
        <StyledFormRow
          title={formatMessage({
            id: 'deliveries.settings-form.delivery-preferences.no-contact-title',
          })}
          bigMargin={renderState === 'edit'}
          subtitle={formatMessage({
            id: 'deliveries.settings-form.dropoff-requirements.no-contact-subtitle',
          })}
          subtitleOnlyForEdit
        >
          <FormRow.ViewMode>
            <FormViewText>
              {selfServiceCorporate.isNoContactDelivery
                ? formatMessage({
                    id: 'common.required',
                  })
                : formatMessage({
                    id: 'common.optional',
                  })}
            </FormViewText>
          </FormRow.ViewMode>

          <FormRow.EditMode>
            <Controller
              name="isNoContactDelivery"
              control={control}
              render={({ field: { onChange, value, name, onBlur } }) => (
                <>
                  <Subtitle>
                    {formatMessage({
                      id: 'deliveries.settings-form.dropoff-requirements.preference-question',
                    })}
                  </Subtitle>
                  <RadioInputCardGroup
                    name={name}
                    value={value ? 'required' : 'optional'}
                    onBlur={onBlur}
                    disabled={
                      watch('isIdCheckRequired') || (watch('isHandshakeRequired') && !value)
                    }
                    onChange={(change) => {
                      onChange(change.target.value === 'required' ? true : false);
                    }}
                    stretched
                    direction="row"
                    options={options}
                  />
                </>
              )}
            />
          </FormRow.EditMode>
        </StyledFormRow>
        <FormRow>
          <FormRow.EditMode>
            <FormAlert variant="info" size="small">
              {formatMessage({
                id: 'deliveries.settings-form.dropoff-requirements.info',
              })}
            </FormAlert>
          </FormRow.EditMode>
        </FormRow>
        {isWoltEmployeeWithUpdate && (
          <>
            <EmployeeAlert variant="info" size="small" bigMargin={renderState === 'edit'}>
              {formatMessage({ id: 'corporate-form.employee-disclaimer' })}
            </EmployeeAlert>
            <FormRow
              title={formatMessage({
                id: 'deliveries.settings-form.delivery-preferences.display-name',
              })}
              subtitle={formatMessage({
                id: 'deliveries.settings-form.delivery-preferences.display-name-hint',
              })}
              subtitleOnlyForEdit
            >
              <FormRow.ViewMode>
                <FormViewText>{selfServiceCorporate.label || '-'}</FormViewText>
              </FormRow.ViewMode>
              <FormRow.EditMode>
                <ReactHookInput
                  register={register('corporateLabel')}
                  errorMessage={errors.corporateLabel?.message}
                />
              </FormRow.EditMode>
            </FormRow>
          </>
        )}
        {!!apiError && (
          <Alert variant="error" size="medium" title={processError(apiError).message}>
            {processError(apiError).data}
          </Alert>
        )}
        <FormRow>
          <FormRow.EditMode>
            <FormSaveCancelButtons
              dirty={isDirty}
              isSubmitting={isSubmitting}
              onCancelClick={() => {
                setApiError(null);
                onRenderStateChange('view');
                reset();
              }}
            />
          </FormRow.EditMode>
        </FormRow>
      </FormCard>
    </form>
  );
};
