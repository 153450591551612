import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { createEnumParam } from 'serialize-query-params';

import { useQueryParam } from 'modules/react-query-params';
import { Corporate, CorporateV2 } from 'modules/corporates/types';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { companySettingsView } from 'telemetry/Avo';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';

import { CompanyInformationContainer } from './CompanyInformationContainer';
import {
  ActivateNewCorporateAccountModal,
  DeleteCorporateAccountModal,
  DisableCorporateAccountModal,
  ReactivateCorporateAccountModal,
} from '../CompanySettings/CorporateAccount';
import { ContactPersonContainer } from './ContactPersonContainer';
import { InvoicesAndReceiptsContainer } from './InvoicesAndReceiptsContainer';
import { CorporateAccountContainer } from './CorporateAccountContainer';
import { DriveConfigContainer } from './DriveConfigContainer';
import { ServiceAgreementContainer } from './ServiceAgreementContainer';
import { ConsentsContainer } from './ConsentsContainer';
import { DiscountsContainer } from './DiscountsContainer';

export type CompanySettingsProps = {
  corporate: Corporate;
  corporateV2: CorporateV2 | undefined;
};

const Root = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const CompanySettingsV2 = ({ corporate, corporateV2 }: CompanySettingsProps) => {
  const [actionQuery, setActionQuery] = useQueryParam(
    'action',
    createEnumParam([
      'deleteCorporate',
      'disableCorporate',
      'reactivateCorporate',
      'activateNewCorporate',
    ]),
  );
  const { isWoltEmployeeWithUpdate } = useIsUserWoltEmployee();
  const history = useHistory();
  const handleDeleteCorporateSuccess = useCallback(() => {
    history.replace('/');
  }, [history]);
  const handleCloseCorporateModal = useCallback(() => setActionQuery(undefined), [setActionQuery]);
  const handleReactivateClick = useCallback(
    () => setActionQuery('reactivateCorporate'),
    [setActionQuery],
  );
  const handleActivateNewClick = useCallback(
    () => setActionQuery('activateNewCorporate'),
    [setActionQuery],
  );
  const handleDeleteClick = useCallback(() => setActionQuery('deleteCorporate'), [setActionQuery]);
  const handleDisableClick = useCallback(
    () => setActionQuery('disableCorporate'),
    [setActionQuery],
  );

  return (
    <Root>
      <ViewEventComponent event={companySettingsView} />
      <CompanyInformationContainer corporate={corporate} />
      <ContactPersonContainer corporate={corporate} />
      {corporate.products.daas_delivery_ui_enabled && isWoltEmployeeWithUpdate && (
        <DriveConfigContainer corporate={corporate} />
      )}
      <InvoicesAndReceiptsContainer corporate={corporate} />
      {corporateV2 && <DiscountsContainer corporate={corporate} corporateV2={corporateV2} />}
      <ServiceAgreementContainer />
      <ConsentsContainer />
      {isWoltEmployeeWithUpdate && (
        <>
          <CorporateAccountContainer
            corporate={corporate}
            onReactivateClick={handleReactivateClick}
            onActivateNewClick={handleActivateNewClick}
            onDeleteClick={handleDeleteClick}
            onDisableClick={handleDisableClick}
          />
          <DeleteCorporateAccountModal
            show={actionQuery === 'deleteCorporate'}
            corporate={corporate}
            onClose={handleCloseCorporateModal}
            onSuccess={handleDeleteCorporateSuccess}
          />
          <DisableCorporateAccountModal
            show={actionQuery === 'disableCorporate'}
            corporate={corporate}
            onClose={handleCloseCorporateModal}
            onSuccess={handleCloseCorporateModal}
          />
          <ReactivateCorporateAccountModal
            show={actionQuery === 'reactivateCorporate'}
            corporate={corporate}
            onClose={handleCloseCorporateModal}
            onSuccess={handleCloseCorporateModal}
          />
          <ActivateNewCorporateAccountModal
            show={actionQuery === 'activateNewCorporate'}
            corporate={corporate}
            onClose={handleCloseCorporateModal}
            onSuccess={handleCloseCorporateModal}
          />
        </>
      )}
    </Root>
  );
};
