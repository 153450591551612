import { color } from '@creditornot/cb-ingredients/design-tokens';
import { DataTable } from '@creditornot/cb-data-table';
import styled from 'styled-components';
import { Tag } from '@creditornot/cb-tag';
import { FormattedDate, useIntl } from 'react-intl';

import { DataCellContent } from 'components';
import { LocalizedNumber } from 'i18n';
import InvoiceIcon from 'assets/images/invoice-icon.svg';
import { useCorporate } from 'modules/corporates';
import { VenuePayout } from 'modules/merchantPayouts/types';

import { ReportsDropdownActions } from './ReportsDropdownActions';
import { getStatus } from '../utils';

type Props = {
  reportsData: VenuePayout[];
  loading: boolean;
  corporateId: string;
};

const TableContainer = styled.div`
  border-bottom: 1px solid ${color.border};
`;

export const ReportsDataTable = ({ reportsData, loading, corporateId }: Props) => {
  const { formatMessage } = useIntl();
  const { data: corporate } = useCorporate();

  return (
    <TableContainer>
      <DataTable
        dataSource={reportsData}
        horizontalPadding="wide"
        loading={loading}
        getRowKey={(row) => row.id}
        emptyMessage={{
          icon: <InvoiceIcon />,
          description: formatMessage({ id: 'reports.no-report-explanation' }),
          title: formatMessage({ id: 'reports.no-report' }),
        }}
        columns={[
          {
            id: 'name',
            name: formatMessage({ id: 'reports.head.name' }),
            render: ({ record }) => (
              <DataCellContent>
                Sales Report {record.interval.replace('_', ' ')} {record.intervalNumber}
              </DataCellContent>
            ),
          },
          {
            id: 'period',
            name: formatMessage({ id: 'reports.head.period' }),
            render: ({ record }) => (
              <DataCellContent>
                <FormattedDate value={record.startDate} />
                {' – '}
                <FormattedDate value={record.endDate} />
              </DataCellContent>
            ),
          },
          {
            id: 'orders',
            name: formatMessage({ id: 'reports.head.orders' }),
            render: ({ record }) => <DataCellContent>{record.numberOfPurchases}</DataCellContent>,
          },
          {
            id: 'amount',
            name: formatMessage({ id: 'reports.head.recieve' }),
            render: ({ record }) => (
              <div>
                <LocalizedNumber value={record.payoutAmount} currency={record.currency} />
              </div>
            ),
          },
          {
            id: 'bank_account',
            name: formatMessage({ id: 'reports.head.bank-account' }),
            render: ({ record }) => (
              <DataCellContent>
                {record.toAccount ? `****${record.toAccount}` : '-'}
              </DataCellContent>
            ),
          },
          {
            id: 'status',
            name: formatMessage({ id: 'common.status' }),
            render: ({ record }) => (
              <DataCellContent>
                <Tag variant={getStatus(record.transferStatus).color}>
                  {formatMessage({
                    id: `reports.status.${getStatus(record.transferStatus).key}`,
                  })}
                </Tag>
              </DataCellContent>
            ),
          },
          {
            id: 'actions',
            name: '',
            style: {
              width: '5%',
            },
            render: ({ record }) => (
              <ReportsDropdownActions
                report={record}
                corporateId={corporateId}
                isJapanese={corporate?.country === 'JPN'}
              />
            ),
          },
        ]}
      />
    </TableContainer>
  );
};
