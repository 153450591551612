import styled from 'styled-components';

import CompanyDetailsFormSection from './CompanyDetailsFormSection';
import WoltBankAccountFormSection from './WoltBankAccountFormSection';
import NetsuiteConfigFormSection from './NetsuiteConfigFormSection';
import { InvoiceDetailsFormSection } from './InvoiceDetailsFormSection';
import { EnabledProductsFormSection } from './EnabledProductsFormSection';

interface Props {
  alwaysShowError?: boolean;
  className?: string;
  currency?: string;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CountryConfigFormFields = ({ alwaysShowError, className, currency }: Props) => {
  return (
    <Root className={className}>
      <CompanyDetailsFormSection alwaysShowError={alwaysShowError} />
      <EnabledProductsFormSection />
      <WoltBankAccountFormSection alwaysShowError={alwaysShowError} />
      <InvoiceDetailsFormSection alwaysShowError={alwaysShowError} currency={currency} />
      <NetsuiteConfigFormSection alwaysShowError={alwaysShowError} />
    </Root>
  );
};
