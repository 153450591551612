import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { Anchor, InformationDisplay } from 'components';
import { env } from 'config';
import { OrderDetails } from 'modules/orders/types';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';
import { ordersInteractionVenue } from 'telemetry/Avo';

type OrderDetailsInformationProps = {
  order: OrderDetails;
  isWoltEmployee: boolean;
};

const localizedDeliverymethods = {
  homedelivery: <FormattedMessage id="common.home-delivery" />,
  takeaway: <FormattedMessage id="common.take-away" />,
  eatin: <FormattedMessage id="common.eat-in" />,
};

export const OrderDetailsInformation = ({
  order,
  isWoltEmployee,
}: OrderDetailsInformationProps) => {
  const ordersInteractionVenueEvent = useTelemetryInteractionEvent(ordersInteractionVenue);
  return (
    <>
      <InformationDisplay labelColor="black" label={<FormattedMessage id="common.venue" />}>
        {isWoltEmployee ? (
          <Anchor
            target="_blank"
            rel="noopener noreferrer"
            href={`${env.OPS_TOOLS_URI}/restaurants/${order.venue.id}`}
          >
            {order.venue.name}
          </Anchor>
        ) : (
          <Anchor
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => ordersInteractionVenueEvent({ venueId: order.venue.id })}
            href={`${env.WOLT_COM_URI}/en/${order.venue.address.country}/${order.venue.address.city}/restaurant/${order.venue.slug}`}
          >
            {order.venue.name}
          </Anchor>
        )}
      </InformationDisplay>
      <InformationDisplay
        labelColor="black"
        label={<FormattedMessage id="orders.details.order-placed" />}
      >
        <FormattedDate
          value={new Date(order.venue_time_received)}
          hour="2-digit"
          minute="2-digit"
          day="2-digit"
          month="2-digit"
          year="numeric"
        />
      </InformationDisplay>
      <InformationDisplay
        labelColor="black"
        label={<FormattedMessage id="orders.details.delivery-method" />}
      >
        {localizedDeliverymethods[order.delivery_method]}
      </InformationDisplay>
      <InformationDisplay
        labelColor="black"
        label={<FormattedMessage id="orders.details.order-delivered" />}
      >
        {order.venue_time_delivered ? (
          <FormattedDate
            value={new Date(order.venue_time_delivered)}
            hour="2-digit"
            minute="2-digit"
            day="2-digit"
            month="2-digit"
            year="numeric"
          />
        ) : (
          '-'
        )}
      </InformationDisplay>
    </>
  );
};
