import { FormRow, InputContainer } from '@creditornot/cb-form';
import styled from 'styled-components';

import { FormikInputField } from 'components';
import { createValidator, isRequired } from 'validation';

import CountrySelectField from './CountrySelectField';

const validate = createValidator([isRequired]);

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: 24px;
  }
`;

const GeneralInfomationFormSection = () => {
  return (
    <FormRow required label="General infomation">
      <StyledInputContainer label="Legal name">
        <FormikInputField validate={validate} name="legal_name" />
      </StyledInputContainer>

      <StyledInputContainer label="Business id">
        <FormikInputField validate={validate} name="business_id" />
      </StyledInputContainer>
      <StyledInputContainer label="Country code">
        <CountrySelectField name="country_code" validate={validate} />
      </StyledInputContainer>
    </FormRow>
  );
};

export default GeneralInfomationFormSection;
