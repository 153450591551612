import { useHistory } from 'react-router';

import { event } from 'modules/google-analytics';
import { LocalizedMessage } from 'i18n';
import { useCorporate } from 'modules/corporates';
import { TourConfig } from 'modules/product-tour/types';
import { StyledLink } from 'components';
import { setLocalStorageItem } from 'modules/hooks';

import { OnboardingTooltip } from './OnboardingTooltip';
import {
  CREATE_GROUP_BUTTON_SELECTOR,
  CREATE_GROUP_MODAL_SELECTOR,
  CREATE_POLICY_BUTTON_SELECTOR,
  CREATE_POLICY_MODAL_SELECTOR,
  GROUPS_TAB_SELECTOR,
  INVITE_USERS_BUTTON_SELECTOR,
  POLICIES_TAB_SELECTOR,
  USER_GUIDE_SELECTOR,
  USER_INVITE_MODAL_SELECTOR,
  WELCOME_MODAL_SELECTOR,
} from './selectors';

export const useOnboardingConfig = () => {
  const history = useHistory();

  const { data: corporate } = useCorporate();

  const totalSteps = 6;

  const gotoPoliciesView = () => history.push(`/${corporate?.id}/policies`);
  const gotoGroupsView = () => history.push(`/${corporate?.id}/groups`);
  const gotoOrdersView = () => history.push(`/${corporate?.id}/orders`);

  const config: TourConfig[] = [
    {
      selector: WELCOME_MODAL_SELECTOR,
      isInteractable: true,
      disableBackdrop: true,
    },
    {
      selector: INVITE_USERS_BUTTON_SELECTOR,
      placement: 'bottom-end',
      renderTooltip: ({ gotoStep }) => (
        <OnboardingTooltip
          title={<LocalizedMessage messageKey="product-tour.invite-users-button-tooltip" />}
          currentStep={1}
          totalSteps={totalSteps}
          onNext={() => {
            gotoStep(POLICIES_TAB_SELECTOR);

            event({
              category: 'new_user_onboarding',
              action: 'next',
              label: 'invite_user_step',
            });
          }}
          onClose={() => {
            gotoOrdersView();
            gotoStep(USER_GUIDE_SELECTOR);

            event({
              category: 'new_user_onboarding',
              action: 'exit',
              label: 'invite_user_step',
            });
          }}
        >
          <LocalizedMessage
            messageKey="product-tour.users.invite-button-description"
            values={{
              'styled-link': (msg: string) => (
                <StyledLink
                  onClick={() => {
                    gotoStep(USER_INVITE_MODAL_SELECTOR);

                    event({
                      category: 'new_user_onboarding',
                      action: 'try_feature',
                      label: 'invite_user_step',
                    });
                  }}
                  to={`${history.location.pathname}/invite`}
                >
                  {msg}
                </StyledLink>
              ),
            }}
          />
        </OnboardingTooltip>
      ),
    },
    {
      selector: USER_INVITE_MODAL_SELECTOR,
      isInteractable: true,
    },
    {
      selector: POLICIES_TAB_SELECTOR,
      placement: 'bottom-end',
      renderTooltip: ({ gotoStep }) => (
        <OnboardingTooltip
          title={<LocalizedMessage messageKey="product-tour.policies-tab" />}
          currentStep={2}
          totalSteps={totalSteps}
          onNext={() => {
            gotoPoliciesView();
            gotoStep(CREATE_POLICY_BUTTON_SELECTOR);

            event({
              category: 'new_user_onboarding',
              action: 'next',
              label: 'policy_tab',
            });
          }}
          onClose={() => {
            gotoOrdersView();
            gotoStep(USER_GUIDE_SELECTOR);

            event({
              category: 'new_user_onboarding',
              action: 'exit',
              label: 'policy_tab',
            });
          }}
        >
          <LocalizedMessage messageKey="product-tour.policies-tab-description" />
        </OnboardingTooltip>
      ),
    },
    {
      selector: CREATE_POLICY_BUTTON_SELECTOR,
      placement: 'bottom-end',
      renderTooltip: ({ gotoStep }) => (
        <OnboardingTooltip
          title={<LocalizedMessage messageKey="product-tour.create-policy-button-tooltip" />}
          currentStep={3}
          totalSteps={totalSteps}
          onNext={() => {
            gotoStep(GROUPS_TAB_SELECTOR);

            event({
              category: 'new_user_onboarding',
              action: 'next',
              label: 'create_policy_step',
            });
          }}
          onClose={() => {
            gotoOrdersView();
            gotoStep(USER_GUIDE_SELECTOR);

            event({
              category: 'new_user_onboarding',
              action: 'exit',
              label: 'create_policy_step',
            });
          }}
        >
          <LocalizedMessage
            messageKey="product-tour.policies.create-policy-button-description"
            values={{
              'styled-link': (msg: string) => (
                <StyledLink
                  onClick={() => {
                    gotoStep(CREATE_POLICY_MODAL_SELECTOR);

                    event({
                      category: 'new_user_onboarding',
                      action: 'try_feature',
                      label: 'create_policy_step',
                    });
                  }}
                  to={`${history.location.pathname}/new`}
                >
                  {msg}
                </StyledLink>
              ),
            }}
          />
        </OnboardingTooltip>
      ),
    },
    {
      selector: CREATE_POLICY_MODAL_SELECTOR,
      isInteractable: true,
    },
    {
      selector: GROUPS_TAB_SELECTOR,
      placement: 'bottom-start',
      renderTooltip: ({ gotoStep }) => (
        <OnboardingTooltip
          title={<LocalizedMessage messageKey="product-tour.groups-tab" />}
          currentStep={4}
          totalSteps={totalSteps}
          onNext={() => {
            gotoGroupsView();
            gotoStep(CREATE_GROUP_BUTTON_SELECTOR);

            event({
              category: 'new_user_onboarding',
              action: 'next',
              label: 'group_tab',
            });
          }}
          onClose={() => {
            gotoOrdersView();
            gotoStep(USER_GUIDE_SELECTOR);

            event({
              category: 'new_user_onboarding',
              action: 'exit',
              label: 'group_tab',
            });
          }}
        >
          <LocalizedMessage messageKey="product-tour.groups-tab-description" />
        </OnboardingTooltip>
      ),
    },
    {
      selector: CREATE_GROUP_BUTTON_SELECTOR,
      placement: 'bottom-end',
      renderTooltip: ({ gotoStep }) => (
        <OnboardingTooltip
          title={<LocalizedMessage messageKey="product-tour.create-group-button-tooltip" />}
          currentStep={5}
          totalSteps={totalSteps}
          onNext={() => {
            gotoOrdersView();
            gotoStep(USER_GUIDE_SELECTOR);

            event({
              category: 'new_user_onboarding',
              action: 'next',
              label: 'create_group_step',
            });
          }}
          onClose={() => {
            gotoOrdersView();
            gotoStep(USER_GUIDE_SELECTOR);

            event({
              category: 'new_user_onboarding',
              action: 'exit',
              label: 'create_group_step',
            });
          }}
        >
          <LocalizedMessage
            messageKey="product-tour.groups.create-group-button-description"
            values={{
              'styled-link': (msg: string) => (
                <StyledLink
                  onClick={() => {
                    gotoStep(CREATE_GROUP_MODAL_SELECTOR);

                    event({
                      category: 'new_user_onboarding',
                      action: 'try_feature',
                      label: 'create_group_step',
                    });
                  }}
                  to={`${history.location.pathname}/create-group`}
                >
                  {msg}
                </StyledLink>
              ),
            }}
          />
        </OnboardingTooltip>
      ),
    },
    {
      selector: CREATE_GROUP_MODAL_SELECTOR,
      isInteractable: true,
    },
    {
      selector: USER_GUIDE_SELECTOR,
      placement: 'bottom-end',
      renderTooltip: ({ end }) => (
        <OnboardingTooltip
          title={<LocalizedMessage messageKey="product-tour.user-guide-tooltip" />}
          currentStep={6}
          totalSteps={totalSteps}
          onDone={() => {
            setLocalStorageItem('isFirstTimeUser', false);
            end();

            event({
              category: 'new_user_onboarding',
              action: 'next',
              label: 'user_guide_step',
            });
          }}
          onClose={() => {
            setLocalStorageItem('isFirstTimeUser', false);
            end();

            event({
              category: 'new_user_onboarding',
              action: 'exit',
              label: 'user_guide_step',
            });
          }}
        >
          <LocalizedMessage messageKey="product-tour.orders.user-guide-description" />
        </OnboardingTooltip>
      ),
    },
  ];

  return config;
};
