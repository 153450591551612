import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { FileInput } from 'components';
import { useFileReader } from 'modules/hooks';
import {
  emailParser,
  getFormikFieldError,
  isCsvFile,
  isFileExtensionValid,
  isXlsxFile,
} from 'validation';
import { type MessageIds } from 'i18n';

import TagsContainer from './TagsContainer';

interface FormikEmailFileInputProps {
  name: string;
  allowedExtensions?: string;
  className?: string;
  validateFile?: (value: File) => MessageIds | undefined;
  validate?: (value: string[] | null) => string | void | Promise<string | void>;
  validateEmail?: (value: string, index: number, values: string[]) => string | undefined;
}

const ErrorMessage = styled.div`
  margin-top: 2px;
  font-size: 12px;
  color: ${color.textNegative};
`;

const FormikEmailFileInput = ({
  className,
  name,
  allowedExtensions = '.csv,.xlsx',
  validateFile = isFileExtensionValid('.csv,.xlsx'),
  validate,
  validateEmail,
}: FormikEmailFileInputProps) => {
  const [file, setFile] = useState<File | null>(null);
  const { formatMessage } = useIntl();
  const { readXlsx, isParsing, readCsv } = useFileReader();
  const [{ value, onBlur }, meta, { setValue, setTouched }] = useField<string[] | null>({
    name,
    validate,
  });
  const form = useFormikContext();
  const formikValidationError = getFormikFieldError(meta);

  const invalidFileError = file && validateFile ? validateFile(file) : undefined;

  const invalidFileErrorMessage =
    !!(file && invalidFileError) && formatMessage({ id: invalidFileError });

  const formikValidationErrorMessage =
    isParsing || !formikValidationError?.messageKey
      ? undefined
      : formatMessage({ id: formikValidationError.messageKey }, formikValidationError.values);

  const invalidEmails =
    value && validateEmail ? value.filter((...args) => !!validateEmail(...args)) : undefined;

  return (
    <div className={className}>
      <FileInput
        disabled={isParsing}
        accept={allowedExtensions}
        value={file}
        onChange={(inputFile) => {
          setValue(null);

          setFile(inputFile);

          if (isCsvFile(inputFile)) {
            readCsv(inputFile)
              .then((csv) => setValue(csv.filter(Boolean).map(emailParser)))
              .catch(() => form.setFieldError(name, 'users.new-user-modal.error-parsing-file'));
            return;
          }

          if (isXlsxFile(inputFile)) {
            readXlsx(inputFile)
              .then((xlsx) => {
                const emailColumnIndex = xlsx.reduce((acc, row) => {
                  const colIndex = row.findIndex(
                    (col) => col && col.toLocaleLowerCase() === 'email',
                  );
                  return colIndex === -1 ? acc : colIndex;
                }, -1);

                if (emailColumnIndex === -1) {
                  form.setFieldError(name, 'users.new-user-modal.no-email-column-found');
                  return;
                }

                setValue(
                  xlsx
                    .map((row) => row[emailColumnIndex])
                    .filter((col) => !!col)
                    .filter((col) => col.toLocaleLowerCase() !== 'email')
                    .map(emailParser),
                );
              })
              .catch(() => form.setFieldError(name, 'users.new-user-modal.error-parsing-file'));
          }
        }}
        onClear={() => {
          setValue(null);
          setFile(null);
        }}
        onBlur={() => {
          onBlur(name);
          setTouched(true, true);
        }}
        invalid={!!invalidFileErrorMessage || !!formikValidationErrorMessage}
      >
        <TagsContainer
          handleClearClick={(_, index) => {
            setValue(value ? [...value.slice(0, index), ...value.slice(index + 1)] : null);
          }}
          tagValues={
            invalidEmails
              ? value?.sort((email: string) => (invalidEmails.includes(email) ? -1 : 0))
              : value
          }
          invalidValues={invalidEmails}
        />
      </FileInput>

      {(invalidFileErrorMessage || formikValidationErrorMessage) && (
        <ErrorMessage>{invalidFileErrorMessage || formikValidationErrorMessage}</ErrorMessage>
      )}
    </div>
  );
};

export default FormikEmailFileInput;
