import { useCallback, useEffect } from 'react';

import { useMedia } from 'modules/media';
import {
  NeverNullBooleanParam,
  NeverNullDelimitedArrayParam,
  NeverNullNumberParam,
  NeverNullStringParam,
  useQueryParams,
  WoltPageSizeParam,
} from 'modules/react-query-params';

export const useUsersQueryParams = () => {
  const mediumView = useMedia('medium');
  const query = useQueryParams({
    showInvites: NeverNullBooleanParam,
    search: NeverNullStringParam,
    groups: NeverNullDelimitedArrayParam,
    status: NeverNullDelimitedArrayParam,
    user: NeverNullStringParam,
    users: NeverNullDelimitedArrayParam,
    invite: NeverNullStringParam,
    invites: NeverNullDelimitedArrayParam,
    page: NeverNullNumberParam,
    page_size: WoltPageSizeParam,
  });
  const [params, setReactQueryParams] = query;
  useEffect(() => {
    if (mediumView) {
      setReactQueryParams({
        page_size: 50,
        page: 1,
      });
    }
  }, [mediumView, setReactQueryParams]);
  const setQueryParams: typeof setReactQueryParams = useCallback(
    (queries) => {
      const shouldResetPage =
        'showInvites' in queries ||
        'search' in queries ||
        'groups' in queries ||
        'status' in queries ||
        'page_size' in queries;

      setReactQueryParams(({ page, ...staledQueries }) => ({
        ...staledQueries,
        page: shouldResetPage ? 1 : page,
        ...queries,
      }));
    },
    [setReactQueryParams],
  );
  return [params, setQueryParams] as typeof query;
};
