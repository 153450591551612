import styled from 'styled-components';
import { ElementType } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { breakpoints } from 'modules/media';
import { SideBarNavigation } from 'navigation';
import { FillViewportHeightDiv } from 'components';

const Root = styled(FillViewportHeightDiv)`
  display: flex;
  align-items: stretch;

  @media (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
  }
`;

// 320px is SideBar width
const ComponentContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-width: calc(${breakpoints.medium - 320}px);

  @media (max-width: ${breakpoints.medium}px) {
    min-width: unset;
    padding-top: 60px;
  }
`;

export const RouteWithSideNavbar = ({ component, ...rest }: RouteProps) => {
  const Component = component as ElementType<any>;

  return (
    <Route
      render={(props) => {
        return (
          <Root>
            <SideBarNavigation />
            <ComponentContainer>
              <Component {...props} />
            </ComponentContainer>
          </Root>
        );
      }}
      {...rest}
    />
  );
};
