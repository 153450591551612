import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled, { css, keyframes } from 'styled-components';

import SpinnerIcon from 'assets/images/spinner-3.svg';

interface Props {
  size?: 'small' | 'medium' | 'large' | 'extra-large';
  color?: 'primary' | 'secondary';
}

const smallStyles = css`
  height: 21px;
  width: 21px;
`;

const mediumStyles = css`
  height: 28px;
  width: 28px;
`;

const largeStyles = css`
  height: 45px;
  width: 45px;
`;
const extraLarge = css`
  height: 95px;
  width: 95px;
`;

const getAnimationSpeed = (size?: string) => {
  switch (size) {
    case 'small':
      return '0.8s';
    case 'medium':
      return '1s';
    case 'large':
      return '1.15s';
    case 'extra-large':
      return '1.25s';
  }
};

const rotate = keyframes`
  0% { transform: rotate(-360deg); }
  100% { transform: rotate(0deg); }
`;

const StyledSpinnerIcon = styled(SpinnerIcon)<{ color?: string }>`
  display: inline-block;
  position: relative;
  animation: ${rotate} ${({ size }) => getAnimationSpeed(size)} infinite linear;

  ${(props) =>
    props.color === 'primary' &&
    css`
      & path {
        fill: ${color.textBrand};
      }
    `}

  ${(props: Props) => props.size === 'small' && smallStyles}
  ${(props: Props) => props.size === 'medium' && mediumStyles}
  ${(props: Props) => props.size === 'large' && largeStyles}
  ${(props: Props) => props.size === 'extra-large' && extraLarge}
`;

const Spinner = ({ size = 'small', color = 'primary', ...rest }: Props) => (
  <StyledSpinnerIcon alt="spinner" size={size} color={color} {...rest} />
);

export default Spinner;
