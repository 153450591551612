import { Tag } from '@creditornot/cb-tag';
import { StarFilled } from '@creditornot/cb-icons';

import { LocalizedMessage } from 'i18n';

interface AdminTagProps {
  isDisabled?: boolean;
  className?: string;
}

const AdminTag = ({ isDisabled, className }: AdminTagProps) => {
  return (
    <Tag
      icon={<StarFilled height="10px" />}
      variant={isDisabled ? 'black' : 'blue'}
      className={className}
    >
      <LocalizedMessage messageKey="common.admin" />
    </Tag>
  );
};

export default AdminTag;
