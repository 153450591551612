import React from 'react';
import styled from 'styled-components';
import { Switch, SwitchProps } from '@creditornot/cb-switch';
import { typographyCss } from '@creditornot/cb-ingredients';
import { UseFormRegisterReturn } from 'react-hook-form';
import { color } from '@creditornot/cb-ingredients/design-tokens';

interface Props {
  label?: string;
  className?: string;
  explanation?: string;
  switchProps?: Partial<SwitchProps>;
  register: UseFormRegisterReturn;
  type?: 'string' | 'number';
  fieldId?: string;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label<{ $disabled: boolean }>`
  ${typographyCss.Body3()};
  color: ${(props) => (props.$disabled ? color.textDisabled : 'inherit')};
`;

const Explanation = styled.small`
  ${typographyCss.Small()};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSwitch = styled(Switch)`
  display: inline-block;
`;

export const ReactHookSwitch: React.FC<Props> = ({
  explanation,
  className,
  switchProps,
  label,
  register,
  fieldId,
}) => {
  const registerWithNoRef = { ...register, ref: undefined };
  return (
    <Root className={className}>
      <Container>
        {label && (
          <Label htmlFor={register.name} $disabled={!!switchProps?.disabled}>
            {label}
          </Label>
        )}
        <StyledSwitch
          {...registerWithNoRef}
          ref={register.ref}
          {...switchProps}
          inputProps={{ id: fieldId }}
        />
      </Container>
      {explanation && <Explanation>{explanation}</Explanation>}
    </Root>
  );
};
