import { useHistory } from 'react-router-dom';
import { TextButton } from '@creditornot/cb-button';
import { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { LocalizedMessage, useI18n } from 'i18n';
import { useNotificationToast } from 'modules/app-modals';
import { breakpoints } from 'modules/media';

const addUsersWithoutGroupToastId = 'ADD_USERS_WITHOUT_GROUP_TOAST_ID';

const notificationToastCss = css`
  width: 420px;

  @media (max-width: ${breakpoints.small}px) {
    width: 320px;
  }
`;

const FooterText = styled(LocalizedMessage)`
  display: block;
  margin-top: 8px;
`;

export const useOpenAddUsersWithoutGroupToast = () => {
  const { getLocalizedMessage } = useI18n();
  const history = useHistory();
  const { openNotificationToast, closeNotificationToast } = useNotificationToast();
  return useCallback(
    ({
      corporateId,
      corporateName,
      numberOfUsers,
    }: {
      corporateId: string;
      corporateName: string;
      numberOfUsers: number;
    }) =>
      openNotificationToast(addUsersWithoutGroupToastId, {
        variant: 'info',
        notificationToastStyle: notificationToastCss,
        title: getLocalizedMessage('toast.after-user-invite-title'),
        onCloseButtonClick: () => closeNotificationToast(addUsersWithoutGroupToastId),
        autoCloseIn: 30 * 1000,
        onClose: () => closeNotificationToast(addUsersWithoutGroupToastId),
        children: (
          <div>
            <LocalizedMessage
              messageKey="toast.after-user-invite-content"
              values={{
                numberOfUsers,
                corporateName,
                b: (msg: string) => <b key="toast.after-user-invite-content">{msg}</b>,
              }}
            />
            <FooterText
              messageKey="toast.after-user-invite-footer"
              values={{
                'create-group-link': (msg: string) => (
                  <TextButton
                    onClick={() => {
                      history.push(`/${corporateId}/groups/create-group`);
                      closeNotificationToast(addUsersWithoutGroupToastId);
                    }}
                    key="create-group"
                  >
                    {msg}
                  </TextButton>
                ),
                'add-user-to-group-link': (msg: string) => (
                  <TextButton
                    onClick={() => {
                      history.push(`/${corporateId}/groups`);
                      closeNotificationToast(addUsersWithoutGroupToastId);
                    }}
                    key="goto-groups"
                  >
                    {msg}
                  </TextButton>
                ),
              }}
            />
          </div>
        ),
      }),
    [closeNotificationToast, getLocalizedMessage, history, openNotificationToast],
  );
};
