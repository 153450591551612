import { ComponentProps } from 'react';

import { FormikTextareaField } from 'components';

export const FormikInviteEventUsersCustomMessageTextarea: React.VFC<
  Partial<ComponentProps<typeof FormikTextareaField>>
> = ({ textareaProps, ...props }) => {
  return (
    <FormikTextareaField
      name="customMessage"
      textareaProps={{
        maxLength: 500,
        style: {
          minHeight: '150px',
        },
        ...textareaProps,
      }}
      {...props}
    />
  );
};
