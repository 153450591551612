import { EditArgreementPartyData } from 'modules/agreement-parties';
import { AgreementParty } from 'modules/agreement-parties/types';

import { EditArgreementPartyFormValues } from './types';

export const formatAgreementPartyApiDataToEditFormValues = (
  data: AgreementParty,
): EditArgreementPartyFormValues => ({
  legal_name: data.legal_name,
  business_id: data.business_id,
  bank_accounts: data.bank_accounts,
  address: data.address,
  vats: data.vats,
  country_code: data.country_code,
});

export const formatEditAgreementPartyFormValuesToSubmitData = (
  data: EditArgreementPartyFormValues,
): EditArgreementPartyData => ({
  legal_name: data.legal_name,
  business_id: data.business_id,
  bank_accounts: data.bank_accounts,
  address: data.address,
  vats: data.vats,
  country_code: data.country_code,
});
