import React from 'react';
import styled from 'styled-components';
import { TextButton } from '@creditornot/cb-button';
import { Empty } from '@creditornot/cb-placeholders';

import { LocalizedMessage, useI18n } from 'i18n';

const Container = styled.div`
  padding: 24px;
`;

interface EventUsersNoParticipantsProps {
  onInviteClick?: () => void;
  isSearching: boolean;
  isFiltering: boolean;
  isEndedEvent: boolean;
}

export const EventUsersNoParticipants = (props: EventUsersNoParticipantsProps) => {
  const { onInviteClick, isSearching, isFiltering, isEndedEvent } = props;
  const { getLocalizedMessage } = useI18n();

  return (
    <Container>
      {isSearching ? (
        <Empty title={getLocalizedMessage('events.participants.no-participants-found')} />
      ) : isFiltering ? (
        <Empty title={getLocalizedMessage('events.participants.no-participants-found-status')} />
      ) : isEndedEvent ? (
        <Empty
          title={getLocalizedMessage('events.participants.no-participants-ended-event')}
          description={getLocalizedMessage(
            'events.participants.no-participants-ended-event-explanation',
          )}
        />
      ) : (
        <Empty
          title={getLocalizedMessage('events.participants.no-participants')}
          description={getLocalizedMessage('events.participants.no-participants-explanation')}
          footer={
            <TextButton onClick={onInviteClick}>
              <LocalizedMessage messageKey="events.participants.invite-participant-button" />
            </TextButton>
          }
        />
      )}
    </Container>
  );
};
