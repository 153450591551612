import { useCallback } from 'react';

import {
  NeverNullArrayParam,
  NeverNullStringParam,
  useQueryParams,
} from 'modules/react-query-params';

export const useReportsQueryParams = () => {
  const query = useQueryParams({
    status: NeverNullArrayParam,
    tabName: NeverNullStringParam,
  });
  const [params, setReactQueryParams] = query;
  const setQueryParams: typeof setReactQueryParams = useCallback(
    (queries) => {
      setReactQueryParams(({ ...staledQueries }) => ({
        ...staledQueries,
        ...queries,
      }));
    },
    [setReactQueryParams],
  );
  return [params, setQueryParams] as typeof query;
};
