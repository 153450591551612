import React from 'react';
import { Modal, ModalProps } from '@creditornot/cbt-modal';

export interface ModalV2Props extends ModalProps {}

const defaultProps: Partial<ModalV2Props> = {
  disableContentPadding: true,
  animateOnMount: true,
};

export const ModalV2 = ({ children, ...props }: ModalV2Props) => {
  return (
    <Modal {...defaultProps} {...props}>
      {children}
    </Modal>
  );
};
