import styled from 'styled-components';
import { Field, FieldProps } from 'formik';
import { Select } from '@creditornot/cb-select';

import { useI18n } from 'i18n';

const Root = styled.div`
  flex: 1;
`;

const PolicySpendingLimitTimePeriodField = () => {
  const { getLocalizedMessage } = useI18n();

  return (
    <Root data-test-id="policy.spending-limit-period-select">
      <Field name="spending_limit_time_period">
        {({ field, form }: FieldProps) => {
          return (
            <Select
              inputId="spending_limit_time_period"
              value={field.value}
              options={[
                { label: getLocalizedMessage('common.per-day'), value: 'day' },
                { label: getLocalizedMessage('common.per-week'), value: 'week' },
                { label: getLocalizedMessage('common.per-month'), value: 'month' },
                { label: getLocalizedMessage('common.per-quarter'), value: 'quarter' },
                { label: getLocalizedMessage('common.per-year'), value: 'year' },
              ]}
              onChange={(value) => form.setFieldValue('spending_limit_time_period', value)}
            />
          );
        }}
      </Field>
    </Root>
  );
};

export default PolicySpendingLimitTimePeriodField;
