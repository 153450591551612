import { useEffect, useRef, useState } from 'react';

export const useDebounce = <T,>(value: T, delay: number | null) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const handlerRef = useRef(setInterval(() => {}, 0));

  const textValue = JSON.stringify(value);

  useEffect(() => {
    if (!delay) {
      return () => clearTimeout(handlerRef.current);
    }
    handlerRef.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handlerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue, delay]);

  return !delay ? value : debouncedValue;
};
