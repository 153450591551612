import { ModalHeader } from '@creditornot/cbt-modal';
import { useCallback } from 'react';
import { Alert } from '@creditornot/cb-alert';
import styled, { css } from 'styled-components';

import { LocalizedMessage } from 'i18n';
import { useAppModals } from 'modules/app-modals';
import { ResponsiveContainer } from 'components';
import { breakpoints } from 'modules/media';

import { AddBillingInformationForm } from './AddBillingInformationForm';

const Add_Billing_Information_Modal = 'Add_Billing_Information_Modal';

const modalStyle = css`
  max-width: 640px;

  @media (max-width: ${breakpoints.small}px) {
    max-width: 100%;
  }

  overflow: auto;
`;

const Container = styled(ResponsiveContainer)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const useAddBillingInformationModal = () => {
  const { openModal, closeModal } = useAppModals();
  const handleCloseModal = useCallback(() => {
    closeModal(Add_Billing_Information_Modal);
  }, [closeModal]);
  return useCallback(
    () =>
      openModal(Add_Billing_Information_Modal, {
        modalStyle,
        onDismiss: handleCloseModal,
        children: (
          <div>
            <ModalHeader onCloseClick={handleCloseModal}>
              <LocalizedMessage messageKey="views.add-billing-info-title" />
            </ModalHeader>
            <Container>
              <Alert variant="info">
                <LocalizedMessage messageKey="views.add-billing-info-description" />
              </Alert>
            </Container>
            <AddBillingInformationForm onSuccess={handleCloseModal} onClose={handleCloseModal} />
          </div>
        ),
      }),
    [handleCloseModal, openModal],
  );
};
