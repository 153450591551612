import { color } from '@creditornot/cb-ingredients/design-tokens';
import { typographyCss } from '@creditornot/cb-ingredients';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  subContent?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const MainContent = styled.div<{ isDisabled: boolean }>`
  ${typographyCss.Body3()}
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ isDisabled }) => (isDisabled ? color.textDisabled : color.text)};
`;

const Subcontent = styled.small<{ isDisabled: boolean }>`
  ${typographyCss.Small()}
  display: block;
  margin-top: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ isDisabled }) => (isDisabled ? color.textDisabled : color.textSubdued)};
`;

export const DataCellContent = ({ children, className, subContent, disabled = false }: Props) => {
  return (
    <Root className={className}>
      <MainContent isDisabled={disabled}>{children}</MainContent>
      {subContent && <Subcontent isDisabled={disabled}>{subContent}</Subcontent>}
    </Root>
  );
};
