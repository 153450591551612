import { colors } from '@creditornot/cb-ingredients';
import * as React from 'react';

export const VenueMarkerPin: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_684_749)">
        <circle cx="6" cy="6" r="3.75" fill="url(#paint0_linear_684_749)" />
        <circle cx="6" cy="6" r="3.25" stroke="white" fill="url(#paint0_linear_684_749)" />
      </g>
      <defs>
        <filter
          id="filter0_dd_684_749"
          x="0.25"
          y="0.25"
          width="11.5"
          height="13.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_684_749" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_684_749"
            result="effect2_dropShadow_684_749"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_684_749"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_684_749"
          x1="6"
          y1="2.25"
          x2="6"
          y2="9.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.wolt100} />
          <stop offset="1" stopColor={colors.wolt100} />
        </linearGradient>
      </defs>
    </svg>
  );
};
