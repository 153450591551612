const minutesInHour = 60;

const hoursOfDay = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];

export const deliveryTimeOptions = [
  ...hoursOfDay.flatMap((hour) => {
    const labelHour = hour < 10 ? `0${hour}` : hour;

    return [
      {
        label: `${labelHour}.00`,
        value: minutesInHour * hour,
      },
      {
        label: `${labelHour}.15`,
        value: minutesInHour * hour + 15,
      },
      {
        label: `${labelHour}.30`,
        value: minutesInHour * hour + 30,
      },
      {
        label: `${labelHour}.45`,
        value: minutesInHour * hour + 45,
      },
    ];
  }),
  {
    label: '24.00',
    value: minutesInHour * 24,
  },
];

export const convertMinutesToDeliveryTime = (timeInMinutes: number) => {
  const hour = `0${Math.floor(timeInMinutes / minutesInHour)}`;
  const minutes = `0${timeInMinutes % minutesInHour}`;

  return `${hour.slice(hour.length - 2, hour.length)}.${minutes.slice(
    minutes.length - 2,
    minutes.length,
  )}`;
};
