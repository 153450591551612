import { memo, useMemo } from 'react';
import { FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import {
  getCurrencyConfig,
  getFinalMaximumFractionDigits,
  getFinalMinimumFractionDigits,
  parseCurrency,
} from './utils';

interface LocalizedNumberProps {
  value: number;
  currency?: string;
  withSign?: boolean;
  currencyDisplay?: 'symbol' | 'code' | 'name' | 'none';
  disableCurrencyParser?: boolean;
  percent?: boolean;
  useGrouping?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  nowrap?: boolean;
}

const Root = styled.span<{ $nowrap?: boolean }>`
  white-space: ${(props) => (props.$nowrap ? 'nowrap' : 'wrap')};
`;

const LocalizedNumber = ({
  value,
  currency,
  disableCurrencyParser,
  currencyDisplay = 'symbol',
  withSign = false,
  percent,
  useGrouping,
  maximumFractionDigits,
  minimumFractionDigits,
  nowrap,
}: LocalizedNumberProps) => {
  const currencyConfig = currency ? getCurrencyConfig(currency) : undefined;
  const parsedValue = useMemo(
    () => (currency && !disableCurrencyParser ? parseCurrency(value, currency) : value),
    [currency, disableCurrencyParser, value],
  );
  const minDigits = getFinalMinimumFractionDigits(minimumFractionDigits, currency);
  const maxDigits = getFinalMaximumFractionDigits(
    maximumFractionDigits || currencyConfig?.formatDecimals,
    currency,
  );

  return (
    <Root $nowrap={nowrap}>
      <FormattedNumber
        value={parsedValue}
        signDisplay={withSign ? 'always' : value < 0 ? 'always' : 'never'}
        currency={currencyDisplay === 'none' ? undefined : currency}
        currencyDisplay={currencyDisplay === 'none' ? undefined : currencyDisplay}
        style={(currency && 'currency') || (percent && 'percent') || 'decimal'}
        useGrouping={useGrouping}
        // add this check to make sure we respect currencyConfig.formatDecimals
        minimumFractionDigits={maxDigits > minDigits ? minDigits : maxDigits}
        // add sanity check, if min > max this crashes the app
        maximumFractionDigits={maxDigits < minDigits ? minDigits : maxDigits}
      />
    </Root>
  );
};

export default memo(LocalizedNumber);
