import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCorporate } from 'modules/corporates';

import {
  editCorporate,
  EditCorporateData,
  EditCorporateRestrictedData,
  editCorporateAsAdmin,
  EditCorporateAsAdminData,
  editCorporateRestricted,
  editCorporateV2,
} from './api';
import { CorporateV2 } from './types';

export const useEditCorporate = (invalidateQueries?: string[]) => {
  const queryClient = useQueryClient();
  const { data: corporate } = useCorporate();

  return useCallback(
    async (data: EditCorporateData) => {
      if (!corporate?.id) {
        return Promise.reject('Corporate is missing');
      }
      const result = await editCorporate(corporate.id, data);
      queryClient.invalidateQueries(['corporate']);
      invalidateQueries &&
        invalidateQueries.forEach((query) => queryClient.invalidateQueries(query));
      return result;
    },
    [corporate?.id, invalidateQueries, queryClient],
  );
};

export const useEditCorporateV2 = (invalidateQueries?: string[]) => {
  const queryClient = useQueryClient();
  const { data: corporate } = useCorporate();

  return useCallback(
    async (data: Partial<CorporateV2>) => {
      if (!corporate?.id) {
        return Promise.reject('Corporate is missing');
      }
      const result = await editCorporateV2(corporate.id, data);
      queryClient.invalidateQueries(['corporate-v2', ...(invalidateQueries ?? [])]);
      return result;
    },
    [corporate?.id, invalidateQueries, queryClient],
  );
};

export const useEditCorporateRestricted = () => {
  const queryClient = useQueryClient();
  const { data: corporate } = useCorporate();

  return useCallback(
    async (data: EditCorporateRestrictedData) => {
      if (!corporate?.id) {
        return Promise.reject('Corporate is missing');
      }
      const result = await editCorporateRestricted(corporate.id, data);
      queryClient.invalidateQueries();
      return result;
    },
    [corporate?.id, queryClient],
  );
};

export const useEditCorporateAsAdmin = () => {
  const queryClient = useQueryClient();
  const { data: corporate } = useCorporate();

  return useCallback(
    async (data: EditCorporateAsAdminData) => {
      if (!corporate?.id) {
        return Promise.reject('Corporate is missing');
      }
      const result = await editCorporateAsAdmin(corporate.id, data);
      queryClient.invalidateQueries();
      return result;
    },
    [corporate?.id, queryClient],
  );
};
