import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { Formik, Field, FieldProps, FormikHelpers } from 'formik';
import { InputContainer } from '@creditornot/cb-form';
import { Button } from '@creditornot/cb-button';
import { Checkmark } from '@creditornot/cb-icons';
import { Alert } from '@creditornot/cb-alert';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCallback } from 'react';

import {
  ResponsiveContainer,
  FormFooter,
  Notification,
  FormikCheckboxField,
  FormikMultiSelectField,
} from 'components';
import { fetchGroups } from 'modules/groups';
import { useAddUser } from 'modules/users';
import { processError } from 'utils';
import { useFetch } from 'modules/react-query';
import { useCorporate } from 'modules/corporates';
import { corporateFeatureFlag } from 'modules/corporates/utils';
import { UI_SUCCESS_DELAY } from 'modules/common/constants';

import { AsyncUserSelect } from './AsyncUserSelect';
import { FormikCreateGroupButtonField } from './FormikCreateGroupButtonField';
import { AddExistingUserFormValues as FormValues } from './types';

interface AddExistingUserFormProps {
  onClose: () => void;
  onSuccess: () => void;
  corporateId: string | null;
}

const StyledResponsiveContainer = styled.div`
  padding: 24px;
`;

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: 24px;
  }
`;

const Container = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const Info = styled.div`
  display: block;
  color: ${color.textSubdued};
  font-size: 14px;
`;

const StyledFormikCreateGroupButtonField = styled(FormikCreateGroupButtonField)`
  margin-top: 16px;
`;

const ApiErrorContainer = styled(ResponsiveContainer)`
  margin-bottom: 32px;
`;

const initialValues: FormValues = {
  selectedUser: null,
  is_admin: false,
  group_ids: [],
};

const AddExistingUserForm = ({ onClose, corporateId, onSuccess }: AddExistingUserFormProps) => {
  const { formatMessage } = useIntl();
  const addUser = useAddUser();
  const { data: corporate } = useCorporate();
  const { groupEnabled } = corporateFeatureFlag(corporate);

  const { data: groupsData, error: groupsError } = useFetch({
    queryKey: ['AddExistingUserForm', 'Groups', corporateId],
    queryFn: () => (corporateId ? fetchGroups(corporateId) : Promise.reject()),
    enabled: !!corporateId,
  });

  const handleSubmit = useCallback(
    async (
      { selectedUser, is_admin, group_ids }: FormValues,
      actions: FormikHelpers<FormValues>,
    ) => {
      if (!corporateId || !selectedUser) {
        actions.setSubmitting(false);
        actions.setStatus({ error: 'Corporate and user must be provided' });
        return;
      }

      try {
        await addUser({
          userData: {
            user_id: selectedUser.user.id.$oid,
            is_admin,
            group_ids,
          },
          invalidateDelay: UI_SUCCESS_DELAY,
        });
        actions.setStatus({ submitSucceeded: true });
        setTimeout(onSuccess, UI_SUCCESS_DELAY);
      } catch (error) {
        actions.setStatus({ error });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [addUser, corporateId, onSuccess],
  );

  return (
    <Formik initialValues={initialValues} initialStatus={{ error: null }} onSubmit={handleSubmit}>
      {({ values: { selectedUser }, status, isSubmitting, submitForm }) => (
        <>
          <Container>
            <StyledResponsiveContainer>
              <Info>
                <Notification>
                  <FormattedMessage id="users.new-user-modal.add-existing.description" />
                </Notification>
              </Info>
            </StyledResponsiveContainer>

            <StyledResponsiveContainer css="padding-top: 0px; padding-bottom: 10px;">
              {corporateId && (
                <Field name="selectedUser">
                  {({ field, form: { setFieldValue } }: FieldProps) => (
                    <AsyncUserSelect
                      corporateId={corporateId}
                      onSelectUser={(user) => setFieldValue(field.name, user)}
                      selectedUser={selectedUser}
                    />
                  )}
                </Field>
              )}
            </StyledResponsiveContainer>

            <StyledResponsiveContainer>
              <StyledInputContainer label={formatMessage({ id: 'common.name' })}>
                {selectedUser?.user.name
                  ? `${selectedUser.user.name.first_name} ${selectedUser.user.name.last_name}`
                  : '–'}
              </StyledInputContainer>
              <StyledInputContainer label={formatMessage({ id: 'common.email' })}>
                {selectedUser?.user.email ?? '–'}
              </StyledInputContainer>
              <StyledInputContainer label={formatMessage({ id: 'common.phone-number' })}>
                {selectedUser?.user.phone_number ?? '–'}
              </StyledInputContainer>

              <StyledInputContainer hint={formatMessage({ id: 'users.admin-role-explanation' })}>
                <FormikCheckboxField name="is_admin">
                  {formatMessage({ id: 'users.add-user-to-be-admin' })}
                </FormikCheckboxField>
              </StyledInputContainer>

              {groupEnabled && (
                <>
                  <StyledInputContainer
                    label={formatMessage({ id: 'users.new-user-modal.groups-label' })}
                  >
                    <FormikMultiSelectField
                      name="group_ids"
                      options={
                        groupsData
                          ? groupsData.map((group) => ({
                              label: group.name,
                              value: group.id,
                            }))
                          : []
                      }
                      multiSelectProps={{
                        placeholder: formatMessage({ id: 'users.edit-user.select-groups' }),
                        menuPlacement: 'top',
                      }}
                    />
                  </StyledInputContainer>
                  <StyledFormikCreateGroupButtonField />
                </>
              )}
            </StyledResponsiveContainer>

            {(status?.error || groupsError) && (
              <ApiErrorContainer>
                <Alert
                  style={{ flex: '1' }}
                  variant="error"
                  title={processError(status?.error || groupsError).message}
                >
                  {processError(status?.error || groupsError).data}
                </Alert>
              </ApiErrorContainer>
            )}
          </Container>

          <FormFooter
            rightContent={
              <>
                <Button variant="lightBlue" size="small" onClick={onClose}>
                  <FormattedMessage id="common.cancel" />
                </Button>
                <Button
                  data-test-id="AddExistingUserForm.AddButton"
                  variant="blue"
                  size="small"
                  onClick={submitForm}
                  disabled={isSubmitting || status?.submitSucceeded || !selectedUser}
                  loading={isSubmitting}
                  icon={
                    status?.submitSucceeded && (
                      <Checkmark data-test-id="AddExistingUserForm.Checkmark" />
                    )
                  }
                >
                  <FormattedMessage id="common.add" />
                </Button>
              </>
            }
          />
        </>
      )}
    </Formik>
  );
};

export default AddExistingUserForm;
