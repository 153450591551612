import { useFormikContext } from 'formik';
import { useCallback } from 'react';

import { FormikInputField } from 'components';
import { createValidator, isGreaterThan, isInteger, isNumber } from 'validation';
import { EditCorporateFormValues } from 'views/settings/CompanySettings/types';

const validate = createValidator([isNumber, isInteger, isGreaterThan(0)]);

interface Props {
  alwaysShowError?: boolean;
  currency: string;
}

export const SingleTransactionLimitField = ({ alwaysShowError, currency }: Props) => {
  const { values } = useFormikContext<EditCorporateFormValues>();

  const validateFn = useCallback(
    async (value: string) => {
      const error = await validate(value);

      if (error) {
        return error;
      }

      if (value === '' && values?.transaction_limits?.daily_transactions_amount) {
        return 'corporate-form.transaction-limit-missing';
      }
    },
    [values?.transaction_limits?.daily_transactions_amount],
  );

  return (
    <FormikInputField
      name="transaction_limits.single_transaction_amount"
      type="text"
      validate={validateFn}
      alwaysShowError={alwaysShowError}
      inputProps={{
        suffixText: currency,
      }}
    />
  );
};
