import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Spinner } from '@creditornot/cb-progress';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { ApiErrorNotification, ResponsiveContainer, View } from 'components';
import { useFetchSummary } from 'modules/summary';
import { env } from 'config';
import { LocalizedMessage, useI18n } from 'i18n';
import { useCorporate } from 'modules/corporates';
import { useOpenEventTutorialVideo } from 'modules/events';
import { event } from 'modules/google-analytics';
import { resources } from 'modules/resources';
import { useCorporatePurchaseStatistics } from 'modules/summary/useCorporatePurchaseStatistics';

import { getCurrentMonthDateRange } from './utils';
import { useOpenOrderTutorialVideo, useOpenOnboardUsersTutorialVideo } from './hooks';
import { HomeViewComponent } from './components/HomeViewComponent';
import { useSubscriptionAdBanner } from './hooks/useSubscriptionAdBanner';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
`;

const ApiErrorContainer = styled(ResponsiveContainer)`
  margin-top: 32px;
  margin-bottom: 32px;
`;

const useDisplayDate = (startDate: Date, endDate: Date) => {
  const { getLocalizedDate } = useI18n();

  const formattedStartDate = getLocalizedDate(startDate, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  const formattedEndDate = getLocalizedDate(endDate, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  return formattedStartDate === formattedEndDate
    ? formattedStartDate
    : `${formattedStartDate} - ${formattedEndDate}`;
};

const useReceivedDates = () => {
  const [dates] = useState(getCurrentMonthDateRange());
  return dates;
};

export const Home = () => {
  const { user } = useAuthState();

  const openOrderTutorialVideo = useOpenOrderTutorialVideo();
  const openEventTutorialVideo = useOpenEventTutorialVideo();
  const openOnboardUsersTutorialVideo = useOpenOnboardUsersTutorialVideo();
  const {
    isSubscriptionBannerEnabled,
    isSubscriptionBannerSeen,
    dismissSubscriptionAdBanner,
    handleSubscriptionAdBannerLearnMoreClick,
  } = useSubscriptionAdBanner();
  const { data: corporate } = useCorporate();
  const { data: summaryData, isLoading: isLoadingSummary, error: summaryError } = useFetchSummary();
  const { startDate, endDate } = useReceivedDates();
  const {
    data: purchaseStatisticsData,
    isLoading: isLoadingPurchaseStatisticsData,
    error: purchaseStatisticsDataError,
  } = useCorporatePurchaseStatistics({ startDate, endDate });

  const multipaymentCountryList = env.MULTIPAYMENT_COUNTRIES?.split(',');
  const shouldShowMultipaymentResource =
    !!corporate &&
    !corporate.products.daas_delivery_ui_enabled &&
    multipaymentCountryList?.includes(corporate.country);

  const handleOpenOrderTutorialVideoClick = useCallback(() => {
    openOrderTutorialVideo();
    event({
      category: 'dashboard',
      action: 'click',
      label: 'order_tutorial',
    });
  }, [openOrderTutorialVideo]);
  const handleOnboardUsersTutorialVideoClick = useCallback(() => {
    openOnboardUsersTutorialVideo();
    event({
      category: 'dashboard',
      action: 'click',
      label: 'accepting_company_invite_tutorial',
    });
  }, [openOnboardUsersTutorialVideo]);
  const handleEventTutorialVideoClick = useCallback(() => {
    openEventTutorialVideo();
    event({
      category: 'dashboard',
      action: 'click',
      label: 'company_event_tutorial',
    });
  }, [openEventTutorialVideo]);
  const handleOrderGuideClick = useCallback(() => {
    window.open(resources.guides.groupOrder);
    event({
      category: 'dashboard',
      action: 'click',
      label: 'order_guide',
    });
  }, []);
  const handleEventGuideClick = useCallback(() => {
    window.open(resources.guides.events);
    event({
      category: 'dashboard',
      action: 'click',
      label: 'company_event_guide',
    });
  }, []);
  const handleMultipaymentGuideClick = useCallback(() => {
    window.open(resources.guides.multipayments);
    event({
      category: 'dashboard',
      action: 'click',
      label: 'multipayment_guide',
    });
  }, []);
  const displayDate = useDisplayDate(startDate, endDate);
  if (isLoadingSummary || isLoadingPurchaseStatisticsData) {
    return (
      <View
        title={
          user?.name && (
            <LocalizedMessage
              messageKey="views.dashboard.title"
              values={{ name: user.name.first_name }}
            />
          )
        }
        subtitle={<LocalizedMessage messageKey="views.dashboard.subtitle" />}
      >
        <SpinnerContainer>
          <Spinner size="extraLarge" />
        </SpinnerContainer>
      </View>
    );
  }

  if (summaryError || purchaseStatisticsDataError) {
    return (
      <View
        title={
          user?.name && (
            <LocalizedMessage
              messageKey="views.dashboard.title"
              values={{ name: user.name.first_name }}
            />
          )
        }
        subtitle={<LocalizedMessage messageKey="views.dashboard.subtitle" />}
      >
        <ApiErrorContainer>
          <ApiErrorNotification error={summaryError || purchaseStatisticsDataError} />
        </ApiErrorContainer>
      </View>
    );
  }

  if (!summaryData || !corporate) {
    return null;
  }

  return (
    <HomeViewComponent
      user={user}
      corporate={corporate}
      purchaseStatistics={purchaseStatisticsData}
      summaryData={summaryData}
      onOpenOrderTutorialVideoClick={handleOpenOrderTutorialVideoClick}
      onOnboardUsersTutorialVideoClick={handleOnboardUsersTutorialVideoClick}
      onEventTutorialVideoClick={handleEventTutorialVideoClick}
      onOrderGuideClick={handleOrderGuideClick}
      onEventGuideClick={handleEventGuideClick}
      onMultipaymentGuideClick={
        shouldShowMultipaymentResource ? handleMultipaymentGuideClick : undefined
      }
      onSubscriptionLearnMoreClick={handleSubscriptionAdBannerLearnMoreClick}
      onSubscriptionDismissClick={dismissSubscriptionAdBanner}
      showSubscriptionAdBanner={isSubscriptionBannerEnabled && !isSubscriptionBannerSeen}
      displayDate={displayDate}
    />
  );
};
