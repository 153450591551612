import styled from 'styled-components';
import { ReactNode } from 'react';
import { InputAlert } from '@creditornot/cb-input';
import { AnimatePresence, motion } from 'framer-motion';

export type FormikInputFieldProps = {
  className?: string;
  errorMessage?: ReactNode;
  children: ReactNode;
};

const ErrorInputAlert = styled(InputAlert).attrs({
  variant: 'error',
})`
  padding-top: 4px;
`;

export function FormikFieldContainer({ className, children, errorMessage }: FormikInputFieldProps) {
  return (
    <div className={className}>
      {children}
      <AnimatePresence>
        {errorMessage && (
          <motion.div
            initial={{ opacity: 0, height: '0' }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: '0' }}
          >
            <ErrorInputAlert>{errorMessage}</ErrorInputAlert>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
