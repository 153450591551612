import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Button, TextButton } from '@creditornot/cb-button';
import { FormattedMessage } from 'react-intl';

import { event } from 'modules/google-analytics';
import { ModalV2, ResponsiveContainer } from 'components';
import { useProductTour } from 'modules/product-tour';
import { useCorporate } from 'modules/corporates';
import { LottieComponent } from 'modules/lotties';
import { breakpoints } from 'modules/media';
import { setLocalStorageItem } from 'modules/hooks';

import {
  WELCOME_MODAL_SELECTOR,
  INVITE_USERS_BUTTON_SELECTOR,
  USER_GUIDE_SELECTOR,
} from './selectors';

const Container = styled(ResponsiveContainer)`
  padding-top: 48px;
  padding-bottom: 48px;
  display: block;
  text-align: center;
`;

const City = styled(LottieComponent).attrs({
  animation: 'city',
  size: 'wide',
})`
  height: 250px;
  margin-top: 40px;

  @media (max-width: ${breakpoints.small}px) {
    max-height: 200px;
  }
`;

const Title = styled.h4`
  ${typographyCss.Heading4()}
  margin-top: 40px;
`;

const Subtitle = styled.div`
  font-size: 16px;
  margin: auto;
  margin-top: 16px;
  color: ${color.textDisabled};
  max-width: 480px;
`;

const GetStartButton = styled(Button).attrs({
  variant: 'blue',
  size: 'small',
})`
  margin-top: 32px;
`;

const CloseButton = styled(TextButton)`
  margin-top: 16px;
`;

export const WoltAtWorkWelcomeModal = () => {
  const history = useHistory();
  const { data: corporate } = useCorporate();
  const { isInTourMode, step, gotoStep } = useProductTour();

  const handleStartClick = useCallback(() => {
    if (!corporate) return;

    gotoStep(INVITE_USERS_BUTTON_SELECTOR);
    history.push(`/${corporate.id}/users`);

    event({
      category: 'new_user_onboarding',
      action: 'next',
      label: 'welcome_step',
    });
  }, [corporate, gotoStep, history]);

  const handleSkipClick = useCallback(() => {
    gotoStep(USER_GUIDE_SELECTOR);
    setLocalStorageItem('isFirstTimeUser', false);

    event({
      category: 'new_user_onboarding',
      action: 'exit',
      label: 'welcome_step',
    });
  }, [gotoStep]);

  return (
    <ModalV2
      open={isInTourMode && step === WELCOME_MODAL_SELECTOR}
      disableDismissOnEsc
      disableDismissOnOutsidePress
    >
      <Container
        data-test-id="product-tour-modal-container"
        data-product-tour={WELCOME_MODAL_SELECTOR}
      >
        <City />
        <Title>
          <FormattedMessage id="product-tour.welcome-modal-title" />
        </Title>
        <Subtitle>
          <FormattedMessage id="product-tour.welcome-modal-subtitle" />
        </Subtitle>

        <div>
          <GetStartButton onClick={handleStartClick}>
            <FormattedMessage id="product-tour.start-button" />
          </GetStartButton>
        </div>
        <div>
          <CloseButton onClick={handleSkipClick}>
            <FormattedMessage id="product-tour.skip-button" />
          </CloseButton>
        </div>
      </Container>
    </ModalV2>
  );
};
