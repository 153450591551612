import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { useAuthState } from '@creditornot/wolt-auth/react';

import { useCorporate } from 'modules/corporates';
import { useFetchUserCorporateRole } from 'modules/users';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';

import AsyncCorporateSelect from './AsyncCorporateSelect';
import RoleBasedCorporateSelect from './RoleBasedCorporateSelect';

const CorporateName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  color: ${color.textBrand};
`;

const CorporateNavigation = () => {
  const { user } = useAuthState();
  const isOnCorporatesPage = useRouteMatch({ path: '/', exact: true });
  const { isWoltEmployee } = useIsUserWoltEmployee();
  const { data: corporateRoles } = useFetchUserCorporateRole();
  const { data: corporate } = useCorporate();

  if (!user || isOnCorporatesPage) {
    return null;
  }

  return (
    <>
      {isWoltEmployee && <AsyncCorporateSelect />}
      {!isWoltEmployee && corporate && corporateRoles ? (
        corporateRoles.length > 1 ? (
          <RoleBasedCorporateSelect corporateRoles={corporateRoles} selectedUser={user} />
        ) : (
          <CorporateName>{corporate.name}</CorporateName>
        )
      ) : null}
    </>
  );
};

export default CorporateNavigation;
