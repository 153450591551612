import { ReactNode, useContext, createContext } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@creditornot/cb-button';

import { FormSection } from 'components';

import { FormStates } from './types';

type FormProps = {
  title: string;
  children: ReactNode;
  onRenderStateChange: (state: FormStates) => void;
};

export const FormCardStatusContext = createContext<{
  renderState: FormStates;
}>({
  renderState: 'view',
});

const FormCard = ({ onRenderStateChange, title, children }: FormProps) => {
  const { formatMessage } = useIntl();
  const { renderState } = useContext(FormCardStatusContext);

  return (
    <FormSection
      title={title}
      topRightItem={
        renderState === 'view' && (
          <Button
            type="button"
            variant="lightBlue"
            size="small"
            onClick={() => onRenderStateChange('edit')}
          >
            {formatMessage({ id: 'common.edit' })}
          </Button>
        )
      }
    >
      {children}
    </FormSection>
  );
};

const FormStatusProvider = ({
  children,
  renderState,
}: {
  children: ReactNode;
  renderState: FormStates;
}) => {
  const values = { renderState };

  return <FormCardStatusContext.Provider value={values}>{children}</FormCardStatusContext.Provider>;
};

const FormCardWrapper: React.FC<FormProps & { renderState?: FormStates }> = ({
  renderState = 'view',
  ...props
}) => {
  return (
    <FormStatusProvider renderState={renderState}>
      <FormCard {...props} />
    </FormStatusProvider>
  );
};

export { FormCardWrapper as FormCard };
