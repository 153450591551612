import { useCallback, useEffect } from 'react';

import { useMedia } from 'modules/media';
import {
  NeverNullBooleanParam,
  NeverNullDelimitedArrayParam,
  NeverNullNumberParam,
  NeverNullStringParam,
  useQueryParams,
  WoltPageSizeParam,
} from 'modules/react-query-params';

export const useSubscriptionUsersQueryParams = () => {
  const mediumView = useMedia('medium');

  const query = useQueryParams({
    showInvites: NeverNullBooleanParam,
    users: NeverNullDelimitedArrayParam,
    user: NeverNullStringParam,
    invites: NeverNullDelimitedArrayParam,
    invite: NeverNullStringParam,
    page: NeverNullNumberParam,
    page_size: WoltPageSizeParam,
  });
  const [params, setReactQueryParams] = query;

  useEffect(() => {
    if (mediumView) {
      setReactQueryParams({
        page_size: 50,
        page: 1,
      });
    }
  }, [mediumView, setReactQueryParams]);

  const setQueryParams: typeof setReactQueryParams = useCallback(
    (queries) => {
      const shouldResetPage = 'showInvites' in queries || 'page_size' in queries;

      setReactQueryParams(({ page, ...staledQueries }) => ({
        ...staledQueries,
        page: shouldResetPage ? 1 : page,
        ...queries,
      }));
    },
    [setReactQueryParams],
  );
  return [params, setQueryParams] as typeof query;
};
