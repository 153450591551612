import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchUser } from './api';
import { UserV2 } from './types';

export const useFetchUser = <T = UserV2>(
  userId: string | null,
  config?: UseQueryOptions<UserV2, unknown, T>,
) => {
  const { data: corporate } = useCorporate();

  const queryFn = useCallback(() => {
    if (!corporate || !userId) {
      return Promise.reject();
    }
    return fetchUser(corporate.id, userId);
  }, [corporate, userId]);

  return useQuery({
    queryKey: ['user', corporate?.id, userId],
    queryFn,
    enabled: !!corporate && !!userId,
    ...config,
  });
};
