import { useHistory } from 'react-router-dom';
import { TextButton } from '@creditornot/cb-button';
import { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { LocalizedMessage, useI18n } from 'i18n';
import { useNotificationToast } from 'modules/app-modals';
import { breakpoints } from 'modules/media';

const createGroupWithoutPolicyToastId = 'CREATE_GROUP_WITHOUT_POLICY_TOAST_ID';

const AddPolicyText = styled(LocalizedMessage)`
  display: block;
  margin-top: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const notificationToastCss = css`
  width: 420px;

  @media (max-width: ${breakpoints.small}px) {
    width: 320px;
  }
`;

export const useOpenCreateGroupWithoutPolicyToast = () => {
  const { getLocalizedMessage } = useI18n();
  const history = useHistory();
  const { openNotificationToast, closeNotificationToast } = useNotificationToast();
  return useCallback(
    ({
      corporateName,
      groupName,
      groupId,
      corporateId,
    }: {
      corporateName: string;
      corporateId: string;
      groupId: string;
      groupName: string;
    }) =>
      openNotificationToast(createGroupWithoutPolicyToastId, {
        variant: 'info',
        notificationToastStyle: notificationToastCss,
        title: getLocalizedMessage('toast.after-create-group-title'),
        onCloseButtonClick: () => closeNotificationToast(createGroupWithoutPolicyToastId),
        autoCloseIn: 30 * 1000,
        onClose: () => closeNotificationToast(createGroupWithoutPolicyToastId),
        children: (
          <Content>
            <div>
              <LocalizedMessage
                messageKey="toast.after-create-group-content"
                values={{
                  corporateName,
                  groupName,
                  b: (msg: string) => <b key="toast.after-create-group-content">{msg}</b>,
                }}
              />
            </div>
            <AddPolicyText
              messageKey="toast.after-create-group-footer"
              values={{
                'add-policies-link': (msg: string) => (
                  <TextButton
                    key="toast.add-policies-link"
                    onClick={() => {
                      history.push(`/${corporateId}/groups/info/${groupId}/change-policy`);
                      closeNotificationToast(createGroupWithoutPolicyToastId);
                    }}
                  >
                    {msg}
                  </TextButton>
                ),
              }}
            />
          </Content>
        ),
      }),
    [closeNotificationToast, getLocalizedMessage, history, openNotificationToast],
  );
};
