import { color } from '@creditornot/cb-ingredients/design-tokens';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { Avatar } from '@creditornot/cb-avatar';
import { Tag } from '@creditornot/cb-tag';

import { OrderItem, Participant, ParticipantBasket } from 'modules/orders/types';
import { LocalizedMessage, LocalizedNumber } from 'i18n';

type GroupOrderItemProps = {
  className?: string;
  currency: string;
  showContent?: boolean;
  orderItems: OrderItem[];
  isHost: boolean;
  participant: Participant;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;

  & + & {
    border-top: 1px solid ${color.border};
  }
`;

const Header = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;

  & + & {
    border-top: 1px solid ${color.border};
  }
`;

const Content = styled(motion.div).attrs({
  initial: { paddingTop: '0px', paddingBottom: '0px', height: '0px' },
  animate: { paddingTop: '12px', paddingBottom: '12px', height: 'auto' },
  exit: { paddingTop: '0px', paddingBottom: '0px', height: '0px' },
  transition: { ease: 'linear', duration: 0.1 },
})`
  border-top: 1px solid ${color.border};
  overflow: hidden;
`;

const Name = styled.span`
  display: inline-block;
  margin-inline-start: 12px;
`;

const StyledTag = styled(Tag)`
  margin-inline-start: 12px;
`;

const StyledAvatar = styled(Avatar)`
  vertical-align: middle;
`;

const getParticipantPayAmount = (basket: ParticipantBasket) =>
  basket.items.reduce((total, val) => total + val.end_amount, 0);

const PayAmount = styled.div`
  margin-inline-start: auto;
`;

const OrderItemComponent = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  p {
    padding: 0;
    margin: 0;
  }

  li {
    display: flex;
  }

  li + li {
    color: ${color.textSubdued};
    margin-top: 4px;
  }
`;

export const GroupOrderItem = ({
  className,
  isHost,
  showContent,
  participant: { profile_picture_url, first_name, last_name, basket },
  currency,
  orderItems,
}: GroupOrderItemProps) => {
  const participantPayAmount = getParticipantPayAmount(basket);

  return (
    <Root className={className}>
      <Header>
        <StyledAvatar size="tiny" imgSrc={profile_picture_url} />

        <Name>{`${first_name} ${last_name ?? ''}`}</Name>

        {isHost && (
          <StyledTag variant="black">
            <LocalizedMessage messageKey="orders.details.host-tag" />
          </StyledTag>
        )}

        <PayAmount>
          <LocalizedNumber value={participantPayAmount} currency={currency} />
        </PayAmount>
      </Header>

      <AnimatePresence>
        {!!showContent && (
          <Content>
            <div>
              {basket.items.map((item, index) => {
                const orderItem = orderItems.find(
                  (orderItem) => orderItem.row_number === item.row_number,
                );

                if (!orderItem) {
                  throw new Error('Cannot find order item');
                }

                const { name, options } = orderItem;

                return (
                  <OrderItemComponent key={index}>
                    <li>
                      {`${item.count} × ${name}`}

                      <PayAmount>
                        <LocalizedNumber value={item.end_amount} currency={currency} />
                      </PayAmount>
                    </li>

                    {options.map((option, index) => {
                      const optionTotal = option.values.reduce(
                        (total, val) => total + val.count * val.price,
                        0,
                      );

                      return (
                        <li key={index}>
                          <p>
                            {`${option.name}${option.values.length ? ':' : ''} ${option.values
                              .map((value) => value.value)
                              .join(', ')}`}

                            {!!optionTotal && (
                              <>
                                {' ('}
                                <LocalizedNumber withSign value={optionTotal} currency={currency} />
                                {')'}
                              </>
                            )}
                          </p>
                        </li>
                      );
                    })}
                  </OrderItemComponent>
                );
              })}
            </div>
          </Content>
        )}
      </AnimatePresence>
    </Root>
  );
};
