import { useCallback } from 'react';

import { useUpdateB2bConfig, useFetchB2bConfig } from 'modules/b2b-configs';
import { useEditCorporate, useEditSelfServiceCorporate } from 'modules/corporates';
import { EditCorporateFormValues } from 'views/settings/CompanySettings/types';

import { formatFormValuesBeforeSending } from './formatFormValues';
import { Corporate } from './types';

type FormValues = Pick<
  EditCorporateFormValues,
  'name' | 'business_id' | 'country' | 'address' | 'post_code' | 'city' | 'vat_id'
>;

export const useEditCompanyInformation = (corporate: Corporate) => {
  const editCorporate = useEditCorporate();
  const editB2bConfig = useUpdateB2bConfig();
  const { data: b2bConfig } = useFetchB2bConfig(corporate.id);
  const { mutateAsync: editSettings } = useEditSelfServiceCorporate();

  const editSelfServiceConfigConditionally = useCallback(
    ({ name }: { name: string }) => {
      if (corporate.products.daas_delivery_ui_enabled) {
        return editSettings({
          woltCorporateId: corporate.id,
          body: {
            name,
          },
        });
      }
    },
    [corporate, editSettings],
  );

  const editB2bConfigConditionally = useCallback(
    ({ country }: { country: string }) => {
      if (b2bConfig) {
        return editB2bConfig.mutate({
          corporateId: corporate.id,
          payload: {
            country_code: country,
          },
        });
      }
    },
    [b2bConfig, editB2bConfig, corporate.id],
  );

  return (initialValues: FormValues, newValues: FormValues) =>
    Promise.all([
      editSelfServiceConfigConditionally({
        name: newValues.name,
      }),
      editB2bConfigConditionally({
        country: newValues.country,
      }),
      editCorporate(formatFormValuesBeforeSending(initialValues, newValues, corporate.currency)),
    ]);
};
