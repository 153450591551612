import { FC, useCallback } from 'react';
import { Input } from '@creditornot/cb-input';
import { MagnifyingGlass, WindowNew } from '@creditornot/cb-icons';
import styled from 'styled-components';
import { Button } from '@creditornot/cb-button';
import { DateRangePickerInput } from '@creditornot/cb-date-picker';
import { RouteComponentProps } from 'react-router';
import { endOfDay, isSameDay, startOfDay } from 'date-fns';
import { useIntl } from 'react-intl';
import { Select } from '@creditornot/cb-select';
import { ListDeliveryOrdersDeliveryStatusEnum } from '@creditornot/wd-api-client';

import { env } from 'config';
import { breakpoints, useMedia } from 'modules/media';
import { View, Header } from 'components';
import { useCorporateDeliverySettingsComplete } from 'modules/corporates';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { deliveriesView } from 'telemetry/Avo';

import { useDeliveriesQueryParams } from './useDeliveriesQueryParams';
import { DeliveriesTable } from './DeliveriesTable';
import { DeliveriesList } from './DeliveriesList';
import { last30DaysDateRange } from './utils';

const StyledInput = styled(Input)`
  min-width: 240px;

  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
  }
`;

const StyledSelect = styled(Select)`
  min-width: 240px;

  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
  }
`;

// hack to stretch input. Root component of DateRangePickerInput is a div with display: inline-block
const DatePickerContainer = styled.div`
  > div {
    display: unset;
  }
`;

const StyledDateRangePickerInput = styled(DateRangePickerInput)`
  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
  }
`;

const isLast30Days = (dateRange: { from: Date; to: Date }) => {
  const last30Days = last30DaysDateRange();
  return isSameDay(last30Days.from, dateRange.from) && isSameDay(last30Days.to, dateRange.to);
};

export const DeliveriesView: FC<RouteComponentProps<{ corporateId: string }>> = ({ match }) => {
  const isMediumView = useMedia('medium');
  const { formatMessage } = useIntl();
  const { locale } = useIntl();
  const {
    params: { searchQuery, from, to, deliveryStatus },
    setQueryParams,
  } = useDeliveriesQueryParams();

  const handleOrderDeliveryClick = useCallback(() => {
    window.open(env.DAAS_SELF_SERVICE_URI, '_blank');
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQueryParams({ searchQuery: event.target.value });
    },
    [setQueryParams],
  );

  const handleStatusChange = useCallback(
    (status: string | null) => {
      setQueryParams({ deliveryStatus: (status as ListDeliveryOrdersDeliveryStatusEnum) ?? '' });
    },
    [setQueryParams],
  );
  const handleClear = useCallback(() => {
    setQueryParams({ searchQuery: '' });
  }, [setQueryParams]);

  const handleDateRangeChange: React.ComponentProps<typeof DateRangePickerInput>['onChange'] =
    useCallback(
      (dateRange) => {
        setQueryParams({
          from: dateRange ? startOfDay(dateRange.from) : undefined,
          to: dateRange ? endOfDay(dateRange.to) : undefined,
        });
      },
      [setQueryParams],
    );

  const { data: deliverySettingsComplete } = useCorporateDeliverySettingsComplete(
    match.params.corporateId,
  );

  return (
    <>
      <ViewEventComponent event={deliveriesView} />
      <View
        title={formatMessage({ id: 'deliveries.title' })}
        rightContent={
          <Button
            disabled={!deliverySettingsComplete}
            size="small"
            variant="blue"
            icon={<WindowNew />}
            iconPosition="right"
            onClick={handleOrderDeliveryClick}
          >
            {formatMessage({ id: 'deliveries.order-delivery' })}
          </Button>
        }
      >
        <Header
          borderBottom
          leftItems={[
            <StyledInput
              key="styledUserSearch"
              icon={<MagnifyingGlass />}
              onChange={handleChange}
              value={searchQuery ?? ''}
              placeholder={formatMessage({ id: 'common.search' })}
              onClearClick={handleClear}
            />,
            <StyledSelect
              key="statusFilter"
              options={[
                {
                  label: formatMessage({ id: 'deliveries.status-filter.order-status' }),
                  options: [
                    {
                      value: '',
                      label: formatMessage({ id: 'deliveries.status.all' }),
                    },
                    {
                      value: 'SCHEDULED',
                      label: formatMessage({ id: 'deliveries.status.scheduled' }),
                    },
                    {
                      value: 'BEING_DELIVERED',
                      label: formatMessage({ id: 'deliveries.status.being-delivered' }),
                    },
                    {
                      value: 'DELIVERED',
                      label: formatMessage({ id: 'deliveries.status.delivered' }),
                    },
                    {
                      value: 'REJECTED',
                      label: formatMessage({ id: 'deliveries.status.rejected' }),
                    },
                  ],
                },
              ]}
              placeholder={formatMessage({ id: 'deliveries.status-filter' })}
              onChange={handleStatusChange}
              value={deliveryStatus || undefined}
            />,
            <DatePickerContainer key="dateRangePicker">
              <StyledDateRangePickerInput
                locale={locale}
                value={!isLast30Days({ from, to }) ? { from, to } : undefined}
                placeholder={formatMessage({ id: 'orders.month-picker.last-30-days' })}
                onChange={handleDateRangeChange}
              />
            </DatePickerContainer>,
          ]}
        />

        {isMediumView ? (
          <DeliveriesList corporateId={match.params.corporateId} />
        ) : (
          <DeliveriesTable corporateId={match.params.corporateId} />
        )}
      </View>
    </>
  );
};
