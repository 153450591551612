import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { FormRow, InputContainer } from '@creditornot/cb-form';
import { colors } from '@creditornot/cb-ingredients';
import { Button } from '@creditornot/cb-button';
import { rem } from 'polished';
import { FieldArray, FieldMetaProps } from 'formik';
import React from 'react';
import { Input, InputAlert } from '@creditornot/cb-input';
import { Trashbin } from '@creditornot/cb-icons';
import { AnimatePresence, motion } from 'framer-motion';
import { ParsedQuery } from 'query-string';
import { Alert } from '@creditornot/cb-alert';

import { type MessageIds, parseCurrency, useI18n } from 'i18n';
import { breakpoints } from 'modules/media';
import {
  BasePriceField,
  CustomPriceFromField,
  CustomPriceFeeField,
  CustomPriceToField,
  CustomPricingPreview,
} from 'modules/corporates/components';
import { getFormikFieldError } from 'validation';
import { PriceIncrement } from 'views/corporates/create-corporate/CreateCorporateForm/types';
import { convertFromAmountToCents } from 'utils';
import { IconWithHelpText } from 'components';

interface Props {
  className?: string;
  currency: string;
  formValues: { base_price: string; price_increments: PriceIncrement[] };
  validateForm: () => void;
  getFieldMeta: (name: string) => FieldMetaProps<unknown>;
}

const Root = styled.div`
  border-bottom: 1px solid ${color.border};
`;

const TrashContainer = styled.button`
  height: 36px;
  width: 36px;
  position: relative;
  background-color: ${colors.strawberry8};
  border-radius: 50%;
  border: none;
  cursor: pointer;
`;

const TrashIcon = styled(Trashbin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: ${color.textNegative};
`;

const StyledTh = styled.th`
  text-align: start;
  font-weight: 400;
`;

const StyledInputContainer = styled(InputContainer)`
  & + & {
    margin-top: ${rem(24)};
  }
`;

const AddNewRange = styled(Button)`
  margin-top: ${rem(8)};
`;

const StyledTd = styled.td`
  padding: 0 ${rem(4)} 0 0;
`;

const StyledTable = styled.table`
  table-layout: fixed;
  border-spacing: ${rem(8)} ${rem(8)};
`;

const StyledFormRow = styled(FormRow)`
  @media (max-width: ${breakpoints.medium}px) {
    flex-direction: column;

    & > div {
      margin-bottom: 8px;
    }
  }
`;

const ErrorInputAlert = styled(InputAlert).attrs({
  variant: 'error',
})`
  padding: ${rem(4)} 0;
`;

const StyledHelpIconWithTooltip = styled(IconWithHelpText).attrs({
  infoTooltipMaxWidth: 247,
  popoverProps: { contentZIndex: 1000 },
})`
  display: inline;
  align-self: baseline;
  margin-inline-start: ${rem(8)};
`;

type AnimatedErrorProps = {
  error:
    | {
        values?: ParsedQuery<string> | undefined;
        messageKey: MessageIds;
      }
    | undefined;
  errorPrefix: string;
};

const AnimatedError = ({ error, errorPrefix }: AnimatedErrorProps) => {
  const { getLocalizedMessage } = useI18n();
  return (
    <AnimatePresence>
      {error && (
        <motion.tr
          initial={{ opacity: 0, height: '0' }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: '0' }}
        >
          <StyledTd colSpan={4}>
            <ErrorInputAlert>
              [{errorPrefix}]: {getLocalizedMessage(error.messageKey, error.values)}
            </ErrorInputAlert>
          </StyledTd>
        </motion.tr>
      )}
    </AnimatePresence>
  );
};

export const CustomPricingFormSection = ({
  className,
  currency,
  formValues: { base_price, price_increments },
  validateForm,
  getFieldMeta,
}: Props) => {
  const { getLocalizedMessage } = useI18n();

  const basePriceInCents =
    !base_price || isNaN(Number(base_price)) ? 0 : convertFromAmountToCents(base_price, currency);

  const priceIncrementsInCents = price_increments.map(({ fee, distance_max, distance_min }) => {
    return {
      fee: convertFromAmountToCents(fee, currency),
      distance_min: +distance_min,
      distance_max: +distance_max,
    };
  });

  const priceIncrementsMeta = getFieldMeta(`price_increments`);

  return (
    <Root className={className}>
      <StyledFormRow
        horizontalPadding
        labelHtmlFor="base_price"
        label={getLocalizedMessage('corporate-form.delivery-settings.base-price')}
        required
        labelAlign="top"
      >
        <StyledInputContainer>
          <BasePriceField />
        </StyledInputContainer>
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        labelHtmlFor="price_increments"
        label={getLocalizedMessage('corporate-form.custom-price.title')}
        required
        labelAlign="top"
      >
        <FieldArray name="price_increments">
          {(arrayHelpers) => (
            <>
              <StyledTable>
                <thead>
                  <tr>
                    <StyledTh>
                      {getLocalizedMessage('corporate-form.custom-price.from')}
                      <StyledHelpIconWithTooltip
                        infoText={getLocalizedMessage('corporate-form.custom-price.from.help')}
                      />
                    </StyledTh>
                    <StyledTh>
                      {getLocalizedMessage('corporate-form.custom-price.to')}
                      <StyledHelpIconWithTooltip
                        infoText={getLocalizedMessage('corporate-form.custom-price.to.help')}
                      />
                    </StyledTh>
                    <StyledTh>
                      {getLocalizedMessage('corporate-form.custom-price.price')}
                      <StyledHelpIconWithTooltip
                        infoText={getLocalizedMessage('corporate-form.custom-price.price.help')}
                      />
                    </StyledTh>
                    <StyledTh>
                      {getLocalizedMessage('corporate-form.custom-price.total')}
                      <StyledHelpIconWithTooltip
                        infoText={getLocalizedMessage('corporate-form.custom-price.total.help')}
                      />
                    </StyledTh>
                  </tr>
                </thead>
                <tbody>
                  {price_increments.map((priceIncrement, index, array) => {
                    const minMeta = getFieldMeta(`price_increments.${index}.distance_min`);
                    const minError = getFormikFieldError(minMeta, false);
                    const maxMeta = getFieldMeta(`price_increments.${index}.distance_max`);
                    const maxError = getFormikFieldError(maxMeta, false);
                    const feeMeta = getFieldMeta(`price_increments.${index}.fee`);
                    const feeError = getFormikFieldError(feeMeta, false);

                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <StyledTd>
                            <CustomPriceFromField
                              index={index}
                              fieldArray={array}
                              allowClear={false}
                            />
                          </StyledTd>
                          <StyledTd>
                            <CustomPriceToField
                              index={index}
                              fieldArray={array}
                              allowClear={false}
                            />
                          </StyledTd>
                          <StyledTd>
                            <CustomPriceFeeField index={index} allowClear={false} />
                          </StyledTd>
                          <StyledTd>
                            <Input
                              value={parseCurrency(
                                convertFromAmountToCents(priceIncrement.fee, currency) +
                                  basePriceInCents,
                                currency,
                              )}
                              disabled
                            />
                          </StyledTd>
                          <StyledTd>
                            <TrashContainer
                              type="button"
                              onClick={() => {
                                arrayHelpers.remove(index);
                                setTimeout(() => {
                                  validateForm();
                                }, 100);
                              }}
                            >
                              <TrashIcon width={16} height={16} />
                            </TrashContainer>
                          </StyledTd>
                        </tr>
                        <AnimatedError errorPrefix="From" error={minError} />
                        <AnimatedError errorPrefix="To" error={maxError} />
                        <AnimatedError errorPrefix="Fee" error={feeError} />
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </StyledTable>
              {typeof priceIncrementsMeta.error === 'string' && (
                <Alert variant="error" size="small">
                  {priceIncrementsMeta.error}
                </Alert>
              )}
              <AddNewRange
                role="button"
                variant="lightBlue"
                size="small"
                onClick={() => {
                  arrayHelpers.push({
                    distance_max: '0',
                    distance_min: '0',
                    fee: '0',
                  });
                  setTimeout(() => {
                    validateForm();
                  }, 100);
                }}
              >
                + {getLocalizedMessage('corporate-form.custom-price.new-range')}
              </AddNewRange>
            </>
          )}
        </FieldArray>
      </StyledFormRow>
      <StyledFormRow
        horizontalPadding
        label={getLocalizedMessage('corporate-form.custom-price.preview-with-vat')}
        labelAlign="top"
      >
        <CustomPricingPreview
          priceIncrements={priceIncrementsInCents}
          currency={currency}
          basePrice={basePriceInCents}
        />
      </StyledFormRow>
    </Root>
  );
};
