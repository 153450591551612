import { Country } from './types';

export const getNameWithCountryCode = (countries: Country[]) => (countryCode: string) => {
  const country = countries.find((country) => country.code === countryCode);

  return country ? country.name : undefined;
};

export const getCurrencyWithCountryCode = (countries: Country[]) => (countryCode: string) => {
  const country = countries.find((country) => country.code === countryCode);

  return country ? country.currency : undefined;
};

export const getLanguageWithCountryCode = (countries: Country[]) => (countryCode: string) => {
  const country = countries.find((country) => country.code === countryCode);

  return country ? country.language : undefined;
};
