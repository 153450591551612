import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { rem } from 'polished';
import { InputContainer } from '@creditornot/cb-form';
import { Controller, useFormContext } from 'react-hook-form';

import { B2bConfigFormValues } from 'views/settings/CompanySettings/B2bConfigSettings/types';
import { FormRowV2 as Row, RadioInputCardGroup } from 'components';
import { ReactHookInput } from 'components/ReactHookInput';
import { CustomPricingFormSectionV2 } from 'views/settings/CompanySettings/B2bConfigSettings/B2bConfigFields/CustomPricingFormSectionV2';
import { DiscountRadioFields } from 'views/settings/CompanySettings/B2bConfigSettings/B2bConfigFields/DiscountRadioFields';
import { PricingInfoAlert } from 'modules/corporates/components';

const StyledDiscountRadioFields = styled(DiscountRadioFields)`
  margin-bottom: ${rem(16)};
`;

const StyledPricingInfoAlert = styled(PricingInfoAlert)`
  margin-top: ${rem(10)};
`;

export const DeliveryPricingTypeFieldsV2 = ({ currency }: { currency: string }) => {
  const { formatMessage } = useIntl();

  const {
    control,
    watch,
    register,
    formState: { errors },
  } = useFormContext<B2bConfigFormValues>();
  const pricingTypeValue = watch('pricing.type');
  const discountTypeValue = watch('pricing.discounts.type');

  const flatDiscountRegister = register('pricing.discounts.flatValue');
  const percentageDiscountRegister = register('pricing.discounts.percentageValue');

  return (
    <>
      <Row
        title={formatMessage({ id: 'corporate-form.delivery-fee' })}
        content={
          <Controller
            name="pricing.type"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <>
                <RadioInputCardGroup
                  name={name}
                  value={value}
                  onChange={onChange}
                  stretched
                  options={[
                    {
                      label: formatMessage({
                        id: 'corporate-form.delivery-price-flat-rate.title',
                      }),
                      explanation: formatMessage({
                        id: 'corporate-form.delivery-price-flat-rate.subtitle',
                      }),
                      value: 'flatFee',
                    },
                    {
                      label: formatMessage({
                        id: 'corporate-form.delivery-price-distance-based.title',
                      }),
                      explanation: formatMessage({
                        id: 'corporate-form.delivery-price-distance-based.subtitle',
                      }),
                      value: 'distanceBased',
                    },
                    {
                      label: formatMessage({
                        id: 'corporate-form.delivery-price-custom.title',
                      }),
                      explanation: formatMessage({
                        id: 'corporate-form.delivery-price-custom.subtitle',
                      }),
                      value: 'custom',
                    },
                  ]}
                />
                <StyledPricingInfoAlert isCustom={pricingTypeValue === 'custom'} />
              </>
            )}
          />
        }
      />
      {pricingTypeValue === 'custom' ? (
        <CustomPricingFormSectionV2 currency={currency} />
      ) : (
        <Row
          title={formatMessage({ id: 'corporate-form.delivery-discount.discount' })}
          content={
            <>
              <StyledDiscountRadioFields />
              {discountTypeValue === 'flat' && (
                <InputContainer
                  hint={formatMessage({ id: 'corporate-form.delivery-discount.flat-hint' })}
                >
                  <ReactHookInput
                    register={flatDiscountRegister}
                    errorMessage={errors.pricing?.discounts?.flatValue?.message}
                    type="number"
                    inputProps={{
                      step: 0.01,
                      suffixText: currency,
                    }}
                  />
                </InputContainer>
              )}
              {discountTypeValue === 'percentage' && (
                <InputContainer
                  hint={formatMessage({ id: 'corporate-form.delivery-discount.percentage-hint' })}
                >
                  <ReactHookInput
                    register={percentageDiscountRegister}
                    errorMessage={errors.pricing?.discounts?.percentageValue?.message}
                    type="number"
                    inputProps={{
                      step: 0.01,
                      suffixText: '%',
                    }}
                  />
                </InputContainer>
              )}
            </>
          }
        />
      )}
    </>
  );
};
