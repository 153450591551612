import { MouseEventHandler, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@creditornot/cb-button';
import { AnimatePresence, motion } from 'framer-motion';
import { typographyCss } from '@creditornot/cb-ingredients';

import howToMakeAnInvoiceOrderSrc from 'assets/images/how-to-make-an-invoice-order.png';
import selectInvoicePaymentMethodSrc from 'assets/images/select-invoice-payment-method.png';
import selectOrderDetailsSrc from 'assets/images/select-order-details.png';
import { FormFooter } from 'components';
import { LocalizedMessage } from 'i18n';
import { breakpoints } from 'modules/media';
import { resources } from 'modules/resources';

type Step = 'SELECT_VENUE' | 'SELECT_ORDER_DETAILS' | 'SELECT_INVOICE_PAYMENT_METHOD';

export type OrderTutorialProps = {
  onClose: () => void;
};

const Root = styled.div`
  position: relative;
  margin: auto;
`;

const VideoContainer = styled.div`
  position: relative;
  min-height: 400px;
  overflow: hidden;

  @media (max-width: ${breakpoints.small}px) {
    min-height: 212px;
  }
`;

const BodyContainer = styled.div`
  padding: 32px;
`;

const Heading = styled.h5`
  ${typographyCss.Heading5()}
`;

const StyledBody3 = styled.span`
  ${typographyCss.Body3()}
  margin-top: 8px;
  display: block;
`;

const MotionDiv = styled(motion.div)`
  width: 100%;
  position: absolute;
`;

const ModalFooter = styled(FormFooter)`
  padding: 32px 0 0;
  border: none;
`;

const Video = styled.video.attrs({
  autoPlay: true,
  loop: true,
  controlsList: 'nodownload,nofullscreen,noremoteplayback',
  control: true,
})`
  height: 100%;
  width: 100%;
`;

const videoOrderTutorialsRecords: Record<Step, string> = {
  SELECT_VENUE: resources.videos.selectRestaurant,
  SELECT_ORDER_DETAILS: resources.videos.selectOrderDetails,
  SELECT_INVOICE_PAYMENT_METHOD: resources.videos.selectInvoicePaymentMethod,
};

const videoOrderTutorialsPostersRecords: Record<Step, string> = {
  SELECT_VENUE: howToMakeAnInvoiceOrderSrc,
  SELECT_ORDER_DETAILS: selectOrderDetailsSrc,
  SELECT_INVOICE_PAYMENT_METHOD: selectInvoicePaymentMethodSrc,
};

const useHandleMotion = () => {
  const [direction, setDirection] = useState<'toLeft' | 'toRight'>('toRight');
  const translateX = (direction === 'toRight' ? 1 : -1) * 300;

  return {
    motionProps: {
      initial: {
        opacity: 1,
        x: translateX,
      },
      animate: { x: 0, opacity: 1 },
      exit: {
        opacity: 1,
        x: translateX,
      },
    },
    setDirection,
    direction,
  };
};

export const OrderTutorial = ({ onClose }: OrderTutorialProps) => {
  const [step, setStep] = useState<Step>('SELECT_VENUE');
  const { motionProps, setDirection } = useHandleMotion();
  //   Prevent user from downloading the video
  const handleRightClick: MouseEventHandler<HTMLVideoElement> = useCallback(
    (e) => e.preventDefault(),
    [],
  );
  const handleNext = useCallback(() => {
    setDirection('toRight');
    switch (step) {
      case 'SELECT_VENUE':
        return setStep('SELECT_ORDER_DETAILS');
      case 'SELECT_ORDER_DETAILS':
        return setStep('SELECT_INVOICE_PAYMENT_METHOD');
    }
  }, [setDirection, step]);
  const handleBack = useCallback(() => {
    setDirection('toLeft');
    switch (step) {
      case 'SELECT_ORDER_DETAILS':
        return setStep('SELECT_VENUE');
      case 'SELECT_INVOICE_PAYMENT_METHOD':
        return setStep('SELECT_ORDER_DETAILS');
    }
  }, [setDirection, step]);
  return (
    <Root>
      <VideoContainer>
        <AnimatePresence initial={false}>
          <MotionDiv key={step} {...motionProps}>
            <Video
              onContextMenu={handleRightClick}
              poster={videoOrderTutorialsPostersRecords[step]}
            >
              <source src={videoOrderTutorialsRecords[step]} type="video/mp4" />
              Your browser does not support the video tag.
            </Video>
          </MotionDiv>
        </AnimatePresence>
      </VideoContainer>
      <BodyContainer>
        <Heading>
          {
            {
              SELECT_VENUE: (
                <LocalizedMessage messageKey="dashboard.order-tutorials.select-venue-title" />
              ),
              SELECT_ORDER_DETAILS: (
                <LocalizedMessage messageKey="dashboard.order-tutorials.select-order-details-title" />
              ),
              SELECT_INVOICE_PAYMENT_METHOD: (
                <LocalizedMessage messageKey="dashboard.order-tutorials.select-invoice-payment-method-title" />
              ),
            }[step]
          }
        </Heading>
        <StyledBody3>
          {
            {
              SELECT_VENUE: (
                <LocalizedMessage messageKey="dashboard.order-tutorials.select-venue-description" />
              ),
              SELECT_ORDER_DETAILS: (
                <LocalizedMessage messageKey="dashboard.order-tutorials.select-order-details-description" />
              ),
              SELECT_INVOICE_PAYMENT_METHOD: (
                <LocalizedMessage messageKey="dashboard.order-tutorials.select-invoice-payment-method-description" />
              ),
            }[step]
          }
        </StyledBody3>
        <ModalFooter
          rightContent={
            {
              SELECT_VENUE: (
                <>
                  <Button onClick={onClose} size="small" variant="lightBlue">
                    <LocalizedMessage messageKey="common.skip" />
                  </Button>
                  <Button onClick={handleNext} size="small" variant="blue">
                    <LocalizedMessage messageKey="common.next" />
                  </Button>
                </>
              ),
              SELECT_ORDER_DETAILS: (
                <>
                  <Button onClick={onClose} size="small" variant="lightBlue">
                    <LocalizedMessage messageKey="common.skip" />
                  </Button>
                  <Button onClick={handleBack} size="small" variant="lightBlue">
                    <LocalizedMessage messageKey="common.back" />
                  </Button>
                  <Button onClick={handleNext} size="small" variant="blue">
                    <LocalizedMessage messageKey="common.next" />
                  </Button>
                </>
              ),
              SELECT_INVOICE_PAYMENT_METHOD: (
                <>
                  <Button onClick={handleBack} size="small" variant="lightBlue">
                    <LocalizedMessage messageKey="common.back" />
                  </Button>
                  <Button onClick={onClose} size="small" variant="blue">
                    <LocalizedMessage messageKey="common.done" />
                  </Button>
                </>
              ),
            }[step]
          }
        />
      </BodyContainer>
    </Root>
  );
};
