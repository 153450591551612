import { useMemo } from 'react';

import ConfigsContext from './ConfigsContext';
import countries from './countries';
import {
  getNameWithCountryCode,
  getCurrencyWithCountryCode,
  getLanguageWithCountryCode,
} from './utils';
import { Configs } from './types';

interface ConfigsProviderProps {
  children: React.ReactNode;
  configsOverride?: Partial<Configs>;
}

const ConfigsProvider = ({ children, configsOverride }: ConfigsProviderProps) => {
  const contextValue = useMemo(
    () => ({
      configs: {
        countries,
        utils: {
          getNameWithCountryCode: getNameWithCountryCode(countries),
          getCurrencyWithCountryCode: getCurrencyWithCountryCode(countries),
          getLanguageWithCountryCode: getLanguageWithCountryCode(countries),
        },
        ...configsOverride,
      },
      isLoading: false,
      error: null,
    }),
    [configsOverride],
  );
  return <ConfigsContext.Provider value={contextValue}>{children}</ConfigsContext.Provider>;
};

export default ConfigsProvider;
