import { createContext } from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';

import { NotificationToastProps } from 'components/NotificationToast';
import { ModalV2Props } from 'components/ModalV2';

export type AppModalData = Omit<ModalV2Props, 'open'> & {
  id: string;
  show?: boolean;
  modalStyle?: FlattenSimpleInterpolation;
};

export type AppNotificationToastsData = NotificationToastProps & {
  id: string;
  notificationToastStyle?: FlattenSimpleInterpolation;
};

type Props = {
  modals: AppModalData[];
  openModal: (id: string, modalProps: Omit<AppModalData, 'id'>) => void;
  closeModal: (id: string) => void;
  toasts: AppNotificationToastsData[];
  openNotificationToast: (id: string, toastProps: Omit<AppNotificationToastsData, 'id'>) => void;
  closeNotificationToast: (id: string) => void;
  hasOpenModal: boolean;
};

export const AppModalsContext = createContext<Props>({
  modals: [],
  openModal: () => {},
  closeModal: () => {},
  toasts: [],
  openNotificationToast: () => {},
  closeNotificationToast: () => {},
  hasOpenModal: false,
});
