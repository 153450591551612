import { TextButton, Button } from '@creditornot/cb-button';
import { VideoPlayer, Note } from '@creditornot/cb-icons';
import { Empty } from '@creditornot/cb-placeholders';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { View } from 'components';
import { eventsView } from 'telemetry/Avo';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { Event } from 'modules/events/types';
import { Corporate } from 'modules/corporates/types';
import { PaginatedData } from 'types/PaginatedData';

import { CreateEventModal } from '../CreateEventModal';
import { EventsDataList } from '../EventsDataList';
import { EventsDataTable } from '../EventsDataTable';
import { Panel } from '../Panel';

type Props = {
  corporate: Corporate | undefined;
  action: 'create-event' | 'cancel-event';
  eventsData: PaginatedData<Event> | null | undefined;
  eventStatuses: string[];
  onCreateEventClick: () => void;
  onCloseClick: () => void;
  onSuccess: (event: Event) => void;
  onSuccessAndOpenInviteEventUsers: (event: Event) => void;
  onLearnMoreClick: () => void;
  openEventTutorialVideo: () => void;
  onEventClick: (eventId: string) => void;
  onStatusesChange: (statuses: string[]) => void;
  isMediumView: boolean;
  isEmpty: boolean;
  isFetchingEvents: boolean;
  eventsError: unknown;
};

const EmptyContainer = styled.div`
  padding: 100px 24px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const BottomContent = styled.div`
  display: flex;

  button + button {
    margin-inline-start: 24px;
  }
`;

export const EventsViewComponent = ({
  corporate,
  action,
  eventsData,
  eventStatuses,
  onCreateEventClick,
  onCloseClick,
  onSuccess,
  onSuccessAndOpenInviteEventUsers,
  onLearnMoreClick,
  openEventTutorialVideo,
  onEventClick,
  onStatusesChange,
  isMediumView,
  isEmpty,
  isFetchingEvents,
  eventsError,
}: Props) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <ViewEventComponent event={eventsView} />
      <View
        title={formatMessage({ id: 'views.events.title' })}
        subtitle={formatMessage({ id: 'views.events.subtitle' })}
        bottomContent={
          <BottomContent>
            <TextButton icon={<VideoPlayer />} onClick={openEventTutorialVideo}>
              {formatMessage({ id: 'events.tutorial-video' })}
            </TextButton>
            <TextButton icon={<Note />} onClick={onLearnMoreClick}>
              {formatMessage({ id: 'events.learn-more' })}
            </TextButton>
          </BottomContent>
        }
        rightContent={
          <Button variant="blue" size="small" onClick={onCreateEventClick}>
            {formatMessage({ id: 'events.create-event-button' })}
          </Button>
        }
      >
        {isEmpty ? (
          <EmptyContainer>
            <Empty
              title={formatMessage({ id: 'events.no-events-title' })}
              description={formatMessage({ id: 'events.no-events-subtitle' })}
              footer={
                <TextButton onClick={onCreateEventClick} variant="blue">
                  {formatMessage({ id: 'events.create-event-button' })}
                </TextButton>
              }
            />
          </EmptyContainer>
        ) : (
          <>
            <Panel eventStatuses={eventStatuses} onChange={onStatusesChange} />
            {isMediumView ? (
              <EventsDataList
                onDataItemClick={(event) => onEventClick(event.id)}
                data={eventsData}
                isLoading={isFetchingEvents}
                error={eventsError}
              />
            ) : (
              <EventsDataTable
                onEventClick={onEventClick}
                eventsData={eventsData}
                isLoading={isFetchingEvents}
                error={eventsError}
              />
            )}
          </>
        )}
      </View>

      {corporate && (
        <CreateEventModal
          currency={corporate.currency}
          show={action === 'create-event'}
          onCloseClick={onCloseClick}
          onSucess={onSuccess}
          onSuccessAndOpenInviteEventUsers={onSuccessAndOpenInviteEventUsers}
        />
      )}
    </>
  );
};
