import { useEffect, useState, useLayoutEffect, useCallback } from 'react';
import styled from 'styled-components';
import ResizeObserver from 'resize-observer-polyfill';

interface Props {
  element: HTMLElement | null;
  isInteractable: boolean;
}

const Blocker = styled.div`
  pointer-events: all;
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  z-index: 1000;
`;

const Root = styled.div`
  position: absolute;
  z-index: 1001;
  box-shadow: 0 0 0 99999px rgb(0 0 0 / 60%);
`;

const useElementBoundingClientRect = (element: HTMLElement | null) => {
  const [offsets, setOffsets] = useState<DOMRect | null>(null);

  const handleResize = useCallback(() => {
    if (!element) return;

    setOffsets(element.getBoundingClientRect());
  }, [element]);

  useEffect(() => {
    if (element) {
      const observer = new ResizeObserver(handleResize);
      observer.observe(element);

      window.addEventListener('resize', handleResize);

      return () => {
        observer.disconnect();
        observer.unobserve(element);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [element, handleResize]);

  useLayoutEffect(() => {
    if (element) {
      setOffsets(element.getBoundingClientRect());
    }
  }, [element]);

  return offsets;
};

const HightlightedElementWrapper: React.FC<Props> = ({ element, isInteractable }) => {
  const { body } = document;

  const boundingClientRect = useElementBoundingClientRect(element);

  if (!boundingClientRect || !element) {
    return (
      <Root
        style={{
          width: '0px',
          height: '0px',
          top: '0px',
          left: '0px',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          pointerEvents: 'all',
        }}
      />
    );
  }

  const { width, left, top, height } = boundingClientRect;
  const { offsetWidth, offsetHeight, offsetTop, offsetLeft } = element;

  return (
    <>
      <Root
        key={element.innerHTML}
        style={{
          borderRadius: '8px',
          width: `${offsetWidth}px`,
          height: `${offsetHeight}px`,
          top: `${offsetTop || top}px`,
          left: `${offsetLeft || left}px`,
          pointerEvents: isInteractable ? 'none' : 'all',
        }}
      />

      {/* Block top section  */}
      <Blocker
        style={{
          right: '0px',
          height: `${offsetTop || top}px`,
        }}
      />

      {/* Block bottom section  */}
      <Blocker
        style={{
          top: `${(offsetTop || top) + (offsetHeight || height)}px`,
          height: `${body.scrollHeight - ((offsetTop || top) + (offsetHeight || height))}px`,
        }}
      />

      {/* Block right section  */}
      <Blocker
        style={{
          left: `${left + width}px`,
          height: `${body.scrollHeight}px`,
          width: `${body.scrollWidth - (left + width)}px`,
        }}
      />

      {/* Block left section  */}
      <Blocker
        style={{
          width: `${left}px`,
          height: `${body.scrollHeight}px`,
        }}
      />
    </>
  );
};

export default HightlightedElementWrapper;
