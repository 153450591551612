import { Alert } from '@creditornot/cb-alert';

import { FormRowV2, FormSection, FormikInputField } from 'components';
import { isRequired, createValidator } from 'validation';

import LocalBankAccountsField from './LocalBankAccountsField';

type Props = {
  alwaysShowError?: boolean;
};

const validate = createValidator([isRequired]);

const WoltBankAccountFormSection: React.FC<Props> = ({ alwaysShowError }) => {
  return (
    <FormSection title="Wolt account details">
      <FormRowV2
        htmlFor="wolt_company_account_iban"
        title="Wolt company account IBAN"
        content={
          <FormikInputField
            name="wolt_company_account_iban"
            type="text"
            required={true}
            validate={validate}
            alwaysShowError={alwaysShowError}
          />
        }
      />
      <FormRowV2
        htmlFor="wolt_company_account_bic"
        title="Wolt company account BIC"
        content={
          <>
            <FormikInputField
              name="wolt_company_account_bic"
              type="text"
              required={true}
              validate={validate}
              alwaysShowError={alwaysShowError}
            />
            <LocalBankAccountsField />
          </>
        }
      />
      <Alert size="small" variant="info">
        Information will be shown in the invoice&apos;s payment instruction section{' '}
      </Alert>
    </FormSection>
  );
};

export default WoltBankAccountFormSection;
