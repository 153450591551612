import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Spinner } from '@creditornot/cb-progress';

import { useDebounce, useInfiniteScroll } from 'modules/hooks';

import { Props as DataListProps, DataList } from './DataList';
import ResponsiveContainer from '../ResponsiveContainer';

type Props<DataItem, DataCellProps> = {
  clearDataOn: string[];
  getSortBy: (d1: DataItem, d2: DataItem) => 1 | -1;
  loadData: () => Promise<DataItem[]>;
  onLoadDataSuccess: () => void;
  onRefresh: () => void;
  updateOn?: number;
  hideSpinner?: boolean;
} & Pick<
  DataListProps<DataItem, DataCellProps>,
  | 'className'
  | 'getDataItemId'
  | 'noData'
  | 'onDataItemClick'
  | 'onDataItemSelectionClick'
  | 'renderDataItem'
  | 'selectedDataItems'
  | 'renderDataItemProps'
>;

const WayPoint = styled.div`
  margin-top: -20px;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpinnerWrapper = styled(ResponsiveContainer)`
  padding-top: 22px;
  padding-bottom: 22px;
  justify-content: center;
`;

function InfiniteScrollDataList<DataItem extends Record<string, any>, DataCellProps>({
  className,
  clearDataOn,
  getDataItemId,
  getSortBy,
  loadData,
  noData,
  onDataItemClick,
  onDataItemSelectionClick,
  onLoadDataSuccess,
  onRefresh,
  renderDataItem,
  renderDataItemProps,
  updateOn = 2000,
  hideSpinner,
  selectedDataItems,
}: Props<DataItem, DataCellProps>) {
  const [loading, setLoading] = useState(false);
  const hasMoreRef = useRef(true);
  const [error, setError] = useState(null);
  const wayPointRef = useRef<HTMLDivElement | null>(null);
  const clearDataOnValues = useDebounce(
    clearDataOn.length ? clearDataOn.join('-') : undefined,
    500,
  );

  const [data, dispatch] = useInfiniteScroll<DataItem>({
    node: wayPointRef.current,
    getItemId: getDataItemId,
    updateOn,
    getSortBy,
    onIntersect: (dispatch) => {
      if (!hasMoreRef.current || loading) return;
      setLoading(true);
      loadData()
        .then((payload) => {
          if (payload.length === 0) {
            hasMoreRef.current = false;
            setLoading(false);
            return;
          }
          dispatch({ type: 'ADD_ITEM', payload });
          setLoading(false);
          onLoadDataSuccess();
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    dispatch({ type: 'CLEAR' });
    setError(null);
    hasMoreRef.current = true;
    setLoading(false);
    onRefresh();
  }, [clearDataOnValues, dispatch, onRefresh]);

  const showIsLoadingMore = loading;
  const showNoData = !loading && data.length === 0 && !hasMoreRef.current;

  return (
    <Root className={className}>
      <DataList
        data={data}
        getDataItemId={getDataItemId}
        loading={false}
        noData={showNoData ? noData : null}
        error={error}
        renderDataItem={renderDataItem}
        onDataItemClick={onDataItemClick}
        onDataItemSelectionClick={onDataItemSelectionClick}
        renderDataItemProps={renderDataItemProps}
        selectedDataItems={selectedDataItems}
      />
      <SpinnerWrapper>
        {showIsLoadingMore && !hideSpinner && <Spinner size="large" />}
      </SpinnerWrapper>
      <WayPoint ref={wayPointRef} />
    </Root>
  );
}

export default InfiniteScrollDataList;
