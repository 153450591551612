import * as t from 'io-ts';

export type PayoutInterval = t.TypeOf<typeof PayoutInterval>;
export const PayoutInterval = t.keyof({
  hexa_monthly: null,
  semi_monthly: null,
  weekly: null,
  monthly: null,
  custom: null,
});

export type PayoutStatus = t.TypeOf<typeof PayoutStatus>;
export const PayoutStatus = t.keyof({
  sent_to_bank: null,
  in_progress: null,
  kyc_failed: null,
  not_applicable: null,
});

export type VenuePayout = t.TypeOf<typeof VenuePayout>;
export const VenuePayout = t.type({
  id: t.string,
  country: t.string,
  currency: t.string,
  doNotPay: t.boolean,
  endDate: t.string,
  interval: PayoutInterval,
  intervalNumber: t.number,
  numberOfPurchases: t.number,
  payoutAmount: t.number,
  startDate: t.string,
  toAccount: t.string,
  transferStatus: PayoutStatus,
  venue: t.string,
  year: t.number,
  invoiceDossierExists: t.boolean,
  csvReportExists: t.boolean,
  isMpsPayout: t.boolean,
  endToEndId: t.number,
});
