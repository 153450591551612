import * as t from 'io-ts';

import { SpendingLimitPeriod } from 'modules/common/types';

export type InviteGroup = t.TypeOf<typeof InviteGroup>;
export const InviteGroup = t.intersection([
  t.type({
    active: t.boolean,
    add_to_all: t.boolean,
    created_at: t.string,
    description: t.string,
    id: t.string,
    is_default: t.boolean,
    modified_at: t.string,
    name: t.string,
    policies: t.array(
      t.type({
        budget_allowance: t.union([
          t.type({
            period: SpendingLimitPeriod,
            amount: t.number,
            currency: t.string,
            exclude_delivery_fee: t.boolean,
          }),
          t.null,
        ]),
        created_at: t.string,
        delivery_areas: t.array(
          t.type({
            created_at: t.string,
            description: t.string,
            id: t.string,
            latitude: t.number,
            longitude: t.number,
            modified_at: t.string,
            name: t.string,
            radius_meters: t.number,
          }),
        ),
        description: t.string,
        giftcard_purchases_allowed: t.boolean,
        id: t.string,
        modified_at: t.string,
        name: t.string,
        order_comment_required: t.boolean,
        non_restaurant_purchases_allowed: t.boolean,
        times: t.array(
          t.type({
            created_at: t.string,
            start_minute: t.number,
            duration_minutes: t.number,
            id: t.string,
            end_minute: t.number,
            modified_at: t.string,
            policy_id: t.string,
            day_of_week: t.keyof({
              monday: null,
              tuesday: null,
              wednesday: null,
              thursday: null,
              friday: null,
              saturday: null,
              sunday: null,
            }),
          }),
        ),
      }),
    ),
  }),
  t.partial({
    user_count: t.number,
  }),
]);

export type UserInvite = t.TypeOf<typeof UserInvite>;
export const UserInvite = t.type({
  corporate_id: t.string,
  created_at: t.string,
  email: t.string,
  event_id: t.union([t.null, t.string]),
  groups: t.array(InviteGroup),
  id: t.string,
  is_corporate_manager: t.boolean,
  is_valid: t.boolean,
  modified_at: t.string,
  used_at: t.union([t.null, t.string]),
  used_by_user_id: t.union([t.null, t.string]),
  valid_until: t.string,
});
