import { useCallback, useMemo } from 'react';
import { Empty } from '@creditornot/cb-placeholders';
import styled from 'styled-components';
import { TextButton } from '@creditornot/cb-button';

import { useCorporate } from 'modules/corporates';
import { useMedia } from 'modules/media';
import { LocalizedMessage, useI18n } from 'i18n';
import { UserInvite } from 'modules/invites/types';
import { PaginatedData } from 'types/PaginatedData';

import InvitesDataList from './InvitesDataList';
import InvitesDataTable from './InvitesDataTable';
import { useUsersModalsController, useUsersQueryParams } from '../hooks';

interface Props {
  invitesData: PaginatedData<UserInvite> | null | undefined;
  isLoading: boolean;
  error: unknown;
}

const Root = styled.div`
  display: flex;
  align-items: center;
  margin-top: 100px;
  justify-content: center;
`;

const InvitesDataView: React.FC<Props> = ({
  invitesData,
  error: invitesError,
  isLoading: isLoadingInvites,
}) => {
  const { data: corporate } = useCorporate();
  const { openUserInviteModal } = useUsersModalsController();
  const { getLocalizedMessage } = useI18n();
  const mediumView = useMedia('medium');
  const [{ search = '', groups = [], status = [], invites = [] }, setQueryParams] =
    useUsersQueryParams();
  const isFiltering = useMemo(
    () => search !== '' || [...groups, ...status].length > 0,
    [groups, search, status],
  );
  const handleRowSelectionChange = useCallback(
    (selectedRowKeys: string[]) => {
      setQueryParams({
        invites: selectedRowKeys,
      });
    },
    [setQueryParams],
  );

  if (!corporate) {
    return null;
  }

  const isEmpty =
    !invitesError && invitesData?.results.length === 0 && !isFiltering && !isLoadingInvites;

  if (isEmpty) {
    return (
      <Root>
        <Empty
          title={getLocalizedMessage('users.invites-view.empty.no-invites')}
          description={
            <LocalizedMessage messageKey="users.invites-view.empty.no-invites-description" />
          }
          footer={
            <TextButton onClick={openUserInviteModal}>
              <LocalizedMessage messageKey="users.invites-view.empty.invites-button" />
            </TextButton>
          }
        />
      </Root>
    );
  }

  return mediumView ? (
    <InvitesDataList
      invites={invitesData?.results}
      error={invitesError}
      isFiltering={isFiltering}
      loading={isLoadingInvites}
      selectedRowKeys={invites}
      onRowSelectionChange={handleRowSelectionChange}
    />
  ) : (
    <InvitesDataTable
      invitesData={invitesData}
      error={invitesError}
      isFiltering={isFiltering}
      loading={isLoadingInvites}
      selectedRowKeys={invites}
      onRowSelectionChange={handleRowSelectionChange}
    />
  );
};

export default InvitesDataView;
