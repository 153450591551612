import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { MenuItemGroup, MenuLinkItem } from '@creditornot/cb-menu';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';
import { Spinner } from '@creditornot/cb-progress';
import { Input } from '@creditornot/cb-input';
import { CaretDown, CaretUp, MagnifyingGlass } from '@creditornot/cb-icons';
import { TextButton } from '@creditornot/cb-button';
import { Avatar } from '@creditornot/cb-avatar';
import { Alert } from '@creditornot/cb-alert';
import { typographyCss } from '@creditornot/cb-ingredients';
import { FormattedMessage, useIntl } from 'react-intl';

import { breakpoints } from 'modules/media';
import { useFetch } from 'modules/react-query';
import { fetchAllUsers, useFetchWoltUsers } from 'modules/users';
import { WoltUserNonNullable } from 'modules/users/types';
import { useDebounce } from 'modules/hooks';
import { processError } from 'utils';

interface Props {
  selectedUser: WoltUserNonNullable | null;
  corporateId: string;
  onSelectUser: (user: WoltUserNonNullable) => void;
}

const Content = styled.div`
  max-height: 200px;
  width: 450px;
  overflow-y: auto;
  padding: 8px;

  @media (max-width: ${breakpoints.small}px) {
    width: 300px;
  }
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  margin-inline-start: 8px;
`;

const StyledMenuLinkItem = styled(MenuLinkItem)<{ disabled: boolean }>`
  margin-top: 8px;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`;

const Text = styled.span`
  ${typographyCss.Body3()}
  font-weight: 500;
`;

const SubText = styled.small`
  ${typographyCss.Small()}
`;

export const AsyncUserSelect = ({ corporateId, selectedUser, onSelectUser }: Props) => {
  const { formatMessage } = useIntl();

  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false);
  const debouncedSearch = useDebounce(search, 500);

  const {
    data: corporateUsers,
    error: corporateUsersError,
    isFetching: corporateUsersLoading,
  } = useFetch({
    queryKey: ['users', corporateId],
    queryFn: () => fetchAllUsers(corporateId),
  });

  const {
    data: woltUsers,
    error: woltUsersError,
    isFetching: woltUsersLoading,
  } = useFetchWoltUsers(debouncedSearch, {
    enabled: debouncedSearch.length > 2,
  });

  const isSearching = corporateUsersLoading || woltUsersLoading;

  const corporateUserIds = useMemo(
    () => corporateUsers?.map((corporateUser) => corporateUser.user_id),
    [corporateUsers],
  );

  const error = corporateUsersError || woltUsersError;

  const options = useMemo(
    () =>
      woltUsers?.map((woltuser) => ({
        disabled: isSearching || corporateUserIds?.includes(woltuser.user.id.$oid),
        id: woltuser.user.id.$oid,
        label: woltuser.user.email,
        value: woltuser,
      })),
    [corporateUserIds, isSearching, woltUsers],
  );

  return (
    <Popover
      show={show}
      placement="bottom-start"
      contentZIndex={2}
      showOnHover={false}
      renderArrow={false}
      onClickOutside={() => setShow(false)}
      content={
        <Content>
          <MenuItemGroup>
            <Input
              value={search}
              placeholder={formatMessage({ id: 'admins.add-admin-modal.select-user-placeholder' })}
              icon={isSearching ? <Spinner size="small" /> : <MagnifyingGlass />}
              onChange={(e) => setSearch(e.target.value)}
              autoFocus
            />

            {!!error && (
              <Alert variant="error" title={processError(error).message}>
                {processError(error).data}
              </Alert>
            )}

            {options?.length === 0 && !isSearching && (
              <FormattedMessage id="common.no-users-found" />
            )}

            {options?.map((option) => (
              <StyledMenuLinkItem
                key={option.id}
                disabled={!!option.disabled}
                onClick={() => {
                  onSelectUser(option.value);
                  setShow(false);
                }}
              >
                <ItemContent>
                  <Avatar size="medium" imgSrc={option.value.user.profile_picture.url} />
                  <Name>
                    <div>{`${option.value.user.name.first_name} ${option.value.user.name.last_name}`}</div>
                    <div>{option.value.user.email}</div>
                    {option.disabled && (
                      <SubText>
                        <FormattedMessage id="admins.add-admin-modal.already-a-member" />
                      </SubText>
                    )}
                  </Name>
                </ItemContent>
              </StyledMenuLinkItem>
            ))}
          </MenuItemGroup>
        </Content>
      }
    >
      <TextButton
        variant="blue"
        iconPosition="right"
        onClick={() => setShow((state) => !state)}
        icon={show ? <CaretUp /> : <CaretDown />}
      >
        <Text>
          {selectedUser ? (
            `${selectedUser.user.name.first_name} ${selectedUser.user.name.last_name}`
          ) : (
            <FormattedMessage id="admins.add-admin-modal.select-user" />
          )}
        </Text>
      </TextButton>
    </Popover>
  );
};
