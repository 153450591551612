import { parseIntoCents } from 'i18n';

import roundToPlaces from './roundToPlaces';

const parseAmount = (amount: string): number => parseFloat(amount.replace(',', '.'));

export const convertFromAmountToCents = (amount: string | number, currency: string) => {
  if (typeof amount === 'string') {
    return roundToPlaces(0, parseIntoCents(parseAmount(amount), currency));
  } else {
    return roundToPlaces(0, parseIntoCents(amount, currency));
  }
};
