import { useFormikContext } from 'formik';
import { set, isPast } from 'date-fns';
import { useCallback } from 'react';

import { FormikTimeSelect } from 'components';
import { TimeOption, FormikTimeSelectProps } from 'components/FormikTimeSelect';
import { useI18n } from 'i18n';
import { isRequired } from 'validation';

import { CreateEventFormData } from '../types';

export const FormikEventStartTimeInputSelect: React.FC<Partial<FormikTimeSelectProps>> = ({
  timeSelectProps,
  ...props
}) => {
  const { getLocalizedMessage } = useI18n();
  const {
    values: { start_date },
  } = useFormikContext<CreateEventFormData>();
  const getDisabledTime = useCallback(
    ({ value }: TimeOption) => {
      if (!start_date) return true;
      return isPast(
        set(start_date, {
          minutes: Number.parseInt(value),
          hours: 0,
          seconds: 0,
        }),
      );
    },
    [start_date],
  );

  return (
    <FormikTimeSelect
      validate={isRequired}
      name="start_minutes"
      timeSelectProps={{
        placeholder: getLocalizedMessage('common.time-picker-placeholder'),
        maxMenuHeight: 140,
        ...timeSelectProps,
      }}
      getDisabledTime={getDisabledTime}
      {...props}
    />
  );
};
