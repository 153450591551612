// Generated by Avo VERSION 124.33.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value }
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('')
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode)
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse)
    };

    // @ts-ignore
    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read')
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit })
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } })
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = "prod",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: any,
    groupTypesToGroupIds: Record<string, string>,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: any,
  shapeUserProps?: any,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "DSe3uVFW2GVbIYdGuiga";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target: any, _varArgs: any) { // .length of function is 2
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

function convertPropertiesArrayToMap(propertiesArray: [{id: string, name: string, value: string}]): {string: string | null | undefined} {
    let result: {string: string} = {}

    propertiesArray.forEach(value => {
      result[value.name] = value.value
    })

    return result
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "ZckwzWYeYEEKcFc8PVAX",
          "br": "master",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "Qxw4SpNZAmmYOzZQPcag",
          "se": (new Date()).toISOString(),
          "so": "eCf7qF1V2",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "ZckwzWYeYEEKcFc8PVAX",
          "br": "master",
          "en": env,
          "ty": type,
          "sc": "Qxw4SpNZAmmYOzZQPcag",
          "se": (new Date()).toISOString(),
          "so": "eCf7qF1V2",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "Qxw4SpNZAmmYOzZQPcag",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[], groupProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export const Resource = {
  'WHAT_ARE_EVENTS': 'what_are_events',
  'HOW_TO_JOIN_TEAM': 'how_to_join_team',
  'LEARN_HOW_EVENTS_WORK': 'learn_how_events_work',
  'PLACE_ORDERS_WITH_INVOICE': 'place_orders_with_invoice',
  'HOW_TO_ORDER': 'how_to_order',
  'INVOICE': 'invoice',
  'ORDER_LIST_CSV': 'order_list_csv',
  'ORDER_LIST_PDF': 'order_list_pdf',
  'RECEIPTS': 'receipts',
  'VAT_REPORT': 'vat_report',
  'PAYOUT_REPORT_CSV': 'payout_report_csv',
  'PAYOUT_REPORT_PDF': 'payout_report_pdf',
} as const;
export type ResourceType = typeof Resource;
export type ResourceValueType = ResourceType[keyof ResourceType];

export const InviteMethod = {
  'ADD_EXISTING_MEMBERS': 'add_existing_members',
  'MANUAL_ENTRY': 'manual_entry',
  'CSV_IMPORT': 'csv_import',
} as const;
export type InviteMethodType = typeof InviteMethod;
export type InviteMethodValueType = InviteMethodType[keyof InviteMethodType];

export const ReportType = {
  'INVOICE': 'invoice',
  'PAYOUT': 'payout',
} as const;
export type ReportTypeType = typeof ReportType;
export type ReportTypeValueType = ReportTypeType[keyof ReportTypeType];

export const LoginOption = {
  'FACEBOOK': 'facebook',
  'APPLE': 'apple',
  'GOOGLE': 'google',
  'EMAIL_LINK': 'email_link',
} as const;
export type LoginOptionType = typeof LoginOption;
export type LoginOptionValueType = LoginOptionType[keyof LoginOptionType];

export const Source = {
  'HOME': 'home',
  'EVENTS': 'events',
  'GROUPS': 'groups',
  'INVOICES': 'invoices',
  'MEMBERS': 'members',
  'ORDERS': 'orders',
  'POLICIES': 'policies',
  'COMPANY_SETTINGS': 'company_settings',
  'DELIVERIES': 'deliveries',
  'PAYOUTS': 'payouts',
  'ALL_DELIVERIES': 'all_deliveries',
  'BEING_DELIVERED': 'being_delivered',
  'SCHEDULED_DELIVERIES': 'scheduled_deliveries',
  'DELIVERED': 'delivered',
} as const;
export type SourceType = typeof Source;
export type SourceValueType = SourceType[keyof SourceType];

export const View = {
  'CURRENT_TASK': 'current_task',
  'NEW_TASK': 'new_task',
  'PURCHASES': 'purchases',
  'BUSINESS_INSIGHTS': 'business_insights',
  'MENU_EDITOR': 'menu_editor',
  'LOGIN_PAGE': 'login_page',
  'ONBOARDING_LANDING_PAGE': 'onboarding_landing_page',
  'ONBOARDING_REGISTRATION_PAGE': 'onboarding_registration_page',
  'FRICTION_TO_REJECT': 'friction_to_reject',
  'SUPPORT_LAYER': 'support_layer',
  'SUPPORT_INBOX_TICKET': 'support_inbox_ticket',
  'SUPPORT_INBOX': 'support_inbox',
  'FEATURED': 'featured',
  'CHECKOUT': 'checkout',
  'CHAT': 'chat',
  'NEW_CHAT_MESSAGE': 'new_chat_message',
  'VENUE': 'venue',
  'ONBOARDING_CHOOSE_CONTRACT': 'onboarding_choose_contract',
  'ONBOARDING_CHOOSE_COUNTRY': 'onboarding_choose_country',
  'ONBOARDING_SELECT_CONTRACT': 'onboarding_select_contract',
  'ONBOARDING_SELECT_DOCUMENT': 'onboarding_select_document',
  'ONBOARDING_UPLOAD_DOCUMENT': 'onboarding_upload_document',
  'ONBOARDING_CONTINUE_VERIFICATION': 'onboarding_continue_verification',
  'ONBOARDING_DOCUMENT_VERIFICATION_CITIZENSHIP': 'onboarding_document_verification_citizenship',
  'ONBOARDING_IDENTITY_VERIFICATION': 'onboarding_identity_verification',
  'ONBOARDING_WAITING_FOR_CONTRACT': 'onboarding_waiting_for_contract',
  'ONBOARDING_VERIFICATION_ERROR': 'onboarding_verification_error',
  'ONBOARDING_VERIFICATION_STATUS': 'onboarding_verification_status',
  'ONBOARDING_OVERVIEW': 'onboarding_overview',
  'ONBOARDING_CONTINUE_TO_CONTRACT': 'onboarding_continue_to_contract',
  'ENTERPRISE_ANALYTICS': 'enterprise_analytics',
  'PERFORMANCE_INSIGHTS': 'performance_insights',
  'REASONS_FOR_REJECTION': 'reasons_for_rejection',
  'HOMEPAGE': 'homepage',
  'SUPPORT_INBOX_CONVERSATION': 'support_inbox_conversation',
  'IMPORTANT_UPDATES': 'important_updates',
  'ALL_ITEMS': 'all_items',
  'ALL_OPTIONS': 'all_options',
  'ALL_DISABLED': 'all_disabled',
  'ITEMS_NO_CATEGORY': 'items_no_category',
  'CATEGORIES': 'categories',
  'CAMPAIGNS': 'campaigns',
  'CHOOSE_CAMPAIGN': 'choose_campaign',
  'NEW_CAMPAIGN': 'new_campaign',
  'NEW_CAMPAIGN_RULES': 'new_campaign_rules',
  'UPDATE_CAMPAIGN': 'update_campaign',
  'ONBOARDING_REGISTRATION_DETAILS': 'onboarding_registration_details',
  'ONBOARDING_APPLICATION': 'onboarding_application',
  'ONBOARDING_DELIVERY_PREFERENCES': 'onboarding_delivery_preferences',
  'ONBOARDING_SMS_VERIFICATION': 'onboarding_sms_verification',
  'ONBOARDING_GET_GEAR': 'onboarding_get_gear',
  'ONBOARDING_FINAL_SCREEN': 'onboarding_final_screen',
  'BUSINESS_INSIGHTS_V2': 'business_insights_v2',
  'ENTERPRISE_USER_MANAGEMENT': 'enterprise_user_management',
  'CART': 'cart',
  'VENUE_ITEM': 'venue_item',
  'APP_REFERRAL_BANNER': 'app_referral_banner',
  'APP_REFERRAL_LINK': 'app_referral_link',
  'BRAND_EDITOR': 'brand_editor',
  'CUTLERY_ITEM': 'cutlery_item',
  'CATALOG_LIST': 'catalog_list',
  'LOYALTY_PROGRAM': 'loyalty_program',
  'ADD_LOYALTY_CARD': 'add_loyalty_card',
  'LOYALTY_CARD_DETAILS': 'loyalty_card_details',
  'MENU_CATEGORY': 'menu_category',
  'SEARCH': 'search',
  'RESTAURANTS': 'restaurants',
  'LIVE_PURCHASES': 'live_purchases',
  'RESTAURANT_DETAILS': 'restaurant_details',
  'STARTUP': 'startup',
  'PAGE_DELIVERY_VENUES': 'page_delivery_venues',
  'VENUE_SEARCH': 'venue_search',
  'ADD_NEW_ADDRESS': 'add_new_address',
  'CREDITS_AND_TOKENS': 'credits_and_tokens',
  'ADD_CARD': 'add_card',
  'ADD_LOYALTY_NUMBER': 'add_loyalty_number',
  'ADDRESS_TYPE': 'address_type',
  'ADDRESSES': 'addresses',
  'AGE_RESTRICTION_NOTIFICATION': 'age_restriction_notification',
  'AGE_VERIFICATION': 'age_verification',
  'CANCEL': 'cancel',
  'CAROUSEL_ITEM_LIST_SEARCH': 'carousel_item_list_search',
  'CODE_NOT_RECEIVED': 'code_not_received',
  'COMMENT_FOR_VENUE': 'comment_for_venue',
  'CONFIRM_CARD': 'confirm_card',
  'COURIERAPPLICATION_APPLICATION_STATUS': 'courierapplication_application_status',
  'COURIERAPPLICATION_APPLYNOW': 'courierapplication_applyNow',
  'COURIERAPPLICATION_DELIVERY_DETAILS': 'courierapplication_delivery_details',
  'COURIERAPPLICATION_DONE': 'courierapplication_done',
  'COURIERAPPLICATION_FRONT': 'courierapplication_front',
  'COURIERAPPLICATION_PROFILE_DETAILS': 'courierapplication_profile_details',
  'CREATE_GROUP': 'create_group',
  'CREDITS_AND_TOKENS_DETAILS': 'credits_and_tokens_details',
  'DELETE_ACCOUNT': 'delete_account',
  'DELETE_ACCOUNT_REQUESTED': 'delete_account_requested',
  'DISCOVERY_OOR_MAP': 'discovery_oor_map',
  'EARN_FREE_DELIVERIES': 'earn_free_deliveries',
  'EASTER_EGG_V1': 'easter_egg_v1',
  'EDIT_CARD': 'edit_card',
  'EMAIL': 'email',
  'EMAIL_CODE': 'email_code',
  'EMAIL_CODE_NOT_RECEIVED': 'email_code_not_received',
  'EMAIL_LOGIN_PROGRESS': 'email_login_progress',
  'EMAIL_NOT_RECEIVED': 'email_not_received',
  'ENTER_EMAIL': 'enter_email',
  'ENTER_PHONE_NUMBER': 'enter_phone_number',
  'ENTER_VERIFICATION_CODE': 'enter_verification_code',
  'ENTRANCE_LOCATION': 'entrance_location',
  'ERROR': 'error',
  'EXTRA_CONSENTS': 'extra_consents',
  'FILTERS': 'filters',
  'FRONT_PAGE': 'front_page',
  'GROUP_INTRO': 'group_intro',
  'GROUP_LOBBY': 'group_lobby',
  'GROUP_LOGIN_OPTIONS': 'group_login_options',
  'GROUP_ORDER_LOGIN': 'group_order_login',
  'GROUP_ORDER_PAYMENT_DETAILS': 'group_order_payment_details',
  'GUEST_CONSENTS': 'guest_consents',
  'HUNGARY_GROCERY_NOTICE': 'hungary_grocery_notice',
  'IMAGE_ZOOM': 'image_zoom',
  'INPUT_CODE': 'input_code',
  'INTERSTITIAL_ITEM': 'interstitial_item',
  'INTRO': 'intro',
  'INVITE_FRIENDS': 'invite_friends',
  'INVITE_FRIENDS_DETAILS': 'invite_friends_details',
  'INVITE_GROUP_MEMBERS': 'invite_group_members',
  'JOIN_GROUP': 'join_group',
  'LINKING_ACCOUNT': 'linking_account',
  'LINKING_ACCOUNT_PROGRESS': 'linking_account_progress',
  'LOGIN': 'login',
  'LOYALTY_WALLET': 'loyalty_wallet',
  'LOYALTY_WALLET_NEW_CARD': 'loyalty_wallet_new_card',
  'MAP': 'map',
  'MOBILE_NUMBER': 'mobile_number',
  'MOBILE_NUMBER_CODE': 'mobile_number_code',
  'MOBILE_NUMBER_CODE_NOT_RECEIVED': 'mobile_number_code_not_received',
  'NEW_VERSION_DIALOG': 'new-version-dialog',
  'NEW_ADDRESS': 'new_address',
  'NO_LOCATION': 'no_location',
  'ORDER_HISTORY': 'order_history',
  'ORDER_TYPE': 'order_type',
  'PAGE_CURATED_CONTENT': 'page_curated_content',
  'PAYMENT_METHOD_PICKER': 'payment_method_picker',
  'PAYMENT_METHODS': 'payment_methods',
  'PERSONAL_INFO': 'personal_info',
  'POSTCODE_ADDRESS_SEARCH_STEP1_POSTCODE': 'postcode_address_search_step1_postcode',
  'POSTCODE_ADDRESS_SEARCH_STEP2_ADDRESS': 'postcode_address_search_step2_address',
  'POSTCODE_ADDRESS_SEARCH_STEP3_MAP': 'postcode_address_search_step3_map',
  'PRISMIC_PAGE': 'prismic_page',
  'PROFILE_ACCOUNT': 'profile_account',
  'PROFILE_ACCOUNT_NAME': 'profile_account_name',
  'PROMO_CODE_APPLIED': 'promo_code_applied',
  'PURCHASE_SESSION_SUBSCRIPTION': 'purchase_session_subscription',
  'PURCHASE_SESSION_TIP_ORDER_REVIEW': 'purchase_session_tip_order_review',
  'PUSH_PERMISSION_GENERAL': 'push_permission_general',
  'PUSH_PERMISSION_ORDER': 'push_permission_order',
  'REDEEM_CODE': 'redeem_code',
  'REFERRAL_AFTER_PURCHASE': 'referral_after_purchase',
  'REFILL': 'refill',
  'REQUEST_EMAIL_PROGRESS': 'request_email_progress',
  'REQUEST_EMAIL_SUCCESS': 'request_email_success',
  'REQUEST_VAT_RECEIPT': 'request_vat_receipt',
  'SEARCH_LOCATION': 'search_location',
  'SEARCH_SUGGESTIONS': 'search_suggestions',
  'SEND_ORDER': 'send_order',
  'SETTINGS': 'settings',
  'SIGN_UP_FORM': 'sign_up_form',
  'SIGN_UP_PROGRESS': 'sign_up_progress',
  'SIGNUP_VERIFY_SMS_CODE': 'signup_verify_sms_code',
  'SMS_CODE_PROGRESS': 'sms_code_progress',
  'SOCIAL_LOGIN_PROGRESS': 'social_login_progress',
  'SUBSCRIPTION_CANCEL': 'subscription_cancel',
  'SUBSCRIPTION_CANCEL_SURVEY': 'subscription_cancel_survey',
  'SUBSCRIPTION_CHANGE_PAYMENT_METHOD': 'subscription_change_payment_method',
  'SUBSCRIPTION_MANAGE': 'subscription_manage',
  'SUBSCRIPTION_MANAGE_DETAILS': 'subscription_manage_details',
  'SUBSCRIPTION_PAYMENT_CYCLE_PICKER': 'subscription_payment_cycle_picker',
  'SUBSCRIPTION_PAYMENTS_HISTORY': 'subscription_payments_history',
  'SUBSCRIPTION_PAYMENTS_HISTORY_DETAILS': 'subscription_payments_history_details',
  'SUBSCRIPTION_PURCHASE': 'subscription_purchase',
  'SUBSCRIPTION_PURCHASE_CONFIRM': 'subscription_purchase_confirm',
  'SUBSTITUTIONS': 'substitutions',
  'SUGGESTED_ADDRESS': 'suggested_address',
  'UNKNOWN': 'unknown',
  'VENUE_INFO': 'venue_info',
  'WAW_INVALID_INVITATION_POPUP': 'waw_invalid_invitation_popup',
  'WAW_USER_POPUP': 'waw_user_popup',
  'WAW_VISITOR_POPUP': 'waw_visitor_popup',
  'WOLT_AT_WORK_ADVERTISE_BANNER': 'wolt-at-work-advertise-banner',
  'WOLT_AT_WORK_CONTACT_FORM': 'wolt-at-work-contact-form',
  'WOLT_AT_WORK_SELF_SIGNUP_FORM': 'wolt-at-work-self-signup-form',
  'WOLT_AT_WORK_TAB': 'wolt_at_work_tab',
  'YOUR_ORDER_TRACKING': 'your_order_tracking',
  'HOME': 'home',
  'RATING': 'rating',
  'ORDER_HISTORY_DETAILS': 'order_history_details',
  'ADDRESS_FORM': 'address_form',
  'PURCHASE_SESSION_CHECKOUT': 'purchase_session_checkout',
  'MENU_CATEGORY_SEARCH': 'menu_category_search',
  'CAROUSEL_ITEM_LIST': 'carousel_item_list',
  'RETAIL': 'retail',
  'LOGIN_OPTIONS': 'login_options',
  'ORDER_TRACKING': 'order_tracking',
  'EASTER_EGG_V2': 'easter_egg_v2',
  'EMAIL_LOGIN_CONFIRMATION': 'email_login_confirmation',
  'PROFILE': 'profile',
  'SHARED_MENU': 'shared_menu',
  'APPLICATION_SENT': 'application_sent',
  'DOWNLOAD_APP': 'download_app',
  'DELIVERY_TYPE_MODAL': 'delivery_type_modal',
  'ORDERS': 'orders',
  'MEMBERS': 'members',
  'INVOICES': 'invoices',
  'DELIVERIES': 'deliveries',
  'GROUPS': 'groups',
  'POLICIES': 'policies',
  'EVENTS': 'events',
  'COMPANY_SETTINGS': 'company_settings',
  'ADJUSTMENT_ITEM_RESERVATION_MODAL': 'adjustment_item_reservation_modal',
  'REPORT_INAPPROPRIATE_BEHAVIOUR': 'report_inappropriate_behaviour',
  'CHECK_EMAIL_LOGIN': 'check_email_login',
  'MAGIC_LINK_VERIFICATION': 'magic_link_verification',
  'EMAIL_LOGIN': 'email_login',
  'LOGIN_OR_APPLY': 'login_or_apply',
  'SUBSCRIPTION_PURCHASE_OUTCOME': 'subscription_purchase_outcome',
  'SUBSCRIPTION_PURCHASE_WELCOME': 'subscription_purchase_welcome',
  'ONBOARDING_APPLICATION_SENT': 'onboarding_application_sent',
  'ONBOARDING_DOWNLOAD_APP': 'onboarding_download_app',
  'MERCHANT_SELF_SERVICE_SIGN_UP': 'merchant_self_service_sign_up',
  'VENUE_LIST': 'venue_list',
  'BANNER_LIST': 'banner_list',
  'SEARCH_MENU_ITEM_LIST': 'search_menu_item_list',
  'PROMO_CONSENTS': 'promo_consents',
  'BILLING_INFORMATION': 'billing_information',
  'ACCEPT_TERMS': 'accept_terms',
  'VERIFICATION': 'verification',
  'BARCODE_SCAN': 'barcode_scan',
  'SUBSCRIPTION_INTRO': 'subscription_intro',
  'SUBSCRIPTION_INVITE_MEMBERS': 'subscription_invite_members',
  'SUBSCRIPTION': 'subscription',
} as const;
export type ViewType = typeof View;
export type ViewValueType = ViewType[keyof ViewType];

export const ClickTarget = {
  'APPLE': 'apple',
  'EMAIL': 'email',
  'EMAIL_LINK': 'email_link',
  'FACEBOOK': 'facebook',
  'GOOGLE': 'google',
  'WOLT_FOR_WORK': 'wolt_for_work',
  'WOLT_DRIVE': 'wolt_drive',
  'ORDER_ON_WOLT': 'order_on_wolt',
  'SUPPORT': 'support',
  'GIVE_FEEDBACK': 'give_feedback',
  'ADMIN_GUIDE': 'admin_guide',
  'ORDER_ON_DRIVE': 'order_on_drive',
  'ADJUST_LATER': 'adjust_later',
  'ADJUST_INVENTORY': 'adjust_inventory',
  'SEND': 'send',
  'LOGIN_SMS': 'login_sms',
  'LOGIN_EMAIL': 'login_email',
  'APPLY': 'apply',
  'ORDER_SUPPORT': 'order_support',
  'PRODUCT_IMAGE': 'product_image',
  'ADD_ANOTHER_BATCH': 'add_another_batch',
  'ADD_TO_INVENTORY': 'add_to_inventory',
  'SHOW_MORE_INFO': 'show_more_info',
  'SHOW_SHIPMENT_INFO': 'show_shipment_info',
  'EXPIRATION_DATE': 'expiration_date',
  'UNIT_QUANTITY': 'unit_quantity',
  'ADD_TO_LOCATION': 'add_to_location',
  'MULTIPLE_POS': 'multiple_pos',
} as const;
export type ClickTargetType = typeof ClickTarget;
export type ClickTargetValueType = ClickTargetType[keyof ClickTargetType];

export const LogType = {
  'VIEW': 'view',
  'DATA': 'data',
  'INTERACTION': 'interaction',
  'IMPRESSION': 'impression',
  'ERROR': 'error',
} as const;
export type LogTypeType = typeof LogType;
export type LogTypeValueType = LogTypeType[keyof LogTypeType];

export const EventName = {
  'VENUE': 'venue',
  'LOGIN_COMPLETED': 'login_completed',
  'LOGIN_RESULT': 'login_result',
  'SIGNUP': 'signup',
  'LOGIN': 'login',
  'CONFIRM': 'confirm',
  'LEARN_MORE': 'learn_more',
  'CLICK_LINK': 'click_link',
  'RESOURCES': 'resources',
  'ORDER_DELIVERY': 'order_delivery',
  'CALENDAR': 'calendar',
  'DOWNLOAD': 'download',
  'ORDER_DETAILS': 'order_details',
  'DOWNLOAD_RECEIPT': 'download_receipt',
  'DOWNLOAD_ANNUAL_REPORT': 'download_annual_report',
  'ORDER_TRACKING': 'order_tracking',
  'LOAD': 'load',
  'GET_WOLT_PLUS': 'get_wolt_plus',
  'GET_WOLT_PLUS_CONFIRM': 'get_wolt_plus_confirm',
  'SUBSCRIPTION_RESULT_SUCCESS': 'subscription_result_success',
  'SUBSCRIPTION_RESULT_ERROR': 'subscription_result_error',
  'FETCH': 'fetch',
  'FORM_SUBMISSION_STATUS': 'form_submission_status',
  'DOWNLOAD_AGREEMENT': 'download_agreement',
  'EDGE_CAMPING_DETECTED': 'edge_camping_detected',
  'WOLT_PLUS_LEARN_MORE': 'wolt_plus_learn_more',
  'EXPAND_BENEFITS': 'expand_benefits',
  'GEO_AREA_FROM_URL': 'geo_area_from_url',
  'GO_ONLINE': 'go_online',
  'ADD_TIP': 'add_tip',
  'BARCODE_SCAN_ERROR': 'barcode_scan_error',
  'ACCEPT_AGREEMENT': 'accept_agreement',
  'INVITE_MEMBERS': 'invite_members',
} as const;
export type EventNameType = typeof EventName;
export type EventNameValueType = EventNameType[keyof EventNameType];

export const SignupOption = {
  'WOLT_DRIVE': 'wolt_drive',
  'WOLT_FOR_WORK': 'wolt_for_work',
} as const;
export type SignupOptionType = typeof SignupOption;
export type SignupOptionValueType = SignupOptionType[keyof SignupOptionType];

let WoltGatekeeper: any;

export function initAvo(options: {env: AvoEnv; webDebugger?: boolean;
  webDebuggerOptions?: webDebuggerOptions; strict?: boolean; noop?: boolean;
  reportFailureAs?: 'error' | 'warn' | 'log'; inspector?: AvoInspector;
  avoLogger?: AvoLogger}, destinationOptions: any,
  WoltGatekeeperDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }

    WoltGatekeeper = WoltGatekeeperDestination;
    if (__AVO_ENV__ === 'prod') {
      WoltGatekeeper && WoltGatekeeper.make && WoltGatekeeper.make(__AVO_ENV__, null);
    } else if (__AVO_ENV__ === 'dev') {
      WoltGatekeeper && WoltGatekeeper.make && WoltGatekeeper.make(__AVO_ENV__, null);
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for Wolt - Gatekeeper. Head to destination settings in Avo to set a staging key.");
      WoltGatekeeper && WoltGatekeeper.make && WoltGatekeeper.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

/**
 * login_page_view: This view event is triggered when user sees the login screen in corporate admin portal
 *
 * When to trigger this event:
 * 1. This trigger is fired when users sees the login portal in corporate admin
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/Zq5pwTeG1Q/trigger/Hv7u8tjkS
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/Zq5pwTeG1Q}
 */
export function loginPageView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "login_page"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Zq5pwTeG1Q", "a0e25152fcbb8dbef2b5f5a75895e56f196a0710e5b0100a4a3754bd063387fa", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("login_page_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Zq5pwTeG1Q", "login_page_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("login_page_view", eventProperties, "Zq5pwTeG1Q", "a0e25152fcbb8dbef2b5f5a75895e56f196a0710e5b0100a4a3754bd063387fa");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("login_page_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LoginPageInteractionLoginProperties {
  loginOption: LoginOptionValueType;
}
/**
 * login_page_interaction_login: This event is triggered when the user clicks on one of the log in options in the corporate admin portal login screen. Email login method-related events are sent when the user clicks on the "log in" button under the email input field
 *
 * When to trigger this event:
 * 1. This trigger is fired when users clicks one of the login options in corporate admin login page
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/L6qg00-60f/trigger/ruS39_Zw2
 *
 * @param properties the properties associatied with this event
 * @param properties.loginOption: This property describes the login option, for example google, facebook etc. chosen by the user
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/L6qg00-60f}
 */
export function loginPageInteractionLogin(
  properties: LoginPageInteractionLoginProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "YMB5McW71", name: "login_option", value: properties.loginOption});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "interaction"});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "login_page"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "login"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "L6qg00-60f", "b700db51cdcaae383d71345e8aee4651a553fdf120347928f6939caf8cc8163f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("login_page_interaction_login", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("L6qg00-60f", "login_page_interaction_login", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("login_page_interaction_login", eventProperties, "L6qg00-60f", "b700db51cdcaae383d71345e8aee4651a553fdf120347928f6939caf8cc8163f");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("login_page_interaction_login", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LoginPageInteractionSignupProperties {
  signupOption: SignupOptionValueType;
}
/**
 * login_page_interaction_signup: This event is triggered when a user clicks on the signup links for either Wolt for Work or Wolt Drive in the admin portal login page
 *
 * When to trigger this event:
 * 1. This trigger is fired when user click the sign up links in the corporate admin portal login page
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/iXzc7Lk9ai/trigger/F2XVsp0aG
 *
 * @param properties the properties associatied with this event
 * @param properties.signupOption: This property describes the signup option that the user can choose from
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/iXzc7Lk9ai}
 */
export function loginPageInteractionSignup(
  properties: LoginPageInteractionSignupProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "yyAjGDuan", name: "signup_option", value: properties.signupOption});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "interaction"});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "login_page"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "signup"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "iXzc7Lk9ai", "cb1d56968d21c213976be0fd87f755e3d30789699cb169f726abf771cce5b075", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("login_page_interaction_signup", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("iXzc7Lk9ai", "login_page_interaction_signup", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("login_page_interaction_signup", eventProperties, "iXzc7Lk9ai", "cb1d56968d21c213976be0fd87f755e3d30789699cb169f726abf771cce5b075");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("login_page_interaction_signup", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * home_view: This event is triggered when the user sees the home page of corporate admin portal
 *
 * When to trigger this event:
 * 1. This trigger is fired when the user sees home screen of corporate admin portal
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/rPA8gud1Cm/trigger/ZZO1OWPxa
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/rPA8gud1Cm}
 */
export function homeView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "home"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "rPA8gud1Cm", "68e0daa4951d50111d1933fd53d479a9a096e3c5c826e87866fff2ffc732de53", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("home_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("rPA8gud1Cm", "home_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("home_view", eventProperties, "rPA8gud1Cm", "68e0daa4951d50111d1933fd53d479a9a096e3c5c826e87866fff2ffc732de53");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("home_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LoginPageDataLoginCompletedProperties {
  loginOption: LoginOptionValueType;
}
/**
 * login_page_data_login_completed: This data event is triggered when a user successfully completes the login process in the corporate admin login page
 *
 * When to trigger this event:
 * 1. This trigger is fired when a user successfully completes login on the admin portal login page
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/uXQ6Ywzb18/trigger/Wk25Quw_2
 *
 * @param properties the properties associatied with this event
 * @param properties.loginOption: This property describes the login option, for example google, facebook etc. chosen by the user
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/uXQ6Ywzb18}
 */
export function loginPageDataLoginCompleted(
  properties: LoginPageDataLoginCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "YMB5McW71", name: "login_option", value: properties.loginOption});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "data"});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "login_page"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "login_completed"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "uXQ6Ywzb18", "b82cb77623b56a74ee42c1af973a3c09fedbac73aca8a621eb66860962dd8942", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("login_page_data_login_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("uXQ6Ywzb18", "login_page_data_login_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("login_page_data_login_completed", eventProperties, "uXQ6Ywzb18", "b82cb77623b56a74ee42c1af973a3c09fedbac73aca8a621eb66860962dd8942");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("login_page_data_login_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * email_login_confirmation_view: This view event is triggered when user sees the email login confirmation screen in corporate admin portal
 *
 * When to trigger this event:
 * 1. This view event is triggered when user sees the email login confirmation screen in corporate admin portal
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/7kocNeuQt/trigger/qnHvAiAzM
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/7kocNeuQt}
 */
export function emailLoginConfirmationView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "email_login_confirmation"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "7kocNeuQt", "2c00f8a99ac207f8ab135def7d0113c6f1e7f8ae47b43375630e783861674dcd", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("email_login_confirmation_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("7kocNeuQt", "email_login_confirmation_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("email_login_confirmation_view", eventProperties, "7kocNeuQt", "2c00f8a99ac207f8ab135def7d0113c6f1e7f8ae47b43375630e783861674dcd");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("email_login_confirmation_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * email_login_confirmation_interaction_confirm: This event is triggered when the user clicks on confirm button in the corporate admin portal email login confirmation screen.
 *
 * When to trigger this event:
 * 1. This event is triggered when the user clicks on confirm button in the corporate admin portal email login confirmation screen.
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/86ca12mFJ/trigger/J5kfqeqNX
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/86ca12mFJ}
 */
export function emailLoginConfirmationInteractionConfirm() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "interaction"});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "email_login_confirmation"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "confirm"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "86ca12mFJ", "ca2e0cddd13513a99d60357518c1d166117d85279af3b007374f7c82636a2154", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("email_login_confirmation_interaction_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("86ca12mFJ", "email_login_confirmation_interaction_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("email_login_confirmation_interaction_confirm", eventProperties, "86ca12mFJ", "ca2e0cddd13513a99d60357518c1d166117d85279af3b007374f7c82636a2154");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("email_login_confirmation_interaction_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * orders_view: This view event is triggered when the user lands on the orders page in the corporate admin
 *
 * When to trigger this event:
 * 1. This view event is triggered when the user lands on the orders page in the corporate admin
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/bFOgM5__5j/trigger/BeNGuZ9jU
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/bFOgM5__5j}
 */
export function ordersView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "orders"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "bFOgM5__5j", "5780d49b3db2446d947781cc9ade07af69ecdcb7435487c31e7a9a834734443e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("orders_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("bFOgM5__5j", "orders_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("orders_view", eventProperties, "bFOgM5__5j", "5780d49b3db2446d947781cc9ade07af69ecdcb7435487c31e7a9a834734443e");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("orders_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * members_view: This view event is triggered when the user lands on the members page in the corporate admin
 *
 * When to trigger this event:
 * 1. This view event is triggered when the user lands on the members page in the corporate admin
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/U3M9vjLqE/trigger/hK2NCF93W9
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/U3M9vjLqE}
 */
export function membersView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "members"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "U3M9vjLqE", "eb2de2b607c369a70823d67b9916d77d3f80d6876c70dd6d908a095e4ec22b77", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("members_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("U3M9vjLqE", "members_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("members_view", eventProperties, "U3M9vjLqE", "eb2de2b607c369a70823d67b9916d77d3f80d6876c70dd6d908a095e4ec22b77");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("members_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * groups_view: This view event is triggered when the user lands on the groups page in the corporate admin
 *
 * When to trigger this event:
 * 1. This view event is triggered when the user lands on the groups page in the corporate admin
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/av5UKtbpW/trigger/UhfcVpL9pZ
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/av5UKtbpW}
 */
export function groupsView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "groups"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "av5UKtbpW", "f616985de9cb9b05dcbf452e49243c09a0c9291293041e60cb21349bc68cf75e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("groups_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("av5UKtbpW", "groups_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("groups_view", eventProperties, "av5UKtbpW", "f616985de9cb9b05dcbf452e49243c09a0c9291293041e60cb21349bc68cf75e");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("groups_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * policies_view: This view event is triggered when the user lands on the policies page in the corporate admin
 *
 * When to trigger this event:
 * 1. This view event is triggered when the user lands on the policies page in the corporate admin
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/jFN1xpTBF/trigger/aqT_3FHMe_
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/jFN1xpTBF}
 */
export function policiesView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "policies"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "jFN1xpTBF", "df3dc1226b76111d569f919aab723fffa0e1ee632fceca34bf6e992e4358dda8", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("policies_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("jFN1xpTBF", "policies_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("policies_view", eventProperties, "jFN1xpTBF", "df3dc1226b76111d569f919aab723fffa0e1ee632fceca34bf6e992e4358dda8");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("policies_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * events_view: This view event is triggered when the user lands on the events page in the corporate admin
 *
 * When to trigger this event:
 * 1. This view event is triggered when the user lands on the events page in the corporate admin
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/O4PDxHfIQ/trigger/bDUwxWLOsI
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/O4PDxHfIQ}
 */
export function eventsView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "events"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "O4PDxHfIQ", "17f1213ef05ddd0bf92fa5cbd655c01abd00c7a8d0b3db6d3b7848869868e009", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("events_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("O4PDxHfIQ", "events_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("events_view", eventProperties, "O4PDxHfIQ", "17f1213ef05ddd0bf92fa5cbd655c01abd00c7a8d0b3db6d3b7848869868e009");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("events_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * invoices_view: This view event is triggered when the user lands on the invoices page in the corporate admin
 *
 * When to trigger this event:
 * 1. This view event is triggered when the user lands on the invoices page in the corporate admin
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/cBIhoUc3H/trigger/V2rKGbIyPp
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/cBIhoUc3H}
 */
export function invoicesView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "invoices"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "cBIhoUc3H", "694c841bf50281f12eac05a2644cce8cdecf5bcdbb28308b81edf1a85e2b7610", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("invoices_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("cBIhoUc3H", "invoices_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("invoices_view", eventProperties, "cBIhoUc3H", "694c841bf50281f12eac05a2644cce8cdecf5bcdbb28308b81edf1a85e2b7610");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("invoices_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * company_settings_view: This view event is triggered when the user lands on the company settings page in the corporate admin
 *
 * When to trigger this event:
 * 1. This view event is triggered when the user lands on the company settings page in the corporate admin
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/oetOEoyJY/trigger/ApMySoaYJt
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/oetOEoyJY}
 */
export function companySettingsView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "company_settings"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "oetOEoyJY", "11d2207b9bdc605f2033d8a7afbfc686a5bb166c9fa6ffd7343491cabcde762d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("company_settings_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("oetOEoyJY", "company_settings_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("company_settings_view", eventProperties, "oetOEoyJY", "11d2207b9bdc605f2033d8a7afbfc686a5bb166c9fa6ffd7343491cabcde762d");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("company_settings_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface HomeInteractionClickLinkProperties {
  source: SourceValueType | null | undefined;
}
/**
 * home_interaction_click_link: This event is triggered when the user clicks one of the links in the side panel of the corporate admin
 *
 * When to trigger this event:
 * 1. This event is triggered when user click one of the links in the sidepanel of the corporate admin
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/2C79Y4atGi/trigger/oDkVqXZ7P
 *
 * @param properties the properties associatied with this event
 * @param properties.source: ⚠️ Will be implemented after new analytics architecture is in place.

Previous page (view name in telemetry) where the current one was opened from if used in multiple places across the App.
OR
Background view for a banner, pop-up or modal.
OR
For in-venue search, identifies location of the interaction that led to in-venue search view.

For error_view: the property shows for which view the error was opened.
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/2C79Y4atGi}
 */
export function homeInteractionClickLink(
  properties: HomeInteractionClickLinkProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.source !== undefined && properties.source !== null ?
    eventPropertiesArray.push({id: "_Y01LmRqp", name: "source", value: properties.source}) :
    eventPropertiesArray.push({id: "_Y01LmRqp", name: "source", value: null});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "home"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "interaction"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "click_link"});
  eventPropertiesArray.push({id: "nvdPVEHyGh", name: "click_target", value: "order_support"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2C79Y4atGi", "d455a02a55ed5a6344ac18344d6ae713d65fa2352171191f14c3196ece52f3ef", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("home_interaction_click_link", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2C79Y4atGi", "home_interaction_click_link", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("home_interaction_click_link", eventProperties, "2C79Y4atGi", "d455a02a55ed5a6344ac18344d6ae713d65fa2352171191f14c3196ece52f3ef");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("home_interaction_click_link", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * deliveries_view: This event is triggered when a user lands on the deliveries page in the corporate admin
 *
 * When to trigger this event:
 * 1. This event is triggered when user lands on the deliveries page in corporate admin
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/2Ii-8bRqfz/trigger/Vez-eGAks
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/2Ii-8bRqfz}
 */
export function deliveriesView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "deliveries"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2Ii-8bRqfz", "27c8142c0c62c5e0e7f5c1cda1990bf1f3d20925f60b03f107f4ecae6219706c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("deliveries_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2Ii-8bRqfz", "deliveries_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("deliveries_view", eventProperties, "2Ii-8bRqfz", "27c8142c0c62c5e0e7f5c1cda1990bf1f3d20925f60b03f107f4ecae6219706c");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("deliveries_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface HomeInteractionResourcesProperties {
  resource: ResourceValueType;
}
/**
 * home_interaction_resources: This event is triggered when a user clicks on the resources links on the corporate admin home page
 *
 * When to trigger this event:
 * 1. This event is triggered when the user clicks one of the links in the side panel of the corporate admin 
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/apLptn3E4/trigger/WMp6gyh00
 *
 * @param properties the properties associatied with this event
 * @param properties.resource: This property discribes the resource a user has been clicking/downloading
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/apLptn3E4}
 */
export function homeInteractionResources(
  properties: HomeInteractionResourcesProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "0bpiblOR1", name: "resource", value: properties.resource});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "home"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "interaction"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "resources"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "apLptn3E4", "9c0f15184d8a3eea72d21257b5a4e28686fdef8a6b4b128375ecac96db129540", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("home_interaction_resources", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("apLptn3E4", "home_interaction_resources", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("home_interaction_resources", eventProperties, "apLptn3E4", "9c0f15184d8a3eea72d21257b5a4e28686fdef8a6b4b128375ecac96db129540");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("home_interaction_resources", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * home_interaction_order_delivery: This event is triggered when a user clicks on the order delivery button on the home page of the corporate admin, taking the user to drive.wolt.com
 *
 * When to trigger this event:
 * 1. This event is triggered when a user clicks on the order delivery button on the home page of the corporate admin, taking the user to drive.wolt.com
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/NDQRC0B1Uj/trigger/BzdtJlAxM
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/NDQRC0B1Uj}
 */
export function homeInteractionOrderDelivery() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "home"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "interaction"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "order_delivery"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "NDQRC0B1Uj", "bb29f97139caf76a1f1cb772fcc4cfae5139845bb55d688885004805cb7e5c05", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("home_interaction_order_delivery", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("NDQRC0B1Uj", "home_interaction_order_delivery", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("home_interaction_order_delivery", eventProperties, "NDQRC0B1Uj", "bb29f97139caf76a1f1cb772fcc4cfae5139845bb55d688885004805cb7e5c05");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("home_interaction_order_delivery", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * home_interaction_calendar: This event is triggered when a user interacts with the calendar widget on the corporate admin home page
 *
 * When to trigger this event:
 * 1. This event is triggered when a user interacts with the calendar widget on the corporate admin home page
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/djqsySsdf5/trigger/Nyx1h-ZCs
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/djqsySsdf5}
 */
export function homeInteractionCalendar() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "home"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "interaction"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "calendar"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "djqsySsdf5", "5aeccc26774bca51ed047fd981b5f6098ee79af63bb80c4931c319455500bbc5", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("home_interaction_calendar", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("djqsySsdf5", "home_interaction_calendar", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("home_interaction_calendar", eventProperties, "djqsySsdf5", "5aeccc26774bca51ed047fd981b5f6098ee79af63bb80c4931c319455500bbc5");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("home_interaction_calendar", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface InvoicesInteractionDownloadProperties {
  resource: ResourceValueType;
  source: SourceValueType | null | undefined;
}
/**
 * invoices_interaction_download: This event is triggered when a user clicks one of the invoice/report download links in the corporate admin invoices page
 *
 * When to trigger this event:
 * 1. This event is triggered when a user clicks one of the invoice/report download links in the corporate admin invoices page
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/wddJMZj2Bn/trigger/2L8gJsQme
 *
 * @param properties the properties associatied with this event
 * @param properties.resource: This property discribes the resource a user has been clicking/downloading
 * @param properties.source: ⚠️ Will be implemented after new analytics architecture is in place.

Previous page (view name in telemetry) where the current one was opened from if used in multiple places across the App.
OR
Background view for a banner, pop-up or modal.
OR
For in-venue search, identifies location of the interaction that led to in-venue search view.

For error_view: the property shows for which view the error was opened.
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/wddJMZj2Bn}
 */
export function invoicesInteractionDownload(
  properties: InvoicesInteractionDownloadProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "0bpiblOR1", name: "resource", value: properties.resource});
  properties.source !== undefined && properties.source !== null ?
    eventPropertiesArray.push({id: "_Y01LmRqp", name: "source", value: properties.source}) :
    eventPropertiesArray.push({id: "_Y01LmRqp", name: "source", value: null});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "invoices"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "interaction"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "download"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "wddJMZj2Bn", "85ec687e64263bc2f9b2c20e0d1e8dd18453498411dc8ce70cf14ab37f7047e2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("invoices_interaction_download", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("wddJMZj2Bn", "invoices_interaction_download", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("invoices_interaction_download", eventProperties, "wddJMZj2Bn", "85ec687e64263bc2f9b2c20e0d1e8dd18453498411dc8ce70cf14ab37f7047e2");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("invoices_interaction_download", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * orders_interaction_order_details: This event is triggered when a user clicks on an individual order in the admin portal orders page
 *
 * When to trigger this event:
 * 1. This event is triggered when a user clicks on an individual order in the admin portal orders page
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/i4oVTmuMzH/trigger/fuKgyQGAX
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/i4oVTmuMzH}
 */
export function ordersInteractionOrderDetails() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "orders"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "interaction"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "order_details"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "i4oVTmuMzH", "17d504ca98301690a5658ed2b569fbe5d97d0bef9109acc0addfb85ab550022f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("orders_interaction_order_details", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("i4oVTmuMzH", "orders_interaction_order_details", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("orders_interaction_order_details", eventProperties, "i4oVTmuMzH", "17d504ca98301690a5658ed2b569fbe5d97d0bef9109acc0addfb85ab550022f");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("orders_interaction_order_details", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OrdersInteractionDownloadReceiptProperties {
  purchaseId: string;
}
/**
 * orders_interaction_download_receipt: This event is triggered when a user clicks on the download receipt button in the admin portal order details section
 *
 * When to trigger this event:
 * 1. This event is triggered when a user clicks on the download receipt button in the admin portal order details section
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/QAhbvwYaWO/trigger/XTGiQhS9w
 *
 * @param properties the properties associatied with this event
 * @param properties.purchaseId: Unique identifier for a purchase generated on backend once order is placed
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/QAhbvwYaWO}
 */
export function ordersInteractionDownloadReceipt(
  properties: OrdersInteractionDownloadReceiptProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Cgy4XVq1cK", name: "purchase_id", value: properties.purchaseId});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "orders"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "interaction"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "download_receipt"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "QAhbvwYaWO", "5da3a7e55113cb93a3c6f6863e2f9de96bfd1cbe9e4e47dc94aa040c0dc2d863", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("orders_interaction_download_receipt", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("QAhbvwYaWO", "orders_interaction_download_receipt", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("orders_interaction_download_receipt", eventProperties, "QAhbvwYaWO", "5da3a7e55113cb93a3c6f6863e2f9de96bfd1cbe9e4e47dc94aa040c0dc2d863");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("orders_interaction_download_receipt", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface InvoicesDataDownloadAnnualReportProperties {
  reportType: ReportTypeValueType;
}
/**
 * invoices_data_download_annual_report: This event is triggered when a user downloads the annual report for invoices/payouts in the corporate admin invoices page
 *
 * When to trigger this event:
 * 1. This event is triggered when a user downloads the annual report for invoices/payouts in the corporate admin invoices page
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/EynXv9ERNC/trigger/P7wqHHDH_
 *
 * @param properties the properties associatied with this event
 * @param properties.reportType: This property indicates the type of report a user is downloading, can be either invoice or payout report
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/EynXv9ERNC}
 */
export function invoicesDataDownloadAnnualReport(
  properties: InvoicesDataDownloadAnnualReportProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "TEGjKF_1F", name: "report_type", value: properties.reportType});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "invoices"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "data"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "download_annual_report"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "EynXv9ERNC", "1ca3a2c859ff54cbb97267a0213a719ef3f53c6da0f290f404d7b94b2fc82e85", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("invoices_data_download_annual_report", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("EynXv9ERNC", "invoices_data_download_annual_report", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("invoices_data_download_annual_report", eventProperties, "EynXv9ERNC", "1ca3a2c859ff54cbb97267a0213a719ef3f53c6da0f290f404d7b94b2fc82e85");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("invoices_data_download_annual_report", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OrdersInteractionVenueProperties {
  venueId: string;
}
/**
 * orders_interaction_venue: This event is triggered when a user clicks on the venue link in the corporate admin portal order details side-panel
 *
 * When to trigger this event:
 * 1. This event is triggered when a user clicks on the venue link in the corporate admin portal order details side-panel
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/97oXC4B1fU/trigger/2F8GmB1p_
 *
 * @param properties the properties associatied with this event
 * @param properties.venueId: Unique identifier for a venue where user sees or interacts with its card or venue objects.
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/97oXC4B1fU}
 */
export function ordersInteractionVenue(
  properties: OrdersInteractionVenueProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "bkm022dfMj", name: "venue_id", value: properties.venueId});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "orders"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "interaction"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "venue"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "97oXC4B1fU", "65eee97d16516decf28bf1c93a862114f51f5d527bc87229a332cb98702b4c52", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("orders_interaction_venue", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("97oXC4B1fU", "orders_interaction_venue", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("orders_interaction_venue", eventProperties, "97oXC4B1fU", "65eee97d16516decf28bf1c93a862114f51f5d527bc87229a332cb98702b4c52");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("orders_interaction_venue", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface DeliveriesInteractionOrderTrackingProperties {
  source: SourceValueType | null | undefined;
}
/**
 * deliveries_interaction_order_tracking: This event is triggered when a user clicks on the tracking link in the corporate admin deliveries page or in the deliveries page side panel
 *
 * When to trigger this event:
 * 1. This event is triggered when a user clicks on the tracking link in the corporate admin deliveries page or in the deliveries page side panel
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/y509MCv6EZ/trigger/zHJhHw8XS
 *
 * @param properties the properties associatied with this event
 * @param properties.source: ⚠️ Will be implemented after new analytics architecture is in place.

Previous page (view name in telemetry) where the current one was opened from if used in multiple places across the App.
OR
Background view for a banner, pop-up or modal.
OR
For in-venue search, identifies location of the interaction that led to in-venue search view.

For error_view: the property shows for which view the error was opened.
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/y509MCv6EZ}
 */
export function deliveriesInteractionOrderTracking(
  properties: DeliveriesInteractionOrderTrackingProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.source !== undefined && properties.source !== null ?
    eventPropertiesArray.push({id: "_Y01LmRqp", name: "source", value: properties.source}) :
    eventPropertiesArray.push({id: "_Y01LmRqp", name: "source", value: null});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "deliveries"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "interaction"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "order_tracking"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "y509MCv6EZ", "6b1766e03613bf1dec1df64da219448dc838a3376ffab3db5b83690b128e3271", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("deliveries_interaction_order_tracking", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("y509MCv6EZ", "deliveries_interaction_order_tracking", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("deliveries_interaction_order_tracking", eventProperties, "y509MCv6EZ", "6b1766e03613bf1dec1df64da219448dc838a3376ffab3db5b83690b128e3271");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("deliveries_interaction_order_tracking", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * billing_information_view: This event is triggered when a user lands on the add company billing details view when signing up for Wolt for Work
 *
 * When to trigger this event:
 * 1. This event is triggered when a user lands on the add company billing details view when signing up for Wolt for Work
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/vfNNJFGerh/trigger/hJTr29wUY
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/vfNNJFGerh}
 */
export function billingInformationView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "billing_information"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "vfNNJFGerh", "21679ad85dc2eaab3a9f0a40fe35d5b7b61b817a71aab5464267eafffb8f5f6a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("billing_information_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("vfNNJFGerh", "billing_information_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("billing_information_view", eventProperties, "vfNNJFGerh", "21679ad85dc2eaab3a9f0a40fe35d5b7b61b817a71aab5464267eafffb8f5f6a");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("billing_information_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * accept_terms_view: This event is triggered when a user lands on the accept terms and conditions view when signing up for Wolt for Work
 *
 * When to trigger this event:
 * 1. This event is triggered when a user lands on the accept terms and conditions view when signing up for Wolt for Work
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/euyhRC_S1/trigger/1coQqI1Ly1
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/euyhRC_S1}
 */
export function acceptTermsView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "accept_terms"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "euyhRC_S1", "065042c4e0fa7f275f1f4c25a1bd2ce94c8e80affe6e63df0251da3e70ca399d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("accept_terms_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("euyhRC_S1", "accept_terms_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("accept_terms_view", eventProperties, "euyhRC_S1", "065042c4e0fa7f275f1f4c25a1bd2ce94c8e80affe6e63df0251da3e70ca399d");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("accept_terms_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * verification_view: This event is triggered when a user lands on the verification pending view when signing up for Wolt for Work
 *
 * When to trigger this event:
 * 1. This event is triggered when a user lands on the verification pending view when signing up for Wolt for Work
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/UVBI-mC2o/trigger/rDfl2z0HNP
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/UVBI-mC2o}
 */
export function verificationView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "verification"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "UVBI-mC2o", "b937c1bccd333879e66c1bc95cb68c22c5e0fc2d5da4ba3475c7ba4173f714ff", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("verification_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("UVBI-mC2o", "verification_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("verification_view", eventProperties, "UVBI-mC2o", "b937c1bccd333879e66c1bc95cb68c22c5e0fc2d5da4ba3475c7ba4173f714ff");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("verification_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface BillingInformationDataSubmitProperties {
  eventName: EventNameValueType;
  contactPersonDefaultsChanged: boolean;
  invoicingDefaultsChanged: boolean;
}
/**
 * billing_information_data_submit: This event is triggered when a user submits company billing details when signing up for Wolt for Work
 *
 * When to trigger this event:
 * 1. This event is triggered when a user submits company billing details when signing up for Wolt for Work
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/XEKH_JXzD/trigger/XEtmnbYy5A
 *
 * @param properties the properties associatied with this event
 * @param properties.eventName: Name of the event according to Wolt internal specifications
 * @param properties.contactPersonDefaultsChanged: Indicates if the user changed the default, pre-filled contact person field in the add billing details view when signing up for Wolt for Work and submitting the form
 * @param properties.invoicingDefaultsChanged: Indicates if the user changed the default, pre-filled invoicing and receipts fields in the add billing details view when signing up for Wolt for Work and submitting the form
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/XEKH_JXzD}
 */
export function billingInformationDataSubmit(
  properties: BillingInformationDataSubmitProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: properties.eventName});
  eventPropertiesArray.push({id: "mJntT7utq", name: "contact_person_defaults_changed", value: properties.contactPersonDefaultsChanged});
  eventPropertiesArray.push({id: "COfYz7c1c", name: "invoicing_defaults_changed", value: properties.invoicingDefaultsChanged});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "billing_information"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "data"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "XEKH_JXzD", "19272cd9031a9281421281793df0a106aabba86ba827ed8de66d59b2011f7625", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("billing_information_data_submit", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("XEKH_JXzD", "billing_information_data_submit", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("billing_information_data_submit", eventProperties, "XEKH_JXzD", "19272cd9031a9281421281793df0a106aabba86ba827ed8de66d59b2011f7625");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("billing_information_data_submit", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface AcceptTermsInteractionDownloadAgreementProperties {
  language: any;
}
/**
 * accept_terms_interaction_download_agreement: This event is triggered when a user clicks on the download terms and conditions  when signing up for Wolt for Work
 *
 * When to trigger this event:
 * 1. This event is triggered when a user clicks on the download terms and conditions  when signing up for Wolt for Work
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/bg7puXl_r/trigger/xPSqkqMto8
 *
 * @param properties the properties associatied with this event
 * @param properties.language: TODO
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/bg7puXl_r}
 */
export function acceptTermsInteractionDownloadAgreement(
  properties: AcceptTermsInteractionDownloadAgreementProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "411E-d3wejWx", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "accept_terms"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "interaction"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "download_agreement"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "bg7puXl_r", "b77ec09bb400bbd8084d3d758e9480ee484d60b8b6c2d01230479e2bc9dfcf29", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("accept_terms_interaction_download_agreement", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("bg7puXl_r", "accept_terms_interaction_download_agreement", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("accept_terms_interaction_download_agreement", eventProperties, "bg7puXl_r", "b77ec09bb400bbd8084d3d758e9480ee484d60b8b6c2d01230479e2bc9dfcf29");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("accept_terms_interaction_download_agreement", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * accept_terms_data_confirm: This event is triggered when a user successfully accepts the confirm terms and conditions when signing up for Wolt for Work
 *
 * When to trigger this event:
 * 1. This event is triggered when a user successfully accepts the confirm terms and conditions when signing up for Wolt for Work
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/UV6c79jgV/trigger/amHYEr9iTn
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/UV6c79jgV}
 */
export function acceptTermsDataConfirm() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "accept_terms"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "data"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "confirm"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "UV6c79jgV", "4ca9af8b68574f97e81eaf2fb4aeccda6405ffdaf7d71917139303e93ffab0d4", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("accept_terms_data_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("UV6c79jgV", "accept_terms_data_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("accept_terms_data_confirm", eventProperties, "UV6c79jgV", "4ca9af8b68574f97e81eaf2fb4aeccda6405ffdaf7d71917139303e93ffab0d4");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("accept_terms_data_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * subscription_intro_view: This event is triggered when a user sees the Wolt+ for Work intro page in the corporate admin portal
 *
 * When to trigger this event:
 * 1. This event is triggered when user sees the Wolt+ for Work intro page in corporate admin portal
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/4YipyLq4VX/trigger/koZj5kFQd
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/4YipyLq4VX}
 */
export function subscriptionIntroView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "subscription_intro"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4YipyLq4VX", "dce2916f376708e1892a2d58c36e4453fd535a7c726eaef258816dc70c5abfca", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("subscription_intro_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4YipyLq4VX", "subscription_intro_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("subscription_intro_view", eventProperties, "4YipyLq4VX", "dce2916f376708e1892a2d58c36e4453fd535a7c726eaef258816dc70c5abfca");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("subscription_intro_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * subscription_intro_interaction_download_agreement: This event is triggered when a user clicks on the link to download T&C for Wolt+ on the Wolt+ for Work intro page in the corporate admin portal
 *
 * When to trigger this event:
 * 1. This event is triggered when a user clicks on the link to download T&C for Wolt+ on the Wolt+ for Work intro page in the corporate admin portal
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/gdhjEnYwf/trigger/1bp76Y1zBM
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/gdhjEnYwf}
 */
export function subscriptionIntroInteractionDownloadAgreement() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "subscription_intro"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "interaction"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "download_agreement"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "gdhjEnYwf", "73161c35dbf559dad7b7a66d83bad55b284a1492c181371a8580792663504295", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("subscription_intro_interaction_download_agreement", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("gdhjEnYwf", "subscription_intro_interaction_download_agreement", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("subscription_intro_interaction_download_agreement", eventProperties, "gdhjEnYwf", "73161c35dbf559dad7b7a66d83bad55b284a1492c181371a8580792663504295");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("subscription_intro_interaction_download_agreement", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * subscription_intro_data_accept_agreement: This event is triggered when a user clicks on the get started button to accept T&C after downloading T&C for Wolt+ on the Wolt+ for Work intro page in the corporate admin portal
 *
 * When to trigger this event:
 * 1. This event is triggered when a user clicks on the get started button to accept T&C after downloading T&C for Wolt+ on the Wolt+ for Work intro page in the corporate admin portal
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/-iQpV_HVW/trigger/ro4a-2zt4V
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/-iQpV_HVW}
 */
export function subscriptionIntroDataAcceptAgreement() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "subscription_intro"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "data"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "accept_agreement"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "-iQpV_HVW", "58f70fe4207eb521c704985349419855f9af9b77850b35d752ddf39e9804cc68", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("subscription_intro_data_accept_agreement", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("-iQpV_HVW", "subscription_intro_data_accept_agreement", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("subscription_intro_data_accept_agreement", eventProperties, "-iQpV_HVW", "58f70fe4207eb521c704985349419855f9af9b77850b35d752ddf39e9804cc68");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("subscription_intro_data_accept_agreement", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * subscription_view: This event is triggered when a user sees the Wolt+ for Work page in the corporate admin portal
 *
 * When to trigger this event:
 * 1. This event is triggered when a user sees the Wolt+ for Work page in the corporate admin portal
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/2uTHdC3yw/trigger/iHTzkyoKNn
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/2uTHdC3yw}
 */
export function subscriptionView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "subscription"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2uTHdC3yw", "36116b796feb0fd24b6eb2802d27d2ee225bc8d8ff9e364820f0a013a8b61ae4", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("subscription_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2uTHdC3yw", "subscription_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("subscription_view", eventProperties, "2uTHdC3yw", "36116b796feb0fd24b6eb2802d27d2ee225bc8d8ff9e364820f0a013a8b61ae4");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("subscription_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * subscription_invite_members_view: This event is triggered when a user sees the modal for inviting members to join Wolt+ for Work in the corporate admin portal
 *
 * When to trigger this event:
 * 1. This event is triggered when a user sees the modal for inviting members to join Wolt+ for Work in the corporate admin portal
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/3lefs5fgc/trigger/eaxCLhDIld
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/3lefs5fgc}
 */
export function subscriptionInviteMembersView() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "subscription_invite_members"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "view"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "3lefs5fgc", "4d2b0d0220b06d3288b1a7a0ed346c74f83fe49bb3bc48019eeb3ea97a3c72d3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("subscription_invite_members_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("3lefs5fgc", "subscription_invite_members_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("subscription_invite_members_view", eventProperties, "3lefs5fgc", "4d2b0d0220b06d3288b1a7a0ed346c74f83fe49bb3bc48019eeb3ea97a3c72d3");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("subscription_invite_members_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SubscriptionInviteMembersDataInviteMembersProperties {
  inviteMethod: InviteMethodValueType;
}
/**
 * subscription_invite_members_data_invite_members: This event is triggered when a user sees the modal for inviting members to join Wolt+ for Work in the corporate admin portal
 *
 * When to trigger this event:
 * 1. This event is triggered when a user sees the modal for inviting members to join Wolt+ for Work in the corporate admin portal
 * View in Avo: https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/V21IymkSQ/trigger/0djsmQbeNF
 *
 * @param properties the properties associatied with this event
 * @param properties.inviteMethod: This property describes the invite method used to invite members in the corporate admin portal to the subscription program and to join a company
 *
 * @see {@link https://www.avo.app/schemas/Qxw4SpNZAmmYOzZQPcag/events/V21IymkSQ}
 */
export function subscriptionInviteMembersDataInviteMembers(
  properties: SubscriptionInviteMembersDataInviteMembersProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "9Uiy84Ss_", name: "invite_method", value: properties.inviteMethod});
  eventPropertiesArray.push({id: "dLEBXcR0Ty", name: "view", value: "subscription_invite_members"});
  eventPropertiesArray.push({id: "pp5TYQIUMm", name: "log_type", value: "data"});
  eventPropertiesArray.push({id: "r5E-SMeZ21", name: "event_name", value: "invite_members"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "V21IymkSQ", "c1e2df3a37eb298e606615a7483db81f8e99219cc49ca3f7f23ae30b5373572c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("subscription_invite_members_data_invite_members", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("V21IymkSQ", "subscription_invite_members_data_invite_members", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("subscription_invite_members_data_invite_members", eventProperties, "V21IymkSQ", "c1e2df3a37eb298e606615a7483db81f8e99219cc49ca3f7f23ae30b5373572c");
    }
    // destination WoltGatekeeper
    WoltGatekeeper.logEvent("subscription_invite_members_data_invite_members", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  avoInspectorApiKey,
  Resource,
  InviteMethod,
  ReportType,
  LoginOption,
  Source,
  View,
  ClickTarget,
  LogType,
  EventName,
  SignupOption,
  loginPageView,
  loginPageInteractionLogin,
  loginPageInteractionSignup,
  homeView,
  loginPageDataLoginCompleted,
  emailLoginConfirmationView,
  emailLoginConfirmationInteractionConfirm,
  ordersView,
  membersView,
  groupsView,
  policiesView,
  eventsView,
  invoicesView,
  companySettingsView,
  homeInteractionClickLink,
  deliveriesView,
  homeInteractionResources,
  homeInteractionOrderDelivery,
  homeInteractionCalendar,
  invoicesInteractionDownload,
  ordersInteractionOrderDetails,
  ordersInteractionDownloadReceipt,
  invoicesDataDownloadAnnualReport,
  ordersInteractionVenue,
  deliveriesInteractionOrderTracking,
  billingInformationView,
  acceptTermsView,
  verificationView,
  billingInformationDataSubmit,
  acceptTermsInteractionDownloadAgreement,
  acceptTermsDataConfirm,
  subscriptionIntroView,
  subscriptionIntroInteractionDownloadAgreement,
  subscriptionIntroDataAcceptAgreement,
  subscriptionView,
  subscriptionInviteMembersView,
  subscriptionInviteMembersDataInviteMembers,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["loginPageView","loginPageInteractionLogin","loginPageInteractionSignup","homeView","loginPageDataLoginCompleted","emailLoginConfirmationView","emailLoginConfirmationInteractionConfirm","ordersView","membersView","groupsView","policiesView","eventsView","invoicesView","companySettingsView","homeInteractionClickLink","deliveriesView","homeInteractionResources","homeInteractionOrderDelivery","homeInteractionCalendar","invoicesInteractionDownload","ordersInteractionOrderDetails","ordersInteractionDownloadReceipt","invoicesDataDownloadAnnualReport","ordersInteractionVenue","deliveriesInteractionOrderTracking","billingInformationView","acceptTermsView","verificationView","billingInformationDataSubmit","acceptTermsInteractionDownloadAgreement","acceptTermsDataConfirm","subscriptionIntroView","subscriptionIntroInteractionDownloadAgreement","subscriptionIntroDataAcceptAgreement","subscriptionView","subscriptionInviteMembersView","subscriptionInviteMembersDataInviteMembers"]
