import { IconButton } from '@creditornot/cb-button';
import { MoreHorizontal } from '@creditornot/cb-icons';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { DropdownButton } from 'components';
import { Invoice } from 'modules/invoices/types';
import { isDefined } from 'utils';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';
import { Resource, Source, invoicesInteractionDownload } from 'telemetry/Avo';

type InvoiceDropdownActionsProps = {
  invoice: Pick<
    Invoice,
    | 'id'
    | 'created_at'
    | 'pdf_url'
    | 'order_list_csv_url'
    | 'order_list_pdf_url'
    | 'receipts_archive_url'
    | 'vat_report_url'
  >;
  onDownloadDocumentClick: (
    url: string,
    createdAt: string,
    document: string,
    invoiceId: string,
  ) => void;
};

export const InvoiceDropdownActions = ({
  invoice: {
    id,
    created_at,
    pdf_url,
    order_list_csv_url,
    order_list_pdf_url,
    receipts_archive_url,
    vat_report_url,
  },
  onDownloadDocumentClick,
}: InvoiceDropdownActionsProps) => {
  const downloadTelemetryEvent = useTelemetryInteractionEvent(invoicesInteractionDownload);
  const actions = useMemo(
    () =>
      [
        pdf_url
          ? {
              label: <FormattedMessage id={'invoices.download-invoice'} />,
              onClick: () => {
                downloadTelemetryEvent({ resource: Resource.INVOICE, source: Source.INVOICES });
                onDownloadDocumentClick(pdf_url, created_at, 'invoice', id);
              },
            }
          : undefined,
        order_list_pdf_url
          ? {
              label: <FormattedMessage id={'invoices.download-order-list-pdf'} />,
              onClick: () => {
                downloadTelemetryEvent({
                  resource: Resource.ORDER_LIST_PDF,
                  source: Source.INVOICES,
                });
                onDownloadDocumentClick(order_list_pdf_url, created_at, 'order_list', id);
              },
            }
          : undefined,
        order_list_csv_url
          ? {
              label: <FormattedMessage id={'invoices.download-order-list-csv'} />,
              onClick: () => {
                downloadTelemetryEvent({
                  resource: Resource.ORDER_LIST_CSV,
                  source: Source.INVOICES,
                });
                onDownloadDocumentClick(order_list_csv_url, created_at, 'order_list', id);
              },
            }
          : undefined,
        receipts_archive_url
          ? {
              label: <FormattedMessage id={'invoices.download-receipts'} />,
              onClick: () => {
                downloadTelemetryEvent({ resource: Resource.RECEIPTS, source: Source.INVOICES });
                onDownloadDocumentClick(receipts_archive_url, created_at, 'receipts', id);
              },
            }
          : undefined,
        vat_report_url
          ? {
              label: <FormattedMessage id={'invoices.download-vat-report'} />,
              onClick: () => {
                downloadTelemetryEvent({ resource: Resource.VAT_REPORT, source: Source.INVOICES });
                onDownloadDocumentClick(vat_report_url, created_at, 'vat_report', id);
              },
            }
          : undefined,
      ].filter(isDefined),
    [
      pdf_url,
      order_list_pdf_url,
      order_list_csv_url,
      receipts_archive_url,
      vat_report_url,
      downloadTelemetryEvent,
      onDownloadDocumentClick,
      created_at,
      id,
    ],
  );

  if (!actions.length) {
    return null;
  }

  return (
    <DropdownButton actions={actions}>
      <IconButton
        data-test-id="invoice-dropdown-actions"
        size="small"
        background="animated"
        variant="black"
      >
        <MoreHorizontal />
      </IconButton>
    </DropdownButton>
  );
};
