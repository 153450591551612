import { useCallback } from 'react';
import { DeliveryOrderApiListDeliveryOrdersRequest as ListDeliveryOrdersRequest } from '@creditornot/wd-api-client';
import { DateTimeParam } from 'use-query-params';

import {
  useQueryParams,
  NeverNullStringParam,
  WoltPageSizeParam,
  withDefault,
} from 'modules/react-query-params';

import { last30DaysDateRange } from './utils';

type DeliveryQueryParam = {
  deliveryStatus?: ListDeliveryOrdersRequest['deliveryStatus'] | '';
  searchQuery?: string;
  from?: Date;
  to?: Date;
  pageSize?: number;
};

export const useDeliveriesQueryParams = () => {
  const defaultDateRange = last30DaysDateRange();
  const query = useQueryParams({
    deliveryStatus: withDefault(NeverNullStringParam, ''),
    searchQuery: withDefault(NeverNullStringParam, ''),
    from: withDefault(DateTimeParam, defaultDateRange.from),
    to: withDefault(DateTimeParam, defaultDateRange.to),
    pageSize: WoltPageSizeParam,
  });
  const [params, setReactQueryParams] = query;
  const setQueryParams = useCallback(
    (queries: DeliveryQueryParam) => {
      setReactQueryParams((staledQueries) => ({
        ...staledQueries,
        ...queries,
      }));
    },
    [setReactQueryParams],
  );
  return { params, setQueryParams };
};
