import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { WoltLogo } from '@creditornot/cb-ingredients';

import { ResponsiveContainer } from 'components';

type Props = {
  className?: string;
};

const Root = styled(ResponsiveContainer)`
  box-shadow:
    0 0 4px 0 rgb(0 0 0 / 6%),
    0 4px 4px 0 rgb(0 0 0 / 12%);
  background-color: ${color.bg};
  height: 70px;
`;

const Container = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 1200px;
`;

const Navbar = (props: Props) => (
  <Root {...props}>
    <Container>
      <WoltLogo width="58px" />
    </Container>
  </Root>
);

export default Navbar;
