import { Tag, TagVariant as Variant } from '@creditornot/cb-tag';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

type Status = 'active' | 'disabled' | 'pending' | 'expired';

interface Props {
  status: Status;
  className?: string;
}

interface StatusDetails {
  variant: Variant;
  title: MessageDescriptor['id'];
}

const statusMap: Record<Status, StatusDetails> = {
  active: { variant: 'lightGreen', title: 'common.status.active' },
  pending: { variant: 'lightBlue', title: 'common.status.pending' },
  expired: { variant: 'lightRed', title: 'common.status.expired' },
  disabled: { variant: 'lightBlack', title: 'common.status.inactive' },
};

export const UserStatus = ({ status, className }: Props) => {
  return (
    <Tag className={className} variant={statusMap[status].variant}>
      <FormattedMessage id={statusMap[status].title} css="display: inline-block" />
    </Tag>
  );
};
