import { B2bConfigsApi, Configuration } from '@creditornot/daas-core-api-client';

import { env } from 'config';
import { daasCoreApiAgent } from 'apiAgents';

const daasCoreBaseConfig = new Configuration({});

export const b2bConfigApiClient = new B2bConfigsApi(
  daasCoreBaseConfig,
  env.NODE_ENV === 'development' ? '/daas-public-api' : env.DAAS_CORE_API_URI,
  daasCoreApiAgent,
);
