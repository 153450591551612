import { useField } from 'formik';
import styled from 'styled-components';
import { RadioCard } from '@creditornot/cb-radio';

import { useI18n } from 'i18n';

type Props = {
  className?: string;
};

const Container = styled.div`
  & + & {
    margin-top: 12px;
  }
`;

export const DeliveryDistanceField: React.FC<Props> = ({ className }) => {
  const { getLocalizedMessage } = useI18n();
  const [{ name, value, onChange, onBlur }] = useField<string>('delivery_distance');

  return (
    <div className={className}>
      {[
        {
          label: getLocalizedMessage('corporate-form.delivery-settings.delivery-distance-default'),
          value: 'default',
          subContent: getLocalizedMessage(
            'corporate-form.delivery-settings.delivery-distance-default-explanation',
          ),
        },
        {
          label: getLocalizedMessage('corporate-form.delivery-settings.delivery-distance-custom'),
          value: 'custom',
          subContent: getLocalizedMessage(
            'corporate-form.delivery-settings.delivery-distance-custom-explanation',
          ),
        },
      ].map((option) => (
        <Container key={JSON.stringify(option)}>
          <RadioCard
            name={name}
            checked={option.value === value}
            label={option.label}
            subContent={option.subContent}
            value={option.value}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Container>
      ))}
    </div>
  );
};
