import { useAuthState } from '@creditornot/wolt-auth/react';
import { useInfiniteQuery, UseInfiniteQueryOptions, QueryKey } from 'react-query';

export function useAuthenticatedInfiniteReactQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(options: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>) {
  const isAuthenticated = !!useAuthState().user;
  return useInfiniteQuery({
    ...options,
    enabled: isAuthenticated ? options?.enabled : false,
  });
}
