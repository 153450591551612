import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { motion } from 'framer-motion';
import { generateCodeVerifier } from '@creditornot/wolt-auth';

import { breakpoints } from 'modules/media';
import { LottieComponent } from 'modules/lotties';
import { LocalizedMessage } from 'i18n';
import { loginPageView } from 'telemetry/Avo';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';
import { authService } from 'modules/auth/authenticator';

import { LoginForm } from './LoginForm';
import Navbar from './Navbar';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 70px);
  /* stylelint-disable-next-line function-linear-gradient-no-nonstandard-direction -- stylelint doesn't recognize cssDirectionalValue interpolation */
  background: linear-gradient(
    to ${cssDirectionalValue({ ltr: 'left', rtl: 'right' })},
    ${color.bg} 50%,
    ${color.bgSurfaceBrand} 50%
  );
  padding-inline: 24px;

  @media (width <= 990px) {
    padding-inline: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  flex: 1;
  width: 100%;

  @media (max-width: ${breakpoints.medium}px) {
    padding: 0;
    flex-direction: column-reverse;
    background-color: ${color.bg};
  }
`;

const FormContainer = styled.div`
  background-color: ${color.bg};
  color: ${color.text};
  width: 480px;
  min-width: 480px;
  display: flex;
  min-height: fit-content;
  border-inline-start: 1px solid ${color.border};

  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
    min-height: 300px;
    box-shadow: none;
  }

  @media (width <= 530px) {
    min-width: unset;
  }
`;

const StyledNavbar = styled(Navbar)<{ show: boolean }>`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  transition: all 250ms ease-in-out;

  ${({ show }) =>
    show
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;

const LottieContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 720px;
  flex-shrink: 1;
  background: ${color.bgSurfaceBrand};
  opacity: 1;
  padding-top: 54px;

  @media (max-width: ${breakpoints.medium}px) {
    padding-top: 88px;
    padding-bottom: 240px;
  }

  @media (max-width: ${breakpoints.small}px) {
    padding-top: 44px;
    padding-bottom: 240px;
  }
`;

// We don't have a mirrored version of this lottie, RTL needs extra padding
const StyledLottieComponent = styled(LottieComponent)`
  max-width: 600px;

  ${cssDirectionalValue({ ltr: '', rtl: 'padding-inline-end: 32px;' })}
  @media (max-width: ${breakpoints.medium}px) {
    max-width: min(600px, 80%);
    margin-inline: auto;
  }
`;

const AnimationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${600 + 24 * 2}px;
  padding-inline-end: 32px;

  @media (max-width: ${breakpoints.medium}px) {
    max-width: min(600px, 80%);
    padding-inline-end: 0;
    margin-inline: auto;
  }
`;

const Title = styled.h4`
  ${typographyCss.Heading4()}
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: start;
  max-width: 600px;
`;

const Subtitle = styled.div`
  ${typographyCss.Body2()}
  display: flex;
  text-align: start;
  max-width: 600px;
`;

const useShouldShowNavbar = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (!document || !window) return;
    const handleScroll = () => {
      setShow(window.scrollY > 500);
    };
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return show;
};

export const LoginView = () => {
  const showNavbar = useShouldShowNavbar();

  const handleLogin = useCallback(async () => {
    try {
      const codeVerifier = await generateCodeVerifier();
      localStorage.setItem('codeVerifier', codeVerifier);
      location.href = await authService.getAuthorizeUri(codeVerifier);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <>
      <ViewEventComponent event={loginPageView} />
      <StyledNavbar show={showNavbar} />
      <Root>
        <Container>
          <LottieContainer>
            <motion.div
              transition={{
                duration: 1.2,
                ease: 'easeInOut',
              }}
              animate={{
                opacity: [0, 1],
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <StyledLottieComponent animation="loginOverview" />
              <AnimationTextContainer>
                <Title>
                  <LocalizedMessage messageKey="login.overview.heading" />
                </Title>
                <Subtitle>
                  <LocalizedMessage messageKey="login.overview.subtitle" />
                </Subtitle>
              </AnimationTextContainer>
            </motion.div>
          </LottieContainer>
          <FormContainer>
            <LoginForm onLoginClick={handleLogin} />
          </FormContainer>
        </Container>
      </Root>
    </>
  );
};
