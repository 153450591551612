import { useAuthActions } from '@creditornot/wolt-auth/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

export const LogoutCallback = () => {
  const { logout } = useAuthActions();
  const history = useHistory();

  useEffect(() => {
    const handleLogout = async () => {
      await logout();

      history.replace('/');
    };

    handleLogout();
  }, [history, logout]);

  return null;
};
