import { Select, SelectProps } from '@creditornot/cb-select';
import { useCallback, useMemo } from 'react';
import { useField } from 'formik';

import { deliveryTimeOptions } from 'utils';
import { getFormikFieldError } from 'validation';
import { useI18n } from 'i18n';

import { FormikFieldContainer } from './FormikFieldContainer';

export type TimeOption = {
  label: string;
  value: string;
};

export type FormikTimeSelectProps = {
  name: string;
  disabled?: boolean;
  alwaysShowError?: boolean;
  hideErrorMessage?: boolean;
  className?: string;
  validate?: (value: TimeOption) => string | void | Promise<string | void>;
  timeSelectProps?: Partial<SelectProps<TimeOption>>;
  getDisabledTime?: (value: TimeOption, index: number, arr: TimeOption[]) => boolean;
};

const timSelectDeliveryTimeOptions = deliveryTimeOptions.map(({ value, label }) => ({
  label,
  value: value.toString(),
}));

export function FormikTimeSelect({
  name,
  disabled,
  className,
  getDisabledTime,
  timeSelectProps,
  alwaysShowError,
  validate,
}: FormikTimeSelectProps) {
  const { getLocalizedMessage } = useI18n();

  const [{ value }, meta, { setValue, setTouched }] = useField<string | null>({
    name,
    validate,
  });

  const handleBlur = useCallback(() => {
    setTouched(true, true);
  }, [setTouched]);

  const options = useMemo(
    () =>
      getDisabledTime
        ? timSelectDeliveryTimeOptions.filter(
            (value, index, arr) => !getDisabledTime(value, index, arr),
          )
        : timSelectDeliveryTimeOptions,
    [getDisabledTime],
  );

  const formikValidationError = getFormikFieldError(meta, alwaysShowError);

  const errorMessage = useMemo(
    () =>
      formikValidationError &&
      getLocalizedMessage(formikValidationError.messageKey, formikValidationError.values),
    [formikValidationError, getLocalizedMessage],
  );

  return (
    <FormikFieldContainer className={className} errorMessage={errorMessage}>
      <Select
        options={options}
        value={value}
        disabled={disabled}
        onChange={setValue}
        onBlur={handleBlur}
        invalid={!!formikValidationError}
        {...timeSelectProps}
      />
    </FormikFieldContainer>
  );
}
