import { Route, Switch } from 'react-router-dom';

import { EventsView } from './EventsView';
import { EventView } from './EventView';
import { EventWelcomeModal } from './EventWelcomeModal';

export const RootRoutes = () => (
  <>
    <EventWelcomeModal />
    <Switch>
      <Route exact path="/:corporateId/events" component={EventsView} />
      <Route exact path="/:corporateId/events/:action" component={EventsView} />
      <Route exact path="/:corporateId/events/info/:eventId" component={EventView} />
      <Route exact path="/:corporateId/events/info/:eventId/:actions" component={EventView} />
    </Switch>
  </>
);
