import { Input } from '@creditornot/cb-input';
import { MagnifyingGlass } from '@creditornot/cb-icons';
import styled from 'styled-components';
import { useCallback, useMemo } from 'react';

import { breakpoints, useMedia } from 'modules/media';
import { Header } from 'components';
import { useI18n } from 'i18n';

import { ResendAndDeletePanel } from './ResendAndDeletePanel';
import { UsersFilter } from './UsersFilter';
import UsersSegmentedControl from './UsersSegmentedControl';
import { useUsersQueryParams } from '../hooks';

type Props = {
  numberOfUsers: number;
  numberOfInvites: number;
  corporateId: string;
  onCheckBoxClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const StyledInput = styled(Input)`
  min-width: 240px;

  @media (max-width: ${breakpoints.medium}px) {
    max-width: 120px;
  }

  @media (max-width: ${breakpoints.small}px) {
    max-width: 100%;
    min-width: 100%;
  }
`;

const StyledUsersFilter = styled(UsersFilter)`
  width: 280px;

  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
  }
`;

const StyledUsersSegmentedControl = styled(UsersSegmentedControl)`
  @media (max-width: ${breakpoints.medium}px) {
    margin-inline-start: 16px;
  }

  @media (max-width: ${breakpoints.small}px) {
    margin-inline-start: 0;
  }
`;

const StyledResendAndDeletePanel = styled(ResendAndDeletePanel)`
  margin-inline-start: auto;

  @media (max-width: ${breakpoints.medium}px) {
    margin-top: 24px;
    margin-inline-start: 0;
    align-items: center;
  }
`;

const useShouldShowResendAndDeletePanel = () => {
  const [{ users: userIdsQueryParam = [], invites: userInviteIdsQueryParam = [] }] =
    useUsersQueryParams();

  const totalNumberOfUsersSelected = useMemo(
    () => (userIdsQueryParam?.length ?? 0) + (userInviteIdsQueryParam?.length ?? 0),
    [userIdsQueryParam, userInviteIdsQueryParam],
  );

  return totalNumberOfUsersSelected > 0;
};

export const UsersPanel = ({
  numberOfInvites,
  numberOfUsers,
  onCheckBoxClick,
  corporateId,
}: Props) => {
  const mediumView = useMedia('medium');
  const smallView = useMedia('small');

  const shouldShowResendAndDeletePanel = useShouldShowResendAndDeletePanel();

  const { getLocalizedMessage } = useI18n();

  const [{ search }, setQueryParams] = useUsersQueryParams();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQueryParams({
        search: event.target.value,
      });
    },
    [setQueryParams],
  );

  const handleClear = useCallback(() => {
    setQueryParams({
      search: undefined,
    });
  }, [setQueryParams]);

  const searchInput = (
    <StyledInput
      key="styledUserSearch"
      icon={<MagnifyingGlass />}
      onChange={handleChange}
      value={search ?? ''}
      placeholder={getLocalizedMessage('common.search')}
      onClearClick={handleClear}
    />
  );

  const usersSegmentedControl = (
    <StyledUsersSegmentedControl
      key="usersSegmentedControl"
      fixedWidths={false}
      numberOfInvites={numberOfInvites}
      numberOfUsers={numberOfUsers}
    />
  );

  const userFilter = <StyledUsersFilter key="styledUsersFilter" corporateId={corporateId} />;

  const resendAndDeletePanel = shouldShowResendAndDeletePanel ? (
    <StyledResendAndDeletePanel
      key="resendAndDeletePanel"
      numberOfInvites={numberOfInvites}
      numberOfUsers={numberOfUsers}
      onCheckBoxClick={onCheckBoxClick}
    />
  ) : null;

  if (smallView) {
    return (
      <Header
        key="usersHeader"
        borderBottom
        leftItems={[
          searchInput,
          <StyledUsersSegmentedControl
            key="usersSegmentedControl"
            fixedWidths
            numberOfInvites={numberOfInvites}
            numberOfUsers={numberOfUsers}
          />,
          userFilter,
        ]}
        rightItems={[resendAndDeletePanel]}
      />
    );
  }

  if (mediumView) {
    return (
      <Header
        key="usersHeader"
        borderBottom
        leftItems={[
          <div key="inputAndSegmentedControl">
            {searchInput}
            {usersSegmentedControl}
          </div>,
          userFilter,
        ]}
        rightItems={[resendAndDeletePanel]}
      />
    );
  }

  return (
    <Header
      borderBottom
      leftItems={[searchInput, usersSegmentedControl, userFilter]}
      rightItems={[resendAndDeletePanel]}
    />
  );
};
