import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useRef, useState, useLayoutEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Tag } from '@creditornot/cb-tag';

export interface TagInputProps {
  id?: string;
  className?: string;
  styles?: React.CSSProperties;
  invalid?: boolean;
  disabled?: boolean;
  onClear?: (values: string[]) => void;
  onChange: (values: string[]) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: React.ReactNode;
  setTagValueByKeyCodes?: number[];
  separateValuesBy?: RegExp;
  size?: 'normal' | 'large';
  values: string[];
  validateValues?: (value: string, index: number, values: string[]) => boolean;
}

const Root = styled.div<{ invalid: boolean; size: 'normal' | 'large'; disabled: boolean }>`
  border: 1px solid ${color.border};
  color: ${color.textSubdued};
  white-space: normal;
  border-radius: 8px;
  position: relative;
  flex: 1;
  font-size: 14px;
  transition: all 350ms ease;
  padding: 16px;
  padding-bottom: 8px;

  ${({ disabled, invalid }) => {
    if (disabled) {
      return css`
        cursor: not-allowed;
        opacity: 0.4;
      `;
    }
    if (invalid) {
      return css`
        border-color: ${color.borderNegative};
      `;
    }

    return css`
      &:hover {
        cursor: text;
        border-color: rgb(32 33 37 / 24%);
        color: ${color.textSubdued};
      }

      &:focus-within {
        border: 1px solid ${color.borderSelected};
      }
    `;
  }}
`;

const Input = styled.input`
  border: none;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
  min-width: 0;
  outline: none;
  height: 24px;
  position: relative;
  background-color: transparent;
  padding-inline-start: 0;
  z-index: 1;
`;

const PlaceHolder = styled.div`
  color: ${color.textSubdued};
  font-size: 14px;
  line-height: 17px;
  margin-top: 3px;
  position: absolute;
  z-index: 0;
`;

const HiddenDiv = styled.div`
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  min-width: 20px;
  position: absolute;
  visibility: hidden;
  z-index: -1;
`;

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-wrap: wrap;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;

const StyledTag = styled(Tag)`
  margin-bottom: 8px;
  margin-inline-end: 8px;
  border-radius: 4px;
`;

const TagInput = ({
  id,
  className,
  invalid,
  disabled = false,
  onChange,
  onClear,
  onBlur,
  placeholder = 'Placeholder',
  setTagValueByKeyCodes = [13],
  separateValuesBy = /.^/,
  size = 'normal',
  values,
  validateValues,
  styles,
}: TagInputProps) => {
  const [inputValue, setInputValue] = useState('');
  const [tagValues, setTagValues] = useState<string[]>(values);
  const [inputWidth, setInputWitdh] = useState(1);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const hiddenDivRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (hiddenDivRef.current) {
      setInputWitdh(hiddenDivRef.current.offsetWidth);
    }
  }, [inputValue, inputValue.length]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }, []);

  const handleRootClick = useCallback(() => inputRef.current?.focus(), []);

  const handleClearClick = useCallback(
    (valueIndex: number) => {
      if (!values || !onClear) return;

      const newValues = [...values.slice(0, valueIndex), ...values.slice(valueIndex + 1)];

      setTagValues(newValues);
      onClear(newValues);
    },
    [onClear, values],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!values || inputValue !== '') return;

      if ([13, 37, 38, 39].includes(e.keyCode)) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (e.keyCode === 8) {
        const newValues = values.slice(0, -1);

        setTagValues(newValues);

        onChange(newValues);
      }
    },
    [inputValue, onChange, values],
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!inputValue.trim()) return;

      if (!setTagValueByKeyCodes.includes(e.charCode)) return;

      e.preventDefault();
      e.stopPropagation();

      if (values) {
        const newTagValues = [
          ...values,
          ...inputValue.split(separateValuesBy).filter((value) => value !== ''),
        ];

        setTagValues(newTagValues);
        setInputValue('');

        return onChange(newTagValues);
      } else {
        const newTagValues = inputValue.split(separateValuesBy).filter((value) => value !== '');

        setTagValues(newTagValues);
        setInputValue('');

        return onChange(newTagValues);
      }
    },
    [inputValue, onChange, separateValuesBy, setTagValueByKeyCodes, values],
  );

  const handeBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e);
      }
      if (inputValue.trim() === '') return;

      const newTagValues = inputValue.split(separateValuesBy).filter((value) => value !== '');

      const newValues = tagValues ? [...tagValues, ...newTagValues] : newTagValues;

      setTagValues(newValues);
      onChange(newValues);

      setInputValue('');
    },
    [inputValue, onBlur, onChange, separateValuesBy, tagValues],
  );

  return (
    <Root
      disabled={disabled}
      size={size}
      className={className}
      invalid={!!invalid}
      onClick={disabled ? undefined : handleRootClick}
      style={styles}
    >
      <Container disabled={disabled}>
        {tagValues?.map((value, index, allValues) => (
          <StyledTag
            data-test-id="tag-value"
            variant={validateValues?.(value, index, allValues) ? 'lightRed' : 'lightBlue'}
            key={`${value}-${index}`}
            onRemoveClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleClearClick(index);
            }}
          >
            {value}
          </StyledTag>
        ))}
        <Input
          id={id}
          css={`
            width: ${inputWidth + 20}px;
          `}
          onBlur={handeBlur}
          value={inputValue}
          onKeyPress={handleKeyPress}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          ref={inputRef}
        />
        {(!tagValues || (tagValues && !tagValues.length)) && inputValue === '' && (
          <PlaceHolder>{placeholder}</PlaceHolder>
        )}
        <HiddenDiv ref={hiddenDivRef}>{inputValue}</HiddenDiv>
      </Container>
    </Root>
  );
};

export default TagInput;
