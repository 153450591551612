import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { UseQueryOptions, useQuery } from 'modules/react-query';
import { PaginatedData } from 'types/PaginatedData';

import { fetchUsers, FetchUsersParams } from './api';
import { UserV2 } from './types';

export const useFetchUsers = <T = PaginatedData<UserV2>>(
  data: Omit<FetchUsersParams, 'disabled'> & {
    statuses?: string[];
  },
  config?: UseQueryOptions<PaginatedData<UserV2>, unknown, T>,
) => {
  const { data: corporate } = useCorporate();
  const { page, page_size, group_ids, statuses, search } = data;

  const queryFn = useCallback(() => {
    if (!corporate?.id) {
      return Promise.reject();
    }
    return fetchUsers(corporate.id, {
      group_ids,
      statuses,
      page,
      page_size,
      search,
    });
  }, [corporate, search, group_ids, statuses, page, page_size]);

  return useQuery({
    queryKey: ['users', corporate?.id, data],
    queryFn,
    enabled: !!corporate,
    ...config,
  });
};
