import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { Button } from '@creditornot/cb-button';

import { CloseButton, ResponsiveContainer } from 'components';
import { LocalizedMessage } from 'i18n';

const Tooltip = styled.div`
  padding-top: 22px;
  padding-bottom: 22px;
  margin: 8px 0;
  width: 320px;
  border-radius: 8px;
  background-color: ${color.bg};
  box-shadow:
    0 0 24px 0 rgb(0 0 0 / 6%),
    0 24px 24px 0 rgb(0 0 0 / 12%);
  color: ${color.text};
  font-size: 14px;
`;

const Container = styled(ResponsiveContainer)`
  align-items: center;
  justify-content: space-between;
`;

const Content = styled(ResponsiveContainer)`
  display: inline-block;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const Step = styled.div`
  color: ${color.textDisabled};
`;

export const OnboardingTooltip: React.FC<{
  children?: React.ReactNode;
  title: React.ReactNode;
  currentStep: number;
  totalSteps: number;
  onClose: () => void;
  onNext?: () => void;
  onDone?: () => void;
}> = ({ title, children, currentStep, totalSteps, onNext, onClose, onDone }) => {
  return (
    <Tooltip>
      <Container>
        <strong>{title}</strong>

        <CloseButton onClick={onClose} />
      </Container>

      <Content>{children}</Content>

      <Container>
        <Step>
          <LocalizedMessage
            messageKey="common.current-step"
            values={{
              currentStep,
              totalSteps,
            }}
          />
        </Step>

        {onNext && (
          <Button size="small" variant="blue" onClick={onNext}>
            <LocalizedMessage messageKey="common.next" />
          </Button>
        )}
        {onDone && (
          <Button size="small" variant="blue" onClick={onDone}>
            <LocalizedMessage messageKey="common.done" />
          </Button>
        )}
      </Container>
    </Tooltip>
  );
};
