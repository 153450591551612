import { useCallback } from 'react';

import {
  useQueryParams,
  NeverNullNumberParam,
  NeverNullStringParam,
  NeverNullBooleanParam,
  WoltPageSizeParam,
  withDefault,
} from 'modules/react-query-params';

export const useAgreementPartiesQueryParams = () => {
  const query = useQueryParams({
    page: withDefault(NeverNullNumberParam, 1),
    page_size: WoltPageSizeParam,
    legal_name: withDefault(NeverNullStringParam, ''),
    create_agreement_party: withDefault(NeverNullBooleanParam, false),
    edit_agreement_party: withDefault(NeverNullStringParam, ''),
  });
  const [params, setReactQueryParams] = query;
  const setQueryParams: typeof setReactQueryParams = useCallback(
    (queries) => {
      const shouldResetPage = 'page_size' in queries || 'legal_name' in queries;
      setReactQueryParams(({ page, ...staledQueries }) => ({
        ...staledQueries,
        page: shouldResetPage ? 1 : page,
        ...queries,
      }));
    },
    [setReactQueryParams],
  );
  return [params, setQueryParams] as typeof query;
};
