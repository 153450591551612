import { shallowObjectDifferences } from 'utils';
import { EditCorporateData, EditCorporateRestrictedData } from 'modules/corporates/api';

import { formatDiscountsFormValuesBeforeSending } from './formatDiscountsFormValuesBeforeSending';
import { formatGeneralInformationFormValuesBeforeSending } from './formatGeneralInformationFormValuesBeforeSending';
import { formatInvoiceFormValuesBeforeSending } from './formatInvoiceFormValuesBeforeSending';
import { EditCorporateFormValues } from '../../../views/settings/CompanySettings/types';
import { CorporateV2 } from '../types';

export const formatFormValuesBeforeSending = (
  initialValues: Partial<EditCorporateFormValues>,
  newValues: Partial<EditCorporateFormValues>,
  currency: string,
): Partial<EditCorporateData & EditCorporateRestrictedData> => {
  const updatedValues = shallowObjectDifferences(newValues, initialValues);

  return {
    ...formatGeneralInformationFormValuesBeforeSending(updatedValues),
    ...formatInvoiceFormValuesBeforeSending(updatedValues, currency),
  };
};

export const formatFormValuesBeforeSendingV2 = (
  initialValues: Partial<CorporateV2>,
  newValues: Partial<CorporateV2>,
): Partial<CorporateV2> => {
  const updatedValues = shallowObjectDifferences(newValues, initialValues);

  return formatDiscountsFormValuesBeforeSending(updatedValues);
};
