import styled from 'styled-components';

import { Divider } from 'components';

export const SplitRow = styled.div`
  display: flex;

  & > * {
    flex: 0.5;

    &:first-child {
      margin-inline-end: 20px;
    }
  }
`;

export const FormSectionDivider = styled(Divider)`
  margin-top: 24px;
  margin-bottom: 24px;
`;
