import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Transition } from 'react-transition-group';
import { colors } from '@creditornot/cb-ingredients';

import Sign from 'assets/images/exclamation-sign.svg';
import { breakpoints } from 'modules/media';
import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';

type Variant = 'danger' | 'info' | 'warning';

interface Props {
  children?: React.ReactNode;
  footerContent?: React.ReactNode;
  onClickShowMore?: (showMore: boolean) => void;
  showMore?: boolean;
  showMoreButtonTextConfig?: {
    show: string;
    hide: string;
  };
  showMoreContent?: React.ReactNode;
  variant: Variant;
  className?: string;
  title?: string;
}

const Root = styled.div<{ variant: Variant }>`
  position: relative;
  text-align: start;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  background: #fefafb;
  width: 100%;
  border-color: ${(props) => {
    switch (props.variant) {
      case 'danger':
        return colors.strawberry100;
      case 'info':
        return colors.wolt100;
      case 'warning':
        return '#fea90d';
    }
  }};
  color: ${(props) => {
    switch (props.variant) {
      case 'danger':
        return colors.strawberry100;
      case 'info':
        return colors.wolt100;
      case 'warning':
        return '#fea90d';
    }
  }};

  @media (max-width: ${breakpoints.small}px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  color: inherit;
  font-weight: 600;
  font-size: 16px;
  display: none;
  padding-inline-start: 45px;

  @media (max-width: ${breakpoints.small}px) {
    display: inline;
  }
`;

const LeftColumn = styled.div`
  flex-shrink: 0;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.small}px) {
    padding-top: 11px;
  }
`;

const CenterColumn = styled.div`
  flex: 1;
  padding-top: 11px;
  padding-bottom: 11px;

  /* https://stackoverflow.com/q/36247140/3597276 */
  min-width: 0;
  min-height: 0;

  @media (max-width: ${breakpoints.small}px) {
    padding: 11px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  /* https://stackoverflow.com/q/36247140/3597276 */
  min-width: 0;
  min-height: 0;
  max-width: 100%;
  overflow: auto;
`;

const ShowMoreContent = styled.div`
  /* https://stackoverflow.com/q/36247140/3597276 */
  min-width: 0;
  min-height: 0;
  max-width: 100%;
  padding-top: 7px;
  color: ${color.textSubdued};
`;

const RightColumn = styled.div`
  min-width: 11px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${breakpoints.small}px) {
    justify-content: flex-start;
    padding-bottom: 11px;
  }
`;

const RightColumnButtonContainer = styled.div`
  text-align: end;
  padding: 0 11px;
`;

const ShowMoreButton = styled.button`
  display: inline-block;
  width: auto;
  color: inherit;
  font-family: inherit;
  font-size: 12px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 0.7;
  padding: 2px;
  min-width: 76px;
  margin: 0;
  text-align: center;

  &:active {
    opacity: 1;
  }
`;

const StyledSign = styled(Sign).attrs({ name: 'danger' })<{ variant: Variant }>`
  position: absolute;
  top: 12px;
  ${cssDirectionalValue({ ltr: 'left: 13px;', rtl: 'right: 13px;' })}
  width: 16px;
  height: 16px;

  & > path {
    fill: ${(props) => {
      switch (props.variant) {
        case 'danger':
          return colors.strawberry100;
        case 'info':
          return colors.wolt100;
        case 'warning':
          return '#fea90d';
      }
    }};
  }
`;

const Collapse = styled.div<{ state: string }>`
  height: auto;
  overflow: hidden;
  transition: all 550ms ease-in-out;
  ${(props) => {
    switch (props.state) {
      case 'entered':
        return css`
          max-height: 600px;
        `;

      default:
        return css`
          max-height: 0;
        `;
    }
  }}
`;

const noop = () => {};

export const FlashNotification = ({
  children,
  footerContent,
  onClickShowMore,
  showMore,
  showMoreButtonTextConfig = { show: 'Show more', hide: 'hide' },
  showMoreContent,
  title,
  variant,
  ...rest
}: Props) => {
  const [controlledShowMore, setControlledShowMore] = useState(false);

  const handleShowMoreButtonClick = useMemo(() => {
    if (showMore !== undefined && !onClickShowMore) {
      return noop;
    }
    if (showMore !== undefined && onClickShowMore) {
      return () => onClickShowMore(!!showMore);
    }
    if (showMore === undefined && !onClickShowMore) {
      return () => setControlledShowMore(!controlledShowMore);
    }
    if (showMore === undefined && onClickShowMore) {
      return () => onClickShowMore(!!showMore);
    }
    return noop;
  }, [controlledShowMore, showMore, onClickShowMore]);

  const derivedShowMore = (showMore === undefined && controlledShowMore) || showMore;

  return (
    <Root variant={variant} {...rest}>
      <LeftColumn>
        <StyledSign variant={variant} />
        <Title>{title}</Title>
      </LeftColumn>

      <CenterColumn>
        <Content>{children}</Content>
        <Transition mountOnEnter timeout={0} in={!!derivedShowMore}>
          {(state) => (
            <Collapse state={state}>
              <ShowMoreContent>{showMoreContent}</ShowMoreContent>
            </Collapse>
          )}
        </Transition>
        {footerContent}
      </CenterColumn>

      <RightColumn>
        {!!showMoreContent && (
          <RightColumnButtonContainer>
            <ShowMoreButton type="button" onClick={handleShowMoreButtonClick}>
              {!!showMoreButtonTextConfig &&
                showMoreButtonTextConfig[derivedShowMore ? 'hide' : 'show']}
            </ShowMoreButton>
          </RightColumnButtonContainer>
        )}
      </RightColumn>
    </Root>
  );
};
