import { FieldValues, UseFormProps, UseFormReturn, useForm } from 'react-hook-form';
/**
 * This hook's role is to unify our form behavior
 * defaultValues should be the most common customOption that one should need to give here
 * reference https://github.com/creditornot/wolt.com/blob/master/src/client/js/common-v2/hooks/useWoltForm.ts
 */

export type UseWoltFormProps<
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/ban-types
  TContext extends object = object,
> = Omit<
  UseFormProps<TFieldValues, TContext>,
  /*
 These values have been omitted to prevent bad form UX. See https://github.com/creditornot/wolt.com/blob/master/docs/forms.md.
*/
  'mode' | 'reValidateMode'
>;

// These type generics are copied from useForm
// eslint-disable-next-line @typescript-eslint/ban-types
export const useWoltForm = <TFieldValues extends FieldValues, TContext extends object = object>(
  customOptions: UseWoltFormProps<TFieldValues, TContext> = {},
): UseFormReturn<TFieldValues, TContext> => {
  const options: UseFormProps<TFieldValues, TContext> = {
    // When set to firstError (default), only the first error from each field will be gathered.
    criteriaMode: 'firstError',
    // Validation will trigger on the first blur event. After that, it will trigger on every change event.
    mode: 'onTouched',
    // When set to true (default) and the user submits a form that fails the validation,
    // it will set focus on the first field with an error.
    shouldFocusError: true,
    ...customOptions,
  };

  return useForm<TFieldValues, TContext>(options);
};
