import { B2bCustomerConfigCreateRequest } from '@creditornot/daas-core-api-client';

import { useMutation, useQueryClient } from 'modules/react-query';

import { b2bConfigApiClient } from './api';

export const useCreateB2bConfigs = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: B2bCustomerConfigCreateRequest) =>
      b2bConfigApiClient.corporateAddB2bConfig({
        corporateId: payload.corporate_id,
        b2bCustomerConfigCreateRequest: payload,
      }),
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries(['b2bConfigs', data.corporate_id]);
      },
    },
  );
};
