import { useCallback } from 'react';

import { ConfirmationModal } from 'components';
import { useDeleteEventUser, useDeleteEventUserInvite } from 'modules/events';
import { LocalizedMessage } from 'i18n';
import { EventUser } from 'modules/events/types';

interface Props {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
  eventUsers: EventUser[];
  eventId: string;
}

export const DeleteUserEventModal = ({ show, onClose, onSuccess, eventUsers, eventId }: Props) => {
  const deleteEventUser = useDeleteEventUser();
  const deleteEventUserInvite = useDeleteEventUserInvite();
  const handleConfirm = useCallback(async () => {
    const promises = eventUsers.map(({ invite_id, status }) =>
      status === 'pending' ? deleteEventUserInvite(invite_id) : deleteEventUser(eventId, invite_id),
    );
    await Promise.all(promises);
  }, [deleteEventUser, deleteEventUserInvite, eventId, eventUsers]);
  return (
    <ConfirmationModal
      title={<LocalizedMessage messageKey="events.remove-user-from-event.title" />}
      content={
        <LocalizedMessage
          messageKey="events.remove-user-from-event.text"
          values={{
            names: <strong>{eventUsers.map(({ email }) => email).join(', ')}</strong>,
          }}
        />
      }
      onClose={onClose}
      show={show}
      cancelButtonMesssge={<LocalizedMessage messageKey="common.cancel" />}
      confirmationButtonMessage={<LocalizedMessage messageKey="common.delete" />}
      confirmationButtonVariant="lightRed"
      onSuccess={() => setTimeout(onSuccess, 500)}
      onConfirm={handleConfirm}
    />
  );
};
