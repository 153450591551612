import { LocalizedMessage } from 'i18n';

interface Props {
  deliveryAreasNames: string[];
  className?: string;
}

export const LocationsRestriction: React.FC<Props> = ({ deliveryAreasNames, className }) => {
  if (deliveryAreasNames.length === 0) {
    return <LocalizedMessage messageKey="policies.any-location" />;
  }

  const uniqueAreaNames = [...new Set(deliveryAreasNames)];

  return <span className={className}>{uniqueAreaNames.map((name) => name).join(', ')}</span>;
};
