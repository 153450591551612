import { useCallback } from 'react';

import { useQueryClient } from 'modules/react-query';

import { createAgreementParty, CreateArgreementPartyData } from './api';

export const useCreateAgreementParty = () => {
  const queryClient = useQueryClient();
  return useCallback(
    async (data: CreateArgreementPartyData) => {
      const result = await createAgreementParty(data);
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 500);
      return result;
    },
    [queryClient],
  );
};
