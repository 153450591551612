import * as React from 'react';
import styled from 'styled-components';
import { colors } from '@creditornot/cb-ingredients';
import { rem } from 'polished';
import { CaretDown } from '@creditornot/cb-icons';
import { Avatar } from '@creditornot/cb-avatar';
import type { User } from '@creditornot/wolt-auth';
import { color } from '@creditornot/cb-ingredients/design-tokens';

interface Props {
  caretDirection: 'up' | 'down';
  user: User;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const Root = styled.button`
  display: flex;
  width: ${rem(73)};
  height: ${rem(40)};
  border-radius: ${rem(20)};
  background-color: ${color.bg};
  transition: background-color 150ms linear;
  padding-inline: ${rem(2.5)} ${rem(5)};
  align-items: center;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${color.bgFillInverseHovered};
  }

  &:active {
    background-color: ${color.bgFillInversePressed};
  }
`;

const CaretIcon = styled(CaretDown)<{ $direction: Props['caretDirection'] }>`
  width: 14px;
  fill: ${color.bgFill};
  transition:
    transform 70ms ease-in,
    fill 150ms linear;
  transform: ${({ $direction }) => ($direction === 'up' ? 'scaleY(-1)' : 'scaleY(1)')};
`;

const IconContainer = styled.div`
  display: 'block';
  margin: auto;
  padding-top: ${rem(4)};
`;

const StyledAvatar = styled(Avatar)`
  cursor: pointer;
  border: ${rem(2)} solid ${colors.salt100};
`;

export const UserMenuButton = ({ caretDirection, user, onClick }: Props) => {
  return (
    <Root onClick={onClick}>
      <StyledAvatar
        imgSrc={user.profile_picture?.url}
        size="small"
        initials={`${user.name.first_name.charAt(0)}${user.name.last_name.charAt(0)}`}
      />
      <IconContainer>
        <CaretIcon $direction={caretDirection} />
      </IconContainer>
    </Root>
  );
};
