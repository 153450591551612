import { useIntl } from 'react-intl';
import { Alert } from '@creditornot/cb-alert';
import { UseFormRegisterReturn, UseFormReset, useFormContext } from 'react-hook-form';
import { InputContainer } from '@creditornot/cb-form';
import { SwitchProps } from '@creditornot/cb-switch';
import styled from 'styled-components';
import { B2bCustomerConfigResponse } from '@creditornot/daas-core-api-client';

import { ApiErrorNotification, FormSection, FormViewText, FormRowV2 as Row } from 'components';
import { B2bConfigFormValues } from 'views/settings/CompanySettings/B2bConfigSettings/types';
import { ReactHookInput } from 'components/ReactHookInput';
import { useIsUserWoltEmployee } from 'modules/wolt-permissions';
import { env } from 'config';
import { Corporate } from 'modules/corporates/types';
import { ReactHookSwitch } from 'views/settings/CompanySettings/B2bConfigSettings/B2bConfigFields/ReactHookSwitch';
import { DeliveryDistanceFieldsV2 } from 'views/settings/CompanySettings/B2bConfigSettings/B2bConfigFields/DeliveryDistanceFieldsV2';
import { DeliveryPricingTypeFieldsV2 } from 'views/settings/CompanySettings/B2bConfigSettings/B2bConfigFields/DeliveryPricingTypeFieldsV2';
import { ApiErrorContainer } from 'views/settings/CompanySettingsV2/ApiErrorContainer';

import { RenderMode } from '../../types';
import { SaveCancelButtons } from '../SaveCancelButtons';
import { EditButton } from '../EditButton';
import {
  DefaultReadOnlyBooleanRender,
  DeliveryDiscountReadOnlyRender,
  DeliveryDistanceReadOnlyRender,
  DeliveryPricingReadOnlyRender,
} from './helpers';

type Props = {
  corporate: Corporate;
  showError: boolean;
  error?: unknown;
  is404?: boolean;
  renderMode: Exclude<RenderMode, 'signup'>;
  onEditClick?: () => void;
  onSaveClick?: () => void;
  onCancelClick?: (reset: UseFormReset<B2bConfigFormValues>) => void;
  submitHandler: (
    formData: B2bConfigFormValues,
    reset: UseFormReset<B2bConfigFormValues>,
  ) => Promise<void>;
};

// Extra gap for this section in the form to improve readability
const StyledFormSection = styled(FormSection)`
  gap: 18px;

  & h6:first-child {
    margin-bottom: 0;
  }
`;

const StyledFormViewText = styled(FormViewText)`
  min-height: unset;
`;

const DefaultViewRender = ({ value }: { value?: string }) => (
  <StyledFormViewText>{value}</StyledFormViewText>
);

type RenderMapValue<FormValues> = {
  [K in keyof FormValues]: {
    [key in Props['renderMode']]: ({
      value,
      readOnlyValue,
      register,
      errorMessage,
      b2bConfig,
      switchProps,
    }: {
      // Make sure that "value" has the same type as the original field
      value: FormValues[K];
      readOnlyValue?: any;
      register: UseFormRegisterReturn;
      errorMessage?: string;
      b2bConfig?: B2bCustomerConfigResponse;
      currency?: string;
      switchProps?: Partial<SwitchProps>;
    }) => JSX.Element | null;
  };
};

type CustomB2bConfigFormValues = Omit<B2bConfigFormValues, 'deliveryRange'> & {
  discount: B2bConfigFormValues['pricing']['discounts'];
};

/**
 * A bit easier to read than the putting all of this logic in the render function.
 */
const renderMap: RenderMapValue<CustomB2bConfigFormValues> = {
  venueId: {
    edit: ({ register, errorMessage }) => (
      <ReactHookInput register={register} errorMessage={errorMessage} name="venueId" />
    ),
    view: DefaultViewRender,
  },
  merchantId: {
    edit: ({ register, errorMessage }) => (
      <ReactHookInput register={register} errorMessage={errorMessage} name="merchantId" />
    ),
    view: DefaultViewRender,
  },
  isCashEnabled: {
    edit: ({ register, switchProps }) => (
      <ReactHookSwitch register={register} fieldId="isCashEnabled" switchProps={switchProps} />
    ),
    view: DefaultReadOnlyBooleanRender,
  },
  isIntercomEnabled: {
    edit: ({ register }) => <ReactHookSwitch register={register} fieldId="isIntercomEnabled" />,
    view: DefaultReadOnlyBooleanRender,
  },
  isCustomDeliveryDistance: {
    edit: ({ errorMessage }) => <DeliveryDistanceFieldsV2 errorMessage={errorMessage} />,
    view: ({ readOnlyValue }) => <DeliveryDistanceReadOnlyRender value={readOnlyValue} />,
  },
  pricing: {
    edit: ({ currency }) => <DeliveryPricingTypeFieldsV2 currency={currency as string} />,
    view: ({ value, currency }) => (
      <DeliveryPricingReadOnlyRender value={value} currency={currency as string} />
    ),
  },
  discount: {
    // Discounts for edit mode are rendered in "pricing"
    edit: () => null,
    view: ({ value, currency }) => (
      <DeliveryDiscountReadOnlyRender value={value} currency={currency as string} />
    ),
  },
};

export const DriveConfig = ({
  corporate,
  showError,
  error,
  is404,
  renderMode,
  onEditClick,
  onCancelClick,
  onSaveClick,
  submitHandler,
}: Props) => {
  const { formatMessage } = useIntl();
  const { isWoltEmployeeWithFullCrudAccess } = useIsUserWoltEmployee();
  const isCashEnabledCountry = env.CASH_ENABLED_COUNTRIES.split(',').includes(corporate.country);

  const {
    handleSubmit,
    getValues,
    reset,
    watch,
    register,
    formState: { errors, isSubmitting, isDirty },
  } = useFormContext<B2bConfigFormValues>();

  const {
    merchantId,
    venueId,
    isCashEnabled,
    isIntercomEnabled,
    deliveryDistance,
    isCustomDeliveryDistance,
    pricing,
  } = getValues();

  const discountValue = watch('pricing.discounts');

  const merchantRegister = register('merchantId');
  const venueRegister = register('venueId');
  const isCashEnabledRegister = register('isCashEnabled');
  const isIntercomEnabledRegister = register('isIntercomEnabled');
  const pricingRegister = register('pricing');
  const discountRegister = register('pricing.discounts');
  const isCustomDeliveryDistanceRegister = register('isCustomDeliveryDistance');

  return (
    <form onSubmit={handleSubmit((formData) => submitHandler(formData, reset))}>
      <StyledFormSection
        testId="b2b-config-information"
        id="drive-config"
        title={formatMessage({ id: 'groups-form.drive-configuration-section-title' })}
        topRightItem={
          onEditClick && <EditButton renderMode={renderMode} onEditClick={onEditClick} />
        }
      >
        {renderMode === 'view' && is404 ? (
          <Alert variant="error" title="Configuration error">
            {formatMessage({ id: 'corporate-form.delivery-settings.b2b-config-error' })}
          </Alert>
        ) : (
          <>
            <Alert variant="info" size="small">
              {formatMessage({ id: 'corporate-form.employee-disclaimer' })}
            </Alert>
            <Row
              htmlFor="merchantId"
              title={formatMessage({ id: 'corporate-form.delivery-settings.merchant-id' })}
              content={
                <InputContainer
                  hint={
                    renderMode === 'view'
                      ? formatMessage({
                          id: 'corporate-form.delivery-settings.merchant-id-tooltip',
                        })
                      : formatMessage({
                          id: 'corporate-form.delivery-settings.merchant-id-explanation',
                        })
                  }
                >
                  {renderMap.merchantId[renderMode]({
                    value: merchantId,
                    register: merchantRegister,
                    errorMessage: errors.merchantId?.message,
                  })}
                </InputContainer>
              }
            />
            <Row
              htmlFor="venueId"
              title={formatMessage({ id: 'corporate-form.delivery-settings.venue-id' })}
              content={
                <InputContainer
                  hint={
                    renderMode === 'view'
                      ? formatMessage({ id: 'corporate-form.delivery-settings.venue-id-tooltip' })
                      : formatMessage({
                          id: 'corporate-form.delivery-settings.venue-id-explanation',
                        })
                  }
                >
                  {renderMap.venueId[renderMode]({
                    value: venueId,
                    register: venueRegister,
                    errorMessage: errors.venueId?.message,
                  })}
                </InputContainer>
              }
            />
            <Row
              htmlFor="isCashEnabled"
              title={formatMessage({ id: 'corporate-form.delivery-settings.cash-deliveries' })}
              content={
                <InputContainer
                  hint={
                    renderMode === 'edit'
                      ? formatMessage({
                          id: 'corporate-form.delivery-settings.cash-deliveries-explanation',
                        })
                      : undefined
                  }
                >
                  {renderMap.isCashEnabled[renderMode]({
                    value: isCashEnabled,
                    register: isCashEnabledRegister,
                    switchProps: {
                      disabled: !isWoltEmployeeWithFullCrudAccess || !isCashEnabledCountry,
                    },
                  })}
                </InputContainer>
              }
            />
            <Row
              title={formatMessage({ id: 'corporate-form.delivery-settings.intercom-enabled' })}
              content={
                <InputContainer>
                  {renderMap.isIntercomEnabled[renderMode]({
                    value: isIntercomEnabled,
                    register: isIntercomEnabledRegister,
                  })}
                </InputContainer>
              }
            />
            <Row
              title={formatMessage({ id: 'corporate-form.delivery-settings.delivery-distance' })}
              content={
                <InputContainer>
                  {renderMap.isCustomDeliveryDistance[renderMode]({
                    value: isCustomDeliveryDistance,
                    readOnlyValue: deliveryDistance,
                    register: isCustomDeliveryDistanceRegister,
                    errorMessage: errors.pricing?.discounts?.flatValue?.message,
                  })}
                </InputContainer>
              }
            />
            {renderMap.pricing[renderMode]({
              value: pricing,
              register: pricingRegister,
              currency: corporate.currency,
            })}
            {renderMode === 'view' && (
              <Row
                testId="delivery-discount.discount"
                title={formatMessage({ id: 'corporate-form.delivery-discount.discount' })}
                content={renderMap.discount[renderMode]({
                  value: discountValue,
                  register: discountRegister,
                  currency: corporate.currency,
                })}
              />
            )}
            {showError && (
              <ApiErrorContainer>
                <ApiErrorNotification error={error} />
              </ApiErrorContainer>
            )}
            <SaveCancelButtons
              renderMode={renderMode}
              onSaveClick={onSaveClick}
              onCancelClick={() => onCancelClick?.(reset)}
              isSubmitting={isSubmitting}
              dirty={isDirty}
            />
          </>
        )}
      </StyledFormSection>
    </form>
  );
};
