import { color } from '@creditornot/cb-ingredients/design-tokens';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { breakpoints } from 'modules/media';

const Root = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${color.border};
  border-inline-end: 1px solid ${color.border};
  min-height: 120px;

  & > {
    div + div {
      margin-top: 8px;
    }
  }

  @media (max-width: ${breakpoints.medium}px) {
    padding: 16px;
    min-height: unset;
  }
`;

interface Props {
  topContent?: ReactNode;
  content?: ReactNode;
  bottomContent?: ReactNode;
}

export const InformationItem: React.FC<Props> = ({ topContent, content, bottomContent }) => {
  return (
    <Root>
      {topContent && <div>{topContent}</div>}
      {content && <div>{content}</div>}
      {bottomContent && <div>{bottomContent}</div>}
    </Root>
  );
};
