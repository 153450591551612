import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  differenceInSeconds,
  minutesToSeconds,
  secondsToMinutes,
  hoursToSeconds,
  secondsToHours,
  differenceInCalendarDays,
} from 'date-fns';
import Bugsnag from '@bugsnag/browser';

import type { MessageIds } from './types';
import {
  getFinalMinimumFractionDigits,
  getFinalMaximumFractionDigits,
  getMessageValue,
  formatMessage,
  parseCurrency,
  getCurrencyConfig,
} from './utils';
import { getLanguageCodeFromLocale } from './LocaleDataProvider';
import { supportedLanguages } from './languageUtils';

/**
 * @param earlierDate
 * @param laterDate
 * @returns user-friendly date difference. If less than 1 min, return seconds.
 * If less than 1 hour, return minutes.
 * If less than 24 hours, return hours.
 * Otherwise, return days.
 */
const dateDifferenceInAutoUnits = (earlierDate: Date, laterDate: Date) => {
  const secondsDifference = differenceInSeconds(earlierDate, laterDate);
  const absSecondsDifference = Math.abs(secondsDifference);
  switch (true) {
    case absSecondsDifference < 60:
      return { value: secondsDifference, unit: 'second' as const };
    case absSecondsDifference >= minutesToSeconds(1) && absSecondsDifference < hoursToSeconds(1):
      return { value: secondsToMinutes(secondsDifference), unit: 'minute' as const };
    case absSecondsDifference >= hoursToSeconds(1) && absSecondsDifference < hoursToSeconds(24):
      return { value: secondsToHours(secondsDifference), unit: 'hour' as const };
    default:
      return { value: differenceInCalendarDays(earlierDate, laterDate), unit: 'day' as const };
  }
};

/**
 *
 * @deprecated use useIntl from react-intl instead
 */
const useI18n = () => {
  const intl = useIntl();

  const getLocalizedMessage = useCallback(
    (messageKey: MessageIds, values?: { [key: string]: any }) => {
      return intl.formatMessage({ id: messageKey }, values);
    },
    [intl],
  );

  const getLocalizedMessageFromCustomLocale = useCallback(
    async (locale: string, messageKey: MessageIds, values?: { [key: string]: any }) => {
      const languageCode = supportedLanguages.find(
        (language) => getLanguageCodeFromLocale(locale) === language.code,
      )?.code;
      if (!languageCode) {
        Bugsnag.notify(`Unsupported language code: ${locale}`);
        return intl.formatMessage({ id: messageKey }, values);
      }
      const messages = await import(`./messages/${languageCode}.json`);
      const message = getMessageValue(messages, messageKey) || messageKey;

      const localizedMessage = formatMessage(message, locale, values);

      // The translation is not available in the specific locale -> react-intl will return the fallback translation
      if (localizedMessage === messageKey) {
        return intl.formatMessage({ id: messageKey }, values);
      }

      return localizedMessage;
    },
    [intl],
  );

  const getLocalizedNumber = useCallback(
    (
      value: number,
      formattingOptions: {
        currency?: string;
        disableCurrencyParser?: boolean;
        sign?: '-' | '+';
        currencyDisplay?: 'symbol' | 'code' | 'name' | 'none';
        percent?: boolean;
        useGrouping?: boolean;
        minimumFractionDigits?: number;
        maximumFractionDigits?: number;
      } = {},
    ) => {
      const currencyConfig = formattingOptions.currency
        ? getCurrencyConfig(formattingOptions.currency)
        : undefined;

      const finalFormattingOptions = {
        style:
          (formattingOptions.currency && ('currency' as const)) ||
          (formattingOptions.percent === true && ('percent' as const)) ||
          ('decimal' as const),
        disableCurrencyParser: !!formattingOptions.disableCurrencyParser,
        currency: formattingOptions.currency,
        currencyDisplay:
          formattingOptions.currencyDisplay === 'none'
            ? undefined
            : formattingOptions.currencyDisplay || 'symbol',
        useGrouping: formattingOptions.useGrouping === false ? false : true,
        minimumFractionDigits: getFinalMinimumFractionDigits(
          formattingOptions.minimumFractionDigits,
        ),
        maximumFractionDigits: getFinalMaximumFractionDigits(
          currencyConfig?.formatDecimals || formattingOptions.maximumFractionDigits,
        ),
      };

      const formattedValue =
        finalFormattingOptions.currency && !finalFormattingOptions.disableCurrencyParser
          ? parseCurrency(value, finalFormattingOptions.currency)
          : value;

      const displayValue = intl.formatNumber(formattedValue, finalFormattingOptions);

      return formattingOptions.sign ? `${formattingOptions.sign}${displayValue}` : displayValue;
    },
    [intl],
  );

  const getLocalizedDate = useCallback(
    (
      value: Date,
      formattingOptions: {
        timeZone?: string;
        weekday?: 'long' | 'short' | 'narrow';
        year?: 'numeric' | '2-digit';
        month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
        day?: 'numeric' | '2-digit';
      },
    ) => {
      return intl.formatDate(value, formattingOptions);
    },
    [intl],
  );

  const getLocalizedTime = useCallback(
    (
      value: Date,
      formattingOptions: {
        timeZone?: string;
        hour12?: boolean;
        hour?: 'numeric' | '2-digit';
        minute?: 'numeric' | '2-digit';
        second?: 'numeric' | '2-digit';
        timeZoneName?: 'long' | 'short';
      },
    ) => {
      return intl.formatTime(value, formattingOptions);
    },
    [intl],
  );

  const getLocalizedDateTime = useCallback(
    (
      value: Date,
      formattingOptions?: {
        timeZone?: string;
        weekday?: 'long' | 'short' | 'narrow';
        year?: 'numeric' | '2-digit';
        month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
        day?: 'numeric' | '2-digit';
        hour12?: boolean;
        hour?: 'numeric' | '2-digit';
        minute?: 'numeric' | '2-digit';
        second?: 'numeric' | '2-digit';
        timeZoneName?: 'long' | 'short';
      },
    ) => {
      return intl.formatDate(value, formattingOptions);
    },
    [intl],
  );

  const getLocalizedTimeAgo = useCallback(
    (value: Date) => {
      const { value: relativeTimeValue, unit } = dateDifferenceInAutoUnits(value, new Date());

      return intl.formatRelativeTime(relativeTimeValue, unit);
    },
    [intl],
  );

  return {
    locale: intl.locale,
    getLocalizedMessage,
    getLocalizedMessageFromCustomLocale,
    getLocalizedNumber,
    getLocalizedDate,
    getLocalizedTime,
    getLocalizedDateTime,
    getLocalizedTimeAgo,
  };
};

export default useI18n;
