import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchEventStatistics } from './api';
import { EventStatistics } from './types';

export const useFetchEventStatistics = <T = EventStatistics>(
  eventId: string | null,
  config?: UseQueryOptions<EventStatistics, unknown, T>,
) => {
  const { data: corporate } = useCorporate();
  const queryFn = useCallback(() => {
    if (!corporate || !eventId) {
      return Promise.reject('Corporate or eventId is missing');
    }

    return fetchEventStatistics(corporate.id, eventId);
  }, [corporate, eventId]);
  return useQuery({ queryKey: ['event-statistics', corporate?.id, eventId], queryFn, ...config });
};
