import { Route, Switch } from 'react-router-dom';

import { CountryConfigsView } from 'views/country-configs';
import { CorporateView } from 'views/corporate';
import AgreementParties from 'views/agreement-parties';
import { LoginCallback } from 'modules/auth/LoginCallback';
import { LogoutCallback } from 'modules/auth/LogoutCallback';

import { RootRoute } from './RootRoute';
import { ProtectedRoute } from './ProtectedRoute';
import { InternalRoute } from './InternalRoute';

const Routes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/oauth2/login-callback" component={LoginCallback} />
        <Route exact path="/oauth2/logout-callback" component={LogoutCallback} />
        <RootRoute exact path="/" />
        <InternalRoute exact path="/internal/country-configs" component={CountryConfigsView} />
        <InternalRoute exact path="/internal/agreement-parties" component={AgreementParties} />
        <ProtectedRoute path="/:corporateId" component={CorporateView} />
      </Switch>
    </>
  );
};

export default Routes;
