import { InputContainer } from '@creditornot/cb-form';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import { rem } from 'polished';
import { useIntl } from 'react-intl';

import { RadioInputCardGroup } from 'components';
import { ReactHookInput } from 'components/ReactHookInput';

import { B2bConfigFormValues } from '../types';

type Props = {
  errorMessage?: string;
};

const StyledInputContainer = styled(InputContainer)`
  margin-top: ${rem(8)};
`;

export const DeliveryDistanceFieldsV2 = ({ errorMessage }: Props) => {
  const { formatMessage } = useIntl();

  const { control, watch, register } = useFormContext<B2bConfigFormValues>();
  const isCustomDeliveryDistance = watch('isCustomDeliveryDistance');

  const deliveryDistanceRegister = register('deliveryDistance');

  return (
    <>
      <Controller
        name="isCustomDeliveryDistance"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <RadioInputCardGroup
            name={name}
            value={value}
            onChange={onChange}
            stretched
            options={[
              {
                label: formatMessage({
                  id: 'corporate-form.delivery-settings.delivery-distance-default',
                }),
                explanation: formatMessage({
                  id: 'corporate-form.delivery-settings.delivery-distance-default-explanation',
                }),
                value: 'isNotCustom',
              },
              {
                label: formatMessage({
                  id: 'corporate-form.delivery-settings.delivery-distance-custom',
                }),
                explanation: formatMessage({
                  id: 'corporate-form.delivery-settings.delivery-distance-custom-explanation',
                }),
                value: 'isCustom',
              },
            ]}
          />
        )}
      />
      {isCustomDeliveryDistance === 'isCustom' && (
        <StyledInputContainer
          labelHtmlFor="maximumDistanceInMeters"
          label={formatMessage({ id: 'corporate-form.delivery-settings.distance-in-meters' })}
        >
          <ReactHookInput
            register={deliveryDistanceRegister}
            errorMessage={errorMessage}
            type="number"
            name="maximumDistanceInMeters"
          />
        </StyledInputContainer>
      )}
    </>
  );
};
