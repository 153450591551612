import { useCallback } from 'react';
import styled from 'styled-components';

import { ConfirmationModal } from 'components';
import { LocalizedMessage } from 'i18n';
import { useSendEventReminder } from 'modules/events';

type SendEventReminderModalProps = {
  show: boolean;
  eventId: string;
  onSuccess: () => void;
  onClose: () => void;
};

const Content = styled.div`
  white-space: pre-line;
`;

export const SendEventReminderModal = ({
  show,
  eventId,
  onClose,
  onSuccess,
}: SendEventReminderModalProps) => {
  const sendEventReminder = useSendEventReminder();

  const handleConfirm = useCallback(async () => {
    sendEventReminder(eventId);
  }, [eventId, sendEventReminder]);

  const handleSuccess = useCallback(() => {
    setTimeout(onSuccess, 500);
  }, [onSuccess]);

  return (
    <ConfirmationModal
      onClose={onClose}
      onConfirm={handleConfirm}
      show={show}
      title={<LocalizedMessage messageKey="events.send-event-reminder-modal.title" />}
      content={
        <Content>
          <LocalizedMessage messageKey="events.send-event-reminder-modal.text" />
        </Content>
      }
      confirmationButtonMessage={
        <LocalizedMessage messageKey="events.send-event-reminder-modal.button" />
      }
      confirmationButtonVariant="blue"
      onSuccess={handleSuccess}
    />
  );
};
