import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Button } from '@creditornot/cb-button';
import { FormattedMessage } from 'react-intl';

import { breakpoints } from 'modules/media';
import { Anchor } from 'components';
import { loginPageInteractionSignup, SignupOption } from 'telemetry/Avo';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';
import env from 'config/env';

const Title = styled.h4`
  ${typographyCss.Heading4()};
  margin-top: 160px;
  text-align: center;
`;

const GetStartedLinks = styled.div`
  ${typographyCss.Body2()};
  display: block;
  margin-top: 40px;
  text-align: center;
`;

const LoginButton = styled(Button).attrs({
  variant: 'blue',
  size: 'large',
})`
  margin-top: 40px;
  align-self: stretch;
`;

const StyledAnchor = styled(Anchor)`
  display: inline;
  font-size: 16px;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 345px;
  margin: 80px auto 60px;
  width: 100%;

  @media (max-width: ${breakpoints.medium}px) {
    margin-top: 88px;
  }

  @media (max-width: ${breakpoints.small}px) {
    margin-top: 56px;
    padding: 0 16px;
  }
`;

export const LoginForm = ({ onLoginClick }: { onLoginClick: () => void }) => {
  const signupInteractionEvent = useTelemetryInteractionEvent(loginPageInteractionSignup);

  return (
    <Root>
      <Title>
        <FormattedMessage id="login-sso.heading" />
      </Title>
      <LoginButton onClick={onLoginClick} data-test-id="email-login-button">
        <FormattedMessage id="login.login" />
      </LoginButton>
      <GetStartedLinks>
        <FormattedMessage
          id="login.get-account-link"
          values={{
            wfw: (chunks) => (
              <StyledAnchor
                target="_blank"
                href={`${env.WOLT_PAGES_URI}/wolt-for-work`}
                onClick={() => signupInteractionEvent({ signupOption: SignupOption.WOLT_FOR_WORK })}
              >
                <u>{chunks}</u>
              </StyledAnchor>
            ),
            drive: (chunks) => (
              <StyledAnchor
                target="_blank"
                href={`${env.WOLT_PAGES_URI}/wolt-drive`}
                onClick={() => signupInteractionEvent({ signupOption: SignupOption.WOLT_DRIVE })}
              >
                <u>{chunks}</u>
              </StyledAnchor>
            ),
          }}
        />
      </GetStartedLinks>
    </Root>
  );
};
