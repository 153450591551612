import { MouseEvent, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, FormikHelpers } from 'formik';
import { isEmpty } from 'ramda';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Alert } from '@creditornot/cb-alert';
import { Button, TextButton } from '@creditornot/cb-button';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';

import { FormFooter, FormikCheckboxField, ModalV2 } from 'components';
import { processError } from 'utils';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';
import {
  subscriptionIntroDataAcceptAgreement,
  subscriptionIntroInteractionDownloadAgreement,
} from 'telemetry/Avo';
import { Corporate } from 'modules/corporates/types';
import { useCreateWoltPagesLink } from 'modules/common/hooks';

interface Props {
  corporate: Corporate;
  show: boolean;
  isFetching: boolean;
  isCorporateManager: boolean;
  onSubmit: (content: string) => Promise<void>;
  onClose: () => void;
}

type FormValues = {
  consent: boolean;
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 28px;
  gap: 8px;
`;

const TooltipContent = styled.div`
  max-width: 300px;
`;

const TermsTextButton = styled(TextButton)`
  font-weight: 500;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledAlert = styled(Alert)`
  width: 100%;
  margin-top: 8px;
`;

const DisclaimerText = styled.div`
  ${typographyCss.Body3({ weight: 'semiBold' })}
`;

const ConsentText = styled.div`
  ${typographyCss.Body3()};
  margin-bottom: 8px;
`;

export const AcceptTermsModal = ({
  corporate,
  show,
  isFetching,
  isCorporateManager,
  onClose,
  onSubmit,
}: Props) => {
  const { formatMessage } = useIntl();
  const createWoltPagesLink = useCreateWoltPagesLink();

  const subscriptionIntroDataAcceptAgreementEvent = useTelemetryInteractionEvent(
    subscriptionIntroDataAcceptAgreement,
  );
  const subscriptionIntroInteractionDownloadAgreementEvent = useTelemetryInteractionEvent(
    subscriptionIntroInteractionDownloadAgreement,
  );

  const validate = useCallback((values: { consent: boolean }) => {
    if (!values.consent) {
      return {
        consent: true,
      };
    }
    return undefined;
  }, []);

  const handleDownloadTermsClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      subscriptionIntroInteractionDownloadAgreementEvent({});
      window.open(createWoltPagesLink('woltplus-terms'), '_blank');
    },
    [createWoltPagesLink, subscriptionIntroInteractionDownloadAgreementEvent],
  );

  const handleSubmit = useCallback(
    async (_: FormValues, actions: FormikHelpers<FormValues>) => {
      subscriptionIntroDataAcceptAgreementEvent({});

      actions.setSubmitting(true);

      try {
        // Pass terms content to the submit callback
        const content = formatMessage(
          { id: 'wolt-plus.consent-text' },
          {
            company: corporate.name,
            link: (text) => `${text}`,
          },
        );
        await onSubmit(content);
      } catch (e: unknown) {
        const error = processError(e);
        actions.setStatus({ error });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [corporate, formatMessage, onSubmit, subscriptionIntroDataAcceptAgreementEvent],
  );

  return (
    <ModalV2
      title={formatMessage({ id: 'wolt-plus.consent-modal-title' })}
      open={show}
      onDismiss={onClose}
      sizePreset={{
        desktop: 'centered:large',
        mobile: 'fullScreen',
      }}
      footerStyle={{ justifyContent: 'flex-end' }}
    >
      <Formik
        initialValues={{ consent: false }}
        onSubmit={handleSubmit}
        validate={validate}
        validateOnBlur={false}
      >
        {({ errors: formErrors, handleSubmit, isSubmitting, status }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormContainer>
              <DisclaimerText>
                <FormattedMessage id="wolt-plus.consent-modal-disclaimer" />
              </DisclaimerText>
              <ConsentText>
                <FormattedMessage
                  id="wolt-plus.consent-text"
                  values={{
                    company: corporate.name,
                    link: (text) => (
                      <TermsTextButton
                        wrap
                        data-test-id="wolt-plus-terms-text-button"
                        onClick={handleDownloadTermsClick}
                      >
                        {text}
                      </TermsTextButton>
                    ),
                  }}
                />
              </ConsentText>
              <FormikCheckboxField
                name="consent"
                required
                data-test-id="wolt-plus-consent-checkbox"
              >
                <FormattedMessage id="wolt-plus.consent-modal-checkbox-text" />
              </FormikCheckboxField>
              {!isEmpty(formErrors) && (
                <StyledAlert
                  title={formatMessage({ id: 'wolt-plus.consent-error-title' })}
                  variant="error"
                >
                  {formatMessage({ id: 'wolt-plus.consent-error-description' })}
                </StyledAlert>
              )}
              {!!status?.error && (
                <StyledAlert variant="error" size="medium" title={status.error.message}>
                  {status.error.data}
                </StyledAlert>
              )}
            </FormContainer>
            <FormFooter
              rightContent={
                <Popover
                  contentZIndex={1}
                  placement="left"
                  showOnHover={!isCorporateManager && !(isSubmitting || isFetching)}
                  showOnClick={false}
                  content={
                    <TooltipContent>
                      <FormattedMessage id="wolt-plus.disabled-get-started-button-tooltip" />
                    </TooltipContent>
                  }
                >
                  <StyledButton
                    variant="blue"
                    type="submit"
                    size="small"
                    loading={isSubmitting || isFetching}
                    disabled={isSubmitting || isFetching || !isCorporateManager}
                    data-test-id="wolt-plus-submit-consent-button"
                  >
                    <FormattedMessage id="common.continue" />
                  </StyledButton>
                </Popover>
              }
            />
          </form>
        )}
      </Formik>
    </ModalV2>
  );
};
