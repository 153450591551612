import { useField } from 'formik';
import { RadioCard } from '@creditornot/cb-radio';
import styled from 'styled-components';
import { DiscountType } from '@creditornot/daas-core-api-client';

import { useI18n } from 'i18n';
import { FormikRadioInputsGroupField } from 'components';

const RadioCards = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
`;

export const DeliveryPriceField = () => {
  const [{ value, onChange }] = useField<'flat_fee' | 'distance_based' | 'custom'>(
    'delivery_price_type',
  );
  const { getLocalizedMessage } = useI18n();

  return (
    <RadioCards>
      <RadioCard
        inputProps={{
          id: 'delivery-price',
        }}
        label={getLocalizedMessage('corporate-form.delivery-price-flat-rate.title')}
        subContent={getLocalizedMessage('corporate-form.delivery-price-flat-rate.subtitle')}
        value="flat_fee"
        name="delivery_price_type"
        checked={value === 'flat_fee'}
        onChange={onChange}
      />
      <RadioCard
        inputProps={{
          id: 'delivery-price',
        }}
        label={getLocalizedMessage('corporate-form.delivery-price-distance-based.title')}
        subContent={getLocalizedMessage('corporate-form.delivery-price-distance-based.subtitle')}
        value="distance_based"
        checked={value === 'distance_based'}
        onChange={onChange}
        name="delivery_price_type"
      />
      <RadioCard
        inputProps={{
          id: 'delivery-price',
        }}
        label={getLocalizedMessage('corporate-form.delivery-price-custom.title')}
        subContent={getLocalizedMessage('corporate-form.delivery-price-custom.subtitle')}
        value="custom"
        checked={value === 'custom'}
        onChange={onChange}
        name="delivery_price_type"
      />
    </RadioCards>
  );
};

export const DeliveryPriceDiscountField = () => {
  const { getLocalizedMessage } = useI18n();
  return (
    <FormikRadioInputsGroupField
      name="discount_type"
      options={[
        {
          label: getLocalizedMessage('corporate-form.delivery-discount.no-discount'),
          value: 'no_discount',
        },
        {
          label: getLocalizedMessage('corporate-form.delivery-discount.flat-amount'),
          value: DiscountType.Flat,
        },
        {
          label: getLocalizedMessage('corporate-form.delivery-discount.percentage-amount'),
          value: DiscountType.TotalPricePercentage,
        },
      ]}
    />
  );
};
