import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useMemo, useCallback } from 'react';
import styled from 'styled-components';

import { NeverNullStringParam, useQueryParams } from 'modules/react-query-params';
import { View, CountrySelect, Spinner } from 'components';
import { useConfigs } from 'modules/configs';
import { useFetchCountryConfigs } from 'modules/country-configs';

import { CreateCountryConfigForm } from './CreateCountryConfigForm';
import { EditCountryConfigForm } from './EditCountryConfigForm';

const LoadingMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 45px;
`;

const LoadingMessage = styled.span`
  margin-inline-start: 10px;
  color: ${color.textSubdued};
`;

export const CountryConfigsView = () => {
  const { configs } = useConfigs();

  const [{ country: countryCodeQueryParam }, setQueryParams] = useQueryParams({
    country: NeverNullStringParam,
  });

  const {
    data: countryConfigsData,
    refetch: refreshCountryConfigsData,
    isLoading: isLoadingCountryConfigs,
  } = useFetchCountryConfigs();

  const countryConfig = useMemo(
    () => countryConfigsData?.find((cc) => cc.country_code === countryCodeQueryParam),
    [countryCodeQueryParam, countryConfigsData],
  );

  const config = useMemo(
    () => configs?.countries.find((country) => country.code === countryCodeQueryParam),
    [configs, countryCodeQueryParam],
  );

  const countryCodeIsValidWoltCountry = !!config;

  const countryConfigExistsForCountryCode = !!countryConfig;

  const handleSuccess = useCallback(() => {
    refreshCountryConfigsData();
  }, [refreshCountryConfigsData]);

  if (isLoadingCountryConfigs) {
    return (
      <LoadingMessageContainer>
        <Spinner size="small" color="secondary" />
        <LoadingMessage>Loading country configurations and agreement parties...</LoadingMessage>
      </LoadingMessageContainer>
    );
  }

  return (
    <View
      title="Country configuration"
      rightContent={
        <CountrySelect
          options={
            configs
              ? configs.countries.map((country) => ({
                  label:
                    country.name +
                    `${
                      !countryConfigsData?.find(
                        (countryConfig) => countryConfig.country_code === country.code,
                      )
                        ? ' (unconfigured)'
                        : ''
                    }`,
                  value: country.code,
                }))
              : []
          }
          value={countryCodeQueryParam}
          onChange={(value) => setQueryParams({ country: value })}
          placeholder="Select a country to configure"
          placement="bottom-end"
        />
      }
    >
      {countryCodeIsValidWoltCountry && countryConfigExistsForCountryCode && (
        <EditCountryConfigForm
          countryName={config?.name}
          countryFlag={config?.flag}
          countryCode={config?.code}
          countryConfig={countryConfig}
          currency={config?.currency}
          onEditSuccess={handleSuccess}
        />
      )}

      {countryCodeIsValidWoltCountry && !countryConfigExistsForCountryCode && (
        <CreateCountryConfigForm
          countryName={config?.name}
          countryFlag={config?.flag}
          countryCode={config?.code}
          currency={config?.currency}
          onCreateSuccess={handleSuccess}
        />
      )}
    </View>
  );
};
