import React from 'react';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';

import { breakpoints } from 'modules/media';

export type Props = {
  className?: string;
  title: string | React.ReactNode;
  subtitle?: string;
  content: React.ReactNode;
  htmlFor?: string;
  testId?: string;
};

const Root = styled.div`
  display: flex;
  align-items: stretch;
  gap: 24px;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
    gap: 0;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  flex-shrink: 0;
  box-sizing: border-box;
  width: 269px;

  @media (max-width: ${breakpoints.medium}px) {
    width: 100%;
  }
`;

const RightColumn = styled.div`
  flex: 1;

  @media (max-width: ${breakpoints.medium}px) {
    margin-top: 8px;
  }
`;

const TitleContainer = styled.label`
  ${typographyCss.Body3()}
`;

const SubtitleContainer = styled.span`
  ${typographyCss.Body3()}
  font-size: 12px;
  opacity: 0.8;
  margin-inline-end: 14%;
`;

export const FormRowV2 = ({ title, className, subtitle, content, testId, htmlFor }: Props) => (
  <Root className={className} data-test-id={testId}>
    <LeftColumn>
      <TitleContainer htmlFor={htmlFor}>{title}</TitleContainer>
      {subtitle && <SubtitleContainer>{subtitle}</SubtitleContainer>}
    </LeftColumn>
    <RightColumn>{content}</RightColumn>
  </Root>
);
