import { MessageBubbleTextFilled } from '@creditornot/cb-icons';
import { typographyCss } from '@creditornot/cb-ingredients';
import { RouteIndicator } from '@creditornot/daas-common';
import { VFC } from 'react';
import styled from 'styled-components';
import {
  DeliveryOrderResponse as DeliveryOrder,
  DeliveryOrderResponseStatusEnum as DeliveryStatus,
} from '@creditornot/wd-api-client';
import { FormattedDate } from 'react-intl';
import { color } from '@creditornot/cb-ingredients/design-tokens';

import { getPickupCommentWithoutSenderInfo } from './utils';
import { AddressLine } from './AddressLine';

const StyledMessageBubble = styled(MessageBubbleTextFilled)`
  width: 12px;
  margin-inline-start: 8px;
  color: ${color.textSubdued};
`;

const Subtitle = styled.small`
  ${typographyCss.Small()}
  display: block;
  color: ${color.textSubdued};
`;
const Title = styled.span`
  ${typographyCss.Body3()}
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

type Props = {
  order: DeliveryOrder;
};

export const IntineraryField: VFC<Props> = ({ order }) => {
  const dropoffTimeDisplay = order.dropoff.eta || order.dropoff.options?.scheduledTime;
  return (
    <RouteIndicator
      steps={[
        {
          key: 'pickup',
          rightContent: order.pickup.eta ? (
            <Subtitle>
              <FormattedDate
                value={new Date(order.pickup.eta)}
                hour="2-digit"
                hour12={false}
                minute="2-digit"
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </Subtitle>
          ) : null,
          rightTitle: (
            <Title>
              <AddressLine
                items={[
                  order.pickup.location.address.street,
                  order.pickup.location.address.postCode,
                  order.pickup.location.address.city,
                ]}
              />
              {order.pickup.comment &&
                getPickupCommentWithoutSenderInfo(order.pickup.comment, order.pickup.sender) && (
                  <StyledMessageBubble />
                )}
            </Title>
          ),
          status:
            order.status === DeliveryStatus.BeingDelivered ||
            order.status === DeliveryStatus.Delivered
              ? 'active'
              : null,
        },
        {
          key: 'dropoff',
          rightContent: dropoffTimeDisplay ? (
            <Subtitle>
              <FormattedDate
                value={new Date(dropoffTimeDisplay)}
                hour="2-digit"
                hour12={false}
                minute="2-digit"
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </Subtitle>
          ) : null,
          rightTitle: (
            <Title>
              <AddressLine
                items={[
                  order.dropoff.location.address.street,
                  order.dropoff.location.address.postCode,
                  order.dropoff.location.address.city,
                ]}
              />
              {order.dropoff.comment && <StyledMessageBubble />}
            </Title>
          ),
          status: order.status === DeliveryStatus.Delivered ? 'active' : null,
        },
      ]}
    />
  );
};
