import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { Column, DataTable } from '@creditornot/cb-data-table';
import { TextButton } from '@creditornot/cb-button';
import { InfoCircleFilled } from '@creditornot/cb-icons';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';

import { LocalizedMessage, useI18n } from 'i18n';
import { ApiErrorNotification, DataCellContent, ResponsiveContainer } from 'components';
import {
  BudgetRestriction,
  LocationsRestriction,
  TimesRestriction,
} from 'modules/policies/components';
import { Policy } from 'modules/policies/types';

import PoliciesDataTableActions from './PoliciesDataTableActions';
import { GroupsDataCellContent } from './GroupsDataCellContent';

interface ComponentProps {
  onOpenEditPolicyModal: (id: string) => void;
  onOpenDeletePolicyModal: (id: string) => void;
}

const StyledInfoCircleFilled = styled(InfoCircleFilled)`
  width: 16px;
  height: 16px;
  margin-inline-start: 8px;
  fill: ${color.text} !important;
  vertical-align: bottom;
`;

const Content = styled.div`
  max-width: 280px;
  padding: 8px;
`;

const GroupCoumnName = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Text = styled.div`
  margin-top: 4px;
`;

interface Props {
  data: Policy[] | null;
  loading?: boolean;
  dataCellProps: ComponentProps;
  onCreatePolicyClick: () => void;
  error?: any;
}

const ErrorContainer = styled(ResponsiveContainer)`
  padding-top: 22px;
  padding-bottom: 22px;
`;

const useColumns = (): Column<Policy, ComponentProps>[] => {
  const { getLocalizedMessage } = useI18n();

  return [
    {
      id: 'name',
      name: getLocalizedMessage('common.name'),
      render: ({ record: { name, description } }) => {
        return <DataCellContent subContent={description}>{name}</DataCellContent>;
      },
    },
    {
      id: 'budget',
      name: getLocalizedMessage('policies.budget-column-title'),
      render: ({ record }) => {
        return (
          <DataCellContent
            subContent={
              record.budget_allowance?.period && (
                <BudgetRestriction.Description period={record.budget_allowance.period} />
              )
            }
          >
            <BudgetRestriction.Content
              amount={record.budget_allowance?.amount ?? null}
              currency={record.budget_allowance?.currency ?? null}
            />
          </DataCellContent>
        );
      },
    },
    {
      id: 'time-restriction',
      name: getLocalizedMessage('policies.time-restriction-column-title'),
      render: ({ record }) => {
        return (
          <DataCellContent
            subContent={
              <TimesRestriction.Description
                startTimeInMinutes={record.times?.[0]?.start_minute}
                endTimeInMinutes={record.times?.[0]?.end_minute}
              />
            }
          >
            <TimesRestriction.Content days={record.times.map(({ day_of_week }) => day_of_week)} />
          </DataCellContent>
        );
      },
    },
    {
      id: 'delivery-location',
      name: getLocalizedMessage('policies.delivery-location'),
      render: ({ record: { delivery_areas } }) => {
        return (
          <DataCellContent>
            <LocationsRestriction deliveryAreasNames={delivery_areas.map(({ name }) => name)} />
          </DataCellContent>
        );
      },
    },
    {
      id: 'accounting-note',
      name: getLocalizedMessage('common.accounting-note'),
      render: ({ record: { order_comment_required } }) => {
        return (
          <DataCellContent>
            {order_comment_required ? (
              <LocalizedMessage messageKey="common.required" />
            ) : (
              <LocalizedMessage messageKey="common.optional" />
            )}
          </DataCellContent>
        );
      },
    },
    {
      id: 'venues',
      name: getLocalizedMessage('policies.table.allowed-venues'),
      render: ({
        record: {
          non_restaurant_purchases_allowed,
          giftcard_purchases_allowed,
          restaurant_purchases_allowed,
        },
      }) => {
        return (
          <div>
            {restaurant_purchases_allowed && (
              <Text>
                <LocalizedMessage messageKey="policies.allowed-venues.restaurants" />
              </Text>
            )}
            {non_restaurant_purchases_allowed && (
              <Text>
                <LocalizedMessage messageKey="policies.table.allowed-venues.non-restaurants" />
              </Text>
            )}
            {giftcard_purchases_allowed && (
              <Text>
                <LocalizedMessage messageKey="policies.allowed-venues.gift-card-venues" />
              </Text>
            )}
          </div>
        );
      },
    },
    {
      id: 'groups',
      style: {
        width: '15%',
      },
      name: (
        <GroupCoumnName>
          <LocalizedMessage messageKey="policies.table.groups" />
          <Popover
            placement="top"
            content={
              <Content>
                <LocalizedMessage messageKey="policies.table.groups-hint" />
              </Content>
            }
          >
            <StyledInfoCircleFilled />
          </Popover>
        </GroupCoumnName>
      ) as unknown as string,
      render: ({ record: { groups } }) => {
        return <GroupsDataCellContent groups={groups} />;
      },
    },
    {
      id: 'actions',
      style: {
        width: '8%',
      },
      name: '',
      render: ({ record: { id }, onOpenDeletePolicyModal, onOpenEditPolicyModal }) => {
        return (
          <PoliciesDataTableActions
            policyId={id}
            onEditClick={() => onOpenEditPolicyModal(id)}
            onDeleteClick={() => onOpenDeletePolicyModal(id)}
          />
        );
      },
    },
  ];
};

const PoliciesDataTable: React.FC<Props> = ({
  data,
  onCreatePolicyClick,
  error,
  dataCellProps,
  ...rest
}) => {
  const { getLocalizedMessage } = useI18n();
  const columns = useColumns();

  if (error) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={error} />
      </ErrorContainer>
    );
  }

  return (
    <DataTable
      horizontalPadding="wide"
      dataSource={data ?? []}
      columns={columns}
      tableLayout="fixed"
      getRowKey={(policy) => policy.id}
      emptyMessage={{
        title: getLocalizedMessage('policies.no-policies'),
        description: (
          <>
            <LocalizedMessage messageKey="policies.no-policies-explanation" />
            <br />
            <TextButton onClick={onCreatePolicyClick}>
              <LocalizedMessage messageKey="policies.create-your-first-policy" />
            </TextButton>
          </>
        ) as unknown as string,
      }}
      componentProps={{ ...dataCellProps }}
      {...rest}
    />
  );
};

export default PoliciesDataTable;
