import { color } from '@creditornot/cb-ingredients/design-tokens';
import { MessageBubbleTextFilled } from '@creditornot/cb-icons';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  color: ${color.textSubdued};
  margin-top: 4px;

  div:not(:first-child) {
    margin-top: 4px;
  }
`;

const AccountingNoteContainer = styled.div`
  width: 100%;
  color: ${color.text};
`;

const StyledMessageBubbleTextFilled = styled(MessageBubbleTextFilled)`
  height: 16px;
  margin-inline-end: 8px;
  vertical-align: middle;
`;

interface Props {
  name?: ReactNode;
  accountingComment?: ReactNode;
  paymentMethodName?: ReactNode;
}

export const PaymentInfo = (props: Props) => {
  const { name, accountingComment, paymentMethodName } = props;
  return (
    <Root>
      {name && <div>{name}</div>}
      {paymentMethodName && <div>{paymentMethodName}</div>}
      {accountingComment && (
        <AccountingNoteContainer>
          <StyledMessageBubbleTextFilled />
          {accountingComment}
        </AccountingNoteContainer>
      )}
    </Root>
  );
};
