import { FormikInputField } from 'components';

interface Props {
  alwaysShowError?: boolean;
  className?: string;
}

export const CustomerReferenceField = ({ alwaysShowError, className }: Props) => {
  return (
    <FormikInputField
      name="customer_reference"
      type="text"
      alwaysShowError={alwaysShowError}
      className={className}
    />
  );
};
