import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useCallback } from 'react';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';
import { useHistory } from 'react-router-dom';
import { Tag } from '@creditornot/cb-tag';
import { TextButton } from '@creditornot/cb-button';
import { Spinner } from '@creditornot/cb-progress';

import {
  ApiErrorNotification,
  Divider,
  EmptyState,
  Header,
  InformationDisplay,
  ResponsiveContainer,
} from 'components';
import {
  AccountingNoteRequirement,
  BudgetRestriction,
  LocationsRestriction,
  TimesRestriction,
} from 'modules/policies/components';
import { breakpoints } from 'modules/media';
import { Group } from 'modules/groups/types';
import { LocalizedMessage, useI18n } from 'i18n';
import { useCorporate } from 'modules/corporates';

interface Props {
  group: Group | null | undefined;
  isLoading: boolean;
  error: any;
  onAcivateGroupClick: () => void;
  onDeacivateGroupClick: () => void;
  onAcivateAutoAddClick: () => void;
  onDeacivateAutoAddClick: () => void;
  onChangeGroupPolicyClick: () => void;
  onRemoveGroupPolicyClick: () => void;
}

const StyledGroupHeader = styled(Header)`
  min-height: 84px;

  @media (max-width: ${breakpoints.medium}px) {
    min-height: unset;
  }
`;

const Root = styled.div`
  flex-direction: column;
  display: flex;
`;

const StyledEmptyState = styled(EmptyState)`
  padding-top: 51px;
  padding-bottom: 51px;
`;

const Container = styled.div`
  padding: 8px 24px;

  @media (max-width: ${breakpoints.medium}px) {
    padding: 8px 16px;
  }
`;

const Item = styled.div`
  padding-bottom: 16px;
  padding-top: 16px;

  & + & {
    border-top: 1px solid ${color.border};
  }
`;

const FirstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const SpinnerWrapper = styled(ResponsiveContainer)`
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const StyledInformationDisplay = styled(InformationDisplay)`
  padding-top: 16px;
`;

const StyledText = styled.span`
  ${typographyCss.Body3()}
  display: block;
  color: ${color.text};
`;

const Heading = styled.h6`
  ${typographyCss.Heading6()}
`;

const Description = styled.small`
  ${typographyCss.Small()}
  max-width: 80%;
  display: inline-block;
  color: ${color.textSubdued};
`;

const Content = styled.span`
  ${typographyCss.Body3()}
`;

export const GroupDetails: React.FC<Props> = ({
  error,
  group,
  isLoading,
  onAcivateAutoAddClick,
  onAcivateGroupClick,
  onDeacivateAutoAddClick,
  onDeacivateGroupClick,
  onChangeGroupPolicyClick,
  onRemoveGroupPolicyClick,
}) => {
  const history = useHistory();
  const { getLocalizedMessage } = useI18n();
  const { data: corporate } = useCorporate();
  const policy = group?.policies[0];

  const handleAddGroupPolicyClick = useCallback(() => {
    history.push(`${history.location.pathname}/change-policy`);
  }, [history]);

  if (isLoading || error) {
    return (
      <Root>
        <Header
          leftItems={[
            <Heading key="groupDetailsHeader">
              <LocalizedMessage messageKey="groups.policy.header" />
            </Heading>,
          ]}
        />

        <Divider />
        {isLoading && (
          <SpinnerWrapper>
            <Spinner size="large" />
          </SpinnerWrapper>
        )}
        {error && (
          <Item>
            <ApiErrorNotification error={error} />
          </Item>
        )}
      </Root>
    );
  }

  if (!group) {
    return null;
  }

  return (
    <Root>
      <StyledGroupHeader
        leftItems={[
          <Heading key="groupDetailsHeader">
            <LocalizedMessage messageKey="common.settings" />
          </Heading>,
        ]}
      />

      <Divider />

      <Container>
        <Item>
          <FirstRow>
            <div>
              <Content>
                <LocalizedMessage messageKey="groups.group-status" />
                {':'}{' '}
              </Content>
              {group.active ? (
                <Tag variant="lightGreen">
                  <LocalizedMessage messageKey="groups.active-group-status" />
                </Tag>
              ) : (
                <Tag variant="lightBlack">
                  <LocalizedMessage messageKey="groups.inactive-group-status" />
                </Tag>
              )}
            </div>

            {group.active ? (
              <TextButton onClick={onAcivateGroupClick}>
                <LocalizedMessage messageKey="groups.deactivate-group" />
              </TextButton>
            ) : (
              <TextButton onClick={onDeacivateGroupClick}>
                <LocalizedMessage messageKey="groups.activate-group" />
              </TextButton>
            )}
          </FirstRow>

          <Description>
            {group.active ? (
              <LocalizedMessage
                messageKey="groups.active-group-description"
                values={{
                  name: (
                    <strong>
                      {corporate?.name} - {group.name}
                    </strong>
                  ),
                }}
              />
            ) : (
              <LocalizedMessage
                messageKey="groups.inactive-group-description"
                values={{
                  name: (
                    <strong>
                      {corporate?.name} - {group.name}
                    </strong>
                  ),
                }}
              />
            )}
          </Description>
        </Item>

        <Item>
          <FirstRow>
            <div>
              <Content>
                <LocalizedMessage messageKey="groups.auto-add-group" />
                {':'}{' '}
              </Content>
              {group.add_to_all ? (
                <Tag variant="lightGreen">
                  <LocalizedMessage messageKey="groups.active-auto-add-group-status" />
                </Tag>
              ) : (
                <Tag variant="lightBlack">
                  <LocalizedMessage messageKey="groups.inactive-auto-add-group-status" />
                </Tag>
              )}
            </div>

            {group.add_to_all ? (
              <TextButton onClick={onDeacivateAutoAddClick}>
                <LocalizedMessage messageKey="groups.turn-off-auto-add-group" />
              </TextButton>
            ) : (
              <TextButton onClick={onAcivateAutoAddClick}>
                <LocalizedMessage messageKey="groups.turn-on-auto-add-group" />
              </TextButton>
            )}
          </FirstRow>

          <Description>
            {group.add_to_all ? (
              <LocalizedMessage messageKey="groups.active-auto-add-group-description" />
            ) : (
              <LocalizedMessage messageKey="groups.inactive-auto-add-group-description" />
            )}
          </Description>
        </Item>

        {policy ? (
          <Item>
            <FirstRow>
              <Content>
                <LocalizedMessage messageKey="groups.group-policy" />
              </Content>

              <div>
                <TextButton onClick={onChangeGroupPolicyClick}>
                  <LocalizedMessage messageKey="groups.change-policy-button" />
                </TextButton>
                <TextButton
                  variant="red"
                  onClick={onRemoveGroupPolicyClick}
                  style={{ marginInlineStart: '16px' }}
                >
                  <LocalizedMessage messageKey="groups.remove-policy-button" />
                </TextButton>
              </div>
            </FirstRow>

            <div>
              <Content>
                <strong>{policy.name}</strong>
              </Content>
              <StyledText>{policy.description}</StyledText>
            </div>

            <StyledInformationDisplay
              labelColor="black"
              label={getLocalizedMessage('policies.budget-column-title')}
            >
              <StyledText>
                <BudgetRestriction.Content
                  amount={policy.budget_allowance?.amount ?? null}
                  currency={policy.budget_allowance?.currency ?? null}
                />{' '}
                {policy.budget_allowance?.period && (
                  <BudgetRestriction.Description period={policy.budget_allowance.period} />
                )}
              </StyledText>
            </StyledInformationDisplay>
            <InformationDisplay
              labelColor="black"
              label={getLocalizedMessage('groups.accounting_note')}
            >
              <StyledText>
                <AccountingNoteRequirement
                  orderCommentRequired={policy.order_comment_required}
                  short={true}
                />
              </StyledText>
            </InformationDisplay>

            <InformationDisplay
              labelColor="black"
              label={getLocalizedMessage('policies.delivery-location')}
            >
              <StyledText>
                <LocationsRestriction
                  deliveryAreasNames={policy.delivery_areas.map(({ name }) => name)}
                />
              </StyledText>
            </InformationDisplay>
            <InformationDisplay
              labelColor="black"
              label={getLocalizedMessage('policies.time-restriction-column-title')}
            >
              <StyledText>
                <TimesRestriction.Content
                  days={policy.times.map(({ day_of_week }) => day_of_week)}
                />
              </StyledText>
              <StyledText>
                <TimesRestriction.Description
                  startTimeInMinutes={policy.times?.[0]?.start_minute}
                  endTimeInMinutes={policy.times?.[0]?.end_minute}
                />
              </StyledText>
            </InformationDisplay>
          </Item>
        ) : (
          <Item>
            <StyledEmptyState
              subtitle={<LocalizedMessage messageKey="groups.no-policies-in-group-explanation" />}
              icon="policy"
              actionButtonText={<LocalizedMessage messageKey="groups.add-policy-to-group" />}
              onActionButtonClick={handleAddGroupPolicyClick}
            >
              <LocalizedMessage messageKey="groups.no-policy-in-group" />
            </StyledEmptyState>
          </Item>
        )}
      </Container>
    </Root>
  );
};
