import { FormikInputField } from 'components';
import { FormikInputFieldProps } from 'components/FormikInputField';
import { createValidator, isRequired } from 'validation';

const validate = createValidator([isRequired]);

interface Props extends Omit<FormikInputFieldProps, 'name' | 'type' | 'required' | 'validate'> {
  alwaysShowError?: boolean;
  className?: string;
}

export const StreetAddressField = ({ alwaysShowError, className, ...rest }: Props) => {
  return (
    <FormikInputField
      name="address"
      type="text"
      required={true}
      validate={validate}
      alwaysShowError={alwaysShowError}
      className={className}
      inputProps={{ id: 'address' }}
      {...rest}
    />
  );
};
