import axios, { AxiosError } from 'axios';

import isDefined from './isDefined';

type ThirdPartyError = {
  error: string;
};

type WAuthError = {
  msg: string;
  error_code: number;
  data: unknown;
  title?: string;
};

type DSSAPIError = {
  error: string;
  message: string;
  statusCode: number;
};

type CorporateServiceError = {
  details: string;
  reason: string;
  error_code: number;
};

export interface APIFailure {
  statusCode?: number;
  message?: string;
  errorCode?: number;
  data?: string;
}

const capitalize = (s: unknown) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const isThirdPartyError = (error: unknown): error is ThirdPartyError =>
  typeof error === 'object' && error !== null && 'error' in error;

const isWAuthError = (error: unknown): error is WAuthError =>
  typeof error === 'object' && error !== null && 'msg' in error && 'error_code' in error;

const isDSSApiError = (error: unknown): error is DSSAPIError =>
  typeof error === 'object' &&
  error !== null &&
  'error' in error &&
  'statusCode' in error &&
  'message' in error;

const isCorporateServiceError = (error: unknown): error is CorporateServiceError =>
  typeof error === 'object' &&
  error !== null &&
  'details' in error &&
  'reason' in error &&
  'error_code' in error;

/**
 * Return error context from different type of API error
 */
const getErrorContext = (error: AxiosError) => {
  const { response } = error;
  if (!response?.data) {
    return { message: String(response?.data ?? 'Unknown error') };
  }
  if (isCorporateServiceError(response.data)) {
    return {
      message: response.data.reason,
      errorCode: response.data.error_code,
      data: response.data.details?.length ? response.data.details : undefined,
    };
  }
  if (isWAuthError(response.data)) {
    return {
      message: response.data.msg,
      errorCode: response.data.error_code,
      data: [
        typeof response?.data?.data === 'string' ? response.data?.data : undefined,
        typeof response?.data?.title === 'string' ? response.data?.title : undefined,
      ].filter(isDefined)[0],
    };
  }
  if (isDSSApiError(response.data)) {
    return { message: response.data.message, errorCode: response.data.statusCode };
  }
  if (typeof response.data === 'object' && 'message' in response.data) {
    return { message: (response.data as { message: string }).message };
  }
  if (typeof response.data === 'object' && 'detail' in response.data) {
    return { message: (response.data as { detail: string }).detail };
  }
  if (typeof response.data === 'object') {
    return { message: '' };
  }
  return { message: String(response.data) };
};

const parseErrorToString = (error: unknown) => {
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error === 'object' && error !== null && 'message' in error) {
    return String((error as { message: string }).message);
  }
  return String(error);
};

const processError = (error: unknown): APIFailure => {
  if (axios.isAxiosError(error) && error.response) {
    const { response } = error;
    const { message, errorCode, data } = getErrorContext(error);
    return {
      statusCode: response.status,
      message: capitalize(message),
      errorCode: errorCode,
      data: data,
    };
  }

  if (isThirdPartyError(error)) {
    return {
      message: capitalize(error.error),
    };
  }

  return {
    message: capitalize(parseErrorToString(error)),
  };
};

export const processErrorBlob = async (error: unknown): Promise<APIFailure> => {
  if (axios.isAxiosError(error) && error.response) {
    const { response } = error;
    const parsedData = JSON.parse(await response.data.text());
    const { message, errorCode, data } = getErrorContext({
      ...error,
      response: { ...response, data: parsedData },
    });
    return {
      statusCode: response.status,
      message: capitalize(message),
      errorCode: errorCode,
      data: data,
    };
  }

  if (isThirdPartyError(error)) {
    return {
      message: capitalize(error.error),
    };
  }

  return {
    message: capitalize(parseErrorToString(error)),
  };
};

export default processError;
