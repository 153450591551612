import { useField } from 'formik';
import { Input, InputProps } from '@creditornot/cb-input';
import { useCallback, useMemo } from 'react';

import { getFormikFieldError } from 'validation';
import { useI18n } from 'i18n';

import { FormikFieldContainer } from './FormikFieldContainer';

export type FormikInputFieldProps = {
  alwaysShowError?: boolean;
  showErrorMessage?: boolean;
  className?: string;
  testId?: string;
  inputProps?: Partial<InputProps>;
  name: string;
  required?: boolean;
  type?: 'text' | 'number' | 'email' | 'password' | 'tel' | 'url';
  allowClear?: boolean;
  validate?: (value: any) => string | void | Promise<string | void>;
  disabled?: boolean;
};

export const FormikInputField = ({
  alwaysShowError = false,
  showErrorMessage = true,
  className,
  testId,
  inputProps,
  name,
  required,
  type = 'text',
  validate,
  allowClear = true,
  disabled,
}: FormikInputFieldProps) => {
  const { getLocalizedMessage } = useI18n();

  const [field, meta, { setValue }] = useField({
    name,
    validate,
  });

  const formikFieldError = useMemo(
    () => getFormikFieldError(meta, alwaysShowError),
    [alwaysShowError, meta],
  );

  const errorMessage = useMemo(
    () =>
      formikFieldError && getLocalizedMessage(formikFieldError.messageKey, formikFieldError.values),
    [formikFieldError, getLocalizedMessage],
  );

  const handleClearClick = useCallback(() => setValue('', true), [setValue]);

  return (
    <FormikFieldContainer errorMessage={showErrorMessage && errorMessage} className={className}>
      <Input
        data-test-id={testId}
        required={required}
        type={type}
        onClearClick={allowClear ? handleClearClick : undefined}
        invalid={!!errorMessage}
        id={name}
        disabled={disabled}
        {...field}
        {...inputProps}
      />
    </FormikFieldContainer>
  );
};
