import type { AvoInspector } from 'avo-inspector';

import env from 'config/env';

import * as Avo from './Avo';
import { Gatekeeper } from './destinations/gatekeeper';

let avoInspector: AvoInspector;

const getAvoInspector = async () => {
  if (avoInspector) {
    return avoInspector;
  }

  if (!Avo.avoInspectorApiKey) {
    return;
  }

  const { AvoInspector, AvoInspectorEnv } = await import('avo-inspector');

  avoInspector = new AvoInspector({
    apiKey: Avo.avoInspectorApiKey,
    env: env.APP_ENV === 'production' ? AvoInspectorEnv.Prod : AvoInspectorEnv.Dev,
    version: env.APP_VERSION ?? 'NO_APP_VERSION',
    appName: 'Corporate Admin Web',
  });

  /**
   * https://github.com/avohq/js-avo-inspector/
   * In production:
   *  - Batching: default batch size is 30 and default batch flush timeout is 30 seconds.
   *  - Logs: logs are disabled in prod mode.
   * In development:
   *   - Batching: mode batching is disabled, each event is sent and logged separately.
   *   - Logs: logs are enabled by default in the dev mode.
   **/
  avoInspector.enableLogging(true);

  return avoInspector;
};

export const avoInit = () => {
  try {
    getAvoInspector().then((inspector) => {
      Avo.initAvo(
        {
          env: env.APP_ENV === 'production' ? Avo.AvoEnv.Prod : Avo.AvoEnv.Dev,
          inspector,
          webDebugger: false,
        },
        {},
        Gatekeeper,
      );
    });
  } catch (error) {
    console.error('Avo has not been initialized with an error from Avo inspector', error);
  }
};
