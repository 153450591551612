import { useCallback } from 'react';
import {
  DeliveryOrderApiGetDeliveryOrderCountRequest as GetDeliveryOrderCountRequest,
  GetDeliveryOrderCountResponse,
} from '@creditornot/wd-api-client';

import { useQuery, UseQueryOptions } from 'modules/react-query';

import { deliveryOrderApiClient } from './api';

export const useFetchDeliveryCount = <T = GetDeliveryOrderCountResponse>(
  params: GetDeliveryOrderCountRequest,
  config?: UseQueryOptions<GetDeliveryOrderCountResponse, unknown, T>,
) => {
  const queryFn = useCallback(async () => {
    const { data } = await deliveryOrderApiClient.getDeliveryOrderCount(params);
    return data;
  }, [params]);
  return useQuery({ queryKey: ['delivery-count', params], queryFn, ...config });
};
