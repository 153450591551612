import styled from 'styled-components';
import { Column, DataTable } from '@creditornot/cb-data-table';

import { LocalizedNumber, LocalizedDate, useI18n } from 'i18n';
import { DataCellContent, ApiErrorNotification, ResponsiveContainer, Spinner } from 'components';
import { useFetchInvoices } from 'modules/invoices';
import { Invoice } from 'modules/invoices/types';

import { useInvoicesQueryParams } from './useInvoicesQueryParams';
import { InvoiceDropdownActions } from './InvoiceDropdownActions';

type InvoicesDataTableProps = {
  onDownloadDocumentClick: (
    url: string,
    createdAt: string,
    document: string,
    invoiceId: string,
  ) => void;
  isDownloading: undefined | string;
};

type ComponentProps = {
  onDownloadDocumentClick: (
    url: string,
    createdAt: string,
    document: string,
    invoiceId: string,
  ) => void;
  isDownloading: undefined | string;
};

const ErrorContainer = styled(ResponsiveContainer)`
  padding-top: 22px;
  padding-bottom: 22px;
`;

const SpinnerContainer = styled.div`
  max-height: 32px;

  svg {
    top: 8px;
  }
`;

const useColumns = (): Column<Invoice, ComponentProps>[] => {
  const { getLocalizedMessage } = useI18n();
  return [
    {
      id: 'name',
      alignContentVertical: 'middle',
      name: getLocalizedMessage('invoices.invoice-number'),
      render: ({ record: { payment_reference_id } }) => {
        return <DataCellContent>{payment_reference_id}</DataCellContent>;
      },
    },
    {
      id: 'invoicingPeriod',
      alignContentVertical: 'middle',
      name: getLocalizedMessage('invoices.invoicing-period'),
      render: ({ record: { start_date, end_date } }) => {
        return (
          <DataCellContent>
            <LocalizedDate value={new Date(start_date)} />
            {' – '}
            <LocalizedDate value={new Date(end_date)} />
          </DataCellContent>
        );
      },
    },
    {
      id: 'invoiceTotal',
      name: getLocalizedMessage('invoices.total-payable-amount'),
      alignContentVertical: 'middle',
      render: ({ record: { total_amount, currency } }) => {
        return (
          <DataCellContent>
            <LocalizedNumber value={total_amount} currency={currency} />
          </DataCellContent>
        );
      },
    },
    {
      id: 'actions',
      style: {
        width: '5%',
      },
      name: '',
      render: ({ record: invoice, onDownloadDocumentClick, isDownloading }) => {
        return isDownloading === invoice.id ? (
          <SpinnerContainer>
            <Spinner size="small" />
          </SpinnerContainer>
        ) : (
          <InvoiceDropdownActions
            invoice={invoice}
            onDownloadDocumentClick={onDownloadDocumentClick}
          />
        );
      },
    },
  ];
};

export const InvoicesDataTable = ({
  onDownloadDocumentClick,
  isDownloading,
}: InvoicesDataTableProps) => {
  const columns = useColumns();
  const { getLocalizedMessage } = useI18n();
  const [{ page, page_size }, setQueryParams] = useInvoicesQueryParams();

  const { data, isLoading, error } = useFetchInvoices({
    page,
    page_size,
    pagination: true,
  });

  if (error) {
    return (
      <ErrorContainer>
        <ApiErrorNotification error={error} />
      </ErrorContainer>
    );
  }

  const hidePagination = isLoading || data?.total_items_count === 0;

  return (
    <DataTable
      horizontalPadding="wide"
      tableLayout="fixed"
      dataSource={data?.results ?? []}
      columns={columns}
      getRowKey={(dataItem) => dataItem.id}
      loading={isLoading}
      emptyMessage={{
        title: getLocalizedMessage('invoices.no-invoices'),
        description: getLocalizedMessage('invoices.no-invoices-explanation'),
      }}
      componentProps={{ onDownloadDocumentClick, isDownloading }}
      pagination={
        hidePagination
          ? undefined
          : {
              disableWhenLoading: true,
              pageCount: Math.ceil((data?.total_items_count ?? 0) / (data?.page_size ?? 1)),
              pageSize: page_size,
              activePage: page,
              onPageChange: (p) => setQueryParams({ page: p }),
              onPageSizeChange: (p) => {
                setQueryParams({ page: 1, page_size: p });
              },
              pageSizeOptions: [10, 20, 50, 100],
            }
      }
    />
  );
};
