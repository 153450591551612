import { useCallback } from 'react';
import styled from 'styled-components';
import { Route, useHistory } from 'react-router-dom';
import { MenuLinkItem } from '@creditornot/cb-menu';

import { event } from 'modules/google-analytics';
import { useCorporate } from 'modules/corporates';
import { useFetchB2bConfig } from 'modules/b2b-configs';
import { getCorporateRoutes } from 'modules/corporates/getCorporateRoutes';

interface Props {
  onClick?: (url: string) => void;
}

const StyledMenuLinkItem = styled(MenuLinkItem)`
  margin-top: 8px;

  :first-child {
    margin-top: 0;
  }

  &,
  :visited {
    text-decoration: none;
  }
`;

export const NavLinks = ({ onClick }: Props) => {
  const history = useHistory();
  const { data: corporate, isFetching: isLoading } = useCorporate();
  const { data: b2bConfig, isFetching: isB2bConfigLoading } = useFetchB2bConfig(
    corporate?.id || null,
  );

  const handleLinkItemClick = useCallback(
    (url: string) => {
      history.push(url);
      onClick?.(url);
    },
    [history, onClick],
  );

  if (isLoading || !corporate || isB2bConfigLoading) {
    return null;
  }

  const routes = getCorporateRoutes(corporate, b2bConfig);

  return (
    <>
      {routes.map((route) => (
        <Route key={route.to} path={route.to}>
          {({ match }) => (
            <StyledMenuLinkItem
              data-test-id={`menu-link-${route.name}`}
              active={!!match}
              icon={route.icon}
              onClick={() => {
                event({
                  category: 'side_bar_navigation',
                  action: 'click',
                  label: route.name,
                });
                handleLinkItemClick(route.to);
              }}
              disabled={route.isDisabled}
              data-product-tour={route.dataProductTour}
            >
              {route.content}
            </StyledMenuLinkItem>
          )}
        </Route>
      ))}
    </>
  );
};
