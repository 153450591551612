import { color } from '@creditornot/cb-ingredients/design-tokens';
import React from 'react';
import styled from 'styled-components';
import { typographyCss } from '@creditornot/cb-ingredients';

import { breakpoints } from 'modules/media';

type Props = {
  className?: string;
  id?: string;
  testId?: string;
  title: string | React.ReactNode;
  content: string | React.ReactNode;
  icon: React.ReactNode;
};

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  padding: 24px 20px 24px 24px;
  border: 1px solid ${color.border};
  border-radius: 10px;
  background-color: ${color.bg};

  @media (max-width: ${breakpoints.small}px) {
    padding: 24px 16px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${color.bgSurfaceBrandSelected};
  align-items: center;
  justify-content: center;

  > svg {
    width: 28px;
    fill: rgb(0 157 224);
  }

  @media (max-width: ${breakpoints.small}px) {
    margin-bottom: 8px;
  }
`;

const Title = styled.h6`
  ${typographyCss.Heading6()}
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;

  @media (max-width: ${breakpoints.small}px) {
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  ${typographyCss.Body3()}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export function InformationSection({ title, content, icon, id, className, testId }: Props) {
  return (
    <Root id={id} className={className} data-test-id={testId}>
      <IconContainer>{icon}</IconContainer>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <ContentContainer>
        <Content>{content}</Content>
      </ContentContainer>
    </Root>
  );
}
