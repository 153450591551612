import { Route, Switch } from 'react-router-dom';

import GroupsView from './GroupsView';
import GroupView from './GroupView';

const RootRoutes = () => (
  <Switch>
    <Route exact path="/:corporateId/groups" component={GroupsView} />
    <Route exact path="/:corporateId/groups/:action" component={GroupsView} />
    <Route exact path="/:corporateId/groups/info/:groupId" component={GroupView} />
    <Route exact path="/:corporateId/groups/info/:groupId/:action" component={GroupView} />
  </Switch>
);

export default RootRoutes;
