import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, FormikErrors, FormikHelpers, useField } from 'formik';
import { Button, TextButton } from '@creditornot/cb-button';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { Select, SelectProps } from '@creditornot/cb-select';
import { Alert } from '@creditornot/cb-alert';
import { isEmpty } from 'lodash';
import { User } from '@creditornot/wolt-auth';
import { LegacyPopover as Popover } from '@creditornot/cb-popover';

import { FormikCheckboxField } from 'components';
import { processError, useGetLanguageName } from 'utils';
import { APIFailure, processErrorBlob } from 'utils/processError';
import { ViewEventComponent } from 'telemetry/components/ViewEventComponent';
import {
  acceptTermsDataConfirm,
  acceptTermsInteractionDownloadAgreement,
  acceptTermsView,
} from 'telemetry/Avo';
import { useTelemetryInteractionEvent } from 'telemetry/hooks/useTelemetryEvents';

import { SignupProgress } from '../components/SignupProgress/SignupProgress';
import { AcceptTermsImage } from '../images/AcceptTermsImage';

type Props = {
  languages: string[];
  companyName: string;
  user: User;
  onSubmit: () => Promise<void>;
  onDownloadClick: (language: string) => Promise<void>;
  goingBack: boolean;
  onBackClick: () => Promise<void>;
  hasBeenDownloaded: boolean;
  isFetching: boolean;
  isCorporateManager: boolean;
  responseError: APIFailure | undefined;
  setResponseError: Dispatch<SetStateAction<APIFailure | undefined>>;
};

type FormValues = {
  terms: boolean;
  language: string;
  download: boolean;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const Title = styled.h1`
  ${typographyCss.Heading1()};
  max-width: 620px;
  margin-bottom: 0;
  margin-top: -24px;
  text-align: center;
  font-weight: 600;
`;

const TooltipContent = styled.div`
  max-width: 250px;
`;

const StyledAcceptTermsImage = styled(AcceptTermsImage)`
  max-height: 200px;
`;

const StyledSignupProgress = styled(SignupProgress)`
  margin-inline: auto;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  align-items: center;
  gap: 40px;
  max-width: 620px;
`;

const DownloadRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const StyledAlert = styled(Alert)`
  margin-top: 16px;
`;

const Strong = styled.span`
  ${typographyCss.Body2({ weight: 'bold' })};
`;

const LanguageSelect = ({
  options,
}: {
  options: SelectProps<{ label: string; value: string }>['options'];
}) => {
  const [{ value }, , { setValue }] = useField<string | null>('language');
  const [, , { setValue: setDownloadClicked }] = useField<boolean>({
    name: 'downloadClicked',
  });
  const { formatMessage } = useIntl();

  return (
    <Select
      value={value}
      options={options}
      placeholder={formatMessage({ id: 'common.select' })}
      onChange={(newValue) => {
        // we want to reset downloadClicked when language is changed
        if (newValue !== value) {
          setDownloadClicked(false);
        }
        setValue(newValue);
      }}
    />
  );
};

const ErrorMessage = ({ errors }: { errors: FormikErrors<FormValues> }) => {
  const { formatMessage } = useIntl();

  const { header, message } = useMemo(() => {
    if (errors.download) {
      return {
        header: formatMessage({ id: 'signup.step2.download-error-header' }),
        message: formatMessage({ id: 'signup.step2.download-error-description' }),
      };
    }

    if (errors.terms) {
      return {
        header: formatMessage({ id: 'signup.step2.terms-error-header' }),
        message: formatMessage({ id: 'signup.step2.terms-error-description' }),
      };
    }

    return { message: '', header: '' };
  }, [errors, formatMessage]);

  return (
    <StyledAlert title={header} variant="error">
      {message}
    </StyledAlert>
  );
};

export const SignupV2Step2 = ({
  companyName,
  languages,
  onDownloadClick,
  onSubmit,
  user,
  hasBeenDownloaded,
  goingBack,
  onBackClick,
  isFetching,
  isCorporateManager,
  responseError,
  setResponseError,
}: Props) => {
  const { formatMessage } = useIntl();

  const [downloading, setDownloading] = useState(false);

  const getLanguageName = useGetLanguageName();

  const acceptTermsInteractionDownloadAgreementEvent = useTelemetryInteractionEvent(
    acceptTermsInteractionDownloadAgreement,
  );
  const acceptTermsDataConfirmEvent = useTelemetryInteractionEvent(acceptTermsDataConfirm);

  const options = languages.map((language) => ({
    label: getLanguageName(language),
    value: language,
  }));

  const initialValues = {
    terms: false,
    language: 'en',
    download: false,
  };

  const validate = useCallback(
    (values: { terms: boolean; language: string | null; download: boolean }) => {
      if (!hasBeenDownloaded) {
        return {
          download: true,
        };
      }

      if (!values.terms) {
        return {
          terms: true,
        };
      }

      return undefined;
    },
    [hasBeenDownloaded],
  );

  const handleDownloadClick = useCallback(
    async (language: string) => {
      setDownloading(true);
      try {
        acceptTermsInteractionDownloadAgreementEvent({ language });
        await onDownloadClick(language);
      } catch (e: unknown) {
        const error = await processErrorBlob(e);

        if (error.message === '') {
          error.message = formatMessage({ id: 'common.exception.header' });
          error.data = formatMessage({ id: 'common.exception.description' });
        }

        setResponseError(error);
        // throw here so DownloadButton can if the request failed
        throw e;
      } finally {
        setDownloading(false);
      }
    },
    [
      acceptTermsInteractionDownloadAgreementEvent,
      formatMessage,
      onDownloadClick,
      setResponseError,
    ],
  );

  const submitCallback = useCallback(
    async (_: FormValues, actions: FormikHelpers<FormValues>) => {
      actions.setSubmitting(true);
      try {
        await onSubmit();
        acceptTermsDataConfirmEvent({});
      } catch (e: unknown) {
        const error = processError(e);

        if (error.message === '') {
          error.message = formatMessage({ id: 'common.exception.header' });
          error.data = formatMessage({ id: 'common.exception.description' });
        }

        actions.setStatus({ error });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [acceptTermsDataConfirmEvent, formatMessage, onSubmit],
  );

  return (
    <Root>
      <ViewEventComponent event={acceptTermsView} />
      <StyledSignupProgress activeIndex={2} />
      <StyledAcceptTermsImage />
      <Title>{formatMessage({ id: 'signup.step2.title' })}</Title>
      <Formik
        initialValues={initialValues}
        onSubmit={submitCallback}
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors: formErrors, values, handleSubmit, isSubmitting, status }) => (
          <form onSubmit={handleSubmit} noValidate>
            <DownloadRow>
              <Button
                variant="lightBlue"
                size="small"
                onClick={() => handleDownloadClick(values.language)}
                loading={downloading}
                disabled={downloading}
              >
                {formatMessage({ id: 'signup.step2.download-agreement-button' })}
              </Button>
              <LanguageSelect options={options} />
            </DownloadRow>
            {!!responseError && (
              <StyledAlert
                variant="error"
                size="medium"
                title={responseError.message}
                onCloseButtonClick={() => setResponseError(undefined)}
              >
                {responseError.data}
              </StyledAlert>
            )}
            {!!status?.error && (
              <StyledAlert variant="error" size="medium" title={status.error.message}>
                {status.error.data}
              </StyledAlert>
            )}
            {!isEmpty(formErrors) && <ErrorMessage errors={formErrors} />}
            <FormContainer>
              <FormikCheckboxField name="terms" required>
                <FormattedMessage
                  id="signup.step2.terms"
                  values={{
                    b: (text) => <Strong>{text}</Strong>,
                    firstName: user.name.first_name,
                    lastName: user.name.last_name,
                    company: companyName,
                  }}
                />
              </FormikCheckboxField>
              <Popover
                placement="top"
                showOnHover={!isCorporateManager && !(isSubmitting || goingBack || isFetching)}
                showOnClick={false}
                content={
                  <TooltipContent>
                    <FormattedMessage id="signup.step2.disabled-submit-button-tooltip" />
                  </TooltipContent>
                }
              >
                <Button
                  style={{ minWidth: '320px' }}
                  variant="blue"
                  type="submit"
                  size="large"
                  loading={isSubmitting || isFetching}
                  disabled={isSubmitting || goingBack || isFetching || !isCorporateManager}
                >
                  {formatMessage({ id: 'common.submit' })}
                </Button>
              </Popover>
              <TextButton
                size="medium"
                onClick={onBackClick}
                disabled={isSubmitting || goingBack || isFetching}
              >
                {formatMessage({ id: 'signup.step2.back-button' })}
              </TextButton>
            </FormContainer>
          </form>
        )}
      </Formik>
    </Root>
  );
};
