import { color } from '@creditornot/cb-ingredients/design-tokens';
import { TextButton } from '@creditornot/cb-button';
import { Upload } from '@creditornot/cb-icons';
import { colors, typographyCss } from '@creditornot/cb-ingredients';
import styled from 'styled-components';
import { useRef } from 'react';

import { cssDirectionalValue } from 'modules/waw-theme/ThemeProvider';

const Root = styled.div<{ $width?: string; $height?: string; $aspectRatio?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
  background-color: ${colors.pepper2};
  border: 2px dashed ${colors.pepper20};
  border-radius: 8px;
  color: ${colors.pepper24};
  padding: 24px;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};
  cursor: pointer;

  > svg {
    fill: currentcolor;
  }
`;

const BannerDescription = styled.span`
  ${typographyCss.Body3()}
  display: block;
  color: ${color.textSubdued};
`;

type Props = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  description: string;
};

export const LogoImagePlaceholder: React.FC<Props> = ({
  role = 'button',
  className,
  description,
  ...props
}) => (
  <Root className={className} role={role} {...props} $width="150px" $height="150px">
    <Upload height={25} width={25} />
    <div>
      <TextButton variant="blue" size="small">
        Choose images
      </TextButton>
      <BannerDescription>{description}</BannerDescription>
    </div>
  </Root>
);

type BannerImageProps = Props & {
  aspectRatio?: string;
  children?: React.ReactNode;
};

export const BannerImagePlaceholder: React.FC<BannerImageProps> = ({
  description,
  role = 'button',
  className,
  aspectRatio,
  children,
  ...props
}) => (
  <Root className={className} role={role} {...props} $width="100%" $aspectRatio={aspectRatio}>
    <Upload width={24} height={24} />
    <div>
      <TextButton variant="blue" size="small">
        Choose images
      </TextButton>
      <BannerDescription>{description}</BannerDescription>
    </div>
    {children}
  </Root>
);

const HiddenFileInput = styled.input.attrs({
  type: 'file',
})`
  position: absolute;
  ${cssDirectionalValue({ ltr: 'left: 0;', rtl: 'right: 0;' })}
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
`;

type BannerImageFileInputProps = BannerImageProps & {
  onFileChange: (file: File) => void | Promise<void>;
};
export const BannerImageFileInput: React.FC<BannerImageFileInputProps> = ({
  onFileChange,
  ...props
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleEditClick = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      onFileChange(e.target.files[0]);
    }
  };
  return (
    <BannerImagePlaceholder onClick={handleEditClick} {...props}>
      <HiddenFileInput ref={fileInputRef} accept="image/*" onChange={handleFileChange} />
    </BannerImagePlaceholder>
  );
};
