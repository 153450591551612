import styled from 'styled-components';

import ResponsiveContainer from './ResponsiveContainer';
import Divider from './Divider';
import CloseButton from './CloseButton';

interface ModalHeaderProps {
  children: React.ReactNode;
  onClose?: () => void;
  className?: string;
  borderBottom?: boolean;
}

const Root = styled(ResponsiveContainer)`
  display: flex;
  align-items: center;
  width: 100%;
  height: max-content;
  min-height: 72px;
  position: relative;
  background: transparent;
`;

const Header = styled.h1`
  font-size: 20px;
  font-weight: 700;
  flex-grow: 2;
`;

const ModalHeader = ({ children, onClose, borderBottom, ...rest }: ModalHeaderProps) => {
  return (
    <>
      <Root {...rest}>
        <Header>{children}</Header>
        {onClose && <CloseButton size="large" onClick={onClose} />}
      </Root>
      {borderBottom && <Divider />}
    </>
  );
};

export default ModalHeader;
