import { useCallback, useState } from 'react';
import { getTime } from 'date-fns';

import { LocalizedMessage } from 'i18n';
import { downloadFile, processError } from 'utils';
import { wolfApiAgent } from 'apiAgents';
import { useNotificationToast } from 'modules/app-modals';

const DOWNLOAD_DOCUMENT_ERROR = 'DOWNLOAD_DOCUMENT_ERROR';

const isDownloadingFromWolfSerivce = (url: string) => !!url.match(/wolf(.*?)files\//);

export const useHandleDownloadInvoiceDocumentFromUrl = () => {
  const { openNotificationToast } = useNotificationToast();
  const [loading, setLoading] = useState<undefined | string>(undefined);
  const download = useCallback(
    async (url: string, createdAt: string, document: string, invoiceId: string) => {
      if (loading) {
        return;
      }

      if (!isDownloadingFromWolfSerivce(url)) {
        window.open(url);
        return;
      }

      try {
        setLoading(invoiceId);
        const documentUrl = new URL(url);
        const { data } = await wolfApiAgent.get(
          `${documentUrl.pathname}${documentUrl.searchParams}`,
          {
            responseType: 'blob',
          },
        );

        downloadFile(data, `${document}_${getTime(new Date(createdAt))}`.toUpperCase());
      } catch (error) {
        openNotificationToast(DOWNLOAD_DOCUMENT_ERROR, {
          variant: 'error',
          autoCloseIn: 10 * 1000,
          title: processError(error).message,
          children: (
            <>
              {processError(error).data || (
                <LocalizedMessage messageKey="invoices.error-downloading-document" />
              )}
            </>
          ),
        });
      } finally {
        setLoading(undefined);
      }
    },
    [openNotificationToast, loading],
  );

  return [download, loading] as [typeof download, string | undefined];
};
