import { color } from '@creditornot/cb-ingredients/design-tokens';
import { CheckmarkCircleFilled } from '@creditornot/cb-icons';
import { typographyCss } from '@creditornot/cb-ingredients';
import { ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { last } from 'ramda';

import { CorporateStatus } from 'modules/corporates/types';
import { LocalizedMessage } from 'i18n';
import { breakpoints } from 'modules/media';

type SelfServiceSignupProgressProps = {
  className?: string;
  status: CorporateStatus;
};

const StyledStatus = styled.div<{ active: boolean }>`
  border-radius: 50%;
  background-color: ${({ active }) => (active ? color.bgFillBrand : color.bg)};
  border: 1px solid ${color.border};
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ active }) => (active ? color.bg : color.textSubdued)};

  @media (max-width: ${breakpoints.small}px) {
    height: 24px;
    width: 24px;
  }
`;

const Root = styled.div`
  display: flex;
  position: relative;
  max-width: 600px;
  justify-content: space-between;
`;

const DashLine = styled.hr`
  border-top: 2px dashed ${color.border};
  border-bottom: none;
  position: absolute;
  width: 80%;
  top: 20px;
  left: 10%;

  @media (max-width: ${breakpoints.small}px) {
    top: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
`;

const StyledCheckmarkCircleFilled = styled(CheckmarkCircleFilled)`
  height: 40px;
  width: 40px;
  fill: ${color.textBrand};
  background-color: ${color.bg};
  z-index: 1;

  @media (max-width: ${breakpoints.small}px) {
    height: 24px;
    width: 24px;
  }
`;

const Title = styled.div`
  ${typographyCss.Title1()}
  text-align: center;
  max-width: 100px;
  margin-top: 8px;
  height: 40px;

  @media (max-width: ${breakpoints.small}px) {
    ${typographyCss.Small({ weight: 'bold' })}
  }
`;

const StatusNumber = styled.div`
  ${typographyCss.Heading5()}

  @media (max-width: ${breakpoints.small}px) {
    ${typographyCss.Small()}
  }
`;

const statuses = [
  ['submitted'],
  ['details_pending'],
  ['verification_pending', 'contract_pending'],
  ['active'],
];

const statusesRecord: Record<string, ReactNode> = {
  submitted: <LocalizedMessage messageKey="corporates.status-progress.submitted" />,
  details_pending: <LocalizedMessage messageKey="corporates.status-progress.details_pending" />,
  verification_pending: (
    <LocalizedMessage messageKey="corporates.status-progress.contract_pending" />
  ),
  contract_pending: <LocalizedMessage messageKey="corporates.status-progress.contract_pending" />,
  active: <LocalizedMessage messageKey="corporates.status-progress.active" />,
};

export const SelfServiceSignupProgress = ({
  className,
  status: corporateStatus,
}: SelfServiceSignupProgressProps) => {
  const currentStatusIndex = useMemo(
    () => statuses.findIndex((status) => status.includes(corporateStatus)),
    [corporateStatus],
  );
  return (
    <Root className={className}>
      {statuses.map((status, index) => (
        <Container key={index}>
          {currentStatusIndex > index ? (
            <StyledCheckmarkCircleFilled />
          ) : (
            <StyledStatus active={last(status) === corporateStatus}>
              <StatusNumber>{index + 1}</StatusNumber>
            </StyledStatus>
          )}

          <Title>{statusesRecord[statuses[index][0]] ?? '–'}</Title>
        </Container>
      ))}
      <DashLine />
    </Root>
  );
};
