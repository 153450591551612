import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQueryClient } from 'modules/react-query';

import { editPolicy, PolicyData } from './api';

export const useEditPolicy = () => {
  const queryClient = useQueryClient();
  const { data: corporate } = useCorporate();

  return useCallback(
    async (policyId: string, data: PolicyData) => {
      if (!corporate?.id) {
        return Promise.reject('Corporate is missing');
      }
      const results = await editPolicy(corporate.id, policyId, data);
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 500);
      return results;
    },
    [corporate?.id, queryClient],
  );
};
