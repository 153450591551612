import { useCallback } from 'react';

import { useCorporate } from 'modules/corporates';
import { useQueryClient } from 'modules/react-query';

import { createGroup, CreateGroupData } from './api';

export const useCreateGroup = () => {
  const { data: corporate } = useCorporate();
  const queryClient = useQueryClient();
  return useCallback(
    async (data: CreateGroupData) => {
      if (!corporate?.id) {
        return Promise.reject('Corporate is missing');
      }
      const result = await createGroup(corporate.id, data);
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 500);
      return result;
    },
    [corporate?.id, queryClient],
  );
};
