import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';
import { Button } from '@creditornot/cb-button';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Checkmark } from '@creditornot/cb-icons';
import { MouseEventHandler } from 'react';

import { LocalizedMessage } from 'i18n';
import { FormFooter } from 'components';

export type NextStepFormFooterProps = {
  currentStep: number;
  totalSteps: number;
  onBackClick?: MouseEventHandler<HTMLButtonElement>;
  onNextClick?: MouseEventHandler<HTMLButtonElement>;
  isLastStep: boolean;
  disabled?: boolean;
  loading?: boolean;
  submitSucceeded?: boolean;
};

const CurrentStepText = styled.span`
  ${typographyCss.Body3()}
  color: ${color.textSubdued};
`;

export const NextStepFormFooter = ({
  currentStep,
  totalSteps,
  onBackClick,
  onNextClick,
  isLastStep,
  disabled,
  loading,
  submitSucceeded,
}: NextStepFormFooterProps) => {
  return (
    <FormFooter
      leftContent={
        <CurrentStepText>
          <LocalizedMessage
            messageKey="corporate-form.current-step"
            values={{
              currentStep,
              totalSteps,
            }}
          />
        </CurrentStepText>
      }
      rightContent={
        <>
          {!!onBackClick && (
            <Button
              variant="lightBlue"
              size="small"
              data-test-id="CreateCorporateForm.BackButton"
              onClick={onBackClick}
            >
              <LocalizedMessage messageKey="common.back" />
            </Button>
          )}
          <Button
            variant="blue"
            data-test-id="CreateCorporateForm.NextButton"
            size="small"
            disabled={disabled}
            loading={loading}
            onClick={onNextClick}
            icon={submitSucceeded && <Checkmark data-test-id="CreateCorporateForm.Checkmark" />}
          >
            {!isLastStep ? (
              <LocalizedMessage messageKey="common.next" />
            ) : (
              <LocalizedMessage messageKey="common.submit" />
            )}
          </Button>
        </>
      }
    />
  );
};
