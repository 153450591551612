import { useCallback } from 'react';

import { useMedia } from 'modules/media';
import { SubscriptionInvite } from 'modules/subscription/types';

import { useSubscriptionUsersQueryParams } from '../../../hooks/useSubscriptionUsersQueryParams';
import { SubscriptionInviteDataList } from './SubscriptionInviteDataList';
import { SubscriptionInviteDataTable } from './SubscriptionInviteDataTable';

interface Props {
  data?: SubscriptionInvite[] | null;
  loading: boolean;
  error: any;
  pageCount?: number;
  dataItemProps: {
    onOpenDeleteInviteModal: (id: string) => void;
    onOpenResendInviteModal: (id: string) => void;
  };
}

export const SubscriptionInviteList = ({
  data,
  loading,
  error,
  pageCount,
  dataItemProps,
}: Props) => {
  const mediumViewport = useMedia('medium');
  const [{ invites: selectedInvites = [] }, setQueryParams] = useSubscriptionUsersQueryParams();

  const handleInviteSelectionClick = useCallback(
    (selectedInvites: string[]) => {
      setQueryParams({
        invites: selectedInvites.length > 0 ? selectedInvites : undefined,
      });
    },
    [setQueryParams],
  );

  const handleSelectAllInvites = useCallback(() => {
    const isAllSelected = selectedInvites.length === data?.length;
    setQueryParams({
      invites: isAllSelected ? undefined : data?.map((invite) => invite.id),
    });
  }, [data, selectedInvites.length, setQueryParams]);

  return mediumViewport ? (
    <SubscriptionInviteDataList
      data={data}
      loading={loading}
      error={error}
      dataItemProps={dataItemProps}
      selectedInvites={selectedInvites}
      onInviteSelectionClick={handleInviteSelectionClick}
    />
  ) : (
    <SubscriptionInviteDataTable
      data={data}
      loading={loading}
      error={error}
      pageCount={pageCount}
      dataCellProps={dataItemProps}
      selectedInvites={selectedInvites}
      onInviteSelectionClick={handleInviteSelectionClick}
      onSelectAllInvites={handleSelectAllInvites}
    />
  );
};
