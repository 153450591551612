import { isBefore, set, isEqual, isPast } from 'date-fns';
import { useCallback } from 'react';
import { useFormikContext } from 'formik';

import { FormikTimeSelect } from 'components';
import { TimeOption, FormikTimeSelectProps } from 'components/FormikTimeSelect';
import { useI18n } from 'i18n';
import { isRequired } from 'validation';

import { CreateEventFormData } from '../types';

export const FormikEventEndTimeInputSelect: React.FC<Partial<FormikTimeSelectProps>> = ({
  timeSelectProps,
  ...props
}) => {
  const { getLocalizedMessage } = useI18n();
  const {
    values: { end_date, start_date, start_minutes },
  } = useFormikContext<CreateEventFormData>();
  const getDisabledTime = useCallback(
    ({ value }: TimeOption) => {
      if (!end_date) return true;
      const dateOption = set(end_date, {
        minutes: Number.parseInt(value),
        hours: 0,
        seconds: 0,
      });
      const startDate =
        start_date &&
        set(start_date, {
          minutes: Number.parseInt(start_minutes) || 0,
          hours: 0,
          seconds: 0,
        });

      const isTimeInThePast = isPast(dateOption);
      const isEndTimeSameAsStartTime = startDate ? isEqual(dateOption, startDate) : false;
      const isEndTimeBeforeStartTime = startDate ? isBefore(dateOption, startDate) : false;

      return isTimeInThePast || isEndTimeBeforeStartTime || isEndTimeSameAsStartTime;
    },
    [end_date, start_date, start_minutes],
  );

  return (
    <FormikTimeSelect
      validate={isRequired}
      name="end_minutes"
      timeSelectProps={{
        placeholder: getLocalizedMessage('common.time-picker-placeholder'),
        maxMenuHeight: 140,
        ...timeSelectProps,
      }}
      getDisabledTime={getDisabledTime}
      {...props}
    />
  );
};
