import { FormikInputField } from 'components';
import { useI18n } from 'i18n';
import { createValidator, isRequired } from 'validation';

const validate = createValidator([isRequired]);

interface Props {
  alwaysShowError?: boolean;
}

export const MerchantIdField = ({ alwaysShowError }: Props) => {
  const { getLocalizedMessage } = useI18n();

  return (
    <FormikInputField
      name="merchant_id"
      type="text"
      required={true}
      validate={validate}
      alwaysShowError={alwaysShowError}
      inputProps={{
        placeholder: getLocalizedMessage(
          'corporate-form.delivery-settings.merchant-id-placeholder',
        ),
      }}
    />
  );
};
