import { useEditCorporate } from 'modules/corporates';
import { EditCorporateFormValues } from 'views/settings/CompanySettings/types';

import { formatFormValuesBeforeSending } from './formatFormValues';
import { Corporate } from './types';

type FormValues = Pick<EditCorporateFormValues, 'account_manager'>;

export const useEditCorporateAccount = (corporate: Corporate) => {
  const editCorporate = useEditCorporate();

  return (initialValues: FormValues, newValues: FormValues) =>
    editCorporate(formatFormValuesBeforeSending(initialValues, newValues, corporate.currency));
};
