import * as t from 'io-ts';

import { PersonName } from 'modules/common/types';

export type ServiceAgreementAcceptance = t.TypeOf<typeof ServiceAgreement>;
export const ServiceAgreementAcceptance = t.type({
  accepted_at: t.string,
  accepted_by_user_id: t.string,
  accepted_by_user_name: PersonName,
});

// https://corporate-service.development-internal.dev.woltapi.com/docs/portal-api#/Signup/get_effective_service_agreement
export type ServiceAgreement = t.TypeOf<typeof ServiceAgreement>;
export const ServiceAgreement = t.type({
  id: t.string,
  corporate_id: t.string,
  effective_after: t.string,
  documents: t.array(
    t.type({
      acceptance: t.union([t.null, ServiceAgreementAcceptance]),
      document_type: t.string,
      language: t.string,
      last_accessed_at: t.union([t.null, t.string]),
      version: t.number,
    }),
  ),
});
