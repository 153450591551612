import { color } from '@creditornot/cb-ingredients/design-tokens';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, TextButton } from '@creditornot/cb-button';
import { typographyCss } from '@creditornot/cb-ingredients';
import { Formik, FormikConfig, FormikValues, useFormikContext } from 'formik';
import { Checkmark } from '@creditornot/cb-icons';

import { LocalizedMessage } from 'i18n';
import { ApiErrorNotification } from 'components';

type ChildrenProps = {
  isEditing: boolean;
};

export type FormikInlineFormProps<Values> = {
  className?: string;
  label: React.ReactNode;
  children?: ((props: ChildrenProps) => React.ReactNode) | React.ReactNode;
} & Omit<FormikConfig<Values>, 'children'>;

const Root = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${color.border};
`;

const Item = styled.div`
  padding-top: 16px;
`;

const FooterItem = styled(Item)`
  display: flex;

  button + button {
    margin-inline-start: 16px;
  }
`;

const Title = styled.div`
  ${typographyCss.Body3({ weight: 'bold' })}
`;

const ItemText = styled.span`
  ${typographyCss.Body3()}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ResetFormStateRenderless = ({ isEditing }: { isEditing?: boolean }) => {
  const { resetForm } = useFormikContext();
  useEffect(() => {
    resetForm();
  }, [resetForm, isEditing]);

  return null;
};

export function FormikInlineForm<Values extends FormikValues>({
  label,
  children,
  onSubmit,
  className,
  ...props
}: FormikInlineFormProps<Values>) {
  const [isEditing, setIsEditing] = useState(false);
  const handleEditClick = useCallback(() => setIsEditing(true), []);
  const handleCancelClick = useCallback(() => setIsEditing(false), []);

  return (
    <Formik<Values>
      onSubmit={async (values, actions) => {
        try {
          await onSubmit(values, actions);
          actions.setSubmitting(false);
          actions.setStatus({ submitSucceeded: true });
          setTimeout(() => setIsEditing(false), 500);
        } catch (error) {
          actions.setSubmitting(false);
          actions.setStatus({ error });
        }
      }}
      enableReinitialize={!isEditing}
      {...props}
    >
      {({ isSubmitting, submitForm, dirty, status }) => (
        <Root className={className}>
          <ResetFormStateRenderless isEditing={isEditing} />

          <Header>
            <Title>{label}</Title>

            {!isEditing && (
              <TextButton onClick={handleEditClick}>
                <LocalizedMessage messageKey="common.edit" />
              </TextButton>
            )}
          </Header>

          <Item>
            <ItemText>
              <div>{typeof children === 'function' ? children({ isEditing }) : children}</div>
            </ItemText>
          </Item>

          {status?.error && (
            <Item>
              <ApiErrorNotification error={status.error} />
            </Item>
          )}

          {isEditing && (
            <FooterItem>
              <Button
                disabled={isSubmitting || status?.submitSucceeded}
                onClick={handleCancelClick}
                variant="lightBlue"
                size="small"
              >
                <LocalizedMessage messageKey="common.cancel" />
              </Button>
              <Button
                loading={isSubmitting}
                variant="blue"
                size="small"
                onClick={submitForm}
                icon={status?.submitSucceeded && <Checkmark />}
                disabled={isSubmitting || status?.submitSucceeded || !dirty}
              >
                <LocalizedMessage messageKey="common.save" />
              </Button>
            </FooterItem>
          )}
        </Root>
      )}
    </Formik>
  );
}
