import styled from 'styled-components';
import { Spinner } from '@creditornot/cb-progress';

import {
  ModalHeader,
  Divider,
  ResponsiveContainer,
  ApiErrorNotification,
  ModalV2,
} from 'components';
import { useFetchAgreementParty } from 'modules/agreement-parties';

import CreateAgreementPartyForm from './CreateAgreementPartyForm';
import EditAgreementPartyForm from './EditAgreementPartyForm';

interface Props {
  show: boolean;
  agreementPartyId?: string;
  onClose: () => void;
  onSuccess: () => void;
}

const SpinnerContainer = styled.div`
  min-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ApiErrorContainer = styled(ResponsiveContainer)`
  margin-bottom: 32px;
`;

const AgreementPartyModal: React.FC<Props> = ({ show, onClose, onSuccess, agreementPartyId }) => {
  const { isLoading, data, error } = useFetchAgreementParty(agreementPartyId || null);

  const isEditing = !!agreementPartyId;

  return (
    <ModalV2
      open={show}
      onDismiss={onClose}
      sizePreset={{
        desktop: 'centered:large',
        mobile: 'fullScreen',
      }}
    >
      <ModalHeader onClose={onClose}>
        {isEditing ? 'Edit agreement party' : 'Create an agreement party'}
      </ModalHeader>
      <Divider />

      {isLoading && (
        <SpinnerContainer>
          <Spinner size="large" />
        </SpinnerContainer>
      )}

      {!!error && (
        <ApiErrorContainer>
          <ApiErrorNotification error={error} />
        </ApiErrorContainer>
      )}

      {!isEditing && <CreateAgreementPartyForm onClose={onClose} onSuccess={onSuccess} />}

      {isEditing && data && (
        <EditAgreementPartyForm onClose={onClose} onSuccess={onSuccess} data={data} />
      )}
    </ModalV2>
  );
};

export default AgreementPartyModal;
