import { useState, useEffect } from 'react';
import { initTelemetry } from '@creditornot/browser-telemetry';

import { useCookieConsents } from 'modules/cookie-consents/CookieConsentsProvider';
import env from 'config/env';

export const TelemetryBoot: React.FC = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const {
    consents: { analytics },
  } = useCookieConsents();

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
      initTelemetry({
        appVersion: env.APP_VERSION,
        disabled: false,
        endpoint: env.GATEKEEPER_URI,
        hasUserConsent: analytics,
        logging: env.APP_ENV !== 'production' ? true : false,
        requestHeaders: {
          'x-api-key': env.GATEKEEPER_API_KEY,
        },
      });
    }
  }, [analytics, isInitialized]);

  return null;
};
