import { useCallback, useEffect } from 'react';

import {
  NeverNullStringParam,
  useQueryParams,
  NeverNullNumberParam,
  WoltPageSizeParam,
  withDefault,
  NeverNullDelimitedArrayParam,
} from 'modules/react-query-params';
import { useLocalStorage } from 'modules/hooks';

export const useCorporatesQueryParams = () => {
  const [localstoragecountrycode = 'FIN', setLocalStorageCountryCode] = useLocalStorage<string>(
    'countryCode',
    'FIN',
  );

  const [params, setReactQueryParams] = useQueryParams({
    search: withDefault(NeverNullStringParam, ''),
    country: withDefault(NeverNullStringParam, localstoragecountrycode),
    createCorporateModal: withDefault(NeverNullStringParam, ''),
    corporate_status: NeverNullDelimitedArrayParam,
    products: NeverNullDelimitedArrayParam,
    page: withDefault(NeverNullNumberParam, 1),
    page_size: WoltPageSizeParam,
  });

  useEffect(
    () => setLocalStorageCountryCode(params.country),
    [params.country, setLocalStorageCountryCode],
  );

  const setQueryParams = useCallback(
    (queries: Record<string, unknown>) => {
      const shouldResetPage =
        'page_size' in queries ||
        'country' in queries ||
        'search' in queries ||
        'products' in queries ||
        'corporate_status' in queries;

      setReactQueryParams(({ page, ...staledQueries }) => ({
        ...staledQueries,
        page: shouldResetPage ? 1 : page,
        ...queries,
      }));
    },
    [setReactQueryParams],
  );

  return { params, setQueryParams };
};
