import { IntlShape, useIntl } from 'react-intl';
import styled from 'styled-components';
import { InputContainer } from '@creditornot/cb-form';
import { Alert } from '@creditornot/cb-alert';
import { useFormikContext } from 'formik';

import {
  CreditLimitField,
  CustomerReferenceField,
  DailyTransactionLimitField,
  EDIOperatorId,
  ElectronicInvoicingAddress,
  InvoicingEmailField,
  OverdueInterestField,
  PreferredInvoiceLanguageField,
  ReceiptEmailField,
  SendInvoiceCopyToEmailsField,
  SingleTransactionLimitField,
  VATExemptionDisclaimer,
} from 'modules/corporates/components';
import { FormSection, FormikInputField, FormRowV2 as Row } from 'components';
import { CountryConfig } from 'modules/country-configs/types';
import { InvoicingOptionFieldV2 } from 'modules/corporates/components/corporate-form-fields/InvoicingOptionFieldV2';
import { OrderOptionFieldV2 } from 'modules/corporates/components/corporate-form-fields/OrderOptionFieldV2';
import { BillingCycleFieldV2 } from 'modules/corporates/components/corporate-form-fields/BillingCycleFieldV2';
import { Corporate } from 'modules/corporates/types';
import { useI18n } from 'i18n';
import { processError } from 'utils';
import { ApiErrorContainer } from 'views/settings/CompanySettingsV2/ApiErrorContainer';
import { EditCorporateFormValues } from 'views/settings/CompanySettings/types';
import { IsElectronicInvoicingDetailsEnabledFieldV2 } from 'modules/corporates/components/corporate-form-fields/ElectronicInvoicingDetailsFields';
import { corporateFeatureFlag } from 'modules/corporates/utils';

import { RenderMode } from '../../types';
import { EditButton } from '../EditButton';
import { SaveCancelButtons } from '../SaveCancelButtons';
import { FormSectionDivider } from '../utils.styled';
import {
  BillingCycle,
  DefaultViewRender,
  InvoiceGroups,
  ReportGroups,
  InvoiceEmail,
  PreferredInvoiceLocale,
  DefaultCurrencyRender,
  EInvoicingEnabled,
} from './helpers';

type Props = {
  isWoltEmployee: boolean;
  corporate: Corporate;
  renderMode: RenderMode;
  countryConfig?: CountryConfig;
  onEditClick?: () => void;
  onSaveClick?: () => void;
  onCancelClick?: () => void;
  isSubmitting?: boolean;
  dirty?: boolean;
  error?: any;
};

type FormValues = {
  invoice_email: string;
  invoice_email_cc_recipients: string[];
  single_receipt_email: string;
  preferred_invoice_locale: string;
  customer_reference: string;
  billing_twice_a_month: string;
  payment_term_delay_days: string;
  invoice_overdue_interest: string;
  organise_invoice_by_groups: string;
  organise_order_report_by_groups: string;
};

const HalfWidth = styled.div`
  width: 50%;
`;

/**
 * A bit easier to read than the putting all of this logic in the render function.
 */
const renderMap: {
  [key in keyof FormValues]: {
    [key in Props['renderMode']]: (args: {
      formatMessage?: IntlShape['formatMessage'];
      hint?: string;
      isWoltEmployee?: boolean;
    }) => JSX.Element | null;
  };
} = {
  billing_twice_a_month: {
    edit: () => <BillingCycleFieldV2 />,
    signup: () => <BillingCycleFieldV2 />,
    view: () => <BillingCycle />,
  },
  customer_reference: {
    edit: ({ formatMessage }) => (
      <InputContainer
        hint={formatMessage?.({ id: 'corporate-form.customer_reference-explanation' })}
      >
        <CustomerReferenceField />
      </InputContainer>
    ),
    signup: () => null,
    view: () => <DefaultViewRender name="customer_reference" />,
  },
  payment_term_delay_days: {
    edit: ({ isWoltEmployee }) => (
      <HalfWidth>
        <FormikInputField
          name="payment_term_delay_days"
          inputProps={{ disabled: !isWoltEmployee }}
          allowClear={isWoltEmployee}
        />
      </HalfWidth>
    ),
    signup: () => null,
    view: () => <DefaultViewRender name="payment_term_delay_days" />,
  },
  invoice_overdue_interest: {
    edit: ({ isWoltEmployee }) => (
      <HalfWidth>
        <OverdueInterestField disabled={!isWoltEmployee} allowClear={isWoltEmployee} />
      </HalfWidth>
    ),
    signup: () => null,
    view: () => <DefaultViewRender name="invoice_overdue_interest" />,
  },
  organise_invoice_by_groups: {
    edit: () => <InvoicingOptionFieldV2 />,
    signup: () => <InvoicingOptionFieldV2 />,
    view: () => <InvoiceGroups />,
  },
  organise_order_report_by_groups: {
    edit: () => <OrderOptionFieldV2 />,
    signup: () => <OrderOptionFieldV2 />,
    view: () => <ReportGroups />,
  },
  invoice_email: {
    edit: ({ hint }) => (
      <InputContainer hint={hint}>
        <InvoicingEmailField />
      </InputContainer>
    ),
    signup: ({ hint }) => (
      <InputContainer hint={hint}>
        <InvoicingEmailField />
      </InputContainer>
    ),
    view: () => <InvoiceEmail />,
  },
  invoice_email_cc_recipients: {
    edit: () => <SendInvoiceCopyToEmailsField />,
    signup: () => <SendInvoiceCopyToEmailsField />,
    view: () => null,
  },
  single_receipt_email: {
    edit: ({ formatMessage }) => (
      <InputContainer hint={formatMessage?.({ id: 'corporate-form.receipt-email-explanation' })}>
        <ReceiptEmailField />
      </InputContainer>
    ),
    signup: ({ formatMessage }) => (
      <InputContainer hint={formatMessage?.({ id: 'corporate-form.receipt-email-explanation' })}>
        <ReceiptEmailField />
      </InputContainer>
    ),
    view: () => <DefaultViewRender name="single_receipt_email" />,
  },
  preferred_invoice_locale: {
    edit: () => <PreferredInvoiceLanguageField />,
    signup: () => <PreferredInvoiceLanguageField />,
    view: () => <PreferredInvoiceLocale />,
  },
};

/**
 * These fields have some custom rendering thus outside of the renderMap.
 */
const invoicingFeeRenderMap = {
  edit: ({ isWoltEmployee }: { isWoltEmployee: boolean }) => (
    <HalfWidth>
      <FormikInputField
        name="invoice_fee_percentage"
        inputProps={{ disabled: !isWoltEmployee }}
        allowClear={isWoltEmployee}
      />
    </HalfWidth>
  ),
  view: () => <DefaultViewRender name="invoice_fee_percentage" />,
};

const creditLimitRenderMap = {
  edit: ({ currency }: { currency: string }) => (
    <HalfWidth>
      <CreditLimitField currency={currency} />
    </HalfWidth>
  ),
  view: ({ value, currency }: { value?: number | null; currency: string }) => (
    <DefaultCurrencyRender value={value} currency={currency} />
  ),
};

const singleTransactionLimitRenderMap = {
  edit: ({ currency, hint }: { currency: string; hint: string }) => (
    <InputContainer hint={hint} style={{ width: '50%' }}>
      <SingleTransactionLimitField currency={currency} />
    </InputContainer>
  ),
  view: ({ value, currency }: { value?: number | null; currency: string }) => (
    <DefaultCurrencyRender value={value} currency={currency} />
  ),
};

const dailyTransactionsRenderMap = {
  edit: ({ currency, hint }: { currency: string; hint: string }) => (
    <InputContainer hint={hint} style={{ width: '50%' }}>
      <DailyTransactionLimitField currency={currency} />
    </InputContainer>
  ),
  view: ({ value, currency }: { value?: number | null; currency: string }) => (
    <DefaultCurrencyRender value={value} currency={currency} />
  ),
};

const eInvoicingRenderMap = {
  is_electronic_invoicing_enabled: {
    edit: () => <IsElectronicInvoicingDetailsEnabledFieldV2 />,
    view: () => <EInvoicingEnabled />,
  },
  edi: {
    edit: ({ disabled }: { disabled: boolean }) => (
      <ElectronicInvoicingAddress disabled={disabled} />
    ),
    view: () => <DefaultViewRender name="electronic_invoicing_details.edi" />,
  },
  edi_operator: {
    edit: ({ disabled }: { disabled: boolean }) => <EDIOperatorId disabled={disabled} />,
    view: () => <DefaultViewRender name="electronic_invoicing_details.edi_operator" />,
  },
  vat_exemption_disclaimer: {
    edit: ({ disabled }: { disabled: boolean }) => <VATExemptionDisclaimer disabled={disabled} />,
    view: () => <DefaultViewRender name="electronic_invoicing_details.vat_exemption_disclaimer" />,
  },
};

/**
 * Expects to be used inside a Formik form.
 */
export const InvoicesAndReceiptsForm = ({
  renderMode,
  isWoltEmployee,
  corporate,
  countryConfig,
  onEditClick,
  onCancelClick,
  onSaveClick,
  isSubmitting,
  dirty,
  error,
}: Props) => {
  const { formatMessage } = useIntl();
  // getLocalizedNumber has custom currency logic, so we need to use it instead of formatNumber
  const { getLocalizedNumber } = useI18n();
  const {
    values: { is_electronic_invoicing_enabled },
  } = useFormikContext<EditCorporateFormValues>();

  const { deliveryEnabled, wfwEnabled } = corporateFeatureFlag(corporate);

  return (
    <FormSection
      testId="invoice-settings"
      title={formatMessage({ id: 'corporate-form.invoices-and-receipts' })}
      topRightItem={onEditClick && <EditButton renderMode={renderMode} onEditClick={onEditClick} />}
    >
      <Row
        title={formatMessage({ id: 'corporate-form.preferred_invoice_language' })}
        content={renderMap.preferred_invoice_locale[renderMode]({ formatMessage })}
      />
      <Row
        title={formatMessage({ id: 'corporate-form.billing_cycle' })}
        content={renderMap.billing_twice_a_month[renderMode]({
          formatMessage,
        })}
      />
      {wfwEnabled && renderMode !== 'signup' && (
        <Row
          title={formatMessage({ id: 'corporate-form.invoice-organization' })}
          content={renderMap.organise_invoice_by_groups[renderMode]({ formatMessage })}
        />
      )}
      <Row
        htmlFor="invoice_email"
        title={formatMessage({ id: 'corporate-form.invoicing_recipient' })}
        content={renderMap.invoice_email[renderMode]({
          hint: formatMessage(
            {
              id: 'corporate-form.invoicing_email_explanation',
            },
            {
              amount: getLocalizedNumber(
                countryConfig?.transaction_limits?.single_transaction_amount ?? 0,
                { currency: corporate.currency },
              ),
            },
          ),
        })}
      />
      {renderMode !== 'view' && (
        <Row
          htmlFor="invoice_email_cc_recipients"
          title={''}
          content={renderMap.invoice_email_cc_recipients[renderMode]({ formatMessage })}
        />
      )}

      <FormSectionDivider />

      {wfwEnabled && renderMode !== 'signup' && (
        <Row
          title={formatMessage({ id: 'corporate-form.order-organization' })}
          content={renderMap.organise_order_report_by_groups[renderMode]({ formatMessage })}
        />
      )}
      <Row
        htmlFor="single_receipt_email"
        title={formatMessage({ id: 'corporate-form.receipt-recipient' })}
        content={renderMap.single_receipt_email[renderMode]({ formatMessage })}
      />

      {renderMode !== 'signup' && (
        <>
          <FormSectionDivider />
          <Row
            htmlFor="customer_reference"
            title={formatMessage({ id: 'corporate-form.customer_reference' })}
            content={renderMap.customer_reference[renderMode]({ formatMessage })}
          />
          <Row
            htmlFor="invoice_fee_percentage"
            title={formatMessage({ id: 'corporate-form.invoicing_fee' })}
            content={invoicingFeeRenderMap[renderMode]({ isWoltEmployee })}
          />
          <Row
            testId="payment_term_delay_days"
            title={formatMessage({ id: 'corporate-form.payment_due_in' })}
            content={renderMap.payment_term_delay_days[renderMode]({
              isWoltEmployee,
              formatMessage,
            })}
          />
          <Row
            htmlFor="invoice_overdue_interest"
            testId="invoice-overdue-interest"
            title={formatMessage({ id: 'corporate-form.overdue_interest' })}
            content={renderMap.invoice_overdue_interest[renderMode]({
              isWoltEmployee,
              formatMessage,
            })}
          />
        </>
      )}

      {isWoltEmployee && renderMode !== 'signup' && (
        <>
          <FormSectionDivider />
          <Alert variant="info" size="small">
            {formatMessage({ id: 'corporate-form.employee-disclaimer' })}
          </Alert>
          <Row
            title={formatMessage({ id: 'corporate-form.credit_limit' })}
            content={creditLimitRenderMap[renderMode]({
              // for display value we used number (in cents for EUR), but for input we need to use string
              value: corporate.credit_limit_amount,
              currency: corporate.currency,
            })}
          />
          {!deliveryEnabled && (
            <>
              <Row
                htmlFor="transaction_limits.single_transaction_amount"
                title={formatMessage({ id: 'corporate-form.single-transaction-limit' })}
                content={singleTransactionLimitRenderMap[renderMode]({
                  hint: formatMessage(
                    {
                      id: 'corporate-form.single-transaction-limit-explanation',
                    },
                    {
                      amount: getLocalizedNumber(
                        countryConfig?.transaction_limits?.single_transaction_amount ?? 0,
                        {
                          currency: corporate.currency,
                        },
                      ),
                    },
                  ),
                  currency: corporate.currency,
                  // for display value we used number (in cents for EUR), but for input we need to use string
                  value: corporate.transaction_limits?.single_transaction_amount,
                })}
              />
              <Row
                htmlFor="transaction_limits.daily_transactions_amount"
                title={formatMessage({ id: 'corporate-form.daily-transaction-limit' })}
                content={dailyTransactionsRenderMap[renderMode]({
                  hint: formatMessage(
                    {
                      id: 'corporate-form.daily-transaction-limit-explanation',
                    },
                    {
                      amount: getLocalizedNumber(
                        countryConfig?.transaction_limits?.daily_transactions_amount ?? 0,
                        {
                          currency: corporate.currency,
                        },
                      ),
                    },
                  ),
                  currency: corporate.currency,
                  // for display value we used number (in cents for EUR), but for input we need to use string
                  value: corporate.transaction_limits?.daily_transactions_amount,
                })}
              />
            </>
          )}
          {countryConfig?.is_electronic_invoicing_enabled && (
            <>
              <FormSectionDivider />
              <Row
                title={formatMessage({ id: 'corporate-form.electronic-invoicing-details.enabled' })}
                content={eInvoicingRenderMap.is_electronic_invoicing_enabled[renderMode]()}
              />
              {(is_electronic_invoicing_enabled || renderMode === 'edit') && (
                <>
                  <Row
                    htmlFor="electronic_invoicing_details.edi"
                    title={formatMessage({
                      id: 'corporate-form.electronic-invoicing-details.edi',
                    })}
                    content={eInvoicingRenderMap.edi[renderMode]({
                      disabled: !is_electronic_invoicing_enabled,
                    })}
                  />
                  <Row
                    htmlFor="electronic_invoicing_details.edi_operator"
                    title={formatMessage({
                      id: 'corporate-form.electronic-invoicing-details.edi-operator',
                    })}
                    content={eInvoicingRenderMap.edi_operator[renderMode]({
                      disabled: !is_electronic_invoicing_enabled,
                    })}
                  />
                  <Row
                    htmlFor="electronic_invoicing_details.vat_exemption_disclaimer"
                    title={formatMessage({
                      id: 'corporate-form.electronic-invoicing-details.vat-exemption-disclaimer',
                    })}
                    content={eInvoicingRenderMap.vat_exemption_disclaimer[renderMode]({
                      disabled: !is_electronic_invoicing_enabled,
                    })}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
      {error && (
        <ApiErrorContainer>
          <Alert variant="error" size="medium" title={processError(error).message}>
            {processError(error).data}
          </Alert>
        </ApiErrorContainer>
      )}
      <SaveCancelButtons
        renderMode={renderMode}
        onSaveClick={onSaveClick}
        onCancelClick={onCancelClick}
        isSubmitting={isSubmitting}
        dirty={dirty}
      />
    </FormSection>
  );
};
