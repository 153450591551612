import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { DatePickerInput, DatePickerInputProps } from '@creditornot/cb-date-picker';
import { useField } from 'formik';

import { useI18n } from 'i18n';
import { getFormikFieldError } from 'validation';

import { FormikFieldContainer } from './FormikFieldContainer';

type FormikDateInputSelectProps = {
  name: string;
  disabled?: boolean;
  alwaysShowError?: boolean;
  hideErrorMessage?: boolean;
  className?: string;
  validate?: (value: any) => string | void | Promise<string | void>;
  datePickerInputProps?: Partial<DatePickerInputProps>;
};

const Root = styled(FormikFieldContainer)`
  & > div {
    width: 100%;
  }
`;

export function FormikDateInputSelect({
  name,
  disabled,
  className,
  datePickerInputProps,
  alwaysShowError,
  validate,
}: FormikDateInputSelectProps) {
  const { getLocalizedMessage } = useI18n();

  const [{ value }, meta, { setTouched, setValue }] = useField<Date | undefined>({
    name,
    validate,
  });

  const handleBlur = useCallback(() => {
    setTouched(true, true);
  }, [setTouched]);

  const formikFieldError = useMemo(
    () => getFormikFieldError(meta, alwaysShowError),
    [alwaysShowError, meta],
  );

  const errorMessage = useMemo(
    () =>
      formikFieldError && getLocalizedMessage(formikFieldError.messageKey, formikFieldError.values),
    [formikFieldError, getLocalizedMessage],
  );

  return (
    <Root errorMessage={errorMessage} className={className}>
      <DatePickerInput
        value={value}
        disabled={disabled}
        onChange={setValue}
        onBlur={handleBlur}
        invalid={!!errorMessage}
        closeOnChange
        {...datePickerInputProps}
      />
    </Root>
  );
}
