import { useQuery, UseQueryOptions } from 'modules/react-query';

import { fetchCountryConfigs } from './api';
import { CountryConfig } from './types';

export const useFetchCountryConfigs = <T = CountryConfig[]>(
  config?: UseQueryOptions<CountryConfig[], unknown, T>,
) => {
  return useQuery({ queryKey: 'country-configs', queryFn: fetchCountryConfigs, ...config });
};
