import { InformationDisplay } from 'components';
import { LocalizedMessage, LocalizedNumber } from 'i18n';
import { Event } from 'modules/events/types';

import { EventDateTime } from './EventDateTime';

interface Props {
  eventData: Event;
  className?: string;
  currency: string;
}

export const EventDetails: React.FC<Props> = ({ eventData, className, currency }) => {
  return (
    <div className={className}>
      <InformationDisplay
        borderBottom
        labelColor="black"
        label={
          <strong>
            <LocalizedMessage messageKey="events.event-details.event-time-label" />
          </strong>
        }
        explanation={<LocalizedMessage messageKey="events.event-details.event-time-info-message" />}
      >
        <LocalizedMessage
          messageKey="events.event-details.event-date"
          values={{
            startDate: <EventDateTime dateTime={eventData.start_time} />,
            endDate: <EventDateTime dateTime={eventData.end_time} />,
          }}
        />
      </InformationDisplay>

      <InformationDisplay
        borderBottom
        labelColor="black"
        label={
          <strong>
            <LocalizedMessage messageKey="events.event-details.budget" />
          </strong>
        }
      >
        <LocalizedNumber value={eventData.participant_budget_amount} currency={currency} />
      </InformationDisplay>

      <InformationDisplay
        labelColor="black"
        borderBottom
        label={
          <strong>
            <LocalizedMessage messageKey="common.description" />
          </strong>
        }
      >
        {eventData.description.trim().length === 0 ? '–' : eventData.description}
      </InformationDisplay>
    </div>
  );
};
