import { useField } from 'formik';
import { parsePhoneNumber, getCountryCallingCode } from 'libphonenumber-js';
import { useIntl } from 'react-intl';
import { Select } from '@creditornot/cb-select';
import { Input } from '@creditornot/cb-input';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { FormikInputFieldProps } from 'components/FormikInputField';
import { createValidator, getFormikFieldError, isRequired, isValidPhoneNumber } from 'validation';
import { FormikFieldContainer } from 'components/FormikFieldContainer';
import { useConfigs } from 'modules/configs';

const validate = createValidator([isRequired, isValidPhoneNumber]);

interface Props extends Omit<FormikInputFieldProps, 'name' | 'type' | 'required' | 'validate'> {
  alwaysShowError?: boolean;
}

const InputsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const CallingCodeSelect = styled(Select)`
  width: fill-available;
`;

const parseNumber = (value: string) => {
  try {
    return parsePhoneNumber(value);
  } catch (e) {
    return undefined;
  }
};

export const ContactPersonPhoneNumberField = ({
  alwaysShowError = false,
  showErrorMessage = true,
  ...rest
}: Props) => {
  const { formatMessage } = useIntl();
  const { configs } = useConfigs();

  const [{ value, onBlur }, meta, { setValue }] = useField<string>({
    name: 'contact_phone',
    validate,
  });

  const parsed = parseNumber(value);

  const [selectedCountryCode, setSelectedCountryCode] = useState<string | undefined>(
    parsed?.country ? `+${getCountryCallingCode(parsed.country)}` : undefined,
  );
  const [selectedNumber, setSelectedNumber] = useState<string | undefined>(parsed?.nationalNumber);

  const phoneNumberCountryCodes = configs?.countries.map((config) => {
    return {
      value: config.phonePrefix,
      label: `${config.flag} ${config.name} ${config.phonePrefix}`,
    };
  });

  const formikFieldError = useMemo(
    () => getFormikFieldError(meta, alwaysShowError),
    [alwaysShowError, meta],
  );

  const errorMessage = useMemo(
    () =>
      formikFieldError &&
      formatMessage({ id: formikFieldError.messageKey }, formikFieldError.values),
    [formikFieldError, formatMessage],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement, Element>) => {
      onBlur(event);

      const parsedNumber = parseNumber(value);

      if (parsedNumber) {
        setValue(parsedNumber.format('E.164'));
        setSelectedNumber(parsedNumber.nationalNumber);
      }
    },
    [onBlur, setValue, value],
  );

  const handleNumberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedNumber(event.target.value);
      setValue(`${selectedCountryCode ?? ''}${event.target.value}`);
    },
    [selectedCountryCode, setValue],
  );

  const handleCountryChange = useCallback(
    (code: string | null) => {
      if (!code) return;

      setSelectedCountryCode(code);
      setValue(`${code}${selectedNumber ?? ''}`);
    },
    [selectedNumber, setValue],
  );

  return (
    <FormikFieldContainer errorMessage={showErrorMessage && errorMessage}>
      <InputsContainer>
        <CallingCodeSelect
          placeholder=""
          options={phoneNumberCountryCodes}
          value={selectedCountryCode}
          onBlur={handleBlur}
          onChange={handleCountryChange}
        />
        <Input
          name="contact_phone"
          type="tel"
          required={true}
          id="contact_phone"
          value={selectedNumber}
          onBlur={handleBlur}
          onChange={handleNumberChange}
          {...rest}
        />
      </InputsContainer>
    </FormikFieldContainer>
  );
};
